<template>
  <v-row no-gutters dense>
    <v-col cols="12">
      <product-sub-nodes :products="sortByIndex(product.main)" section="main" :title="$t('Sub-products')" @deleteChild="deleteChild" :readonly="readonly" />
    </v-col>

    <cs-confirm-dialog ref="confirm-product-delete" />
  </v-row>
</template>

<script>
import _ from 'lodash'
import ProductSubNodes from './ProductSubNodes'

export default {
  name: 'ProductNode',
  components: {
    'product-sub-nodes': ProductSubNodes
  },
  props: {
    product: { type: Object, required: true },
    readonly: { type: Boolean, default: false }
  },
  data () { return {} },
  methods: {
    deleteChild ({ section, index, child }) {
      if (this.product[section] && this.product[section][index]) {
        this.$refs['confirm-product-delete'].open(this.$t('Remove sub-product'), this.$t('Confirm removal of {name}?', { name: child.ref }), { color: 'red' })
          .then(confirmed => {
            if (confirmed) this.product[section].splice(index, 1)
          })
      }
    },
    sortByIndex (products) {
      return _.sortBy(products, 'index')
    }
  }
}
</script>
