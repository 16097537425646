<template>
  <span v-if="reference" class="large ? 'subtitle-1': ''">
    <sca-product-identity :value="reference" :company="company" :label="label" show-avatar />
  </span>
</template>

<script>
export default {
  name: 'ProductName',
  props: {
    company: { type: String, default: '' },
    large: { type: Boolean, default: false },
    name: { type: String, default: '' },
    qty: { type: Number, default: 0 },
    reference: { type: String, default: '' },
    showRef: { type: Boolean, default: false },
    unit: { type: String, default: '' }
  },
  data () {
    return {
    }
  },
  computed: {
    label () {
      return (this.showRef ? `[${this.reference}] ` : '') + `${this.name}` + (this.qty !== 0 ? ` | ${this.$t('Qty')} : ${this.qty} ${(this.unit !== '') ? this.unit : ''}` : '')
    }
  }
}
</script>
