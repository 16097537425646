<template>
  <v-row no-gutters>
    <v-col cols="12">
      <!-- <img class="ml-2 beta-icon" src="/img/beta.png" height="36px"> -->
      <v-tabs hide-slider v-model="tab">
        <v-tab v-if="canListSchedule" href="#tab-schedules">
          {{ $t('Schedules List (admin)') }}
        </v-tab>
        <v-tab v-if="canListUserTasks" href="#tab-user-tasks">
          {{ $t('Schedules Tasks') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="pa-3">
        <v-tab-item v-if="canListSchedule" value="tab-schedules" eager>
          <csm-schedule-list />
        </v-tab-item>

        <v-tab-item v-if="canListUserTasks" value="tab-user-tasks" eager>
          <csm-schedule-user-tasks />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    'csm-schedule-list': () => import(/* webpackChunkName: "views" */ './ScheduleList'),
    'csm-schedule-user-tasks': () => import(/* webpackChunkName: "views" */ './ScheduleUserTasks')
  },
  data () {
    return {
      tab: 'tab-schedules'
    }
  },
  computed: {
    canListSchedule () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.SCHEDULER, this.$alto.API_PERMISSIONS.LIST)
    },
    canListUserTasks () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.SCHEDULER_BY_TYPE, this.$alto.API_PERMISSIONS.LIST)
    }
  },
  async created () {
    await this.$store.dispatch('$stratus-states/getMe')
    const me = this.$store.getters['$stratus-states/me']
    if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

    // Preload instances in cache
    this.$store.dispatch('$alto-instances/list')
  }
}
</script>

<style scoped>
.beta-icon {
  position: absolute;
  z-index: 1000;
}
</style>
