<template>
  <v-row align="center">
    <v-col cols="12">
      <sca-advanced-store-grid :options="options" resource="carts" :columns="columns" :filters="filters" :custom-search="customSearch" @createItem="gotoCatalog" @deleteItem="deleteItem" @selectItems="selectItems" @showItem="showItem" @updateItem="updateItem" @resetFilters="resetFilters" ref="quotations-list-grid">
        <template slot="search-append">
          <v-row no-gutters align="center">
            <v-col v-show="companies.length > 1" class="ml-1">
              <sca-customer-select v-model="companiesSearch" :label="$t('Company')" @input="searchChange" clearable show-email show-phone link="emit" @link-click="openCompany(companiesSearch)" />
            </v-col>
            <v-col v-if="isLord" class="ml-2">
              <v-select v-model="filterBy" :items="filterQuotationsBy" :label="$t('Filter')" @change="searchChange" class="pt-1" />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item-customer="{ itemRaw }">
          <sca-company-identity :value="itemRaw" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(itemRaw)" />
        </template>

        <template v-slot:item-expired="{ item }">
          <v-icon v-if="item === 'true'" color="danger" small>
            $vuetify.icons.expired
          </v-icon>
          <span v-else />
        </template>
      </sca-advanced-store-grid>
      <cs-confirm-dialog ref="confirmCartDeletion" />
      <csm-company-dialog ref="company-dialog" />
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'

const FILTER_QUOTATIONS_ALL = 0
const FILTER_QUOTATIONS_BY_SCALAIR = 1
const FILTER_QUOTATIONS_BY_OTHER = 2

export default {
  name: 'QuotationsList',
  data () {
    return {
      ACTION: {
        CHOOSE: 0,
        DELETE: 10
      },
      actionCount: 0,
      companies: [],
      companiesCache: {},
      companiesSearch: '',
      filterBy: 0,
      filterQuotationsBy: [],
      filters: ['id', 'customer', 'name', 'create_at', 'createdBy'],
      isLogged: false,
      options: {
        allowColumnsVisible: true,
        allowContextMenuOnCell: true,
        create: false,
        customShowItem: true,
        customUpdateItem: true,
        delete: false,
        key: 'id',
        multiSelect: false,
        notifyErrors: false,
        searchVisibleColumns: false, // we need to filter columns that can be hidden
        show: true,
        sortBy: 'updatedAt',
        sortDescending: true,
        update: false
      },
      spheres: [],
      selection: [],
      selectionAction: 0,
      selectionActions: []
    }
  },
  computed: {
    canReadProjects () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PROJECTS, this.$alto.API_PERMISSIONS.LIST)
    },
    columns () {
      return [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Company',
        value: 'customer'
      }, {
        text: 'Sphere',
        value: 'id_sphere',
        hidden: true,
        format: (value) => {
          const sphere = _.find(this.spheres, { id: value })
          return sphere ? sphere.name : ''
        }
      }, {
        text: 'Expires on',
        value: 'expiration_date',
        hidden: true,
        format: (value, col, row) => {
          return row.expired ? this.$t('Out-of-date') : this.$stratus.dt(value).format('ll LT')
        }
      }, {
        text: 'Out-of-date',
        value: 'expired',
        align: 'center',
        alignValue: 'center',
        hidden: true
      }, {
        text: 'Total with discount',
        value: 'total_discount',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.CURRENCY
      }, {
        text: 'Total setup with discount',
        value: 'total_setup_discount',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.CURRENCY
      }, {
        text: 'Updated at',
        value: 'updatedAt',
        format: this.$stratus.services.fieldRenderers.DATETIME_SHORT
      }, {
        text: 'Created by',
        value: 'createdBy',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.FALSY_AS_EMPTY
      }]
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] }
  },
  methods: {
    actionRun () {
      this.actionCount = this.selection.length
      switch (this.selectionAction) {
        case this.ACTION.DELETE:
          this.$refs.confirmCartDeletion
            .open(this.$t('Delete quotations'), this.$t('Confirm deletion of quotations?'), { color: 'red' })
            .then((confirm) => {
              if (confirm) {
                try {
                  _.forEach(this.selection, async item => {
                    await this.$store.dispatch('carts/delete', item.id)
                    this.actionCount--
                  })
                } catch (error) {
                  this.$stratus.services.notify.error(error)
                }
                setTimeout(() => {
                  this.selection = []
                  this.refresh()
                }, 1000)
              }
            })
          break
      }
    },
    closeDialog () {
      this.visible = false
      this.refresh()
    },
    searchChange () {
      this.refresh()
    },
    customSearch () {
      const cusSearch = []
      if (this.companiesSearch) {
        cusSearch.push({
          column: 'customer',
          search: this.companiesSearch
        })
      }
      if (this.filterBy === FILTER_QUOTATIONS_BY_SCALAIR) {
        cusSearch.push({
          column: 'createdBy',
          operator: 'rg',
          search: '@scalair.fr'
        })
      } else if (this.filterBy === FILTER_QUOTATIONS_BY_OTHER) {
        cusSearch.push({
          column: 'createdBy',
          operator: 'nrg',
          search: '@scalair.fr'
        })
      }
      return cusSearch
    },
    deleteItem ({ success, error, item }) {
      if (item?.id) {
        const currentCart = this.$store.getters['carts/current']
        if (currentCart?.id === item.id) {
          // Clear current cart as we just delete it!
          if (this.$root['global-cart-menu']) {
            this.$root['global-cart-menu'].clearCart()
            this.$root['global-cart-menu'].refreshList()
          }
        }
      }
      this.refresh()
    },
    async gotoCatalog () {
      await this.$router.push({ name: 'catalog' }).catch((error) => { console.warn(error) })
    },
    async loadProjects () {
      if (!this.canReadProjects) return

      try {
        if (!this.isLord) this.spheres = await this.$store.dispatch('$alto-spheres/loadForCompany', this.myCompany)
        else this.spheres = await this.$store.dispatch('$alto-spheres/list')
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    refresh () {
      if (this.$refs['quotations-list-grid']) this.$refs['quotations-list-grid'].fetchData()
    },
    resetFilters () {
      this.companiesSearch = ''
      this.filterBy = ''
    },
    selectItems (items) {
      this.selection = items
    },
    showItem (data) {
      if (!this.$root['cart-menu-quotation-dialog']) return
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.$root['cart-menu-quotation-dialog'].load(data.item.id, (cart) => {
        this.refresh()
      })
    },
    updateItem (data) {
      if (!this.$root['cart-menu-quotation-dialog']) return
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.$root['cart-menu-quotation-dialog'].load(data.item.id, (cart) => {
        this.refresh()
      })
    }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Quotations'))
    this.selectionActions = [
      { text: this.$t('Delete'), value: this.ACTION.DELETE }
    ]
  },
  async mounted () {
    try {
      this.isLogged = this.$stratus.services.auth.isLogged()
      await this.$store.dispatch('$stratus-states/getMe')
      const me = this.$store.getters['$stratus-states/me']
      if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

      // this.options.create = false // User MUST use the cart-menu button in application header bar. This is the only way to refresh on screen data.
      this.options.create = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST) && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.CREATE)
      this.options.update = false
      this.options.show = this.isLogged && !this.options.update
      this.options.delete = false
      this.options.search = this.isLogged

      await this.$store.dispatch('$alto-companies/list')
      this.companiesCache = this.$store.getters['$alto-companies/cache']()
      this.companies = this.$stratus.services.fields.ObjectToSelectItems(this.companiesCache, { keyInValue: true })
      this.loadProjects()

      this.filterQuotationsBy = [
        { value: FILTER_QUOTATIONS_ALL, text: this.$t('All') },
        { value: FILTER_QUOTATIONS_BY_SCALAIR, text: this.$t('Created by Scalair') },
        { value: FILTER_QUOTATIONS_BY_OTHER, text: this.$t('Created independently') }
      ]
    } catch (error) {
      this.$stratus.services.notify.error(error)
    }
  }
}
</script>
