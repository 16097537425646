<template>
  <div class="background-blurred">
    <sca-month-filters :default="DEFAULT_RANGE" @change="onInputChangeDebounced" />

    <v-row no-gutters>
      <v-col cols="12" md="6" lg="2" class="text-center">
        <div>
          <cs-helper>
            <div slot="content" class="d-flex align-center text-h5 primary--text">
              {{ $t('Average response time') }}
            </div>
            <div slot="help">
              {{ $t('Average time over the period of completed requests between the date of creation and the date of transition to “completed” status.') }}
            </div>
          </cs-helper>
        </div>
        <cs-icon-loading v-if="loadingAnswerTime" small />
        <v-chip v-if="answerTime?.total" large class="ml-2 color3 text-h4 mt-8" @click="showTicketList(null, $t('Average response time'), answerTime?.total)">
          {{ $stratus.services.format.secondsToDays(answerTime.answer_time_minute_average * 60) }}
        </v-chip>
        <div v-else class="text-center pt-4">
          {{ $t(loadingAnswerTime ? 'Loading...' : 'No data...') }}
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="3" class="text-center">
        <div class="text-h5 text-center primary--text">
          {{ $t('Time spent on requests processed') }}
          <cs-icon-loading v-if="loadingPassedTime" small />
          <div v-else class="ml-2 text-body-1">
            ({{ $t('Total') }} {{ $stratus.services.format.secondsToDays(passedTime?.total_passed_time * 60) }})
          </div>
        </div>

        <cs-chart-donut v-show="!loadingPassedTime" ref="chart-passed-time" class="chart-max-height" :styles="chartStylesPassedTime" :options="chartOptionsPassedTime" :chart-data="passedTimeChartData" />
        <div v-show="loadingPassedTime" class="text-center pt-4">
          {{ $t(loadingPassedTime ? 'Loading...' : 'No data...') }}
        </div>
      </v-col>

      <v-col cols="12" lg="7" class="text-center">
        <div class="text-h5 primary--text">
          {{ $t('Number of requests by month') }}
          <cs-icon-loading v-if="loadingByMonthByUser" small />
        </div>

        <cs-chart-bar v-show="ticketByMonthByUser?.total" :chart-data="ticketByMonthByUserChartData" :options="chartOptionsByMonthByUser" :styles="chartStylesByMonthByUser" ref="by-month-by-user-chart" />
        <div v-show="!ticketByMonthByUser?.total" class="text-center pt-4">
          {{ $t(loadingPassedTime ? 'Loading...' : 'No data...') }}
        </div>
      </v-col>

      <v-col cols="12">
        <div class="text-h5 primary--text">
          {{ $t('Forecast of requests by week') }}
          <cs-icon-loading v-if="loadingEstimatedPerWeek" small />
        </div>

        <v-row no-gutters>
          <v-col cols="12" :lg="tickets?.length ? '8' : ''">
            <cs-chart-bar v-show="estimatedPerWeek?.total" :chart-data="estimatedPerWeekChartData" :options="chartOptionsEstimatedWeek" :styles="chartStylesEstimatedTime" ref="estimated-per-week-chart" />
            <div v-show="!estimatedPerWeek?.total" class="text-center pt-4">
              {{ $t(loadingPassedTime ? 'Loading...' : 'No data...') }}
            </div>
          </v-col>

          <v-col v-show="tickets?.length" cols="12" :lg="tickets?.length ? '4' : ''">
            <div class="text-h5 primary--text d-flex align-center mb-2">
              {{ $t('Tickets') }} <v-divider vertical class="mx-2" /> {{ ticketListLabel }}
              <div v-if="ticketPeriod" class="d-flex align-center">
                <v-divider v-if="ticketListLabel" vertical class="mx-2" />
                {{ $stratus.dt(ticketPeriod.begin).format('ll') }}
                <v-icon small color="primary" class="mx-1">
                  $vuetify.icons.for
                </v-icon>
                {{ $stratus.dt(ticketPeriod.end).format('ll') }}
              </div>

              <v-icon small class="ml-auto clickable" @click="showTicketList()">
                $vuetify.icons.close
              </v-icon>
            </div>
            <sca-ticket-list-panel :value="tickets" link="emit" @link-click="openTicket" dense show-state show-severity show-time />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <csm-ticket-dialog ref="ticket-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

const DEFAULT_CHART_DATA = {
  labels: [],
  datasets: [{
    backgroundColor: [],
    data: []
  }]
}
export default {
  name: 'PreSaleDashboard',
  data () {
    return {
      DEFAULT_RANGE: this.$alto.defines.DATES.RANGE_CURRENT_QUARTER,
      PASSED_TIME_PALETTE: this.$alto.defines.COLORS.PALETTE_SCALAIR_10,
      answerTime: null,
      colorByUser: {},
      colorIndexUser: 0,
      dateBeginMem: null,
      dateEndMem: null,
      estimatedPerWeek: null,
      estimatedPerWeekChartData: { ...DEFAULT_CHART_DATA },
      loadingAnswerTime: false,
      loadingByMonthByUser: false,
      loadingEstimatedPerWeek: false,
      loadingPassedTime: false,
      passedTime: null,
      passedTimeChartData: { ...DEFAULT_CHART_DATA },
      ticketByMonthByUser: null,
      ticketByMonthByUserChartData: { ...DEFAULT_CHART_DATA },
      ticketListLabel: '',
      ticketPeriod: {},
      tickets: []
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    chartOptionsByMonthByUser () {
      return {
        // Click on a bar to load it's opportunities
        onClick (e) {
          /* --- IMPORTANT! ---
           * This is a native click event, outside Vue scope!
           * Value of «this» is not vue but the chart instance this event is plug to.
           */
          const element = this.getElementAtEvent(e)
          if (element.length > 0) {
            // Create a focus effect by changing the background color of the bar
            // Reset all colors
            _.forEach(this.data.datasets, dataset => {
              dataset.backgroundColor = dataset._backgroundColor
            })
            // Set the focus
            const bc = new Array(this.data.datasets[element[0]._datasetIndex].data.length).fill(this.data.datasets[element[0]._datasetIndex]._backgroundColor) // Restore native color
            bc[element[0]._index] = this.data.datasets[element[0]._datasetIndex].borderColor
            this.data.datasets[element[0]._datasetIndex].backgroundColor = bc
            // Forge a query to load opportunities for this bar
            this.data.datasets[element[0]._datasetIndex].showTicketList(
              null,
              this.data.datasets[element[0]._datasetIndex].label,
              this.data.datasets[element[0]._datasetIndex].ticketIds?.[element[0]?._index],
              this.data.labels[element[0]._index]
            )
            this.update()
          }
        },
        // FIXME: if exception « TypeError: Cannot read properties of null (reading 'transition') » still occurs,
        // uncomment the following:
        // animation: {
        //   duration: 0
        // },
        // hover: {
        //   animationDuration: 0
        // },
        responsiveAnimationDuration: 0,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: false,
            gridLines: {
              display: true
            }
          }],
          yAxes: [{
            id: 'counter-axis',
            type: 'linear',
            ticks: {
              beginAtZero: true,
              min: 0,
              callback: function (value) {
                // Display only ticks for integer value
                if (value % 1 === 0) { return value }
              }
            }
          }]
        },
        tooltips: {
          enabled: true,
          intersect: false
        }
      }
    },
    chartOptionsEstimatedWeek () {
      return {
        // Click on a bar to load it's opportunities
        onClick (e) {
          /* --- IMPORTANT! ---
           * This is a native click event, outside Vue scope!
           * Value of «this» is not vue but the chart instance this event is plug to.
           */
          const element = this.getElementAtEvent(e)
          if (element.length > 0) {
            // Create a focus effect by changing the background color of the bar
            // Reset all colors
            _.forEach(this.data.datasets, dataset => {
              dataset.backgroundColor = [...dataset._backgroundColor]
            })
            // Set the focus
            this.data.datasets[element[0]._datasetIndex].backgroundColor[element[0]._index] = this.data.datasets[element[0]._datasetIndex].borderColor
            // Forge a query to load opportunities for this bar
            this.data.datasets[element[0]._datasetIndex].showTicketList(
              this.data.datasets[element[0]._datasetIndex].dates?.[element[0]?._index],
              null, // this.data.datasets[element[0]._datasetIndex].label,
              this.data.datasets[element[0]._datasetIndex].ticketIds?.[element[0]?._index]
            )
            this.update()
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: false
          }],
          yAxes: [{
            id: 'counter-axis',
            type: 'linear',
            ticks: {
              beginAtZero: true,
              min: 0,
              callback: function (value) {
                // Display only ticks for integer value
                if (value % 1 === 0) { return value }
              }
            }
          }]
        },
        tooltips: {
          enabled: true,
          intersect: false
        }
      }
    },
    chartOptionsPassedTime () {
      return {
        // Click on a part to load it's data
        onClick (e) {
          // FUTURE: click on donut part will show ticket list
          /* --- IMPORTANT! ---
           * This is a native click event, outside Vue scope!
           * Value of «this» is not vue but the chart instance this event is plugged to.
           */
          // const element = this.getElementAtEvent(e)
          // if (element.length > 0) {
          //   // Forge a query to load data for this part
          //   this.data.datasets[element[0]._datasetIndex].showTicketList(
          //     this.data.datasets[element[0]._datasetIndex].query[element[0]._index],
          //     this.data.caption + ' > ' + this.data.labels[element[0]._index],
          //     this.data.datasets[element[0]._datasetIndex].ticketIds[element[0]._index]
          //   )
          //   this.update()
          // }
        },
        responsive: true,
        animation: {
          animateRotate: true,
          animateScale: true
        },
        maintainAspectRatio: false,
        // FIXME: if exception « Cannot read property 'transition' of null » still occurs,
        // uncomment the following:
        // animation: {
        //     duration: 0
        // },
        // hover: {
        //     animationDuration: 0
        // },
        responsiveAnimationDuration: 0,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
          }
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltips: {
          enabled: true,
          intersect: false,
          callbacks: {
            label: (tooltipItems, data) => {
              return data.labels[tooltipItems.index]
            }
          }
        },
        circumference: 2 * Math.PI,
        rotation: -Math.PI / 2
      }
    },
    chartStylesEstimatedTime () {
      return {
        height: '300px',
        width: '100%',
        position: 'relative'
      }
    },
    chartStylesByMonthByUser () {
      return {
        height: '300px',
        width: '100%',
        position: 'relative'
      }
    },
    chartStylesPassedTime () {
      return {
        height: '240px',
        width: '100%',
        position: 'relative'
      }
    },
    months () {
      if (!this.ticketByMonthByUser?.per_month) return []
      return Object.keys(this.ticketByMonthByUser.per_month).sort()
    },
    weeks () {
      if (!this.estimatedPerWeek?.per_week) return []

      const weeks = []
      // Keep end date of weeks given by data from API
      _.forEach(this.estimatedPerWeek.per_week, week => {
        weeks.push(week.end)
      })
      return weeks
    }
  },
  methods: {
    addSector (chart, name, value, color, query, ticketIds) {
      const currentDataset = chart.datasets[0]
      chart.labels.push(name)
      currentDataset.data.push(value)
      // FUTURE currentDataset.ticketIds.push(ticketIds)
      currentDataset.backgroundColor.push(color)
      currentDataset._backgroundColor.push(color)
      currentDataset.borderWidth = 0
      // FUTURE currentDataset.showTicketList = this.showTicketList
    },
    async createGraphByMonthByUser () {
      const months = this.months.sort()
      const dataSets = []
      const dataPerUser = {}
      // const dates = []
      const labels = []
      const ticketPerUser = {} // per user

      if (this.ticketByMonthByUser?.per_month && months?.length > 0) {
        _.forEach(months, (month, index) => {
          const monthData = this.ticketByMonthByUser.per_month[month]
          labels.push(this.$stratus.dt(month + '-01').format('MM/YYYY'))

          _.forEach(monthData.by_user, (tickets, user) => {
            if (!dataPerUser[user]) dataPerUser[user] = _.fill(Array(months.length), 0) // Array with all months to display
            dataPerUser[user][index] = tickets?.length || 0
            if (!ticketPerUser[user]) ticketPerUser[user] = _.fill(Array(months.length), 0) // Array with all months to display
            ticketPerUser[user][index] = [...tickets] || []
          })
        })

        // create one serie for each user
        for (const userId in dataPerUser) { // This kind of for is needed to use await
          let user
          try {
            user = await this.$store.dispatch('$alto-users/getById', userId)
          } catch (error) {
            console.warn('[pre-sale-dashboard]', error)
          }

          if (!this.colorByUser[userId]) {
            this.colorByUser[userId] = this.$alto.defines.COLORS.PALETTE_SCALAIR_10[this.colorIndexUser].opacity80
            this.colorIndexUser++
            if (this.colorIndexUser >= this.$alto.defines.COLORS.PALETTE_SCALAIR_10.length) this.colorIndexUser = 0
          }

          dataSets.push({
            label: user ? `${user.firstname} ${user.lastname}` : this.$t('Unknown'),
            backgroundColor: this.colorByUser[userId],
            _backgroundColor: this.colorByUser[userId],
            borderColor: this.$alto.defines.COLORS.PRIMARY,
            hoverBorderColor: this.$alto.defines.COLORS.PRIMARY,
            borderWidth: 0,
            data: dataPerUser[userId],
            ticketIds: ticketPerUser[userId],
            showTicketList: this.showTicketList
          })
        }
      }

      this.ticketByMonthByUserChartData.labels = labels
      this.ticketByMonthByUserChartData.datasets = dataSets

      this.$nextTick(() => {
        if (this.$refs['by-month-by-user-chart']) this.$refs['by-month-by-user-chart'].update()
      })
    },
    createGraphEstimatedPerWeek () {
      const weeks = this.weeks.sort()
      const dataSets = []
      const data = []
      const dates = []
      const labels = []
      const ticketIds = []

      const backgroundColors = []
      const todayWeek = this.$stratus.dt().week()

      if (this.estimatedPerWeek && weeks?.length > 0) {
        _.forEach(weeks, (week, index) => {
          const weekData = _.find(this.estimatedPerWeek.per_week, { end: week })
          const weekNum = this.$stratus.dt(week).week()
          const weekTick = this.$stratus.dt(week).startOf('week').format(this.$stratus.defines.i18n.current.monthDayShortFormat)

          if (weekData && this.$stratus.dt(week).isValid()) {
            labels.push(todayWeek === weekNum ? `${weekTick} *` : weekTick)
            data.push(weekData.total?.length || 0)
            dates.push({ begin: weekData.begin, end: weekData.end })
            ticketIds.push(weekData.total || [])
            backgroundColors.push(todayWeek === weekNum ? this.$alto.defines.COLORS.COLOR2 : this.$alto.defines.TICKETS.TICKET_TYPE_PRE_SALE_COLOR.html)
          }
        })

        dataSets.push({
          label: this.$t('Number of tickets'),
          backgroundColor: backgroundColors,
          _backgroundColor: [...backgroundColors],
          borderColor: this.$alto.defines.COLORS.PRIMARY,
          hoverBorderColor: this.$alto.defines.COLORS.PRIMARY, // Used to highlight clicked element
          borderWidth: 0,
          data,
          ticketIds,
          dates,
          showTicketList: this.showTicketList
        })
      }
      this.estimatedPerWeekChartData.labels = labels
      this.estimatedPerWeekChartData.datasets = dataSets

      this.$nextTick(() => {
        if (this.$refs['estimated-per-week-chart']) this.$refs['estimated-per-week-chart'].update()
      })
    },
    createGraphPassedTime () {
      this.resetChartPassedTime()

      if (this.passedTime?.by_user) {
        _.forEach(this.passedTime.by_user, async (data, ticketOwner) => {
          let userName

          if (ticketOwner) {
            const user = { ...await this.$store.dispatch('$alto-users/getById', ticketOwner) }
            if (user) {
              userName = `${user.firstname} ${user.lastname}`
            }
          } else userName = this.$t('Unallocated')

          if (!this.colorByUser[ticketOwner]) {
            this.colorByUser[ticketOwner] = this.$alto.defines.COLORS.PALETTE_SCALAIR_10[this.colorIndexUser].opacity80
            this.colorIndexUser++
            if (this.colorIndexUser >= this.$alto.defines.COLORS.PALETTE_SCALAIR_10.length) this.colorIndexUser = 0
          }

          // Only keep the users that do have some time processing tickets
          if (data.per_total > 0) {
            this.addSector(
              this.passedTimeChartData,
               `${userName} : ${this.$stratus.services.format.secondsToDays(data.passed_time * 60) || '-'}`,
               data.per_total,
               this.colorByUser[ticketOwner],
               null,
               null)
          }
        })
      }

      this.$nextTick(() => {
        // Be sure to have DOM node of chart visible!
        setTimeout(() => {
          if (this.$refs['chart-passed-time']) this.$refs['chart-passed-time'].update()
        }, 1000)
      })
    },
    onInputChange ({ dateBegin, dateEnd } = {}) {
      if (!this.$stratus.dt(dateBegin).isValid() || !this.$stratus.dt(dateEnd).isValid()) return

      this.refresh({ dateBegin, dateEnd })
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticket.id)
    },
    async refresh ({ dateBegin, dateEnd } = {}) {
      if (!dateBegin) dateBegin = this.dateBeginMem
      if (!dateEnd) dateEnd = this.dateEndMem

      if (!this.$stratus.dt(dateBegin).isValid() || !this.$stratus.dt(dateEnd).isValid()) return

      try {
        this.dateBeginMem = dateBegin
        this.dateEndMem = dateEnd
        this.loadingAnswerTime = true
        this.loadingByMonthByUser = true
        this.loadingEstimatedPerWeek = true
        this.loadingPassedTime = true
        this.colorIndexUser = 0

        // Calling API asynchronously, these requests need some time to return data.
        this.$store.dispatch('$alto-ticketing/ticketAnalysisPreSaleAnswerTime', {
          dateBegin: this.$stratus.dt(dateBegin).startOf('month'),
          dateEnd: this.$stratus.dt(dateEnd).endOf('month')
        })
          .then(response => {
            this.answerTime = response
          })
          .catch(error => {
            this.$stratus.services.notify.error(error)
          })
          .finally(() => {
            setTimeout(() => { this.loadingAnswerTime = false }, 250)
          })

        this.$store.dispatch('$alto-ticketing/ticketAnalysisPreSaleEstimatedPerWeek', {
          dateBegin: this.$stratus.dt(dateBegin).startOf('month'),
          dateEnd: this.$stratus.dt(dateEnd).endOf('month')
        })
          .then(response => {
            this.estimatedPerWeek = response
            this.createGraphEstimatedPerWeek()
          })
          .catch(error => {
            this.$stratus.services.notify.error(error)
          })
          .finally(() => {
            setTimeout(() => { this.loadingEstimatedPerWeek = false }, 250)
          })

        this.$store.dispatch('$alto-ticketing/ticketAnalysisPreSaleByUser', {
          dateBegin: this.$stratus.dt(dateBegin).startOf('month'),
          dateEnd: this.$stratus.dt(dateEnd).endOf('month')
        })
          .then(response => {
            this.ticketByMonthByUser = response
            this.createGraphByMonthByUser()
          })
          .catch(error => {
            this.$stratus.services.notify.error(error)
          })
          .finally(() => {
            setTimeout(() => { this.loadingByMonthByUser = false }, 250)
          })

        this.passedTime = await this.$store.dispatch('$alto-ticketing/ticketAnalysisPreSalePassedTime', {
          dateBegin: this.$stratus.dt(dateBegin).startOf('month'),
          dateEnd: this.$stratus.dt(dateEnd).endOf('month')
        })
        this.$nextTick(() => this.createGraphPassedTime())
        setTimeout(() => { this.loadingPassedTime = false }, 1000)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    refreshGraph () {
      if (this.$refs['by-month-by-user-chart']) this.resetChartColors(this.$refs['by-month-by-user-chart'])
      if (this.$refs['estimated-per-week-chart']) this.resetChartColors(this.$refs['estimated-per-week-chart'])
      if (this.$refs['chart-passed-time']) this.resetChartColors(this.$refs['chart-passed-time'])
    },
    resetChartColors (chart) {
      _.forEach(chart.chartData.datasets, dataset => {
        dataset.backgroundColor = dataset._backgroundColor
      })
      if (chart) chart.update()
    },
    resetChartPassedTime () {
      this.$set(this.passedTimeChartData, 'labels', [])
      this.$set(this.passedTimeChartData, 'datasets', [{
        backgroundColor: [],
        _backgroundColor: [],
        data: [],
        nbOpportunities: [],
        query: [],
        ticketIds: []
      }])
    },
    showTicketList (dates, label, ticketIds, serieLabel) {
      this.ticketPeriod = dates || null
      this.ticketListLabel = label || ''
      if (serieLabel) this.ticketListLabel += ' — ' + serieLabel || ''
      this.tickets = ticketIds || []
      if (!this.tickets.length) {
        this.refreshGraph()
      }
    }
  },
  created () {
    this.onInputChangeDebounced = _.debounce(this.onInputChange, 1000)
  }
}
</script>
