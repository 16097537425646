<template>
  <sca-modal-dialog :visible="visible" :loading="loading || isSaving" :can-save="canSave" :is-saving="isSaving" max-width="80%" @closeDialog="closeDialog" @saveDialog="saveDialog">
    <template #title>
      {{ $t(`ticket-type-${ticketType}`) }} — {{ $t('Create a global ticket') }}
    </template>

    <div slot="content">
      <div class="d-flex justify-center mb-2">
        <cs-alert-panel v-if="ticketType === TICKET_TYPE_INCIDENT" type="info" :text="$t('A global incident will create an incident on the Scalair company, and then an incident on each selected company. The incidents will be linked together so that any publication or modification on the global incident will reflect on the incidents on each dedicated company.')" />
        <cs-alert-panel v-else type="info" :text="$t('A global ticket will create a ticket on the Scalair company, and then a ticket on each selected company.')" />
      </div>

      <v-form ref="ticket-global-form" v-model="valid" lazy-validation :disabled="!canSave">
        <v-row no-gutters>
          <v-col cols="12" md="6" lg="8" class="pr-4">
            <v-row dense align="center">
              <v-col class="shrink">
                <sca-ticket-type-select v-model="ticketType" :label="$t('Type')" dense :types="ticketTypesList" hide-details />
              </v-col>

              <v-col>
                <v-text-field v-model="name" :label="$t('Title')" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.max(128)]" class="text-h6 required" counter="128" />
              </v-col>

              <v-col cols="12" md="6" lg="3">
                <sca-ticket-severity-select v-model="severity" :label="$t('Severity')" :disabled="!isLord" :rules="[$stratus.services.form.rules.required]" class="required" />
              </v-col>
            </v-row>

            <v-row dense align="center">
              <v-col cols="12" md="4" lg="2">
                <cs-date-picker v-model="estimatedDate" :label="$t('Estimated delivery date')" allow-empty format-short :disabled="!canSave" />
              </v-col>

              <v-col v-if="ticketType === TICKET_TYPE_FACT" cols="12" md="4" lg="2">
                <cs-date-picker v-model="interventionDate" :label="$t('Intervention date')" format-short :disabled="!canSave" :rules="[$stratus.services.form.rules.required]" class="required" />
              </v-col>

              <v-col>
                <v-switch v-model="addTechnicalManagers" :label="$t('Add the technical referents of the companies affected to the ticket follow-up')" allow-empty dense hide-details class="ma-0" />
              </v-col>
            </v-row>

            <div>
              <div class="text-subtitle-1">
                {{ $t('Description') }}
              </div>

              <sca-wysiwyg-editor v-model="description" ref="global-ticket-description-editor" :placeholder="$t('Description')" :customers="companies" medium :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" :disabled="!canSave" highlight @drop-paste-image="onDropPasteImage" />
            </div>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <div class="d-flex align-start">
              <span class="text-subtitle-1">
                {{ $t('Companies') }}
              </span>

              <v-switch v-model="allCompanies" :label="$t('All')" hide-details dense class="ml-auto ma-0" @change="onSelectCompanyAll" />
            </div>

            <v-row class="d-flex align-center mb-4" dense>
              <v-col cols="11">
                <sca-customer-select ref="ticket-global-customers" v-model="selectedCompanies" :exclude="excludedCompanies" dense customers-only hide-details multiple :disabled="!canSave || allCompanies" />
              </v-col>

              <v-col cols="1" class="shrink">
                <v-btn rounded icon :disabled="selectedCompanies.length === 0 || !canSave || allCompanies" @click="addCompany(selectedCompanies)">
                  <v-icon>
                    $vuetify.icons.add
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-card outlined tile class="px-1">
              <div v-show="companies.length" class="d-flex align-center">
                <span class="required text-h5">
                  {{ $t('Impacted companies') }}
                  <v-chip small v-show="companies.length">
                    {{ companies.length }}
                  </v-chip>
                </span>

                <v-btn small text rounded color="danger" class="ml-auto" :disabled="companies.length === 0 || !canSave" @click="removeAllCompany">
                  <v-icon small left color="danger">
                    $vuetify.icons.delete
                  </v-icon>
                  {{ $t('Remove all') }}
                </v-btn>
              </div>

              <div v-if="companies.length === 0" class="text-center mt-8">
                {{ $t('None') }}
                <p class="text-caption mt-4">
                  {{ $t('Please, choose one or more customers from the selection above to add them to this list.') }}
                </p>
              </div>

              <v-virtual-scroll v-else :bench="1" :items="sortedCompanies" height="420" item-height="40">
                <template v-slot:default="{ item }">
                  <div :key="item" class="d-flex align-center">
                    <div class="text-truncate mr-2">
                      <sca-company-identity :value="item" font-fixed no-icon show-avatar show-email show-phone show-sales-person show-card />
                    </div>

                    <v-icon small class="danger--text ml-auto mr-2" @click="removeCompany(item)">
                      $vuetify.icons.delete
                    </v-icon>
                  </div>

                  <v-divider />
                </template>
              </v-virtual-scroll>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import config from '@/config'

const CORP_CODE = config.defaults.corporationCode
const CLOSE_NOTIFICATION_DELAY = 30000 // 30 seconds

export default {
  name: 'TicketGlobal',
  props: {
    visible: { type: Boolean, default: false }
  },
  data () {
    return {
      CORP_CODE,
      DESCRIPTION_MAX_LENGTH: 131072, // 128KB
      DESCRIPTION_MIN_LENGTH: 0,
      TICKET_TYPE_FACT: this.$alto.defines.TICKETS.TICKET_TYPE_FACT,
      TICKET_TYPE_INCIDENT: this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT,
      addTechnicalManagers: false,
      allCompanies: false,
      companies: [],
      description: '',
      estimatedDate: null,
      interventionDate: null,
      isSaving: false,
      loading: false,
      name: '',
      selectedCompanies: [],
      severity: this.$alto.defines.TICKETS.TICKET_SEVERITY_NONE,
      ticketType: this.$alto.defines.TICKETS.TICKET_TYPE_FACT,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.TICKET_GLOBAL_TICKET)
    },
    excludedCompanies () {
      return [CORP_CODE].concat(this.companies)
    },
    sortedCompanies () {
      return _.chain(this.companies).sortBy().value()
    },
    ticketTypesList () {
      return [this.TICKET_TYPE_FACT, this.TICKET_TYPE_INCIDENT]
    }
  },
  methods: {
    addCompany (company) {
      const list = Array.isArray(company) ? company : [company]

      _.forEach(list, code => {
        if (!this.companies.includes(code)) {
          this.companies.push(code)
        }
      })

      this.selectedCompanies = []
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    async onDropPasteImage () {
      return false // Do not allow pasting image
    },
    onSelectCompanyAll () {
      if (this.allCompanies) {
        this.removeAllCompany()
        this.companies = this.$refs['ticket-global-customers'].getAll()
      } else {
        this.removeAllCompany()
      }
    },
    removeAllCompany () {
      this.companies = []
      this.allCompanies = false
    },
    removeCompany (code) {
      const index = this.companies.indexOf(code)
      if (index >= 0) {
        this.companies.splice(index, 1)
        this.allCompanies = false
      }
    },
    reset () {
      this.allCompanies = false
      this.companies = []
      this.description = ''
      this.isSaving = false
      this.loading = false
      this.name = ''
      this.estimatedDate = null
      this.interventionDate = null
      this.selectedCompanies = []
      this.addTechnicalManagers = false
      this.severity = this.$alto.defines.TICKETS.TICKET_SEVERITY_NONE
      this.valid = false
    },
    async saveDialog (closeDialog = true) {
      if (!this.canSave) return false

      if (this.selectedCompanies?.length) {
        this.addCompany(this.selectedCompanies)
      }

      this.isSaving = true

      if (!this.$refs['ticket-global-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return false
      }

      try {
        // Sanitize
        const ticket = {
          name: this.$stratus.services.strings.stripHtmlTags(this.name),
          severity: this.severity,
          companies: this.companies,
          estimated_date: this.estimatedDate,
          add_technical_managers: this.addTechnicalManagers
        }

        if (this.$refs['global-ticket-description-editor']) {
          ticket.description = this.$refs['global-ticket-description-editor'].getContent()
        } else {
          ticket.description = ''
        }

        if (this.ticketType === this.TICKET_TYPE_FACT) {
          ticket.date_intervention = this.interventionDate
        }

        let action = 'createGlobalFact'
        if (this.ticketType === this.TICKET_TYPE_INCIDENT) action = 'createGlobalIncident'

        const savedTicket = await this.$store.dispatch(`$alto-ticketing/${action}`, ticket)
        this.$stratus.services.notify.success(this.$t('Ticket {name} created.', ticket), { duration: CLOSE_NOTIFICATION_DELAY, tickets: [savedTicket] })

        if (closeDialog) this.closeDialog()
        this.isSaving = false
        return savedTicket
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.isSaving = false
        return false
      }
    }
  }
}
</script>
