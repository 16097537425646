<template>
  <div>
    <v-row v-show="showOpportunityList" align="center" justify="space-between">
      <v-col class="shrink text-no-wrap">
        <span class="text-h6 mr-4">
          {{ $t('Opportunities') }} {{ $t(`opportunities-${category}`) }}
        </span>
        <span v-show="$stratus.dt(month).isValid()" class="mr-4">
          ({{ $stratus.dt(month).format('MMMM YYYY') }})
        </span>
        <sca-user-identity v-if="salesPerson" :value="salesPerson" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(salesPerson)" />
      </v-col>

      <v-col class="shrink text-right">
        <v-btn rounded class="main-button" @click="showOpportunityList = false">
          <v-icon left>
            $vuetify.icons.prev
          </v-icon>
          {{ $t('Return to dashboard') }}
        </v-btn>
      </v-col>

      <v-col cols="12">
        <csm-opportunity-grid :query="query" :options="opportunityGridOptions" ref="opportunity-dashboard-grid" />
      </v-col>
    </v-row>

    <v-row v-show="!showOpportunityList" class="background-blurred">
      <v-col cols="12">
        <sca-opportunities-month-dashboard :date-begin="dateBegin" :date-end="dateEnd" :threshold-max="thresholdMax" :threshold-med="thresholdMed" :threshold-min="thresholdMin" input-period reverse-order user-list @query="listOpportunities" @user-click="openUser" />
      </v-col>
    </v-row>
    <csm-user-dialog ref="user-dialog" />
  </div>
</template>

<script>
import config from '../../config'

export default {
  name: 'OpportunitiesGauges',
  components: {
    'csm-opportunity-grid': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/OpportunitiesGrid')
  },
  data () {
    return {
      dateBegin: null,
      category: '',
      dateEnd: null,
      companiesCache: {},
      loadingCart: false,
      loadingOrder: false,
      month: '',
      opportunityGridOptions: {
        // disable advanced search to avoid collision with forged query from computeQuery
        allowAdvancedSearch: false
      },
      query: '',
      salesPerson: '',
      salesPersonId: '',
      showOpportunityList: false
    }
  },
  computed: {
    thresholdMax () { return config.opportunities.dashboard.THRESHOLD_VALUE_MAX },
    thresholdMed () { return config.opportunities.dashboard.THRESHOLD_PERCENT_MED },
    thresholdMin () { return config.opportunities.dashboard.THRESHOLD_PERCENT_MIN }
  },
  async mounted () {
    this.loadCompanies()
    await this.$store.dispatch('$alto-users/loadSalesPersons')
    this.dateBegin = this.$stratus.dt().startOf('month').format('YYYY-MM')
    this.dateEnd = this.$stratus.dt().endOf('month').format('YYYY-MM')
  },
  methods: {
    async listOpportunities ({ month, category, salesPersonId, salesPerson, query }) {
      try {
        this.showOpportunityList = true
        this.$nextTick(() => {
          this.month = month
          this.category = category
          this.salesPersonId = salesPersonId
          this.salesPerson = salesPerson
          this.query = query
          this.refreshGrid()
        })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    loadCompanies () {
      this.$store.dispatch('$alto-companies/list')
        .then(() => {
          this.companiesCache = this.$store.getters['$alto-companies/cache']()
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    openUser (user) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(user.id || user)
    },
    refreshGrid () {
      if (this.$refs['opportunity-dashboard-grid']) this.$refs['opportunity-dashboard-grid'].refreshGrid()
    }
  }
}
</script>
