<template>
  <div>
    <v-tabs slot="tabs" color="transparent" v-model="tab" hide-slider show-arrows>
      <cs-validation-tab href="#tab-order" icon="$vuetify.icon.product" :label="$t('Product')" />
      <cs-validation-tab v-show="orderDescription" href="#tab-description" icon="$vuetify.icon.details" :label="$t('Details')" />
      <cs-validation-tab v-if="isLord && canReadOpportunities" href="#tab-opportunity" icon="$vuetify.icons.opportunity" :label="$t('Price trends')" :loading="opportunityLoading" @change="loadPriceTrends" />
      <cs-validation-tab v-if="orderInternal.history && orderInternal.history.length" href="#tab-history" icon="$vuetify.icons.history" :label="$t('History')" />
      <cs-validation-tab v-if="orderInternal.files && orderInternal.files.length > 0" href="#tab-documents" icon="$vuetify.icon.documents" :label="$t('Documents')" :count="orderInternal?.files.length" />
    </v-tabs>

    <v-row>
      <v-col md="12">
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-order" eager>
            <v-row v-if="orderInternal.products">
              <v-col cols="12">
                <v-data-table :items="orderLines" :headers="headers" :loading="loading" must-sort :page="pagination.page" :items-per-page.sync="pagination.rowsPerPage" hide-default-footer>
                  <template v-slot:header="{}">
                    <thead>
                      <tr>
                        <th :colspan="headers.length">
                          <div class="d-flex align-center">
                            <div v-if="isLord">
                              <v-switch v-model="filterCanDeliverProducts" :label="$t('Show only products to be delivered.')" :disabled="loading" />
                            </div>

                            <div v-if="productsToDeliver.length > 0 && canValidateDeposit" class="ml-auto d-inline-flex">
                              <cs-alert-panel dense type="warning" class="mr-4" :text="$t('This order is waiting for paid deposit.')" /> />
                              <v-btn v-if="isLord" rounded class="main-button" :loading="isDepositValidating" @click="validateDeposit">
                                {{ $t('Validate deposit') }}
                              </v-btn>
                            </div>

                            <div v-else-if="productsToDeliver.length > 0" class="ml-auto d-inline-flex">
                              <cs-alert-panel v-if="productsNeedCaption > 0" dense type="warning" class="mr-4" :text="$tc('One product needs a caption.|{count} products need a caption.', productsNeedCaption, { count: productsNeedCaption })" />
                              <v-btn rounded class="main-button" :loading="isDelivering" @click="deliverRemainingProducts" :disabled="productsNeedCaption !== 0">
                                {{ $tc('Deliver the remaining product...|Deliver remaining {count} products...', productsToDeliver.length, { count: productsToDeliver.length }) }}
                              </v-btn>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                  </template>

                  <template v-slot:item="{ item, index }">
                    <tr v-show="filterCanDeliverProducts && !canDeliver(item, false)">
                      <td colspan="4" class="table-separator" />
                    </tr>

                    <tr v-show="!filterCanDeliverProducts || canDeliver(item, false)">
                      <td class="text-subtitle-1">
                        {{ index + 1 }}
                      </td>

                      <td style="width: 50%;">
                        <div class="d-flex align-center">
                          <div class="text-subtitle-1 font-weight-bold shrink text-no-wrap">
                            {{ item.name }}
                          </div>

                          <div v-if="canOrderSameProduct(item)" class="ml-4">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn v-on="on" small icon :loading="orderingSameProduct === item.id" :disabled="orderingSameProduct > 0" @click="orderSameProduct(item)">
                                  <v-icon small color="menu-icon">
                                    $vuetify.icons.cart
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('Order same product') }} ({{ currentCart.name ? currentCart.name: $t('New cart') }})</span>
                            </v-tooltip>
                          </div>

                          <div v-if="isLord && item.main && item.main.length" class="ml-4">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn v-on="on" small icon @click="expandedToggle(index)">
                                  <v-icon :class="expanded[index] ? 'primary--text' : 'menu-icon--text'" small>
                                    $vuetify.icons.discount
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('Sub-product discount') }}</span>
                            </v-tooltip>
                          </div>

                          <div v-if="isLord && item.ref" class="ml-4">
                            <sca-product-identity :value="item.ref" :company="currentCartCustomer" :label="item.ref" show-avatar link="emit" @link-click="openProduct(item.ref)" />
                          </div>

                          <div v-if="isLord && item.ref">
                            <cs-clipboard-button :text="item.ref" link icon dense />
                          </div>
                        </div>

                        <div>
                          <span v-if="item.date_begin">
                            {{ $t('Begin: {date}', { date: $stratus.dt(item.date_begin).format('LL') || $t('Undefined') }) }}
                          </span>
                          <span v-if="item.date_end">
                            <v-icon small class="px-4">
                              icon-long-arrow-right
                            </v-icon>
                            {{ $t('End: {date}', { date: $stratus.dt(item.date_end).format('LL') || $t('Undefined') }) }}
                          </span>

                          <v-row align="baseline" no-gutters>
                            <v-col>
                              <v-text-field v-model="item.caption" :label="$t('Caption')" :rules="captionRules(item)" counter="128" @input="onCaptionChange(item)" :disabled="!isLord" />
                            </v-col>

                            <v-col class="shrink" v-if="isLord">
                              <v-btn small rounded :loading="applyingCaption" @click="applyCaption(item)">
                                {{ $t('Apply') }}
                              </v-btn>
                            </v-col>
                          </v-row>

                          <v-row align="baseline" no-gutters v-if="canModify(item)">
                            <v-col>
                              <v-text-field v-model="item.customer_caption" :label="$t('Customer caption')" :rules="[$stratus.services.form.rules.max(128)]" counter="128" @input="delayedApplyCustomerCaption(item)" />
                            </v-col>

                            <v-col class="shrink">
                              <v-btn small rounded :loading="applyingCustomerCaption" @click="applyCustomerCaption(item)">
                                {{ $t('Apply') }}
                              </v-btn>
                            </v-col>
                          </v-row>

                          <v-row no-gutters>
                            <v-slide-y-transition>
                              <v-col v-if="expanded[index] && isLord" cols="12">
                                <csm-product-sub-discount :product="item" :customer="item.code" readonly />
                              </v-col>
                            </v-slide-y-transition>
                          </v-row>

                          <cs-expand-list v-if="isLord && item.opportunity_id && item.opportunity_id.length > 0" :items="item.opportunity_id" row>
                            <template v-slot:expand-list-item="{ item: opportunity }">
                              <sca-opportunity-identity :value="opportunity" :label="opportunity" show-avatar link="emit" @link-click="openOpportunity(opportunity)" />
                            </template>
                          </cs-expand-list>

                          <sca-product-details :product="item" :company="item.code" :has-caption="!isLord" :show="true" />

                          <div class=" pb-8" />
                        </div>
                      </td>

                      <td>
                        <v-row dense>
                          <v-col>
                            {{ $t('Quantity') }} :
                            <span class="text-subtitle-1">{{ $n(item.quantity) }}</span>
                          </v-col>

                          <v-col class="shrink">
                            <v-btn v-if="refNeedTechForm(item)" small rounded color="warning-button" @click="showTechForms(item)">
                              {{ $t('Send technical information...') }}
                            </v-btn>
                            <sca-order-state v-else-if="isStateChanging !== item.id" :value="item.state" dense hide-text />
                            <cs-icon-loading v-else small right />
                          </v-col>

                          <v-col v-if="isLord && item.delivery_at" cols="12" class="d-flex align-center text-right text-caption">
                            <div class="ml-auto mr-1">
                              {{ $t('Delivered at {date} by', { date: $stratus.dt(item.delivery_at).format('LLL') }) }}
                            </div>
                            <sca-user-identity :value="item.delivery_by" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(item.delivery_by)" />
                          </v-col>

                          <v-col v-if="canReadPrices" cols="12">
                            <sca-product-prices-grid :prices="item" :periodicity="item.periodicity" dense />
                          </v-col>
                        </v-row>
                      </td>

                      <!-- actions on product line -->
                      <td>
                        <div class="d-flex align-center">
                          <v-tooltip top v-if="canUpdate(item) || permissionShowProduct">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon v-on="on" @click="showSubscription(item)">
                                <v-icon small color="menu-icon">
                                  $vuetify.icons.read
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('Display this product.') }}</span>
                          </v-tooltip>

                          <v-tooltip top v-if="canModifyStop(item)">
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" small icon @click="showDeliveredProduct(item)">
                                <v-icon small color="menu-icon">
                                  $vuetify.icons.setup
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('Modify or stop product') }}</span>
                          </v-tooltip>

                          <v-tooltip top v-if="canDeliver(item, false)">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="success" v-on="on" @click="completed(item)">
                                <v-icon small>
                                  $vuetify.icons.complete
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('Mark this product as delivered.') }}</span>
                          </v-tooltip>

                          <v-tooltip top v-if="canCancel(item)">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="danger" v-on="on" @click="cancelProductDelivery(item)">
                                <v-icon small>
                                  $vuetify.icons.cancel
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('Cancel the product order.') }}</span>
                          </v-tooltip>

                          <v-tooltip top v-if="canStop(item)">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="danger" v-on="on" @click="showDeliveredProduct(item, 'stop-all')">
                                <v-icon small>
                                  $vuetify.icons.cancel
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('Stop this product') }}</span>
                          </v-tooltip>

                          <v-tooltip top v-if="canCancelLastUpdate(item)">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="danger" v-on="on" @click="cancelLastUpdate(item)">
                                <v-icon small>
                                  $vuetify.icons.undo
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('Cancel last update of this line') }}</span>
                          </v-tooltip>

                          <sca-customer-new-request-incident small :tooltip="requestIncidentTooltip" @create-ticket="createTicket(item, $event)" />
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <csm-order-product-date-dialog ref="order-product-date-dialog">
              <v-form slot="content">
                <cs-date-picker v-model="productDate" :min="minDateEnd" :max="maxDateEnd" />
              </v-form>
            </csm-order-product-date-dialog>

            <!-- <csm-order-line-dialog ref="product-dialog" readonly /> -->
          </v-tab-item>

          <v-tab-item value="tab-description" eager>
            <v-card flat>
              <v-card-text>
                <p v-html="orderDescription" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item v-if="isLord && canReadOpportunities && canReadPrices" value="tab-opportunity">
            <v-row justify="center" class="my-2">
              <v-col cols="12" md="10">
                <sca-price-trends ref="order-price-trend" :data="opportunityData" :disabled="opportunityLoading" :loading="opportunityLoading" input-period @refresh="loadPriceTrends">
                  <v-switch slot="button-prepend" v-model="opportunityAccounting" :label="$t('Accounting')" @change="loadPriceTrends" />
                </sca-price-trends>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item v-if="canShowHistory" value="tab-history" eager>
            <sca-order-history-timeline :company="currentCartCustomer" :history="orderInternal.history" :cancellation-history="orderInternal.history_cancel" :caption-history="orderInternal.history_caption" @open-user="openUser" />
          </v-tab-item>

          <v-tab-item v-if="orderInternal.files && orderInternal.files.length > 0" value="tab-documents" eager>
            <cs-attachments-list :id="orderInternal.id" :value="orderInternal.files" can-download />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <csm-opportunity-dialog ref="opportunity-dialog" />
    <csm-product-modify-dialog ref="delivered-product-modify-dialog" @productUpdated="productUpdated" />
    <csm-product-dialog ref="product-dialog" readonly />
    <csm-subscription-dialog ref="subscription-dialog" @modify-product="showDeliveredProduct" @closeDialog="closeOtherDialog" />
    <csm-tech-form-dialog ref="tech-form-dialog" @closeDialog="closeTechFormDialog" />
    <cs-confirm-dialog ref="confirmValidation" />
    <cs-input-value-dialog ref="cancelValidation" />
    <cs-confirm-dialog ref="confirm-order-same-product" />
    <csm-ticket-dialog @closeDialog="closeOtherDialog" ref="ticket-dialog" />
    <csm-user-dialog ref="user-dialog" />
    <csm-ticket-new-request ref="form-ticket-new-request" :visible="showRequestDialog" :descriptor="descriptor" :subscription="subscription" @closeDialog="closeTicketDialog" />
    <csm-ticket-new-incident ref="form-ticket-new-incident" :visible="showIncidentDialog" :descriptor="descriptor" :subscription="subscription" :product="product" @closeDialog="closeTicketDialog" />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'OrderPending',
  components: {
    // 'csm-order-line-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Products/ProductDialog'),
    'csm-order-product-date-dialog': () => import(/* webpackChunkName: "components" */ './OrderProductDate'),
    'csm-product-sub-discount': () => import(/* webpackChunkName: "components" */ '@/components/Products/ProductSubDiscount'),
    'csm-product-modify-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Products/ProductModifyDialog'),
    'csm-tech-form-dialog': () => import(/* webpackChunkName: "components" */ '../Products/ProductTechForms.vue'),
    'csm-ticket-new-incident': () => import(/* webpackChunkName: "views" */ '@/components/Tickets/TicketNewIncident'),
    'csm-ticket-new-request': () => import(/* webpackChunkName: "views" */ '@/components/Tickets/TicketNewRequest')
  },
  props: {
    order: { type: Object, required: true },
    orderId: { type: [Number, String], default: null }
  },
  data () {
    return {
      ORDER_STATE_CANCELED: this.$alto.defines.ORDERS.ORDER_STATE_CANCELED,
      ORDER_STATE_COMPLETED: this.$alto.defines.ORDERS.ORDER_STATE_COMPLETED,
      ORDER_STATE_DEPOSIT_AWAITING: this.$alto.defines.ORDERS.ORDER_STATE_DEPOSIT_AWAITING,
      ORDER_STATE_FORM_AWAITING: this.$alto.defines.ORDERS.ORDER_STATE_FORM_AWAITING,
      ORDER_STATE_PENDING: this.$alto.defines.ORDERS.ORDER_STATE_PENDING,
      ORDER_STATE_RE_PENDING: this.$alto.defines.ORDERS.ORDER_STATE_RE_PENDING,
      ORDER_STATE_STOPPED: this.$alto.defines.ORDERS.ORDER_STATE_STOPPED,
      ORDER_STATE_STOPPING: this.$alto.defines.ORDERS.ORDER_STATE_STOPPING,
      ORDER_STATE_TECH_AWAITING: this.$alto.defines.ORDERS.ORDER_STATE_TECH_AWAITING,
      ORDER_STATE_VALIDATING: this.$alto.defines.ORDERS.ORDER_STATE_VALIDATING,
      ORDER_STATE_WAITING: this.$alto.defines.ORDERS.ORDER_STATE_WAITING,
      applyingCaption: false,
      applyingCustomerCaption: false,
      delayedApplyCaption: () => {},
      delayedApplyCustomerCaption: () => {},
      descriptor: [],
      filterCanDeliverProducts: false,
      headers: [
        { text: this.$t('#'), value: 'index', width: 64, sortable: false },
        { text: this.$t('Product'), value: 'name', sortable: false },
        { text: '', value: 'price', sortable: false },
        { text: '', value: 'updateOrder', sortable: false }
      ],
      isDelivering: false,
      isDepositValidating: false,
      isStateChanging: null,
      expanded: {},
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: -1,
        sortBy: 'index',
        descending: false,
        totalItems: 0
      },
      tab: 'tab-order',
      opportunityAccounting: false,
      opportunityData: {},
      opportunityLoading: false,
      orderingSameProduct: -1,
      prevQuantity: null,
      productDate: this.$stratus.dt().toISOString().substr(0, 10),
      productsNeedCaption: -1,
      maxDateEnd: null,
      minDateEnd: null,
      orderInternal: {},
      product: undefined,
      showIncidentDialog: false,
      showRequestDialog: false,
      subscription: null
    }
  },
  watch: {
    order: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          this.setOrder(newValue)
          if (this.$refs['order-price-trend']) this.$refs['order-price-trend'].empty() // Empty graph
        }
      }
    }
  },
  computed: {
    canReadCatalog () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST)
    },
    canReadOpportunities () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES, this.$alto.API_PERMISSIONS.OPPORTUNITIES_ONELINE)
    },
    canReadPrices () {
      return !this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.LIST_NO_PRICE)
    },
    canShowHistory () {
      return this.isLord && (
        (this.orderInternal.history && this.orderInternal.history.length) ||
        (this.orderInternal.history_caption && this.orderInternal.history_caption.length)
      )
    },
    canStopThisMonth () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_PAST) },
    canStopFullPast () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_PAST_FULL) },
    canStopFuture () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_FUTURE) },
    canValidateDeposit () { return (this.orderInternal.current_state === this.ORDER_STATE_DEPOSIT_AWAITING && this.orderInternal.per_partial_billing && this.orderInternal.total_deposit && this.orderInternal.total_deposit_incl_vat) },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    currentCart () { return this.$store.getters['carts/current'] || {} },
    currentCartsExists () { return this.currentCart && this.currentCart.name },
    currentCartCustomer () { return this.currentCart.customer || this.myCompany },
    me () { return this.$store.getters['$stratus-states/me'] },
    myCompany () { return this.me.company },
    orderDescription () { return this.$stratus.services.strings.stripHtmlTags(this.orderInternal.description) },
    orderLines () {
      if (!this.filterCanDeliverProducts) return this.orderInternal.products
      return _.map(this.orderInternal.products, item => {
        item.canDeliver = this.canDeliver(item, false)
        return item
      })
      // if (!this.filterCanDeliverProducts) return this.orderInternal.products
      // return _.filter(this.orderInternal.products, item => this.canDeliver(item, false))
    },
    permissionCancel () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_CANCEL)
    },
    permissionCancelLastUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_CANCEL_LAST_UPDATE)
    },
    permissionComplete () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_DELIVERY, this.$alto.API_PERMISSIONS.ORDER_DELIVERY_COMPLETE)
    },
    permissionDeliver () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_DELIVERY, this.$alto.API_PERMISSIONS.ORDER_DELIVERY_COMPLETE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_DELIVER_PAST)
    },
    permissionModify () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    permissionShowProduct () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.READ)
    },
    permissionStop () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_FUTURE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_PAST) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_PAST_FULL)
    },
    permissionUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_DELIVERY, this.$alto.API_PERMISSIONS.UPDATE)
    },
    productsToDeliver () {
      if (!this.orderInternal.products || !this.orderInternal.products.length) return []
      const list = []
      this.orderInternal.products.forEach(product => {
        if (this.canDeliver(product, true)) list.push(product)
      })
      return list
    }
  },
  methods: {
    applyCaption (product) {
      this.applyingCaption = true
      this.$store.dispatch('orders/updateSubscription', { id: product.id, caption: product.caption || '' })
        .then(response => {
          this.$stratus.services.notify.success(this.$t('Order updated.'))
        })
        .catch(error => this.$stratus.services.notify.error(error))
        .finally(() => {
          setTimeout(() => { this.applyingCaption = false }, 250)
        })
    },
    applyCustomerCaption (product) {
      this.applyingCustomerCaption = true
      this.$store.dispatch('orders/updateSubscription', { id: product.id, customer_caption: product.customer_caption || '' })
        .then(response => {
          this.$stratus.services.notify.success(this.$t('Order updated.'))
        })
        .catch(error => this.$stratus.services.notify.error(error))
        .finally(() => {
          setTimeout(() => { this.applyingCustomerCaption = false }, 250)
        })
    },
    canCancel (item) {
      return this.permissionCancel && this.$store.getters['orders/STATE_CAN_CANCEL'].includes(item.state)
    },
    canCancelLastUpdate (item) {
      return this.permissionCancelLastUpdate &&
        [this.ORDER_STATE_COMPLETED, this.ORDER_STATE_RE_PENDING, this.ORDER_STATE_CANCELED, this.ORDER_STATE_STOPPING, this.ORDER_STATE_STOPPED].includes(item.state)
    },
    canDeliver (item, withoutDeposit) {
      return this.permissionDeliver && (withoutDeposit || this.order.current_state !== this.ORDER_STATE_DEPOSIT_AWAITING) && this.$store.getters['orders/STATE_CAN_DELIVER'].includes(item.state)
    },
    canModify (item) {
      return this.permissionModify
    },
    canModifyStop (item) {
      return item.state === this.ORDER_STATE_COMPLETED && item.periodicity !== 'O' && (this.permissionModify || this.permissionStop)
    },
    canOrderSameProduct (product) {
      const p = this.$store.getters['$alto-catalog/get'](product.ref)
      if (!p) return false
      if (p.end_subscription) return false
      return p.available || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.CREATE)
    },
    canStop (item) {
      return this.permissionStop && item.periodicity !== 'O' && this.$store.getters['orders/STATE_CAN_STOP'].includes(item.state)
    },
    canUpdate (item) {
      return item.periodicity !== 'O' && this.permissionUpdate && this.$store.getters['orders/STATE_CAN_UPDATE'].includes(item.state)
    },
    cancelProductDelivery (product) {
      this.$refs.confirmValidation
        .open(this.$t('Cancel the product order.'), this.$t('Do you confirm the cancellation of the order of the product "{name}"?', { name: product.name }), { color: 'orange' })
        .then(confirm => {
          if (confirm) {
            this.isStateChanging = product.id
            this.$store.dispatch('orders/cancelProduct', { id: product.id })
              .then(() => {
                this.getOrder(this.orderId)
              })
              .catch(error => this.$stratus.services.notify.error(error))
              .finally(() => { setTimeout(() => { this.isStateChanging = null }, 250) })
          }
        })
    },
    cancelLastUpdate (line) {
      this.$refs.cancelValidation
        .open(this.$t('Cancel last update of this line'), this.$t('This will remove latest history item, update quantity with history\'s quantity, update state to completed, remove last update\'s tickets and delete it in apps, remove last opportunity ids and update order\'s price. Do you confirm?'), { color: 'red', inputLabel: this.$t('Reason for cancellation') })
        .then(({ confirmed, value }) => {
          if (confirmed) {
            this.isStateChanging = line.id
            this.$store.dispatch('orders/cancelLastUpdate', { id: line.id, comment: value })
              .then(() => {
                this.getOrder(this.orderId)
              })
              .catch(error => this.$stratus.services.notify.error(error))
              .finally(() => { setTimeout(() => { this.isStateChanging = null }, 250) })
          }
        })
    },
    captionRules (item) {
      const rules = [this.$stratus.services.form.rules.max(128)]
      if (this.refNeedCaption(item.ref)) {
        rules.push(this.$stratus.services.form.rules.min(1))
      }
      return rules
    },
    closeOtherDialog () {
      // Nothing to do for now.
    },
    closeTechFormDialog () {
      this.productUpdated()
    },
    closeTicketDialog () {
      this.showIncidentDialog = false
      this.showRequestDialog = false
    },
    countProductsNeedCaption () {
      if (!this.orderInternal.products || !this.orderInternal.products.length) {
        this.productsNeedCaption = 0
        return
      }
      let count = 0
      let p
      this.orderInternal.products.forEach(product => {
        p = this.$store.getters['$alto-catalog/get'](product.ref)
        if (p && p.need_caption && !product.caption) count++
      })
      this.productsNeedCaption = count
    },
    async createTicket (subscription, { ticketType, descriptor }) {
      this.descriptor = descriptor
      this.subscription = subscription
      if (ticketType === this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT) {
        this.product = await this.$store.dispatch('$alto-catalog/getProductByRef', subscription.ref)
        this.$refs['form-ticket-new-incident'].reset()
        this.showIncidentDialog = true
        this.$refs['form-ticket-new-incident'].resetPage()
      } else if (ticketType === this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST) {
        this.$refs['form-ticket-new-request'].reset()
        this.showRequestDialog = true
      }
    },
    expandedToggle (line) {
      this.$set(this.expanded, line, !this.expanded[line])
    },
    async orderSameProduct (product) {
      // load product
      try {
        const cartItem = { ...product } // Clone to keep original product

        this.$refs['confirm-order-same-product'].open(this.$t('Order same product'), this.$t('Do you want to add product «{pname}» to {cname}?', { pname: cartItem.name, cname: this.currentCart.name || this.$t('a new cart') }))
          .then(async (confirmed) => {
            if (confirmed) {
              this.orderingSameProduct = product.id

              // No cart exist, create a brand new one
              if (!this.currentCartsExists) {
                this.$store.dispatch('carts/create', { company: this.myCompany })
              }

              const quantity = 1
              // Add product to cart
              await this.$store.dispatch('carts/addToCurrent', { items: Object.assign(cartItem, { quantity }), company: this.myCompany })

              // Save the cart (it will cleanup all unwanted fields from item)
              const { cart, isNew } = await this.$store.dispatch('carts/save', { cart: this.currentCart })
              if (isNew) {
                this.$stratus.services.notify.success(this.$t('New cart {name} created.', { name: cart.name }))
              }
              this.$store.dispatch('carts/list') // Refresh cart menu
              this.$store.dispatch('carts/setCurrent', cart) // Force refresh current cart
              if (this.$root['global-cart-menu']) this.$root['global-cart-menu'].refreshList()
              this.$stratus.services.notify.success(this.$t('{quantity} × {product} added to cart.', { quantity, product: cartItem.name || cartItem.ref }))
              setTimeout(() => { this.orderingSameProduct = -1 }, 200)
            }
          })
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.orderingSameProduct = -1
      }
    },
    async loadPriceTrends ({ dateBegin, dateEnd } = {}) {
      if (!this.canReadOpportunities) return

      this.opportunityLoading = true
      try {
        const opportunityOrderLineIds = _.map(this.orderInternal.products, prod => { return prod.id })
        this.opportunityData = await this.$store.dispatch('opportunities/getSubscribedProductsHistory', {
          ids: opportunityOrderLineIds,
          dateBegin: dateBegin || this.$stratus.dt().subtract(6, 'month').startOf('month'),
          dateEnd: dateEnd || this.$stratus.dt(),
          countable: this.opportunityAccounting
        })
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.opportunityData = {}
      }
      setTimeout(() => { this.opportunityLoading = false }, 500)
    },
    refNeedCaption (ref) {
      const p = this.$store.getters['$alto-catalog/get'](ref)
      return p && p.need_caption
    },
    refNeedTechForm (item) {
      if (item.state !== this.ORDER_STATE_TECH_AWAITING) return false
      const p = this.$store.getters['$alto-catalog/get'](item.ref)
      return p && p.tech_forms && p.tech_forms.length > 0
    },
    completed (product) {
      if (this.refNeedCaption(product.ref) && !product.caption) {
        this.$stratus.services.notify.error('The caption is mandatory!')
        return
      }

      if (product.state === this.ORDER_STATE_PENDING && product.date_begin) {
        this.$refs.confirmValidation
          .open(this.$t('Confirm delivery'), this.$t('Validate is an irreversible step, do you confirm the validation?'), { color: 'blue' })
          .then((confirm) => {
            if (confirm) {
              this.isStateChanging = product.id
              this.$store.dispatch('orders/deliverProduct', { id: product.id })
                .then(() => {
                  this.getOrder(this.orderId)
                })
                .catch(error => this.$stratus.services.notify.error(error))
                .finally(() => { setTimeout(() => { this.isStateChanging = null }, 250) })
            }
          })
      } else {
        this.productDate = this.$stratus.dt().toISOString().substr(0, 10)

        this.minDateEnd = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_DELIVERY, this.$alto.API_PERMISSIONS.ORDER_DELIVERY_COMPLETE_OVERRIDE) ? this.$stratus.dt().subtract(1, 'years').startOf('year') : this.$stratus.dt().startOf('month')
        this.maxDateEnd = this.$stratus.dt().add(1, 'days').startOf('day')

        this.$refs['order-product-date-dialog'].open(this.$t('Start this product'), `${product.name} × ${product.quantity}`).then(confirm => {
          if (confirm) {
            this.isStateChanging = product.id
            this.$store.dispatch('orders/deliverProduct', { id: product.id, date_begin: this.$stratus.dt(this.productDate).format('YYYY-MM-DD') })
              .then(() => {
                this.getOrder(this.orderId)
              })
              .catch(error => this.$stratus.services.notify.error(error))
              .finally(() => { setTimeout(() => { this.isStateChanging = null }, 250) })
          }
        })
      }
    },
    deliverRemainingProducts () {
      this.countProductsNeedCaption()
      if (this.productsNeedCaption > 0) return

      const list = this.productsToDeliver
      if (list.length) {
        this.productDate = this.$stratus.dt().toISOString().substr(0, 10)

        this.minDateEnd = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_DELIVERY, this.$alto.API_PERMISSIONS.ORDER_DELIVERY_COMPLETE_OVERRIDE) ? this.$stratus.dt().subtract(1, 'years').startOf('year') : this.$stratus.dt().startOf('month')
        this.maxDateEnd = this.$stratus.dt().add(1, 'days').startOf('day')

        this.$refs['order-product-date-dialog'].open(this.$tc('Deliver the remaining product...|Deliver remaining {count} products...', list.length, { count: list.length }), this.$t('Begin date'))
          .then((confirm) => {
            if (confirm) {
              this.isDelivering = true
              list.forEach(async (product, index) => {
                try {
                  if (product.state === this.ORDER_STATE_PENDING && product.date_begin) {
                    await this.$store.dispatch('orders/deliverProduct', { id: product.id })
                  } else {
                    // re-pending
                    await this.$store.dispatch('orders/deliverProduct', { id: product.id, date_begin: this.$stratus.dt(this.productDate).format('YYYY-MM-DD') })
                  }

                  if (index === list.length - 1) {
                    setTimeout(() => {
                      this.isDelivering = false
                      this.getOrder(this.orderId)
                    }, 1000)
                  }
                } catch (error) {
                  this.isDelivering = false
                  this.$stratus.services.notify.error(error)
                }
              })
            }
          })
      }
    },
    computeDiscount (total, discount) {
      return parseFloat(total) - (parseFloat(total) * (parseFloat(discount) * 0.01))
    },
    getOrder (id) {
      this.$emit('refresh', this.orderInternal)
    },
    setOrder (order) {
      this.orderInternal = { ...order } // Get a copy
      this.countProductsNeedCaption()
    },
    onCaptionChange (item) {
      this.countProductsNeedCaption()
      if (this.delayedApplyCaption) this.delayedApplyCaption(item)
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openProduct (id) {
      if (this.$refs['product-dialog']) this.$refs['product-dialog'].openId(id)
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticket.id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    productUpdated () {
      this.getOrder(this.orderId) // Refresh this order
    },
    saveSubscriptionDialog () {
      this.$store.dispatch('orders/updateSubscription', { id: this.product.id, id_sphere: this.product.id_sphere, customer_caption: this.product.customer_caption || '', comment: this.product.comment || '', customer_comment: this.product.customer_comment || '' })
        .then(response => {
          this.$stratus.services.notify.success(this.$t('Your product has been updated.'))
          this.getOrder(this.orderId) // Refresh this order
          this.closeOtherDialog()
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    showDeliveredProduct (product, action) {
      this.$refs['delivered-product-modify-dialog'].open(product.id, action, this.currentCartCustomer)
    },
    async showSubscription (product) {
      try {
        if (this.$refs['subscription-dialog']) this.$refs['subscription-dialog'].open(product.id)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    showTechForms (item) {
      if (!this.canReadCatalog) return
      const product = this.$store.getters['$alto-catalog/get'](item.ref)
      this.$refs['tech-form-dialog'].open({ update: true, orderId: this.orderId, orderItem: item, product })
    },
    validateDeposit () {
      this.isDepositValidating = true
      this.$stratus.services.notify.success(this.$t('The deposit has been validated.'))
      this.$store.dispatch('orders/validateDeposit', { id: this.orderId })
        .then(() => {
          this.isDepositValidating = false
          this.getOrder(this.orderId) // Refresh this order
        })
    }
  },
  created () {
    this.requestIncidentTooltip = {
      [this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT]: this.$t('Report an incident on this product.'),
      [this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST]: this.$t('Make a free-format request on this product.')
    }
  },
  async mounted () {
    this.delayedApplyCaption = _.debounce(this.applyCaption, 750)
    this.delayedApplyCustomerCaption = _.debounce(this.applyCustomerCaption, 750)
    await this.$store.dispatch('$stratus-states/getMe')
    this.countProductsNeedCaption()
  }
}
</script>

<style>
td.table-separator {
  height: 6px !important;
}
</style>
