<template>
  <div v-if="canListSLA">
    <sca-advanced-store-grid :options="gridOptions" resource="tickets/sla-counters" :columns="gridColumns" :dense="dense" :custom-search="customSearch" :filters="filters" :ref="`sla-grid-${uuid}`" :store-suffix="uuid" @showItem="onShowItem">
      <template v-if="title" #legend-top>
        <span class="pl-2 text-h5">
          {{ title }}
        </span>
      </template>

      <template slot="search-append">
        <v-btn-toggle v-if="!hideFilters" v-model="filterBy" dense class="transparent" @change="refresh">
          <v-btn small text :value="FILTER_OWNER" :class="filterBy === FILTER_OWNER ? 'button-main button-main-ink--text' : ''" :disabled="loading">
            {{ $t('My SLA') }}
          </v-btn>
          <v-btn v-if="me?.lord_team_id" small text :value="FILTER_MY_TEAM" :class="filterBy === FILTER_MY_TEAM ? 'button-main button-main-ink--text' : ''" :disabled="loading">
            {{ $t('SLA of my team') }}
          </v-btn>
          <v-btn small text :value="FILTER_NONE" :class="filterBy === FILTER_NONE ? 'button-main button-main-ink--text' : ''" :disabled="loading">
            {{ $t('All') }}
          </v-btn>
        </v-btn-toggle>
        <slot name="search-append" />
      </template>

      <template v-slot:item-id_ticket="{ item }">
        <span class="text-font-fixed text-no-wrap">
          {{ item }}
        </span>
      </template>

      <template v-slot:item-state="{ itemRaw }">
        {{ $t(`sla-state-${itemRaw}`) }}
      </template>

      <template v-slot:item-ticket_id_owner="{ itemRaw }">
        <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
        <span v-else />
      </template>

      <template v-slot:item-ticket_id_referring="{ itemRaw }">
        <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
        <span v-else />
      </template>

      <template v-slot:item-ticket_id_lord_team="{ itemRaw }">
        <sca-team-identity v-if="itemRaw" :value="itemRaw" />
        <span v-else />
      </template>

      <template v-slot:item-ticket_code="{ itemRaw }">
        <sca-company-identity v-if="itemRaw" :value="itemRaw" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(itemRaw)" />
        <span v-else />
      </template>

      <template v-slot:item-due_time="{ itemRaw }">
        {{ itemRaw ? $stratus.services.format.secondsToDays(60 * itemRaw, SECONDS_BY_WORK_DAY) : '-' }}
      </template>

      <template v-slot:item-passed_time="{ itemRaw }">
        {{ itemRaw ? $stratus.services.format.secondsToDays(60 * itemRaw, SECONDS_BY_WORK_DAY) : '-' }}
      </template>

      <template v-slot:item-per_passed_time="{ itemRaw }">
        <v-row class="d-flex align-center" dense>
          <v-col>
            <cs-progress-bar-color :value="itemRaw" />
          </v-col>
          <v-col cols="4" class="text-no-wrap">
            {{ $t('{percent}%', { percent: Math.round(itemRaw) }) }}
          </v-col>
        </v-row>
      </template>

      <template v-slot:item-create_by="{ itemRaw }">
        <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
        <span v-else />
      </template>
    </sca-advanced-store-grid>

    <csm-company-dialog ref="company-dialog" />
    <csm-user-dialog ref="user-dialog" />
    <csm-ticket-dialog @closeDialog="closeDialog" ref="ticket-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

const FILTER_OWNER = 1
const FILTER_MY_TEAM = 2
const FILTER_NONE = 3

export default {
  name: 'SlaGrid',
  props: {
    columns: { type: [Array, Object], default: null },
    dense: { type: Boolean, default: false },
    hideActions: { type: Boolean, default: false },
    hideFilters: { type: Boolean, default: false },
    options: { type: Object, default: () => {} },
    query: { type: String, default: '' },
    title: { type: String, default: '' }
  },
  data () {
    return {
      FILTER_MY_TEAM,
      FILTER_NONE,
      FILTER_OWNER,
      SECONDS_BY_WORK_DAY: this.$alto.defines.TICKETS.SECONDS_BY_WORK_DAY,
      filterBy: null,
      filters: ['name', 'id_ticket', 'ticket_name'],
      loading: false,
      uuid: this.$stratus.uuid()
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    gridColumns () {
      if (Array.isArray(this.columns)) return this.columns

      const cols = [{
        text: 'Name',
        value: 'name',
        width: '80px'
      }, {
        text: 'Ticket',
        value: 'id_ticket'
      }, {
        text: 'State',
        value: 'state'
      }, {
        text: 'Start at',
        value: 'start_at',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.DATETIME
      }, {
        text: 'Passed time',
        value: 'passed_time',
        hidden: true
      }, {
        text: 'Passed time %',
        value: 'per_passed_time',
        sortable: false,
        width: '100px'
      }, {
        text: 'Due time',
        value: 'due_time',
        hidden: true
      }, {
        text: 'Due date',
        value: 'due_date',
        format: v => v ? this.$stratus.services.fieldRenderers.DATETIME_SHORT(v) : ''
      }, {
        text: 'Ticket name',
        value: 'ticket_name',
        width: '50%'
      }, {
        text: 'Company',
        value: 'ticket_code'
      }, {
        text: 'Assigned to',
        value: 'ticket_id_owner'
      }, {
        text: 'Referrer',
        value: 'ticket_id_referring'
      }, {
        text: 'Team',
        value: 'ticket_id_lord_team'
      }, {
        text: 'Created by',
        value: 'ticket_create_by',
        hidden: true
      }]

      if (!this.columns) return cols // Return default

      return _.map(this.columns, (hidden, value) => {
        return { ..._.find(cols, { value }), hidden }
      })
    },
    gridOptions () {
      return {
        advancedSearchFieldsSchemaBaseUrl: '/docs/tickets',
        advancedSearchFieldsSchemaName: 'SLACounter',
        allowContextMenuOnCell: true,
        allowActions: !this.hideActions,
        allowActionsAppend: !this.hideActions,
        allowActionsPrepend: !this.hideActions,
        allowColumnsVisible: true,
        clickLineShowUpdate: !this.hideActions,
        create: false,
        debounce: {
          onList: 500 // Request rate is one every 500ms
        },
        delete: false,
        foreignFields: ['id'],
        key: 'id',
        multiSelect: false,
        notifyErrors: true,
        search: this.isLogged,
        show: false,
        sortBy: 'due_date',
        sortDescending: false,
        update: false,
        ...this.options
      }
    }
  },
  watch: {
    query: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue !== oldValue) this.refresh()
      }
    }
  },
  methods: {
    canListSLA () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_SLA_COUNTERS, this.$alto.API_PERMISSIONS.LIST)
    },
    closeDialog () {
      this.showForm = false
      if (this.$refs[`sla-grid-${this.uuid}`]) this.$refs[`sla-grid-${this.uuid}`].fetchData()
    },
    customSearch () {
      const cusSearch = []
      cusSearch.raw = ''
      if (this.query) {
        cusSearch.raw = this.query.replace('query=', '')
      }

      function add (qry) {
        if (cusSearch.raw) cusSearch.raw += '!!'
        cusSearch.raw += qry
      }

      if (!this.hideFilters) {
        switch (this.filterBy) {
          case FILTER_MY_TEAM:
            if (this.me.lord_team_id) add(`ticket_id_lord_team=${this.me.lord_team_id}`)
            break
          case FILTER_OWNER:
            add(`ticket_id_owner=${this.me.id}||ticket_id_referring=${this.me.id}`)
            break
        }
      }

      return cusSearch
    },
    async onShowItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.$refs['ticket-dialog'].open(data.item.id_ticket)
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async refresh () {
      try {
        if (this.$refs[`sla-grid-${this.uuid}`]) this.$refs[`sla-grid-${this.uuid}`].fetchData()
      } catch (error) {
        console.error('[SLA] grid error:', error)
      }
    }
  }
}
</script>
