const actions = {
  cancelProduct ({ commit, rootState }, orderLine) {
    return rootState.$stratus.services.api.patch(`/orders/canceloneline/${orderLine.id}`)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  cancelLastUpdate ({ commit, rootState }, orderLine) {
    return rootState.$stratus.services.api.patch(`/orders/oneline/cancel-last-update/${orderLine.id}`, { comment: orderLine.comment })
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  computeNewPrices ({ commit, rootState }, { id, main }) {
    return rootState.$stratus.services.api.patch(`/orders/modifyoneline/${id}?fake=true`, { main, date_update: rootState.$stratus.dt().toISOString().substr(0, 10) })
      .then(response => {
        return response.ok ? response.body : {}
      })
      .catch(error => commit('API_FAILURE', error))
  },
  deliverProduct ({ commit, rootState }, orderLine) {
    return rootState.$stratus.services.api.patch(`/orders/deliveryoneline/${orderLine.id}`, orderLine)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  extractCmdb ({ commit, rootState, state }, { companies, withRef = false }) {
    let url = `/orders/extract_cmdb?with_ref=${withRef}`
    if (companies && companies.length) {
      url += `&companies=["${companies.join('","')}"]`
    }
    return rootState.$stratus.services.api.get(url)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  get ({ commit, rootState }, id) {
    return rootState.$stratus.services.api.get(`/orders/${id}`)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  getActiveProductsForCompany ({ commit, rootState, state }, company) {
    const today = rootState.$stratus.dt().format('YYYY-MM-DD')
    return rootState.$stratus.services.api.get(`/orders/oneline?limit=0&fields=id,name,code,ref,total_discount,total_discount_incl_vat,total_setup_discount,total_setup_discount_incl_vat,state,description,caption,customer_caption,id_sphere,periodicity,end_subscription,quantity,detail_daily,date_begin,date_end,price,per_discount,total,price_setup,per_discount_setup,total_setup&query=(code[eq]=${company}!!state[in]=tech-awaiting,pending,completed,re-pending,stopping!!ref[ne]=C06THP101OL!!ref[ne]=C06THP102OL!!(date_end[eq]=null||date_end[gte]=${today}))`)
      .then((response) => {
        return rootState.$stratus.services.sort.natural(response.results, { customer_caption: 'asc', caption: 'asc', name: 'asc', ref: 'asc' })
      })
      .catch((error) => commit('API_FAILURE', error))
  },
  getProduct ({ commit, rootState }, orderLineId) {
    return rootState.$stratus.services.api.get(`/orders/oneline/${orderLineId}`)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  list ({ commit, rootState, state }) {
    return rootState.$stratus.services.api.get('/orders?fields=id,code,current_state,history,accomplishment,create_at,update_at&sort=-update_at')
      .then(response => {
        commit('setList', response.results)
        return state.list
      })
      .catch(error => commit('API_FAILURE', error))
  },
  listStates ({ commit, rootState, state }) {
    if (!state.states && state.states.length) return
    return rootState.$stratus.services.api.get('/orders/states')
      .then(response => {
        commit('setStates', { rootState, data: response })
        return state.states
      })
      .catch(error => commit('API_FAILURE', error))
  },
  listTopDelivered ({ commit, rootState, state }, count = 5) {
    return rootState.$stratus.services.api.get(`/orders/oneline?fields=id,name,code,caption,cmd_id,id_sphere,ref,state,date_begin&sort=-date_begin&limit=${count}&query=state[eq]=completed`)
      .then(response => {
        return response.results || []
      })
      .catch(error => commit('API_FAILURE', error))
  },
  listTopPending ({ commit, rootState, state }, count = 5) {
    return rootState.$stratus.services.api.get(`/orders?fields=id,code,cart_name,current_state,history,accomplishment,create_at,update_at&sort=-update_at&limit=${count}&query=current_state[in]=delivered,pending,re-pending`)
      .then(response => {
        return response.results || []
      })
      .catch(error => commit('API_FAILURE', error))
  },
  setLinesSphere ({ commit, rootState }, { idSphere, lines }) {
    return rootState.$stratus.services.api.patch('/orders/oneline', { id_sphere: idSphere, onelines: lines })
      .then(response => {
        return response.body || 0
      })
      .catch(error => commit('API_FAILURE', error))
  },
  skipProductTechForm ({ commit, rootState }, lineId) {
    return rootState.$stratus.services.api.patch(`/orders/skip-tech-form/${lineId}`)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  stopProduct ({ commit, rootState }, orderLine) {
    return rootState.$stratus.services.api.patch(`/orders/stoponeline/${orderLine.id}`, orderLine)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  submitProductTechForm ({ commit, rootState }, { lineId, needHelp, validate, techForms }) {
    const data = { need_help: needHelp, validate }
    if (techForms) data.tech_forms = techForms
    return rootState.$stratus.services.api.patch(`/orders/oneline/submit-tech/${lineId}`, data)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  updatePeriodicity ({ commit, rootState }, orderLine) {
    return rootState.$stratus.services.api.patch(`/orders/changeperiodicity/${orderLine.id}`, orderLine)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  updateSubscription ({ commit, rootState }, orderLine) {
    return rootState.$stratus.services.api.patch(`/orders/oneline/${orderLine.id}`, orderLine)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  updateProductMain ({ commit, rootState }, orderLine) {
    return rootState.$stratus.services.api.patch(`/orders/modifyoneline/${orderLine.id}`, orderLine)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  validate ({ commit, rootState }, validation) {
    return rootState.$stratus.services.api.patch(`/orders/${validation.id}`, validation)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  validateDeposit ({ commit, rootState }, validation) {
    return rootState.$stratus.services.api.patch(`/orders/validate-deposit/${validation.id}`, validation)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  }
}

const mutations = {
  setList (state, orders) {
    state.list = orders
  },
  setStates (state, { rootState, data }) {
    state.states = rootState.$stratus.services.array.toSelectItems(data, { textPrefix: 'order-state-', sort: true, itemText: 'id', itemValue: 'id' })
  },
  API_FAILURE (state, error) {
    if (error.status === 401) {
      console.warn('[API] status 401', error)
    } else throw error
  }
}

export default {
  namespaced: true,
  state: {
    list: [],
    states: []
  },
  getters: {
    list: state => state.list || [],
    states: state => state.states || [],
    STATE_CAN_CANCEL: state => ['pending', 'tech-awaiting'],
    STATE_CAN_DELIVER: state => ['pending', 're-pending'],
    STATE_CAN_MODIFY_MAIN: state => ['completed'],
    STATE_CAN_SHOW_PRODUCT: state => ['re-pending'],
    STATE_CAN_STOP: state => ['completed'],
    STATE_CAN_UPDATE: state => ['completed']
  },
  actions,
  mutations
}
