<template>
  <div id="app" :class="clsFix">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {
      clsFix: ''
    }
  },
  created () {
    console.log('[user-agent]', navigator.userAgent)
    if (navigator.userAgent.includes('Win')) {
      console.log('[fix] Windows/Chrome font aliasing fixed.', navigator.userAgent)
      this.clsFix = 'fix-font-aliasing-chrome'
    }
  }
}
</script>
