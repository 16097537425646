import config from '@/config'
import enUS from './lang/en-US'
import frFR from './lang/fr-FR'

// Locales to load
const LOCALES = ['en-US', 'fr-FR']
const DEFAULT_LOCALE = config.locale
const LOCALES_COUNTRIES = {
  'en-US': 'us',
  'fr-FR': 'fr'
}

// Load all locales according to constant LOCALES
const messages = {
  'en-US': { ...enUS },
  'fr-FR': { ...frFR }
}

export default {
  DEFAULT_LOCALE,
  LOCALES,
  LOCALES_COUNTRIES,
  messages
}
