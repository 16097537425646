<template>
  <span>
    <v-btn v-bind="$attrs" :icon="!label.length && !showId" :rounded="Boolean(label || showId)" :text="Boolean(label || showId)" :large="large" :small="small" :to="value ? '/users/' + value : null">
      <v-icon v-if="icon" :small="small" :left="Boolean(label || showId)" color="menu-icon">{{ icon }}</v-icon>
      <span v-if="showId" class="mr-1">{{ value }}</span>
      <span v-else-if="label" class="mr-1">{{ $t(label) }}</span>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: 'UserButton',
  props: {
    icon: { type: String, default: '' },
    label: { type: String, default: 'Details...' },
    large: { type: Boolean, default: false },
    showId: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    value: { type: String, required: true }
  },
  data () { return {} },
  computed: {
    isLord () { return this.$store.getters['$stratus-states/isLord'] }
  }
}
</script>
