<template>
  <div class="my-1">
    <cs-expand-panel :icon="icon" block>
      <v-row slot="header" align="center" no-gutters>
        <v-col cols="1" class="grow text-no-wrap">
          <div class="text-caption">
            {{ sortDate }}
          </div>

          <v-chip small label :class="`${color} color0--text clickable`">
            {{ $t('follow-up-type-task-1') | uppercase }}
          </v-chip>
        </v-col>

        <v-col>
          <div class="d-flex align-center">
            <sca-user-identity v-if="followUpData.id_owner" :value="followUpData.id_owner" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(followUpData.id_owner)" />

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="ml-2" :class="priorityColor" small v-on="on">
                  {{ priorityIcon }}
                </v-icon>
              </template>
              <span>{{ $t('Priority: {name}', { name: $t('task-priority-' + followUpData.priority) }) }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="ml-2" :class="statusColor" small v-on="on">
                  {{ statusIcon }}
                </v-icon>
              </template>
              <span>{{ $t('Status: {name}', { name: $t('task-status-' + followUpData.status) }) }}</span>
            </v-tooltip>

            <div v-if="taskIsNotDone" class="text-caption ml-2" :class="taskExpired ? 'danger--text' : ''">
              {{ $t('Due date: {date}', { date: dueDate }) }}
            </div>
          </div>

          <div class="text-wrap">
            {{ followUpData.subject }}
          </div>
        </v-col>

        <v-col class="shrink text-no-wrap text-right text-caption">
          <div v-if="showCompany && followUpData.code" class="my-1 mr-1">
            <sca-company-identity :value="followUpData.code" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(followUpData.code)" />
          </div>

          <!-- <div v-if="showSalesPerson" class="ml-2">
            <sca-user-identity :value="followUpData.id_lord_salesperson" dense show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(followUpData.id_lord_salesperson)" />
          </div> -->
        </v-col>

        <v-divider vertical class="ml-2 mr-4" />

        <v-col class="shrink text-no-wrap">
          <v-btn v-if="canUpdate" small icon rounded class="mr-2" @click.stop="updateItem(value)">
            <v-icon small color="primary">
              $vuetify.icons.edit
            </v-icon>
          </v-btn>

          <v-btn v-if="canDelete" small icon rounded class="mr-2" @click.stop="deleteItem(value)">
            <v-icon small color="danger">
              $vuetify.icons.delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row slot="content" dense class="mt-2">
        <v-col cols="4">
          <span>{{ $t('Priority: {name}', { name: $t('task-priority-' + followUpData.priority) }) }}</span>
          <v-icon class="ml-2" :class="priorityColor">
            {{ priorityIcon }}
          </v-icon>
        </v-col>

        <v-col cols="4">
          <span>{{ $t('Status: {name}', { name: $t('task-status-' + followUpData.status) }) }}</span>
          <v-icon class="ml-2" :class="statusColor">
            {{ statusIcon }}
          </v-icon>
        </v-col>

        <v-col cols="4" class="text-right">
          {{ $t('Due date: {date}', { date: dueDate }) }}
          <span v-if="taskIsNotDone && taskExpired" class="danger--text">
            ({{ $t('Out-of-date') }})
          </span>
        </v-col>

        <v-col cols="12">
          <v-row justify="center">
            <v-col cols="12">
              <div>
                <v-tabs hide-slider v-model="tab" color="transparent">
                  <cs-loader-tab href="#tab-content" :header="$t('Content')" icon="icon-opened-email" />
                  <cs-loader-tab href="#tab-raw-text" :header="$t('Raw text')" icon="icon-file" />
                  <cs-loader-tab href="#tab-attachment" :header="$t('Attachments')" icon="icon-attachment" :loading="loadingAttachments" :count="followUpData.attachments.length" @load="loadFollowUpAttachmentsMeta(followUpData.attachments)" />

                  <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-content">
                      <div v-if="followUpData.description" class="pa-2" v-html="sanitizeBody(followUpData.description)" />
                      <v-row v-else justify="center" class="mt-4">
                        <v-col class="shrink text-no-wrap">
                          <cs-alert-panel type="info" dense :text="$t('No content...')" />
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <v-tab-item value="tab-raw-text">
                      <pre v-if="followUpData.description">{{ followUpData.description }}</pre>
                      <v-row v-else justify="center" class="mt-4">
                        <v-col class="shrink text-no-wrap">
                          <cs-alert-panel type="info" dense :text="$t('No content...')" />
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <v-tab-item value="tab-attachment">
                      <v-expand-transition>
                        <v-row v-if="loadingAttachmentsCount > 0" dense align="center" justify="center">
                          <v-col cols="12" sm="6" md="3">
                            <div text dense class="ma-4">
                              <cs-icon-loading show-text small left />
                              <v-progress-linear :value="percentLoadingAttachmentsCount" />
                            </div>
                          </v-col>
                        </v-row>
                      </v-expand-transition>

                      <v-row dense>
                        <v-col cols="12" class="text-right pa-4">
                          <v-menu offset-y v-model="menuAttachments" transition="scale-transition" :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <v-btn rounded v-on="on">
                                {{ $t('Add attachments') }}
                                <v-icon small right>
                                  icon-small-arrow-down
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-card outlined class="px-0">
                              <v-card-title>
                                {{ $t('Add attachments') }}
                              </v-card-title>
                              <v-card-text>
                                <cs-file-upload-input v-model="files" :file-size-limit="ALL_FILE_SIZE_LIMIT" :total-size-limit="ALL_FILE_SIZE_LIMIT" use-isu />
                                <div class="text-caption">
                                  {{ $t('You have a space of {size}MB for all attachments.', { size: ALL_FILE_SIZE_LIMIT / 1000 / 1000 }) }}
                                </div>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer />
                                <v-btn rounded :disabled="!files.length" @click="sendAttachments">
                                  {{ $t('Send') }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-menu>

                          <v-row v-if="sendingFiles">
                            <v-col v-if="files[fileIndex]" cols="6">
                              {{ $t('Sending file « {name} »', { name: files[fileIndex].name }) }}
                            </v-col>
                            <v-col>
                              <v-progress-linear v-model="sendingFileProgress" />
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" class="pa-4">
                          <cs-attachments-list :id="followUpData.id" :value="followUpData.attachments" :can-delete="canDeleteAttachment" @delete="deleteAttachments" />
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col class="d-flex">
          <cs-expand-list v-if="isArray(followUpData.opportunity_ids) && followUpData.opportunity_ids.length > 0" :items="followUpData.opportunity_ids" row>
            <template v-slot:expand-list-item="{ item }">
              <sca-opportunity-identity :value="item" :label="item" show-avatar link="emit" @link-click="openOpportunity(item)" />
            </template>
          </cs-expand-list>
          <span v-else class="ml-8">{{ $t('Undefined opportunity') }}</span>
        </v-col>

        <v-col cols="12">
          <sca-footer-create-update-at-by :value="value" :link-user="isLord" />
        </v-col>
      </v-row>
    </cs-expand-panel>

    <cs-confirm-dialog ref="confirm-event-delete" />
    <csm-opportunity-dialog ref="opportunity-dialog" />
    <csm-followup-task-dialog ref="followup-task-dialog" :company-code="companyCode" update @close="onCloseDialog" />
    <csm-company-dialog ref="company-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import getClassNameForExtension from 'font-awesome-filetypes'

export default {
  name: 'FollowUpTask',
  components: {
    'csm-followup-task-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpTaskDialogLite')
  },
  props: {
    companyCode: { type: String, default: null },
    showCompany: { type: Boolean, default: false },
    showSalesPerson: { type: Boolean, default: false },
    value: { type: Object, required: true }
  },
  data () {
    return {
      ALL_FILE_SIZE_LIMIT: 20 * 1000 * 1000,
      attachmentsMeta: [],
      fileIndex: 0,
      files: [],
      followUpData: {
        attachments: []
      },
      isArray: _.isArray,
      isDeleting: false,
      loadingAttachments: false,
      loadingAttachmentsCount: 0,
      loadingPdf: false,
      menuAttachments: false,
      sendingFiles: false,
      tab: 'tab-content',
      tmpAttachmentList: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue, oldValue) {
        this.followUpData = { ...newValue }
      }
    }
  },
  computed: {
    canAdd () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.CREATE)
    },
    canDelete () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.DELETE)
    },
    canDeleteAttachment () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.FOLLOWUP_EVENT_DELETE_ATTACHMENT)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.UPDATE)
    },
    color () { return this.$store.getters['followups/ITEM_COLOR'](this.followUpData.$type) },
    customerList () { return this.$store.getters['$alto-companies/list'] },
    customerListSorted () {
      return this.$stratus.services.sort.natural(_.compact(this.customerList), { nameCode: 'asc' })
    },
    dueDate () {
      return this.followUpData.date_due ? this.$stratus.dt(this.followUpData.date_due).format('l') : this.$t('Undefined')
    },
    icon () { return this.$store.getters['followups/ICON_TYPE'](this.followUpData.$type) },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    percentLoadingAttachmentsCount () {
      return this.followUpData && this.followUpData.attachments && this.followUpData.attachments.length ? 100 * (this.followUpData.attachments.length - this.loadingAttachmentsCount) / this.followUpData.attachments.length : 0
    },
    priorityColor () {
      if (this.followUpData.priority === 'low') return 'lime--text'
      if (this.followUpData.priority === 'medium') return ''
      return 'danger--text'
    },
    priorityIcon () {
      if (this.followUpData.priority === 'low') return '$vuetify.icons.priority_low'
      if (this.followUpData.priority === 'medium') return '$vuetify.icons.priority_medium'
      return '$vuetify.icons.priority_high'
    },
    sendingFileProgress () { return this.files.length ? 100 * this.fileIndex / this.files.length : 0 },
    sortDate () {
      if (!this.followUpData.sortDate) return this.$t('Undefined')

      if (this.$stratus.dt(this.followUpData.sortDate).isAfter(this.$stratus.dt().startOf('year'))) return this.$stratus.dt(this.followUpData.sortDate).format('DD MMM HH:mm')
      return this.$stratus.dt(this.followUpData.sortDate).format('L HH:mm')
    },
    sortedMeta () {
      return _.sortBy(this.attachmentsMeta, ['name'])
    },
    statusColor () {
      if (this.followUpData.status === 'to_do') return ''
      if (this.followUpData.status === 'doing') return 'blue--text'
      return 'green--text'
    },
    statusIcon () {
      if (this.followUpData.status === 'to_do') return 'icon-task-to-do'
      if (this.followUpData.status === 'doing') return 'icon-task-in-progress'
      return 'icon-task-completed'
    },
    taskIsNotDone () {
      return this.followUpData.status !== 'done'
    },
    taskExpired () {
      return this.followUpData.date_due && this.$stratus.dt().isAfter(this.followUpData.date_due)
    }
  },
  methods: {
    async deleteAttachments ({ id, attachment }) {
      if (!id || !attachment) return

      try {
        this.isDeleting = true
        this.followUpData = await this.$store.dispatch('followups/taskAttachmentDel', { id, filename: attachment.name })
        this.loadFollowUpAttachmentsMeta(this.followUpData.attachments)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.isDeleting = false }, 1000)
      }
    },
    deleteItem (item) {
      if (!item) return

      this.$refs['confirm-event-delete']
        .open(
          this.$t('Delete call'),
          this.$t('Do you want to delete {subject}?', item),
          { color: 'red' }
        )
        .then(async (confirm) => {
          if (confirm) {
            try {
              await this.$store.dispatch('followups/deleteTask', item.id)
              this.$emit('updated', true)
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }
          }
        })
    },
    mimeIsImage (meta) {
      return meta && meta.mime && meta.mime.includes('image/')
    },
    mimeIsPdf (meta) {
      return meta && meta.mime && meta.mime.includes('application/pdf')
    },
    async loadAttachement () {
      if (this.tmpAttachmentList.length === 0) {
        setTimeout(() => {
          this.loadingAttachments = false
          this.loadingAttachmentsCount = 0
        }, 500)
        return
      }

      const attachment = this.tmpAttachmentList.pop()
      try {
        this.loadingAttachmentsCount = this.tmpAttachmentList.length
        const meta = await this.$store.dispatch(
          'documents/getFileMeta',
          attachment.path
        )
        meta.name = attachment.name
        meta.path = attachment.path
        meta.icon = getClassNameForExtension(
          attachment.path.split('.').pop()
        )
        meta.url = this.$stratus.services.api.path(`/documents/${attachment.path}?format=raw`)
        this.attachmentsMeta.push(meta)
      } catch (error) {
        this.attachmentsMeta.push({
          name: attachment.name,
          error: this.$stratus.services.format.sanitizeNotificationMessage(error)
        })
      }
      this.loadAttachement()
    },
    loadFollowUpAttachmentsMeta (attachments) {
      if (!attachments) return
      this.loadingAttachments = true
      this.attachmentsMeta = []
      this.tmpAttachmentList = [...attachments]
      this.loadAttachement()
    },
    onCloseDialog (saved) {
      this.$emit('updated', saved)
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    sanitizeBody (content) {
      if (!content) return ''
      // Sanitize and replace \n by html tag BR
      // Windows use \r\n, Linux/Unix use \n and MacOS use \r
      return this.$stratus.services.strings
        .stripHtmlTags(content)
        .replace(/\r\n/g, '<br>')
        .replace(/\r/g, '<br>')
        .replace(/\n/g, '<br>')
    },
    async sendAttachments () {
      try {
        if (!this.files.length) return
        this.sendingFiles = true
        this.followUpData = await this.$store.dispatch('followups/taskAttachmentAdd', { id: this.followUpData.id, files: this.files })
        this.loadFollowUpAttachmentsMeta(this.followUpData.attachments)
        this.files = []
        this.menuAttachments = false
      } catch (error) {
        console.error(error)
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.sendingFiles = false }, 500)
      }
    },
    updateItem (item) {
      if (this.canAdd && this.$refs['followup-task-dialog']) {
        this.$refs['followup-task-dialog'].open(item)
      }
    }
  }
}
</script>
