<template>
  <div>
    <v-tabs v-model="tab" class="transparent" hide-slider>
      <v-tab v-show="canShowDashboard" href="#tab-dashboard">
        <v-icon left small>
          $vuetify.icons.dashboardMarketing
        </v-icon>
        {{ $t('Marketing Dashboard') }}
      </v-tab>

      <v-tab href="#tab-events">
        <v-icon left small>
          $vuetify.icons.marketingEvent
        </v-icon>
        {{ $t('Campaigns') }}
      </v-tab>

      <v-tab href="#tab-leads">
        <v-icon left small>
          $vuetify.icons.leads
        </v-icon>
        {{ $t('Leads') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-if="canShowDashboard" value="tab-dashboard">
        <csm-marketing-dashboard ref="marketing-dashboard" />
      </v-tab-item>

      <v-tab-item value="tab-events">
        <sca-advanced-store-grid :options="optionsEvent" resource="opportunities/marketing/events" :columns="columnsEvent" @createItem="createEvent" @deleteItem="refresh" @updateItem="updateEvent" ref="marketing-event-grid">
          <template v-slot:item-id_parent="{ itemRaw, itemInternal }">
            <sca-marketing-event-identity v-if="itemRaw" :value="itemInternal || itemRaw" show-avatar link="emit" @link-click="openEvent(itemRaw)" />
          </template>
        </sca-advanced-store-grid>
      </v-tab-item>

      <v-tab-item value="tab-leads">
        <sca-advanced-store-grid :options="optionsLead" resource="opportunities/leads" :columns="columnsLead" :custom-search="customSearchLeads" @createItem="createLead" @deleteItem="refresh" @updateItem="updateLead" ref="marketing-lead-grid">
          <template #search-append>
            <v-btn-toggle v-model="filterByState" mandatory dense class="transparent" @change="refreshLeads">
              <v-btn small text :value="FILTER_HANDLED" :class="filterByState === FILTER_HANDLED ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('handled') }}
              </v-btn>
              <v-btn small text :value="FILTER_NOT_HANDLED" :class="filterByState === FILTER_NOT_HANDLED ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('unhandled') }}
              </v-btn>
              <v-btn small text :value="FILTER_ALL" :class="filterByState === FILTER_ALL ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('All') }}
              </v-btn>
            </v-btn-toggle>
          </template>

          <template v-slot:item-score="{ itemRaw }">
            <v-avatar size="36">
              {{ itemRaw || 0 }}
            </v-avatar>
          </template>

          <template v-slot:item-marketing_events_ids="{ itemRaw }">
            <template v-if="itemRaw?.length">
              <sca-marketing-event-identity v-for="id in itemRaw" :key="id" :value="id" show-avatar link="emit" @link-click="openEvent(itemRaw)" />
            </template>
            <span v-else class="font-italic">
              {{ $t('None') }}
            </span>
          </template>

          <template v-slot:item-id_lord_salesperson="{ itemRaw }">
            <sca-user-identity :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
          </template>

          <template v-slot:item-id_user="{ itemRaw }">
            <sca-user-identity :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
          </template>
        </sca-advanced-store-grid>
      </v-tab-item>
    </v-tabs-items>

    <csm-marketing-event-dialog ref="marketing-event-dialog" @closeDialog="closeEventDialog" />
    <csm-marketing-lead-dialog ref="marketing-lead-dialog" @closeDialog="closeLeadDialog" />
    <csm-user-dialog ref="user-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

const FILTER_ALL = 0
const FILTER_HANDLED = 1
const FILTER_NOT_HANDLED = 2

export default {
  name: 'OpportunitiesMarketingDashboard',
  components: {
    'csm-marketing-dashboard': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/MarketingDashboard'),
    'csm-marketing-event-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/MarketingEventDialog'),
    'csm-marketing-lead-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/MarketingLeadDialog')
  },
  data () {
    return {
      FILTER_ALL,
      FILTER_HANDLED,
      FILTER_NOT_HANDLED,
      eventTypeSearch: '',
      filterByState: FILTER_ALL,
      loading: false,
      tab: 'tab-dashboard'
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord',
      eventTypesList: '$alto-marketing/eventTypesList'
    }),
    canShowDashboard () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.TICKET_PRE_SALE_DASHBOARD)
    },
    columnsEvent () {
      return [{
        value: 'id',
        text: 'Id'
      }, {
        value: 'name',
        text: 'Name'
      }, {
        value: 'type',
        text: 'Type',
        format: v => this.$t(`marketing-event-type-${v}`)
      }, {
        value: 'date_begin',
        text: 'Begin date',
        format: v => v ? this.$stratus.services.fieldRenderers.DATE_SHORT(v) : this.$t('None')
      }, {
        value: 'date_end',
        text: 'End date',
        format: v => v ? this.$stratus.services.fieldRenderers.DATE_SHORT(v) : this.$t('None')
      }, {
        value: 'turnover_target',
        text: 'Turnover target',
        align: 'right',
        alignValue: 'right',
        format: v => this.$n(v, 'currency')
      }, {
        value: 'turnover_generated',
        text: 'Turnover generated',
        align: 'right',
        alignValue: 'right',
        format: v => this.$n(v, 'currency')
      }, {
        value: 'id_parent',
        text: 'Parent',
        internalKey: 'id' // id_parent is to be found in same data as column internalKey
      }, {
        value: 'create_at',
        text: 'Created at',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.DATETIME_SHORT
      }, {
        value: 'update_at',
        text: 'Updated at',
        format: this.$stratus.services.fieldRenderers.DATETIME_SHORT
      }, {
        value: 'create_by',
        text: 'Created by',
        hidden: true
      }, {
        value: 'update_by',
        text: 'Updated by',
        hidden: true
      }]
    },
    columnsLead () {
      return [{
        value: 'email',
        text: 'Email'
      }, {
        value: 'state',
        text: 'State',
        format: v => this.$t(v) || ''
      }, {
        value: 'firstname',
        text: 'Firstname',
        format: v => v || ''
      }, {
        value: 'lastname',
        text: 'Lastname',
        format: v => v || ''
      }, {
        value: 'phone',
        format: v => v || '',
        text: 'Phone'
      }, {
        value: 'company_name',
        text: 'Company',
        format: v => v || ''
      }, {
        value: 'function',
        text: 'Function',
        format: v => v || ''
      }, {
        value: 'score',
        text: 'Score'
      }, {
        value: 'id_user',
        text: 'User',
        hidden: true
      }, {
        value: 'id_lord_salesperson',
        text: 'Sales Person'
      }, {
        value: 'comment',
        text: 'Comment',
        format: v => this.$stratus.services.fieldRenderers.BOOLEAN_CHECK_IF_TRUE(v)
      }, {
        value: 'marketing_events_ids',
        text: 'Campaigns'
      }, {
        value: 'create_at',
        text: 'Created at',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.DATETIME_SHORT
      }, {
        value: 'create_by',
        text: 'Created by',
        hidden: true
      }]
    },
    eventTypeList () {
      return this.$store.getters['$alto-marketing/eventTypesList']
    },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    optionsEvent () {
      return {
        advancedSearchFieldsSchemaBaseUrl: '/docs/opportunities',
        advancedSearchFieldsSchemaName: 'MarketingEvent',
        allowContextMenuOnCell: true,
        allowColumnsVisible: true,
        create: true,
        createInFab: false,
        customShowItem: true,
        customUpdateItem: true,
        debounce: {
          onList: 500 // Request rate is one every 500ms
        },
        delete: true,
        key: 'id',
        multiSelect: false,
        notifyErrors: false,
        show: true,
        sortBy: 'update_at',
        sortDescending: true,
        update: true
      }
    },
    optionsLead () {
      return {
        advancedSearchFieldsSchemaBaseUrl: '/docs/opportunities',
        advancedSearchFieldsSchemaName: 'Lead',
        allowContextMenuOnCell: true,
        allowColumnsVisible: true,
        create: true,
        createInFab: false,
        customShowItem: true,
        customUpdateItem: true,
        debounce: {
          onList: 500 // Request rate is one every 500ms
        },
        delete: true,
        foreignFields: ['id', 'history'],
        key: 'id',
        multiSelect: false,
        notifyErrors: false,
        show: true,
        sortBy: 'email',
        sortDescending: false,
        update: true
      }
    }
  },
  methods: {
    closeEventDialog () {
      this.refresh()
    },
    closeLeadDialog () {
      this.refresh()
    },
    createEvent () {
      this.$refs['marketing-event-dialog'].open()
    },
    createLead () {
      this.$refs['marketing-lead-dialog'].open()
    },
    customSearchLeads () {
      const cusSearch = []
      switch (this.filterByState) {
        case FILTER_HANDLED:
          cusSearch.push({ column: 'state', search: 'handled' })
          break
        case FILTER_NOT_HANDLED:
          cusSearch.push({ column: 'state', search: 'unhandled' })
          break
      }

      return cusSearch
    },
    openEvent (id) {
      this.updateEvent({ item: { id }, success: true })
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    refresh () {
      if (this.$refs['marketing-event-grid']) this.$refs['marketing-event-grid'].fetchData()
      if (this.$refs['marketing-lead-grid']) this.$refs['marketing-lead-grid'].fetchData()
      if (this.$refs['marketing-dashboard']) this.$refs['marketing-dashboard'].refresh()
    },
    refreshLeads: _.debounce(function () {
      if (this.$refs['marketing-lead-grid']) this.$refs['marketing-lead-grid'].fetchData()
    }, 500),
    updateEvent ({ item, success, error }) {
      if (!success) {
        if (error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(error)
        return
      }
      this.$refs['marketing-event-dialog'].open(item.id)
    },
    updateLead ({ item, success, error }) {
      if (!success) {
        if (error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(error)
        return
      }
      this.$refs['marketing-lead-dialog'].open(item.id)
    }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Marketing Dashboard'))
  },
  mounted () {
    setTimeout(() => {
      if (this.canShowDashboard) this.tab = 'tab-dashboard'
      else this.tab = 'tab-events'
    }, 500)
  }
}
</script>
