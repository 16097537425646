<template>
  <sca-modal-dialog :visible="show" max-width="700" @closeDialog="close" @saveDialog="save" :can-save="canSave" :is-saving="isSaving" :action="action" :external-id="externalId()">
    <template #title>
      {{ subject }} — {{ update ? $t('Event') : $t('Add an event') }}
    </template>

    <div slot="content">
      <v-form ref="followup-form" v-model="valid" lazy-validation>
        <v-row align="center" dense>
          <v-col v-if="companyCode === null" cols="12" md="4">
            <sca-customer-select v-model="customer" :rules="[$stratus.services.form.rules.required]" class="required" :label="$t('Customer')" clearable @input="onChangeCustomer" show-email show-phone link="emit" @link-click="openCompany(customer)" />
          </v-col>

          <v-col>
            <sca-users-select v-model="organizer" filter-lord show-email show-phone show-company show-card :label="$t('Organizer')" :rules="[$stratus.services.form.rules.required]" class="required" link="emit" @link-click="openUser(organizer)" />
          </v-col>
        </v-row>

        <v-row align="center" no-gutters>
          <v-col cols="12">
            <sca-users-select v-model="guestList" :filter-company="customers" :label="$t('Attendees')" :rules="[$stratus.services.form.rules.required]" class="required" clearable multiple show-company />
          </v-col>
        </v-row>

        <v-row align="baseline" dense>
          <v-col cols="6" md="5">
            <v-select v-model="eventType" :items="eventTypeList" :label="$t('Type')" />
          </v-col>

          <v-col cols="12" md="7">
            <cs-date-time-range :from-date="dateBegin" :to-date="dateEnd" single-date required @changeFrom="onChangeFrom" @changeTo="onChangeTo" />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field v-model="subject" :label="$t('Subject')" class="required" />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <cs-wysiwyg-textarea v-model="description" :placeholder="$t('Description')" :title="$t('Description')" :actions="['bold', 'italic', 'underline', 'olist', 'ulist']" :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" />
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div v-if="!id" slot="buttons-prepend">
      <v-switch v-model="anotherOne" :label="$t('Create another event')" />
    </div>

    <sca-footer-create-update-at-by slot="footer" v-model="followup" :link-user="isLord" />

    <csm-company-dialog ref="company-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

let previousId = null

export default {
  name: 'FollowUpEventDialogLite',
  props: {
    companyCode: { type: String, default: null },
    update: { type: Boolean, default: false }
  },
  data () {
    return {
      DESCRIPTION_MAX_LENGTH: 4095,
      DESCRIPTION_MIN_LENGTH: 1,
      anotherOne: false,
      customer: null,
      dateBegin: null,
      dateEnd: null,
      description: '',
      eventType: '',
      followup: {},
      guestList: [],
      id: null,
      idSalesPerson: null,
      isSaving: false,
      organizer: null,
      show: false,
      subject: '',
      valid: false
    }
  },
  computed: {
    action () {
      if (this.canUpdate) return this.$t('Update')
      return this.$t('Details')
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.UPDATE)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.UPDATE)
    },
    icon () { return this.$store.getters['followups/ICON_TYPE'](this.$store.getters['followups/ITEM_TYPE_EVENT']) },
    color () { return this.$store.getters['followups/ITEM_COLOR'](this.$store.getters['followups/ITEM_TYPE_EVENT']) },
    customers () {
      if (!this.customer) return []
      const list = [this.customer]
      if (this.isLord) list.push(this.$store.getters.appConfig.defaults.corporationCode)
      return list
    },
    eventTypeList () {
      return this.$store.getters['$alto-followups/eventsTypes']
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    maxWidth () {
      if (this.$vuetify.breakpoint.xl) return '50%'
      if (this.$vuetify.breakpoint.lgAndUp) return '70%'
      return '100%'
    }
  },
  watch: {
    companyCode: {
      immediate: true,
      handler (newValue) {
        this.customer = newValue
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('$alto-followups/eventsTypes')
    if (this.$route?.name === 'followup-event' && this.$route.params.id) {
      if (this.$route.params.id !== previousId) {
        previousId = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        this.openId(this.$route.params.id)
      }
    }
  },
  methods: {
    close (saved = false) {
      this.show = false
      this.resolve(saved)
      if (this.anotherOne) {
        this.$emit('close-reopen', saved)
      } else {
        this.$emit('close', saved)
      }
    },
    customerName (id) { return this.$store.getters['$alto-companies/getName'](id) },
    externalId () {
      return this.id ? 'followup-event/' + this.id : ''
    },
    isDark () { return this.$store.getters['$stratus-states/isDark'] },
    async onChangeCustomer () {
      // Do not modify if a sales person is already input
      if (!this.customer || this.idSalesPerson) return null
      try {
        const salesPersonId = await this.$store.dispatch('$alto-companies/getSalesPerson', this.customer)
        if (_.isNumber(salesPersonId) && salesPersonId >= 0) {
          this.idSalesPerson = salesPersonId
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    onChangeFrom (value) {
      this.dateBegin = value
    },
    onChangeTo (value) {
      this.dateEnd = value
    },
    open (data) {
      this.followup = { ...data }
      this.reset(this.followup)
      this.show = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    async openId (id) {
      try {
        this.followup = await this.$store.dispatch('$alto-followups/event', id)
        this.open(this.followup)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async reset (data = {}) {
      if (this.$refs['followup-form']) await this.$refs['followup-form'].reset()
      this.id = data.id
      this.subject = data.subject || ''
      this.eventType = data.type
      this.guestList = data.id_guests || []

      if (this.$stratus.dt(data.date_begin).isAfter(this.$stratus.dt(data.date_end))) {
        // date begin after date end
        this.dateBegin = data.date_end || new Date()
        this.dateEnd = data.date_begin || new Date()
      } else {
        this.dateBegin = data.date_begin || new Date()
        this.dateEnd = data.date_end || new Date()
      }

      this.description = data.description || ''
      this.idSalesPerson = data.id_lord_salesperson
      this.customer = data.code || this.companyCode
      this.organizer = data.id_organizer || null
      this.anotherOne = data.anotherOne || false
      this.isSaving = false
      this.valid = false
    },
    save () {
      this.isSaving = true
      if (!this.$refs['followup-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      if (this.$stratus.dt(this.dateBegin).isAfter(this.$stratus.dt(this.dateEnd))) {
        [this.dateEnd, this.dateBegin] = [this.dateBegin, this.dateEnd]
      }

      const data = {
        id: this.id,
        subject: this.subject,
        type: this.eventType,
        id_guests: this.guestList,
        date_begin: this.$stratus.dt(this.dateBegin).toISOString(),
        date_end: this.$stratus.dt(this.dateEnd).toISOString(),
        description: this.description,
        id_lord_salesperson: this.idSalesPerson,
        code: this.customer,
        id_organizer: this.organizer
      }

      this.$store.dispatch('followups/saveEvent', data)
        .then(response => {
          this.resolve(response)
          this.close(true)
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
          this.isSaving = false
        })
    }
  }
}
</script>
