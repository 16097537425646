<template>
  <v-timeline align-top :dark="dark">
    <v-timeline-item v-for="(item, i) in history" :key="i" color="primary" fill-dot>
      <template v-slot:icon>
        <v-avatar :dark="dark">
          <v-icon class="white--text">
            icon-clock
          </v-icon>
        </v-avatar>
      </template>

      <template v-slot:opposite>
        <v-divider v-if="item.global" class="mb-5" />
        <span v-else-if="item.update_by">
          {{ $t('Requested by') }}
          <sca-user-identity :value="item.update_by" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(item.update_by)" />
        </span>
      </template>

      <v-card v-if="!item.global" outlined>
        <v-row class="text-subtitle-1 px-4" dense>
          <v-col cols="12" md="8">
            {{ $stratus.dt(item.update_at).format('LL LT') }}
          </v-col>
          <v-col v-if="item.state" class="text-right">
            {{ $t(`opp-state-${item.state}`) }}
          </v-col>
        </v-row>
        <cs-color-divider color="primary" />
        <v-card-text>
          {{ item.name }}
          <sca-opportunity-rates :value="item" dense />
        </v-card-text>
      </v-card>
      <cs-alert-panel v-else dense type="warning" :text="$t('The history below concerns the parent opportunity.')" />
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import _sortBy from 'lodash/sortBy'

export default {
  name: 'OpportunityHistoryTimeline',
  // eslint-disable-next-line vue/require-prop-types
  props: ['value'],
  data () { return {} },
  computed: {
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    history () {
      return _sortBy(this.value, item => {
        return -this.$stratus.dt(item.update_at)
      })
    }
  },
  methods: {
    openUser (id) {
      this.$emit('open-user', id)
    }
  }
}
</script>

<style scoped>
.divider-global {
  border-bottom: 2px solid;
}
</style>
