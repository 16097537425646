<template>
  <div class="background-blurred">
    <sca-month-filters @change="onInputChangeDebounced" :loading="loading" />

    <v-row dense align="start" class="mt-4">
      <v-col cols="12" md="6" lg="3" class="text-center">
        <div class="text-h5 primary--text">
          {{ $t('Sales generated by leads') }}
        </div>
        <!-- Value in kilo Euro (k€) -->
        <div v-if="!isNaN(data.total?.turnover_generated)">
          <cs-gauge hide-value
                    :value="data.total.turnover_generated / 1000"
                    :interval-timing="0"
                    :min="Math.floor(minValue(data.total.turnover_generated) / 1000)"
                    :max="Math.ceil(maxValue(data.total.turnover_generated) / 1000)"
                    :labels-on-arc="true"
                    :min-threshold="minThreshold(data.total.turnover_generated)"
                    :min-threshold-fill="COLOR_THRESHOLD_MIN"
                    :med-threshold="medThreshold(data.total.turnover_generated)"
                    :med-threshold-fill="COLOR_THRESHOLD_MED"
                    :max-threshold="maxThreshold(data.total.turnover_generated)"
                    :max-threshold-fill="COLOR_THRESHOLD_MAX"
                    :value-to-exceed-limits="true"
                    :active-fill="COLOR_THRESHOLD_MED"
                    :inactive-fill="COLOR_THRESHOLD_MAX"
                    :pointer-gap="3"
                    :thickness="20"
                    :radius="80"
                    :pointer-stroke-width="4"
                    :pivot-radius="6"
                    :pivot-stroke-width="3"
                    pivot-fill="none"
                    :heading-level="6"
                    font-size="0.8em"
                    :dp="PRICE_DIGITS"
                    :unit="$t('CURRENCY.symbolK')"
                    thresholds-always-visible
          />
          <div class="text-h4 font-weight-light text-center">
            {{ $n(data.total?.turnover_generated, 'currency') }}
          </div>
        </div>
        <div v-else class="text-center pt-4">
          {{ $t(loading ? 'Loading...' : 'No data...') }}
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="3" class="text-center">
        <div class="text-h5 primary--text">
          {{ $t('R.O.I. of shares') }}
        </div>
        <!-- Value in kilo Euro (k€) -->
        <div v-if="!isNaN(data.total?.roi)">
          <cs-gauge hide-value
                    :value="data.total.roi / 1000"
                    :interval-timing="0"
                    :min="-20"
                    :max="40"
                    :labels-on-arc="true"
                    :min-threshold="0.001"
                    :min-threshold-fill="COLOR_THRESHOLD_MIN"
                    :med-threshold="20"
                    :med-threshold-fill="COLOR_THRESHOLD_MED"
                    :max-threshold="40"
                    :max-threshold-fill="COLOR_THRESHOLD_MAX"
                    :value-to-exceed-limits="true"
                    :active-fill="COLOR_THRESHOLD_MED"
                    :inactive-fill="COLOR_THRESHOLD_MAX"
                    :pointer-gap="3"
                    :thickness="20"
                    :radius="80"
                    :pointer-stroke-width="4"
                    :pivot-radius="6"
                    :pivot-stroke-width="3"
                    pivot-fill="none"
                    :heading-level="6"
                    font-size="0.8em"
                    :dp="PRICE_DIGITS"
                    :unit="$t('CURRENCY.symbolK')"
                    thresholds-always-visible
          />
          <div class="text-h4 font-weight-light text-center">
            {{ $n(data.total?.roi, 'currency') }}
          </div>
        </div>
        <div v-else class="text-center pt-4">
          {{ $t(loading ? 'Loading...' : 'No data...') }}
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="3" class="text-center">
        <div class="text-h5 primary--text">
          {{ $t('Total number of leads') }}
        </div>
        <div v-if="!isNaN(data.total?.nb_leads)">
          <cs-gauge hide-value
                    :value="data.total.nb_leads"
                    :min="0"
                    :max="90"
                    :labels-on-arc="true"
                    :min-threshold="30"
                    :min-threshold-fill="COLOR_THRESHOLD_MIN"
                    :med-threshold="60"
                    :med-threshold-fill="COLOR_THRESHOLD_MED"
                    :max-threshold="90"
                    :max-threshold-fill="COLOR_THRESHOLD_MAX"
                    :value-to-exceed-limits="true"
                    :active-fill="COLOR_THRESHOLD_MED"
                    :inactive-fill="COLOR_THRESHOLD_MAX"
                    :pointer-gap="3"
                    :thickness="20"
                    :radius="80"
                    :pointer-stroke-width="4"
                    :pivot-radius="6"
                    :pivot-stroke-width="3"
                    pivot-fill="none"
                    :heading-level="6"
                    font-size="0.8em"
                    :dp="PRICE_DIGITS"
                    thresholds-always-visible
          />
          <div class="text-h4 font-weight-light text-center">
            {{ $n(data.total?.nb_leads) }}
          </div>
        </div>
        <div v-else class="text-center pt-4">
          {{ $t(loading ? 'Loading...' : 'No data...') }}
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="3" class="text-center">
        <div class="text-h5 primary--text">
          {{ $t('Leads converted to opportunities') }}
        </div>
        <div v-if="!isNaN(data.total?.nb_converted_leads)">
          <cs-gauge hide-value
                    :value="data.total.nb_converted_leads"
                    :min="0"
                    :max="30"
                    :labels-on-arc="true"
                    :min-threshold="10"
                    :min-threshold-fill="COLOR_THRESHOLD_MIN"
                    :med-threshold="20"
                    :med-threshold-fill="COLOR_THRESHOLD_MED"
                    :max-threshold="30"
                    :max-threshold-fill="COLOR_THRESHOLD_MAX"
                    :value-to-exceed-limits="true"
                    :active-fill="COLOR_THRESHOLD_MED"
                    :inactive-fill="COLOR_THRESHOLD_MAX"
                    :pointer-gap="3"
                    :thickness="20"
                    :radius="80"
                    :pointer-stroke-width="4"
                    :pivot-radius="6"
                    :pivot-stroke-width="3"
                    pivot-fill="none"
                    :heading-level="6"
                    font-size="0.8em"
                    :dp="PRICE_DIGITS"
                    thresholds-always-visible
          />
          <div class="text-h4 font-weight-light text-center">
            {{ $n(data.total?.nb_converted_leads) }}
          </div>
        </div>
        <div v-else class="text-center pt-4">
          {{ $t(loading ? 'Loading...' : 'No data...') }}
        </div>
      </v-col>
    </v-row>

    <v-row dense align="start" class="mt-4">
      <v-col cols="12" md="6" lg="4">
        <div class="text-h5 primary--text text-center">
          {{ $t('Turnover generated by type of event') }}
        </div>
        <cs-chart-donut v-show="turnoverByEventType?.labels?.length" ref="chart-turnover-by-event" class="chart-max-height" :styles="chartStyles" :options="chartDonutOptions" :chart-data="turnoverByEventType" />
        <div class="text-h4 font-weight-light text-center">
          {{ $n(turnoverByEventTypeTotal, 'currency') }}
        </div>
        <div v-show="!turnoverByEventType?.labels?.length" class="text-center pt-4">
          {{ $t(loading ? 'Loading...' : 'No data...') }}
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <div class="text-h5 primary--text text-center">
          {{ $t('Campaigns with the most impact') }}
        </div>
        <cs-chart-horizontal-bar v-show="campaignMostImpact?.labels?.length" :chart-data="campaignMostImpact" :options="barChartOptions" :styles="chartStyles" ref="chart-campaign-impact" />
        <div v-show="!campaignMostImpact?.labels?.length" class="text-center pt-4">
          {{ $t(loading ? 'Loading...' : 'No data...') }}
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <div class="text-h5 text-center primary--text">
          {{ $t('Amount spent by type of event') }}
        </div>
        <cs-chart-donut v-show="data?.per_type" ref="chart-cost-by-type" class="chart-max-height" :styles="chartStyles" :options="chartDonutOptions" :chart-data="costByType" />
        <div class="text-h4 font-weight-light text-center">
          {{ $n(costByTypeTotal, 'currency') }}
        </div>
        <div v-show="!data?.per_type" class="text-center pt-4">
          {{ $t(loading ? 'Loading...' : 'No data...') }}
        </div>
      </v-col>
    </v-row>

    <csm-marketing-event-dialog ref="marketing-event-dialog" @closeDialog="closeEventDialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

const COLOR_THRESHOLD_MAX = '#A34C00'
const COLOR_THRESHOLD_MED = '#FF871F'
const COLOR_THRESHOLD_MIN = '#FFE1C6'

const PRICE_DIGITS = 0

const DEFAULT_CHART_DATA = {
  labels: [],
  datasets: [{
    backgroundColor: [],
    data: []
  }]
}

const MAX_GAUGE = 24000

export default {
  name: 'MarketingDashboard',
  components: {
    'csm-marketing-event-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/MarketingEventDialog')
  },
  data () {
    return {
      COLOR_THRESHOLD_MAX,
      COLOR_THRESHOLD_MED,
      COLOR_THRESHOLD_MIN,
      DONUT_PALETTE: this.$alto.defines.COLORS.PALETTE_MIX_BIGYOP,
      GRAPH_PALETTE: this.$alto.defines.COLORS.PALETTE_BLUE_REVERSE,
      MAX_GAUGE,
      PRICE_DIGITS,
      campaignMostImpact: { ...DEFAULT_CHART_DATA, openEvent: this.openEvent },
      costByType: { ...DEFAULT_CHART_DATA },
      costByTypeTotal: 0,
      data: {},
      dateBeginMem: null,
      dateEndMem: null,
      loading: true,
      thresholdMax: 100,
      thresholdMed: 66,
      thresholdMin: 33,
      turnoverByEventType: { ...DEFAULT_CHART_DATA },
      turnoverByEventTypeTotal: 0
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord',
      eventTypesList: '$alto-marketing/eventTypesList'
    }),
    barChartOptions () {
      return {
        formatPrice: this.formatPrice,
        defaultFontFamily: 'Outfit-light',
        // Click on a bar to show it's event
        onClick (e) {
          /* --- IMPORTANT! ---
           * This is a native click event, outside Vue scope!
           * Value of «this» is not vue but the chart instance this event is plug to.
           */
          const element = this.getElementsAtEventForMode(e, 'nearest', { intersect: false, axis: 'y' })
          if (element.length) {
            const bar = element[0]
            if (bar?._index >= 0 && bar?._datasetIndex >= 0) {
              // Create a focus effect by changing the background color of the bar
              // Reset all colors
              _.forEach(this.data.datasets, dataset => {
                dataset.backgroundColor = dataset._backgroundColor
              })
              // Set the focus
              const bc = new Array(this.data.datasets[bar._datasetIndex].data.length).fill(this.data.datasets[bar._datasetIndex]._backgroundColor) // Restore native color
              bc[bar._index] = this.data.datasets[bar._datasetIndex].borderColor
              this.data.datasets[bar._datasetIndex].backgroundColor = bc

              // const label = this.scales['y-axis-0'].getLabelForIndex(bar._index, bar._datasetIndex)
              this.data.openEvent(this.data.datasets[bar._datasetIndex].eventId[bar._index])
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            stacked: false,
            ticks: {
              ...this.$stratus.services.charts.getFontProps(this.isDark),
              callback: function (value) {
                // Truncate long text
                return _.truncate(value, { length: 32, omission: this.chart.data.omission })
              }
            }
          }],
          xAxes: [{
            type: 'linear',
            ticks: {
              // beginAtZero: true,
              // min: 0,
              callback: function (value) {
                // Display only ticks for integer value
                return this.chart.options.formatPrice(value, true)
              }
            }
          }]
        },
        tooltips: {
          enabled: true,
          intersect: false,
          callbacks: {
            label: (tooltipItems, data) => {
              return this.formatPrice(tooltipItems.xLabel, true)
            }
          }
        }
      }
    },
    chartDonutOptions () {
      return {
        // Click on a part to load it's data
        onClick (e) {
          // FUTURE
          /* --- IMPORTANT! ---
           * This is a native click event, outside Vue scope!
           * Value of «this» is not vue but the chart instance this event is plugged to.
           */
          // const element = this.getElementAtEvent(e)
          // if (element.length > 0) {
          //   // Forge a query to load data for this part
          //   this.data.datasets[element[0]._datasetIndex].computeQuery(
          //     this.data.datasets[element[0]._datasetIndex].query[element[0]._index],
          //     this.data.caption + ' > ' + this.data.labels[element[0]._index],
          //     this.data.datasets[element[0]._datasetIndex].ticketIds[element[0]._index]
          //   )
          //   this.update()
          // }
        },
        responsive: true,
        animation: {
          animateRotate: true,
          animateScale: true
        },
        maintainAspectRatio: false,
        // FIXME: if exception « Cannot read property 'transition' of null » still occurs,
        // uncomment the following:
        // animation: {
        //     duration: 0
        // },
        // hover: {
        //     animationDuration: 0
        // },
        responsiveAnimationDuration: 0,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
          }
        },
        legend: {
          display: true,
          position: 'left'
        },
        tooltips: {
          enabled: true,
          intersect: false,
          callbacks: {
            label: (tooltipItems, data) => {
              return data.labels[tooltipItems.index] + ' : ' + this.$n(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] || 0, 'currency')
            }
          }
        },
        circumference: 2 * Math.PI,
        rotation: -Math.PI / 2
      }
    },
    chartStyles () {
      return {
        height: '200px',
        width: '100%',
        position: 'relative'
      }
    }
  },
  methods: {
    addSector (chart, name, value, color, query, ids, data) {
      const currentDataset = chart.datasets[0]
      chart.caption = this.$t('Turnover generated by type of event')
      chart.labels.push(name)
      currentDataset.data.push(value)
      currentDataset.query.push(query)
      currentDataset.ids.push(ids)
      currentDataset.backgroundColor.push(color.html)
      currentDataset._backgroundColor.push(color.html)
      currentDataset.borderColor = this.$alto.defines.COLORS.WARNING
      currentDataset.borderWidth = 0
      currentDataset.computeQuery = this.computeQuery
    },
    closeEventDialog () {
      this.createGraphCampaignImpact() // Restore colors
    },
    createGraphCampaignImpact () {
      const dataSets = [{
        backgroundColor: [],
        _backgroundColor: [],
        borderColor: [],
        hoverBorderColor: [],
        borderWidth: 0,
        eventId: [],
        data: [],
        minBarLength: 2 // This is to allow to click on bar that have width 0
      }]
      const labels = []

      if (this.data?.per_roi?.length) {
        _.forEach(this.data.per_roi, (roi, index) => {
          if (roi.roi >= 0) {
            labels.push(roi.event.name)
            dataSets[0].backgroundColor.push(this.GRAPH_PALETTE[8].opacity80)
            dataSets[0]._backgroundColor.push(this.GRAPH_PALETTE[8].opacity80)
            dataSets[0].borderColor.push(this.$alto.defines.COLORS.PRIMARY)
            dataSets[0].hoverBorderColor.push(this.$alto.defines.COLORS.PRIMARY)
            dataSets[0].eventId.push(roi.id) // this is used to open event dialog
            dataSets[0].data.push(roi.roi)
          }
        })
      }

      this.campaignMostImpact.omission = this.$t('...') // Used in ticks callback
      this.campaignMostImpact.labels = labels
      this.campaignMostImpact.datasets = dataSets

      this.$nextTick(() => {
        if (this.$refs['chart-campaign-impact']) this.$refs['chart-campaign-impact'].update()
      })
    },
    createGraphCostByType () {
      this.resetChart(this.costByType)
      this.costByTypeTotal = 0

      if (this.data?.per_type) {
        let colorIndex = 0
        _.forEach(this.eventTypesList, eventType => {
          if (this.data?.per_type[eventType.value]?.total_cost > 0) {
            const color = this.DONUT_PALETTE[colorIndex]
            this.addSector(
              this.costByType,
              this.$t(`marketing-event-type-${eventType.value}`),
              this.data?.per_type[eventType.value].total_cost || 0,
              color
            )
            this.costByTypeTotal += this.data?.per_type[eventType.value].total_cost || 0
            colorIndex++
            if (colorIndex >= this.DONUT_PALETTE.length) colorIndex = 0
          }
        })
      }

      this.$nextTick(() => {
        if (this.$refs['chart-cost-by-type']) this.$refs['chart-cost-by-type'].update()
      })
    },
    createGraphTurnoverByEventType () {
      this.resetChart(this.turnoverByEventType)
      this.turnoverByEventTypeTotal = 0

      if (this.data?.per_type) {
        let colorIndex = 0
        _.forEach(this.eventTypesList, eventType => {
          if (this.data?.per_type[eventType.value]?.turnover_generated) {
            const color = this.DONUT_PALETTE[colorIndex]
            this.addSector(
              this.turnoverByEventType,
              this.$t(`marketing-event-type-${eventType.value}`),
              this.data?.per_type[eventType.value].turnover_generated || 0,
              color
            )
            this.turnoverByEventTypeTotal += this.data?.per_type[eventType.value].turnover_generated || 0
            colorIndex++
            if (colorIndex >= this.DONUT_PALETTE.length) colorIndex = 0
          }
        })
      }

      this.$nextTick(() => {
        if (this.$refs['chart-turnover-by-event']) this.$refs['chart-turnover-by-event'].update()
      })
    },
    formatPrice (price, asCurrency) {
      return asCurrency ? this.$n(price, 'currency') : this.$stratus.services.format.asDigits(price, PRICE_DIGITS)
    },
    maxThreshold (value) {
      return this.formatPrice(this.thresholdMax)
    },
    maxValue (value) {
      return this.formatPrice(Math.max(MAX_GAUGE, value))
    },
    medThreshold (value) {
      const range = this.maxValue(value) - this.minValue(value)
      return this.formatPrice(range * this.thresholdMed / 100)
    },
    minThreshold (value) {
      const range = this.maxValue(value) - this.minValue(value)
      return this.formatPrice(range * this.thresholdMin / 100)
    },
    minValue (value) {
      return this.formatPrice(Math.min(0, value))
    },
    onInputChange ({ dateBegin, dateEnd } = {}) {
      if (!this.$stratus.dt(dateBegin).isValid() || !this.$stratus.dt(dateEnd).isValid()) return

      this.refresh({ dateBegin, dateEnd })
    },
    openEvent (id) {
      this.$refs['marketing-event-dialog'].open(id)
    },
    async refresh ({ dateBegin, dateEnd } = {}) {
      if (!dateBegin) dateBegin = this.dateBeginMem
      if (!dateEnd) dateEnd = this.dateEndMem

      if (!this.$stratus.dt(dateBegin).isValid() || !this.$stratus.dt(dateEnd).isValid()) return

      try {
        this.loading = true
        this.dateBeginMem = dateBegin
        this.dateEndMem = dateEnd
        await this.$store.dispatch('$alto-marketing/loadEventTypes')
        const data = await this.$store.dispatch('$alto-marketing/getDashboard', {
          dateBegin: this.$stratus.dt(dateBegin).startOf('month'),
          dateEnd: this.$stratus.dt(dateEnd).endOf('month')
        })
        this.data = data || {}
        this.createGraphTurnoverByEventType()
        this.createGraphCampaignImpact()
        this.createGraphCostByType()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    resetChart (chart) {
      this.$set(chart, 'labels', [])
      this.$set(chart, 'datasets', [{
        backgroundColor: [],
        _backgroundColor: [],
        data: [],
        query: [],
        ids: []
      }])
    }
  },
  created () {
    this.onInputChangeDebounced = _.debounce(this.onInputChange, 1000)
  }
}
</script>
