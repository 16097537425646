<template>
  <csm-ticket-grid :options="gridOptions" pref-key="ticketGrid" filter-all :auto-refresh-delay="2" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TicketsList',
  components: {
    'csm-ticket-grid': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketGrid')
  },
  data () { return {} },
  watch: {
    isLord (newValue, oldValue) {
      if (newValue === false) {
        this.$router.replace('/tickets/list')
      }
    }
  },
  computed: {
    ...mapGetters({
      isLord: '$stratus-states/isLord'
    }),
    gridOptions () {
      return {
        multiSelect: true
      }
    }
  },
  async mounted () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Tickets'))
    const me = await this.$store.getters['$stratus-states/me']
    if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

    if (!this.$store.getters['$stratus-states/isLord']) {
      this.$router.replace('/tickets/list')
    }
  }
}
</script>
