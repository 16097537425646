<template>
  <v-dialog v-model="show" persistent scrollable :max-width="maxWidth">
    <v-card tile flat>
      <v-card-title class="pa-2">
        <img class="ml-8 beta-icon" src="/img/beta.png">
        <v-icon class="menu-icon--text" @click="close()">
          $vuetify.icons.close
        </v-icon>
        <div class="ml-4 text-h6 pl-2">
          {{ caption || id }} — {{ $t('Task') }}
        </div>
      </v-card-title>

      <cs-color-divider color="lime" />

      <v-card-text>
        <v-simple-table v-if="history && history.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ $t('Date') }}
                </th>
                <th>
                  {{ $t('Type') }}
                </th>
                <th>
                  {{ $t('Message') }}
                </th>
                <th>
                  {{ $t('Body') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in historySorted" :key="index" dense>
                <td class="text-no-wrap">
                  {{ $stratus.dt(line.date).format('l LTS') }}
                </td>
                <td>
                  {{ line.response }}
                </td>
                <td>
                  {{ line.information }}
                </td>
                <td>
                  {{ line.body }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row v-else justify="center" class="mt-4">
          <v-col class="shrink text-no-wrap">
            <cs-alert-panel dense type="info" :text="$t('No history')" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn rounded @click="close">
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'UserTaskDialog',
  data () {
    return {
      caption: '',
      history: [],
      id: null,
      show: false
    }
  },
  computed: {
    historySorted () {
      // Sort by date descending
      const list = _.sortBy(this.history, line => {
        return -this.$stratus.dt(line.date)
      })
      return list
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    maxWidth () {
      if (this.$vuetify.breakpoint.xl) return '50%'
      if (this.$vuetify.breakpoint.lgAndUp) return '70%'
      return '100%'
    }
  },
  methods: {
    close () {
      this.resolve(false)
      this.show = false
    },
    isDark () { return this.$store.getters['$stratus-states/isDark'] },
    open (options) {
      this.show = true
      this.reset(options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async reset (options = { data: {} }) {
      if (this.$refs['schedule-form']) await this.$refs['schedule-form'].reset()
      if (this.$refs['task-data']) this.$refs['task-data'].reset()
      this.caption = options.data.caption || ''
      this.id = options.data.id || null
      this.history = options.data.history || []
    }
  },
  async mounted () {
    await this.$store.dispatch('$stratus-states/getMe')
    const me = this.$store.getters['$stratus-states/me']
    this.$alto.services.routes.connectionForbidden.call(this, me)
  }
}
</script>

<style scoped>
.beta-icon {
  position: absolute;
  left: 8px;
  height: 40px;
}
</style>
