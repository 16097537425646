<template>
  <v-card class="pa-2" v-bind="$attrs">
    <div class="full-width d-flex align-center">
      <img src="/img/brevo.svg" :height="24" class="mr-4">

      <slot name="content" />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'MarketingEmailingPanel'
}
</script>
