<template>
  <csm-order-form :ref="`order-form-${uuid}`" :order="order" :order-id="orderId" :show="canShow" :update="canUpdate" @closeDialog="closeDialog" :company-name="companyName" @refreshOrder="onRefresh" />
</template>

<script>
let previousOrder = null

export default {
  name: 'OrderDialog',
  components: { 'csm-order-form': () => import(/* webpackChunkName: "components" */ './OrderForm') },
  data () {
    return {
      companyName: null,
      order: {},
      orderId: null,
      uuid: this.$stratus.uuid()
    }
  },
  computed: {
    canReadCatalog () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST)
    },
    canReadPrices () {
      return !this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.LIST_NO_PRICE)
    },
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.READ)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.UPDATE)
    }
  },
  methods: {
    closeDialog () {
      if (this.$refs[`order-form-${this.uuid}`].display) this.$refs[`order-form-${this.uuid}`].display(false)
      previousOrder = null
      if (this.$route?.name === 'order' && this.$route?.params?.id) {
        this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
      } else {
        this.$emit('closeDialog', this.order)
      }
    },
    // fetchCatalog () {
    //   if (!this.canReadCatalog) return
    //   this.$store.dispatch('$alto-catalog/list')
    //     .catch(error => {
    //       this.$stratus.services.notify.error(error)
    //     })
    //     .finally(() => {
    //       setTimeout(() => { this.loading = false }, 500)
    //     })
    // },
    getOrder (id) {
      if (!id) return
      this.$store.dispatch('orders/get', id)
        .then(response => {
          if (response) {
            this.order = response
            return this.$store.dispatch('$alto-companies/getByCode', this.order.code)
          }
          return null
        })
        .then(company => {
          this.companyName = (company || {}).name || ''
        })
        .catch(error => {
          if (error.status === 404) this.$stratus.services.notify.error(this.$t('Order «{name}» not found!', { name: id }))
          else this.$stratus.services.notify.error(error)
          this.closeDialog()
        })
    },
    onRefresh (order) {
      if (!order.id) {
        this.order = { ...order }
        this.$forceUpdate()
        return
      }
      // Reload order
      this.$store.dispatch('orders/get', order.id)
        .then(response => {
          if (response) {
            this.order = response
            this.$forceUpdate()
          }
        })
        .catch(error => this.$stratus.services.notify.error(error))
    },
    open (id) {
      if (!id) return
      this.orderId = id
      // this.fetchCatalog()
      this.getOrder(this.orderId)
      if (this.$refs[`order-form-${this.uuid}`].display) this.$refs[`order-form-${this.uuid}`].display(true)
    }
  },
  async mounted () {
    // Example: https://manager-int-cloudsystem.aws.scalair.fr/#/order/1
    if (this.$route?.name === 'order' && this.$route.params.id) {
      if (this.$route.params.id !== previousOrder) {
        previousOrder = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        this.open(this.$route.params.id)
      }
    }
  }
}
</script>
