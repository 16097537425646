export const OPPORTUNITY_STATE_ABANDONED = 'abandoned'
export const OPPORTUNITY_STATE_LOST = 'lost'
export const OPPORTUNITY_STATE_PROPOSAL = 'proposal'
export const OPPORTUNITY_STATE_QUALIFICATION = 'qualification'
export const OPPORTUNITY_STATE_WON = 'won'

export const OPPORTUNITY_STATES_READ_ONLY_CREATE = [OPPORTUNITY_STATE_ABANDONED, OPPORTUNITY_STATE_LOST]
export const OPPORTUNITY_STATES_CREATION = [OPPORTUNITY_STATE_PROPOSAL, OPPORTUNITY_STATE_WON]
export const OPPORTUNITY_CHANGE_CLOSING_DATE = [OPPORTUNITY_STATE_PROPOSAL, OPPORTUNITY_STATE_QUALIFICATION, OPPORTUNITY_STATE_WON]

export const OPPORTUNITY_ALWAYS_UPDATABLE_FIELDS = ['id_lord_salesperson', 'owner', 'notes']
export const OPPORTUNITY_QUALIFICATION_UPDATABLE_FIELDS = ['closing_date', 'probability', 'company', 'name', 'price', 'price_setup', 'buying', 'buying_setup']
export const OPPORTUNITY_PROPOSAL_UPDATABLE_FIELDS = ['probability']

export const OPPORTUNITY_LINK_CART = 'cart'
export const OPPORTUNITY_LINK_NONE = 'no'
export const OPPORTUNITY_LINK_ORDER_LINE = 'oneline'

export default {
  OPPORTUNITY_ALWAYS_UPDATABLE_FIELDS,
  OPPORTUNITY_CHANGE_CLOSING_DATE,
  OPPORTUNITY_STATE_ABANDONED,
  OPPORTUNITY_STATE_LOST,
  OPPORTUNITY_STATE_PROPOSAL,
  OPPORTUNITY_STATE_QUALIFICATION,
  OPPORTUNITY_STATE_WON,
  OPPORTUNITY_STATES_READ_ONLY_CREATE,
  OPPORTUNITY_STATES_CREATION,
  OPPORTUNITY_LINK_CART,
  OPPORTUNITY_LINK_NONE,
  OPPORTUNITY_LINK_ORDER_LINE
}
