<template>
  <sca-modal-dialog :visible="visible" :loading="isLoading || isSaving" @closeDialog="closeDialog" @saveDialog="saveDialog" :action="action" :can-save="canSave && !isLoading" :is-saving="isSaving" max-width="70%">
    <div slot="title" dense>
      {{ this.$t('Tickets automation') }}
      <span v-show="isDirty()" class="ml-2">*</span>
    </div>

    <div slot="content">
      <v-form ref="ticket-automation-form" v-model="valid" lazy-validation :disabled="!canSave">
        <v-row>
          <v-col cols="12" md="8">
            <v-row align="center" dense>
              <v-col class="shrink">
                <sca-ticket-type-select v-model="ticket.type" :label="$t('Type')" :disabled="!canSave" />
              </v-col>

              <v-col class="mx-2">
                <v-text-field v-model="ticket.name" :label="$t('Title')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(128)]" class="text-h5 required" counter="128" />
              </v-col>
            </v-row>

            <v-row dense class="pb-4">
              <v-col cols="12">
                <div>
                  {{ $t('Description') }}
                </div>

                <v-card slot="content" outlined tile>
                  <sca-wysiwyg-editor ref="ticket-automation-description-editor" v-model="ticket.description" :placeholder="$t('Description')" :customers="ticket.code" :disabled="!canSave" small :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" />
                </v-card>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <cs-expand-panel v-if="ticket.ids_tickets_created?.length > 0" :header="$t('Tickets')" :count="ticket.ids_tickets_created?.length">
                  <sca-ticket-list-panel slot="content" :value="ticket.ids_tickets_created" can-open block show-icon show-severity link="emit" @link-click="openTicket" />
                </cs-expand-panel>
              </v-col>
            </v-row>

            <v-row align="center" no-gutters class="mt-4">
              <v-col cols="12" class="d-flex align-center">
                <v-icon left>
                  $vuetify.icons.scheduling
                </v-icon>

                <div class="text-h6">
                  {{ $t('Scheduling') }}
                </div>

                <div v-if="ticket.last_date_launch" class="d-flex align-center text-caption ml-4">
                  {{ $t('Last run') }}
                  <cs-alert-panel v-show="cronExplain" dense no-icon class="ml-2 justify-center" type="success" :text="$stratus.dt(ticket.last_date_launch).format('LL LT')" />
                </div>

                <div class="d-flex align-center text-caption ml-4">
                  {{ $t('Next run') }}
                  <cs-alert-panel v-show="cronExplain" dense no-icon class="ml-2 justify-center" :text="cronNext" />
                </div>

                <v-btn v-if="ticket.id" rounded small class="ml-auto primary-button" :disabled="showScheduling" @click="showScheduling = !showScheduling">
                  {{ $t('Modify scheduling...') }}
                </v-btn>
              </v-col>

              <v-col cols="12" class="d-flex align-center">
                <v-switch v-model="ticket.cancel_last_ticket" :label="$t('Cancel the previous ticket when creating the new one.')" dense />
              </v-col>
            </v-row>

            <v-row align="start" dense>
              <v-col cols="12" md="6" lg="8">
                <div v-show="!ticket.id || showScheduling">
                  {{ $t('Choose how often this task should be performed.') }}

                  <v-radio-group v-model="cronScheduling" @change="explainScheduling" class="ma-0">
                    <v-radio value="every_day">
                      <template v-slot:label>
                        {{ $t('Every day') }}
                      </template>
                    </v-radio>

                    <v-radio value="every_week">
                      <template v-slot:label>
                        <div class="d-flex align-center">
                          <div class="text-no-wrap mr-4">
                            {{ $t('Every week on') }}
                          </div>
                          <cs-day-select v-model="cronDayWeek" hide-details dense @change="explainScheduling" />
                        </div>
                      </template>
                    </v-radio>

                    <v-radio value="every_month">
                      <template v-slot:label>
                        <div class="d-flex align-center">
                          <div class="text-no-wrap mr-4">
                            {{ $t('Every month, the') }}
                          </div>
                          <cs-integer-input v-model="cronDayMonth" :min="1" :max="31" hide-details dense @input="explainScheduling" />
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="4">
            <!-- DETAILS -->
            <v-row dense>
              <v-col cols="12">
                <v-card outlined tile class="pa-2">
                  <cs-expand-panel :header="$t('Details')" block expanded>
                    <template slot="content">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <div class="mt-2 d-flex">
                            <div class="shrink mr-4 text-no-wrap">
                              {{ $t('Customer') }}
                            </div>

                            <sca-customer-select v-model="ticket.code" :rules="[$stratus.services.form.rules.required]" class="required" :customers-only="ticket?.type !== TICKET_TYPE_PRE_SALE" dense hide-details link="emit" @link-click="openCompany(ticket.code)" />
                          </div>
                        </v-col>

                        <v-col cols="12">
                          <div class="mt-2">
                            <div class="shrink text-no-wrap mb-2">
                              {{ $t('Assigned to') }}
                            </div>
                            <v-row dense>
                              <v-col cols="11" offset="1">
                                <sca-users-select v-model="ticket.id_owner" :filter-company="companies" :disabled="!canSave" clearable no-bot show-card show-company show-email show-team dense link="emit" @link-click="openUser(ticket.id_owner)" @change="onOwnerChange" />
                              </v-col>

                              <v-col cols="11" offset="1">
                                <sca-teams-select v-model="ticket.id_lord_team" dense :label="$t('Team')" clearable :disabled="ticket?.type === TICKET_TYPE_PRE_SALE || !canSave" @change="onTeamChange" />
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>

                        <v-col cols="12">
                          <div class="d-flex">
                            <div class="shrink mr-4 text-no-wrap">
                              {{ ticket?.type === TICKET_TYPE_PRE_SALE ? $t('Sales consultant') : $t('Referrer') }}
                            </div>

                            <sca-users-select v-model="ticket.id_referring" :filter-company="companies" :disabled="!canSave" clearable no-bot show-company show-email show-card dense link="emit" @link-click="openUser(ticket.id_referring)" />
                          </div>
                        </v-col>

                        <v-col cols="12">
                          <div class="d-flex">
                            <div class="shrink mr-4 text-no-wrap">
                              {{ $t('Severity') }}
                            </div>

                            <sca-ticket-severity-select v-model="ticket.severity" :disabled="!canSave" dense :rules="[$stratus.services.form.rules.required]" class="required" />
                          </div>
                        </v-col>

                        <v-col cols="12">
                          <div class="d-flex">
                            <div class="shrink mr-4 text-no-wrap">
                              {{ $t('Sphere') }}
                            </div>

                            <sca-sphere-select v-model="ticket.id_sphere" :customer="ticket.code" :readonly="!canSave" dense hide-details />
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </cs-expand-panel>
                </v-card>
              </v-col>
            </v-row>

            <!-- TICKET LIFE -->
            <v-row dense>
              <v-col cols="12">
                <v-card outlined tile class="pa-2">
                  <cs-expand-panel :header="$t('Ticket life')" block>
                    <template #content>
                      <v-col cols="12">
                        <div class="d-flex align-center text-no-wrap pr-4">
                          <v-icon left>
                            $vuetify.icons.time
                          </v-icon>
                          {{ $t('Estimated time') }}
                        </div>

                        <sca-ticket-time-input v-model="ticket.estimated_time" hide-details dense required no-padding :disabled="!canSave" @change="onChangeEstimatedTime" />
                      </v-col>
                    </template>
                  </cs-expand-panel>
                </v-card>
              </v-col>
            </v-row>

            <!-- LINKS -->
            <v-row dense>
              <v-col cols="12">
                <v-card outlined tile class="pa-2">
                  <cs-expand-panel :header="$t('Links')" block>
                    <template #content>
                      <v-row align="center" dense>
                        <v-col cols="12" md="4">
                          <v-select v-model="linkTo" :items="ticketLinkToList" :placeholder="$t('Link...')" dense hide-details @change="linkToId = null" />
                        </v-col>

                        <v-col>
                          <sca-quotation-select v-if="linkTo === LINK_TO_CART" v-model="linkToId" dense hide-details :company="ticket.code" :placeholder="$t('Quotation')" />
                          <sca-opportunity-select v-if="linkTo === LINK_TO_OPPORTUNITY" v-model="linkToId" dense hide-details :company="ticket.code" :placeholder="$t('Opportunity')" :filter-company="ticket.code" />
                          <sca-order-select v-if="linkTo === LINK_TO_ORDER" v-model="linkToId" dense hide-details :company="ticket.code" :placeholder="$t('Order')" />
                          <sca-product-select v-if="linkTo === LINK_TO_PRODUCT" v-model="linkToId" dense hide-details :placeholder="$t('Product')" />
                          <sca-subscription-select v-if="linkTo === LINK_TO_SUBSCRIPTION" v-model="linkToId" dense hide-details :company="ticket.code" :placeholder="$t('Subscription')" only-subscriptions />
                        </v-col>

                        <v-col class="shrink">
                          <v-btn rounded class="main-button" :disabled="!linkToId" @click="addOtherLink">
                            {{ $t('Link to') }}
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <ticket-links ref="ticket-links" :ticket="ticket" />
                        </v-col>
                      </v-row>
                    </template>
                  </cs-expand-panel>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>

      <csm-company-dialog ref="company-dialog" />
      <csm-ticket-dialog ref="ticket-dialog" />
      <csm-user-dialog ref="user-dialog" />
      <cs-confirm-dialog ref="confirm-ticket-dialog" />
    </div>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import cronParser from 'cron-parser'
import cronstrue from 'cronstrue/i18n'

const LINK_TO_CART = 'carts'
const LINK_TO_OPPORTUNITY = 'opportunities'
const LINK_TO_ORDER = 'orders'
const LINK_TO_PRODUCT = 'products'
const LINK_TO_SUBSCRIPTION = 'onelines'

const DEFAULT_CRON_TIME = '0 7' // at 7'o'clock
const DEFAULT_CRON = `${DEFAULT_CRON_TIME} * * *`

export default {
  name: 'TicketAutomationForm',
  components: {
    'ticket-links': () => import(/* webpackChunkName: "components" */ './TicketLinks.vue')
  },
  props: {
    show: { type: Boolean, default: true },
    update: { type: Boolean, default: false },
    value: { type: Object, required: true, default: () => { return {} } }
  },
  data () {
    return {
      DEFAULT_CRON,
      DEFAULT_CRON_TIME,
      DESCRIPTION_MAX_LENGTH: 524288, // 500KB
      DESCRIPTION_MIN_LENGTH: 0,
      LINK_TO_CART,
      LINK_TO_OPPORTUNITY,
      LINK_TO_ORDER,
      LINK_TO_PRODUCT,
      LINK_TO_SUBSCRIPTION,
      TICKET_TYPE_PRE_SALE: this.$alto.defines.TICKETS.TICKET_TYPE_PRE_SALE,
      cronExplain: '',
      cronNext: '',
      cronScheduling: 'every_day',
      crontab: DEFAULT_CRON,
      cronDayMonth: 1,
      cronDayWeek: 0,
      cronTime: null,
      cronTimeMonth: null,
      cronTimeWeek: null,
      isSaving: false,
      isLoading: false,
      linkTo: null,
      linkToId: null,
      showScheduling: false,
      ticket: {},
      ticketLinkToList: [],
      valid: false,
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    action () {
      if (this.update) return this.$t('Update')
      return this.show ? this.$t('Details') : this.$t('Create')
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_LAUNCHERS, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_LAUNCHERS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    companies () {
      const companies = [this.me.company]
      if (this.ticket?.code) companies.push(this.ticket?.code)
      if (this.me?.customers) companies.concat(this.me?.customers)
      return companies
    },
    nextDate () {
      return cronParser.parseExpression(this.cronify(), { tz: this.$stratus.dt.tz.guess() }).next()
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue) && (!_.isEmpty(newValue) || oldValue !== undefined)) {
          this.ticket = { ...newValue }
        }
      }
    }
  },
  methods: {
    addOtherLink () {
      const ids = { ...this.ticket.ids_intern || {} }
      if (!ids[this.linkTo]) ids[this.linkTo] = []
      if (Array.isArray(this.linkToId)) ids[this.linkTo] = ids[this.linkTo].concat(this.linkToId)
      else ids[this.linkTo].push(this.linkToId)
      ids[this.linkTo] = _.uniq(ids[this.linkTo])
      this.linkToId = null
      this.$set(this.ticket, 'ids_intern', { ...ids }) // This will refresh display
      this.expandLinks = true
    },
    closeDialog (saved) {
      if (!this.canSave || !this.isDirty()) {
        this.$emit('closeDialog', saved)
        return
      }

      this.$refs['confirm-ticket-dialog'].open(this.$t('Modifications detected'), this.$t('Do you confirm the loss of your modifications?'))
        .then(confirmed => {
          if (confirmed) {
            this.$emit('closeDialog', saved)
          }
        })
    },
    cronify () {
      let cronExpr
      switch (this.cronScheduling) {
        case 'every_day':
          cronExpr = DEFAULT_CRON
          break
        case 'every_week':
          cronExpr = `${DEFAULT_CRON_TIME} * * ${this.cronDayWeek}`
          break
        case 'every_month':
          cronExpr = `${DEFAULT_CRON_TIME} ${this.cronDayMonth} * *`
          break
        default:
          cronExpr = this.crontab // Expert
          break
      }
      if (cronExpr) {
        this.cronExplain = cronstrue.toString(cronExpr, {
          use24HourTimeFormat: this.$store.getters['$stratus-states/timeFormat'] === '24hr',
          locale: this.$store.getters['$stratus-states/countryCode']
        })
      }
      this.crontab = cronExpr
      return cronExpr
    },
    explainScheduling () {
      this.cronNext = this.$stratus.dt(this.nextDate).format('LL LT')
    },
    display (show = false) {
      this.visible = show
    },
    isDirty () {
      if (!this.originalTicket) return false
      const currentTicket = this.sanitizeTicket(this.ticket)
      return !_.isEqual(currentTicket, this.originalTicket)
    },
    onChangeEstimatedTime (value) {
      this.$set(this.ticket, 'estimated_time', value)
    },
    async onOwnerChange (owner) {
      if (!owner) return
      try {
        const user = await this.$store.dispatch('$alto-users/getById', owner)
        if (user) {
          this.$set(this.ticket, 'id_lord_team', user.lord_team_id)
        }
      } catch (error) {
        console.error('[onOwnerChange]', error)
      }
    },
    onTeamChange () {
      this.$set(this.ticket, 'id_owner', null)
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openTicket (ticket) {
      if (ticket?.id && this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticket.id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    sanitizeTicket (ticket) {
      const result = {
        ...ticket,
        cron: this.crontab,
        name: this.$stratus.services.strings.stripHtmlTags(ticket.name),
        id_owner: ticket.id_owner || '',
        id_referring: ticket.id_referring || '',
        estimated_date: ticket.estimated_date || null
      }
      if (this.$refs['ticket-automation-description-editor']) {
        result.description = this.$refs['ticket-automation-description-editor'].getContent()
      }
      return result
    },
    async saveDialog () {
      if (!this.canSave) return false

      if (!this.$refs['ticket-automation-form']?.validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        return false
      }

      this.isSaving = true
      try {
        const ticket = this.sanitizeTicket(this.ticket)
        await this.$store.dispatch('$alto-ticketing/saveTicketLauncher', ticket)
        if (this.update) {
          this.$stratus.services.notify.success(this.$t('Ticket {name} updated.', ticket))
        } else {
          this.$stratus.services.notify.success(this.$t('Ticket {name} created.', ticket))
        }
        this.resetOriginal()
        this.closeDialog(true)
      } catch (error) {
        this.$stratus.services.notify.error(error)
        return false
      } finally {
        this.isSaving = false
      }
    },
    async reset (ticket) {
      this.isLoading = true
      if (this.$refs['ticket-automation-form']) await this.$refs['ticket-automation-form'].reset()
      this.linkTo = null
      this.linkToId = null
      this.showScheduling = false
      this.cronExplain = ''
      this.cronNext = ''
      this.cronDayMonth = 1
      this.cronDayWeek = 0
      this.cronTime = null
      this.cronTimeMonth = null
      this.cronTimeWeek = null
      this.cronScheduling = ticket?.cron ? 'expert' : 'every_day'
      this.crontab = ticket?.cron || DEFAULT_CRON
      this.ticket = { ...ticket }
      this.explainScheduling()
      setTimeout(() => {
        this.resetOriginal()
        this.isLoading = false
      }, 500)
    },
    resetOriginal () {
      this.originalTicket = { ...this.sanitizeTicket(this.ticket) }
    }
  },
  created () {
    this.ticketLinkToList = [
      { value: LINK_TO_CART, text: this.$t('Link a quotation') },
      { value: LINK_TO_OPPORTUNITY, text: this.$t('Link an opportunity') },
      { value: LINK_TO_ORDER, text: this.$t('Link an order') },
      { value: LINK_TO_PRODUCT, text: this.$t('Link a product') },
      { value: LINK_TO_SUBSCRIPTION, text: this.$t('Link a subscription') }
    ]
  }
}
</script>
