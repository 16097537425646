<template>
  <div v-if="isLord">
    <v-row dense align="baseline">
      <v-col cols="12" md="6" lg="3">
        <sca-sales-person-select v-model="salesPersonIds" allow-all clearable multiple show-email show-phone show-company show-card :label="$t('Sales Persons')" dense single-line />
      </v-col>

      <v-col cols="6" lg="">
        <cs-date-picker v-model="beginAt" allow-empty format-short :disabled="loading" :label="$t('From date')" />
      </v-col>

      <v-col cols="6" lg="">
        <cs-date-picker v-model="endAt" allow-empty format-short :disabled="loading" :label="$t('To date')" />
      </v-col>

      <v-col cols="12" lg="5">
        <v-btn small class="shortcut-button mr-2" @click="setDateRange(RANGE_LAST_WEEK)">
          <v-icon small left color="menu-icon">
            icon-clock
          </v-icon>
          {{ $t('Last week') }}
        </v-btn>
        <v-btn small class="shortcut-button mr-2" @click="setDateRange(RANGE_CURRENT_WEEK)">
          <v-icon small left color="menu-icon">
            icon-clock
          </v-icon>
          {{ $t('Current week') }}
        </v-btn>
        <v-btn small class="shortcut-button mr-2" @click="setDateRange(RANGE_CURRENT_MONTH)">
          <v-icon small left color="menu-icon">
            icon-clock
          </v-icon>
          {{ $t('Current month') }}
        </v-btn>
        <v-btn small class="shortcut-button mr-2" @click="setDateRange(RANGE_CURRENT_YEAR)">
          <v-icon small left color="menu-icon">
            icon-clock
          </v-icon>
          {{ $t('Current year') }}
        </v-btn>
      </v-col>

      <v-col class="shrink">
        <cs-refresh-button :disabled="loading || !salesPersonIds.length" :loading="loading" @click="refreshData" />
      </v-col>
    </v-row>

    <v-row no-gutters align="center" justify="center">
      <v-col v-if="!canDisplayFollowUp" class="shrink text-no-wrap">
        <cs-alert-panel :text="$t('Please select one or more options above and press button refresh to display the follow-ups.')" />
      </v-col>
      <v-col v-else-if="canListFollowUp" cols="12" class="background-blurred">
        <csm-follow-up-list ref="global-follow-up" allow-add :sales-persons="salesPersonIds" :begin-at="beginAt" :end-at="endAt" @loading="onFollowupLoading" @loaded="onFollowupLoaded" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  components: {
    'csm-follow-up-list': () => import('@/components/FollowUps/FollowUpList')
  },
  data (vm) {
    return {
      RANGE_CURRENT_YEAR: this.$alto.defines.DATES.RANGE_CURRENT_YEAR,
      RANGE_CURRENT_MONTH: this.$alto.defines.DATES.RANGE_CURRENT_MONTH,
      RANGE_CURRENT_WEEK: this.$alto.defines.DATES.RANGE_CURRENT_WEEK,
      RANGE_LAST_WEEK: this.$alto.defines.DATES.RANGE_LAST_WEEK,
      addButtonVisible: false,
      attachmentsMeta: [],
      beginAt: null,
      endAt: null,
      call: {},
      canDisplayFollowUp: false,
      customers: [],
      filter: [],
      followUp: {},
      followUpCount: 0,
      followUps: [],
      loading: false,
      me: {},
      salesPersons: [],
      salesPersonIds: [],
      search: '',
      tab: 'tab-content'
    }
  },
  computed: {
    canListFollowUp () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.LIST) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.LIST)
    },
    customerList () { return this.$store.getters['$alto-companies/list'] },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    isSalesPersonManager () { return this.myself ? this.myself.is_manager : false },
    ITEM_TYPES () { return this.$store.getters['followups/ITEM_TYPES'] },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    myIdSalesPerson () { return this.myself ? this.myself.value : null },
    myself () { return _.find(this.salesPersons, { mail: this.me.email }) || {} }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Follow-up'))
    this.resetFollowup()
    this.resetFilter()
  },
  async mounted () {
    try {
      await this.$store.dispatch('$alto-companies/list', 'code,name,type,id_lord_salesperson')
      this.me = await this.$store.dispatch('$stratus-states/getMe')
      if (!this.isLord) {
        this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
        return
      }

      await this.$store.dispatch('$alto-users/loadSalesPersons')
      this.salesPersons = this.$store.getters['$alto-users/salesPersons']

      if (!this.isSalesPersonManager && this.myIdSalesPerson) this.salesPersonIds = [this.myIdSalesPerson]
      else if (this.isLord) this.salesPersonIds = ['ALL']

      this.setDateRange(this.RANGE_CURRENT_WEEK, false)
    } catch (error) {
      this.$stratus.services.notify.error(error)
    }
  },
  methods: {
    customerName (id) { return this.$store.getters['$alto-companies/getName'](id) },
    onFollowupLoaded (count) {
      this.followUpCount = count
      this.loading = false
    },
    onFollowupLoading (loading) {
      this.loading = loading
    },
    onFollowupDialogClose (saved) {
      if (saved) {
        // Reload data
        this.refreshData()
      }
    },
    onSearch (value) {
      this.search = value || ''
    },
    refreshData () {
      this.canDisplayFollowUp = true
      if (this.$refs['global-follow-up']) this.$refs['global-follow-up'].refresh()
    },
    resetFilter () {
      const filtered = [] // Use a local var to avoir multiple vue DOM update if we were using this.filter.
      this.ITEM_TYPES.forEach((itemType, index) => {
        filtered.push(index)
      })
      this.filter = filtered
    },
    resetFollowup () {
      this.followUp = {}
      this.ITEM_TYPES.forEach(itemType => {
        this.followUp[itemType] = []
      })
    },
    setDateRange (rangeType, refresh = true) {
      switch (rangeType) {
        case this.RANGE_CURRENT_YEAR:
          this.beginAt = this.$stratus.dt().startOf('year').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('year').format('YYYY-MM-DD')
          break
        case this.RANGE_CURRENT_MONTH:
          this.beginAt = this.$stratus.dt().startOf('month').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('month').format('YYYY-MM-DD')
          break
        case this.RANGE_CURRENT_WEEK:
          this.beginAt = this.$stratus.dt().startOf('week').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('week').format('YYYY-MM-DD')
          break
        case this.RANGE_LAST_WEEK:
          this.beginAt = this.$stratus.dt().startOf('week').subtract(1, 'week').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('week').subtract(1, 'week').format('YYYY-MM-DD')
          break
      }
      if (refresh) this.refreshData()
    }
  }
}
</script>
