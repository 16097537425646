const actions = {
  list ({ commit, state, rootState }) {
    if (state.list && state.list.length) return
    return rootState.$stratus.services.api.get('/newsletters?limit=0&fields=id,name')
      .then((response) => {
        commit('setList', { rootState, data: response.results })
      })
      .catch((error) => commit('API_FAILURE', error))
  }
}

const mutations = {
  API_FAILURE (state, error) {
    if (error.status === 401) {
      console.warn('[API] status 401', error)
    } else throw error
  },
  setList (state, { rootState, data }) {
    state.list = rootState.$stratus.services.sort.natural((data || []), { name: 'asc' })
  }
}

export default {
  namespaced: true,
  state: {
    list: []
  },
  getters: {
    list: state => {
      return state.list || []
    }
  },
  actions,
  mutations
}
