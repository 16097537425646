<template>
  <csm-subscription-form ref="subscription-form" :visible="dialogVisible" :subscription="subscription" :catalog-product="catalogProduct" :show="canShow" :update="canUpdate" :can-modify="canModifyProduct" @closeDialog="closeDialog" @refresh="onRefresh" @modify-product="modifyProduct" />
</template>

<script>
import _ from 'lodash'

let previousSubscription = null

export default {
  name: 'SubscriptionDialog',
  components: { 'csm-subscription-form': () => import(/* webpackChunkName: "components" */ './SubscriptionForm') },
  data () {
    return {
      catalogProduct: {},
      dialogVisible: false,
      subscription: {}
    }
  },
  computed: {
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.READ)
    },
    canModifyProduct () {
      return this.subscription.state === 'completed' && this.subscription.periodicity !== 'O' && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_MODIFY)
    },
    canUpdate () {
      return this.subscription?.id && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.UPDATE)
    }
  },
  methods: {
    closeDialog () {
      this.dialogVisible = false
      previousSubscription = null
      if (this.$route?.name === 'subscription' && this.$route?.params?.id) {
        this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
      } else {
        this.$emit('closeDialog', this.subscription)
      }
    },
    async get (id) {
      try {
        if (_.isObject(id)) {
          this.subscription = id || {}
        } else {
          this.subscription = { ...await this.$store.dispatch('$alto-subscriptions/get', id) }
        }
        this.catalogProduct = this.$store.getters['$alto-catalog/get'](this.subscription.ref)
      } catch (error) {
        if (error.status === 404) this.$stratus.services.notify.error(this.$t('Subscription «{name}» not found!', { name: id }))
        else this.$stratus.services.notify.error(error)
        this.closeDialog()
      }
    },
    async onRefresh (data) {
      if (!data.id) {
        this.subscription = { ...data }
        this.$forceUpdate()
        return
      }
      // Reload
      await this.get(data.id)
      this.$forceUpdate()
    },
    open (id) {
      if (!id) return
      this.get(id)
      this.dialogVisible = true
    },
    modifyProduct (subscription) {
      this.$emit('modify-product', subscription)
    },
    async reset () {
      if (this.$refs['subscription-form']) await this.$refs['subscription-form'].reset()
    }
  },
  async mounted () {
    if (this.$route?.name === 'subscription' && this.$route.params.id) {
      if (this.$route.params.id !== previousSubscription) {
        previousSubscription = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        this.open(this.$route.params.id)
      }
    }
  }
}
</script>
