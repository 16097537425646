<template>
  <csm-contract-form :visible="isVisible" :contract="contract" :update="update" :show="show" @closeDialog="closeDialog" ref="ContractForm" />
</template>

<script>

export default {
  components: { 'csm-contract-form': () => import('./ContractForm') },
  data () {
    return {
      contract: {},
      isVisible: true,
      show: false,
      update: false
    }
  },
  methods: {
    closeDialog () {
      this.isVisible = false
      this.$router.back()
    },
    getContract (code) {
      this.show = this.$store?.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.COMPANIES, this.$alto.API_PERMISSIONS.READ)
      this.update = this.$store?.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.COMPANIES, this.$alto.API_PERMISSIONS.UPDATE)
      this.$store.dispatch('$alto-contracts/get', code)
        .then(response => {
          if (response) {
            this.contract = response
          }
          return this.$refs.ContractForm.reset(this.contract)
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    }
  },
  async mounted () {
    // Example: https://manager-int-cloudsystem.aws.scalair.fr/#/contracts/1
    if (this.$route?.name === 'contract' && this.$route.params.id) {
      await this.$store.dispatch('$stratus-states/getMe')
      const me = this.$store.getters['$stratus-states/me']
      if (this.$alto.services.routes.connectionForbidden.call(this, me)) return
      this.getContract(this.$route.params.id)
    }
  }
}
</script>
