<template>
  <div class="background-blurred">
    <v-row>
      <v-col cols="12" :lg="selected ? 6 : 12">
        <v-card flat tile class="overflow-y-auto" scrollable :max-height="containerHeight">
          <v-card-text>
            <v-treeview v-model="tree" ref="documents-tree" :key="treeKey" :load-children="fetchFolder" :items="items" :active.sync="active" active-class="focused" activatable open-all return-object dense>
              <template v-slot:prepend="{ item, open }">
                <v-icon v-if="item.type === TYPE_DIR" small>
                  {{ open ? '$vuetify.icons.folderOpen' : '$vuetify.icons.folder' }}
                </v-icon>
                <v-icon v-else small>
                  {{ item.icon }}
                </v-icon>
              </template>
              <template v-slot:label="{ item, active }">
                <div v-if="item.companyId">
                  <sca-company-identity :value="item.companyId" width="600px" label-prepend show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(item.companyId)" />
                </div>
                <span v-else class="clickable" :class="active ? 'pa-1 font-weight-bold button-main button-main-ink--text' : ''">{{ item.name }}</span>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="selected" cols="12" :lg="selected ? 6 : 12">
        <v-card flat tile class="background-blurred-color6 full-height d-flex flex-column">
          <div class="flex-grow-1 d-flex flex-column text-center">
            <!-- UPLOAD ERRORS -->
            <div v-if="selected.type === TYPE_DIR && canSend && uploadFiles.length" cols="12">
              <div v-for="(file, index) in uploadFiles" :key="index" cols="12">
                <v-icon v-if="file.error" color="error">
                  $vuetify.icons.error
                </v-icon>
                <cs-icon-loading v-else-if="file.sending && !file.uploaded" />
                <v-icon v-else :color="file.uploaded ? 'success' : ''">
                  {{ file.uploaded ? 'icon-checkmark-circle' : 'icon-checkmark' }}
                </v-icon> {{ file.name }}
                <span v-if="file.error" class="text-caption primary--text clickable" @click="sendFile(file.bin, index)">({{ $t('Retry') }})</span>
              </div>
            </div>

            <!-- PREVIEW -->
            <v-card v-if="selected.type !== TYPE_DIR" outlined tile class="transparent">
              <div v-if="isImage(selected)">
                <img :src="$stratus.services.api.path(`/documents/${selected.path}?format=raw`)" width="90%">
              </div>
              <div v-else-if="isPDF(selected)" class="ml-auto mr-auto" style="width: 70%">
                <sca-pdf-viewer-form :file-path="selected.path" toolbar-bottom />
              </div>
            </v-card>
          </div>

          <!-- TOOLBAR -->
          <div class="mt-auto d-flex align-center">
            <div v-if="selected.type !== TYPE_DIR" class="text-caption text-center text-truncate">
              <v-icon small right>
                {{ selected.icon }}
              </v-icon>
              {{ selected.name }}
              ({{ $t('{count}{unit}', $stratus.services.format.bytesToBestUnit(selected.size)) }})
            </div>
            <v-spacer />

            <div v-if="selected.type === TYPE_DIR" class="px-4">
              <cs-file-upload-button v-if="selected.type === TYPE_DIR && canSend" text rounded ref="document-upload-button" :default-text="$t('Send documents')" @fileChanged="onFileChanged" @reset="onResetUploads" multiple />
            </div>

            <div class="px-4">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click.native.stop="downloadItem(selected)" :disabled="isDownloading" icon>
                    <cs-icon-loading v-if="isDownloading" />
                    <v-icon v-else>
                      $vuetify.icons.download
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Download') }}</span>
              </v-tooltip>
              <v-progress-circular v-if="downloadProgress > 0" v-model="downloadProgress" :indeterminate="downloadStart" />
            </div>

            <div v-if="canRead && isPDF(selected)" class="px-4">
              <v-tooltip v-if="navigator.clipboard" top>
                <template #activator="{ on }">
                  <v-btn v-on="on" @click.native.stop="copyToClipboard($event, selected)" :disabled="isReading" icon>
                    <cs-icon-loading v-if="isReading" />
                    <v-icon v-else>
                      $vuetify.icons.link
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Copy URL to clipboard') }}</span>
              </v-tooltip>
            </div>

            <div v-if="canDelete" class="px-4">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="danger" @click.native.stop="deleteItem(selected)" :disabled="isDeleting" icon>
                    <cs-icon-loading v-if="isDeleting" />
                    <v-icon v-else>
                      $vuetify.icons.delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Delete') }}</span>
              </v-tooltip>
            </div>

            <div v-if="canCreateFolder" class="px-4">
              <v-menu offset-y :close-on-content-click="false" v-model="menuCreateFolder">
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn v-on="{ ...tooltip, ...menu }" icon>
                        <v-icon>
                          $vuetify.icons.folder
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Create folder here') }}</span>
                  </v-tooltip>
                </template>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{ $t('Create folder:') }}
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <v-form ref="folderNameForm" v-model="folderNameValid" lazy-validation>
                          <v-text-field v-model="folderName" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.folderName, $stratus.services.form.rules.max(64)]" class="required" counter="64" @keyup.enter.stop="createFolder(selected)" />
                        </v-form>
                        <kbd>{{ selected.dir }}{{ folderName }}</kbd>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn @click.native.stop="createFolder(selected)" :disabled="isCreatingFolder || !folderNameValid" icon>
                        <v-icon>$vuetify.icons.add</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <cs-confirm-dialog ref="confirm-file-action" />
    <sca-pdf-viewer-dialog ref="pdf-document-file-viewer" :external-id="externalId" />
    <csm-company-dialog ref="company-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import getClassNameForExtension from 'font-awesome-filetypes'

const TYPE_DIR = 'd'
const TYPE_FILE = '-'

export default {
  name: 'Documents',
  data: () => ({
    TYPE_DIR,
    TYPE_FILE,
    active: [],
    container: null,
    containerHeight: 0,
    downloadProgress: 0,
    downloadStart: false,
    externalId: '',
    open: [],
    items: [{ id: 1, name: '/', type: 'd', children: [], icon: 'icon-folder', path: '' }], // Root directory
    isCreatingFolder: false,
    isDeleting: false,
    isDownloading: false,
    isReading: false,
    isUploading: false,
    onResizeHandler: null,
    showFile: true,
    treeKey: 0,
    menuCreateFolder: false,
    navigator,
    folderName: '',
    folderNameValid: false,
    tree: [],
    uploadFiles: []
  }),
  watch: {
    menuCreateFolder (newVal) {
      if (newVal === true) {
        // Reset menu v-form on opening
        if (this.$refs.folderNameForm) this.$refs.folderNameForm.reset()
      }
    }
  },
  computed: {
    breadcrumb () {
      const path = this.selected?.path ? this.selected.path.split('/') : []
      _.map(path, item => {
        return {
          text: item,
          href: ''
        }
      })
      return path
    },
    canCreateFolder () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.DOCUMENTS, this.$alto.API_PERMISSIONS.CREATE)
    },
    canDelete () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.DOCUMENTS, this.$alto.API_PERMISSIONS.DELETE)
    },
    canRead () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.DOCUMENTS, this.$alto.API_PERMISSIONS.READ)
    },
    canSend () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.DOCUMENTS, this.$alto.API_PERMISSIONS.CREATE)
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    selected () {
      if (!this.active.length) return undefined
      return this.active[0]
    }
  },
  methods: {
    copyToClipboard (ev, item) {
      let url
      if (ev.ctrlKey || ev.metaKey) {
        url = `[${item.name}](${window.location.origin}/#/documents?path=${item.path}&name=${item.name}&mime=${item.mime})`
      } else {
        url = `${window.location.origin}/#/documents?path=${item.path}&name=${item.name}&mime=${item.mime}`
      }

      if (this.$stratus.services.clipboard.copyText(url)) {
        this.$stratus.services.notify.success(this.$t('URL {url} copied to clipboard.', { url }))
      } else {
        this.$stratus.services.notify.success(this.$t('Error copying to clipboard!'))
      }
    },
    createFolder (item) {
      if (!this.$refs.folderNameForm.validate()) return
      this.isCreatingFolder = true
      this.$store.dispatch('documents/createFolder', { path: item.dir + this.folderName })
        .then((folder) => {
          let parent = item
          if (item.type === TYPE_FILE) parent = item.parent
          if (parent) {
            parent.children.push({
              children: [],
              mime: folder.mime,
              name: this.folderName,
              dir: item.dir + this.folderName + '/',
              path: item.dir + this.folderName,
              parent,
              size: folder.size,
              type: folder.type
            })
            parent.children = this.sortByName(parent.children)
          }
        })
        .then(() => {
          this.$stratus.services.notify.success(this.$t('Folder {name} created.', { name: item.dir + this.folderName }))
          this.menuCreateFolder = false
          this.treeKey = this.treeKey + 1
          this.isCreatingFolder = false
        })
        .catch(error => this.$stratus.services.notify.error(error))
    },
    displayDownloadProgress ({ loaded, total, percent }) {
      this.downloadStart = percent === 0
      this.downloadProgress = percent
    },
    isDark () {
      return this.$store.getters['$stratus-states/isDark']
    },
    isPDF (item) {
      return item.mime === 'application/pdf'
    },
    isImage (item) {
      return item.mime.includes('image/')
    },
    deleteItem (item) {
      let title, msg
      if (item.type === TYPE_DIR) {
        title = this.$t('Delete folder')
        msg = this.$t('Confirm deletion of folder «{name}»?', { name: item.name })
      } else {
        title = this.$t('Delete file')
        msg = this.$t('Confirm deletion of file «{name}»?', { name: item.name })
      }
      this.$refs['confirm-file-action']
        .open(title, msg, { color: 'red' })
        .then(async (confirm) => {
          if (confirm) {
            this.isDeleting = true
            try {
              await this.$store.dispatch('documents/deleteFile', { path: item.path, recursive: item.type === TYPE_DIR })
              this.$stratus.services.notify.success(this.$t('File {name} deleted.', { name: item.name }))
              this.$stratus.services.array.removeObject(this.items, 'id', item.id, 'children')
              this.active = []
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }
            this.isDeleting = false
          }
        })
    },
    setHeight () {
      if (!isNaN(this.container?.clientHeight)) {
        this.containerHeight = this.container.clientHeight - 90 // remove breadcrumb
      }
    },
    showItem (item) {
      if (!item || !item.path || !this.isPDF(item)) return
      this.isReading = true
      this.externalId = `documents?path=${item.path}&name=${item.name}&mime=application/pdf`
      this.$refs['pdf-document-file-viewer'].open(item.path, item.name).then()
      this.isReading = false
    },
    async downloadItem (item) {
      if (!item || !item.path) return

      this.downloadStart = true
      this.downloadProgress = 0

      if (item.type === TYPE_FILE) {
        this.isLoading = true
        await this.$stratus.services.api.downloadProgress(`/documents${item.path}?format=raw`, item.name, false, {}, this.displayDownloadProgress)
        setTimeout(() => { this.isLoading = false }, 3000)
        return
      }

      if (item.type === TYPE_DIR) {
        this.isLoading = true
        await this.$stratus.services.api.downloadProgress(`/documents${item.path}?format=zip`, item.name + '.zip', false, {}, this.displayDownloadProgress)
        setTimeout(() => { this.isLoading = false }, 3000)
      }
    },
    fetchFolder (item) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const content = await this.$store.dispatch('documents/openFolder', _.trimEnd(item.path, '/') || '')
          let f
          const children = []
          for (let i = 0; i < content.children.length; i++) {
            f = {
              id: this.$stratus.uuid(),
              parent: item,
              dir: item.path + '/' + (content.children[i].type === TYPE_DIR ? content.children[i].name + '/' : ''),
              path: item.path + '/' + content.children[i].name,
              type: content.children[i].type,
              mime: content.children[i].mime,
              name: content.children[i].name + (content.children[i].meta && content.children[i].meta.company ? ` — ${content.children[i].meta.company}` : ''),
              companyId: content.children[i]?.meta?.company?.length ? content.children[i].name : null,
              meta: content.children[i].meta,
              size: content.children[i].size,
              icon: content.children[i].type === TYPE_DIR ? 'icon-folder' : getClassNameForExtension(content.children[i].name.split('.').pop()),
              ...(content.children[i].type === TYPE_DIR && { children: [] })
            }
            children.push(f)
          }
          resolve(children)
        } catch (error) {
          reject(error)
        }
      })
        .then(data => {
          item.children = this.sortByName(data)
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    readFile (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        let fileContent
        reader.onError = (e) => {
          reader.abort()
          reject(e)
        }
        reader.onload = (e) => {
          fileContent = e.target.result
          resolve(fileContent)
        }
        reader.readAsArrayBuffer(file)
      })
    },
    async sendFile (file, index) {
      try {
        this.uploadFiles[index].sending = true

        const fileContent = await this.readFile(file)
        const addedFile = await this.$store.dispatch('documents/uploadFile', { path: this.selected.path + '/' + file.name, data: fileContent })
        // Example: {"type":"-","size":70749,"name":"data.csv","$can":"c,r,u,d"}
        this.selected.children.push({
          id: this.$stratus.uuid(),
          dir: this.selected.dir,
          path: this.selected.path + '/' + addedFile.name,
          type: addedFile.type,
          mime: addedFile.mime,
          name: addedFile.name,
          size: addedFile.size,
          icon: addedFile.type === TYPE_DIR ? 'icon-folder' : getClassNameForExtension(addedFile.name.split('.').pop()),
          ...(addedFile.type === TYPE_DIR && { children: [] })
        })
        this.uploadFiles[index].sending = false
        this.uploadFiles[index].uploaded = true
        return false
      } catch (error) {
        this.uploadFiles[index].error = true
        if (error.status === 409 && error.bodyText.includes('File already exists')) {
          this.$stratus.services.notify.error(this.$t('File «{name}» already exists!', { name: file.name }))
        } else this.$stratus.services.notify.error(error)
        return true
      }
    },
    sortByName (children) {
      if (!children?.length) return children
      return this.$stratus.services.sort.natural(children, { name: 'asc' })
    },
    onFileChanged (files, names) {
      if (files && files.length) {
        this.$refs['confirm-file-action']
          .open(this.$t('Send documents'), this.$t('Confirm uploading {count} file(s)?', { count: files.length }))
          .then(confirm => {
            if (confirm) {
              let count = 0
              this.isUploading = true
              this.uploadFiles = _.map(names, name => { return { name, uploaded: false, sending: false } })
              _.forEach(files, async (file, index) => {
                this.uploadFiles[index].bin = file
                const error = await this.sendFile(file, index)
                if (!error) count++
                if (index === files.length - 1) {
                  if (count) this.$stratus.services.notify.success(this.$tc('{count} files uploaded.', count, { count: count }))
                  this.$refs['document-upload-button'].reset()
                  this.$forceUpdate()
                  this.isUploading = false
                }
              })
            }
          })
      }
    },
    onResetUploads () {
      this.uploadFiles = []
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    }
  },
  beforeDestroy () {
    if (this.onResizeHandler) window.removeEventListener('resize', this.onResizeHandler)
  },
  async mounted () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Documents'))

    // Hook an event handler to capture window resizing and redraw canvas
    if (this.onResizeHandler) window.removeEventListener('resize', this.onResizeHandler)
    this.$nextTick(() => {
      this.container = document.getElementById('app-main-container') // This is the area that embed this view.
      this.setHeight()

      this.onResizeHandler = window.addEventListener('resize', () => {
        this.setHeight()
      })
    })

    await this.$store.dispatch('$stratus-states/getMe')
    const me = this.$store.getters['$stratus-states/me']
    this.$alto.services.routes.connectionForbidden.call(this, me)
    if (this.$route?.name === 'documents' && this.$route.query?.path && this.$route.query?.mime) {
      this.showItem({
        path: this.$route.query.path,
        mime: this.$route.query.mime,
        name: this.$route.query.name || this.$route.query.path
      })
    }
  }
}
</script>

<style scoped>
.floating-bottom-right {
  display: inline;
  height: 35px;
  float: right;
  bottom: 0;
  margin-top: 69px;
}
</style>
