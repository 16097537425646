<template>
  <sca-modal-dialog :visible="show" :max-width="$vuetify.breakpoint.lgAndUp ? '800' : '600'" @closeDialog="show = false">
    <template #title>
      {{ company?.siren }} — {{ company?.name }}
    </template>

    <template #content>
      <v-row v-for="(field, index) in company" :key="index" dense>
        <v-col cols="12" md="6" lg="4" class="font-weight-bold">
          {{ $t(`company-field-${index}`) }}
        </v-col>

        <v-col cols="12" md="6" lg="8">
          <span v-if="field">
            {{ field }}
          </span>
          <span v-else class="font-italic">
            {{ $t('None') }}
          </span>
        </v-col>
      </v-row>
    </template>

    <template #buttons>
      <v-btn rounded class="main-button" @click="apply">
        {{ $t('Apply') }}
      </v-btn>
    </template>
  </sca-modal-dialog>
</template>

<script>
export default {
  name: 'CompanyResume',
  data () {
    return {
      company: null,
      show: false
    }
  },
  methods: {
    apply () {
      this.$emit('apply', { ...this.company })
      this.show = false
    },
    open (company) {
      if (!company) return
      delete company.create_by
      delete company.type
      this.company = company
      this.show = true
    }
  }
}
</script>
