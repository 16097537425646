<template>
  <csm-opportunity-form :visible="isVisible" :opportunity="opportunity" :update="update" :show="show" @closeDialog="closeDialog" ref="opportunity-form" />
</template>

<script>
let previousOpportunity = null

export default {
  components: { 'csm-opportunity-form': () => import(/* webpackChunkName: "components" */ './OpportunityForm.vue') },
  data () {
    return {
      isVisible: false,
      opportunity: {},
      opportunityId: null,
      show: false,
      update: false
    }
  },
  methods: {
    closeDialog () {
      this.isVisible = false
      previousOpportunity = null
      if (this.$route?.name === 'opportunity' && this.$route?.params?.id) {
        this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
      } else {
        this.$emit('closeDialog', this.opportunity)
      }
    },
    getOpportunity (id) {
      this.show = this.$store?.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.COMPANIES, this.$alto.API_PERMISSIONS.READ)
      this.update = this.$store?.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.COMPANIES, this.$alto.API_PERMISSIONS.UPDATE)
      this.$store.dispatch('opportunities/get', id)
        .then(response => {
          if (response) {
            this.opportunity = { ...response }
          }
          return this.$refs['opportunity-form'].reset(this.opportunity)
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    open (id) {
      if (!id) return
      this.opportunityId = id
      this.getOpportunity(this.opportunityId)
      this.isVisible = true
    }
  },
  async mounted () {
    // Example: https://manager-int-cloudsystem.aws.scalair.fr/#/opportunities/1
    if (this.$route?.name === 'opportunity' && this.$route.params.id) {
      if (this.$route.params.id !== previousOpportunity) {
        previousOpportunity = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        const me = this.$store.getters['$stratus-states/me']
        if (this.$alto.services.routes.connectionForbidden.call(this, me)) return
        this.open(this.$route.params.id)
      }
    }
  }
}
</script>
