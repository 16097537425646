<template>
  <v-row align="center" no-gutters>
    <v-col>
      <v-text-field v-bind="$attrs" v-on="$listeners" :rules="[$stratus.services.form.rules.required]" class="required" @change="onChange" />
    </v-col>
    <v-col class="shrink" v-if="unit">
      <span>{{ $t(unit) }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CsmDynaProductText',
  props: {
    unit: { type: String, default: '' }
  },
  data () { return {} },
  methods: {
    onChange (payload) {
      this.$emit('change', payload)
    }
  }
}
</script>
