<template>
  <sca-modal-dialog :visible="visible" :loading="loading || isSaving" :can-save="canSave" :is-saving="isSaving" max-width="440" @closeDialog="closeDialog" @saveDialog="saveDialog">
    <template slot="title">
      <span v-if="cost.id">{{ cost.id }} —</span> {{ cost.name }} — {{ $t('Cost') }}
    </template>

    <div slot="content">
      <v-form ref="cost-form" v-model="valid" lazy-validation :disabled="!canSave">
        <v-row align="end" dense>
          <v-col cols="12">
            <v-text-field v-model="cost.name" :label="$t('Name')" :counter="128" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.min(1), $stratus.services.form.rules.max(64)]" class="required" />
          </v-col>
        </v-row>

        <v-row align="center" dense>
          <v-col cols="12" md="6">
            <cs-date-picker v-model="cost.date" :label="$t('Date')" format-short allow-empty />
          </v-col>

          <v-col cols="12" md="6" class="align-self-start">
            <cs-price-input v-model="cost.cost" :label="$t('Cost')" />
          </v-col>
        </v-row>

        <v-row v-if="canUpdateState || cost.state" dense>
          <v-col>
            <v-select v-if="canUpdateState" v-model="cost.state" :label="$t('State')" :items="costStateList">
              <template #item="{ item }">
                <sca-marketing-cost-state :value="item.value" show-label />
              </template>
              <template #selection="{ item }">
                <sca-marketing-cost-state :value="item.value" show-label />
              </template>
            </v-select>
            <span v-else>
              <sca-marketing-cost-state :value="cost.state" show-label />
            </span>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <cs-file-uploader v-if="canSave && !cost.attachments?.length" v-model="file" use-isu :file-size-limit="20 * 1000 * 1000" max-file-count="1" />

            <v-sheet v-if="cost && cost.id && cost.attachments?.length > 0">
              <cs-attachments-list :id="cost.id" :value="cost.attachments" dense can-download thumbnail :can-delete="canDeleteAttachment" @delete="deleteAttachments" />
            </v-sheet>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </sca-modal-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MarketingCostForm',
  props: {
    visible: { type: Boolean, default: false }
  },
  data () {
    return {
      cost: {},
      file: [],
      isSaving: false,
      loading: false,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    canDeleteAttachment () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_MARKETING, this.$alto.API_PERMISSIONS.DELETE_ATTACHMENTS)
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_MARKETING, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_MARKETING, this.$alto.API_PERMISSIONS.UPDATE)
    },
    canUpdateState () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_MARKETING, this.$alto.API_PERMISSIONS.UPDATE_COST_STATE)
    },
    costStateList () {
      return [{
        value: 'to-validate',
        icon: '$vuetify.icons.inProgress'
      }, {
        value: 'validated',
        icon: '$vuetify.icons.completed'
      }, {
        value: 'refused',
        icon: '$vuetify.icons.canceled'
      }]
    }
  },
  methods: {
    closeDialog (cost) {
      this.$emit('closeDialog', cost)
    },
    async deleteAttachments ({ id, attachment }) {
      this.isSaving = true
      try {
        const cost = await this.$store.dispatch('$alto-marketing/deleteCostAttachment', { id: this.cost.id, fileName: attachment.name })
        this.cost = { ...cost }
      } catch (error) {
        this.$stratus.services.notify.error(error)
        return false
      } finally {
        this.isSaving = false
      }
    },
    reset (cost = {}) {
      this.cost = { ...cost, date: cost.date || null }
    },
    async saveDialog () {
      if (!this.canSave) return false

      if (!this.$refs['cost-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        return false
      }

      this.isSaving = true
      try {
        // Sanitize
        const cost = {
          ...this.cost,
          name: this.$stratus.services.strings.stripHtmlTags(this.cost.name),
          date: this.$stratus.dt(this.cost.date).isValid() ? this.$stratus.dt(this.cost.date).format('YYYY-MM-DD') : null
        }

        const newCost = await this.$store.dispatch('$alto-marketing/saveCost', { cost, file: this.file })
        this.closeDialog(newCost)
      } catch (error) {
        this.$stratus.services.notify.error(error)
        return false
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>
