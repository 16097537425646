const baseUrl = process.env.VUE_APP_API_URL || 'http://api.scalair.localhost'
const baseStatusUrl = 'https://api.status.scalair.localhost'
const appVersion = '<local>'
const sentryDSN = '' // No Sentry for DEV env

const config = {
  beta: true,
  env: 'development',
  appVersion,
  url: baseUrl,
  api: baseUrl,
  sentryDSN,
  urlScalairDashboard: process.env.VUE_APP_DASHBOARD_URL || 'http://dashboard.scalair.localhost',
  urlScalairManager: process.env.VUE_APP_MANAGER_URL || 'http://manager.scalair.localhost',
  urlScalairStatus: process.env.VUE_APP_STATUS_URL || 'http://status.scalair.localhost',
  ajaxUploadUrl: `${baseUrl}/upload`,
  infraStatusBaseUrl: `${baseStatusUrl}`,
  infraStatusServicesUrl: `${baseStatusUrl}/api/services`,
  servicesIconsPath: '/img/services/',
  avatarApi: 'https://www.gravatar.com/avatar/<%=hash%>?s=64&d=retro',
  locale: 'fr-FR',
  debug: {
    i18n: true, // Log warning if translation is missing
    router: true, // Display log for route loading
    http: true // Log every HTTP request
  },
  features: { // Available features in GUI
    carts: true,
    catalog: true,
    companies: true,
    contracts: true,
    opportunities: true,
    orders: true,
    documents: true,
    projects: true,
    subscriptions: true,
    users: true
  },
  defaults: {
    oemCountry: 'FRA',
    parentCompanyCode: 'C1111',
    corporationCode: 'C1111',
    productTags: ['cloud public', 'cloud dédié', 'cloud Scalair', 'réseau', 'service']
  },
  carts: {
    expirationWarningDelay: 5 // Warn 5 days before expiration
  },
  ticketsAppLinks: {
    // #ID# will be replaced by a ticket identifier
    ticketIdPattern: '#ID#',
    service_now: 'https://scalairtest.service-now.com/task.do?sys_id=#ID#',
    jira: 'https://scalair-services.atlassian.net/issues/?jql=id=#ID#'
  },
  subscriptions: {
    list: {
      hideProducts: { // This will allow to show/hide these products in the list
        title: 'Hide tickets',
        refs: ['C06THP101OL', 'C06THP102OL']
      }
    }
  },
  opportunities: {
    dashboard: {
      THRESHOLD_VALUE_MAX: 10000, // This should always be 100% :-)
      THRESHOLD_PERCENT_MED: 90, // NOTE: This can change once a year
      THRESHOLD_PERCENT_MIN: 50 // NOTE: This can change once a year
    }
  }
}

export default config
