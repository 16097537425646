<template>
  <v-row align="center" dense>
    <v-col class="shrink">
      <sca-product-identity :value="value" :company="customer" show-avatar />
    </v-col>
    <v-col>
      <v-select v-bind="$attrs" v-on="$listeners" :value="value" @input="onChange" class="pa-0 ma-0" hide-details dense />
    </v-col>
    <v-col class="shrink" v-if="unit">
      <span>{{ unit }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CsmDynaProductSelect',
  props: {
    customer: { type: String, default: '' },
    unit: { type: String, default: '' },
    value: { type: [Number, String, Object], required: true }
  },
  data () { return {} },
  methods: {
    onChange (payload) {
      this.$emit('change', payload)
    }
  }
}
</script>
