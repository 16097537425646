<template>
  <div>
    <csm-quotation-form :ref="`quotation-form-${uuid}`" :update="updateCart" :show="showDialog" @closeDialog="closeDialog" />
    <v-dialog v-model="updating" persistent max-width="400">
      <v-card class="pa-12 on-top text-center">
        {{ $t('Updating cart data...') }}
        <p class="text-h5 mt-4">
          {{ cart?.name }}
        </p>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QuotationForm from './QuotationForm'

let previousQuotation = null

export default {
  components: { 'csm-quotation-form': QuotationForm },
  data () {
    return {
      callbackFn: null,
      updating: false,
      showDialog: true,
      updateCart: false,
      timeoutHandler: null,
      uuid: this.$stratus.uuid()
    }
  },
  computed: {
    ...mapGetters({
      cart: 'carts/current',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    })
  },
  methods: {
    closeDialog () {
      this.updating = false
      if (this.$refs[`quotation-form-${this.uuid}`]) this.$refs[`quotation-form-${this.uuid}`].display(false)

      if (this.callbackFn) this.callbackFn(this.cart)

      if (this.$route.params.id && this.$route.params.id !== 'new') {
        // We must go to Home page because we've bump to a cart without going from Home.
        this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
      } else {
        if (this.$route.params.id) this.$router.back()
      }
    },
    create () {
      if (this.$refs[`quotation-form-${this.uuid}`] && this.cart && this.cart.items?.length > 0) {
        clearTimeout(this.timeoutHandler)
        this.updating = true
        this.$refs[`quotation-form-${this.uuid}`].save()
          .then(id => { this.load(id) })
          .catch(error => { this.$stratus.services.notify.error(error) })
          .finally(() => {
            setTimeout(() => { this.updating = false }, 2000)
          })
      }
    },
    async load (id, callbackFn) {
      if (!id) return
      this.updating = true
      this.callbackFn = callbackFn
      try {
        const loadedCart = await this.$store.dispatch('carts/load', id)
        this.$refs[`quotation-form-${this.uuid}`].sanitize(loadedCart)
        await this.$store.dispatch('carts/setCurrent', loadedCart)
        this.$refs[`quotation-form-${this.uuid}`].resetControls()
        await this.$store.dispatch('carts/getDiscounts', loadedCart.customer)
        this.$refs[`quotation-form-${this.uuid}`].resetOriginalCart()
        this.$refs[`quotation-form-${this.uuid}`].display(true)
      } catch (error) {
        console.error(error)
        if (error.status === 404) this.$stratus.services.notify.error(this.$t('Quotation «{name}» not found!', { name: id }))
        else this.$stratus.services.notify.error(error)
      } finally {
        this.updating = false
      }
    },
    open (id) {
      if (!id) return
      this.load(id)
    }
  },
  async mounted () {
    if (this.$route && (this.$route.name === 'quotation' || this.$route.name === 'quotation-new') && this.$route.params.id) {
      if (this.$route.params.id === 'new') this.create() // Create a new one
      else {
        if (this.$route.params.id !== previousQuotation) {
          previousQuotation = this.$route.params.id
          this.load(this.$route.params.id) // Load cart from given id
        }
      }
    }
  }
}
</script>

<style scoped>
.on-top {
  z-index: 5000;
}
</style>
