<template>
  <v-row no-gutters align="baseline">
    <v-col v-if="index !== null" cols="1" class="text-center text-font-fixed">
      {{ index }}
    </v-col>
    <v-col v-if="required" class="shrink mx-2">
      *
    </v-col>
    <v-col :cols="colSize(type)">
      <v-text-field v-if="type === 'string' || type === '[]string'" :class="required ? 'font-weight-bold danger--text' : ''" v-model="value" :rules="getRules()" hide-details dense />

      <sca-instance-select v-else-if="type === 'instanceid' || type === '[]instanceid'" :class="required ? 'font-weight-bold danger--text' : ''" v-model="value" :rules="getRules()" dense />

      <cs-date-picker v-else-if="type === SCHEDULE_SERVICE_FIELD_TYPE_DATETIME || type === '[]datetime'" :class="required ? 'font-weight-bold danger--text required' : ''" v-model="value" :rules="getRules()" hide-details dense />

      <cs-integer-input v-else-if="type === 'int' || type === '[]int'" :class="required ? 'font-weight-bold danger--text required' : ''" v-model="value" :rules="getRules()" :max="max" :min="min" hide-details dense />

      <cs-decimal-input v-else-if="type === 'float64' || type === '[]float64'" :class="required ? 'font-weight-bold danger--text required' : ''" v-model="value" :rules="getRules()" :max="max" :min="min" hide-details dense />

      <v-checkbox v-else-if="type === 'bool' || type === '[]bool'" :class="required ? 'font-weight-bold danger--text required' : ''" v-model="value" :rules="getRules()" hide-details dense class="ma-0 pa-0" />

      <v-text-field v-else-if="type === 'email' || type === '[]email'" :class="required ? 'font-weight-bold danger--text required' : ''" v-model="value" :rules="getRules()" dense class="ma-0 pa-0" />

      <cs-alert-panel v-else type="error" dense :text="$t('Unknown field type «{type}»!', { type })" />
    </v-col>
    <v-col v-if="canDelete" class="shrink">
      <v-btn small icon>
        <v-icon small color="danger" @click="onDelete">
          $vuetify.icons.delete
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { SCHEDULE_SERVICE_FIELD_TYPE_DATETIME } from '@/services/scheduler'

export default {
  name: 'TaskDataInputs',
  props: {
    canDelete: { type: Boolean, default: false },
    // eslint-disable-next-line vue/require-prop-types
    fieldData: { default: '' },
    index: { type: Number, default: null },
    max: { type: Number, required: false, default: Infinity },
    min: { type: Number, required: false, default: -Infinity },
    uuid: { type: String, required: true },
    type: { type: String, required: true },
    required: { type: Boolean, required: true }
  },
  data () {
    return {
      SCHEDULE_SERVICE_FIELD_TYPE_DATETIME,
      value: null
    }
  },
  watch: {
    fieldData: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        this.setValue(newValue)
      }
    }
  },
  methods: {
    colSize (type) {
      // string, datetime, int, float64, bool, []string, []datetime, []int, []float64, []bool
      if (type.includes(SCHEDULE_SERVICE_FIELD_TYPE_DATETIME)) return '4'
      if (type.includes('int')) return '3'
      if (type.includes('float')) return '4'
      if (type.includes('bool')) return '1'
      return ''
    },
    convert ({ type }, value) {
      // Schedule API needs RFC3339 or ISO8601 date format
      if (type.includes(SCHEDULE_SERVICE_FIELD_TYPE_DATETIME)) return this.$stratus.dt(value).toISOString()
      else return value
    },
    getRules () {
      const rules = this.required ? [this.$stratus.services.form.rules.required] : []
      if (this.type.includes('email')) rules.push(this.$stratus.services.form.rules.email)
      return rules
    },
    onDelete () {
      this.$emit('delete', {
        index: this.index,
        uuid: this.uuid
      })
    },
    serialize () {
      let result = this.value
      if (this.type.includes('bool')) result = this.value || false
      if (this.type.includes('string')) result = this.value || ''
      if (this.type.includes('int')) result = this.value || 0
      if (this.type.includes('float')) result = this.value || 0.0
      if (this.type.includes(SCHEDULE_SERVICE_FIELD_TYPE_DATETIME)) result = this.value || ''
      if (this.type.includes('email')) result = this.value || ''

      result = this.convert({ max: this.max, min: this.min, type: this.type, required: this.required }, result)
      return result
    },
    setValue (value) {
      // Convert value to needed type:
      if (this.type.includes('bool')) this.value = Boolean(value)
      else if (this.type.includes('string')) this.value = String(value)
      else if (this.type.includes('int')) this.value = Number(value)
      else if (this.type.includes('float')) this.value = Number(value)
      else this.value = value
      this.$forceUpdate()
    }
  }
}
</script>
