import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    servicesCache: {},
    taskTemplates: []
  },
  getters: {
    servicesCache: state => { return state.servicesCache },
    taskTemplates: state => { return state.taskTemplates || [] }
  },
  actions: {
    listTaskTemplates ({ state, commit, rootState }) {
      return rootState.$stratus.services.api.get('/schedules/schedule-type?limit=0&fields=id,name,data_needed')
        .then(response => {
          commit('setListTaskTemplates', { rootState, data: response ? response.results : [] })
          return state.taskTemplates
        })
        .catch(error => commit('API_FAILURE', error))
    },
    servicesList ({ commit, rootState }) {
      return rootState.$stratus.services.api.get('/schedules/services?limit=0&fields=id,name')
        .then(response => {
          commit('cacheServices', response ? response.results : [])
        })
        .catch(error => {
          commit('API_FAILURE', error)
        })
    },
    saveSchedule ({ state, commit, rootState }, data) {
      const promise = data.id ? rootState.$stratus.services.api.patch(`/schedules/schedule-outer-layer/${data.id}`, data) : rootState.$stratus.services.api.post('/schedules/schedule-outer-layer', data)
      return promise
        .then(response => {
          commit('setListTaskTemplates', { rootState, data: response.results })
          return state.taskTemplates
        })
        .catch(error => commit('API_FAILURE', error))
    }
  },
  mutations: {
    API_FAILURE: (state, error) => {
      /* if (error.status === 401) {
        console.warn('[API] status 401', error)
      } else */ throw error
    },
    cacheServices (state, data) {
      state.servicesCache = _.keyBy(data, 'id')
    },
    setListTaskTemplates (state, { rootState, data }) {
      state.taskTemplates = rootState.$stratus.services.sort.natural(data, { name: 'asc' })
    }
  }
}
