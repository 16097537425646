<template>
  <v-dialog v-model="show" persistent scrollable :max-width="maxWidth">
    <v-card tile flat>
      <v-card-title class="pa-2">
        <img class="ml-8 beta-icon" src="/img/beta.png">
        <v-icon class="menu-icon--text" @click="close()">
          $vuetify.icons.close
        </v-icon>
        <div class="ml-4 text-h6 pl-2">
          {{ task.caption || task.id }} — {{ $t('Task') }}
        </div>
      </v-card-title>

      <v-card-text>
        <v-form ref="schedule-form" v-model="valid" :disabled="!canUpdate" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field v-model="task.caption" :label="$t('Caption')" :rules="[$stratus.services.form.rules.min(4), $stratus.services.form.rules.max(128)]" :counter="128" />
            </v-col>
            <v-col cols="12" md="3">
              <v-select v-model="task.type_id" item-value="id" item-text="name" :label="$t('Template')" :items="taskTemplateList" :rules="[$stratus.services.form.rules.required]" class="required" :disabled="task.id !== undefined" @change="onTaskTemplateChange" />
            </v-col>
            <v-col cols="12" md="3">
              <v-switch v-model="task.catch_up_history" :label="$t('Relaunch if failure')" :persistent-hint="task.catch_up_history" :hint="$t('If task execution failed, it will relaunch in the same order as first run.')" />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="4">
              <v-switch v-model="task.send_error_email" :label="$t('Send a mail on error')" :persistent-hint="task.send_error_email" :hint="$t('If an error occurs, a mail will be sent to {email} and copy emails if any.', { email: myEmail })" />
            </v-col>
            <v-col cols="12" md="8">
              <sca-mail-accounts v-model="task.emails_error_copy" :disabled="!task.send_error_email" :min="0" :max="10" :label="$t('E-Mail copy')" @change="onChangeMails" />
            </v-col>
          </v-row>

          <v-row class="mt-4" no-gutters>
            <v-col cols="12" md="6">
              <task-data-inputs ref="task-data" :fields="fields" :data="getData()" :title="$t('Data') " />
            </v-col>

            <v-col class="shrink px-2">
              <v-divider vertical />
            </v-col>

            <v-col>
              <v-row align="center" no-gutters>
                <v-col cols="12" class="text-subtitle-1 mp-2 font-weight-bold">
                  <v-icon>
                    $vuetify.icons.scheduling
                  </v-icon> {{ $t('Scheduling') }}
                </v-col>

                <v-col cols="12">
                  <cs-alert-panel v-show="cronExplain" dense class="text-center">
                    <template #content>
                      <div>{{ cronExplain }}</div>
                      <span class="text-caption">{{ $t('Next run') }}</span> <span class="text-body-1">{{ cronNext }}</span>
                    </template>
                  </cs-alert-panel>
                </v-col>

                <v-col cols="12">
                  <v-radio-group v-model="cronScheduling" dense @change="explainScheduling" hide-details>
                    <template v-slot:label>
                      <div>{{ $t('Choose how often this task should be performed.') }}</div>
                    </template>

                    <v-radio value="every_hour">
                      <template v-slot:label>
                        <v-row align="baseline" no-gutters>
                          <v-col class="shrink text-no-wrap">
                            {{ $t('Every hour at minute') }}
                          </v-col>
                          <v-col class="ml-4">
                            <cs-integer-input v-model="cronMinute" :min="0" :max="59" @input="explainScheduling" />
                          </v-col>
                        </v-row>
                      </template>
                    </v-radio>

                    <v-radio value="every_day">
                      <template v-slot:label>
                        <v-row align="center" no-gutters>
                          <v-col class="shrink text-no-wrap">
                            {{ $t('Every day at') }}
                          </v-col>
                          <v-col class="ml-4">
                            <cs-time-input v-model="cronTime" @change="explainScheduling" />
                          </v-col>
                        </v-row>
                      </template>
                    </v-radio>

                    <v-radio value="every_week">
                      <template v-slot:label>
                        <v-row align="baseline" no-gutters>
                          <v-col class="shrink text-no-wrap">
                            {{ $t('Every week on') }}
                          </v-col>
                          <v-col class="mx-4">
                            <cs-day-select v-model="cronDayWeek" @change="explainScheduling" />
                          </v-col>
                          {{ $t('at') }}
                          <v-col class="ml-4">
                            <cs-time-input v-model="cronTimeWeek" @change="explainScheduling" />
                          </v-col>
                        </v-row>
                      </template>
                    </v-radio>

                    <v-radio value="every_month">
                      <template v-slot:label>
                        <v-row align="baseline" no-gutters>
                          <v-col class="shrink text-no-wrap">
                            {{ $t('Every month, the') }}
                          </v-col>
                          <v-col class="mx-4">
                            <cs-integer-input v-model="cronDayMonth" :min="1" :max="31" @input="explainScheduling" />
                          </v-col>
                          {{ $t('at') }}
                          <v-col class="ml-4">
                            <cs-time-input v-model="cronTimeMonth" @input="explainScheduling" />
                          </v-col>
                        </v-row>
                      </template>
                    </v-radio>

                    <v-radio v-if="isLord" value="expert">
                      <template v-slot:label>
                        <v-row align="center" no-gutters>
                          <v-col class="shrink text-no-wrap">
                            {{ $t('Expert') }}
                          </v-col>
                          <v-col cols="3" class="mx-4">
                            <v-text-field v-model="crontab" class="text-font-fixed" @input="explainScheduling" />
                          </v-col>
                          <v-col class="shrink">
                            (¹)
                          </v-col>
                        </v-row>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <div v-if="isLord" class="text-caption">
                (¹) <a href="https://crontab.guru/" target="_cg">{{ $t('This is a cron format. Click for more details.') }}</a>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row align="center" dense>
          <v-col cols="12">
            <sca-footer-create-update-at-by v-model="task" :link-user="isLord" />
          </v-col>

          <v-col class="text-right">
            <v-btn v-if="canUpdate" rounded :disabled="isSaving" :loading="isSaving" class="main-button" @click="save">
              {{ $t('Save') }}
            </v-btn>
            <v-btn rounded class="ml-2" @click="close">
              {{ canUpdate ? $t('Cancel') : $t('Close') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import cronParser from 'cron-parser'
import cronstrue from 'cronstrue/i18n'
import TaskDataInputs from '@/components/Scheduler/TaskDataInputs.vue'

export default {
  name: 'UserTaskDialog',
  components: {
    TaskDataInputs
  },
  data () {
    return {
      caption: '',
      catchupHistory: false,
      cronExplain: '',
      cronNext: '',
      cronScheduling: 'every_day',
      crontab: '* * * * *',
      cronMinute: 0,
      cronDayMonth: 0,
      cronDayWeek: 0,
      cronTime: null,
      cronTimeMonth: null,
      cronTimeWeek: null,
      task: {},
      fields: [],
      id: null,
      isSaving: false,
      myEmail: '',
      path: '',
      show: false,
      valid: false
    }
  },
  computed: {
    canUpdate () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.SCHEDULER, this.$alto.API_PERMISSIONS.UPDATE) },
    historySorted () {
      // Sort by date descending
      const list = _.sortBy(this.history, line => {
        return -this.$stratus.dt(line.date)
      })
      return list
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    maxWidth () {
      if (this.$vuetify.breakpoint.xl) return '60%'
      if (this.$vuetify.breakpoint.lgAndUp) return '75%'
      return '100%'
    },
    taskTemplateList () { return this.$store.getters['scheduler/taskTemplates'] }
  },
  methods: {
    async close () {
      this.resolve(false)
      await this.reset()
      this.show = false
    },
    cronify () {
      let cronExpr
      switch (this.cronScheduling) {
        case 'every_hour':
          cronExpr = `${this.cronMinute} * * * *`
          break
        case 'every_day':
          cronExpr = this.hourToCron(this.cronTime) + ' * * *'
          break
        case 'every_week':
          cronExpr = this.hourToCron(this.cronTimeWeek) + ' * * ' + this.cronDayWeek
          break
        case 'every_month':
          cronExpr = this.hourToCron(this.cronTimeMonth) + ` ${this.cronDayMonth} * *`
          break
        default:
          cronExpr = this.crontab // Expert
          break
      }
      if (cronExpr) {
        this.cronExplain = cronstrue.toString(cronExpr, { use24HourTimeFormat: this.$store.getters['$stratus-states/timeFormat'] === '24hr', locale: this.$store.getters['$stratus-states/countryCode'] })
      }
      return cronExpr
    },
    explainScheduling () {
      const cron = this.cronify()
      const nextDate = cronParser.parseExpression(cron, { tz: this.$stratus.dt.tz.guess() }).next()
      this.cronNext = this.$stratus.dt(nextDate).format('LL LTS')
    },
    getData () { return this.task?.data || {} },
    hourToCron (time) {
      return time ? time.substr(3, 2) + ' ' + time.substr(0, 2) : '* *' // MINUTE HOUR
    },
    isDark () { return this.$store.getters['$stratus-states/isDark'] },
    onChangeMails (value, value2) {
      this.task.emails_error_copy = value2
    },
    onTaskTemplateChange () {
      const template = _.find(this.taskTemplateList, { id: this.task.type_id })
      if (this.$refs['task-data']) this.$refs['task-data'].reset()
      this.fields = template && template.data_needed ? template.data_needed : []
    },
    async open (options) {
      await this.reset(options)
      this.show = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async reset (options = { data: {} }) {
      if (this.$refs['schedule-form']) await this.$refs['schedule-form'].reset()
      if (this.$refs['task-data']) this.$refs['task-data'].reset()

      try {
        this.task = { ...options.data }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
      this.cronExplain = ''
      this.cronScheduling = this.task.cron ? 'expert' : 'every_day'
      this.crontab = this.task.cron || '* * * * *'
      this.cronMinute = 0
      this.cronDayMonth = 0
      this.cronDayWeek = 0
      this.cronTime = null
      this.cronTimeMonth = null
      this.cronTimeWeek = null
      this.fields = []
      this.isSaving = false
      this.myEmail = this.$store.getters['$stratus-states/me'].email
      this.valid = false

      // Create data inputs
      this.onTaskTemplateChange()
    },
    async save () {
      this.isSaving = true
      if (!this.$refs['schedule-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      const schedule = {
        caption: this.task.caption,
        cron: this.cronify(),
        type_id: this.task.type_id,
        path: this.task.path,
        catch_up_history: this.task.catch_up_history,
        send_error_email: Boolean(this.task.send_error_email),
        emails_error_copy: this.task.emails_error_copy || [],
        data: this.$refs['task-data'].serialize() || {}
      }

      if (this.task.id) {
        schedule.id = this.task.id
      }

      try {
        await this.$store.dispatch('scheduler/saveSchedule', schedule)
        this.resolve({ ...schedule })
        await this.reset()
        this.show = false
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.isSaving = false
      }
    }
  },
  async mounted () {
    this.explainScheduling = _.debounce(this.explainScheduling, 500)
    await this.$store.dispatch('$stratus-states/getMe')

    const me = this.$store.getters['$stratus-states/me']
    this.$alto.services.routes.connectionForbidden.call(this, me)
  }
}
</script>

<style scoped>
.beta-icon {
  position: absolute;
  left: 8px;
  height: 40px;
}
</style>
