<template>
  <div class="pa-2">
    <csm-product-node :product="product" :readonly="readonly" />
  </div>
</template>

<script>
import ProductNode from './ProductNode'

export default {
  name: 'ProductTree',
  props: {
    product: { type: Object, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    'csm-product-node': ProductNode
  },
  data () { return {} },
  methods: {
    fullProduct () {
      this.$store.dispatch('products/get', this.product.ref)
        .then(() => {
          return this.$store.getters['products/cache'](this.product.ref)
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
          return {}
        })
    }
  }
}
</script>
