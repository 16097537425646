<template>
  <v-row>
    <v-col cols="12">
      <sca-advanced-store-grid v-if="columns" :pause="pause" :options="gridOptions" :resource="resourceId" :columns="columns" :filters="filters" :csv-export="computeCsvData" :custom-search="customSearch" @createItem="createItem" @dataLoaded="dataLoaded" @selectItems="selectItems" @showItem="showItem" @updateItem="updateItem" @resetFilters="resetFilters" ref="opportunity-grid">
        <template slot="search-append">
          <v-row dense align="baseline">
            <v-col v-show="companies.length > 1">
              <sca-customer-select v-model="companiesSearch" :label="$t('Company')" @input="refreshGrid" clearable show-email show-phone link="emit" @link-click="openCompany(companiesSearch)" />
            </v-col>
            <v-col>
              <v-autocomplete :items="opportunityStates" v-model="opportunityStateSearch" :label="$t('State')" @input="refreshGrid" clearable multiple deletable-chips />
            </v-col>
            <v-col>
              <sca-sales-person-select v-model="salesPersonSearch" clearable :label="$t('Sales Person')" @input="refreshGrid" link="emit" @link-click="openUser(salesPersonSearch)" />
            </v-col>
            <v-col v-if="isLord">
              <sca-downsell-reason-select v-model="downsellReasonSearch" clearable :label="$t('Downsell reason')" @input="refreshGrid" />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item-company="{ itemRaw }">
          <sca-company-identity :value="itemRaw" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(itemRaw)" />
        </template>

        <template v-slot:item-owner="{ itemRaw }">
          <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
          <span v-else />
        </template>

        <template v-slot:item-state="{ itemRaw }">
          <sca-opportunity-state :value="itemRaw" show-label />
        </template>

        <template v-slot:item-accounting_family="{ item }">
          {{ getAccountingFamily(item) }}
        </template>

        <template v-slot:item-id_lord_salesperson="{ itemRaw }">
          <sca-user-identity :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
        </template>

        <template v-slot:item-first_contact="{ itemRaw }">
          <span v-if="itemRaw">
            {{ $t(`company-first-contact-${itemRaw.toLowerCase()}`) }}
          </span>
        </template>

        <template v-slot:item-link_id="{ itemRaw, row }">
          <sca-subscription-identity v-if="itemRaw && row.type_of_link === OPPORTUNITY_LINK_ORDER_LINE" :value="itemRaw" :label="itemRaw" link="emit" @link-click="showSubscription(itemRaw)" />

          <v-btn small rounded v-if="itemRaw && row.type_of_link === OPPORTUNITY_LINK_CART" @click="showQuotation(itemRaw)">
            {{ $t('Show quotation...') }}
          </v-btn>
        </template>

        <div slot="multi-select">
          <v-form :disabled="isActionRunning">
            <v-row dense align="center">
              <v-col class="shrink">
                <v-icon small>
                  $vuetify.icons.run
                </v-icon>
              </v-col>

              <v-col cols="4" md="3">
                <v-select dense v-model="selectionAction" :items="getActions()" clearable hide-details :placeholder="$t('Select an action to perform...')" @change="validateSelection" />
              </v-col>

              <v-col v-show="selectionAction === LINE_ACTIONS.CHANGE_OWNER">
                <sca-users-select v-model="selectedOwner" filter-lord show-email item-value="email" :label="$t('Owner')" dense clearable hide-details @change="validateSelection" link="emit" @link-click="openUser(selectedOwner)" />
              </v-col>

              <v-col v-show="selectionAction === LINE_ACTIONS.CHANGE_CLOSING_DATE">
                <cs-date-picker v-model="closingDate" dense />
              </v-col>

              <v-col v-show="isLord && selectionAction === LINE_ACTIONS.CHANGE_DOWNSELL_REASON">
                <sca-downsell-reason-select v-model="newDownsellReason" clearable :label="$t('Downsell reason')" @input="validateSelection" />
              </v-col>

              <v-col class="shrink text-no-wrap">
                {{ $tc('on selected line | on {count} lines', selection.length, { count: selection.length }) }}
              </v-col>

              <v-col>
                <v-btn rounded small color="main-button" :disabled="!canRunAction" :loading="isActionRunning" @click="actionRun">
                  {{ $t('Run...') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row dense justify="center">
              <v-col class="shrink text-no-wrap">
                <cs-alert-panel v-show="selectionError" dense type="error" :text="selectionError" />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </sca-advanced-store-grid>
    </v-col>

    <cs-confirm-dialog ref="confirm-opportunity-dialog" />
    <csm-opportunity-form :visible="opportunityDialog" :opportunity="opportunity" allow-clone :create="createOpportunity" :update="updateOpportunity" :show="showOpportunity" @clone="cloneOpportunity" @closeDialog="closeDialog" ref="OpportunityForm" />
    <csm-company-dialog ref="company-dialog" />
    <csm-user-dialog ref="user-dialog" />
    <csm-subscription-dialog ref="subscription-dialog" />
  </v-row>
</template>

<script>
import _ from 'lodash'

import { OPPORTUNITY_CHANGE_CLOSING_DATE, OPPORTUNITY_LINK_CART, OPPORTUNITY_LINK_NONE, OPPORTUNITY_LINK_ORDER_LINE, OPPORTUNITY_STATE_LOST, OPPORTUNITY_STATE_WON, OPPORTUNITY_STATE_PROPOSAL } from '@/services/opportunities'
const FIELD_COMPANY_ID = 'company'
const FIELD_SALES_PERSON_ID = 'id_lord_salesperson'

export default {
  name: 'OpportunitiesGrid',
  components: {
    'csm-opportunity-form': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/OpportunityForm')
  },
  props: {
    create: { type: Boolean, default: false },
    options: { type: Object, default: () => {} },
    pause: { type: Boolean, default: false },
    query: { type: String, default: '' }
  },
  data () {
    return {
      OPPORTUNITY_LINK_CART,
      OPPORTUNITY_LINK_NONE,
      OPPORTUNITY_LINK_ORDER_LINE,
      OPPORTUNITY_STATE_LOST,
      OPPORTUNITY_STATE_PROPOSAL,
      OPPORTUNITY_STATE_WON,
      closingDate: null,
      columns: null,
      companies: [],
      companiesCache: {},
      companiesSearch: '',
      createOpportunity: false,
      downsellReasonSearch: null,
      opportunityStates: [],
      opportunityStateSearch: '',
      salesPersonSearch: '',
      isActionRunning: false,
      isLogged: false,
      filters: ['id', 'company', 'name', 'owner', 'notes'],
      LINE_ACTIONS: {
        CHANGE_OWNER: 10,
        CHANGE_CLOSING_DATE: 20,
        DELETE_SELECTED: 30,
        CHANGE_DOWNSELL_REASON: 40
      },
      newDownsellReason: '',
      resourceId: 'opportunities',
      selectedOwner: null,
      selection: [],
      selectionAction: 0,
      selectionError: null,
      showOpportunity: false,
      updateOpportunity: false,
      opportunityDialog: false,
      opportunity: {}
    }
  },
  computed: {
    canCreate () {
      return this.create && this.isLogged && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES, this.$alto.API_PERMISSIONS.CREATE)
    },
    canRunAction () {
      return !this.selectionError && this.selectionAction && (this.selectionAction !== this.LINE_ACTIONS.DELETE_SELECTED || this.canDeleteMultiple())
    },
    gridOptions () {
      return {
        advancedSearchFieldsSchemaBaseUrl: `/docs/${this.resourceId}`,
        advancedSearchFieldsSchemaName: 'Opportunity',
        allowContextMenuOnCell: true,
        allowColumnsVisible: true,
        create: this.canCreate,
        debounce: {
          onList: 500 // Request rate is one every 500ms
        },
        delete: false,
        foreignFields: ['type_of_link', 'id', FIELD_COMPANY_ID, 'name', 'owner', 'notes'],
        foreignFieldsFilter: true,
        key: 'id',
        messages: {
          deleteItem: 'Please, confirm the deletion of opportunity {id} — {name}?'
        },
        multiSelect: true,
        notifyErrors: true,
        search: this.isLogged,
        show: false,
        sortBy: 'update_at',
        sortDescending: true,
        update: false,
        ...this.options
      }
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    me () { return this.$store.getters['$stratus-states/me'] }
  },
  watch: {
    query: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue !== oldValue) this.refreshGrid()
      }
    }
  },
  methods: {
    actionRun () {
      switch (this.selectionAction) {
        case this.LINE_ACTIONS.CHANGE_OWNER:
          this.changeOwner()
          break
        case this.LINE_ACTIONS.CHANGE_CLOSING_DATE:
          this.changeClosingDate()
          break
        case this.LINE_ACTIONS.DELETE_SELECTED:
          this.deleteSelected()
          break
        case this.LINE_ACTIONS.CHANGE_DOWNSELL_REASON:
          this.changeDownsellReason()
          break
      }
    },
    canDeleteMultiple () {
      let deletableCount = 0
      _.forEach(this.selection, selected => {
        if (this.$store.getters['$alto-roles/can'](selected.$can, this.$alto.API_PERMISSIONS.DELETE)) deletableCount++
      })
      return this.selection.length === deletableCount
    },
    canChangeDateClosing () {
      let changeCount = 0
      _.forEach(this.selection, selected => {
        if (this.$store.getters['$alto-roles/can'](selected.$can, this.$alto.API_PERMISSIONS.DELETE) && OPPORTUNITY_CHANGE_CLOSING_DATE.includes(selected.state)) changeCount++
      })
      return this.selection.length === changeCount
    },
    changeClosingDate () {
      this.$refs['confirm-opportunity-dialog']
        .open(this.$t('Change closing date'), this.$t('Do you want to set closing date «{date}» for {count} opportunities?', { date: this.$stratus.dt(this.closingDate).format('LL'), count: this.selection.length }))
        .then(async (confirmed) => {
          if (confirmed) {
            this.isActionRunning = true
            try {
              const ids = _.map(this.selection, 'id')
              await this.$store.dispatch('opportunities/setClosingDate', { ids, closingDate: this.closingDate })
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }
            this.refreshGrid()
            this.isActionRunning = false
          }
        })
    },
    changeDownsellReason () {
      this.$refs['confirm-opportunity-dialog']
        .open(this.$t('Downsell reason'), this.$t('Do you want to set reason «{name}» for {count} opportunities?', { name: this.$t(`downsell-reason-${this.newDownsellReason}`), count: this.selection.length }))
        .then(async (confirmed) => {
          if (confirmed) {
            this.isActionRunning = true
            try {
              const ids = _.map(this.selection, 'id')
              const failed = await this.$store.dispatch('opportunities/updateMultiple', { ids, fields: { downsell_reason: this.newDownsellReason } })
              if (failed) {
                const count = Object.keys(failed['opportunities-not-updated']).length
                if (count) this.$stratus.services.notify.warning(this.$t('{count} opportunities have not been modified.', { count: count }))
              }
            } catch (error) {
              console.error(error)
              this.$stratus.services.notify.error(error)
            }
            this.refreshGrid()
            this.isActionRunning = false
          }
        })
    },
    changeOwner () {
      this.$refs['confirm-opportunity-dialog']
        .open(this.$t('Change owner'), this.$t('Do you want to set owner «{name}» for {count} opportunities?', { name: this.selectedOwner, count: this.selection.length }))
        .then(async (confirmed) => {
          if (confirmed) {
            this.isActionRunning = true
            try {
              const ids = _.map(this.selection, 'id')
              await this.$store.dispatch('opportunities/setOwners', { ids, owner: this.selectedOwner })
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }
            this.refreshGrid()
            this.isActionRunning = false
          }
        })
    },
    cloneOpportunity (opportunity) {
      this.updateOpportunity = false
      this.createOpportunity = true
      this.showOpportunity = false
    },
    closeDialog () {
      this.opportunityDialog = false
      this.refreshGrid()
    },
    computeCsvData (rows) {
      if (!rows || rows.length === 0) return rows

      const result = []
      _.forEach(rows, item => {
        const orderedItem = {}
        Object.keys(item).forEach(key => {
          orderedItem[key] = item[key] // Add native field
          if (key === FIELD_COMPANY_ID) {
            orderedItem.company_name = this.companiesCache[item[FIELD_COMPANY_ID]] || this.$t('Unknown')
          } else if (key === FIELD_SALES_PERSON_ID) {
            orderedItem.salesperson_name = this.salesPersonNameById(item[FIELD_SALES_PERSON_ID]) || this.$t('None')
          }
        })
        result.push(orderedItem)
      })
      return result
    },
    createColumns () {
      let cols = [{
        text: 'Identifier',
        value: 'id'
      }, {
        text: 'Company',
        value: FIELD_COMPANY_ID
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Owner',
        value: 'owner'
      }, {
        text: 'State',
        value: 'state',
        width: 110
      }, {
        text: 'Details',
        value: 'link_id'
      }, {
        text: 'Accounting family',
        value: 'accounting_family'
      }, {
        text: 'Subscription',
        value: 'price',
        format: this.$stratus.services.fieldRenderers.CURRENCY
      }, {
        text: 'periodicity-long-O',
        value: 'price_setup',
        format: this.$stratus.services.fieldRenderers.CURRENCY
      }, {
        text: 'Buying price',
        value: 'buying',
        format: this.$stratus.services.fieldRenderers.CURRENCY
      }, {
        text: 'Buying Setup Price',
        value: 'buying_setup',
        format: this.$stratus.services.fieldRenderers.CURRENCY
      }, {
        text: 'Margin',
        value: 'margin',
        format: this.$stratus.services.fieldRenderers.PERCENT
      }, {
        text: 'Margin setup',
        value: 'margin_setup',
        format: this.$stratus.services.fieldRenderers.PERCENT
      }, {
        text: 'Sales Person',
        value: FIELD_SALES_PERSON_ID,
        hidden: false
      }, {
        text: 'Percentage of success',
        value: 'probability',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.PERCENT
      }, {
        text: 'Potential closing date',
        value: 'closing_date',
        hidden: true,
        format: (value) => {
          return value ? this.$stratus.services.fieldRenderers.DATE(value) : this.$t('Undefined')
        }
      }]

      if (this.isLord) {
        cols = cols.concat([{
          text: 'Source',
          value: 'first_contact'
        }, {
          text: 'Source details',
          value: 'first_contact_detail'
        }, {
          text: 'Downsell reason',
          value: 'downsell_reason',
          sortable: true,
          format: v => {
            return v ? this.$t(`downsell-reason-${v}`) : ''
          }
        }])
      }

      cols = cols.concat([{
        text: 'Note',
        value: 'notes',
        hidden: true
      }, {
        text: 'Updated at',
        align: 'right',
        alignValue: 'right',
        hidden: false,
        value: 'update_at',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }, {
        text: 'Created at',
        align: 'right',
        alignValue: 'right',
        hidden: true,
        value: 'create_at',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }])

      return cols
    },
    async createItem () {
      this.updateOpportunity = false
      this.createOpportunity = true
      this.showOpportunity = false
      if (this.$refs.OpportunityForm) await this.$refs.OpportunityForm.reset()
      /*
      * Set default attributes this new opportunity
      */
      this.opportunity = {
        id_lord_salesperson: null,
        notes: '',
        closing_date: this.$stratus.dt().add(2, 'M').format('YYYY-MM-DD'), // 2 month later
        type_of_link: OPPORTUNITY_LINK_NONE,
        state: 'qualification',
        probability: 0,
        price: 0,
        price_setup: 0,
        buying: 0,
        buying_setup: 0,
        margin: 0,
        margin_setup: 0
      }
      this.opportunityDialog = true
    },
    customSearch () {
      const cusSearch = [{}]
      cusSearch.raw = ''

      function add (qry) {
        if (cusSearch.raw) cusSearch.raw += '!!'
        cusSearch.raw += qry
      }

      if (this.opportunityStateSearch && this.opportunityStateSearch.length) {
        add(`state[eq]=${this.opportunityStateSearch}`)
      }

      if (this.isLord && this.downsellReasonSearch) {
        add(`downsell_reason[eq]=${this.downsellReasonSearch}`)
      }

      if (this.companiesSearch) {
        add(`${FIELD_COMPANY_ID}[eq]=${this.companiesSearch}`)
      }

      if (this.salesPersonSearch) {
        add(`${FIELD_SALES_PERSON_ID}[eq]=${this.salesPersonSearch}`)
      }

      if (this.query) {
        add(this.query.replace('query=', ''))
      }

      return cusSearch
    },
    deleteSelected () {
      if (!this.canDeleteMultiple()) return

      this.$refs['confirm-opportunity-dialog']
        .open(this.$t('Abandon opportunities'), this.$t('Do you confirm the deletion of {count} opportunities?', { count: this.selection.length }))
        .then(async (confirmed) => {
          if (confirmed) {
            this.isActionRunning = true
            try {
              const ids = _.map(this.selection, 'id')
              await this.$store.dispatch('opportunities/deleteMultiple', ids)
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }
            this.refreshGrid()
            this.isActionRunning = false
          }
        })
    },
    dataLoaded ({ success, error, items, count, pages }) {
      if (error) {
        console.error(error)
      }
      this.$emit('dataLoaded', { success, error, items, count, pages })
    },
    dataLoading (...args) {
      this.$emit('dataLoading', ...args)
    },
    getAccountingFamily (id) {
      return id ? this.$store.getters['$alto-catalog/getAccountingFamily'](id) : ''
    },
    getActions () {
      return [
        { text: this.$t('Change owner'), value: this.LINE_ACTIONS.CHANGE_OWNER },
        { text: this.$t('Change closing date'), value: this.LINE_ACTIONS.CHANGE_CLOSING_DATE, disabled: !this.canChangeDateClosing() },
        { text: this.$t('Abandon opportunities'), value: this.LINE_ACTIONS.DELETE_SELECTED, disabled: !this.canDeleteMultiple() },
        { text: this.$t('Assign downsell reason'), value: this.LINE_ACTIONS.CHANGE_DOWNSELL_REASON }
      ]
    },
    async getOrderId (onelineId) {
      try {
        const line = await this.$store.dispatch('orders/getProduct', this.currentOpportunity.link_id)
        if (!line || !line.cmd_id) throw new Error(this.$t('This opportunity refers to a command line not found!'))
        return line.cmd_id
      } catch (error) {
        console.log(error)
      }
    },
    loadCompanies () {
      this.$store.dispatch('$alto-companies/list')
        .then(() => {
          this.companiesCache = this.$store.getters['$alto-companies/cache']()
          this.companies = this.$stratus.services.fields.ObjectToSelectItems(this.companiesCache, { keyInValue: true })
          this.customers = [this.myCompany]
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    async loadOpportunityMeta () {
      await this.$store.dispatch('$alto-opportunities/loadStates')
      this.opportunityStates = this.$store.getters['$alto-opportunities/states']
      await this.$store.dispatch('$alto-catalog/loadAccountingFamilies')
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    refreshGrid () {
      if (this.$refs['opportunity-grid']) this.$refs['opportunity-grid'].fetchData()
    },
    resetFilters () {
      this.companiesSearch = ''
      this.opportunityStateSearch = ''
      this.salesPersonSearch = ''
    },
    salesPersonNameById (id) {
      const salesPerson = this.$store.getters['$alto-users/getSalesPerson'](id)
      return salesPerson ? salesPerson.firstname + ' ' + (salesPerson.lastname || '').toUpperCase() : null
    },
    selectItems (items) {
      this.selection = items || []
      this.validateSelection()
    },
    showItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.showOpportunity = true
      this.updateOpportunity = false
      this.createOpportunity = false
      this.$refs.OpportunityForm.reset(data.item)
      this.opportunity = data.item
      this.opportunityDialog = true
    },
    showSubscription (id) {
      try {
        if (this.$refs['subscription-dialog']) this.$refs['subscription-dialog'].open(id)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    showQuotation (id) {
      if (this.$root['cart-menu-quotation-dialog']) this.$root['cart-menu-quotation-dialog'].load(id)
    },
    updateItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.showOpportunity = false
      this.updateOpportunity = true
      this.createOpportunity = false
      this.$refs.OpportunityForm.reset()
      this.opportunity = data.item
      this.opportunityDialog = true
    },
    validateSelection () {
      let error = null

      if (this.selectionAction === this.LINE_ACTIONS.CHANGE_DOWNSELL_REASON) {
        const byState = _.groupBy(this.selection, 'state')
        if (Object.keys(byState).length > 1 || this.selection[0].state !== OPPORTUNITY_STATE_WON) {
          error = this.$t('This can apply only to opportunities won!')
        } else if (!this.newDownsellReason) {
          error = this.$t('A reason must be selected !')
        }
      }

      this.selectionError = error
    }
  },
  created () {
    this.isLogged = this.$stratus.services.auth.isLogged()
  },
  async mounted () {
    await this.$store.dispatch('$stratus-states/getMe')
    if (this.$alto.services.routes.connectionForbidden.call(this, this.me)) return

    this.loadCompanies()
    this.loadOpportunityMeta()
    this.closingDate = this.$stratus.dt().toISOString().substr(0, 10) // Today
    this.columns = this.createColumns()
  }
}
</script>
