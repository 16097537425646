<template>
  <v-form :ref="ref" v-model="valid" lazy-validation>
    <cs-alert-panel v-if="errorMessage" dense type="error" :text="errorMessage" />
    <v-row dense align="center" justify="start" v-for="(input, index) in productsByIndex" :key="index">
      <v-col v-if="input.ref">
        <csm-dyna-product-recursive v-model="input.ref" :detail="input" :product-ref="product.main[hasThisRef(product.main, input.index)]" :customer="product.code || customer" :in-cart="inCart" :indent="true" :large="large" @change="changeProduct" :choices-discount="false" :choices-product="true" />
      </v-col>
      <v-col v-else>
        <csm-dyna-product-recursive v-model="input.type[0]" :detail="input" :product-ref="product.main[hasThisRef(product.main, input.index)]" :customer="product.code || customer" :in-cart="inCart" :indent="true" :large="large" @change="changeProduct" :choices-discount="false" :choices-product="true" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import _ from 'lodash'
import INPUTS from './dyna-defines'

const DYNAMIC_LOAD_REF_QUANTITY_PATTERN = [0, 1] // Which kind of quantity enable a dynamic load of sub-products
const DYNAMIC_LOAD_REF_QUANTITY_LOAD = 1 // Which quantity will start the loading of sub-products

export default {
  props: {
    customer: { type: String, default: '' },
    large: { type: Boolean, default: false },
    product: { type: Object, default: () => {} },
    productRef: { type: Object, required: true },
    inCart: { type: Boolean, default: false }
  },
  components: {
    'csm-dyna-product-recursive': () => import(/* webpackChunkName: "components" */ './DynaProductModifyRecursive')
  },
  data () {
    return {
      mainProductCustom: [],
      INPUTS,
      errorMessage: '',
      inputs: [],
      inputByRef: {},
      inputById: {},
      inputByTypes: {},
      ref: this.$stratus.uuid(),
      refStack: [],
      show: false,
      valid: true
    }
  },
  computed: {
    productsByIndex () {
      return _.sortBy(this.productRef.main, 'index')
    }
  },
  methods: {
    addInput (input) {
      this.inputs.push(input)
      this.inputByRef[input.ref] = input
      this.inputById[input.uuid] = input
    },
    changeProduct (data) {
      const test = this.hasThisRef(this.mainProductCustom, data.index)
      if (test !== null) {
        // Si la réf est déjà présente on la modifie
        this.mainProductCustom[test] = data
      } else {
        this.mainProductCustom.push(data)
      }
      this.$emit('change', this.mainProductCustom)
    },
    hasThisRef (main, index) {
      let i = 0
      while (i < main.length) {
        if (main[i].index === index) return i
        i++
      }
      return null
    },
    delInput (input) {
      if (input.uuid) {
        this.inputs = _.remove(this.inputs, item => {
          if (item.fromUuid === input.uuid) console.log('inputs', item.ref, item.label, 'deleted')
          return item.fromUuid !== input.uuid // Yes, test is reversed to keep items
        })
        this.inputByRef = _.remove(this.inputByRef, item => {
          if (item.fromUuid === input.uuid) console.log('inputByRef', item.ref, item.label, 'deleted')
          return item.fromUuid !== input.uuid // Yes, test is reversed to keep items
        })
      }
    },
    buildInput ({ ref, name }, product, fromUuid) {
      let subTitle = ''
      if (fromUuid) {
        let fromProduct = this.inputById[fromUuid]
        if (fromProduct.dynamicLoadRef) fromProduct = fromProduct.dynamicLoadRef
        else fromProduct = this.getProductByRef(this.inputById[fromUuid].value)
        subTitle = fromProduct ? fromProduct.name : ''
      }

      const tmp = {
        uuid: this.$stratus.uuid(),
        fromUuid,
        index: product.index,
        ref,
        level: this.refStack.length,
        label: name || '',
        subTitle: subTitle || '',
        items: Array.isArray(product.quantity) ? product.quantity : null,
        value: Array.isArray(product.quantity) ? (product.quantity[0].value ? product.quantity[0].value : product.quantity[0]) : null,
        unit: product.quantity.unit || product.unit || null,
        valueMin: product.quantity && product.quantity.min ? product.quantity.min : null,
        valueMax: product.quantity && product.quantity.max ? product.quantity.max : null,
        dynamicLoadType: product.dynamicLoadType,
        dynamicLoadRef: product.dynamicLoadRef
      }

      if (tmp.valueMin && tmp.valueMax) {
        tmp.type = INPUTS.INTEGER_MIN_MAX
        tmp.value = tmp.value || tmp.valueMin
      } else {
        tmp.type = Array.isArray(product.quantity) ? INPUTS.SELECT : INPUTS.TEXT
      }
      // Hide input that have one value to choose from
      tmp.hidden = (tmp.items && tmp.items.length <= 1)

      return tmp
    },
    buildInputs (product, fromUuid) {
      /*
        * Recursive conversion of the properties of the product that need user inputs.
        * product.main:
        * [
        *   {"ref": "C02PDT101HL", "quantity": [2, 4, 8], "index": 0},
        *   {"ref": "C02MMO101HL", "unit": "Gb", "quantity": [2, 4, 8, 16], "index": 1},
        *   {"ref": "C02DIS101HL", "quantity": [1], "index": 2}
        * ]
        * C02DIS101HL:
        * [
        *   {"ref": "C02SDT101HL", "quantity": {"min": 10, "max": 4096}, "index": 1},
        *   {"type": ["disk"], "unit": "Gb", "quantity": {"min": 10, "max": 4096}, "index": 0}
        * ]
        */
      if (this.refStack.length > 10) {
        this.inputs = []
        this.inputByRef = {}
        this.inputById = {}
        this.inputByTypes = {}
        console.error('[catalog] CoDD/Circle of Death detected. Too many sub-product recursion!', this.refStack)
        console.error('[catalog] CoDD product stack', this.refStack)
        console.error('[catalog] CoDD last product', product)
        this.errorMessage = this.$t('[CoDD] Error reading products catalog.')
        return
      }

      if (product.main) {
        this.refStack.push(product.ref)
        const sorted = _.sortBy(product.main, p => { return p.index })
        let subProduct

        _.forEach(sorted, p => {
          /* Process record of "ref"
            * p -> {"ref": "P02SDT101HL", "quantity": {"min": 10, "max": 4096}, "index": 1
            * p -> {"ref": "P02SDT101HL", "quantity": [1, 2, 4, 8], "index": 1
            * p -> {"ref": "P02SDT101HL", "quantity": [1, 2, 4, 8], "index": 1
            *
            * We load the product given by *ref* and create a single input for the quantity
            */
          if (p.ref) {
            subProduct = this.getProductByRef(p.ref)
            if (subProduct) {
              /* Process record of "ref" with quantity = DYNAMIC_LOAD_REF_QUANTITY_PATTERN
                * p -> {"ref": "P02SDT101HL", "quantity": [0, 1], "index": 1
                *
                * When choosing DYNAMIC_LOAD_REF_QUANTITY_LOAD, here it's number «1», we must load its sub-products and show their inputs
                */
              // testing C01ALS104ML:
              p.dynamicLoadRef = Array.isArray(p.quantity) && _.isEqual(p.quantity.sort(), DYNAMIC_LOAD_REF_QUANTITY_PATTERN.sort()) ? subProduct : false

              const input1 = this.buildInput(subProduct, p, fromUuid)
              if (p.dynamicLoadRef) input1.ignoreAttr = true
              this.addInput(input1)

              // Attribute dynamicLoadRef means that we will load all sub-products when choosing DYNAMIC_LOAD_REF_QUANTITY_LOAD. So we DO NOT build inputs right now.
              if (!p.dynamicLoadRef) this.buildInputs(subProduct, fromUuid)
            } else {
              console.error('[buildInputs] ref not found:', p.ref)
            }
          } else if (p.type) {
            /*
              * p -> {"type": ["disk"], "unit": "Gb", "quantity": {"min": 10, "max": 4096}, "index": 0},
              *
              * For each type :
              *   - We load all the products of the given type
              *   - We create a select to choose one from all products loaded before
              *   - Plug a dynamic loader when an item is chosen from the list above
              */
            _.forEach(p.type, pType => {
              // Get all products for the given type
              const productsOfType = this.getProductsByType(pType)
              if (productsOfType && productsOfType.length > 0) {
                // So we've got some products for this type
                const inputLabel = pType // productsOfType[0].name

                // Create a list of products for this type
                const selectionItems = [{ value: '', text: this.$t('Choose...') }]
                // Keep a cache of products of this type
                this.inputByTypes[pType] = {}
                _.forEach(productsOfType, item => {
                  selectionItems.push({ value: item.ref, text: item.name })
                  this.inputByTypes[pType][item.ref] = { input: item }
                })

                // This input is a selection of products from the given type
                const input1 = this.buildInput({ ref: product.ref, name: inputLabel }, { index: p.index, quantity: selectionItems, dynamicLoadType: pType }, fromUuid) // dynamicLoadType to plug a loader of products of type «pType»
                input1.hasSub = true
                this.addInput(input1)

                // 2nd input for quantity
                const input2 = this.buildInput({ ref: product.ref, name: inputLabel + ' ' + product.name }, p, fromUuid)
                input2.mapInput = {
                  uuid: input1.uuid,
                  ref: 'value'
                }
                this.addInput(input2)
              }
            })
          } else {
            console.error('[buildInputs] wrong format (no ref/type):', JSON.stringify(p))
          }
        })
      }
      this.refStack.pop()
    },
    computeAttributes (inputs, parent, { debug } = {}) {
      const subById = {}
      let attr
      _.forEach(inputs, input => {
        if (input.ignoreAttr) return

        if (input.hasSub) {
          if (input.fromUuid) {
            attr = {
              ref: input.value,
              quantity: 1,
              index: input.index,
              sub: {
                main: []
              }
            }
            if (debug) attr._debug_input = input
            subById[input.fromUuid].sub.main.push(attr)
          } else {
            attr = {
              ref: input.value,
              quantity: 1,
              index: input.index,
              sub: {
                main: []
              }
            }
            if (debug) attr._debug_input = input
            subById[input.uuid] = attr
            parent.push(attr)
          }
        } else if (input.mapInput) {
          if (!input.fromUuid) {
            subById[input.mapInput.uuid].quantity = parseFloat(input.value)
          } else {
            // console.log('[computeAttributes] unknown use case:', JSON.stringify(input))
          }
        } else {
          if (input.fromUuid) {
            // Input from Ref is a sub-product
            attr = {
              ref: input.ref,
              quantity: parseFloat(input.value),
              index: input.index,
              sub: {
                main: []
              }
            }
            if (debug) attr._debug_input = input
            // subById[input.uuid] = attr
            // if (!subById[input.fromUuid]) {
            //   subById[input.fromUuid] = {}
            // }
            // if (!subById[input.fromUuid].sub) {
            //   subById[input.fromUuid].sub = { main: [] }
            // }
            subById[input.fromUuid].sub.main.push(attr)
          } else {
            // Input from Ref
            attr = {
              ref: input.ref,
              quantity: parseFloat(input.value),
              index: input.index,
              sub: {
                main: []
              }
            }
            if (debug) attr._debug_input = input
            parent.push(attr)
          }
        }
      })
    },
    getAttributes () {
      this.attributes = []
      this.computeAttributes(this.inputById, this.attributes)
      this._attributes = []
      // FIXME: remove this debug lines
      // console.log('[getAttributes] calling computeAttributes - - - - - - - - - -')
      this.computeAttributes(this.inputById, this._attributes, { debug: true })
      // console.log('[getAttributes]', this._attributes)
      // console.log('[getAttributes]', JSON.stringify(this._attributes))
      return this.attributes
    },
    getProductByRef (ref) {
      return this.$store.getters['$alto-catalog/get'](ref)
    },
    getProductsByType (type) {
      return this.$store.getters['$alto-catalog/getType'](type)
    },
    onChange (input) {
      if (input.dynamicLoadRef) {
        this.delInput(input)
        if (input.value === DYNAMIC_LOAD_REF_QUANTITY_LOAD) this.buildInputs(input.dynamicLoadRef, input.uuid)
      } else if (input.dynamicLoadType && this.inputByTypes[input.dynamicLoadType][input.value] && (!this.inputByTypes[input.dynamicLoadType].value || this.inputByTypes[input.dynamicLoadType].value !== input.value)) {
        this.delInput(input)
        this.inputByTypes[input.dynamicLoadType].value = input.value // Keep user selection for future deletion
        this.buildInputs(this.inputByTypes[input.dynamicLoadType][input.value].input, input.uuid)
      }

      this.$emit('change', input)
    },
    reset () {
      // Reset when product changed
      this.errorMessage = ''
      this.refStack = []
      this.inputByRef = {}
      this.inputById = {}
      this.inputByTypes = {}
    }
  }
}
</script>
