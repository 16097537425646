<template>
  <v-sheet :class="indentationClass">
    <div :class="{ focused }">
      <csm-product-name v-if="!detail.type" :name="product.name" :company="customer" class="primary--text" :reference="product.ref" />
      <csm-product-name v-else :name="beautifyTypeName(detail.type[0])" :company="customer" class="primary--text" :reference="product.ref" />
      <span v-show="product.main && product.main.length > 0 && product.quantityChosen !== 0 && !hideSubProductsExpander">
        <v-icon :class="showSubProducts ? 'rotate-once-180' : ''" @click="showSubProducts = !showSubProducts">
          $vuetify.icons.expand
        </v-icon>
      </span>

      <v-row dense align="center">
        <v-col :cols="Object().hasOwnProperty.call(detail.quantity, 'list') && isBoolean(detail.quantity.list) ? '' : 1" :class="isBoolean(detail.quantity.list) ? 'shrink text-no-wrap' : ''">
          {{ Object().hasOwnProperty.call(detail.quantity, 'list') && isBoolean(detail.quantity.list) ? (product.quantityChosen ? $t('Yes') : $t('None')) : $t('Qty:') }}
        </v-col>

        <v-col v-if="product.option_not_multiplication" class="shrink">
          <cs-help-icon>
            <div slot="content">
              {{ $t('The quantity of this product will not be multiplied by the quantities of its ancestors.') }}
            </div>
          </cs-help-icon>
        </v-col>

        <v-col cols="12" md="3">
          <csm-product-quantity-choice v-if="detail.quantity && product.quantityChosen !== undefined && typeof product.quantityChosen === 'number'" :customer="customer" :quantity-rules="detail.quantity" :quantity="product.quantityChosen" :unit="detail.unit" v-model="product.quantityChosen" @input="onChangeQuantity" />
        </v-col>

        <v-col v-if="type.length > 0" md="8">
          <!--S'il s'agit d'un type de produit-->
          <csm-dyna-product-select v-show="product.quantityChosen" :items="type" v-model="product" :customer="customer" item-text="name" return-object :class="(indent) ? `margin-indent-${level}` : ''" @change="onSelectProduct(product)" @blur="focused = false" @focus="focused = true" :readonly="readonly" />
        </v-col>
      </v-row>
    </div>

    <div v-show="product.quantityChosen !== 0">
      <div v-for="supProduct in product.main" v-show="showSubProducts" :key="supProduct.index" class="sub-product-border-left">
        <!--S'il s'agit d'un produit-->
        <csm-dyna-product-recursive v-if="supProduct.ref" v-model="supProduct.ref" :detail="supProduct" :indent="true" :level="level+1" @goToTop="goToTop" :super-indent="superIndent" :customer="customer" :readonly="readonly" />
        <!--S'il s'agit d'un type de produit-->
        <csm-dyna-product-recursive v-else v-model="supProduct.type[0]" :detail="supProduct" :indent="true" :level="level+1" @goToTop="goToTop" :super-indent="superIndent" :customer="customer" :readonly="readonly" />
      </div>
    </div>
  </v-sheet>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CsmDynaProductRecursive',
  props: {
    customer: { type: String, default: '' },
    detail: { type: Object, required: true },
    level: { type: Number, default: 0 },
    indent: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    superIndent: { type: Boolean, default: false },
    value: { type: String, required: true }
  },
  components: {
    'csm-dyna-product-select': () => import(/* webpackChunkName: "components" */ './DynaProductSelect'),
    'csm-dyna-product-recursive': () => import(/* webpackChunkName: "components" */ './DynaProductRecursive'),
    'csm-product-name': () => import(/* webpackChunkName: "components" */ './ProductName'),
    'csm-product-quantity-choice': () => import(/* webpackChunkName: "components" */ './ProductQuantityChoice')
  },
  data () {
    return {
      focused: false,
      hideSubProductsExpander: false,
      product: {},
      type: [],
      productToReturn: {},
      showSubProducts: true
    }
  },
  watch: {
    value: {
      handler () {
        if (this.value !== this.productToReturn.ref && this.detail.index === this.productToReturn.index) {
          this.initialiseProduct()
        }
      }
    }
  },
  computed: {
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    indentationClass () {
      const cls = (this.indent) ? `margin-indent-${(this.superIndent) ? this.level * 2 : this.level}` : ''
      return cls
    }
  },
  methods: {
    beautifyTypeName (type) {
      const tmp = type.split('-')
      return tmp.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')
    },
    getMinimalQuantity (quantity, quantityChosen) {
      if (Object().hasOwnProperty.call(quantity, 'list')) {
        if (quantity.list.indexOf(quantityChosen) >= 0) {
          return quantityChosen
        }
        return quantity.list[0]
      } else {
        if (quantityChosen >= quantity.min && quantityChosen <= quantity.max) {
          return quantityChosen
        }
        return quantity.min
      }
    },
    getProductByRef (ref) {
      return this.$store.getters['$alto-catalog/get'](ref)
    },
    getProductsByType (type) {
      return this.$store.getters['$alto-catalog/getType'](type) // Sorted list
    },
    goToTop (payload) {
      // On crée un produit à retourner avec les infos nécessaire
      this.productToReturn.quantity = this.product.quantityChosen
      this.productToReturn.ref = this.product.ref
      this.productToReturn.name = this.product.name
      if (!Object.hasOwnProperty.call(this.productToReturn, 'index')) {
        this.productToReturn.index = this.detail.index
      }

      // On s'occupe du main à retourner
      if (this.product.main && this.product.main.length > 0) {
        // Si le produit a un main non vide
        if (!Object().hasOwnProperty.call(this.productToReturn, 'sub')) {
          // Si le produit à retourner n'a pas encore de sub alors on lui en crée un vide
          this.productToReturn.sub = { main: [] }
        }
        // On test si la référence existe déjà dans le main du produit à retourner
        const test = this.hasThisRef(this.productToReturn.sub.main, payload.index)
        if (this.productToReturn.ref !== payload.ref) {
          // On filtre la ref lui même
          if (test !== null) {
            // Si la réf est déjà présente on la modifie
            this.productToReturn.sub.main[test] = payload
          } else {
            this.productToReturn.sub.main.push(payload)
          }
        }
      } else {
        // Si le produit n'a pas de main, on en ajoute un vide
        this.productToReturn.sub = { main: [] }
      }

      if (this.productToReturn.quantity === 0 || this.product.main.length === this.productToReturn.sub.main.length) {
        if (this.level === 0) {
          this.$emit('change', this.productToReturn)
        } else {
          this.$emit('goToTop', this.productToReturn)
        }
      }
    },
    hasThisRef (main, index) {
      let i = 0
      while (i < main.length) {
        if (main[i].index === index) return i
        i++
      }
      return null
    },
    hasToShowBoth () {
      return !(this.type.length === 0 || (Object().hasOwnProperty.call(this.detail.quantity, 'list') && this.detail.quantity.list.length === 1 && this.type.length === 0))
    },
    async initialiseProduct () {
      if (!this.product) return
      if (Object().hasOwnProperty.call(this.detail, 'type')) {
        // S'il s'agit d'un type de produit on remplit le type et on prend le premier produit des type
        this.type = await this.getProductsByType(this.detail.type)
        this.type.forEach(item => (item.quantityChosen = this.getMinimalQuantity(this.detail.quantity)))
        this.product = this.type[0]
      } else {
        this.product = await this.getProductByRef(this.value)
      }

      if (this.product) this.product.quantityChosen = this.getMinimalQuantity(this.detail.quantity)

      // On update le produit père avec l'ajout de la quantité
      if (this.product?.main?.length === 0 || this.product?.quantityChosen === 0) {
        this.goToTop(this.product)
      }

      this.showSubProducts = this.isLord || !this.product?.hide_subproduct
      this.hideSubProductsExpander = !this.showSubProducts
    },
    isBoolean (array) {
      return _.isEqual(array, [0, 1])
    },
    onChangeQuantity (payload) {
      this.$set(this.product, 'quantityChosen', payload)
      if (Object().hasOwnProperty.call(this.detail, 'type')) {
        this.type.forEach(item => {
          item.quantityChosen = this.getMinimalQuantity(this.detail.quantity, payload)
        })
      }

      this.$forceUpdate()
      this.goToTop(this.product)
    },
    onSelectProduct (payload) {
      if (payload.main.length === 0) {
        this.goToTop(payload)
      }
    }
  },
  created () {
    this.initialiseProduct = _.debounce(this.initialiseProduct, 500)
  },
  async mounted () {
    await this.initialiseProduct()
  }
}
</script>
