<template>
  <v-row v-if="count > 0" align="center" dense>
    <v-col v-if="!hideLabel" class="shrink text-no-wrap">
      {{ $t('Tickets:') }}
    </v-col>
    <v-col v-if="noExpand">
      <span v-for="(ticket, index) in ticketArray" :key="`${ticket.number}-${index}`">
        <div :class="column ? 'd-block' : 'd-inline-flex align-center'">
          <v-btn small rounded :href="getLink(ticket.provider, ticket.sysid)" target="manager_ticket" class="mr-1 text-no-wrap" :class="highlightFirst && index === 0 ? 'font-weight-bold' : ''">
            <v-icon v-if="!noIcon" small :class="highlightFirst && index === 0 ? 'primary--text' : 'menu-icon--text'">
              $vuetify.icons.ticket
            </v-icon>
            {{ ticket.number }}
          </v-btn>
        </div>
      </span>
    </v-col>
    <v-col v-else>
      <cs-expand-list :items="ticketArray" :row="!column">
        <template v-slot:expand-list-item="{ item, index }">
          <div :class="column ? 'd-block' : 'd-inline-flex align-center'">
            <v-btn small rounded :href="getLink(item.provider, item.sysid)" target="manager_ticket" class="mr-1 text-no-wrap" :class="highlightFirst && index === 0 ? 'font-weight-bold' : ''">
              <v-icon v-if="!noIcon" small :class="highlightFirst && index === 0 ? 'primary--text' : 'menu-icon--text'">
                $vuetify.icons.ticket
              </v-icon>
              {{ item.number }}
            </v-btn>
          </div>
        </template>
      </cs-expand-list>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'OrderTickets',
  props: {
    column: { type: Boolean, default: false },
    hideLabel: { type: Boolean, default: false },
    highlightFirst: { type: Boolean, default: false },
    noExpand: { type: Boolean, default: false },
    noIcon: { type: Boolean, default: false },
    tickets: { type: [Object, Array], default: () => {} },
    useExpandList: { type: Boolean, default: false }
  },
  data () {
    return {
      count: 0,
      ticketArray: []
    }
  },
  watch: {
    tickets: {
      immediate: true,
      handler (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          this.setTicketList(newValue)
        }
      }
    }
  },
  methods: {
    getLink (provider, ticketId) {
      if (!this.$store.getters.appConfig.ticketsAppLinks[provider]) return null
      return this.$store.getters.appConfig.ticketsAppLinks[provider].replace(this.$store.getters.appConfig.ticketsAppLinks.ticketIdPattern, ticketId)
    },
    setTicketList (tickets) {
      this.count = 0
      let _array = []
      _.forEach(tickets, (ticketsCategories, provider) => {
        _.forEach(ticketsCategories, (tickets, category) => {
          // Each ticket must have a number for display, a sysid for link and its provider
          if (_.isString(tickets) || _.isNumber(tickets)) {
            _array.push({ number: tickets, sysid: tickets, provider })
          } else if (_.isArray(tickets)) {
            _array = _array.concat(_.map(tickets, ticket => { return { ...ticket, provider } }))
          } else if (_.isObject(tickets)) {
            _array.push({ ...tickets, provider })
          }
          this.count++
        })
      })
      this.ticketArray = this.$stratus.services.sort.natural(_array, { number: 'desc' })
    }
  }
}
</script>

<style scoped>
.column {
  display: block;
}
</style>
