<template>
  <sca-modal-dialog :visible="show" :max-width="maxWidth" @closeDialog="close" @saveDialog="save" :can-save="canSave" :is-saving="isSaving" :action="action" :external-id="externalId()">
    <span slot="title">
      {{ subject }} — {{ update ? $t('Call') : $t('Add a call') }}
    </span>

    <span slot="buttons-header" v-if="customer">
      {{ customer }}
      <div class="text-caption text-truncate">{{ customerName(customer) }}</div>
    </span>

    <div slot="content">
      <v-form ref="followup-form" v-model="valid" lazy-validation>
        <v-row align="center" dense>
          <v-col cols="6" md="2">
            <sca-call-type-select v-model="callType" :label="$t('Type')" />
          </v-col>

          <v-col cols="6" md="3">
            <v-text-field v-model="callPhone" :label="$t('Phone')" :rules="[$stratus.services.form.rules.phoneOrEmpty]" />
          </v-col>

          <v-col>
            <v-text-field v-model="subject" :label="$t('Subject')" class="required" />
          </v-col>
        </v-row>

        <v-row align="center" dense>
          <v-col>
            <sca-users-select v-model="caller" :label="$t('Caller')" :rules="[$stratus.services.form.rules.required]" class="required" filter-lord dense show-email show-phone show-company show-card link="emit" @link-click="openUser(caller)" />
          </v-col>

          <v-col v-if="companyCode === null">
            <sca-customer-select v-model="customer" :rules="[$stratus.services.form.rules.required]" class="required" :label="$t('Customer')" clearable dense @input="onChangeCustomer" show-email show-phone link="emit" @link-click="openCompany(customer)" />
          </v-col>

          <v-col>
            <sca-users-select v-model="callUser" :filter-company="customer" :label="$t('User')" :rules="[$stratus.services.form.rules.required]" class="required" dense show-email show-phone show-company show-card link="emit" @link-click="openUser(callUser)" />
          </v-col>
        </v-row>

        <v-row align="end" dense>
          <v-col cols="4">
            <cs-date-time-picker v-model="callDateTime" required />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <cs-wysiwyg-textarea v-model="callDetails" :placeholder="$t('Call details...')" :title="$t('Call details...')" :actions="['bold', 'italic', 'underline', 'olist', 'ulist']" :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" />
          </v-col>
        </v-row>
      </v-form>
    </div>

    <sca-footer-create-update-at-by slot="footer" v-model="followup" :link-user="isLord" />

    <csm-company-dialog ref="company-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

let previousId = null

export default {
  name: 'FollowUpCallDialog',
  props: {
    companyCode: { type: String, default: null },
    update: { type: Boolean, default: false }
  },
  data () {
    return {
      DESCRIPTION_MAX_LENGTH: 4095,
      DESCRIPTION_MIN_LENGTH: 1,
      callDateTime: '',
      callDetails: '',
      caller: null,
      callOpportunityIds: [],
      callPhone: '',
      callType: this.$store.getters['followups/ITEM_TYPE_CALL_IN'],
      callUser: null,
      customer: null,
      followup: {},
      id: null,
      idSalesPerson: null,
      isSaving: false,
      show: false,
      subject: '',
      valid: false
    }
  },
  watch: {
    companyCode: {
      immediate: true,
      handler (newValue) {
        this.customer = newValue
      }
    }
  },
  computed: {
    action () {
      if (this.canUpdate) return this.$t('Update')
      return this.$t('Details')
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.UPDATE)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.UPDATE)
    },
    ITEM_TYPE_CALL_IN () { return this.$store.getters['followups/ITEM_TYPE_CALL_IN'] },
    ITEM_TYPE_CALL_OUT () { return this.$store.getters['followups/ITEM_TYPE_CALL_OUT'] },
    callTypeList () {
      return [
        { value: this.ITEM_TYPE_CALL_IN, text: this.$t(this.ITEM_TYPE_CALL_IN) },
        { value: this.ITEM_TYPE_CALL_OUT, text: this.$t(this.ITEM_TYPE_CALL_OUT) }
      ]
    },
    color () { return this.$store.getters['followups/ITEM_COLOR'](this.$store.getters['followups/ITEM_TYPE_CALL']) },
    maxWidth () {
      if (this.$vuetify.breakpoint.xl) return '60%'
      if (this.$vuetify.breakpoint.lgAndUp) return '80%'
      return '100%'
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] }
  },
  async mounted () {
    if (this.$route?.name === 'followup-call' && this.$route.params.id) {
      if (this.$route.params.id !== previousId) {
        previousId = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        this.openId(this.$route.params.id)
      }
    }
  },
  methods: {
    close (saved = false) {
      this.show = false
      this.resolve(saved)
      this.$emit('close', saved)
    },
    customerName (id) { return this.$store.getters['$alto-companies/getName'](id) },
    externalId () {
      return this.id ? 'followup-call/' + this.id : ''
    },
    isDark () { return this.$store.getters['$stratus-states/isDark'] },
    async onChangeCustomer () {
      // Do not modify if a sales person is already input
      if (!this.customer || this.idSalesPerson) return null
      try {
        const salesPersonId = await this.$store.dispatch('$alto-companies/getSalesPerson', this.customer)
        if (_.isNumber(salesPersonId) && salesPersonId >= 0) {
          this.idSalesPerson = salesPersonId
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    open (data) {
      this.reset(data)
      this.followup = { ...data }
      this.show = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    async openId (id) {
      try {
        const followup = await this.$store.dispatch('$alto-followups/call', id)
        this.open(followup)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async reset (data = {}) {
      if (this.$refs['followup-form']) await this.$refs['followup-form'].reset()
      this.id = data.id
      this.caller = data.id_caller || null
      this.callDateTime = data.date_call || new Date()
      this.callDetails = data.details_call || ''
      this.callOpportunityIds = data.opportunity_ids ? [...data.opportunity_ids] : []
      this.callPhone = data.number_phone_user
      this.idSalesPerson = data.id_lord_salesperson
      this.callType = data.type_call || this.ITEM_TYPE_CALL_IN
      this.callUser = data.id_user
      this.subject = data.subject || ''
      this.customer = data.code || this.companyCode
      this.isSaving = false
      this.valid = false
    },
    save () {
      this.isSaving = true
      if (!this.$refs['followup-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      const data = {
        id: this.id,
        code: this.customer,
        id_caller: this.caller,
        id_user: this.callUser,
        number_phone_user: this.callPhone,
        id_lord_salesperson: this.idSalesPerson,
        opportunity_ids: this.callOpportunityIds,
        details_call: this.callDetails,
        subject: this.subject,
        type_call: this.callType,
        date_call: this.$stratus.dt(this.callDateTime).toISOString()
      }

      this.$store.dispatch('followups/saveCall', data)
        .then(response => {
          this.resolve(response)
          this.close(true)
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
          this.isSaving = false
        })
    }
  }
}
</script>
