<template>
  <v-row v-if="product.quantity > 0" justify="end" :class="indent ? 'sub-product-border-left': ''" no-gutters>
    <v-col cols="12">
      <v-row :class="{ focused }" no-gutters align="center">
        <v-col>
          <csm-product-name :show-ref="true" :company="customer" :name="product.name" :reference="product.ref" />
          <v-icon v-show="!hideSubProductsExpander && product.sub.main && product.sub.main.length > 0" :class="showSubProducts ? 'rotate-once-180' : ''" @click="showSubProducts = !showSubProducts">
            $vuetify.icons.expand
          </v-icon>
        </v-col>

        <v-col v-if="!readonly" class="shrink text-no-wrap">
          {{ $t('Qty:') }} {{ product.quantity }}
        </v-col>

        <v-col v-if="!readonly" class="shrink text-no-wrap ml-2">
          <v-spacer />
          <v-checkbox v-model="custom_discounts" :color="custom_discounts ? 'primary' : ''" dense hide-details class="ma-0 pa-0">
            <template v-slot:label>
              <span class="text-caption">
                {{ $t('Apply customer discounts') }}
              </span>
            </template>
          </v-checkbox>
        </v-col>

        <v-col v-if="!readonly" cols="3" lg="2" class="ml-4 pl-4">
          <cs-percent-input v-model="product.per_discount" :min="0" :max="100" dense @input="onChangeDiscount(product.per_discount)" :disabled="!product.custom_discounts" @blur="focused = false" @focus="focused = true" />
        </v-col>

        <v-col v-if="readonly" cols="12" md="5" class="text-right">
          <span class="text-caption">
            {{ $t('Discount') }}
          </span>
          : {{ $t('{percent}%', { percent: product.per_discount }) }}
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-row v-for="(subProduct, index) in product.sub.main" v-show="showSubProducts" :key="subProduct.index" no-gutters>
        <v-col v-if="subProduct" cols="12">
          <csm-dyna-product-discount-recursive ref="sub-product-discount" v-model="product.sub.main[index]" :indent="true" :level="level+1" @change="onChangeSubProduct" :super-indent="superIndent" :customer="customer" :readonly="readonly" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CsmDynaProductRecursive',
  props: {
    customer: { type: String, default: '' },
    level: { type: Number, default: 0 },
    indent: { type: Boolean, default: false },
    superIndent: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    value: { type: Object, required: true }
  },
  components: {
    'csm-dyna-product-discount-recursive': () => import(/* webpackChunkName: "components" */ './DynaProductDiscountRecursive'),
    'csm-product-name': () => import(/* webpackChunkName: "components" */ './ProductName')
  },
  data () {
    return {
      uuid: null,
      focused: false,
      hideSubProductsExpander: false,
      product: {},
      type: [],
      productToReturn: {},
      showSubProducts: false
    }
  },
  computed: {
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    chevron () { return _.repeat('>', this.level) },
    custom_discounts: {
      get () {
        return !this.product.custom_discounts
      },
      set (value) {
        this.product.custom_discounts = !value
        this.$forceUpdate()
        this.emitChange()
      }
    }
  },
  methods: {
    async getProductDiscount (ref) {
      return new Promise(resolve => {
        this.$store.dispatch('carts/getDiscount', { ref, company: this.customer })
          .then(res => {
            resolve(res.per_discount || 0)
          })
          .catch(error => {
            this.$stratus.services.notify.error(error)
          })
      })
    },
    build () {
      const result = {}

      // Construire sa propre racine produit
      result.ref = this.product.ref
      result.name = this.product.name
      result.index = this.product.index
      result.custom_discounts = this.product.custom_discounts
      result.per_discount = this.product.per_discount
      result.quantity = this.product.quantity

      // On s'occupe du main à retourner
      if (this.product.sub && this.product.sub.main.length > 0) {
        // Si le produit a un main non vide
        if (!Object().hasOwnProperty.call(result, 'sub')) {
          // Si le produit à retourner n'a pas encore de sub alors on lui en crée un vide
          result.sub = { main: [] }
        }
        // On test si la référence existe déjà dans le main du produit à retourner
        const test = this.hasThisRef(result.sub.main, this.product.ref)
        if (result.ref !== this.product.ref) {
          // On filtre la ref lui même
          if (test !== null) {
            // Si la réf est déjà présente on la modifie
            result.sub.main[test] = this.product
          } else {
            result.sub.main.push(this.product)
          }
        }
      } else {
        // Si le produit n'a pas de main, on en ajoute un vide
        result.sub = { main: [] }
      }

      // Récupérer tous les sous-produits de ce produit-ci, s'il y en a
      if (this.$refs['sub-product-discount']) {
        for (let i = 0; i < this.$refs['sub-product-discount'].length; i++) {
          result.sub.main.push(this.$refs['sub-product-discount'][i].build())
        }
      }

      return result
    },
    async getProductByRef (ref) {
      return await this.$store.dispatch('$alto-catalog/getProductByRef', ref)
    },
    hasThisRef (main, ref) {
      let index = 0
      while (index < main.length) {
        if (main[index].ref === ref) return index
        index++
      }
      return null
    },
    async initialiseProduct () {
      this.product = this.value
      this.productDetails = await this.getProductByRef(this.product.ref)
      this.showSubProducts = this.isLord || !this.productDetails.hide_subproduct
      this.hideSubProductsExpander = !this.showSubProducts
    },
    emitChange () {
      // This method should be debounced to prevent flood-calling
      this.$emit('change')
    },
    onChangeDiscount (payload) {
      this.product.per_discount = Number(payload)
      this.$forceUpdate()
      this.emitChange()
    },
    onChangeSubProduct () {
      this.emitChange()
    },
    setCheckbox (value) {
      this.custom_discounts = value
      if (this.$refs['sub-product-discount']) {
        for (let i = 0; i < this.$refs['sub-product-discount'].length; i++) {
          this.$refs['sub-product-discount'][i].setCheckbox(value)
        }
      }
    }
  },
  created () {
    this.uuid = this.$stratus.uuid()
    this.emitChange = _.debounce(this.emitChange, 500)
  },
  async mounted () {
    await this.initialiseProduct()
  }
}
</script>
