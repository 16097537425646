import _ from 'lodash'

const COLORS = ['White', 'Yellow', 'Blue', 'Red', 'Green', 'Black', 'Brown', 'Azure', 'Ivory', 'Teal', 'Silver', 'Purple', 'Navy blue', 'Gray', 'Orange', 'Maroon', 'Charcoal', 'Aquamarine', 'Coral', 'Fuchsia', 'Wheat', 'Lime', 'Crimson', 'Khaki', 'Pink', 'Magenta', 'Olden', 'Plum', 'Olive', 'Cyan']

const VEGETABLES = ['Artichoke', 'Arugula', 'Asparagus', 'Avocado', 'Bamboo', 'Bean', 'Beans', 'Beet', 'Endive', 'Bell Pepper', 'Melon', 'Broccoli', 'Gobo', 'Cabbage', 'Calabash', 'Capers', 'Carrot', 'Cassava', 'Cauliflower', 'Celery', 'Celtuce', 'Chayote', 'Corn', 'Cucumber', 'Gherkin', 'Pickling ', 'Edamame', 'Eggplant', 'Endive', 'Curly', 'Escarole', 'Fennel', 'Fiddlehead', 'Galangal', 'Garlic', 'Ginger', 'Grape', 'Greens', 'Leaves', 'Collard', 'Dandelion', 'Kale', 'Kohlrabi', 'Mustard', 'Rapini', 'Spinach', 'Chard', 'Turnip', 'Horseradish', 'Sunchokes', 'Jícama', 'Kale', 'Curly', 'Lacinato', 'Ornamental', 'Kohlrabi', 'Leeks', 'Lemongrass', 'Lettuce', 'Butterhead', 'Iceberg', 'Romaine', 'Lotus', 'Seed', 'Nopales', 'Okra', 'Olive', 'Onion', 'Parsley', 'Parsnip', 'Peas', 'Peppers', 'Plantain', 'Potato', 'Pumpkin', 'Purslane', 'Radicchio', 'Radish', 'Rutabaga', 'Shallots', 'Spinach', 'Taro', 'Tomatillo', 'Tomato', 'Turnip', 'Chestnut', 'Watercress', 'Yams', 'Zucchini']

function createName (rootState) {
  return rootState.$stratus.i18n.t('MY QUOTATION') + ' - ' + _.sample(COLORS) + ' ' + _.sample(VEGETABLES)
}

const getDiscount = ({ commit, rootState, state }, { ref, company }) => {
  // Do we have the data in cache
  if (state.customerDiscounts[company]) {
    return new Promise(resolve => {
      if (!state.customerDiscounts[company][ref]) {
        resolve({})
        return
      }

      resolve({
        per_discount: state.customerDiscounts[company][ref].discount || 0, // It's a percent
        per_discount_setup: state.customerDiscounts[company][ref].discount_setup || 0 // It's a percent
      })
    })
  }

  // Not found in cache, we must call API
  return new Promise((resolve) => {
    if (!ref) {
      resolve({})
      return
    }

    rootState.$stratus.services.api.get(`/products/modifiedprice?fields=ref,discount,discount_setup&query=code=${company}!!ref=${ref}`)
      .then(response => {
        const discount = {}
        if (response.results.length > 0) {
          if (response.results[0].discount > 0 && !Object.hasOwnProperty.call({ ref, company }, 'per_discount')) {
            discount.per_discount = Number(response.results[0].discount)
          }
          if (response.results[0].discount_setup > 0 && !Object.hasOwnProperty.call({ ref, company }, 'per_discount_setup')) {
            discount.per_discount_setup = Number(response.results[0].discount_setup)
          }
        }
        resolve(discount)
      })
      .catch(error => console.error('compute discount', error))
  })
}

const cleanCartToSave = cart => {
  return {
    name: cart.name,
    customer: cart.customer,
    items: cart.items.map(item => {
      const newItem = {
        uuid: item.uuid,
        ref: item.ref,
        main: item.main,
        quantity: item.quantity,
        nb_days_commitment: item.nb_days_commitment,
        per_discount: item.per_discount,
        custom_discounts: item.custom_discounts,
        per_discount_setup: item.per_discount_setup,
        caption: item.caption,
        customer_caption: item.customer_caption
      }
      if (item.no_price) {
        newItem.price = item.price
        newItem.buying_price = item.buying_price
      }
      return newItem
    }),
    description: cart.description || null,
    order_form_number: cart.order_form_number || null,
    per_partial_billing: cart.per_partial_billing || 0,
    opportunity_id: cart.opportunity_id || null,
    set_id: cart.set_id || null,
    id_sphere: cart.id_sphere,
    id_ticket_pre_sale: cart.id_ticket_pre_sale ? cart.id_ticket_pre_sale.id || cart.id_ticket_pre_sale : null,
    users_alerts: cart.users_alerts,
    purchase_chances: cart.purchase_chances || 0,
    potential_closing_date: cart.potential_closing_date || null,
    tags: cart.tags,
    id_pre_sale: cart.id_pre_sale,
    nb_days_begin_delay: parseFloat(cart.nb_days_begin_delay || 0),
    nb_days_building_time: parseFloat(cart.nb_days_building_time || 0),
    expiration_date: cart.expiration_date
  }
}

const state = {
  current: {
    items: []
  },
  customerDiscounts: {
    // 'C1111': { 'C02SDD101ML': { discount: 100, discount_setup: 0 } }, ...
  },
  list: [],
  randomName: null
}

const getters = {
  current: state => { return state.current },
  currentTotal: state => {
    function addPrice (key, sellingPrice, quantity) {
      if (!result[key]) {
        result[key] = { selling_price: 0 }
      }
      result[key].selling_price += (sellingPrice || 0) * (quantity || 0)
    }

    const result = {}
    state.current.items.forEach(item => {
      _.forEach(item.prices, (price, key) => { addPrice(key, price.selling_price, item.quantity) })
      _.forEach(item.setup_price, (price, key) => { addPrice(key, price.selling_price, item.quantity) })
      _.forEach(item.termination_price, (price, key) => { addPrice(key, price.selling_price, item.quantity) })
    })
    return result
  },
  currentQuantity: state => {
    let result = 0.0
    state.current.items.forEach(item => {
      if (item.float_quantity) {
        result += 1
      } else {
        result += item.quantity || 0
      }
    })
    return result
  },
  customerDiscount: state => (customer, ref) => {
    if (!state.customerDiscounts[customer]) return
    return state.customerDiscounts[customer][ref]
  },
  expirationDays: (state, getters, rootState, rootGetters) => date => {
    // Positive result = date is in the future = not expired
    // Negative result = date is in the past = expired
    return -rootState.$stratus.dt().diff(date, 'days')
  },
  expirationDateHtml: (state, getters, rootState, rootGetters) => date => {
    const d = getters.expirationDays(date)
    const d2 = d < 0 ? 0 : d
    const color = d <= 0 ? 'danger--text' : (d <= rootGetters.appConfig.carts.expirationWarningDelay ? 'warning--text' : '')
    const text = rootState.$stratus.i18n.tc('Expired|Expire tomorrow|Expires in {d} days', d2, { d })
    return `<span class="${color}">${text}</span>`
  },
  list: state => { return state.list || [] },
  randomName: state => { return state.randomName },
  get: state => id => {
    const _items = state.list || []
    let found
    let i = 0
    while (!found && i < _items.length) {
      if (_items[i].id === id) {
        found = _items[i]
      }
      i++
    }
    return found
  }
}

const actions = {
  addAttachments ({ commit, state, rootState }, { id, files }) {
    return rootState.$stratus.services.api.sendFilesPost(`/carts/${id}/files`, files, 'files')
      .then(response => {
        return response.body
      })
      .catch(error => commit('API_FAILURE', error))
  },
  delAttachment ({ commit, state, rootState }, { id, file }) {
    return rootState.$stratus.services.api.delete(`/carts/${id}/files/${file.name}`)
      .then(response => {
        return response.body
      })
      .catch(error => commit('API_FAILURE', error))
  },
  addToCurrent ({ commit, rootState, state }, { items, company }) {
    if (!Object.hasOwnProperty.call(items, 'main') || !Array.isArray(items.main)) {
      items.main = []
    }
    return new Promise((resolve) => {
      getDiscount({ commit, rootState, state }, { items, company })
        .then(res => {
          const item2 = { ...items }
          item2.per_discount = (res.per_discount) ? res.per_discount : 0
          item2.per_discount_setup = (res.per_discount_setup) ? res.per_discount_setup : 0
          return rootState.$stratus.services.api.post('/products/multiprices', { products: [Object.assign({ id: 0 }, item2)], code: company })
        })
        .then(res2 => {
          items.total = res2.total
          items.total_discount = res2.total_discount
          items.total_setup = res2.total_setup
          items.total_setup_discount = res2.total_setup_discount
          commit('addToCurrentCart', { rootState, items })
          resolve(items)
        })
        .catch(error => commit('API_FAILURE', error))
    })
  },
  create ({ commit, rootState }, { company, opportunityId, opportunitySetId } = {}) {
    commit('create', { rootState, company, opportunityId, opportunitySetId })
  },
  createName ({ commit, state, rootState }) {
    commit('createName', { rootState })
    return state.randomName
  },
  delete ({ commit, rootState }, cartId) {
    return rootState.$stratus.services.api.delete('/carts/' + cartId)
      .then(() => {
        commit('deleteCart', cartId)
      })
      .catch(error => commit('API_FAILURE', error))
  },
  delFromCurrent ({ commit, state, rootState }, uuid) {
    // Keep all other item
    const items = _.remove(state.current.items, item => item.uuid !== uuid)
    if (!items.length === state.current.items.length) {
      throw new Error('Cart item not found! ' + uuid)
    }
    commit('setCurrentCartItems', { rootState, items })
  },
  getDiscount,
  getDiscounts ({ commit, rootState }, customer) {
    return rootState.$stratus.services.api.get(`/products/modifiedprice?fields=id,ref,discount,discount_setup&query=code[eq]=${customer}`)
      .then(response => {
        commit('setCustomerDiscounts', { customer, prices: response.results })
      })
      .catch(error => commit('API_FAILURE', error))
  },
  getPrice ({ commit, rootState }, { code, ref, data }) {
    return rootState.$stratus.services.api.post('/products/multiprices', { products: [Object.assign({ id: 0 }, { ref }, data)], code })
      .then(response => {
        return { attributes: data, price: response }
      })
      .catch(error => commit('API_FAILURE', error))
  },
  getPriceDetails ({ commit, rootState, state }, { code, ref, data }) {
    return rootState.$stratus.services.api.post('/products/multiprices?prices_details=true', { code, ref, products: [Object.assign({ id: ref }, { ref }, data)] })
      .then(response => {
        return { ref, attributes: data, price: response }
      })
      .catch(error => commit('API_FAILURE', error))
  },
  getPrices ({ commit, rootState }, cart) {
    const data = { products: cart.items }
    if (cart.company) data.code = cart.company
    return rootState.$stratus.services.api.post('/products/multiprices', data)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  list ({ commit, rootState, state }) {
    return rootState.$stratus.services.api.get('/carts?limit=0&expired=true&page=1&fields=id,name,customer,total,total_setup,total_discount,total_setup_discount,updatedAt,expired,expiration_date&sort=-updatedAt')
      .then((response) => {
        commit('setList', { rootState, data: response.results })
        return response.results
      })
      .catch(error => commit('API_FAILURE', error))
  },
  listTop ({ commit, rootState, state }, count = 5) {
    return rootState.$stratus.services.api.get(`/carts?limit=${count}&expired=true&fields=id,name,customer,total,total_setup,total_discount,total_setup_discount,updatedAt,expired,expiration_date&sort=-updatedAt`)
      .then((response) => {
        return response.results || []
      })
      .catch(error => commit('API_FAILURE', error))
  },
  load ({ commit, rootState }, cartId) {
    return rootState.$stratus.services.api.get('/carts/' + cartId)
      .then((response) => {
        commit('addToList', { rootState, cart: response })
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  save ({ commit, rootState }, { cart }) {
    if (cart.id) {
      return rootState.$stratus.services.api.put('/carts/' + cart.id, cleanCartToSave(cart))
        .then((response) => {
          return { cart: response.body, isNew: false }
        })
        .catch(error => commit('API_FAILURE', error))
    }
    return rootState.$stratus.services.api.post('/carts', cleanCartToSave(cart))
      .then((response) => {
        commit('setCartId', response.id)
        return { cart: response, isNew: true }
      })
      .catch(error => commit('API_FAILURE', error))
  },
  saveCurrent ({ commit, rootState, state }) {
    if (state.current.id) {
      return rootState.$stratus.services.api.put('/carts/' + state.current.id, state.current)
        .then((response) => {
          return response
        })
        .catch(error => commit('API_FAILURE', error))
    }
    return rootState.$stratus.services.api.post('/carts', state.current)
      .then((response) => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  sendOrder ({ commit, rootState }, cartId) {
    return rootState.$stratus.services.api.post('/orders', { id: cartId })
      .then((response) => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  setCurrent ({ commit, rootState }, cart) {
    commit('setCurrent', { rootState, cart })
    return Promise.resolve(cart)
  }
}

const mutations = {
  addToCurrentCart (state, { rootState, items }) {
    if (!Array.isArray(items)) {
      items = [items]
    }

    items.forEach(item => {
      if (!item.ref || !item.quantity) {
        return Promise.reject(new Error(rootState.$stratus.i18n.t('Invalid cart item: {name} × {qty}', { name: item.ref, qty: item.quantity })))
      }
    })

    // Add an id for each line, this will simplify removal
    const _items = []
    items.forEach(item => {
      _items.push(
        Object.assign({}, _.cloneDeep(item), { uuid: rootState.$stratus.services.strings.getUUID() })
      )
    })

    if (!state.current.name) {
      state.current.name = createName(rootState)
      state.current.items = _items
      return
    }
    state.current.items = state.current.items.concat(_items)
  },
  addToList (state, { rootState, cart }) {
    let found = _.find(state.list, c => {
      return c.id === cart.id
    })
    if (found) {
      // Replace existing cart
      found = cart
    } else {
      // Add new cart
      state.list.push(cart)
    }
    state.list = _.sortBy(state.list, cart => {
      // Sort newer first, older last
      return -rootState.$stratus.dt(cart.updatedAt)
    })
  },
  clear (state) {
    state.current = { items: [] }
  },
  create (state, { rootState, company, opportunityId, opportunitySetId }) {
    state.current = {
      name: createName(rootState),
      description: '',
      items: []
    }
    if (company) state.current.customer = company
    if (opportunityId) state.current.opportunity_id = opportunityId
    if (opportunitySetId) state.current.set_id = opportunitySetId
  },
  createName (state, { rootState }) {
    state.randomName = createName(rootState)
  },
  deleteCart (state, cartId) {
    // Delete given cart from list
    state.list = _.remove(state.list, cart => cart.id === cartId)
  },
  setCartId (state, cartId) {
    state.current.id = cartId
  },
  setCurrent (state, { rootState, cart }) {
    const _items = []
    // Create item UUID if it does not already exists
    _.forEach(cart.items, item => {
      _items.push(
        Object.assign({ uuid: rootState.$stratus.services.strings.getUUID() }, _.cloneDeep(item))
      )
    })
    cart.items = _items
    // MUST use Object.assign to keep Vue aware of object change.
    state.current = Object.assign(state.current, _.cloneDeep(cart))
  },
  setCurrentCartItems (state, { rootState, items }) {
    const _items = []
    // Create item UUID if it does not already exists
    _.forEach(items, item => {
      _items.push(
        Object.assign({}, _.cloneDeep(item), (Object.hasOwnProperty.call(item, 'uuid') ? {} : { uuid: rootState.$stratus.services.strings.getUUID() }))
      )
    })
    items = _items
    state.current.items = _.cloneDeep(items)
  },
  setCustomerDiscounts (state, { customer, prices }) {
    const tmp = {}
    // Transform { id: 380, ref: "C02SDD101ML", discount: 100, discount_setup: 0 }
    // To "C02SDD101ML": { id: 380, ref: "C02SDD101ML", discount: 100, discount_setup: 0 }
    _.forEach(prices, price => { tmp[price.ref] = price })
    state.customerDiscounts[customer] = { ...tmp }
  },
  setList (state, { rootState, data }) {
    state.list = _.sortBy(data, cart => {
      // Sort newer first, older last
      return -rootState.$stratus.dt(cart.updatedAt)
    })
  },
  API_FAILURE (state, error) {
    if (error.status === 401) {
      console.warn('[API] status 401', error)
    } else throw error
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
