/* eslint-disable quote-props */
/* eslint-disable no-irregular-whitespace */
export default {
  'Visit <a href="{brand_website}" target="_sws">{brand} website</a> to stay aware about {brand} latest news and offers.': 'Visitez le <a href="{brand_website}" target="_sws">Site Web {brand}</a> pour les dernières actualités et offres.',
  'Manage your products, quotations and orders with <a href="https://manager.scalair.fr" target="_csi">Scalair Manager</a>.': 'Gérer vos produits, devis et commandes avec <a href="https://manager.scalair.fr" target="_csi">Scalair Manager</a>.',
  'Scalair': 'Scalair',
  'Scalair Manager': 'Scalair Manager',
  'Administration': 'Administration',
  'To automate the transition to full screen, you can install a kiosk extension in your browser.': 'Pour automatiser le passage en plein écran, vous pouvez installer une extension de type kiosk dans votre navigateur.',
  'Invalid date': 'Date invalide',
  'Disconnecting...': 'Déconnexion…',
  'Report': 'Signaler',
  'Yes': 'Oui',
  'No': 'Non',
  'All': 'Tous',
  'None': 'Aucun(e)',
  'Empty': 'Vide',
  'About': 'À propos',
  'Light theme': 'Thème clair',
  'Dark theme': 'Thème sombre',
  'Languages': 'Langages',
  'Home': 'Accueil',
  'Activities': 'Activités',
  'Reloading application...': 'Rechargement de l\'application…',
  'You must login to access your Scalair Manager.': 'Connectez-vous pour accéder à Scalair Manager.',
  'Can not connect to Scalair Manager!': 'Impossible de se connecter à Scalair Manager !',
  'Email': 'Email',
  'Recipient email': 'Email du destinataire',
  'Password': 'Mot de passe',
  'New password': 'Nouveau mot de passe',
  'Scalair applications access': 'Accès aux applications Scalair',
  'Block user': 'Bloquer la connexion de cet utilisateur',
  'Allow user': 'Débloquer la connexion de cet utilisateur',
  'Generate API key': 'Générer une clé d\'API',
  'Previous API key will be revoked and a new one will be generated. Confirm?': 'La clé d\'API en cours d\'utilisation sera révoquée et une nouvelle clé sera générée. Les applications tierces pourraient ne plus avoir accès à l\'API Scalair. Confirmez-vous la génération ?',
  'Reset password': 'Réinitialiser le mot de passe',
  'User will receive a password reset link. Confirm?': 'L\'utilisateur recevra un lien pour réinitialiser son mot de passe. Confirmez-vous la réinitialisation ?',
  '* Indicates required field': '* Champ obligatoire',
  'Password is mandatory to create user': 'Le mot de passe est obligatoire pour créer l\'utilisateur',
  'This kind of field is mandatory.': 'Ce type de champ est obligatoire',
  'Portal': 'Portail',
  'It\'s you!': 'C\'est vous !',
  'Username': 'Identifiant',
  'Users': 'Utilisateurs',
  'User': 'Utilisateur',
  'Spheres': 'Sphères',
  'Sphere': 'Sphère',
  'Bot': 'Automate',
  'user': 'Utilisateur',
  'bot': 'Automate',
  'Partner': 'Revendeur',
  'Crew': 'Équipe Scalair',
  'Crew?': 'Équipe Scalair ?',
  'Settings': 'Préférences',
  'Lastname': 'Nom de famille',
  'Please, confirm the deletion of user {lastname} {firstname} <{email}>?': 'Please, confirm the deletion of user {lastname} {firstname} <{email}>?', // «Confirmez-vous la suppression de l'utilisateur : {lastname} {firstname} ({login}) ?»
  'Please, confirm the deletion of the {name} sphere?': 'Confirmez-vous la suppression de la sphère "{name}" ?',
  'Show/hide menu': 'Afficher / cacher le menu',
  'CSV': 'CSV',
  'Note': 'Note',
  'Last update at {date}': 'Dernière modification le {date}',
  'Created at {date}': 'Créé le {date}',
  'Created by': 'Créé par',
  'Updated by': 'Modifié par',
  'by {name}': 'par {name}',
  'Comments': 'Commentaires',
  'Connection: ': 'Connexion : ',
  '{count} Items': '{count} éléments',
  'priority-critical': 'Critique',
  'priority-high': 'Élevée',
  'priority-moderate': 'Modérée',
  'priority-medium': 'Moyenne',
  'priority-low': 'Basse',
  'priority-planning': 'Planifiée',
  'Severity': 'Sévérité',
  'severity-disaster': 'Catastrophique',
  'severity-critical': 'Critique',
  'severity-high': 'Élevée',
  'severity-moderate': 'Modérée',
  'severity-average': 'Moyenne',
  'severity-low': 'Basse',
  'severity-planning': 'Planifiée',
  'scalair-status-compute': 'Calcul',
  'scalair-status-storage': 'Stockage',
  'scalair-status-network': 'Réseau',
  'scalair-status-voip': 'VoIP',
  'scalair-status-other': 'Autre',
  'State': 'État',
  'state-new': 'Nouveau',
  'state-in progress': 'En cours…',
  'Compute': 'Calcul',
  'Storage': 'Stockage',
  'Network': 'Réseau',
  'VoIP': 'VoIP',
  'Other': 'Autre',
  'and': 'et',
  'Columns': 'Colonnes',
  'Choose the columns to display': 'Choisissez les colonnes à afficher',
  'Back': 'Retour',
  'Edit': 'Modifier',
  'Add': 'Ajouter',
  'Clone': 'Dupliquer',
  'Save': 'Enregistrer',
  'Send order': 'Envoyer la commande',
  'Update order': 'Modifier la commande',
  'Send': 'Envoyer',
  'Return to Cart': 'Retourner au panier',
  'Are you sure ?': 'Êtes-vous sûr ?',
  'No.': 'n°',
  'Confirm': 'Confirmation',
  'Confirm deletion of item?': 'Confirmez-vous la suppression de l\'élément ?',
  'Confirm the stop of this product.': 'Confirmez-vous l\'arrêt du produit ?',
  'Confirm to completed this product.': 'Confirmez-vous la validation du produit ?',
  'Update': 'Modifier',
  'Delete': 'Supprimer',
  'Cannot delete this item!': 'Impossible de supprimer cet élément !',
  'Create': 'Créer',
  'Submit': 'Enregistrer',
  'Created at': 'Créé le',
  'Get CSV': 'Télécharger CSV',
  'Updated at': 'Modifié le',
  'Detail': 'Détail',
  'Details': 'Détails',
  'Usage': 'Utilisation',
  'Daily use': 'Utilisation journalière',
  'n/a': 'n/a',
  'Used this month: {count}{unit}': 'Utilisé ce mois : {count}{unit}',
  'This month: {count}{unit}': 'Ce mois : {count}{unit}',
  'Previous month: {count}{unit}': 'Mois précédent : {count}{unit}',
  'Previous: {count}{unit}': 'Précédent : {count}{unit}',
  'day|day|days': 'jour|jour|jours',
  'Product detail :': 'Détail du produit :',
  'Name': 'Nom',
  'Title': 'Titre',
  'Create {}': 'Création {}',
  'Page Not Found.': 'Page non trouvée',
  'Demo user': 'Utilisateur de démonstration',
  'Type': 'Type',
  'Id': 'Id',
  'Scalair account': 'Compte Scalair',
  'Application connection': 'Connexion aux applications',
  'Label': 'Intitulé',
  'Identifier': 'Identifiant',
  'Tags': 'Étiquettes',
  'Notes': 'Notes',
  'Saved!': 'Enregistré !',
  'Last update:': 'Mise à jour :',
  'Services Status': 'Météo des services',
  'Toggle fullscreen': 'Activer/Désactiver le plein écran',
  'Toggle widgets bar': 'Afficher/cacher la barre des widgets',
  'Supervision': 'Supervision',
  'Alarms': 'Alarmes',
  'Tickets': 'Tickets',
  'Hardware': 'Matériel',
  'Monitor': 'Suivi',
  'Choose one or more widgets above...': 'Choisissez un widget ci-dessus…',
  'Hide supervision widget': 'Cacher le widget de supervision',
  'Show supervision widget': 'Afficher le widget de supervision',
  'Hide': 'Cacher',
  'Show': 'Afficher',
  'No data...': 'Pas de données…',
  'No data with this criteria...': 'Pas de données avec ces critères…',
  'Your search for «{search}» found no results.': 'Votre recherche «{search}» ne donne aucun résultat.',
  'Rows per page': 'Lignes par page',
  'Hide grid columns headers': 'Cacher les entêtes des colonnes',
  'Display dates in long format': 'Afficher les dates en format long',
  'Display tickets with recent customer comments first': 'Afficher en premier les tickets récemment commentés par les clients',
  'Shortcuts': 'Raccourcis',
  'Ticket': 'Ticket',
  'Assigned to': 'Affecté à',
  'Profiles': 'Profils',
  'Choose a profile': 'Choisir un profil',
  'Profile': 'Profil',
  'Profile: ': 'Profil : ',
  'Profile name': 'Nom du profil',
  'New profile': 'Nouveau profil',
  'Default profile': 'Profil par défaut',
  'Choose as default profile': 'Choisir comme profil par défaut',
  'Confirm deletion of «{item}»?': 'Confirmez-vous l\'effacement de « {item} » ?',
  'Address': 'Adresse',
  'Host': 'Hôte',
  'Service': 'Service',
  'Message': 'Message',
  'Products & licenses': 'Produits & licences',
  'Virtual machines': 'Machines Virtuelles',
  'Licenses & other products': 'Licences & autres produits',
  'Customer': 'Client',
  'Customers': 'Clients',
  'Company': 'Société',
  'Companies': 'Sociétés',
  'Subscribed products': 'Produits souscrits',
  'Subscribed product': 'Produit souscrit',
  'Your products': 'Vos produits',
  'Instances': 'Instances',
  'Other products & licences': 'Autres produits & licences',
  'Other products': 'Autres produits',
  'Orders history': 'Historique des commandes',
  'Quotations': 'Devis',
  'Finalize': 'Finaliser',
  'Welcome to Scalair Manager': 'Bienvenue dans Scalair Manager',
  'This application allows you to manage all your Scalair products.': 'Cette application vous permet de gérer tous vos produits Scalair.',
  'List all your products.': 'Lister les produits',
  'Manage your licences and instances': 'Gérer vos licences et instances',
  'Purchasing new ones': 'En acheter de nouveaux',
  'Keep in touch with our teams...': 'Rester en contact avec nos équipes…',
  'Your report as been submitted.': 'Votre rapport a été envoyé.',
  'Please, confirm the deletion of company {code} — {name}?': 'Confirmez-vous la suppression de la société {code} — {name} ?',
  'Parent company': 'Société gérante',
  'Start typing to Search': 'Saisissez votre recherche',
  'Find...': 'Recherche…',
  'Company {code} — {name} created.': 'Société {code} — {name} créée.',
  'Company {code} — {name} updated.': 'Société {code} — {name} modifiée.',
  'Company {code} — {name} deleted.': 'Société {code} — {name} supprimée.',
  'User {lastname} {firstname} created.': 'Utilisateur {lastname} {firstname} créé.',
  'User {lastname} {firstname} updated.': 'Utilisateur {lastname} {firstname} modifié.',
  'User {lastname} {firstname} <{email}> deleted.': 'Utilisateur « {fullName} » supprimé.',
  'This email address is already used by another user!': 'Cette adresse mail est déjà utilisée par un autre utilisateur !',
  'You\'re not allowed to delete {lastname} {firstname} <{email}>!': 'Vous n\'êtes pas autorisé à supprimer « {fullName} » !',
  'Sphere "{name}" deleted.': 'Sphère "{name}" supprimé.',
  'You\'re not allowed to delete sphere "{name}".': 'Vous n\'êtes pas autorisé à supprimer la sphère "{name}".',
  'Your product has been updated.': 'Votre produit a bien été modifié',
  'Subscriptions': 'Souscriptions',
  'Subscription': 'Abonnement',
  'Subscription: ': 'Abonnement : ',
  'Setup': 'Installation',
  'Setup: ': 'Installation : ',
  'No user for this company.|One user for this company.|{count} users for this company.': 'Aucun Utilisateur pour cette société.|Un seul utilisateur pour ce client.|{count} utilisateurs pour cette société.',
  'Scalair contacts': 'Contacts Scalair',
  'Information': 'Information',
  'Code': 'Code',
  'Complement': 'Complément',
  'Fax': 'Fax',
  'Phone': 'Téléphone',
  'Mobile phone': 'Téléphone portable',
  'You should choose a sales manager, a project manager and one or more Service Manager for this customer.': 'Vous devriez choisir un responsable commercial, un chef de projet et un ou plusieurs CSM.',
  'Contracts': 'Contrats',
  'Contract': 'Contrat',
  'This contract': 'Ce contrat',
  'to_sign': 'À signer',
  'signed': 'Signé',
  'terminated': 'Terminé',
  'To sign': 'À signer',
  'Signed': 'Signé',
  'Sign date': 'Date de signature',
  'Terminated': 'Terminé',
  'Number': 'Numéro',
  'Principal\'s contract': 'Contrat principal',
  'Contract\'s number': 'Numéro de contrat',
  'Additional clause': 'Contrat rattaché',
  'Additional clauses': 'Contrats rattachés',
  'Document\'s path': 'Chemin du document',
  'Signature\'s date:': 'Date de signature :',
  'Begin\'s date:': 'Date de début :',
  'Please, confirm the deletion of contract {number}?': 'Confirmez-vous la suppression du contrat {number} ?',
  'Contract {number} updated.': 'Contrat {number} modifié.',
  'Contract {number} created.': 'Contrat {number} créé.',
  'Contract {number} deleted.': 'Contrat {number} supprimé.',
  'Document\'s path:': 'Chemin du document:',
  'Upload a contract:': 'Envoyer un document :',
  'Confirm selection of file «{name}»?': 'Confirmer la selection de ce document «{name}» ?',
  'Select': 'Sélectionner',
  'Select file': 'Sélectionner un document',
  'My contract': 'Mon contrat',
  'My contracts': 'Mes contrats',
  'Replace document:': 'Remplacer le document :',
  'Add a document:': 'Ajouter un document :',
  'Please select a company\'s code': 'Veuillez sélectionner un code compagnie',
  'Tags & Notes': 'Étiquettes & Notes',
  'Add a note': 'Ajouter une note',
  'Blocked?': 'Bloqué ?',
  'Allow user to authenticate': 'Autoriser l\'utilisateur à s\'authentifier',
  'Payment blocked': 'Paiement bloqué',
  'Account is blocked': 'Le compte est bloqué',
  'Account allowed': 'Compte autorisé',
  'This user can access Scalair applications such as Dashboard and Manager.': 'Cet utilisateur peut accéder aux applications Scalair, telles que Dashboard et Manager.',
  'This user cannot access Scalair applications such as Dashboard and Manager.': 'Cet utilisateur ne peut pas accéder aux applications Scalair, telles que Dashboard et Manager.',
  'Scalair Bot': 'Automate Scalair',
  'Firstname': 'Prénom',
  'Function': 'Fonction',
  'Job title': 'Titre',
  'Teams': 'Équipes',
  'Reset password...': 'Réinitialiser le mot de passe…',
  'Client': 'Client',
  'Sales Manager': 'Responsable commercial',
  'Project Manager': 'Chef de projet',
  'Service Manager': 'CSM',
  'Backup Service Manager': 'CSM remplaçant',
  'Account type': 'Type de compte',
  'Delete note': 'Supprimer la note',
  'Scalair applications account': 'Compte Scalair',
  'Scalair API key': 'Clé d\'API Scalair',
  'Generate API Key...': 'Générer une clé d\'API…',
  'List of companies that this user manage': 'Liste des sociétés gérées par cet utilisateur',
  'Do you want to delete «{note}»?': 'Voulez-vous supprimer la note « {note} » ?',
  'Products Catalog': 'Catalogue Produits',
  'Filter': 'Filtre',
  'Add to your shopping cart': 'Ajouter au panier',
  'No quotation|One quotation|{count} quotations': 'Aucun devis|Un devis|{count} devis',
  'No item added to cart.|One item added to cart.|{count} items added to cart.': 'Aucun produit ajouté au panier!|Un article ajouté au panier : {item}|{count} articles ajoutés au panier.',
  'Quotation «{name}» not found!': 'Devis « {name} » non trouvé !',
  'Order «{name}» not found!': 'Commande « {name} » non trouvée !',
  'Deposit validate by {mail} on {date}.': 'Acompte validé par {mail} le {date}.',
  'Cart': 'Devis',
  'Carts': 'Devis',
  'Quantity': 'Quantité',
  'Qty': 'Qté',
  'Qty:': 'Qté :',
  'No description...': 'Aucune description…',
  'No detail...': 'Aucun detail…',
  'MY QUOTATION': 'MON DEVIS',
  'Delete quotation': 'Supprimer le devis',
  'Delete quotations': 'Supprimer les devis',
  'Confirm deletion of quotation «{name}»?': 'Confirmez-vous la suppression du devis « {name} » ?',
  'Confirm deletion of quotations?': 'Confirmez-vous la suppression du/des devis ?',
  'Quotation «{name}» saved.': 'Devis « {name} » enregistré.',
  'Quotation «{name}» deleted!': 'Devis « {name} » supprimé !',
  'Download as PDF': 'Télécharger le PDF',
  'Download as CSV': 'Télécharger le CSV',
  'Secret': 'Secret',
  'Share a secret': 'Partager un secret',
  'This feature allows you to securely transmit information to an email. The recipient will receive two emails: one with the link for open the secret, a second with the password for unlock it. Once read, the secret will no longer be accessible.': 'Cette fonctionnalité permet de transmettre de manière sécurisée, de l\'information à une autre adresse mail. Le destinataire recevra deux mails : l\'un avec un lien pour ouvrir le secret, un second avec le mot de passe pour déverrouiller le secret. Une fois lu, le secret ne sera plus accessible.',
  'Secret send to {email}.': 'Secret envoyé à {email}.',
  'Reveal a secret': 'Révéler un secret',
  'Secret identifier': 'Identifiant du secret',
  'Reveal': 'Révéler',
  'Please, enter the secret identifier, and password. Then click on «Reveal» button to display the content of the secret.': 'Saisissez l\'identifiant et le mot de passe du secret. Puis cliquez sur le bouton « Révéler » pour en afficher le contenu.',
  'Please, enter the password for the secret and click on «Reveal» button to display the content of the secret.': 'Saisissez le mot de passe du secret, puis cliquez sur le bouton « Révéler » pour en afficher le contenu.',
  'Secret as been read and is now erased.': 'Le secret a été lu et est maintenant effacé.',
  'No secret for this id and password.': 'Aucun secret ne correspond à cet identifiant et ce mot de passe.',
  'Product': 'Produit',
  'Instance': 'Instance',
  'Select an action to perform...': 'Choisissez une action à réaliser…',
  'on selected instance | on {count} instances': 'sur l\'instance sélectionnée | sur {count} instances',
  'on selected line | on {count} lines': 'sur la ligne sélectionnée | sur {count} lignes',
  'Change sphere': 'Changer la sphère',
  'Do you want to set sphere «{pname}» for {count} subscribed products?': 'Voulez-vous affecter la sphère « {pname} » à {count} produits souscrits ?',
  '{count} subscribed product(s) did not match the reference company of the sphere, they have not been modified.': '{count} produit(s) souscrit(s) ne correspondaient pas à la société référente de la sphère, elles n\'ont pas été modifiées.',
  'You can change sphere for only one company at a time.': 'Vous ne pouvez changer la sphère que pour une société à la fois.',
  'No user found on {count}. | One user found on {count}. | {found} users found on {count}.': 'Aucun utilisateur trouvé sur {count}. | Un utilisateur trouvé sur {count}. | {found} utilisateurs trouvés sur {count}.',
  'No product found on {count}. | One product found on {count}. | {found} products found on {count}.': 'Aucun produit trouvé sur {count}. | Un produit trouvé sur {count}. | {found} produits trouvés sur {count}.',
  'Provider': 'Fournisseur',
  'Service Pack': 'Pack de Services',
  'Belongs to': 'Propriétaire',
  'Instance is {state}': 'L\'instance est {state}',
  'state_on': 'allumée',
  'state_off': 'éteinte',
  'state_stopped': 'suspendue',
  'state_unknown': 'état inconnu',
  'Instance is on': 'Instance allumée',
  'Instance is off': 'Instance éteinte',
  'Instance is not supervised': 'Instance non supervisée',
  'Instance is supervised': 'Instance supervisée',
  'Storage {name}': 'Stockage {name}',
  'Storage used': 'Stockage utilisé',
  'from {min}{unit} to {max}{unit}': 'De {min} {unit} à {max} {unit}',
  '{qty} * {name}': '{qty} × {name}',
  'Run...': 'Exécuter…',
  'End subscription': 'Arrêter la souscription',
  'Restart': 'Redémarrer',
  'Last refresh: {date}': 'Actualisé à {date}',
  'Last refresh on: {date}': 'Actualisé le {date}',
  'Start {name}': 'Démarrer {name}',
  'To start this instance, please enter your password:': 'Pour démarrer cette instance, saisissez votre mot de passe :',
  'Restart {name}': 'Redémarrer {name}',
  'To restart this instance, please enter your password:': 'Pour redémarrer cette instance, saisissez votre mot de passe :',
  'Stop': 'Arrêter',
  'To stop this instance, please enter your password:': 'Pour arrêter cette instance, saisissez votre mot de passe :',
  'Stop {name}': 'Arrêter {name}',
  'Unknown': 'Inconnu',
  'Allow/Disallow user\'s connection': 'Autoriser / Interdire la connexion de l\'utilisateur',
  'Roles': 'Rôles',
  'Invoicing': 'Facturation',
  'Business sector': 'Secteur d\'activité',
  'You can paste a SIRET number into this SIREN field to fill it in as well as the NIC field.': 'Vous pouvez coller un numéro de SIRET dans ce champ SIREN pour le renseigner lui ainsi que le champ NIC.',
  'This SIREN is already used by:': 'Ce numéro de SIREN est utilisé par :',
  'Orders': 'Commandes',
  'Order': 'Commande',
  'To order': 'Commander',
  'Order now': 'Passer la commande',
  'Confirm the order of quotation {name}?': 'Confirmez-vous le passage de la commande pour le devis {name} ?',
  'Order waiting for validation.': 'Commande en attente de validation',
  'Order in building.': 'Commande en construction',
  'Order canceled.': 'Commande annulée',
  'Order completed.': 'Commande livrée',
  'Order ID': 'Numéro de commande',
  'Order progress:': 'État de la commande :',
  'Begin: {date}': 'Démarrage : {date}',
  'End: {date}': 'Fin : {date}',
  'validating': 'En attente de validation',
  'Waiting': 'En attente',
  'Pending': 'En construction',
  'Repending': 'Évolution en cours',
  'Building': 'En construction',
  'Rebuilding': 'Évolution en cours',
  'Canceled': 'Annulé',
  'Stopped': 'Annulé',
  'Completed': 'Délivré',
  'Technical validation': 'Validation technique',
  'Financial validation': 'Validation financière',
  'Latest orders & quotations': 'Commandes & devis récents',
  'Last saved quotations': 'Devis récemment enregistrés',
  'action-Order': 'Commander',
  'state-validating': 'En attente de validation',
  'state-form-awaiting': 'En attente d\'informations',
  'state-tech-awaiting': 'En attente d\'info. techniques',
  'state-deposit-awaiting': 'En attente d\'acompte versé',
  'state-waiting': 'En attente',
  'state-pending': 'En construction',
  'state-re-pending': 'Évolution en cours',
  'state-stopping': 'Arrêt programmé',
  'state-stopped': 'Arrêté',
  'state-canceled': 'Annulé',
  'state-completed': 'Délivré',
  'Be careful: Payment is blocked by this company! Do not issue any order!': 'Attention : Paiement bloqué par cette société ! Ne plus passer de commande !',
  'Be careful: Payment is blocked by this company! Ticket cannot be saved!': 'Attention : Paiement bloqué par cette société ! Aucun ticket ne pourra être enregistré !',
  'Payment problem, please contact Scalair sales or administrative department.': 'Problème de paiement, merci de contacter le service commercial ou administratif Scalair.',
  'Use head-quarter address for invoicing.': 'Utiliser l\'adresse de la société comme adresse de facturation',
  'IBAN': 'IBAN',
  'BIC': 'BIC',
  'NIC': 'NIC',
  'SIREN': 'SIREN',
  'SIRET': 'SIRET',
  'VAT number must be valid!': 'Le numéro TVA doit être valide !',
  'VAT is mandatory!': 'Le numéro de TVA est obligatoire !',
  'E-Mail accounting': 'Email de facturation',
  'No product in catalog!|One product in catalog.|Loaded {count} products from catalog.': 'Aucun produit au catalogue!|1 produit au catalogue.|{count} produits dans le catalogue.',
  'Once': 'Forfait',
  'Hourly': 'Par heure',
  'Daily': 'Par jour',
  'Weekly': 'Par semaine',
  'Monthly': 'Par mois',
  'Quarterly': 'Par trimestre',
  'Yearly': 'Pour 1 an',
  'Bi-annually': 'Pour 2 ans',
  'Byannually': 'Pour 2 ans',
  'Three-yearly': 'Pour 3 ans',
  'If this switch is on, we will send one billing for each sphere. Otherwise, every product of every sphere will be send in one billing.': 'Activer la facturation par sphère signifie que le client recevra autant de facture que de sphères. Le cas échéant, une seule facture, regroupant tous les produits de toutes les sphères sera émise.',
  '{name}: {price}': '{name} : {price}',
  'Prices': 'Prix',
  '#': 'N°',
  'Caption': 'Libellé',
  'Price': 'Prix',
  'Price: ': 'Prix : ',
  'No product.': 'Aucun produit.',
  'No order.': 'Aucune commande.',
  'No quotation.': 'Aucun devis.',
  'Products': 'Produits',
  'Hide academic products': 'Cacher les produits pour les Académies',
  'Included:': 'Inclus :',
  'Total': 'Total',
  'Total: ': 'Total : ',
  'Total with discount': 'Total avec remise',
  'Total setup': 'Total d\'installation',
  'Total setup with discount': 'Total d\'installation avec remise',
  'Total setup incl. VAT': 'Total d\'installation TTC',
  'Total subscription': 'Total d\'abonnement',
  'Total subscription with discount': 'Total d\'abonnement avec remise',
  'Total subscription incl. VAT': 'Total d\'abonnement TTC',
  'Associated products:': 'Produits associés :',
  'Not available': 'Non disponible',
  'Hide products that are not available': 'Cacher les produits non disponibles',
  'section-main': 'Propriété du produit',
  'on selected company | on {count} companies': 'sur la société sélectionnée | sur {count} sociétés',
  'on selected quotation | on {count} quotations': 'sur le devis sélectionné | sur {count} devis',
  'Category: {category}': 'Catégorie : {category}',
  'Secrets': 'Secrets',
  'Close': 'Fermer',
  '{ description } — {ref}': '{ description } — {ref}',
  'Sub-product discount': 'Remise sous-produit',
  'Quick access:': 'Raccourcis :',
  'You don\'t have any quotation yet.': 'Vous n\'avez pas de devis en cours. Pour en créer un, utilisez le catalogue produits.',
  'When you do, their status will be visible here.': 'Leurs états s\'afficheront ici quand vous en aurez enregistré.',
  'Payment method': 'Moyen de paiement',
  'Payment term': 'Échéance de paiement',
  'Newsletters': 'Lettres d\'information',
  'Role': 'Rôle',
  'Last authentication: {date}': 'Dernière authentification le {date}',
  'Last authentication': 'Dernière authentification',
  'Never logged in.': 'Jamais connecté.',
  'Generate': 'Générer',
  'Add to cart': 'Ajouter au panier',
  'Discount': 'Réduction',
  'Discount %': '% Réduction',
  'Installation discount %': '% Réduction d\'installation',
  'Subscription discount %': '% Réduction d\'abonnement',
  'Subscription price: ': 'Prix d\'abonnement : ',
  'Subscription price': 'Prix d\'abonnement : ',
  'Subscr. margin: ': 'Marge abo. : ',
  'Setup price': 'Prix d\'installation',
  'Setup price: ': 'Prix d\'installation : ',
  'Setup margin: ': 'Marge d\'installation : ',
  'Buying price': 'Prix d\'achat',
  'Buying Setup Price': 'Coût interne d\'installation',
  'Margin subscription: ': 'Marge abonnement : ',
  'Margin subscription %: ': '% Marge abonnement : ',
  'Margin subscription %': '% Marge abonnement',
  'Margin: ': 'Marge : ',
  'Margin': 'Marge',
  'Product catalog': 'Catalogue des produits',
  'All products': 'Tous les produits',
  '{quantity} × {product} added to cart.': '{quantity} × {product} ajouté au panier.',
  'There is no cart!': 'Aucun panier !',
  'Billing start date': 'Début de facturation',
  'Billing end date': 'Fin de facturation',
  'Not started yet': 'Non commencé',
  'Not ended yet': 'Non terminé',
  'Progression': 'Progression',
  '(copy)': '(copie)',
  'Cart cloned.': 'Panier dupliqué.',
  'Sphere cloned.': 'Sphère dupliquée.',
  'User cloned.': 'Utilisateur dupliqué.',
  'Choose...': 'Choisissez…',
  'Order number': 'Numéro de commande client',
  'Add some important informations about your quotation...': 'Ajouter toutes les informations que vous jugez nécessaires pour ce devis…',
  '{percent}%': '{percent} %',
  '#Order': 'N° Commande',
  'Date': 'Date',
  'Authorizations': 'Autorisations',
  'Update Order': 'Modifier la commande',
  'Reference': 'Référence',
  'No filter': 'Aucun filtre',
  'Hide products at end of subscription': 'Cacher les produits en fin de souscription',
  '(Price excl. VAT)': '(Prix H.T.)',
  '(Price incl. VAT)': '(Prix T.T.C.)',
  'Total incl. VAT': 'Total T.T.C.',
  'Price excluding VAT': 'Prix H.T.',
  'Price including VAT': 'Prix T.T.C.',
  'VAT': 'T.V.A.',
  'excl.VAT': 'H.T.',
  'incl.VAT': 'T.T.C.',
  'New cart': 'Nouveau devis',
  'Quotations list': 'Liste des devis',
  'Creating a new cart will empty the current one. Do you saved you cart «{name}»?': 'Créer un nouveau panier va vider celui qui est chargé. Avez-vous enregistré votre panier « {name} » ?',
  'This is the current quotations.': 'Ce devis est le devis courant.',
  '[CoDD] Error reading products catalog.': '[CoDD] Erreur de lecteur du catalogue produits.',
  'Signature by mail': 'Signature par mail',
  'Electronic signature': 'Signature électronique',
  'Terms of your contract': 'Votre contrat',
  'This is your first order. Please take some time to verify that the following informations are correct. If not, you can modify them right now.': 'C\'est votre première commande. Merci de bien vouloir vérifier que les informations suivantes sont correctement renseignées. Vous pouvez les rectifier ci-dessous.',
  'When you will order, this quotation will be recorded as an order and sent to our teams.': 'Lorsque vous passerez commande, ce devis sera converti en commande puis envoyé à nos équipes.',
  'E-Mail accounts': 'Comptes mails',
  'Sphere {name} created.': 'Sphère {name} créée.',
  'Sphere {name} updated.': 'Sphère {name} modifiée.',
  'Please contact your Sales manager at Scalair.': 'Merci de contacter votre responsable commercial Scalair.',
  'Before ordering, one product needs your attention.|Before ordering, {count} products need your attention.': 'Avant de commander, un product requiert votre attention.|Avant de commander, {count} produits requièrent votre attention.',
  'This free field allows you to enter an internal order number, and thus better identify this future order and allow you to better follow it.': 'Ce champ libre vous permet de rentrer un numéro de commande interne, et donc mieux identifier cette future commande pour vous permettre de mieux la suivre.',
  'Spheres allow you to generate multiple invoices. To create and manage spheres, go to his space, in the administration tab!': 'Les sphères vous permettent de générer plusieurs factures. Pour créer et gérer les sphères, rendez-vous dans son espace, dans l\'onglet administration !',
  'Spheres allow you to better organize your products. Each sphere will generate a clean invoice. You have the possibility for each order to choose a sphere directly. If you later want to change the subscriptions contained in a sphere order, this will also be possible from the instances & other products tab.': 'Les sphères vous permettent d\'organiser au mieux vos produits. Chaque sphère génèrera une facture propre. Vous avez la possibilité à chaque commande de choisir une sphère directement. Si par la suite vous voudriez changer les abonnements contenus dans une commande de sphère, ça sera possible également depuis l\'onglet instances & autres produits.',
  'Welcome to our catalog. You will find below our entire product catalog. It is organized into themes. For more information, do not hesitate to get closer to our teams.': 'Bienvenue dans notre catalogue. Vous trouverez ci dessous l\'ensemble de notre catalogue produits. Il est organisé en thèmes. Pour plus d\'informations, n\'hésitez pas à vous rapprocher de nos équipes.',
  'These roles limit the possibilities available to your accounts:': 'Ces rôles permettent de définir les possibilités offertes à vos comptes :',
  'The administrator can do everything: create other users, fully use supervision, access to the catalog price and ordering process, etc.': 'L\'administrateur peut tout faire : créer d\'autres utilisateurs, utiliser pleinement la supervision, accès au catalogue tarifaire et à tout le processus de commande, ouvrir des tickets, etc.',
  'The business can carry out business actions: see all prices, have access to invoices, validate quotations.': 'Le business peut réaliser des actions commerciales : voir tous les prix, avoir accès aux factures, valider les devis et voir les tickets.',
  'The project manager can do technical actions and place orders: from the technical team, he has the same permissions as the technicians, and can place orders in addition.': 'Le chef de projet peut réaliser des actions techniques et passer des commandes : issue de l\'équipe technique, il a les mêmes permissions que les techniciens, et peut passer des commandes en plus.',
  'The accountant can do actions in relation to accounting : access only to invoices and contracts.': 'Le comptable peut réaliser des actions rapport à la facturation: il peut accéder uniquement aux factures, aux contrats et consulter les tickets.',
  'The technician can perform technical actions: fully use the supervision, list subscriptions and create quotation. He does not have the right to place an order.': 'Le technicien peut réaliser des actions techniques : utiliser pleinement la supervision, lister les souscriptions, réaliser des devis et ouvrir des tickets. Il ne peut pas passer commande.',
  'The spectator cannot do anything but observe : has access to the Dashboard and the Manager in read-only mode, he can access the supervision, see the prices, see the orders, etc. However, he cannot take action on the applications.': 'Le spectateur ne peut réaliser d\'autre d\'action que de voir: il a accès au Dashboard et au Manager en mode lecture seule, il peut accéder à la supervision, voir les prix, voir les commandes, voir les tickets, etc. Cependant il ne peut pas réaliser d\'action sur les applications.',
  'The pure technician is a role with access to all technical tools without seeing prices: he can see subscriptions, open tickets and have access to supervision.': 'Le technicien pur est un rôle ayant accès à tous les outils techniques sans voir les prix : il peut voir les souscriptions, ouvrir des tickets et avoir accès à la supervision.',
  'The supervisor is a role with access to supervision only.': 'Le superviseur est un rôle n\'ayant accès qu\'à la supervision.',
  'Validations': 'Validations',
  'Validate': 'Valider',
  'Deliver': 'Livrer',
  'Confirm validation': 'Confirmez la validation',
  'Validate is an irreversible step, do you confirm the validation?': 'Valider est une étape irréversible, confirmez-vous la validation ?',
  'Confirm delivery': 'Confirmez la livraison',
  'Display this product.': 'Afficher le produit',
  'Mark this product as delivered.': 'Marquer ce produit comme étant livré.',
  'Deliver the remaining product...|Deliver remaining {count} products...': 'Livrer le produit restant…|Livrer les {count} produits restants…',
  'Cancel the product order.': 'Annuler la commande de ce produit.',
  'Do you confirm the cancellation of the order of the product "{name}"?': 'Confirmez-vous l\'annulation de la commande du produit «{name}» ?',
  'Complete this product': 'Livraison du produit',
  'Cost: ': 'Frais : ',
  'Stop this product': 'Arrêt du produit',
  'margin-O: ': 'Marge forfait : ',
  'margin-O-%: ': '% Marge forfait : ',
  'Periodicity': 'Type d\'abonnement',
  'periodicity-long-strict': 'Périodicité',
  'Simulate the number of uses': 'Simuler le nombre de jour d\'utilisation',
  'off|Used for one day|Used for {count} days': 'Non utilisé|Utilisé une journée|Utilisé {count} jours',
  'For now your periodicity is {periodicity}': 'Pour le moment, vous avez un {periodicity}',
  'Please choose the new periodicity of your product:': 'Choisissez un nouveau type d\'abonnement pour votre produit :',
  'Modify products\' periodicity': 'Modifier le type d\'abonnement',
  'Invoices': 'Factures',
  'Invoice': 'Facture',
  'Saving your cart...': 'Enregistrement de votre panier…',
  'Updating cart data...': 'Mise à jour des informations du panier…',
  'Order updated.': 'La commande a été mise à jour.',
  'Update this product': 'Mettre à jour le produit',
  'Modify or stop product': 'Modifier ou arrêter le produit',
  'Modify product\'s properties': 'Modifier les caractéristiques du produit',
  'Please choose your new properties of your product:': 'Choisissez les nouvelles caractéristiques de votre produit :',
  'Stop subscription': 'Arrêter la souscription',
  'Permanently stop a part of all products': 'Arrêter définitivement une partie des produits',
  'Permanently stop all products': 'Arrêter définitivement tous les produits',
  'Please choose the new quantity wanted:': 'Indiquez la nouvelle quantité voulue :',
  'Installation Discount %': '% remise installation',
  'No product stopped|One product stopped|{count} products stopped': 'Aucun produit ne sera arrêté définitivement|Un seul produit s\'arrête|{count} produits s\'arrêtent',
  'All product stopped': 'Tous les produits seront arrêtés définitivement',
  'Select the date to stop the product:': 'Sélectionnez la date d\'arrêt du produit :',
  'Select the date to apply the modifications:': 'Sélectionnez la date d\'application des modifications :',
  'The order must be validated in order to be processed by our teams.': 'La commande doit être validée afin de pouvoir être traitée par nos équipes.',
  'Apply': 'Appliquer',
  'What do you want to do?': 'Que voulez-vous faire ?',
  'Start this product': 'Démarrer le produit',
  'Product update': 'Modification de produit',
  'Confirm the modifications?': 'Confirmez-vous les modifications ?',
  'Permanent product stop': 'Arrêt définitif de produit',
  'Confirm the permanent stop?': 'Confirmez-vous l\'arrêt définitif ?',
  '(Date is undefined)': '(Date non renseignée)',
  'Download this invoice.': 'Télécharger la facture.',
  'Modify this quotation.': 'Modifier le devis.',
  'Display this order.': 'Afficher la commande.',
  'Favorites': 'Favoris',
  'Add to your favorites': 'Ajouter à vos favoris',
  'Remove from your favorites': 'Retirer de vos favoris',
  'Add same product to this quotation': 'Ajouter un produit identique à ce devis',
  'Documents': 'Documents',
  'This folder contains your invoices and other precious documents.': 'Ce dossier contient vos factures et autres documents précieux.',
  'Select a File': 'Sélectionnez un fichier',
  'Select a file or a folder': 'Sélectionnez un fichier ou un dossier',
  'Download': 'Télécharger',
  'Name on invoices': 'Nom pour les factures',
  'IBAN is mandatory!': 'IBAN est obligatoire !',
  'BIC is mandatory!': 'BIC est obligatoire !',
  'BIC or IBAN is mandatory!': 'IBAN ou BIC est obligatoire !',
  'Your order has been saved. Before being taken into account by our teams, we kindly ask you to validate it financially, and technically by members of your company having the rights necessary for this purpose.': 'Votre commande a été enregistrée. Avant d\'être prise en compte par nos équipes, nous vous demandons de bien vouloir la valider financièrement, et techniquement par des membres de votre entreprise ayant les droits nécessaires à cet effet.',
  'Important!': 'Important !',
  'History': 'Historique',
  'Currently': 'Actuellement',
  'Estimated billing': 'Estimation de facturation',
  'Evolution of subscriptions': 'Évolution des abonnements',
  'This is an estimate of your monthly product billing by sector.': 'Il s\'agit d\'une estimation de la facturation de vos produits mensuels par secteur.',
  '{label} — {value}': '{label} — {value}',
  'Preferences': 'Préférences',
  'Use dark theme': 'Utiliser le thème sombre',
  'Use light theme': 'Utiliser le thème clair',
  'Use small application drawer': 'Utiliser un menu d\'application réduit',
  'Your preferences has been saved.': 'Vos préférences ont été enregistrées.',
  'State modified by': 'État modifié par',
  'Customer caption': 'Libellé client',
  'Tickets:': 'Tickets :',
  'Quotation for:': 'Devis pour :',
  'Quotation': 'Devis',
  'Quotation: {name}': 'Devis : {name}',
  'You are engaged on this product until {date}.': 'Vous êtes engagé sur ce produit jusqu\'au {date}.',
  'This complete stop of this product takes {days} notice days. You can program the stop of this product from {date}.': 'L\'arrêt complet de ce produit nécessite {days} jours de préavis. Vous pouvez programmer l\'arrêt de ce produit à partir du {date}.',
  'You will be able to stop it definitively after this deadline.': 'Vous pourrez l\'arrêter définitivement après cette échéance.',
  'This deadline has passed, so you can definitively stop this product if you wish.': 'Cette échéance est passée et vous pouvez donc arrêter définitivement ce produit si vous le désirez.',
  'List of news letters subscriptions. You will receive the activated ones.': 'Liste des souscriptions aux lettres d\'information. Vous recevrez celles qui sont activées.',
  'Connect as...': 'Se connecter en tant que…',
  'Choose a user...': 'Choisissez un utilisateur…',
  'Please choose the user you want to connect as. Only user accounts are shown, bot account are not allowed.': 'Choisissez l\'utilisateur dont vous voulez emprunter le compte. Les comptes de type automate ne sont pas autorisés.',
  'Returning to your account.': 'Retour à votre compte.',
  'Error connecting as {name}!': 'Erreur de connexion en tant que {name} !',
  'Expires on': 'Expire le',
  'Out-of-date': 'Expiré',
  'Expires on {date}': 'Expire le {date}',
  'This quotation expires on {date}.': 'Ce devis a expiré le {date}.',
  'Expired|Expire tomorrow|Expires in {d} days': 'Expiré|Expire demain|Expire dans {d} jours',
  'Folder': 'Dossier',
  'Send a document': 'Envoyer un document',
  'Send documents': 'Envoyer des documents',
  'Confirm uploading {count} file(s)?': 'Confirmez-vous l\'envoi de {count} fichiers(s) ?',
  '{count} files uploaded.': 'Aucun fichier envoyé | Un fichier envoyé. | {count} fichiers envoyés.',
  'File {name} deleted.': 'Fichier {name} supprimé.',
  'Delete file': 'Supprimer le fichier',
  'Confirm deletion of file «{name}»?': 'Confirmez-vous la suppression du fichier « {name} » ?',
  'Delete folder': 'Supprimer le dossier',
  'Confirm deletion of folder «{name}»?': 'Confirmez-vous la suppression du dossier « {name} » ?',
  'Retry': 'Réessayer',
  'Apply customer discounts': 'Appliquer les réductions clients',
  'Payment information': 'Informations de paiement',
  'Billing Address': 'Adresse de facturation',
  'Billing address': 'Adresse (facturation)',
  'Billing complement': 'Complément (facturation)',
  'Billing town': 'Localité (facturation)',
  'Billing zip code': 'Code postal (facturation)',
  'Billing country': 'Pays (facturation)',
  'Spheres allow you to organize your quotations and orders.': 'Les sphères vous permettent d\'organiser vos devis et commandes.',
  'Create folder:': 'Créer le dossier :',
  'Create folder here': 'Créer un dossier ici',
  'Folder {name} created.': 'Le dossier {name} a été créé.',
  'An error occurred!': 'Une erreur est survenue !',
  'Rotate': 'Pivoter',
  'Document': 'Document',
  'Status': 'Status',
  'End\'s date:': 'Date de fin :',
  'This contract is ongoing.': 'Ce contrat est en cours.',
  'A contract with number {n} already exists!': 'Un contrat numéro {n} existe déjà !',
  'List': 'Liste',
  'Tree': 'Arborescence',
  'Search for customers': 'Rechercher un client',
  'Not started': 'Non commencé',
  'Finished': 'Terminé',
  'Document «{doc}» for this contract is missing!': 'Le document «{doc}» associé à ce contrat est manquant !',
  'To search for a contract number or other information, use the list tab.': 'Pour chercher un numéro de contrat ou d\'autres informations, utiliser l\'onglet liste.',
  'To be signed': 'À signer',
  'Sign this contract...': 'Signer ce contrat…',
  'This contract must be signed to take effect.': 'Ce contrat doit être signé pour entrer en vigueur.',
  'Sign': 'Signer',
  'Finish': 'Terminer',
  'Finish this contract...': 'Terminer ce contrat…',
  'One product needs a caption.|{count} products need a caption.': 'Un produit requiert son libellé.|{count} produits requièrent leurs libellés.',
  'The caption is mandatory!': 'Le libellé est obligatoire !',
  'Display document': 'Afficher le document',
  'CMDB': 'CMDB',
  'CMDB Extract': 'Extrait de CMDB',
  'Show product references instead of product names': 'Montrer les références des produits au lieu de leurs noms',
  'Your CMDB will be converted to a CSV file. You will quickly receive an email with it. Please confirm.': 'Votre CMDB sera convertie en un fichier CSV qui va vous être envoyé par email rapidement. Confirmez-vous l\'envoi ?',
  'Choose the companies to extract their CMDB in CSV format. You will quickly receive an email with it.': 'Choisissez les sociétés pour extraire leurs CMDB au format CSV. Ce fichier vous sera envoyé par email rapidement.',
  'The extract is being created...': 'L\'extrait est en cours de création…',
  'List of people to be notified by email about the progress of the order': 'Liste des personnes à avertir par mail de l\'avancement de la commande',
  '{name} prices': 'Tarifs {name}',
  'Purchase chances': 'Probabilité d\'achat',
  'Potential closing date': 'Date potentielle de closing',
  'Hide products description': 'Cacher les descriptions',
  'Hide linked products': 'Cacher les produits associés',
  'Declaration date': 'Déclaré le',
  'Validated': 'Validé',
  'Validation date': 'Validé le',
  'Validated by': 'Validé par',
  'Validated at {date}': 'Validé le {date}',
  'Current quantity': 'Quantité actuelle',
  'Costs': 'Coûts',
  'No caption': 'Aucun libellé',
  'Sales Persons': 'Commerciaux',
  'Sales Person': 'Commercial',
  'VAT is mandatory for customers of type "{mandatory}". Excluding "{excluding}" business sectors.': 'La TVA est obligatoire pour les clients de type «{mandatory}». Mais pas pour le(s) secteur(s) d\'activité(s) «{excluding}».',
  'Hide tickets': 'Cacher les tickets',
  'status-Hide tickets. You can change this in the grid options.': 'Les tickets ne sont pas affichés. Vous pouvez modifier ceci dans les options de la grille.',
  'Order same product': 'Commander un produit identique',
  'Do you want to add product «{pname}» to {cname}?': 'Voulez-vous ajouter le produit « {pname} » à {cname} ?',
  'a new cart': 'un nouveau devis',
  'New cart {name} created.': 'Nouveau devis {name} créé.',
  'Historic updates': 'Historique changements',
  'Commercials': 'Commerciaux',
  'Opportunity': 'Opportunité',
  'Opportunities': 'Opportunités',
  'Salesperson': 'Commercial',
  'Price Subs. - Setup': 'Prix Sous. - Instal.',
  'Percentage of success': 'Pourcentage de succès',
  'Opportunity\'s order': 'Opportunité de commande',
  'Opportunity\'s order\'s lines': 'Opportunité des lignes de commande',
  'Prices:': 'Prix :',
  'Discount:': 'Remise :',
  'Total subscription:': 'Total abonnement :',
  'Total subscription with discount:': 'Total abonnement avec remise :',
  'Margin subscription:': 'Marge abonnement :',
  'Discount setup:': 'Remise installation :',
  'Total setup:': 'Total installation :',
  'Total setup with discount:': 'Total installation avec remise :',
  'Margin setup:': 'Marge installation :',
  'Margin setup': 'Marge installation',
  'Opportunity {name} updated.': 'Opportunité {name} modifiée.',
  'Opportunity {name} created.': 'Opportunité {name} créée.',
  'Your request will be processed immediately.': 'Votre demande sera traitée immédiatement.',
  'To stop this product, please contact Scalair customer support.': 'Pour arrêter ce produit, merci de contacter le support client Scalair.',
  'You cannot stop some quantity of this product. To stop all quantities of this product, please go to "Permanently stop all products".': 'Impossible d\'arrêter d\'autre produit. Pour arrêter définitivement tous les produits, cliquez sur "Arrêter définitivement tous les produits"',
  'Closing date': 'Date de closing',
  'opp-state-qualification': 'Qualification',
  'opp-state-proposal': 'Proposition',
  'opp-state-won': 'Gagnée',
  'opp-state-lost': 'Perdue',
  'opp-state-abandoned': 'Abandonnée',
  'Percentage of subscription discount:': '% de remise sur l\'abonnement :',
  'Percentage of setup discount:': '% de remise sur l\'installation :',
  'Opportunity...': 'Opportunité…',
  'Create opportunity': 'Créer une opportunité',
  'Update opportunity': 'Modifier l\'opportunité',
  'Take the lead': 'Prendre le lead',
  'Opportunity lost': 'Opportunité perdue',
  'Opportunity canceled': 'Opportunité annulée',
  'Abandon opportunity': 'Abandonner l\'opportunité',
  'You must provide an accounting family!': 'Vous devez renseigner une famille comptable !',
  'Abandoning the opportunity is irreversible. Please confirm?': 'Abandonner une opportunité est irréversible. Confirmez-vous ?',
  'Opportunity is won': 'L\'opportunité est gagnée',
  'Winning the opportunity is irreversible. Please confirm?': 'Gagner une opportunité est irréversible. Confirmez-vous ?',
  'Opportunity {name} has been updated.': 'L\'opportunité {name} a été mise à jour.',
  'Rates': 'Tarifs',
  'Remove opportunity': 'Retirer l\'opportunité',
  'This opportunity refers to a command line not found!': 'Cette opportunité référence une ligne de commande non trouvée !',
  'No history': 'Aucun historique',
  'The history below concerns the parent opportunity.': 'L\'historique ci-dessous concerne l\'opportunité mère.',
  'Related Opportunity...': 'Opportunité associée…',
  'Please, confirm the deletion of opportunity {id} — {name}?': 'Confirmez-vous la suppression de l\'opportunité {id} — {name}?',
  'This is a foreign customer. Contact the accounting department for invoicing.': 'Ce client est étranger. Contacter le service comptable pour la facturation.',
  'Allow customer to stop product': 'Autoriser l\'arrêt de produit par le client',
  'Delivered at {date} by': 'Livré le {date} par',
  'Delivered at {date} by {name}.': 'Livré le {date} par {name}.',
  'Order date': 'Date de commande',
  'Price trends': 'Évolution des prix',
  'Sales margin': 'Marge',
  'Sales margin%': '% Marge',
  'Gross margin': 'Marge brute',
  'Gross margin setup': 'Marge brute forfait',
  'No user for this company.': 'Aucun utilisateurs pour cette société.',
  'Internal production cost': 'Coût de production',
  'The quantity of this product will not be multiplied by the quantities of its ancestors.': 'La quantité de ce produit ne sera pas multipliée par les quantités de ses ascendants.',
  'New total:': 'Nouveau total :',
  'Total before changes:': 'Total avant modifications :',
  'Scheduler': 'Planificateur de tâches',
  'Template': 'Modèle',
  'Data': 'Données',
  'Scheduling': 'Planification',
  'Not run': 'Non exécuté',
  'Last run': 'Dernière exécution',
  'Next run': 'Prochaine exécution',
  'Choose how often this task should be performed.': 'Choisissez la fréquence d\'exécution de cette tâche.',
  'Every hour at minute': 'Toutes les heures, à la minute',
  'Every day at': 'Tous les jours à',
  'Every day': 'Tous les jours',
  'Every week on': 'Toutes les semaines, le',
  'Every month, the': 'Tous les mois, le',
  'Expert': 'Expert',
  'Relaunch if failure': 'Relancer si échec',
  'Please, confirm the deletion of task «{caption}»?': 'Confirmez-vous la suppression de la tâche «{caption}» ?',
  'Task template {name} deleted.': 'La tâche {name} à été supprimée.',
  'Body': 'Contenu',
  'If task execution failed, it will relaunch in the same order as first run.': 'Si l\'exécution de la tâche a échoué, elle sera relancée dans le même ordre que lors de la première exécution.',
  'at': 'à',
  'This is a cron format. Click for more details.': 'C\'est un format cron. Cliquez pour plus d\'informations.',
  'Required field': 'Champ obligatoire',
  'The product will be stopped after {days} notice.': 'Le produit sera arrêté après {days} jours de préavis.',
  'Prices diff:': 'Différence de prix :',
  'Is default': 'Par défaut',
  'Email on error': 'Email si erreur',
  'Send a mail on error': 'Envoyer un mail en cas d\'erreur',
  'If an error occurs, a mail will be sent to {email} and copy emails if any.': 'En cas d\'erreur, un mail est envoyé à {email} et une copie à la liste des emails en copie.',
  'E-Mail copy': 'Emails en copie',
  'Domicile of the company': 'Domiciliation',
  'Additional information': 'Informations complémentaires',
  'Business': 'Activité',
  'Business creation': 'Activité créée le',
  'Employees': 'Employés',
  'First contact': 'Premier contact',
  'First contact details': 'Détails de premier contact',
  'Source': 'Provenance',
  'Source details': 'Détails de la provenance',
  'Two-factor authentication for new users by default': 'Double authentification pour les nouveaux utilisateurs par défaut',
  'Turnover': 'Chiffre d\'affaire',
  'company-first-contact-phone_prospecting': 'Prospection téléphonique',
  'company-first-contact-event_meeting': 'Rencontre lors d\'un événement',
  'company-first-contact-get_in_touch': 'Prise de contact',
  'company-first-contact-lead_partner': 'Lead partner',
  'company-first-contact-emailing': 'Emaillage',
  'company-first-contact-google_ads': 'Google ads',
  'company-first-contact-kaspr': 'Kaspr',
  'company-first-contact-linkedin': 'LinkedIn',
  'company-first-contact-linkedin_ads': 'LinkedIn ads',
  'company-first-contact-media_coverage': 'Couverture médiatique',
  'company-first-contact-nomination': 'Nomination',
  'company-first-contact-partner': 'Partenaire',
  'company-first-contact-web_site': 'Site web',
  'company-first-contact-networking': 'Réseautage',
  'company-first-contact-existing_customer': 'Client existant',
  'Current subscriptions': 'Abonnements en cours',
  'Tickets and cancelled or stopped subscriptions are not in this list.': 'Ne sont pas listés : les tickets, ainsi que les souscriptions annulées ou stoppées.',
  'product|product|products': 'produit|produit|produits',
  'CSM': 'CloudSystem® Manager',
  'Accounting software': 'Logiciel de comptabilité',
  'Corporate': 'Entreprise',
  'Others': 'Autres',
  'License': 'Licence',
  'Computer hardware': 'Matériel informatique',
  'Telecom equipment': 'Équipement télécom',
  'Link': 'Lien réseau',
  'Fees for access to services': 'Frais d\'accès aux services (FAS)',
  'IT service': 'Service informatique',
  'Telecom service': 'Service télécom',
  'Follow-up': 'Suivi des échanges',
  'Old link': 'Ancien lien',
  'Cancel last update of this line': 'Annuler cette demande',
  'This will remove latest history item, update quantity with history\'s quantity, update state to completed, remove last update\'s tickets and delete it in apps, remove last opportunity ids and update order\'s price. Do you confirm?': 'La dernière entrée d\'historique de cette ligne sera supprimée, la quantité modifiée, l\'état passé à délivré, les tickets et opportunités concernées seront supprimées et les prix de la commande mis à jour. Confirmez-vous l\'annulation de cette ligne ?',
  'Overwrite file': 'Remplacer le fichier',
  'File «{name}» already exists!': 'Le fichier «{name}» existe déjà !',
  'File «{name}» already exists. Do you want to overwrite?': 'Le fichier «{name}» existe déjà. Voulez-vous le remplacer ?',
  'follow-up-type-call': 'appels',
  'follow-up-type-event': 'événements',
  'follow-up-type-email': 'emails',
  'follow-up-type-task': 'tâches',
  'follow-up-type-call-1': 'appel',
  'follow-up-type-event-1': 'événement',
  'follow-up-type-email-1': 'email',
  'follow-up-type-task-1': 'tâche',
  'Click to show or hide {name}.': 'Cliquer pour afficher ou cacher les {name}.',
  'Calls': 'Appels',
  'Call': 'Appel',
  'in': 'entrant',
  'out': 'sortant',
  'Add a call': 'Ajouter un appel',
  'Call details...': 'Détails de l\'appel…',
  'Delete call': 'Supprimer un appel',
  'Do you want to delete call of {date}?': 'Confirmez-vous la suppression de l\'appel du {date} ?',
  'Time': 'Heure',
  'Emails': 'Emails',
  'Content': 'Contenu',
  'Raw text': 'Texte brut',
  'From: ': 'De : ',
  'To: ': 'À : ',
  'Cc: ': 'Cc : ',
  'Percentage of order\'s deliver accomplishment.': 'Pourcentage de réalisation de la commande',
  'Name of the cart before ordered.': 'Nom du devis ayant généré la commande',
  'Company\'s code.': 'Code de la société',
  'Creation\'s date time.': 'Date et heure de création',
  'Current state of the order.': 'État actuel de la commande',
  'Order\'s ID.': 'Numéro de la commande',
  'Client\'s order\'s number.': 'Numéro client de la commande',
  'Subscription\'s total price of the order.': 'Prix total de l\'abonnement de la commande',
  'Subscription\'s total price of the order with discount.': 'Prix total de l\'abonnement de la commande avec remise',
  'Setup\'s total price of the order.': 'Prix total d\'installation de la commande',
  'Setup\'s total price of the order with discount.': 'Prix total de la commande avec remise',
  'Last update\'s date time.': 'Date et heure de la dernière mise à jour',
  'Attachments': 'Pièces jointes',
  'Unknown user': 'Utilisateur inconnu',
  'Can authenticate?': 'Authentification autorisée ?',
  'Authentication': 'Authentification',
  '2 factors': '2 facteurs',
  'Simple': 'Simple',
  'simple': 'simple',
  'Add a value': 'Ajouter une valeur',
  'Product\'s caption of the oneline.': 'Libellé du produit',
  'Order\'s ID': 'Numéro de la commande',
  'Company\'s code of the oneline.': 'Code société',
  'Customer product\'s caption of the oneline.': 'Libellé client du produit',
  'Beginning datetime of the oneline.': 'Date de démarrage',
  'Ending datetime of the oneline.': 'Date d\'arrêt',
  'Ordering datetime.': 'Date de la commande',
  'Product\'s name of the oneline.': 'Nom du produit',
  'Product\'s percentage of discount of the oneline.': 'Pourcentage de remise du produit',
  'Product\'s periodicity of the oneline.': 'Périodicité du produit',
  'Product\'s price of the oneline.': 'Prix du produit',
  'Product\'s setup\'s price of the oneline.': 'Frais d\'installation du produit',
  'Product\'s quantity of the oneline.': 'Quantité de produit',
  'Product\'s reference of the oneline.': 'Référence du produit',
  'Current state of the oneline.': 'État de la ligne de commande',
  'Product\'s total price of the oneline. Correspond to price multiplied by quantity.': 'Prix total price hors remise',
  'Product\'s total price with discount of the oneline.': 'Prix total remise incluse',
  'Product\'s total setup\'s price of the oneline. Correspond to price_setup multiplied by quantity.': 'Total des frais d\'installation hors remise',
  'Product\'s total setup\'s price with discount of the oneline.': 'Total des frais d\'installation remise incluse',
  'Product\'s setup\'s price with discount of the oneline.': 'Frais d\'installation remise comprise',
  'Product\'s percentage of discount of setup\'s price of the oneline.': 'Pourcentage de remise sur les frais d\'installation du produit',
  'Company\'s business city adress.': 'Ville de l\'adresse facturation.',
  'Company\'s business 3 first letter of country adress.': 'Code pays sur 3 lettres de l\'adresse de facturation.',
  'Company\'s business street adress.': 'Adresse de facturation.',
  'Company\'s business street adress 2.': 'Complément d\'adresse de facturation.',
  'Company\'s business zipcode adress.': 'Code postal de facturation.',
  'Is company has billings by sphere.': 'Facturation par sphère.',
  'Company\'s home city adress.': 'Ville de la société.',
  'Company\'s home 3 first letter of country adress.': 'Code pays sur 3 lettres.',
  'Company\'s home street adress.': 'Adresse de la société.',
  'Company\'s home street adress 2.': 'Complément d\'adresse de la société.',
  'Company\'s home zipcode adress.': 'Code postal de la société',
  'Company\'s manager ID.': 'Identifiant du CSM.',
  'Company\'s manager ID 2 (if first manager is already fill).': 'Identifiant du second CSM.',
  'Salesperson\'s ID of the company. Can be only modified by admin lord or salesperson\'s manager.': 'Identifiant du commercial',
  'Company\'s email adress.': 'Adresse email de la société',
  'Company\'s name.': 'Nom de la société.',
  'Company\'s nic.': 'Numéro NIC (SIREN + NIC = SIRET).',
  'Company\'s parent. Default to C1111.': 'Société gérante.',
  'Company\'s payment method.': 'Moyen de paiement.',
  'Company\'s payment terms.': 'Délai de règlement.',
  'Company\'s phone number.': 'Numéro de Téléphone.',
  'Company\'s siren.': 'Numéro de SIREN',
  'Company\'s type.': 'Type de société.',
  'Company\'s vat number.': 'Numéro de TVA.',
  'Zip code': 'Code postal',
  'No content...': 'Pas de contenu…',
  'Attendees': 'Participants',
  'No attendee|One attendee|{count} attendees': 'Aucun participant|Un participant|{count} participants',
  'Undefined sales person': 'Commercial non renseigné',
  'Undefined opportunity': 'Opportunité non définie',
  'Subject': 'Sujet',
  'Add an event': 'Ajouter un événement',
  'Event location: {location}': 'Lieu de l\'événement : {location}',
  'Task': 'Tâche',
  'Tasks': 'Tâches',
  'Add a task': 'Ajouter une tâche',
  'task-status-to_do': 'À faire',
  'task-status-doing': 'En cours',
  'task-status-done': 'Terminée',
  'task-priority-low': 'Basse',
  'task-priority-medium': 'Moyenne',
  'task-priority-high': 'Haute',
  'Due date: {date}': 'Échéance : {date}',
  'Due date': 'Échéance',
  'event-type-meeting': 'Réunion',
  'event-type-interview': 'Interview',
  'event-type-show': 'Démonstration',
  'event-type-cold_calling': 'Appel',
  'event-type-lunch': 'Déjeuner',
  'event-type-other': 'Autre',
  'Deleted? (¹)': 'Supprimé ? (¹)',
  'Show deactivated users': 'Afficher les utilisateurs désactivés',
  '(¹) You can see all users, even those who have been deleted. Deleted users are in fact simply deactivated.': '(¹) Vous pouvez voir tous les utilisateurs, même ceux qui ont été supprimés. Les utilisateurs supprimés sont en fait simplement désactivés.',
  'Return to quotation': 'Retour au devis',
  'Unknown field type «{type}»!': 'Type de champ « {type} » inconnu !',
  'Schedules List (admin)': 'Liste des Planifications (Admin)',
  'Schedules Tasks': 'Liste des Tâches planifiées',
  'Reason for cancellation': 'Motif de l\'annulation',
  'Package': 'Produit packagé',
  'Complex products including this reference will not be taken into account when calculating the price with and without discount, and the price including VAT.': 'Les produits complexes incluant cette référence ne seront pas pris en compte lors du calcul du prix avec et sans remise, et du prix incluant la TVA.',
  'No price fixed': 'Pas de prix fixe',
  'Please fill the price and buying price fields for this product.': 'Remplissez les champs prix et prix d\'achat de ce produit.',
  'To update price and buying price fields you need to update this product.': 'Pour modifier les champs prix et prix d\'achat, cliquez sur modifier le produit.',
  'Custom price': 'Prix',
  'Custom buying price': 'Prix d\'achat',
  'Quantity (sub-products quantity)': 'Quantité (Qté sous-produits)',
  'Unique IDs': 'Identifiants uniques',
  'Comment': 'Commentaire',
  'Scalair comment': 'Commentaire Scalair',
  'Comment here...': 'Commentez ici...',
  'Product {ref} not found!': 'Produit {ref} non trouvé !',
  'Deposit': 'Acompte',
  'Details of the monthly cost of a powered day:': 'Détails du coût mensuel pour une journée allumée',
  'Deposit percentage': '% d\'acompte',
  'A deposit will be required before delivering your order.': 'Un acompte sera nécessaire avant la livraison de votre commande.',
  'This order is waiting for paid deposit.': 'Cette commande est en attente d\'un acompte versé.',
  'Validate deposit': 'Validé l\'acompte',
  'The deposit has been validated.': 'L\'acompte a été validé.',
  'You need to input the country to validate the VAT!': 'Il faut saisir le pays pour valider la TVA !',
  'Include proposal (¹)': 'Inclure les propositions (¹)',
  '(¹) Taking into account opportunities that are not yet on order (in qualification status or proposal). Each calculated price is multiplied by the probability field.': '(¹) Prise en compte des opportunités qui ne sont pas encore passées en commande (en état de qualification ou proposition). Chaque prix calculé est multiplié par le champ probabilité.',
  'graph-opportunity-margin_setup_proposal-percent': '% Marge forfaitaire (¹)',
  'graph-opportunity-margin_proposal-percent': '% Marge (¹)',
  'graph-opportunity-buying_setup_proposal': 'Prix d\'achat forfaitaire (¹)',
  'graph-opportunity-buying_proposal': 'Prix d\'achat (¹)',
  'graph-opportunity-price_setup_proposal': 'Prix forfaitaire (¹)',
  'graph-opportunity-price_proposal': 'Abonnement (¹)',
  'graph-opportunity-internal_production_cost_proposal': 'Coût de production (¹)',
  'graph-opportunity-internal_production_cost_setup_proposal': 'Coût de production forfaitaire (¹)',
  'graph-opportunity-gross_margin_setup-percent_proposal': '% Marge brute forfaitaire (¹)',
  'graph-opportunity-gross_margin-percent_proposal': '% Marge brute (¹)',
  'Accounting': 'Comptable',
  'Impossible to place an order for a non-customer company.': 'Impossible de passer commande pour une société non-cliente.',
  'Unable to order for this company, please contact Scalair.': 'Impossible de commander pour cette société, veuillez contacter Scalair.',
  'Modify company {name} — {code}': 'Modifier la société {name} — {code}',
  'Your request will be processed at the end of the commitment.': 'Votre demande sera traitée à la fin de la période d\'engagement.',
  'Activity follow-up': 'Suivi d\'activité',
  'Sales Dashboard': 'Dashboard Commercial',
  'From {dateBegin} to {dateEnd}': 'Du {dateBegin} au {dateEnd}',
  'Return to dashboard': 'Retour au Dashboard',
  'Date range': 'Période',
  'Ref': 'Réf',
  'customer': 'client',
  'customers': 'clients',
  'Do you want to delete {subject}?': 'Confirmez-vous la suppression de {subject} ?',
  'Opportunity cloned.': 'Opportunité dupliquée.',
  'User email': 'Email utilisateur',
  'Owner': 'Titulaire',
  'Change owner': 'Changer le responsable',
  'Change closing date': 'Changer la date de closing',
  'Abandon opportunities': 'Abandonner les opportunités',
  'Do you confirm the deletion of {count} opportunities?': 'Confirmez-vous la suppression de {count} opportunités ?',
  'Do you want to set owner «{name}» for {count} opportunities?': 'Confirmez-vous l\'affectation du responsable « {name} » à {count} opportunités ?',
  'Caller': 'Appelant',
  'Organizer': 'Organisateur',
  'Company\'s business sector.': 'Secteur d\'activité de la société.',
  'Last week': 'Semaine dernière',
  'Created by Scalair': 'Créés par Scalair',
  'Created independently': 'Créés en autonomie',
  'Domain': 'Domaine',
  'Orders in progress or recently delivered': 'Commandes en cours ou livrées récemment',
  'Last quotations': 'Derniers devis',
  'Last products delivered': 'Derniers produits livrés',
  'product-domain-help-infrastructure': 'Scalair vous propose du IaaS à travers ses offres de Housing, Cloud et Cloud Dédié.',
  'product-domain-help-license': 'Scalair vous permet de déclarer vos usages d\'outils d\'éditeurs mais aussi de noms de domaine.',
  'product-domain-help-service': 'Scalair vous accompagne avec des prestations d\'expert sur mesure lors du BUILD et du RUN.',
  'product-domain-help-network': 'Scalair vous propose du transit internet avec IP Publiques en data-center mais aussi de créer des réseaux privés type L2L/MPLS.',
  'product-domain-help-security': 'Scalair dispose d\'une panoplie de produits/services permettant de répondre à vos besoins de sécurité.',
  'product-domain-help-all': 'Le catalogue complet Scalair avec notre expertise.',
  'Send technical information': 'Envoyer les informations techniques',
  'Send technical information...': 'Envoyer les informations techniques…',
  'Tech form': 'Informations Technique',
  '{count} questions': 'Aucune question|Une question|{count} questions',
  'This order needs some technical informations. Click on «Send technical information...» to provide them.': 'Cette commande nécessite des informations techniques. Cliquez sur « Envoyer les informations technique… » pour les fournir.',
  'Ask for help...': 'Demander de l\'aide…',
  'This will send a ticket to SCALAIR support. Do you confirm?': 'Un ticket sera envoyé au support SCALAIR. Confirmez-vous votre demande ?',
  'Ignore questionnaires...': 'Ignorer les questionnaires...',
  'Error for the response to « {question} »': 'Erreur dans la réponse à la question « {question} »',
  'Confirm that you override the questions?': 'Confirmez-vous passer outre les réponses aux questions ?',
  'Forecast': 'Prévisionnel',
  '{count} opportunities': 'Aucune opportunité|Une opportunité|{count} opportunités',
  'Minimal percentage of success': 'Pourcentage de succès minimum',
  'forecast-opportunities-booking': 'Prévisionnel abonnement',
  'forecast-opportunities-netbooking_gross': 'Prévisionnel marge abonnement',
  'forecast-opportunities-setup_gross': 'Prévisionnel marge forfait',
  'Click on a bar in the graph to see the opportunities.': 'Cliquez sur une barre du graphique pour en afficher les opportunités.',
  'Click on the graph to see the opportunities.': 'Cliquez sur le graphique pour en afficher les opportunités.',
  'Click on a bar in the graph to see the event list.': 'Cliquez sur une barre du graphique pour afficher la liste des événements.',
  'Click on a bar in the graph to see the call list.': 'Cliquez sur une barre du graphique pour afficher la liste des appels.',
  'Do you want to set closing date «{date}» for {count} opportunities?': 'Confirmez-vous le changement de date de closing au « {date} » pour {count} opportunités ?',
  'This cart contains no product that reach end of life.|This cart contains one product that reach end of life.|This cart contains {count} products that reach end of life.': 'Ce devis ne contient aucun produit en fin de vie.|Ce devis contient un produit en fin de vie.|Ce devis contient {count} produits en fin de vie.',
  'This product reach end of life.': 'Ce produit est en fin de vie.',
  'Please contact your sales manager at Scalair.': 'Veuillez contacter votre commercial Scalair.',
  'An adjustment must be made for the change to be taken into account.': 'Il faut faire une modification pour que le changement soit pris en compte.',
  'Show prices on details': 'Afficher les prix (hors taxes) dans le détail',
  'Latest documents': 'Derniers documents',
  'Health contact point': 'Point de contact santé',
  'Commitment\'s days': 'Jours d\'engagement',
  'Committed for {days} days': 'Pas d\'engagement|Engagé pour 1 jour|Engagé pour {days} jours',
  'Initial product commitment: {days} days': 'Pas d\'engagement initial du produit|Engagement initial du produit : 1 jour|Engagement initial du produit : {days} jours',
  'Committed until {date}': 'Engagé jusqu\'au {date}',
  'Until {date}': 'Jusqu\'au {date}',
  'Modify the commitment': 'Modifier l\'engagement',
  'Specific permissions': 'Permissions spéciales',
  'No specific permission.': 'Aucune permission spéciale.',
  'Resource': 'Ressource',
  'Permissions': 'Permissions',
  'Input permissions pressing enter key to create each of them': 'Saisissez les permissions en les validant avec la touche « entrée ».',
  'Question « {name} » requires a response!': 'La question « {name} » nécessite une réponse !',
  'Setup booking goal': 'Objectif de booking installation',
  'Subscription booking goal': 'Objectif de booking abonnement',
  'Sage Id': 'Identifiant Sage',
  'Jira Id': 'Identifiant Jira',
  'Number of calls': 'Nombre d\'appels',
  'per day': 'par jour',
  'Number of business meals': 'Nombre de repas d\'affaires',
  'Business meals': 'Repas d\'affaires',
  'per month': 'par mois',
  'Number of prospect appointments': 'Nombre de RDV prospect',
  'per week': 'par semaine',
  'Targets': 'Objectifs',
  'Event Targets': 'Objectifs événements',
  'Sales': 'Chiffres',
  'Target': 'Objectif',
  'Target: ': 'Objectif : ',
  'On target': 'Objectif atteint',
  'Under target': 'Sous l\'objectif',
  'calls': 'appels',
  'call(s)': 'appel(s)',
  'meal(s)': 'repas d\'affaires(s)',
  'event(s)': 'événement(s)',
  'Include weekends': 'Inclure les weekends',
  '{count} days': 'Aucun jour|Un jour|{count} jours',
  '{count} months': 'Aucun mois|Un mois|{count} mois',
  '{count} weeks': 'Aucune semaine|Une semaine|{count} semaines',
  '{count} calls': '{count} appels',
  '{count} events': '{count} événements',
  '{count} meals': '{count} repas',
  '{count} calls during weekend.': '{count} appels pendant les weekend.',
  'on {count} days': 'sur {count} jours',
  'on {count} months': 'sur {count} mois',
  'on {count} weeks': 'sur {count} semaines',
  'Average: ': 'Moyenne : ',
  'Average {count}{unit} {period}': 'Moyenne : {count} {unit} {period}',
  'No target is defined.': 'Aucun objectif défini.',
  'business meals': 'repas d\'affaires',
  '{count} business meals': '{count} repas d\'affaires',
  '{count} events with prospects': '{count} événements avec des prospects',
  '{count} business meals during weekend.': '{count} repas d\'affaires pendant les weekend.',
  'Events with prospects': 'Événements prospects',
  'Include users with no target': 'Inclure les utilisateurs sans objectif',
  'Number of event with prospects': 'Nombre d\'événements avec des prospects',
  'Phone is private:': 'Ce numéro de téléphone est privé :',
  'Reason for final stop': 'Raison de l\'arrêt',
  'Downsell reason': 'Motif de downsell',
  'Assign stop reason': 'Affecter une raison d\'arrêt aux produits',
  'Assign downsell reason': 'Affecter une raison aux downsell',
  'A reason must be selected !': 'Une raison doit être sélectionnée !',
  'This can apply only to stopped products !': 'Ceci ne peut s\'appliquer qu\'aux produits arrêtés !',
  'Do you want to set reason «{name}» for {count} subscribed products?': 'Confirmez-vous la raison d\'arrêt « {name} » pour {count} produits souscrits ?',
  '{count} subscribed product(s) have not been modified.': '{count} produits souscrits n\'ont pas été modifiés.',
  'Do you want to set reason «{name}» for {count} opportunities?': 'Confirmez-vous la raison d\'arrêt « {name} » pour {count} opportunité(s) ?',
  '{count} opportunities have not been modified.': '{count} opportunité(s) n\'ont pas été modifié(s).',
  'If you have a moment, tell us why you\'re stopping this product?': 'Si vous avez un instant, dites nous pourquoi vous arrêtez ce produit ?',
  'This can apply only to opportunities won!': 'Ceci ne peut être appliqué qu\'aux opportunités gagnées !',
  'CSM Dashboard': 'Dashboard CSM',
  '{label}= {value}': '{label} = {value}',
  'Top {count} downsell by customers': 'Top {count} downsell par client',
  'Distribution of downsell': 'Répartition des downsell',
  'Top {count} stopped products by downsell category': 'Top {count} des produits arrêtés, par catégorie de downsell',
  'No product has been stopped.': 'Aucun produit n\'a été arrêté.',
  'Show opportunities...': 'Afficher les opportunités…',
  'Look': 'Style',
  'Compare with history': 'Comparer avec l\'historique',
  'Quantity:': 'Quantité :',
  'Show only products to be delivered.': 'Afficher uniquement les produits à livrer.',
  'Billable': 'Facturable',
  'This ticket is billable': 'Ce ticket est facturable',
  'This ticket is not billable': 'Ce ticket n\'est pas facturable',
  'Waiting reason': 'Motif d\'attente',
  'Passed time': 'Temps passé',
  'Estimated time (minutes)': 'Estimation (minutes)',
  'Estimated time': 'Estimation du temps',
  'Billable passed time': 'Temps facturable',
  'Follow ticket': 'Suivre ce ticket',
  'Follow tickets': 'Suivre ces tickets',
  'Unfollow tickets': 'Ne plus suivre ces tickets',
  'Assign a sprint': 'Affecter un sprint',
  'Change tickets referrer': 'Changer le référent des tickets',
  'Delete tickets': 'Supprimer les tickets',
  'Change tickets owner': 'Changer le responsable des tickets',
  'Change tickets state': 'Changer l\'état des tickets',
  'Tracked tickets': 'Tickets suivis',
  'Tickets with me as referrer': 'Tickets dont je suis référent',
  'Tickets created be me': 'Tickets créés par moi',
  'Contributors': 'Contributeurs',
  'Trackers': 'Suivi par',
  'Referrer': 'Référent',
  'Reporter': 'Rapporteur',
  'Ticket {name} created.': 'Ticket « {name} » créé.',
  'Ticket {name} updated.': 'Ticket « {name} » modifié.',
  'Ticket «{name}» not found!': 'Ticket «{name}» non trouvé !',
  'Please, confirm the deletion of ticket {id} {name}?': 'Confirmez-vous la suppression du ticket « {id} {name} »?',
  'Closure date': 'Date de clôture',
  'Ticket life': 'Vie du ticket',
  'Sprints': 'Sprints',
  'Links': 'Liens',
  'Remaining time': 'Temps restant',
  'Request information': 'Demande d\'information',
  'Request information on a product': 'Demande d\'information sur un produit',
  'I am interested in this product « {name} » for...': 'Je suis intéressé par le produit « {name} » en vue de…',
  'Last comment on': 'Dernier commentaire le',
  'Last comment by': 'Dernier commentaire par',
  'Estimated delivery date': 'Date de livraison estimée',
  'You must choose a state for this ticket!': 'Vous devez choisir l\'état du ticket !',
  'Link to a ticket': 'Associer un ticket',
  'ticket-link-tickets': 'Tickets associés',
  'ticket-link-orders': 'Commandes',
  'ticket-link-onelines': 'Souscriptions',
  'ticket-link-products': 'Produits',
  'ticket-link-opportunities': 'Opportunités',
  'ticket-link-carts': 'Devis',
  'Link to': 'Lier',
  'Link an order': 'Lier une commande',
  'Link a product': 'Lier un produit',
  'Link a subscription': 'Lier une souscription',
  'Add sub-task from list': 'Ajouter une sous-tâche',
  'Link...': 'Lier…',
  'Ticket "{id} {name}" deleted.': 'Ticket « {id} {name} » supprimé.',
  'Follow': 'Suivre',
  'Tracking': 'Suivi',
  'Un-follow': 'Ne plus suivre',
  'Compact view': 'Vue compacte',
  'New link': 'Nouveau lien',
  'Save this ticket': 'Enregistrer ce ticket',
  'This ticket must be saved before adding an image. Do you confirm?': 'Confirmez-vous l\'enregistrement du ticket avant de pouvoir y ajouter une image ?',
  'This ticket must be saved before adding a sub-task. Do you confirm?': 'Confirmez-vous l\'enregistrement du ticket avant de pouvoir y ajouter une sous-tâche ?',
  'This ticket must be saved before adding an attachment. Do you confirm?': 'Confirmez-vous l\'enregistrement du ticket avant de pouvoir y ajouter une pièce-jointe ?',
  'Start at': 'Début le',
  'SLA': 'SLA',
  'SLA Dashboard': 'Dashboard SLA',
  'Due time': 'Délai prévu',
  'Passed time %': '% Temps passé',
  'Ticket name': 'Nom du ticket',
  'CDS Dashboard': 'Dashboard CDS',
  '{count} tickets': 'Aucun ticket|Un ticket|{count} tickets',
  'Total failures': 'Pannes totales',
  'Latest SLA': 'SLA urgents',
  'Distribution of tickets': 'Répartition des tickets',
  'Distribution of pending tickets': 'Répartition des tickets en cours',
  'Customer feedback': 'Retours clients',
  'Unallocated': 'Non affecté',
  'Ticket(s) not assigned': 'Ticket(s) non assigné(s)',
  'Change ticket company': 'Changer la société du ticket',
  'Confirm changing the company from {from} to {to}?': 'Confirmez-vous le changement la société {from} à {to} ?',
  'Change to company:': 'Changer la société :',
  'Group by': 'Groupe',
  'By team': 'Par équipe',
  'By user': 'Par utilisateur',
  'sla-state-ongoing': 'en cours',
  'sla-state-paused': 'en pause',
  'sla-state-stopped': 'arrêtée',
  'Estimated delivery time': 'Date de livraison estimée',
  'Pre-sale': 'Avant-vente',
  'My SLA': 'Mes SLA',
  'SLA of my team': 'SLA de mon équipe',
  'Display empty project tickets': 'Afficher les tickets projets vides',
  'Products concerned': 'Produits concernés',
  'Sub-products concerned': 'Sous-produits concernés',
  'List of virtual machines': 'Liste des machines virtuelles concernées',
  'Sub-product': 'Catégorie de produit',
  'Which company is concerned?': 'Quelle société est concernée ?',
  'Which companies are concerned?': 'Quelles sociétés sont concernées ?',
  'Which companies have this problem?': 'Quelles sociétés rencontrent ce problème ?',
  'This is an exceptionally urgent request': 'Ceci est une demande exceptionnellement urgente',
  'sub-product-help-infrastructure-backup-ms365': 'Service de sauvegarde de vos données MS365 vers Scalair.',
  'sub-product-help-infrastructure-dedicated-cloud': 'Plateforme dédiée Scalair ou VM/Volume de votre Cloud Dédié Scalair.',
  'sub-product-help-infrastructure-housing-network': 'Hébergement physique d\'équipements en Datacenter Scalair.',
  'sub-product-help-infrastructure-pra': 'Service de Plan de Reprise d\'Activités de votre infrastructure assuré par Scalair.',
  'sub-product-help-infrastructure-storage-cloud-scalair': 'Volume de stockage sur Cloud Scalair.',
  'sub-product-help-infrastructure-vm': 'Machine virtuelle sur Cloud Scalair.',
  'sub-product-help-license-backup': 'Veeam Backup & Replication ou Veeam Backup MS365.',
  'sub-product-help-license-captive-portal': 'Ucopia.',
  'sub-product-help-license-domain': 'DN Internet (Domain Name).',
  'sub-product-help-license-mails-protection': 'Vade Cloud ou Vade MS365.',
  'sub-product-help-license-microsoft-365': 'AD connect, entra ID, exchange online, sharepoint etc.',
  'sub-product-help-license-microsoft-spla': 'Windows Server, Remote Desktop, SQL etc',
  'sub-product-help-license-virtual-desktop': 'Ivanti/RES Workspace manager.',
  'sub-product-help-network-data-center': 'Délivré en datacenter.',
  'sub-product-help-network-lan-to-lan': 'Liaison point à point de niveau 2 ou rocade en datacenter.',
  'sub-product-help-network-mpls': 'Service d\'interconnexion de vos établissements.',
  'sub-product-help-security-captive-portal': 'Service Scalair basée sur la solution UCOPIA.',
  'sub-product-help-security-edr': 'EDR Harfanglab.',
  'sub-product-help-security-epp-antivirus': 'Antivirus & Patch Manager With-Secure.',
  'sub-product-help-security-firewall': 'Pare-feu (filtrage/VPN) Stormshield virtuel ou physique.',
  'sub-product-help-security-pam-bastion': 'Bastion Wallix pour les accès administrateurs.',
  'sub-product-help-security-sase': 'Solution CATO Networks convergeant réseau et cybersécurité.',
  'sub-product-help-security-ssl-certificate': 'Pour domaine et sous-domaine.',
  'sub-product-infrastructure-backup-ms365': 'Backup MS365',
  'sub-product-infrastructure-dedicated-cloud': 'Cloud dédié',
  'sub-product-infrastructure-housing-network': 'Housing & network',
  'sub-product-infrastructure-pra': 'PRA',
  'sub-product-infrastructure-storage-cloud-scalair': 'Stockage',
  'sub-product-infrastructure-vm': 'VM',
  'sub-product-license-backup': 'Backup',
  'sub-product-license-captive-portal': 'Portail captif',
  'sub-product-license-domain': 'Domaine',
  'sub-product-license-mails-protection': 'Protection mails',
  'sub-product-license-microsoft-365': 'O365',
  'sub-product-license-microsoft-spla': 'SPLA',
  'sub-product-license-virtual-desktop': 'Bureau virtuel',
  'sub-product-network-data-center': 'Transit Internet',
  'sub-product-network-lan-to-lan': 'Lan to lan',
  'sub-product-network-mpls': 'MPLS',
  'sub-product-security-captive-portal': 'Portail captif',
  'sub-product-security-edr': 'EDR',
  'sub-product-security-epp-antivirus': 'EPP / Anti-virus',
  'sub-product-security-firewall': 'Firewall',
  'sub-product-security-pam-bastion': 'PAM / Bastion',
  'sub-product-security-sase': 'SASE',
  'sub-product-security-ssl-certificate': 'Certificat SSL/TLS',
  'Incidents involving another product.': 'Incident concernant tout autre produit/système/service non cité.',
  'No tickets were processed.': 'Aucun ticket n\'a été traité.',
  'All tickets were processed.': 'Tous les tickets ont été traités.',
  'Only {count} tickets on {total} were processed.': 'Seulement {count} tickets sur {total} ont été traités.',
  'Confirm deletion of {count} ticket(s)?': 'Confirmez-vous la suppression de {count} ticket(s)?',
  'Confirm new owner {name} for {count} ticket(s)?': 'Confirmez-vous le nouveau responsable {name} pour {count} ticket(s)?',
  'Confirm new state {state} for {count} ticket(s)?': 'Confirmez-vous le nouvel état {state} pour {count} ticket(s)?',
  'Confirm the follow-up for {count} ticket(s)?': 'Confirmez-vous le suivi de {count} ticket(s)?',
  'Can you confirm that you will no longer be following these {count} ticket(s)?': 'Confirmez vous de ne plus suivre ces {count} ticket(s)?',
  'product-domain-help-incident-infrastructure': 'Incident concernant une ressource hébergée (VM/Volume/Housing) ou un service infrastructure tel que le Cloud dédié, le PRA, la Sauvegarde MS365.',
  'product-domain-help-incident-license': 'Incident concernant un système tiers tel que les noms de domaine, Microsoft, Vade, Veeam, Ucopia, Ivanti.',
  'product-domain-help-incident-network': 'Incident concernant un service réseau tel que le transit internet en datacenter, le MPLS et le Lan-To-Lan.',
  'product-domain-help-incident-security': 'Incident concernant un produit/service de sécurité tel que le firewall, l\'EPP, l\'EDR, le SASE, le PAM, le portail captif et les certificats SSL/TLS.',
  'Use this input to enter products you may not have found in the list.': 'Utilisez cette saisie pour renseigner des produits que vous n\'auriez pas trouvé dans la liste.',
  'Display sphere': 'Afficher les sphères',
  'Display': 'Afficher',
  'Hide subs-tasks': 'Cacher les sous-tâches',
  'New sub-task': 'Créer une sous-tâche',
  'Title of the new sub-task...': 'Titre de la nouvelle sous-tâche…',
  'Filter name': 'Nom du filtre',
  'Save current filter': 'Enregistrer le filtre en cours',
  'Save in this filter': 'Enregistrer dans ce filtre',
  'Apply this filter': 'Appliquer ce filtre',
  'Saved filters': 'Filtres enregistrés',
  'Delete filter': 'Effacer le filtre',
  'Delete filter «{name}»?': 'Effacer le filtre « {name} » ?',
  'Spheres replace projects. They work in the same way.': 'Les sphères remplacent les projets. Elles fonctionnent de la même manière.',
  'Copy ticket URL to clipboard': 'Copier l\'URL du ticket dans le presse-papier',
  'New tickets': 'Nouveaux tickets',
  'Report an incident on this product.': 'Déclarer un incident sur ce produit.',
  'Make a free-format request on this product.': 'Faire une demande en rapport avec ce produit.',
  'Report an incident on this subscription.': 'Déclarer un incident sur cette souscription.',
  'Make a free-format request on this subscription.': 'Faire une demande en rapport avec cette souscription.',
  'Create a global incident': 'Créer un incident global',
  'Create a global ticket': 'Créer un ticket global',
  'Impacted companies': 'Sociétés impactées',
  'A global incident will create an incident on the Scalair company, and then an incident on each selected company. The incidents will be linked together so that any publication or modification on the global incident will reflect on the incidents on each dedicated company.': 'Un incident global va créer un incident pour la société Scalair, puis un incident pour chaque société sélectionnée. Les incidents seront reliés entre eux de sorte que toute publication ou modification sur l\'incident global se répercutera sur les incidents pour chaque société sélectionnée.',
  'Remove all': 'Tout supprimer',
  'Please, choose one or more customers from the selection above to add them to this list.': 'Choisissez une ou plusieurs sociétés dans la sélection ci-dessus pour les ajouter à cette liste.',
  'Origin': 'Provenance',
  'Modifications detected': 'Modification détectée',
  'Do you confirm the loss of your modifications?': 'Confirmez-vous la perte de vos modifications ?',
  'Top 70': 'Top 70',
  'Customer companies': 'Sociétés clientes',
  'Companies with CSM': 'Sociétés avec CSM',
  'This role designates a contact person to be contacted in the event of a technical incident or specific technical questions about the company.': 'Ce rôle désigne une personne référente à contacter en cas d\'incident technique ou questions précises d\'ordre technique sur la société.',
  'Hide canceled or closed tickets': 'Cacher les tickets annulés ou fermés.',
  'Be automatically added to my companies\' ticket tracking': 'Être automatiquement ajouté au suivi des tickets de mes entreprises',
  'Customer tickets': 'Tickets clients',
  'Internal tickets': 'Tickets internes',
  'Unassigned tickets': 'Ticket non affectés',
  'Satisfaction survey': 'Questionnaire de satisfaction',
  'Questionnaire «{name}» not found!': 'Questionnaire «{name}» non trouvé !',
  'We\'d like to know how the handling of your recently closed ticket went.': 'Nous aimerions savoir comment la prise en charge de votre ticket récemment clôturé s\'est déroulée.',
  'Was the incident handled correctly by our teams?': 'L\'incident a-t-il été traité correctement par nos équipes ?',
  'Was the request handled correctly by our teams?': 'La demande a-t-elle été traitée correctement par nos équipes ?',
  'If no, please give details': 'Si non, donnez-nous des détails',
  'Are you satisfied with the way the project went?': 'Avez-vous une bonne appréciation du déroulé du projet ?',
  'Did our team meet your requirements?': 'Notre équipe a-t-elle respecté les besoins ?',
  'Does Scalair\'s service meet expectations?': 'La prestation proposée par Scalair est-elle conforme aux attentes ?',
  'Is the quality of Scalair\'s support satisfactory (visibility, exchanges, responsiveness, etc.)': 'La qualité de l’accompagnement Scalair est-elle satisfaisante ? (visibilité, échanges, réactivité, etc.)',
  'Were Scalair\'s commitments respected (response times, appointments, planning, etc.)': 'Les engagements de Scalair ont-ils étaient respectés ? (délais d\'intervention, rdv, planification, etc.)',
  'Comments on project progress': 'Commentaire sur le déroulée du projet (4096 caractères)',
  'A reply was received on {date}.': 'Réponse enregistrée le {date}.',
  'Do not reply': 'Je ne veux pas répondre',
  'Send reply': 'Envoyer ma réponse',
  'Satisfaction questionnaire': 'Questionnaire de satisfaction',
  'Email sent': 'Email envoyé',
  'Answered at': 'Date de réponse',
  'You must add a customer user to the people who will follow up this ticket.': 'Vous devez ajouter obligatoirement un utilisateur du client aux personnes qui suivront ce ticket.',
  'ticket-questionnaire-state-answered': 'Répondu',
  'ticket-questionnaire-state-created': 'Créé',
  'ticket-questionnaire-state-read': 'Lu',
  'ticket-questionnaire-state-refused': 'Refusé',
  'Making contact': 'Prise de contact',
  'Business follow-up': 'Suivi d\'affaires',
  'Lead qualification': 'Lead qualification',
  'Closing': 'Closing',
  'News taking': 'Prise de nouvelles',
  'Create another call': 'Créer un autre appel',
  'Create another event': 'Créer un autre événement',
  'Create a user': 'Créer un utilisateur',
  'Call followup created for {lastname} {firstname}.': 'Le suivi d\'appel a été créé pour {firstname} {lastname}.',
  'Call followup saved.': 'Le suivi d\'appel a été enregistré.',
  'At least one product must be entered.': 'Il faut renseigner au moins un produit.',
  'Do you have a specific request to pass on to our teams concerning the shutdown (precise time of intervention, need or other)?': 'Avez-vous une demande spécifique à transmettre à nos équipes concernant l\'arrêt ? (heure précise d\'intervention, besoin ou autre)',
  'Infra Dashboard': 'Dashboard Infra',
  'Today': 'Aujourd\'hui',
  'Statistics': 'Statistiques',
  'Ticket breakdown': 'Répartition des Tickets',
  'Category breakdown': 'Répartition par Categories',
  'Customer incidents': 'Incidents clients',
  'Customer requests': 'Demandes clients',
  'Customer projects': 'Projects clients',
  'Internal projects': 'Projects internes',
  'The Scalair teams': 'Les équipes Scalair',
  'For your feedback': 'Tickets en attente',
  'For your deposit': 'Commandes avec acompte',
  'For your technical information': 'Informations techniques',
  'Favorite products': 'Produits favoris',
  'Add favorite products to find them here.': 'Ajouter des produits favoris pour les retrouver ici.',
  'Quick order': 'Commande rapide',
  'No action pending on your part.': 'Aucune action en attente de votre part.',
  'Trend': 'Évolution',
  'To do': 'À faire',
  'Incidents': 'Incidents',
  'Requests': 'Demandes',
  'Projects': 'Projets',
  'Projects & Requests': 'Projets & Demandes',
  'Open ticket': 'Ouvrir le ticket',
  'Open ticket "{id}"': 'Ouvrir le ticket « {id}»',
  'Managing company': 'Société gérante',
  'Sphere «{name}» not found!': 'Sphere « {name} » non trouvée !',
  'Scalair Manager (top)': 'Référent Scalair',
  'Downsale analysis': 'Analyse downsale',
  'Company indicators': 'Indicateurs sociétés',
  'Overview': 'Synthèse',
  'Current topics': 'Sujets en cours',
  'Satisfaction': 'Satisfaction',
  'Scope': 'Périmètre',
  'Questionnaire {id}': 'Questionnaire {id}',
  'Link a quotation': 'Lier un devis',
  'Link an opportunity': 'Lier une opportunité',
  'Create a ticket': 'Créer un ticket',
  'Pre-sale Dashboard': 'Dashboard',
  'Requested reply date': 'Date de réponse souhaitée',
  'Add the technical referents of the companies affected to the ticket follow-up': 'Ajouter les référents techniques des sociétés impactées au suivi du ticket',
  'Assign me all sub-tasks': 'M\'affecter toutes les sous-tâches',
  'Sales consultant': 'Référent commercial',
  'Activate SLA for this ticket': 'Activer les SLA pour ce ticket',
  'No topics.': 'Aucun sujet.',
  'Hide prices': 'Cacher les prix',
  'Marketing Dashboard': 'Dashboard Marketing',
  'Campaign type': 'Type de campagne',
  'Leads target': 'Lead cible',
  'Turnover target': 'C.A. cible',
  'Turnover generated': 'C.A. généré',
  'Parent': 'Parent',
  'Create a new cost...': 'Créer un nouveau coût…',
  'Add a cost:': 'Ajouter un coût :',
  'Leads': 'Leads',
  'Lead': 'Lead',
  'ROI': 'R.O.I.',
  'Add opportunity:': 'Ajouter une opportunité :',
  'Add a lead:': 'Ajouter un lead :',
  'Create a lead...': 'Créer un lead…',
  'Amount spent by type of event': 'Montant dépensé par type de campagne',
  'R.O.I. of shares': 'R.O.I. des actions',
  'Sales generated by leads': 'C.A. généré par les leads',
  'Total number of leads': 'Nombre total de leads',
  'Leads converted to opportunities': 'Leads convertis en opport.',
  'Turnover generated by type of event': 'C.A. généré par type de campagne',
  'Campaigns with the most impact': 'Campagnes les plus rentables',
  'Start-up time': 'Charge équipe build',
  'Time to production': 'Durée de réalisation estimée',
  'The quotation cannot be validated because certain information is missing concerning:': 'Le devis ne peut être validé car il manque certaines informations concernant :',
  'Order details': 'Le détail de la commande',
  'Information on the delivery date of the order': 'Des informations sur la date de livraison de la commande',
  'The estimated delivery date is calculated from the day the quotation is signed. The sooner the quotation is signed, the sooner the project can be delivered.': 'La date de livraison estimée est calculée par rapport au jour de signature du devis. Plus vite le devis est signé, plus vite le projet pourra être livré.',
  'Campaign cloned.': 'Campagne dupliquée.',
  'Update targets': 'Modifier les objectifs',
  'The build load reflects the current load of the team that will be in charge of your project, the start of your project should take place after this deadline.': 'La charge du build reflète la charge actuelle de l\'équipe qui sera en charge de votre projet, le démarrage de votre projet devrait avoir lieu après ce délai.',
  'Estimate the duration of your project, the time between the start date and the delivery date.': 'Estimation de la durée de réalisation de votre projet, délai qui s\'écoule entre la date de démarrage et la date de livraison.',
  'Planned budget': 'Budget planifié',
  'Campaign': 'Campagne',
  'Campaigns': 'Campagnes',
  'A global ticket will create a ticket on the Scalair company, and then a ticket on each selected company.': 'Un ticket global va créer un ticket pour la société Scalair, puis un ticket pour chaque société sélectionnée.',
  'Writing a comment will turn this ticket into a request.': 'L\'écriture d\'un commentaire transformera ce ticket en demande.',
  'Score': 'Score',
  'Download leads template': 'Télécharger le modèle des leads',
  'Upload leads': 'Importer des leads',
  'An error occurred when importing the file {name}. Is the file in the expected format?': 'Une erreur est survenu à l\'import du fichier {name}. Le fichier est-il au format attendu ?',
  'The leads have been imported.': 'Les leads ont été importés.',
  'Marketing': 'Marketing',
  'Average response time': 'Temps de réponse moyen',
  'Average time over the period of completed requests between the date of creation and the date of transition to “completed” status.': 'Temps moyen sur la période des demandes réalisées entre la date de création et la date de passage à l\'état "réalisé".',
  'Time spent on requests processed': 'Temps passé sur les demandes',
  'Number of requests by month': 'Nombre de demandes par mois',
  'Forecast of requests by week': 'Prévisionnel des demandes en traitement',
  'Number of tickets': 'Nombre de tickets',
  '* Current week': '* Semaine actuelle',
  'Related events': 'Campagnes rattachées',
  'Create an email campaign': 'Créer une campagne d\'emailing',
  'Send date: ': 'Date d\'envoi : ',
  'Campaign name: ': 'Nom de la campagne: ',
  'Clickers: ': 'Clic : ',
  'Opening: ': 'Ouverture : ',
  'Recipients: ': 'Destinataires : ',
  'Unsubscribing: ': 'Désabonnement : ',
  'Open campaign in Brevo': 'Ouvrir la campagne sur Brevo',
  'Email campaign': 'Campagne d\'emailing',
  'Choose a team to see its tickets...': 'Choisissez une équipe pour en afficher ses tickets…',
  'Please select one or more options above and press button refresh to display the follow-ups.': 'Veuillez sélectionner une ou plusieurs options ci-dessus et appuyez sur le bouton « Actualiser » pour afficher les échanges.',
  'Please select a customer and press button refresh to display the follow-ups.': 'Veuillez sélectionner un client ci-dessus et appuyez sur le bouton « Actualiser » pour afficher les échanges.',
  '{count} leads': 'Aucun lead|Un lead|{count} leads',
  'Sent: ': 'Envoyé : ',
  'Delivered: ': 'Livré : ',
  'Please, choose a temblate for the email campaign :': 'Choisissez un modéle pour la campagne d\'emailing :',
  'No template available!': 'Aucun modèle disponible !',
  'No campaign': 'Lead sans campagne',
  'Related campaigns': 'Campagnes associées',
  'Cancel this ticket': 'Annuler ce ticket',
  'Do you confirm that this ticket is cancelled?': 'Confirmez-vous que ce ticket est annulé ?',
  'Delete cost': 'Supprimer le coût',
  'Do you confirm the deletion of cost "{name}"?': 'Confirmez-vous la suppression du coût "{name}" ?',
  'Turning into a suspect...': 'Transformer en suspect…',
  'Do you confirm the creation of the company from {name}?': 'Confirmez-vous la création de la société à partir de {name} ?',
  'Company {name} created.': 'La société {name} a été créée.',
  'company-field-name': 'Nom',
  'company-field-parent': 'Société gérante',
  'company-field-name_billing': 'Nom pour les factures',
  'company-field-business_sector': 'Secteur d\'activité',
  'company-field-business_description': 'Activité',
  'company-field-b_street': 'Adresse de facturation',
  'company-field-b_street2': 'Complément',
  'company-field-b_city': 'Ville',
  'company-field-b_zipcode': 'Code postal',
  'company-field-b_country': 'Pays',
  'company-field-h_street': 'Addresse',
  'company-field-h_city': 'Ville',
  'company-field-h_zipcode': 'Code postal',
  'company-field-h_country': 'Pays',
  'company-field-siren': 'SIREN',
  'company-field-nic': 'NIC',
  'company-field-vat': 'TVA',
  'company-field-health_contact_point': 'Point de contact santé',
  'company-field-payment_method': 'Moyen de paiement',
  'company-field-iban': 'IBAN',
  'company-field-bic': 'BIC',
  'company-field-id_lord_salesperson': 'Responsable commercial',
  'company-field-mail_accounting': 'E-Mail de facturation',
  'company-field-type': 'Type',
  'company-field-business_creation_date': 'Activité créée le',
  'company-field-employee_number': 'Employés',
  'Subtype': 'Sous-type',
  'This lead has already been associated with a company.': 'Ce lead a déjà été associé à une société.',
  'Required for non suspect company.': 'Obligatoire pour les sociétés non suspect.',
  'No company found for SIREN "{siren}"!': 'Aucune société ne correspond au numéron de SIREN "{siren}" !',
  'A customer company needs at least one user.': 'Une société a besoin d\'au moins un utilisateur.',
  'Required for client company.': 'Obligatoire pour une société cliente.',
  'Expiration date': 'Date d\'expiration',
  'Company «{name}» not found!': 'Société « {name} » non trouvée !',
  'Tickets automation': 'Lanceur de tickets',
  'Cancel last ticket': 'Annuler le dernier ticket',
  'Modify scheduling...': 'Modifier la planification…',
  'Cancel the previous ticket when creating the new one.': 'Annuler le ticket précédent lors de la création du nouveau.',
  'Last contact': 'Dernier contact',
  'Last call': 'Dernier appel',
  'Last event': 'Dernière réunion ',
  'Last email': 'Dernier email'
}
