<template>
  <div>
    <sca-advanced-store-grid :options="gridOptions" resource="tickets/questionnaires" :columns="gridColumns" :filters="filters" :custom-search="customSearch" ref="ticket-questionnaire-grid" @line-click="openTicketSurvey">
      <div slot="search-row" class="d-inline-flex align-center">
        <sca-month-filters :loading="loading" hide-refresh-button @change="refreshDateRange" />

        <sca-customer-select v-model="companiesSearch" :label="$t('Company')" @input="refreshDebounced" clearable show-email show-phone link="emit" @link-click="openCompany(companiesSearch)" />

        <v-select v-model="stateSearch" :label="$t('State')" :items="states" class="ml-1" clearable @input="refreshDebounced" />

        <sca-ticket-type-select v-model="ticketTypeSearch" :label="$t('Type')" class="ml-1" clearable @input="refreshDebounced" />
      </div>

      <template v-slot:item-id_ticket="{ item }">
        <sca-ticket-identity :value="item" dense show-card link="emit" @link-click="openTicket" />
      </template>

      <template v-slot:item-type_ticket="{ item }">
        <sca-ticket-type :value="item" dense />
      </template>

      <template v-slot:item-code="{ itemRaw }">
        <sca-company-identity v-if="itemRaw" :value="itemRaw" show-avatar show-email show-phone show-sales-person text-resume="20" link="emit" @link-click="openCompany(itemRaw)" />
        <span v-else />
      </template>

      <template v-slot:item-id_owner="{ itemRaw }">
        <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
        <span v-else />
      </template>

      <template v-slot:item-create_by="{ itemRaw }">
        <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
        <span v-else />
      </template>

      <template v-slot:item-update_by="{ itemRaw }">
        <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
        <span v-else />
      </template>

      <template v-slot:actions-append="{ row }">
        <template v-if="row.state === 'answered'">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon @click="openTicketSurvey(row)">
                <v-icon small>
                  $vuetify.icons.read
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Show') }}</span>
          </v-tooltip>
        </template>
      </template>
    </sca-advanced-store-grid>

    <csm-company-dialog ref="company-dialog" />
    <csm-ticket-questionnaire-dialog ref="ticket-questionnaire-dialog" />
    <csm-ticket-dialog ref="ticket-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

const LOADING_MIN_DURATION = 500

export default {
  name: 'QuestionnaireList',
  components: {
    'csm-ticket-questionnaire-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketQuestionnaireDialog')
  },
  data () {
    return {
      companiesSearch: '',
      dateBeginMem: null,
      dateEndMem: null,
      filters: ['id', 'create_by', 'update_by'],
      loading: false,
      refreshDebounced: this.refresh,
      states: [],
      stateSearch: 'answered',
      ticketTypeSearch: null
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    gridColumns () {
      if (Array.isArray(this.columns)) return this.columns

      const cols = [{
        text: 'Ticket',
        value: 'id_ticket'
      }, {
        text: 'Owner',
        value: 'id_owner'
      }, {
        text: 'Company',
        value: 'code'
      }, {
        text: 'State',
        value: 'state',
        format: v => this.$t(`ticket-questionnaire-state-${v}`)
      }, {
        text: 'Type',
        value: 'type_ticket'
      }, {
        text: 'Answered at',
        value: 'answered_at',
        format: v => v ? this.$stratus.services.fieldRenderers.DATETIME_SHORT(v) : ''
      }, {
        text: 'Email sent',
        value: 'is_mail_sent',
        alignValue: 'center',
        format: this.$stratus.services.fieldRenderers.BOOLEAN_CHECK
      }, {
        text: 'Created at',
        value: 'create_at',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }, {
        text: 'Created by',
        value: 'create_by'
      }, {
        text: 'Updated at',
        value: 'update_at',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }, {
        text: 'Updated by',
        value: 'update_by'
      }]

      if (!this.columns) return cols // Return default

      return _.map(this.columns, (hidden, value) => {
        return { ..._.find(cols, { value }), hidden }
      })
    },
    gridOptions () {
      return {
        advancedSearchFieldsSchemaBaseUrl: '/docs/tickets',
        advancedSearchFieldsSchemaName: 'SatisfactionQuestionnaire',
        allowContextMenuOnCell: true,
        allowActions: false,
        allowActionsAppend: true,
        allowActionsPrepend: false,
        allowColumnsVisible: true,
        create: false,
        debounce: {
          onList: 500 // Request rate is one every 500ms
        },
        delete: false,
        foreignFields: ['id'],
        key: 'id',
        multiSelect: false,
        notifyErrors: true,
        search: this.isLogged,
        show: true,
        sortBy: 'create_at',
        sortDescending: false,
        update: false
      }
    }
  },
  methods: {
    customSearch () {
      const cusSearch = []

      // Use filter inputs
      if (this.companiesSearch?.length) {
        cusSearch.push({
          column: 'code',
          search: Array.isArray(this.companiesSearch) ? this.companiesSearch.join(',') : this.companiesSearch,
          operator: Array.isArray(this.companiesSearch) ? 'in' : 'eq'
        })
      }

      if (this.stateSearch?.length) {
        cusSearch.push({
          column: 'state',
          search: Array.isArray(this.stateSearch) ? this.stateSearch.join(',') : this.stateSearch,
          operator: Array.isArray(this.stateSearch) ? 'in' : 'eq'
        })
      }

      if (this.ticketTypeSearch?.length) {
        cusSearch.push({
          column: 'type_ticket',
          search: Array.isArray(this.ticketTypeSearch) ? this.ticketTypeSearch.join(',') : this.ticketTypeSearch,
          operator: Array.isArray(this.ticketTypeSearch) ? 'in' : 'eq'
        })
      }

      if (this.dateBeginMem || this.dateEndMem) {
        cusSearch.raw = (this.dateBeginMem ? `create_at[gte]=${this.$stratus.dt(this.dateBeginMem).format('YYYY-MM-DD')}` : '') +
        '!!' + (this.dateEndMem ? `create_at[lte]=${this.$stratus.dt(this.dateEndMem).format('YYYY-MM-DD')}` : '')
      }

      // dateBegin: this.$stratus.dt(dateBegin).startOf('month'),
      // dateEnd: this.$stratus.dt(dateEnd).endOf('month')

      return cusSearch
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticket.id)
    },
    openTicketSurvey (survey) {
      if (!survey?.id) return
      if (this.$refs['ticket-questionnaire-dialog']) this.$refs['ticket-questionnaire-dialog'].openReadonly(survey.id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    refresh () {
      try {
        this.loading = true
        if (this.$refs['ticket-questionnaire-grid']) this.$refs['ticket-questionnaire-grid'].fetchData()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.loading = false }, 250)
      }
    },
    refreshDateRange ({ dateBegin, dateEnd } = {}) {
      if (!dateBegin) dateBegin = this.dateBeginMem
      if (!dateEnd) dateEnd = this.dateEndMem

      if (!this.$stratus.dt(dateBegin).isValid() || !this.$stratus.dt(dateEnd).isValid()) return

      this.dateBeginMem = dateBegin
      this.dateEndMem = dateEnd
      this.refresh()
    }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Satisfaction questionnaire'))
    this.refreshDebounced = _.debounce(this.refresh, LOADING_MIN_DURATION)
    this.states = [
      { value: 'created', text: this.$t('ticket-questionnaire-state-created') },
      { value: 'read', text: this.$t('ticket-questionnaire-state-read') },
      { value: 'refused', text: this.$t('ticket-questionnaire-state-refused') },
      { value: 'answered', text: this.$t('ticket-questionnaire-state-answered') }
    ]
  }
}
</script>
