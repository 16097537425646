<template>
  <div>
    <v-tabs v-model="tab" class="transparent" hide-slider>
      <v-tab href="#tab-today">
        {{ $t('Today') }}
      </v-tab>
      <!-- FUTURE <v-tab href="#tab-stats" :header="$t('Statistics')" icon="icon-file" /> -->
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-today">
        <v-row align="center" dense>
          <v-col cols="6">
            <sca-users-select ref="user-select" v-model="userId" :label="$t('User')" :filter-team="TEAM_INFRA" allow-all multiple clearable explode-value @selectionChange="refresh" @input="refresh" />
          </v-col>

          <v-col class="shrink text-no-wrap text-right">
            <cs-refresh-button :loading="loading" @click="load()" />

            <div v-show="lastRefreshDate" class="text-caption">
              ({{ $t('Last refresh: {date}', { date: $stratus.dt(this.lastRefreshDate).format('LT') }) }})
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col cols="12" md="6">
            <v-row dense>
              <v-col cols="12" md="6" class="text-center">
                <div class="pa-1 full-height">
                  <div class="text-h6 text-center">
                    {{ $t('Ticket breakdown') }}
                  </div>

                  <cs-chart-donut v-show="ticketByUserCount" ref="chart-ticket-by-user" class="chart-max-height" :styles="chartStyles" :options="chartOptions" :chart-data="ticketByUserChartData" />
                  <span v-show="!ticketByUserCount">
                    {{ $t('No data...') }}
                  </span>
                </div>
              </v-col>

              <v-col md="6" class="text-center">
                <div class="pa-1 full-height">
                  <div class="text-h6 text-center">
                    {{ $t('Category breakdown') }}
                  </div>

                  <cs-chart-donut v-show="ticketByCategoryCount" ref="chart-ticket-by-category" class="chart-max-height" :styles="chartStyles" :options="chartOptions" :chart-data="ticketByCategoryChartData" />
                  <span v-show="!ticketByCategoryCount">
                    {{ $t('No data...') }}
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-expand-transition>
              <v-row v-if="showTicketPanel && ticketsPanel.length" dense>
                <v-col cols="12">
                  <div class="pa-1 full-height">
                    <div class="d-flex align-center text-h6 mb-2">
                      {{ ticketByUserGridLabel }}
                      <v-spacer />
                      <v-btn icon @click="showTicketPanel = false">
                        <v-icon>
                          $vuetify.icons.close
                        </v-icon>
                      </v-btn>
                    </div>

                    <sca-ticket-list-panel :value="ticketsPanel" dense can-open fixed-height link="emit" @link-click="openTicket" show-severity />
                  </div>
                </v-col>
              </v-row>
            </v-expand-transition>

            <v-row dense>
              <v-col cols="12">
                <csm-ticket-grid v-if="usersSelected?.length" ref="customer-incident-ticket-grid" :title="$t('Incidents')" :columns="gridColumns" :query="queryIncidents" :options="gripOptions" :save-preferences="false" hide-toggles hide-filter hide-global-incident />
              </v-col>

              <v-col cols="12" />
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <div class="pa-1">
              <csm-ticket-grid v-if="usersSelected?.length" ref="internal-request-ticket-grid" :title="$t('Projects & Requests')" :columns="gridColumns" :query="queryProjectsPequests" :options="gripOptions" :save-preferences="false" hide-toggles hide-filter hide-global-incident />
              <div v-else class="full-height d-flex flex-column">
                <div class="mt-auto mb-auto ml-auto mr-auto">
                  <cs-alert-panel type="info" dense class="ml-auto mr-auto mt-auto mb-auto" :text="$t('Choose a user...')" />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item value="tab-stats">
        <!-- FUTURE -->
      </v-tab-item>
    </v-tabs-items>

    <csm-ticket-dialog @closeDialog="closeDialog" ref="ticket-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'

import { mapGetters } from 'vuex'

import config from '@/config'

const DEFAULT_REFRESH_DELAY = 3 // Minutes

const DEFAULT_CHART_DATA = {
  labels: [],
  datasets: [{
    backgroundColor: [],
    data: []
  }]
}

export default {
  name: 'InfraDashboard',
  components: {
    'csm-ticket-grid': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketGrid')
  },
  data () {
    return {
      CORPORATION_CODE: config.defaults.corporationCode,
      PANEL_TICKET_BY_OWNER: 1,
      TEAM_INFRA: this.$alto.defines.TEAMS.TEAM_INFRA,
      TICKET_BY_CATEGORY_PALETTE: this.$alto.defines.TICKETS.COLORS_TICKET_TYPE,
      TICKET_BY_USER_PALETTE: this.$alto.defines.COLORS.PALETTE_SCALAIR_10,
      TICKET_TYPE_INCIDENT: this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT,
      TICKET_TYPE_PROJECT: this.$alto.defines.TICKETS.TICKET_TYPE_PROJECT,
      TICKET_TYPE_REQUEST: this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST,
      TICKETS_STATES_TEAM_INFRA: [
        this.$alto.defines.TICKETS.TICKET_STATE_DOING,
        this.$alto.defines.TICKETS.TICKET_STATE_REVIEW,
        this.$alto.defines.TICKETS.TICKET_STATE_TODO,
        this.$alto.defines.TICKETS.TICKET_STATE_WAITING
      ],
      lastRefreshDate: new Date(),
      loading: true,
      gripOptions: {
        allowAdvancedSearch: false,
        allowColumnsOrdering: false,
        allowColumnsVisible: false,
        create: false,
        csvExport: false,
        search: false,
        sortBy: ['severity', 'update_at'],
        sortDescending: [false, true],
        footerProps: { 'items-per-page-options': [7] },
        itemsPerPage: 7
      },
      refreshHandler: null,
      showTicketPanel: null,
      tab: 'tab-today',
      ticketByCategoryChartData: { ...DEFAULT_CHART_DATA },
      ticketByCategoryCount: 0,
      ticketByUser: [],
      ticketByUserChartData: { ...DEFAULT_CHART_DATA },
      ticketByUserCount: 0,
      ticketByUserGridLabel: '',
      ticketByUserQuery: null,
      ticketsPanel: [],
      userId: [],
      usersSelected: []
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord',
      preferences: '$alto-preferences/preferences'
    }),
    chartOptions () {
      return {
        // Click on a part to load it's data
        onClick (e) {
          /* --- IMPORTANT! ---
           * This is a native click event, outside Vue scope!
           * Value of «this» is not vue but the chart instance this event is plugged to.
           */
          const element = this.getElementAtEvent(e)
          if (element.length > 0) {
            // Forge a query to load data for this part
            this.data.datasets[element[0]._datasetIndex].computeQuery(
              this.data.datasets[element[0]._datasetIndex].query[element[0]._index],
              this.data.caption + ' > ' + this.data.labels[element[0]._index],
              this.data.datasets[element[0]._datasetIndex].ticketIds[element[0]._index]
            )
            this.update()
          }
        },
        responsive: true,
        animation: {
          animateRotate: true,
          animateScale: true
        },
        maintainAspectRatio: false,
        // FIXME: if exception « Cannot read property 'transition' of null » still occurs,
        // uncomment the following:
        // animation: {
        //     duration: 0
        // },
        // hover: {
        //     animationDuration: 0
        // },
        responsiveAnimationDuration: 0,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
          }
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltips: {
          enabled: true,
          intersect: false,
          callbacks: {
            label: (tooltipItems, data) => {
              return data.labels[tooltipItems.index]
            }
          }
        },
        circumference: 2 * Math.PI,
        rotation: -Math.PI / 2
      }
    },
    chartStyles () {
      return {
        height: '200px',
        width: '100%',
        position: 'relative'
      }
    },
    gridColumns () {
      return [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Company',
        value: 'code'
      }, {
        text: 'Updated at',
        value: 'update_at',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }, {
        text: 'Last comment on',
        value: 'last_comment_at',
        hidden: true,
        format: v => v ? this.$stratus.services.fieldRenderers.DATETIME_SHORT(v) : ''
      }, {
        text: 'State',
        value: 'state'
      }, {
        text: 'Severity',
        value: 'severity'
      }, {
        text: 'Owner',
        value: 'id_owner'
      }]
    },
    queryIncidents () {
      return `id_lord_team[in]=${this.TEAM_INFRA}!!(id_owner=""||id_owner[in]=${this.usersSelected.join(',')})!!state[nin]=canceled,closed,done!!type[eq]=${this.TICKET_TYPE_INCIDENT}`
    },
    queryProjectsPequests () {
      return `id_lord_team[in]=${this.TEAM_INFRA}!!(id_owner=""||id_owner[in]=${this.usersSelected.join(',')})!!state[nin]=canceled,closed,done!!type[in]=${this.TICKET_TYPE_PROJECT},${this.TICKET_TYPE_REQUEST}`
    }
  },
  methods: {
    addSector (chart, name, value, color, query, ticketIds) {
      const currentDataset = chart.datasets[0]
      chart.caption = this.$t('Distribution of tickets')
      chart.labels.push(name)
      currentDataset.data.push(value)
      currentDataset.query.push(query)
      currentDataset.ticketIds.push(ticketIds)
      currentDataset.backgroundColor.push(color.opacity80)
      currentDataset._backgroundColor.push(color.opacity80)
      currentDataset.borderColor = this.$alto.defines.COLORS.WARNING
      currentDataset.borderWidth = 0
      currentDataset.computeQuery = this.computeQuery
    },
    closeDialog () {
      this.refresh()
    },
    computeQuery (query, label, ticketIds) {
      this.ticketByUserQuery = query || []
      this.ticketByUserGridLabel = label || ''
      this.ticketsPanel = ticketIds || []
      this.showTicketPanel = this.ticketsPanel.length > 0
    },
    createGraphCategoryBreakdown () {
      this.resetChart(this.ticketByCategoryChartData)

      const ticketByCategory = _.groupBy(this.ticketsOfDashboard, 'type')
      if (ticketByCategory) {
        this.ticketByCategoryCount = this.ticketsOfDashboard.length
        _.forEach(ticketByCategory, async (list, ticketType) => {
          const color = ticketType ? this.TICKET_BY_CATEGORY_PALETTE[ticketType] : { html: '#ccc' }
          this.addSector(this.ticketByCategoryChartData, this.$t(`ticket-type-${ticketType}`) + ' : ' + list.length, list.length, color)
        })
      } else this.ticketByCategoryCount = 0

      this.$nextTick(() => {
        if (this.$refs['chart-ticket-by-category']) this.$refs['chart-ticket-by-category'].update()
      })
    },
    createGraphTicketBreakdown () {
      this.resetChart(this.ticketByUserChartData)

      const ticketByUser = _.groupBy(this.ticketsOfDashboard, 'id_owner')
      if (ticketByUser) {
        this.ticketByUserCount = this.ticketsOfDashboard.length
        let i = 0
        _.forEach(ticketByUser, async (list, ticketOwner) => {
          let userName
          let query = ''

          if (ticketOwner) {
            const user = { ...await this.$store.dispatch('$alto-users/getById', ticketOwner) }
            if (user) {
              userName = `${user.firstname} ${user.lastname}`
              query = `id_owner[eq]=${ticketOwner}!!`
            }
          } else userName = this.$t('Unallocated')

          query += `state[in]=${this.TICKETS_STATES_TEAM_INFRA}`

          this.addSector(this.ticketByUserChartData, `${userName} : ${list.length}`, list.length, this.TICKET_BY_USER_PALETTE[i % this.TICKET_BY_USER_PALETTE.length], query, _.map(list, 'id'))
          i++
        })
      } else this.ticketByUserCount = 0

      this.$nextTick(() => {
        if (this.$refs['chart-ticket-by-user']) this.$refs['chart-ticket-by-user'].update()
      })
    },
    async load () {
      try {
        clearTimeout(this.refreshHandler)

        this.loading = true

        const { explodedValue } = this.$refs['user-select'].explode(this.userId)
        this.usersSelected = explodedValue

        // Get all tickets for desk team
        const ticketsDesk = await this.$store.dispatch('$alto-ticketing/getTickets', { teamIds: [this.TEAM_INFRA], states: this.TICKETS_STATES_TEAM_INFRA })
        if (ticketsDesk?.results) {
          this.ticketsOfDashboard = [...ticketsDesk.results]
          this.ticketNotAssigned = _.filter(ticketsDesk.results, ticket => {
            return ticket.id_owner === '' && ticket.id_lord_team === this.TEAM_INFRA && ![this.TICKET_STATE_CANCELED, this.TICKET_STATE_CLOSED, this.TICKET_STATE_DONE].includes(ticket.state)
          })
        } else {
          this.ticketNotAssigned = []
          this.ticketsOfDashboard = []
        }

        if (this.refreshHandler) {
          if (this.$refs['internal-request-ticket-grid']) this.$refs['internal-request-ticket-grid'].refresh()
          if (this.$refs['internal-project-ticket-grid']) this.$refs['internal-project-ticket-grid'].refresh()
          if (this.$refs['customer-incident-ticket-grid']) this.$refs['customer-incident-ticket-grid'].refresh()
          if (this.$refs['customer-request-ticket-grid']) this.$refs['customer-request-ticket-grid'].refresh()
          if (this.$refs['customer-project-ticket-grid']) this.$refs['customer-project-ticket-grid'].refresh()
          this.lastRefreshDate = new Date()
        }

        // Propagate data modifications
        // if (this.showTicketPanel) {
        //   this.showTickets()
        // }

        // It's time to render the graph
        this.createGraphTicketBreakdown()
        this.createGraphCategoryBreakdown()
      } catch (error) {
        console.error('[infra] dashboard error:', error)
      } finally {
        this.refreshHandler = setTimeout(() => { this.refresh() }, DEFAULT_REFRESH_DELAY * 60 * 1000)
        setTimeout(() => {
          this.loading = false
          if (this.$refs['chart-ticket-by-category']) this.$refs['chart-ticket-by-category'].update()
          if (this.$refs['chart-ticket-by-user']) this.$refs['chart-ticket-by-user'].update()
        }, 1000)
      }
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticket.id)
    },
    refresh () {
      if (!this.$refs['user-select']) return
      this.load()
    },
    resetChart (chart) {
      this.$set(chart, 'labels', [])
      this.$set(chart, 'datasets', [{
        backgroundColor: [],
        _backgroundColor: [],
        data: [],
        nbOpportunities: [],
        query: [],
        ticketIds: []
      }])
    }
  },
  beforeDestroy () {
    clearTimeout(this.refreshHandler)
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Infra Dashboard'))
    this.refresh = _.debounce(this.refresh, 1000)
    this.resetChart(this.ticketByUserChartData)
    this.resetChart(this.ticketByCategoryChartData)
  },
  mounted () {
    // this. = _.debounce(this.load, LOADING_MIN_DURATION)
    this.$nextTick(() => {
      if (this.me.lord_team_id === this.TEAM_INFRA) this.userId = [this.me?.id]
      else this.userId = ['ALL']
      setTimeout(() => {
        this.refresh()
      }, 1000)
    })
  }

}
</script>
