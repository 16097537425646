<template>
  <csm-marketing-lead-form ref="marketing-lead-form" :loading="loading" @closeDialog="closeDialog" />
</template>

<script>
let previousLead = null

export default {
  name: 'MarketingLeadDialog',
  components: {
    'csm-marketing-lead-form': () => import(/* webpackChunkName: "components" */ './MarketingLeadForm')
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_LEADS, this.$alto.API_PERMISSIONS.READ)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_LEADS, this.$alto.API_PERMISSIONS.UPDATE)
    }
  },
  methods: {
    closeDialog (data) {
      if (this.$refs['marketing-lead-form']) this.$refs['marketing-lead-form'].display(false)
      previousLead = null
      this.$emit('closeDialog', data)
    },
    async get (id) {
      try {
        this.loading = true
        const lead = { ...await this.$store.dispatch('$alto-marketing/getLead', id) }
        this.reset(lead)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.loading = false
      }
    },
    open (id) {
      this.reset()
      if (id) this.get(id)
      this.dialogVisible = true
      if (this.$refs['marketing-lead-form']) this.$refs['marketing-lead-form'].display(true)
    },
    reset (lead = {}) {
      this.loading = false
      this.$refs['marketing-lead-form'].reset(lead)
    }
  },
  async mounted () {
    if (this.$route?.name === 'marketing-lead' && this.$route.params.id) {
      if (this.$route.params.id !== previousLead) {
        previousLead = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        const me = this.$store.getters['$stratus-states/me']
        if (this.$alto.services.routes.connectionForbidden.call(this, me)) return
        this.open(this.$route.params.id)
      }
    }
  }
}
</script>
