<template>
  <v-row align="center" justify="center" class="full-height">
    <v-col cols="12" md="6" v-if="!isLogged">
      <v-card outlined tile class="background-blurred mt-4 pt-8 mt-8">
        <v-row dense align="start" class="px-8 mt-8">
          <v-col cols="4">
            <v-img :height="200" position="top center" class="mt-4" contain :src="`/img/scalair/scalair-full-light.svg?1`" />
          </v-col>

          <v-col cols="8">
            <p class="text-h6">
              {{ $t('Welcome to Scalair Manager') }}
            </p>
            <sca-connection-form :sentry="Sentry" @login="onLogin" identifier-text="Email" identifier-is-email allow-forgot-password :captcha-key="captchaKey" />
          </v-col>
        </v-row>

        <v-row dense align="center" class="mt-8">
          <v-col cols="12" class="pl-8">
            <v-row class="menu-icon--text">
              <v-col>
                © 2019-{{ currentYear }} Scalair
              </v-col>
              <v-col class="text-right">
                <cs-language-input look="menu" @localeChange="changeLocale" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      Sentry,
      dark: false,
      isLogged: this.$stratus.services.auth.isLogged()
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me'
    }),
    captchaKey () {
      return this.$store.getters.appConfig.captchaKey
    },
    currentYear () { return this.$stratus.dt().format('YYYY') }
  },
  methods: {
    changeLocale (newLocale) {
      this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Home'))
    },
    onLogin (error) {
      if (!error) {
        const fromUrl = localStorage.getItem('from-url')
        if (fromUrl) {
          localStorage.removeItem('from-url')
          window.location.href = fromUrl
          window.location.reload()
        } else {
          this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
          window.location.reload()
        }
      }
    }
  },
  created () {
    this.dark = this.$store.getters['$stratus-states/isDark']
    this.changeLocale()
  },
  mounted () {
    if (this.$stratus.services.auth.isLogged()) {
      if (this.me?.role === this.$alto.defines.USERS.USER_ROLE_PURE_TECH) {
        this.$router.push({ name: 'subscriptions' }).catch((error) => { console.warn(error) })
      } else {
        this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
      }
    }
  }
}
</script>
