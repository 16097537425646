import _ from 'lodash'

const actions = {
  create ({ commit, rootState }, data) {
    return rootState.$stratus.services.api.post('/opportunities', data)
      .then((response) => { return response })
      .catch(error => commit('API_FAILURE', error))
  },
  deleteMultiple ({ commit, rootState }, ids = []) {
    return rootState.$stratus.services.api.delete('/opportunities', { opportunities: ids })
      .then((response) => { return response })
      .catch(error => commit('API_FAILURE', error))
  },
  get ({ commit, rootState }, id) {
    return rootState.$stratus.services.api.get('/opportunities/' + id)
      .then((response) => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  getHistory ({ commit, rootState }, id) {
    return rootState.$stratus.services.api.get('/opportunities/' + id + '?history=true')
      .then((response) => {
        return response.history || []
      })
      .catch(error => commit('API_FAILURE', error))
  },
  getCompaniesHistory ({ commit, rootState }, { ids, dateBegin, dateEnd, countable, proposal, proposalSum, filter }) {
    let url = `/opportunities/companiesanalysis?companies=["${ids.join('","')}"]`

    if (dateBegin) url += '&begin=' + rootState.$stratus.dt(dateBegin).format('YYYY-MM-DD')
    else url += '&begin=' + rootState.$stratus.dt().subtract(6, 'month').startOf('month').format('YYYY-MM-DD')
    if (dateEnd) url += '&end=' + rootState.$stratus.dt(dateEnd).format('YYYY-MM-DD')
    else url += '&end=' + rootState.$stratus.dt().format('YYYY-MM-DD')
    if (proposal) url += '&proposal=true'
    if (countable) url += '&countable=true'

    return rootState.$stratus.services.api.get(url)
      .then((response) => {
        if (proposalSum) {
          // Proposal field is cumulated. Add non proposal value to the value of proposal fields.
          _.forEach(response, (data, name) => {
            if (response[name + '_proposal'] !== undefined) {
              _.forEach(data.values, value => {
                const v = _.find(response[name + '_proposal'].values, { time: value.time })
                if (v) { v.value += value.value }
              })
            }
          })
        }
        return response || {}
      })
      .catch(error => commit('API_FAILURE', error))
  },
  getCompaniesHistoryPrices ({ commit, rootState }, { ids, dateBegin, dateEnd }) {
    let url = `/opportunities/companiesanalysis?fields=price&companies=["${ids.join('","')}"]`

    if (dateBegin) url += '&begin=' + rootState.$stratus.dt(dateBegin).format('YYYY-MM-DD')
    else url += '&begin=' + rootState.$stratus.dt().subtract(6, 'month').startOf('month').format('YYYY-MM-DD')
    if (dateEnd) url += '&end=' + rootState.$stratus.dt(dateEnd).format('YYYY-MM-DD')
    else url += '&end=' + rootState.$stratus.dt().format('YYYY-MM-DD')

    return rootState.$stratus.services.api.get(url)
      .then((response) => {
        return response || {}
      })
      .catch(error => commit('API_FAILURE', error))
  },
  getSubscribedProductsHistory ({ commit, rootState }, { ids, dateBegin, dateEnd, countable }) {
    let url = `/opportunities/onelinesanalysis?onelines=[${ids.join(',')}]`

    if (dateBegin) url += '&begin=' + rootState.$stratus.dt(dateBegin).format('YYYY-MM-DD')
    else url += '&begin=' + rootState.$stratus.dt().subtract(6, 'month').startOf('month').format('YYYY-MM-DD')
    if (dateEnd) url += '&end=' + rootState.$stratus.dt(dateEnd).format('YYYY-MM-DD')
    else url += '&end=' + rootState.$stratus.dt().format('YYYY-MM-DD')
    if (countable) url += '&countable=true'

    return rootState.$stratus.services.api.get(url)
      .then((response) => {
        return response || {}
      })
      .catch(error => commit('API_FAILURE', error))
  },
  listForecast ({ commit, rootState }, { dateBegin, dateEnd, successPercent }) {
    const url = `/opportunities/dashboard?begin=${rootState.$stratus.dt(dateBegin).format('YYYY-MM-DD')}&end=${rootState.$stratus.dt(dateEnd).format('YYYY-MM-DD')}&probability=${successPercent}`
    return rootState.$stratus.services.api.get(url)
      .then((response) => {
        return response
      })
      .catch((error) => commit('API_FAILURE', error))
  },
  query ({ commit, rootState }, { fields = [], query, page = 1, limit = 0 }) {
    const url = `/opportunities?${query}&limit=${limit}&page=${page}&fields=${fields.join()}`
    return rootState.$stratus.services.api.get(url)
      .then((response) => {
        return response ? response.results : []
      })
      .catch(error => commit('API_FAILURE', error))
  },
  setClosingDate ({ commit, rootState }, { ids, closingDate } = []) {
    return rootState.$stratus.services.api.patch('/opportunities', { opportunities: ids, closing_date: rootState.$stratus.dt(closingDate).format('YYYY-MM-DD') })
      .then((response) => { return response })
      .catch(error => commit('API_FAILURE', error))
  },
  setOwners ({ commit, rootState }, { ids, owner }) {
    return rootState.$stratus.services.api.patch('/opportunities', {
      opportunities: ids,
      owner
    })
      .then((response) => { return response.body })
      .catch(error => commit('API_FAILURE', error))
  },
  update ({ commit, rootState }, data) {
    return rootState.$stratus.services.api.patch('/opportunities/' + data.id, data)
      .then((response) => { return response.body })
      .catch(error => commit('API_FAILURE', error))
  },
  updateMultiple ({ commit, rootState }, { ids, fields }) {
    return rootState.$stratus.services.api.patch('/opportunities', { opportunities: ids, ...fields })
      .then((response) => { return response.body })
      .catch(error => commit('API_FAILURE', error))
  }
}

const mutations = {
  API_FAILURE (state, error) {
    if (error.status === 401) {
      console.warn('[API] status 401', error)
    } else throw error
  }
}

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions,
  mutations
}
