<template>
  <cs-expand-panel block>
    <v-row slot="header" align="center" dense>
      <v-col class="font-weight-bold">
        {{ $t('Number of business meals') }}
        <cs-icon-loading v-if="loading" small right />
      </v-col>

      <v-col v-if="user?.lord_team_info?.business_lunch_by_month" class="shrink text-no-wrap">
        <span class="text-caption">
          {{ $t('Target: ') }}
        </span>
        <span class="font-weight-bold">
          {{ $t('{count}{unit}', { count: user.lord_team_info.business_lunch_by_month, unit: $t('per month') } ) }}
        </span>
      </v-col>

      <v-col v-else class="shrink text-no-wrap">
        <span class="error--text">
          <v-icon class="error--text">
            $vuetify.icons.error
          </v-icon>
          {{ $t('No target is defined.') }}
        </span>
      </v-col>

      <v-col v-show="dataExists && average?.user?.lord_team_info?.business_lunch_by_month" cols="12">
        <div class="d-flex">
          <v-row dense align="center">
            <v-col class="shrink text-no-wrap ml-4">
              {{ $t('On target') }}
            </v-col>

            <v-col class="shrink text-no-wrap">
              <v-chip label :dark="dark" :color="average?.monthsTargetIn === average?.months ? 'success' : colorTargetIn" class="color0--text">
                {{ $tc('{count} months', average?.monthsTargetIn, { count: average?.monthsTargetIn }) }}
              </v-chip>
            </v-col>

            <template v-if="average?.monthsTargetOut > 0">
              <v-col class="shrink text-no-wrap ml-4">
                {{ $t('Under target') }}
              </v-col>

              <v-col class="shrink text-no-wrap">
                <v-chip label :dark="dark" :color="colorTargetOut" class="color0--text">
                  {{ $tc('{count} months', average?.monthsTargetOut, { count: average?.monthsTargetOut }) }}
                </v-chip>
              </v-col>
            </template>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <div slot="content">
      <v-row v-show="!dataExists" justify="center" class="mt-4">
        <cs-alert-panel dense type="info" :text="$t('No data for this period...')" />
      </v-row>

      <v-row v-show="dataExists" justify="center">
        <v-col cols="12">
          <cs-chart-bar :ref="`business-lunch-by-month-chart-${userId}`" class="business-lunch-by-month-chart" :chart-data="graphData" :options="getGraphOptions()" />
        </v-col>

        <v-col v-show="mealsDetails?.length" v-if="canListEvents" cols="12">
          <div class="text-h5">
            {{ $t('Details') }} — {{ mealsDetailsPeriod }}
            <v-chip>
              {{ $t('{count} meals', { count: mealsDetails.length }) }}
            </v-chip>
          </div>
          <csm-follow-up-list ref="target-follow-up" :sales-persons="[userId]" :no-data-request="true" :raw-data-events="mealsDetails" hide-search @loading="onFollowupLoading" @loaded="onFollowupLoaded" />
        </v-col>

        <v-col v-if="!mealsDetails?.length" class="shrink text-no-wrap">
          <cs-alert-panel dense type="info" :text="$t('Click on a bar in the graph to see the event list.')" />
        </v-col>

        <!-- FUTURE
        <v-col v-if="rawData && rawData.bySalesPerson && rawData.bySalesPerson[userId]">
          {{ $tc('{count} business meals', rawData.bySalesPerson[userId].total, { count: rawData.bySalesPerson[userId].total }) }}
          {{ $t('on {count} months', { count: average?.months }) }}
        </v-col>

        <v-col>
          <span v-if="!isNaN(average?.value)" class="ml-4" label>
            {{ $t('Average {count}{unit} {period}', {
              count: average?.value,
              unit: $t('meal(s)'),
              period: $t('on {count} weeks', { count: average?.weeks })
            }) }}
          </span>
        </v-col> -->
      </v-row>
    </div>
  </cs-expand-panel>
</template>

<script>
import _ from 'lodash'

const COLOR_TARGET_IN = '#6BDE81'
const COLOR_TARGET_OUT = '#FFC133'
const COLOR_NO_TARGET = '#777'
const DEFAULT_CHART_DATA = { labels: [], datasets: [{ data: [] }] }

export default {
  name: 'TargetBusinessMealByMonth',
  components: {
    'csm-follow-up-list': () => import('@/components/FollowUps/FollowUpList')
  },
  props: {
    beginAt: { type: [Date, String], required: true },
    colorNoTarget: { type: String, default: COLOR_NO_TARGET },
    colorTargetIn: { type: String, default: COLOR_TARGET_IN },
    colorTargetOut: { type: String, default: COLOR_TARGET_OUT },
    endAt: { type: [Date, String], required: true },
    includeWeekend: { type: Boolean, default: false },
    userId: { type: String, required: true }
  },
  data () {
    return {
      average: {},
      dataExists: false,
      graphData: { ...DEFAULT_CHART_DATA },
      loading: false,
      mealsDetails: [],
      mealsDetailsPeriod: '',
      rawData: {},
      user: null
    }
  },
  computed: {
    canListEvents () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.LIST)
    },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    isLord () { return this.$store.getters['$stratus-states/isLord'] }
  },
  methods: {
    computeAverage () {
      const months = this.$stratus.dt(this.endAt).diff(this.beginAt, 'month') + 1
      const average = {
        months,
        user: this.user,
        monthsTargetIn: 0,
        monthsTargetOut: this.rawData.bySalesPerson[this.userId] ? 1 + months - Object.keys(this.rawData.bySalesPerson[this.userId]).length : months,
        businessMealsTargetIn: 0,
        businessMealsTargetFull: this.user?.lord_team_info?.business_lunch_by_month ? this.user?.lord_team_info?.business_lunch_by_month * months : 0,
        value: NaN,
        empty: this.rawData.bySalesPerson[this.userId] === undefined
      }

      if (this.rawData?.bySalesPerson[this.userId]) {
        average.value = average.months > 0 ? this.$stratus.services.format.asDigits(this.rawData.bySalesPerson[this.userId].total / average.months, 2) : NaN

        if (this.user?.lord_team_info?.business_lunch_by_month) {
          _.forEach(this.rawData.bySalesPerson[this.userId], (count, date) => {
            // This is to avoid the key named "total"
            if (this.$stratus.dt(date).isValid()) {
              average.businessMealsTargetIn += count
              if (count >= this.user?.lord_team_info?.business_lunch_by_month) average.monthsTargetIn++
              else average.monthsTargetOut++
            }
          })
        }
      }

      this.average = { ...average }
    },
    createGraph () {
      const labels = []
      const data = []
      const target = []
      let dataExists = false
      const backgroundColor = []

      // Create array with a tick/dot by day
      let date = this.$stratus.dt(this.beginAt)
      const stopDate = this.$stratus.dt(this.endAt)
      while (stopDate.isAfter(date)) {
        const key = date.format('YYYY-MM')
        labels.push(key)
        target.push(this.user?.lord_team_info?.business_lunch_by_month)
        dataExists = true

        // Create graph series of dots
        if (this.rawData.bySalesPerson[this.userId]?.[key] !== undefined) {
          data.push(parseInt(this.rawData.bySalesPerson[this.userId][key]))
          if (this.user?.lord_team_info?.business_lunch_by_month === undefined) {
            // No target is defined for the user, use a neutral color
            backgroundColor.push(this.colorNoTarget)
          } else {
            // Color depends if target is hit or miss
            backgroundColor.push(parseInt(this.rawData.bySalesPerson[this.userId][key]) >= this.user?.lord_team_info?.business_lunch_by_month ? this.colorTargetIn : this.colorTargetOut)
          }
        } else {
          // Put a tick in the graph
          data.push(0)
          backgroundColor.push('')
        }

        date = date.add(1, 'month') // Next
      }

      try {
        this.graphData = {
          labels,
          datasets: [{
            label: this.$t('Number of business meals'),
            yAxisID: 'counter-axis', // Attach to the correct Y axis
            data,
            backgroundColor,
            ids: this.rawData?.ids?.byMonth || {},
            loadDetails: this.loadDetails,
            order: 1
          }, {
            // This dataset display a line to show the target
            label: this.$t('Target'),
            data: target,
            borderColor: this.$alto.defines.COLORS.PRIMARY,
            borderDash: [10, 10],
            borderWidth: 2,
            fill: false,
            type: 'line',
            pointRadius: 0, // Hide all points
            pointHitRadius: 0, // Disable tooltip
            order: 0
          }]
        }
        this.dataExists = dataExists
      } catch (error) {
        console.error(error)
      }

      this.updateGraph()
    },
    getGraphOptions () {
      return {
        // Click on a bar to load it's events
        onClick (e) {
          // * IMPORTANT *
          // This is a native click event, outside Vue scope!
          // Value of «this» is not vue but the chart instance this event is plug to.
          const element = this.getElementAtEvent(e)
          if (element.length > 0) {
            // load all events according to the array of ids
            this.data.datasets[element[0]._datasetIndex].loadDetails({
              index: element[0]._index, // Index in data, ids, labels
              ids: this.data.datasets[element[0]._datasetIndex].ids?.[this.data.labels[element[0]._index]],
              chartLabel: this.data.datasets[element[0]._datasetIndex].label,
              xLabel: this.data.labels[element[0]._index]
            })
            this.update()
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              callback: (tick) => {
                return this.$stratus.dt(tick).format('MMMM YYYY')
              }
            },
            gridLines: {
              display: true
            }
          }],
          yAxes: [{
            id: 'counter-axis',
            type: 'linear',
            ticks: {
              beginAtZero: true,
              min: 0,
              callback: function (value) {
                // Display only ticks for integer value
                if (value % 1 === 0) { return value }
              }
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: (tooltipItems, data) => {
              return this.$stratus.dt(tooltipItems.xLabel).format('MMMM YYYY') + ' : ' +
              this.$t('{count}{unit}', { count: tooltipItems.yLabel, unit: this.$t('business meals') })
            }
          }
        }
      }
    },
    async loadDetails ({ index, ids, chartLabel, xLabel }) {
      try {
        this.loading = true
        this.mealsDetailsPeriod = this.$stratus.dt(xLabel).format('MMMM YYYY')
        const details = await this.$store.dispatch('followups/getEventsByIds', ids)
        this.mealsDetails = details?.results || []
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.loading = false }, 1000)
      }
    },
    onFollowupLoaded (count) {
      this.loading = false
    },
    onFollowupLoading (loading) {
      this.loading = loading
    },
    progress (byMealsCount) {
      if (!this.average) return null

      if (byMealsCount) {
        return this.average.businessMealsTargetFull > 0 ? 100 * this.average.businessMealsTargetIn / this.average.businessMealsTargetFull : null
      }

      return this.average.months > 0 ? 100 * this.average.monthsTargetIn / this.average.months : null
    },
    async refreshInternal () {
      try {
        this.loading = true
        this.reset()
        this.user = this.$store.getters['$alto-users/getSalesPerson'](this.userId)
        this.rawData = await this.$store.dispatch('followups/businessMealsByMonth', { beginAt: this.beginAt, endAt: this.endAt, salesPersons: this.userId, includeWeekend: this.includeWeekend })
        this.createGraph()
        this.computeAverage()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.loading = false }, 1000)
      }
    },
    reset () {
      this.average = {}
      this.rawData = {}
      this.graphData = { ...DEFAULT_CHART_DATA }
      this.mealsDetails = []
      this.mealsDetailsPeriod = ''
    },
    success () {
      return this.average && this.average.months > 0 ? this.average.monthsTargetIn === this.average.months : null
    },
    updateGraph () {
      try {
        if (this.$refs[`business-lunch-by-month-chart-${this.userId}`] && this.$refs[`business-lunch-by-month-chart-${this.userId}`][0]) {
          this.$refs[`business-lunch-by-month-chart-${this.userId}`][0].resize(400, '100%')
          this.$refs[`business-lunch-by-month-chart-${this.userId}`][0].update()
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  created () {
    this.refresh = _.debounce(this.refreshInternal, 1000)
  },
  mounted () {
    this.user = this.$store.getters['$alto-users/getSalesPerson'](this.userId)
  }
}
</script>

<style scoped>
.business-lunch-by-month-chart {
  height: 400px;
  width: 100%;
}
</style>
