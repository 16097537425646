<template>
  <sca-modal-dialog :visible="visible" :loading="loading || isSaving" :can-save="canSave" :is-saving="isSaving" :max-width="$vuetify.breakpoint.mdAndUp ? '1280' : ''" @closeDialog="closeDialog" @saveDialog="saveDialog" :external-id="externalId">
    <template slot="title">
      <span v-if="event.id">{{ event.id }} </span>{{ event.name }} — {{ $t('Campaign') }} {{ isDirty() ? '*' : '' }}
    </template>

    <template #buttons-header>
      <cs-action-button v-if="canCreateEmailCampaign" icon="$vuetify.icons.emailCampaign" help="Create an email campaign" :loading="creatingCampaign" @click="createEmailCampaign" />

      <cs-action-menu v-if="event.id && event.target !== MARKETING_EVENT_TARGET_BRANDING" icon="$vuetify.icons.setup" help="Update targets">
        <v-card slot="menu" tile class="pa-2" width="200">
          <div>
            <cs-integer-input v-model="event.leads_target" :label="$t('Leads target')" />
          </div>

          <div class="pt-2">
            <cs-price-input v-model="event.turnover_target" :label="$t('Turnover target')" />
          </div>

          <div class="pt-2">
            <cs-price-input v-model="event.planned_budget" :label="$t('Planned budget')" />
          </div>
        </v-card>
      </cs-action-menu>

      <cs-action-menu v-if="canImportLead && event.id" icon="$vuetify.icons.leads" help="Leads">
        <v-card slot="menu" class="pa-2">
          <v-btn text rounded @click="downloadLeadModel">
            <v-icon left>
              $vuetify.icons.download
            </v-icon>
            {{ $t('Download leads template') }}
          </v-btn>

          <v-divider class="my-2" />

          <v-row dense>
            <v-col cols="12">
              {{ $t('Upload leads') }}
            </v-col>

            <v-col>
              <cs-file-uploader v-model="leadsImportFile" use-isu :file-size-limit="20 * 1000 * 1000" max-file-count="1" />
            </v-col>

            <v-col class="shrink">
              <v-btn small rounded class="primary" :disabled="!leadsImportFile?.length > 0" @click="uploadLeads">
                {{ $t('Send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </cs-action-menu>

      <cs-action-button v-if="canClone && event.id" icon="$vuetify.icons.clone" help="Clone" @click="clone" />
    </template>

    <div slot="content">
      <v-row>
        <v-col cols="12" md="">
          <v-form ref="event-form" v-model="valid" lazy-validation :disabled="!canSave">
            <v-row align="end" dense>
              <v-col cols="12" md="6" lg="2">
                <sca-marketing-event-target-select v-model="event.target" single-line />
              </v-col>

              <v-col cols="12" md="6" lg="6">
                <v-text-field v-model="event.name" :label="$t('Name')" :counter="128" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.min(1), $stratus.services.form.rules.max(128)]" class="required" />
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <sca-marketing-event-type-select v-model="event.type" :label="$t('Campaign type')" :rules="[$stratus.services.form.rules.required]" allow-empty />
              </v-col>
            </v-row>

            <v-row align="center" dense>
              <v-col cols="12" md="5">
                <cs-date-time-range :from-date="event.date_begin" :to-date="event.date_end" no-time @changeFrom="onChangeFrom" @changeTo="onChangeTo" format-short />
              </v-col>

              <v-col>
                <cs-integer-input v-model="event.score" :label="$t('Score')" />
              </v-col>

              <v-col cols="12" md="">
                <sca-marketing-event-select v-model="event.id_parent" :label="$t('Parent')" exclude-children :exclude-ids="event.id" />
              </v-col>
            </v-row>

            <v-row v-if="!event.id" align="center" dense>
              <v-col cols="12" md="6" lg="3">
                <cs-integer-input v-model="event.leads_target" :label="$t('Leads target')" />
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <cs-price-input v-model="event.turnover_target" :label="$t('Turnover target')" />
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <cs-price-input v-model="event.planned_budget" :label="$t('Planned budget')" />
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col v-if="event.id && event.target !== MARKETING_EVENT_TARGET_BRANDING" cols="12" lg="5">
          <cs-header :title="$t('Targets')" dense>
            <template #content>
              <v-row v-if="event?.id">
                <v-col cols="12" md="6">
                  <div class="d-flex align-center">
                    {{ $t('Turnover target') }}
                    <div class="ml-auto">
                      {{ $n(event.turnover_target, 'currency') }}
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    {{ $t('Turnover generated') }}
                    <div class="font-weight-bold ml-auto">
                      {{ $n(event.turnover_generated, 'currency') }}
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    {{ $t('ROI') }}
                    <div class="ml-auto">
                      {{ $n(event.roi, 'currency') }}
                    </div>
                  </div>

                  <div class="text-center">
                    <v-progress-circular :value="percentTargetCostAchieved" color="color2" size="64" width="6">
                      <template #default>
                        <div class="text-h4">
                          {{ $t('{percent}%', { percent: percentTargetCostAchieved }) }}
                        </div>
                      </template>
                    </v-progress-circular>
                  </div>
                </v-col>

                <v-col cols="12" md="6">
                  <div class="d-flex align-center">
                    {{ $t('Leads target') }}
                    <div class="ml-auto">
                      {{ $n(event.leads_target) }}
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    {{ $t('Leads') }}
                    <div class="ml-auto">
                      {{ $n(event.nb_leads) }}
                    </div>
                  </div>

                  <div class="text-center">
                    <v-progress-circular :value="percentTargetLeadsAchieved" color="color2" size="64" width="6">
                      <template #default>
                        <div class="text-h4">
                          {{ $t('{percent}%', { percent: percentTargetLeadsAchieved }) }}
                        </div>
                      </template>
                    </v-progress-circular>
                  </div>
                </v-col>
              </v-row>
              <div v-else class="text-center my-4">
                {{ $t('No data...') }}
              </div>
            </template>
          </cs-header>
        </v-col>
      </v-row>

      <csm-marketing-emailing-panel v-if="(event.id_campaign_brevo && event.brevo_name) || event.type === MARKETING_EVENT_TYPE_EMAILING" class="mb-4" outlined>
        <template #content>
          <div v-if="event.id_campaign_brevo && event.brevo_name">
            <a :href="`https://app.brevo.com/marketing-campaign/edit/${event.id_campaign_brevo}`" target="_ext" class="hover-underline-from-center pt-1">
              {{ $t('Open campaign in Brevo') }}
              <v-icon small>
                $vuetify.icons.for
              </v-icon>
              <b>{{ event.brevo_name }}</b>
            </a>
          </div>

          <div v-else-if="event.type === MARKETING_EVENT_TYPE_EMAILING" class="d-flex align-center">
            <sca-marketing-email-campaign-select v-model="event.id_campaign_brevo" :label="$t('Email campaign')" />
            <v-btn small rounded class="ml-2 main-button" :disabled="!event.id_campaign_brevo" @click="saveDialog(false)">
              {{ $t('Apply') }}
            </v-btn>
          </div>

          <div v-if="event.brevo_date_send" class="align-self-center ml-2 d-flex align-center">
            <v-divider vertical class="mx-4" />
            <div>
              {{ $t('Send date: ') }} {{ $stratus.dt(event.brevo_date_send).format('LLL') }}
            </div>
            <v-chip small label class="ml-2">
              {{ $t('Recipients: ') }} {{ event.brevo_nb_recipients }}
            </v-chip>
            <v-chip small label class="ml-2">
              {{ $t('Clickers: ') }} {{ event.brevo_nb_clickers }}
            </v-chip>
            <v-chip small label class="ml-2">
              {{ $t('Opening: ') }} {{ event.brevo_nb_opening }}
            </v-chip>
            <v-chip small label class="ml-2">
              {{ $t('Unsubscribing: ') }} {{ event.brevo_nb_unsubscribing }}
            </v-chip>
          </div>
        </template>
      </csm-marketing-emailing-panel>

      <v-row>
        <v-col cols="12" md="6" lg="5">
          <div class="d-flex align-center">
            <v-icon small left color="primary">
              $vuetify.icons.marketingCost
            </v-icon>
            <div class="primary--text text-h5">
              {{ $t('Costs') }}
            </div>

            <div class="ml-auto">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on" @click="createCost">
                    $vuetify.icons.add
                  </v-icon>
                </template>
                {{ $t('Create a new cost...') }}
              </v-tooltip>
            </div>
          </div>

          <div>
            <sca-marketing-cost-table :value="allCosts" dense can-delete can-modify @delete="onDeleteCost" @modify="onModifyCost" />

            <div v-if="allCosts?.length > 0" class="text-right text-h5 primary--text">
              Total {{ $n(totalCost, 'currency') }} / {{ $n(event.planned_budget, 'currency') }}
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="6" lg="7" class="pl-8">
          <div class="d-flex align-center">
            <v-icon small left color="primary">
              $vuetify.icons.leads
            </v-icon>
            <div class="primary--text text-h5">
              {{ $t('Leads') }}
            </div>

            <div class="ml-auto">
              <cs-options-menu>
                <template #menu>
                  <v-btn rounded block @click="createLead">
                    <v-icon>
                      $vuetify.icons.add
                    </v-icon>

                    {{ $t('Create a lead...') }}
                  </v-btn>

                  <div class="mt-2">
                    {{ $t('Add a lead:') }}
                  </div>
                  <div class="d-flex align-center">
                    <sca-marketing-lead-select v-model="selectedLeads" :exclude-ids="currentLeads" multiple clearable dense hide-selected />
                  </div>

                  <v-btn small rounded :disabled="!selectedLeads?.length" @click="addLeads(selectedLeads)">
                    <v-icon>
                      $vuetify.icons.add
                    </v-icon>
                    {{ $t('Add') }}
                  </v-btn>
                </template>
              </cs-options-menu>
            </div>
          </div>

          <div v-if="event.id">
            <sca-marketing-lead-table ref="leads-table" :value="leads" dense can-delete :disabled="loading" @delete="onRemoveLead" />
          </div>
        </v-col>
      </v-row>

      <v-row v-if="childrenEvents?.length">
        <v-col cols="12">
          <div class="d-flex align-center">
            <v-icon small left color="primary">
              $vuetify.icons.marketingEvent
            </v-icon>
            <div class="primary--text text-h5">
              {{ $t('Related events') }}
            </div>
          </div>

          <div class="d-flex flex-wrap">
            <sca-marketing-event-identity v-for="child in childrenEvents" outlined :key="child.id" :value="child" class="ma-1" show-avatar link="emit" @link-click="openEvent(child.id)" />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex align-center">
            <v-icon small left color="primary">
              $vuetify.icons.opportunity
            </v-icon>
            <div class="primary--text text-h5">
              {{ $t('Opportunities') }}
            </div>

            <div class="ml-auto">
              <v-menu left offset-y :close-on-content-click="false" transition="slide-y-transition">
                <template v-slot:activator="{ on: onMenu }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-icon small rounded v-on="{ ...onMenu, ...onTooltip }">
                        $vuetify.icons.add
                      </v-icon>
                    </template>
                    {{ $t('Add') }}
                  </v-tooltip>
                </template>

                <v-card tile class="pa-2">
                  {{ $t('Add opportunity:') }}
                  <div class="d-flex align-center">
                    <sca-opportunity-select v-model="selectedOpportunity" dense />
                  </div>

                  <v-btn small rounded :disabled="!selectedOpportunity" @click="addOpportunity">
                    <v-icon>
                      $vuetify.icons.add
                    </v-icon>
                    {{ $t('Add') }}
                  </v-btn>
                </v-card>
              </v-menu>
            </div>
          </div>

          <div v-if="allOpportunities?.length > 0">
            <sca-marketing-opportunity-table :value="allOpportunities" dense can-delete @delete="onRemoveOpportunity" />
          </div>
        </v-col>
      </v-row>

      <csm-marketing-event-dialog ref="marketing-event-dialog-bis" />
      <csm-marketing-cost-dialog ref="marketing-cost-dialog" @closeDialog="closeCostDialog" />
      <csm-marketing-lead-dialog ref="marketing-lead-dialog" @closeDialog="closeLeadDialog" />
      <cs-confirm-dialog ref="confirm-event-action-dialog" />
      <cs-generic-dialog ref="email-campaign-dialog">
        <div slot="content">
          <v-select v-model="templateId" :items="templateList" item-value="id" item-text="name" />
        </div>
      </cs-generic-dialog>
    </div>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'MarketingEventForm',
  components: {
    'csm-marketing-event-dialog': () => import(/* webpackChunkName: "components" */ './MarketingEventDialog'),
    'csm-marketing-cost-dialog': () => import(/* webpackChunkName: "components" */ './MarketingCostDialog'),
    'csm-marketing-lead-dialog': () => import(/* webpackChunkName: "components" */ './MarketingLeadDialog'),
    'csm-marketing-emailing-panel': () => import(/* webpackChunkName: "components" */ './MarketingEmailingPanel')
  },
  data () {
    return {
      MARKETING_EVENT_TARGET_BRANDING: this.$alto.defines.MARKETING.MARKETING_EVENT_TARGET_BRANDING,
      MARKETING_EVENT_TYPE_EMAILING: this.$alto.defines.MARKETING.MARKETING_EVENT_TYPE_EMAILING,
      childrenEvents: [],
      creatingCampaign: false,
      costs: [],
      isSaving: false,
      event: {},
      opportunities: [],
      leads: [],
      leadsImportFile: [],
      loading: false,
      selectedOpportunity: null,
      selectedLeads: null,
      templateId: null,
      templateList: [],
      valid: false,
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    allCosts () {
      return this.event ? this.costs.concat(this.event.costs || []) : this.costs
    },
    allOpportunities () {
      return this.event ? this.opportunities.concat(this.event.opportunities || []) : this.opportunities
    },
    canClone () {
      return this.event?.id && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_MARKETING, this.$alto.API_PERMISSIONS.CREATE)
    },
    canCreateEmailCampaign () {
      return this.event?.id &&
        this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_MARKETING, this.$alto.API_PERMISSIONS.MARKETING_EMAIL_CAMPAIGN) &&
        this.event?.type !== this.$alto.defines.MARKETING.MARKETING_EVENT_TYPE_EMAILING &&
        _.isEmpty(this.event?.id_parent)
    },
    canImportLead () {
      return this.event?.id && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_LEADS, this.$alto.API_PERMISSIONS.OPPORTUNITIES_IMPORT)
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_MARKETING, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_MARKETING, this.$alto.API_PERMISSIONS.UPDATE)
    },
    currentLeads () {
      return _.map(this.leads, 'id')
    },
    percentTargetCostAchieved () {
      if (!this.event?.turnover_target || this.event?.turnover_generated <= 0) return 0
      return Math.round(100 * this.event.turnover_generated / this.event.turnover_target)
    },
    percentTargetLeadsAchieved () {
      if (!this.event?.nb_leads || this.event?.leads_target <= 0) return 0
      return Math.round(100 * this.event.nb_leads / this.event.leads_target)
    },
    totalCost () {
      let total = 0.0
      _.forEach(this.allCosts, cost => {
        total += cost.cost
      })
      return total
    }
  },
  methods: {
    async addCost (cost) {
      if (!cost?.id || this.event?.costs_ids?.includes(cost.id)) return
      if (!this.event.costs_ids) this.event.costs_ids = []
      this.event.costs_ids.push(cost.id)
      this.costs.push(cost)
    },
    async addOpportunity () {
      if (!this.selectedOpportunity || this.event?.opportunities_ids?.includes(this.selectedOpportunity)) return
      if (!this.event.opportunities_ids) this.event.opportunities_ids = []
      this.event.opportunities_ids.push(this.selectedOpportunity)
      try {
        const opportunity = await this.$store.dispatch('$alto-opportunities/get', this.selectedOpportunity)
        if (opportunity) this.opportunities.push(opportunity)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    async addLeads (leads) {
      try {
        this.loading = true
        for (const index in leads) { // This kind of for is needed to use await
          await this.$store.dispatch('$alto-marketing/addLeadToEvent', { idEvent: this.event.id, idLead: leads[index] })
        }
        await this.loadLeads()
        this.selectedLeads = null
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.loading = false
      }
    },
    clone () {
      this.$set(this.event, 'id', '')
      this.resetOriginal()
      this.$stratus.services.notify.success(this.$t('Campaign cloned.'))
    },
    closeDialog (forceClose) {
      if (forceClose || !this.canSave || !this.isDirty()) {
        this.$emit('closeDialog')
        return
      }

      this.$refs['confirm-event-action-dialog'].open(this.$t('Modifications detected'), this.$t('Do you confirm the loss of your modifications?'))
        .then(confirmed => {
          if (confirmed) {
            this.$emit('closeDialog')
          }
        })
    },
    async closeCostDialog (cost) {
      if (cost?.id) {
        this.addCost(cost)
        // Update table of costs
        let index = _.indexOf(this.event.costs_ids, cost.id)
        if (index >= 0) {
          this.event.costs_ids.splice(index, 1, cost.id)
        }
        index = _.findIndex(this.costs, { id: cost.id })
        if (index >= 0) {
          this.costs.splice(index, 1, cost)
        }
        index = _.findIndex(this.event.costs, { id: cost.id })
        if (index >= 0) {
          this.event.costs.splice(index, 1, cost)
        }
        this.saveCostIds()
      }
    },
    closeLeadDialog (lead) {
      if (lead?.id) {
        this.addLeads([lead?.id])
      }
    },
    createCost () {
      if (this.$refs['marketing-cost-dialog']) this.$refs['marketing-cost-dialog'].open()
    },
    createLead () {
      if (this.$refs['marketing-lead-dialog']) this.$refs['marketing-lead-dialog'].open()
    },
    async createEmailCampaign () {
      try {
        this.templateList = await this.$store.dispatch('$alto-marketing/listEmailCampaignTemplates')
        if (!this.templateList?.length) {
          this.$stratus.services.notify.warning(this.$t('No template available!'))
          return
        }

        const { confirmed } = await this.$refs['email-campaign-dialog']
          .open(this.$t('Create an email campaign'), this.$t('Please, choose a temblate for the email campaign :'))
        if (confirmed && this.templateId) {
          this.creatingCampaign = true
          const newChildEvent = await this.$store.dispatch('$alto-marketing/createEmailCampaign', { id: this.event.id, templateId: this.templateId })
          if (newChildEvent?.id) {
            // Update our childrens
            this.childrenEvents = await this.$store.dispatch('$alto-marketing/listChildrenEvents', this.event.id)
            // Open the new one
            this.openEvent(newChildEvent.id)
          }
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.creatingCampaign = false }, 250)
      }
    },
    async downloadLeadModel () {
      try {
        await this.$store.dispatch('$alto-marketing/getLeadTemplate')
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    externalId () {
      if (!this.event.id) return
      return [this.event.id, this.event.id ? 'marketing-event/' + this.event.id : '', this.event.name]
    },
    isDirty () {
      if (!this.originalEvent) return false
      return !_.isEqual(this.sanitize(this.event), this.originalEvent)
    },
    async loadLeads () {
      this.leads = await this.$store.dispatch('$alto-marketing/getLeadsForEvent', this.event.id)
    },
    onChangeFrom (value) {
      this.$set(this.event, 'date_begin', value)
    },
    onChangeTo (value) {
      this.$set(this.event, 'date_end', value)
    },
    onModifyCost (cost) {
      if (this.$refs['marketing-cost-dialog']) this.$refs['marketing-cost-dialog'].open(cost?.id)
    },
    onDeleteCost (cost) {
      this.$refs['confirm-event-action-dialog'].open(this.$t('Delete cost'), this.$t('Do you confirm the deletion of cost "{name}"?', cost))
        .then(async confirmed => {
          if (confirmed) {
            try {
              this.loading = true
              await this.$store.dispatch('$alto-marketing/deleteCost', cost.id)
              let index = _.indexOf(this.event.costs_ids, cost.id)
              if (index >= 0) {
                this.event.costs_ids.splice(index, 1)
              }
              index = _.findIndex(this.costs, { id: cost.id })
              if (index >= 0) {
                this.costs.splice(index, 1)
              }
              index = _.findIndex(this.event.costs, { id: cost.id })
              if (index >= 0) {
                this.event.costs.splice(index, 1)
              }
              this.saveCostIds()
            } catch (error) {
              this.$stratus.services.notify.error(error)
            } finally {
              this.loading = false
            }
          }
        })
    },
    async onRemoveLead (lead) {
      try {
        this.loading = true
        await this.$store.dispatch('$alto-marketing/deleteLeadFromEvent', { idLead: lead.id, idEvent: this.event.id })
        await this.loadLeads()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.$refs['leads-table'].load()
        this.loading = false
      }
    },
    onRemoveOpportunity (opportunity) {
      try {
        this.loading = true
        let index = _.indexOf(this.event.opportunities_ids, opportunity.id)
        if (index >= 0) {
          this.event.opportunities_ids.splice(index, 1)
        }
        index = _.findIndex(this.opportunities, { id: opportunity.id })
        if (index >= 0) {
          this.opportunities.splice(index, 1)
        }
        index = _.findIndex(this.event.opportunities, { id: opportunity.id })
        if (index >= 0) {
          this.event.opportunities.splice(index, 1)
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.loading = false
      }
    },
    openEvent (id) {
      this.$refs['marketing-event-dialog-bis'].open(id)
    },
    resetOriginal () {
      this.originalEvent = _.cloneDeep(this.sanitize(this.event))
    },
    async reset (event = {}) {
      this.costs = []
      this.leads = []
      this.opportunities = []
      this.selectedOpportunity = null
      this.event = { ...event }

      if (this.event.id) {
        this.loading = true
        try {
          this.childrenEvents = await this.$store.dispatch('$alto-marketing/listChildrenEvents', this.event.id)
          await this.loadLeads()
        } catch (error) {
          this.$stratus.services.notify.error(error)
        } finally {
          this.loading = false
        }
      }

      this.resetOriginal()
    },
    sanitize (event) {
      return {
        ...event,
        name: this.$stratus.services.strings.stripHtmlTags(event.name),
        date_begin: this.$stratus.dt(event.date_begin).isValid() ? this.$stratus.dt(event.date_begin).format('YYYY-MM-DD') : '',
        date_end: this.$stratus.dt(event.date_end).isValid() ? this.$stratus.dt(event.date_end).format('YYYY-MM-DD') : ''
      }
    },
    async saveCostIds () {
      if (this.event.id) {
        this.isSaving = true
        try {
          await this.$store.dispatch('$alto-marketing/saveEvent', {
            id: this.event.id,
            costs_ids: this.event.costs_ids
          })
        } catch (error) {
          this.$stratus.services.notify.error(error)
        } finally {
          this.isSaving = false
        }
      }
    },
    async saveDialog (canClose = true) {
      if (!this.canSave) return false

      if (!this.$refs['event-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        return false
      }

      this.isSaving = true
      try {
        await this.$store.dispatch('$alto-marketing/saveEvent', this.sanitize(this.event))
        this.resetOriginal()
        if (canClose) this.closeDialog()
      } catch (error) {
        this.$stratus.services.notify.error(error)
        return false
      } finally {
        this.isSaving = false
      }
    },
    async uploadLeads () {
      try {
        this.loading = true
        await this.$store.dispatch('$alto-marketing/uploadLeads', { id: this.event.id, file: this.leadsImportFile })
        this.loadLeads()
        this.$stratus.services.notify.success(this.$t('The leads have been imported.'))
      } catch (error) {
        if (error.status === 500) {
          this.$stratus.services.notify.error(this.$t('An error occurred when importing the file {name}. Is the file in the expected format?', { name: this.leadsImportFile[0].name }))
        } else this.$stratus.services.notify.error(error)
      } finally {
        this.$refs['leads-table'].load()
        this.loading = false
      }
    },
    display (show = false) {
      this.visible = show
      // this.$refs['leads-table'].reset()
    }
  }
}
</script>
