<template>
  <div class="pa-4">
    <v-dialog v-model="isSaving" persistent max-width="400">
      <v-card class="pa-12">
        {{ $t('Updating cart data...') }}
      </v-card>
    </v-dialog>

    <!-- <v-row v-if="isExpired()" no-gutters justify="center">
      <v-col class="shrink text-no-wrap">
        <cs-alert-panel type="warning" :text="$t('This quotation expires on {date}.', { date: $stratus.dt(expirationDateOrigin).format('LLL') })" />
      </v-col>
    </v-row> -->

    <v-row>
      <v-col>
        <v-row no-gutters>
          <v-col cols="12" md="6" lg="7">
            <v-row align="start">
              <v-col cols="12" md="5">
                <v-text-field v-model="cart.name" :label="$t('Name')" counter="64" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.min(4), $stratus.services.form.rules.max(64)]" class="required" dense append-outer-icon="icon-refresh" @click:append-outer="createName" :readonly="!canSave" />
              </v-col>

              <v-col cols="12" md="3">
                <cs-helper>
                  <sca-sphere-select slot="content" v-model="cart.id_sphere" :label="$t('Sphere')" :customer="cart.customer" dense hide-details :readonly="!canSave" />
                  <div slot="help">
                    {{ $t('Spheres allow you to generate multiple invoices. To create and manage spheres, go to his space, in the administration tab!') }}
                  </div>
                </cs-helper>
              </v-col>

              <v-col cols="12" md="4">
                <sca-customer-select v-model="cart.customer" :label="$t('Customer')" :rules="[$stratus.services.form.rules.required]" class="required" @change="onChangeCompany" :readonly="!canSave" dense link="emit" @link-click="openCompany(cart.customer)" />
              </v-col>
            </v-row>

            <v-row align="start" dense>
              <v-col cols="12" md="6" lg="4">
                <div class="d-flex align-center">
                  <div class="text-no-wrap pr-2">
                    {{ $t('Pre-sale') }}
                  </div>
                  <div v-if="cart.id_pre_sale || isLord" class="d-flex align-center">
                    <sca-users-select v-if="isLord" v-model="cart.id_pre_sale" allow-empty clearable filter-lord show-card single-line dense hide-details link="emit" @link-click="openUser(cart.id_pre_sale)" />
                    <div v-else-if="cart.id_pre_sale">
                      <sca-user-identity :value="cart.id_pre_sale" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(cart.id_pre_sale)" />
                    </div>
                  </div>
                </div>

                <sca-tickets-select v-if="isLord" v-model="cart.id_ticket_pre_sale" :label="$t('Ticket')" :types="TICKET_TYPE_PRE_SALE" :states="[TICKET_STATE_CLOSED, TICKET_STATE_DONE, TICKET_STATE_NO_GO]" exclude-states class="pt-1 mr-2 full-width" :include="[cart.id_ticket_pre_sale]" disable-include clearable hide-details dense show-card show-company :disabled="!canSave" />
              </v-col>

              <v-col cols="12" md="6" lg="8">
                <v-row dense>
                  <v-col cols="12" lg="7" class="pl-4">
                    <v-row no-gutters align="center">
                      <v-col>
                        <cs-helper prepend-help>
                          <template #content>
                            {{ $t('Start-up time') }}
                          </template>
                          <div slot="help">
                            {{ $t('The build load reflects the current load of the team that will be in charge of your project, the start of your project should take place after this deadline.') }}
                          </div>
                        </cs-helper>
                      </v-col>
                      <v-col>
                        <cs-decimal-input v-model="cartDaysBegin" :suffix="$t('days')" :min="0" :disabled="!isLord" class="ml-2" @input="changeDelay" />
                      </v-col>
                    </v-row>

                    <v-row no-gutters align="center">
                      <v-col>
                        <cs-helper prepend-help>
                          <template #content>
                            {{ $t('Time to production') }}
                          </template>
                          <div slot="help">
                            {{ $t('Estimate the duration of your project, the time between the start date and the delivery date.') }}
                          </div>
                        </cs-helper>
                      </v-col>
                      <v-col>
                        <cs-decimal-input v-model="cartDaysBuilding" :suffix="$t('days')" :min="0" :disabled="!isLord" class="ml-2" @input="changeDelay" />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" lg="5" class="text-no-wrap d-flex align-center">
                    <v-icon left right>
                      $vuetify.icons.for
                    </v-icon>
                    <div class="text-center">
                      <div>
                        <cs-helper prepend-help>
                          <template #content>
                            {{ $t('Estimated delivery time') }}
                          </template>
                          <div slot="help">
                            {{ $t('The estimated delivery date is calculated from the day the quotation is signed. The sooner the quotation is signed, the sooner the project can be delivered.') }}
                          </div>
                        </cs-helper>
                      </div>
                      <span v-if="daysBegin + daysBuilding > 0" class="text-h5 font-weight-bold text-no-wrap">
                        {{ $stratus.dt().add(daysBegin + daysBuilding, 'days').format('ll') }}
                      </span>
                      <span v-else>
                        {{ $t('None') }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="12" md="4" lg="2" class="align-self-start ml-2">
                <cs-percent-input v-show="isLord || cart.per_partial_billing > 0" v-model="cart.per_partial_billing" :label="$t('Deposit percentage')" :max="100" :min="0" :readonly="!canSave || !isLord" @input="onChangeInput" />
              </v-col>

              <v-col v-if="isLord" cols="12" md="4" lg="3">
                <cs-date-picker v-model="cart.potential_closing_date" :label="$t('Potential closing date')" :disabled="!canEditClosing" />
              </v-col>

              <v-col cols="12" md="4" lg="3" v-if="canUpdateExpirationDate">
                <cs-date-picker v-model="cart.expiration_date" :label="$t('Expiration date')" :min="minExpirationDate" :disabled="!canSave" />
              </v-col>

              <v-col cols="6" v-if="isLord && cart.opportunity_id">
                <sca-opportunity-identity :value="cart.opportunity_id" :label="cart.opportunity_id" show-avatar link="emit" @link-click="openOpportunity(cart.opportunity_id)" />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" lg="5" class="pl-8">
            <sca-product-prices-grid :prices="getPrices()" dense :title="$t('Total')" :sub-title="$tc('No product|One product|{count} products', cartQuantity, { count: cartQuantity })" :company="cartCustomer" :show-margin="isLord" :working="working" outlined />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <csm-opportunity-dialog ref="opportunity-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'ShoppingCartHeader',
  props: {
    pauseSaveCart: { type: Boolean, default: false }
  },
  data () {
    return {
      TICKET_STATE_CLOSED: this.$alto.defines.TICKETS.TICKET_STATE_CLOSED,
      TICKET_STATE_DONE: this.$alto.defines.TICKETS.TICKET_STATE_DONE,
      TICKET_STATE_NO_GO: this.$alto.defines.TICKETS.TICKET_STATE_NO_GO,
      TICKET_TYPE_PRE_SALE: this.$alto.defines.TICKETS.TICKET_TYPE_PRE_SALE,
      cartId: 0,
      descending: false,
      daysBegin: 0,
      daysBuilding: 0,
      expanded: {},
      expirationDateOrigin: null,
      isDeleting: false,
      isModifyingProduct: false,
      isSaving: false,
      listSalesPersons: [],
      loading: false,
      page: 1,
      pricesByPeriodicity: null,
      rowsPerPage: -1,
      showDescription: [],
      sortBy: 'index',
      total_discount: 0,
      total_setup_discount: 0,
      updateProduct: false,
      working: false
    }
  },
  watch: {
    'cart.expiration_date': {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue && !this.expirationDateOrigin) {
          this.expirationDateOrigin = this.$stratus.dt(newValue)
        }
      }
    },
    cart: {
      deep: true,
      immediate: true,
      handler () {
        if (this.cart.id !== this.cartId) {
          this.cartId = this.cart.id
          this.resetFields()
          this.computeTotal()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      cart: 'carts/current',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    cartCustomer () { return this.cart.customer || this.myCompany },
    canEditClosing () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.CART_UPDATE_CLOSING)
    },
    cartQuantity () {
      let result = 0.0
      this.cart.items.forEach(item => {
        if (item.float_quantity) {
          result += 1
        } else {
          result += item.quantity || 0
        }
      })
      return result
    },
    canSave () {
      return !this.cart.expired || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.CART_UPDATE_EXPIRED)
    },
    canUpdateExpirationDate () {
      return this.isLord && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.CART_UPDATE_EXPIRED)
    },
    cartDaysBegin: {
      get () {
        return this.cart.nb_days_begin_delay
      },
      set (value) {
        this.$set(this.cart, 'nb_days_begin_delay', Number(value))
        this.daysBegin = Number(value)
      }
    },
    cartDaysBuilding: {
      get () {
        return this.cart.nb_days_building_time
      },
      set (value) {
        this.$set(this.cart, 'nb_days_building_time', Number(value))
        this.daysBuilding = Number(value)
      }
    },
    headers () {
      return [
        { text: this.$t('#'), value: 'id', width: 32, sortable: false },
        { text: this.$t('Product'), value: 'name', sortable: false },
        { text: this.$t('Quantity'), value: 'quantity', width: 180, sortable: false },
        { text: this.$t('Price'), value: 'price', sortable: false }
      ]
    },
    minExpirationDate () { return this.$stratus.dt().add(1, 'days') },
    myCompany () { return this.$store.getters['$stratus-states/me'].company },
    prices () {
      if (!this.pricesByPeriodicity) this.computeTotal()
      return this.pricesByPeriodicity
    }
  },
  methods: {
    async addProductToCart ({ product, main, quantity }) {
      this.isSaving = true
      // Override default product from catalog with user inputs, if any
      product.main = main || null
      try {
        await this.$store.dispatch('carts/addToCurrent', { items: Object.assign(product, { quantity }), company: this.cartCustomer })
        this.$stratus.services.notify.success(this.$t('{quantity} × {product} added to cart.', { quantity, product: product.name || product.ref }))
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.isSaving = false }, 1000)
      }
    },
    canChangeQuantity (item) {
      if (!this.isLord) {
        if (item.no_price) {
          return false
        }
      }
      return true
    },
    changeDelay () {
      this.$emit('refresh')
    },
    computePrice () {
      this.working = true
      this.saveCart()
      setTimeout(() => { this.working = false }, 2000)
    },
    computeTotal () {
      this.$forceUpdate()
    },
    async createName () {
      if (this.canSave) {
        this.$set(this.cart, 'name', await this.$store.dispatch('carts/createName'))
        this.$forceUpdate()
        this.$emit('refresh')
      }
    },
    async deleteItem (item) {
      try {
        this.isDeleting = true
        await this.$store.dispatch('carts/delFromCurrent', item.uuid)
        this.saveCart()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
      setTimeout(() => { this.isDeleting = false }, 2000)
    },
    duplicateItem (item) {
      this.$refs['cart-product-duplicate-dialog'].open(_.cloneDeep(item), { update: false, customer: this.cartCustomer })
        .then(({ product, main, quantity }) => {
          if (product) this.addProductToCart({ product, main, quantity })
        })
    },
    expandedToggle (product) {
      this.$set(this.expanded, product.uuid, !this.expanded[product.uuid])
    },
    expirationDays () { return this.$store.getters['carts/expirationDays'](this.cart.date) },
    expirationDateHtml () { return this.$store.getters['carts/expirationDateHtml'](this.cart.date) },
    getIndexFromUUID (tab, uuid) {
      let index = null
      _.forEach(tab, (item, key) => {
        if (item.uuid === uuid) {
          index = key
        }
      })
      return index
    },
    getPrices () {
      return {
        ...this.cart
      }
    },
    getProductByRef (ref) { return this.$store.getters['$alto-catalog/get'](ref) || {} },
    isExpired () {
      return this.expirationDateOrigin && this.$stratus.dt(this.expirationDateOrigin).isBefore(this.$stratus.dt())
    },
    modifyItem (item) {
      this.$refs['cart-product-duplicate-dialog'].open(_.cloneDeep(item), { update: true, customer: this.cartCustomer })
        .then(({ product, main, quantity }) => {
          if (product) {
            item.caption = product.caption
            item.customer_caption = product.customer_caption
            item.main = _.cloneDeep(main)
            item.quantity = quantity
            if (product.no_price) {
              item.price = product.price
              item.buying_price = product.buying_price
            }
            this.computePrice()
          }
        })
    },
    onChangeCompany (customer) {
      if (!customer) return
      this.isSaving = true
      setTimeout(() => {
        this.isSaving = false
      }, 4000)
      this.resetDiscounts(this.cart.items)
      this.resetControls()
      this.computePrice()
    },
    onChangeDiscount (payload) {
      if (!this.$refs[`product-discount-${payload.uuid}`]) return
      const index = this.getIndexFromUUID(this.cart.items, payload.uuid)
      this.cart.items[index].main = this.$refs[`product-discount-${payload.uuid}`].build()
      this.computePrice()
    },
    onChangeInput (newValue, oldValue) {
      if (!isNaN(newValue)) {
        this.computePrice()
      }
    },
    onChangeQuantity (item) {
      if (item.quantity) {
        this.computePrice()
      }
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openProduct (id) {
      if (this.$refs['product-dialog']) this.$refs['product-dialog'].openId(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    refresh () {
      this.reload()
    },
    reload () {
      this.$nextTick(() => {
        try { this.$forceUpdate() } catch (error) {}
      })
    },
    resetDiscounts (productTree) {
      _.each(productTree, (value, key, product) => {
        if (key === 'per_discount') { product.per_discount = 0 }
        if (key === 'per_discount_setup') { product.per_discount_setup = 0 }
        if (key === 'custom_discounts') { product.custom_discounts = false }
        if (_.isObject(value)) this.resetDiscounts(value)
      })
    },
    resetControls () {
      // This will force nested components to update themselves
      this.expanded = {}
    },
    resetFields () {
      this.daysBegin = this.cart.nb_days_begin_delay
      this.daysBuilding = this.cart.nb_days_building_time
    },
    saveCart () {
      if (!this.canSave) return
      if (!this.pauseSaveCart) this.$emit('saveCart')
    },
    setCheckbox (item) {
      if (this.$refs[`product-discount-${item.uuid}`]) {
        this.$refs[`product-discount-${item.uuid}`].setCheckbox(!item.custom_discounts)
      }
    },
    showEstimatedComputation () {
      return this.daysBegin !== this.cart.nb_days_begin_delay || this.daysBuilding !== this.cart.nb_days_building_time
    },
    showItem (item) {
      this.$refs['cart-product-duplicate-dialog'].open(_.cloneDeep(item), { update: false, readonly: true, customer: this.cartCustomer })
        .then(({ product, main, quantity }) => {
          if (product) {
            item.caption = product.caption
            item.customer_caption = product.customer_caption
            item.main = _.cloneDeep(main)
            item.quantity = quantity
            this.computePrice()
          }
        })
    },
    sortNatural (items, index, isDescending) {
      const sort = {}
      sort[index] = isDescending ? 'desc' : 'asc'
      return this.$stratus.services.sort.natural(items, sort)
    }
  },
  async created () {
    this.onChangeInput = _.debounce(this.onChangeInput, 500)
    this.onChangeDiscount = _.debounce(this.onChangeDiscount, 500)
    if (!this.$store.getters['$alto-catalog/list'].length) await this.$store.dispatch('$alto-catalog/list')
  }
}
</script>
