<template>
  <csm-company-grid />
</template>

<script>
export default {
  name: 'CompaniesList',
  components: {
    'csm-company-grid': () => import(/* webpackChunkName: "components" */ '@/components/Companies/CompanyGrid')
  },
  data () {
    return {}
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Companies'))
  }
}
</script>
