<template>
  <sca-modal-dialog :visible="visible" :loading="isSaving" hide-close-button :is-saving="isSaving" @closeDialog="closeDialog" :max-width="$vuetify.breakpoint.lgAndUp ? '50%' : '100%'">
    <div slot="title" class="text-body-1 font-weight-bold">
      {{ $t('We\'d like to know how the handling of your recently closed ticket went.') }}
    </div>

    <div slot="content">
      <div v-if="!value || loading" class="mt-8 text-center">
        <cs-icon-loading show-text small left />
      </div>

      <v-row v-else align="start">
        <v-col v-if="value && value.answered_at" cols="12" class="d-flex justify-center">
          <cs-alert-panel type="info" :text="$t('A reply was received on {date}.', { date: answerDate })" icon="$vuetify.icons.success" />
        </v-col>

        <v-col>
          <v-row align="center" dense>
            <v-col class="d-flex align-center text-h6">
              <sca-ticket-identity ref="ticket-identity" :value="value.id_ticket" show-card show-name @loaded="setTicket" link="emit" @link-click="openTicket" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-form :disabled="readonly">
        <v-row align="center" dense justify="center" class="mb-2">
          <v-col v-if="ticket?.type === TICKET_TYPE_INCIDENT" cols="12">
            <div class="d-flex mt-1 align-center">
              <v-icon small left>
                $vuetify.icons.for
              </v-icon>
              {{ $t('Was the incident handled correctly by our teams?') }}"
              <div v-if="value && value.answered_at" class="ml-auto">
                <cs-yes-no-toggle :value="getValue('incident-handle')" dense single :disabled="readonly" />
              </div>
              <cs-yes-no-toggle v-else v-model="handled" dense class="ml-auto" :disabled="readonly" />
            </div>
            <div v-if="!handled" class="mt-4">
              {{ $t('If no, please give details') }}
              <div v-if="value && value.answered_at" class="ml-4">
                {{ getValue('bad-handle-incident-comment') || $t('None') }}
              </div>
              <v-textarea v-else v-model="details" outlined :rules="[$stratus.services.form.rules.max(2048)]" :counter="2048" />
            </div>
          </v-col>

          <v-col v-else-if="ticket?.type === TICKET_TYPE_PROJECT" cols="12" md="12">
            <div class="d-flex mt-3 align-center">
              <v-icon small left>
                $vuetify.icons.for
              </v-icon>
              {{ $t('Are you satisfied with the way the project went?') }}
              <div v-if="value && value.answered_at" class="ml-auto">
                <cs-yes-no-toggle :value="getValue('project-appreciation')" dense single :disabled="readonly" />
              </div>
              <cs-yes-no-toggle v-else v-model="handled" dense class="ml-auto" :disabled="readonly" />
            </div>

            <div class="d-flex mt-3 align-center">
              <v-icon small left>
                $vuetify.icons.for
              </v-icon>
              {{ $t('Did our team meet your requirements?') }}
              <div v-if="value && value.answered_at" class="ml-auto">
                <cs-yes-no-toggle :value="getValue('needs-respected')" dense single :disabled="readonly" />
              </div>
              <cs-yes-no-toggle v-else v-model="projectNeedRespected" dense class="ml-auto" :disabled="readonly" />
            </div>

            <div class="d-flex mt-3 align-center">
              <v-icon small left>
                $vuetify.icons.for
              </v-icon>
              {{ $t('Does Scalair\'s service meet expectations?') }}
              <div v-if="value && value.answered_at" class="ml-auto">
                <cs-yes-no-toggle :value="getValue('service')" dense single :disabled="readonly" />
              </div>
              <cs-yes-no-toggle v-else v-model="projectService" dense class="ml-auto" :disabled="readonly" />
            </div>

            <div class="d-flex mt-3 align-center">
              <v-icon small left>
                $vuetify.icons.for
              </v-icon>
              {{ $t('Is the quality of Scalair\'s support satisfactory (visibility, exchanges, responsiveness, etc.)') }}
              <div v-if="value && value.answered_at" class="ml-auto">
                <cs-yes-no-toggle :value="getValue('accompanying')" dense single :disabled="readonly" />
              </div>
              <cs-yes-no-toggle v-else v-model="projectAccompanying" dense class="ml-auto" :disabled="readonly" />
            </div>

            <div class="d-flex mt-3 align-center">
              <v-icon small left>
                $vuetify.icons.for
              </v-icon>
              {{ $t('Were Scalair\'s commitments respected (response times, appointments, planning, etc.)') }}
              <div v-if="value && value.answered_at" class="ml-auto">
                <cs-yes-no-toggle :value="getValue('commitment')" dense single :disabled="readonly" />
              </div>
              <cs-yes-no-toggle v-else v-model="projectCommitment" dense class="ml-auto" :disabled="readonly" />
            </div>

            <div class="mt-4">
              {{ $t('Comments on project progress') }}
              <div v-if="value && value.answered_at" class="ml-4">
                {{ getValue('project-comment') || $t('None') }}
              </div>
              <v-textarea v-else v-model="details" outlined :rules="[$stratus.services.form.rules.max(2048)]" :counter="4096" />
            </div>
          </v-col>

          <v-col v-else-if="ticket?.type === TICKET_TYPE_REQUEST" cols="12">
            <div class="d-flex mt-1 align-center">
              <v-icon small left>
                $vuetify.icons.for
              </v-icon>
              {{ $t('Was the request handled correctly by our teams?') }}
              <div v-if="value && value.answered_at" class="ml-auto">
                <cs-yes-no-toggle :value="getValue('request-handle')" dense single :disabled="readonly" />
              </div>
              <cs-yes-no-toggle v-else v-model="handled" dense class="ml-auto" :disabled="readonly" />
            </div>
            <div v-if="!handled" class="mt-4">
              {{ $t('If no, please give details') }}
              <div v-if="value && value.answered_at" class="ml-4">
                {{ getValue('bad-handle-request-comment') || $t('None') }}
              </div>
              <v-textarea v-else v-model="details" outlined :rules="[$stratus.services.form.rules.max(2048)]" :counter="2048" />
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <template v-if="!readonly && value && !value.answered_at" slot="buttons">
      <v-btn rounded text @click="doNotReply">
        {{ $t('Do not reply') }}
      </v-btn>
      <v-btn rounded class="main-button" @click="reply">
        {{ $t('Send reply') }}
      </v-btn>

      <csm-ticket-dialog ref="ticket-dialog-secondary" />
    </template>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'TicketQuestionnaireForm',
  props: {
    loading: { type: Boolean, default: false },
    value: { type: Object, default: null },
    readonly: { type: Boolean, default: false },
    visible: { type: Boolean, default: false }
  },
  data () {
    return {
      TICKET_TYPE_INCIDENT: this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT,
      TICKET_TYPE_PROJECT: this.$alto.defines.TICKETS.TICKET_TYPE_PROJECT,
      TICKET_TYPE_REQUEST: this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST,
      details: '',
      handled: true,
      isSaving: false,
      projectNeedRespected: true,
      projectService: true,
      projectAccompanying: true,
      projectCommitment: true,
      ticket: null
    }
  },
  computed: {
    answerDate () {
      return this.$stratus.dt(this.value.answered_at).format('LL')
    }
  },
  methods: {
    async closeDialog (applied) {
      if (!this.readonly && !applied && !this.value?.answered_at) {
        try {
          await this.$store.dispatch('$alto-ticketing/questionnaireRead', { id: this.value.id })
        } catch (error) {
          this.$stratus.services.notify.error(error)
        }
      }
      this.$emit('closeDialog')
    },
    convert (question, response) {
      if (question.type === 'string') return response || ''
      if (question.type === 'enum') return response === true ? 'yes' : 'no'
    },
    async doNotReply () {
      try {
        await this.$store.dispatch('$alto-ticketing/questionnaireRefused', { id: this.value.id })
        this.$emit('refused', this.value)
        this.closeDialog(true)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    getValue (key) {
      const answer = _.find(this.value.answers, { question: key })
      return answer?.value === 'yes'
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog-secondary']) this.$refs['ticket-dialog-secondary'].open(ticket.id)
    },
    async reply () {
      try {
        const data = {}
        switch (this.ticket.type) {
          case this.TICKET_TYPE_INCIDENT:
            data['incident-handle'] = this.handled
            data['bad-handle-incident-comment'] = this.details
            break
          case this.TICKET_TYPE_PROJECT:
            data['project-appreciation'] = this.handled
            data['needs-respected'] = this.projectNeedRespected
            data.service = this.projectService
            data.accompanying = this.projectAccompanying
            data.commitment = this.projectCommitment
            data['project-comment'] = this.details
            break
          case this.TICKET_TYPE_REQUEST:
            data['request-handle'] = this.handled
            data['bad-handle-request-comment'] = this.details
            break
          default:
            this.closeDialog()
        }

        const answers = []
        _.forEach(this.value.answers, answer => {
          answers.push({
            index: answer.index,
            value: this.convert(answer, data[answer.question])
          })
        })

        await this.$store.dispatch('$alto-ticketing/questionnaireReplied', { id: this.value.id, answers })
        this.$emit('replied', this.value)
        this.closeDialog(true)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    reset () {
      this.details = ''
      this.handled = true
      this.isSaving = false
      this.projectNeedRespected = true
      this.projectService = true
      this.projectAccompanying = true
      this.projectCommitment = true
    },
    setTicket (ticket) {
      this.ticket = ticket
    }
  }
}
</script>
