<template>
  <v-row>
    <v-col cols="12">
      <sca-advanced-store-grid v-if="columns" :options="gridOptions" :resource="resourceId" :store-suffix="uuid" :pause="gridOnPause" :columns="columns" :custom-search="customSearch" @createItem="createItem" @dataLoaded="dataLoaded" @deleteItem="deleteItem" @selectItems="selectItems" @showItem="showItem" @updateItem="updateItem" @resetFilters="resetFilters" :ref="`company-grid-${uuid}`">
        <div v-if="isLord" slot="search-append" class="d-flex align-center">
          <v-btn-toggle v-model="filterBy" dense class="transparent" @change="refreshGrid(true)">
            <v-btn small text :value="FILTER_CUSTOMER_COMPANIES" :class="filterBy === FILTER_CUSTOMER_COMPANIES ? 'button-main button-main-ink--text' : ''">
              {{ $t('Customer companies') }}
            </v-btn>
            <v-btn small text v-if="isLord && me?.lord_team_id" :value="FILTER_COMPANIES_WITH_CSM" :class="filterBy === FILTER_COMPANIES_WITH_CSM ? 'button-main button-main-ink--text' : ''">
              {{ $t('Companies with CSM') }}
            </v-btn>
            <v-btn small text :value="FILTER_COMPANIES_IN_TOP" :class="filterBy === FILTER_COMPANIES_IN_TOP ? 'button-main button-main-ink--text' : ''">
              {{ $t('Top 70') }}
            </v-btn>
            <v-btn small text :value="FILTER_NONE" :class="filterBy === FILTER_NONE ? 'button-main button-main-ink--text' : ''">
              {{ $t('All') }}
            </v-btn>
          </v-btn-toggle>

          <div class="ml-8 mr-1">
            {{ $t('Display') }}
          </div>
          <v-btn-toggle v-if="showLastContactFilter" v-model="filterLastContactBy" multiple dense class="transparent" @change="refreshGrid(true)">
            <v-btn small text :value="FILTER_LAST_CONTACT_CALL" :class="filterLastContactBy.includes(FILTER_LAST_CONTACT_CALL) ? 'button-main button-main-ink--text' : ''">
              {{ $t('Last call') }}
            </v-btn>
            <v-btn small text v-if="isLord && me?.lord_team_id" :value="FILTER_LAST_CONTACT_EVENT" :class="filterLastContactBy.includes(FILTER_LAST_CONTACT_EVENT) ? 'button-main button-main-ink--text' : ''">
              {{ $t('Last event') }}
            </v-btn>
            <v-btn small text :value="FILTER_LAST_CONTACT_EMAIL" :class="filterLastContactBy.includes(FILTER_LAST_CONTACT_EMAIL) ? 'button-main button-main-ink--text' : ''">
              {{ $t('Last email') }}
            </v-btn>
          </v-btn-toggle>
        </div>

        <template v-if="isLord" slot="search-row">
          <v-row dense align="baseline">
            <v-col lg="3">
              <sca-customer-manager-select v-model="managerSearch" clearable :label="$t('Service Manager')" @input="refreshGrid" link="emit" @link-click="openUser(managerSearch)" />
            </v-col>
            <v-col lg="3">
              <sca-sales-person-select v-model="salesPersonSearch" clearable :label="$t('Sales Person')" @input="refreshGrid" link="emit" @link-click="openUser(salesPersonSearch)" />
            </v-col>
            <v-col lg="3">
              <sca-company-type-select v-model="type" clearable @input="refreshGrid" />
            </v-col>
            <v-col lg="3">
              <v-autocomplete v-model="businessSector" :items="listBusinessSectors" clearable :label="$t('Business sector')" @input="refreshGrid" />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item-code="{ itemRaw }">
          <sca-company-identity :value="itemRaw" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(itemRaw)" />
        </template>

        <template v-slot:item-id_lord_manager="{ itemRaw }">
          <sca-user-identity :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
        </template>

        <template v-slot:item-id_lord_one_of_the_top="{ itemRaw }">
          <sca-user-identity :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
        </template>

        <template v-slot:item-id_lord_salesperson="{ itemRaw }">
          <sca-user-identity :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
        </template>

        <template v-slot:item-last_contact="{ itemRaw }">
          <v-chip label outlined small v-if="itemRaw?.id && itemRaw?.type && itemRaw?.date" @click.stop="openFollowup(itemRaw.type, itemRaw.id)">
            <v-icon small left>
              {{
                itemRaw.type === FILTER_LAST_CONTACT_CALL ? '$vuetify.icons.followUpCall' : (
                  itemRaw.type === FILTER_LAST_CONTACT_EVENT ? '$vuetify.icons.followUpEvent' : (
                    itemRaw.type === FILTER_LAST_CONTACT_EMAIL ? '$vuetify.icons.followUpEmail' : '$vuetify.icons.unknown'
                  )
                )
              }}
            </v-icon>
            {{ $stratus.dt(itemRaw.date).format('LL LT') }}
          </v-chip>
          <span v-else>
            {{ $t('None') }}
          </span>
        </template>
      </sca-advanced-store-grid>
    </v-col>

    <csm-user-dialog ref="user-dialog" />
    <csm-company-dialog ref="company-dialog" @closeDialog="closeDialog" />
    <csm-followup-call-dialog ref="company-followup-call-dialog" update @close="closeDialog" />
    <csm-followup-email-dialog ref="company-followup-email-dialog" update @close="closeDialog" />
    <csm-followup-event-dialog ref="company-followup-event-dialog" update @close="closeDialog" />
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

const FILTER_COMPANIES_IN_TOP = 1
const FILTER_CUSTOMER_COMPANIES = 2
const FILTER_COMPANIES_WITH_CSM = 3
const FILTER_NONE = 10

const FILTER_LAST_CONTACT_CALL = 'calls'
const FILTER_LAST_CONTACT_EMAIL = 'emails'
const FILTER_LAST_CONTACT_EVENT = 'events'

export default {
  name: 'CompanyGrid',
  components: {
    'csm-followup-call-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpCallDialog'),
    'csm-followup-email-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpEmailDialogLite'),
    'csm-followup-event-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpEventDialog')
  },
  props: {
    options: { type: Object, default: () => {} },
    prefKey: { type: String, default: 'companyGrid' },
    showLastContactFilter: { type: Boolean, default: false }
  },
  data () {
    return {
      FILTER_COMPANIES_IN_TOP,
      FILTER_COMPANIES_WITH_CSM,
      FILTER_CUSTOMER_COMPANIES,
      FILTER_LAST_CONTACT_CALL,
      FILTER_LAST_CONTACT_EMAIL,
      FILTER_LAST_CONTACT_EVENT,
      FILTER_NONE,
      TYPE_SUSPECT: this.$alto.defines.COMPANIES.TYPE_SUSPECT,
      businessSector: null,
      columns: null,
      company: {},
      companyCode: null,
      filterBy: null,
      filterLastContactBy: [FILTER_LAST_CONTACT_CALL, FILTER_LAST_CONTACT_EVENT],
      gridOnPause: true,
      isLogged: false,
      listBusinessSectors: [],
      listTypes: [],
      managerSearch: '',
      resourceId: 'companies',
      salesPersonSearch: '',
      type: null,
      updateCompanies: false,
      uuid: this.$stratus.uuid()
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord',
      preferences: '$alto-preferences/preferences',
      ticketStateMandatoryFields: '$alto-ticketing/ticketStateMandatoryFields'
    }),
    gridOptions () {
      return {
        advancedSearchFieldsSchemaBaseUrl: '/docs/companies',
        advancedSearchFieldsSchemaName: 'Company',
        allowContextMenuOnCell: true,
        allowColumnsVisible: true,
        create: this.isLogged && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.COMPANIES, this.$alto.API_PERMISSIONS.CREATE),
        debounce: {
          onList: 500 // Request rate is one every 500ms
        },
        delete: false,
        csvAppendFields: ['name'],
        foreignFields: ['code', 'code_no_customer', 'name', 'tags', 'business_sector'],
        foreignFieldsFilter: true,
        foreignFieldsAdvancedSearch: true,
        key: 'code',
        messages: {
          deleteItem: 'Please, confirm the deletion of company {code} — {name}?'
        },
        multiSelect: false,
        notifyErrors: true,
        queryAddRaw: this.queryContact,
        search: this.isLogged,
        searchVisibleColumns: false,
        show: false,
        sortBy: 'update_at',
        sortDescending: true,
        uuid: this.$stratus.uuid(),
        update: false,
        ...this.options
      }
    }
  },
  methods: {
    async closeDialog ({ company, saved }) {
      if (saved) {
        await this.$store.dispatch('$alto-companies/getCache', true) // Reload cache
        if (this.$refs[`company-grid-${this.uuid}`]) this.$refs[`company-grid-${this.uuid}`].fetchData({ forceReload: true })
      }
    },
    createColumns () {
      let cols = [{
        text: 'Company',
        value: 'code'
      }, {
        text: 'Type',
        value: 'type',
        format: v => {
          return v ? this.$t(`company-type-${v.toLowerCase()}`) : this.$t('Undefined')
        }
      }, {
        text: 'Business sector',
        value: 'business_sector',
        format: v => {
          return v ? this.$t(`business_sector-${v}`) : this.$t('Undefined')
        }
      }, {
        text: 'SIREN',
        value: 'siren',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.FALSY_AS_EMPTY
      }]

      if (this.isLord) {
        cols = cols.concat([{
          text: 'Scalair Manager (top)',
          value: 'id_lord_one_of_the_top'
        }, {
          text: 'Sales Person',
          value: 'id_lord_salesperson'
        }, {
          text: 'Service Manager',
          value: 'id_lord_manager',
          hidden: true
        }, {
          text: 'Top 70',
          value: 'one_of_the_top',
          hidden: true,
          format: this.$stratus.services.fieldRenderers.BOOLEAN_CHECK_IF_TRUE
        }])

        if (this.showLastContactFilter) {
          cols = cols.concat([{
            text: 'Last contact',
            value: 'last_contact',
            sortable: false
          }])
        }
      }

      cols = cols.concat([{
        text: 'Updated at',
        align: 'right',
        alignValue: 'right',
        value: 'update_at',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }])
      return cols
    },
    customSearch () {
      const cusSearch = []
      cusSearch.raw = ''

      if (this.managerSearch) {
        cusSearch.raw += `id_lord_manager=${this.managerSearch}||id_lord_one_of_the_top=${this.managerSearch}`
      }
      if (this.salesPersonSearch) {
        cusSearch.push({
          column: 'id_lord_salesperson',
          search: this.salesPersonSearch,
          operator: ''
        })
      }
      if (this.type) {
        cusSearch.push({
          column: 'type',
          search: this.type,
          operator: ''
        })
      }
      if (this.businessSector) {
        cusSearch.push({
          column: 'business_sector',
          search: this.businessSector,
          operator: ''
        })
      }

      switch (this.filterBy) {
        case FILTER_COMPANIES_IN_TOP:
          cusSearch.push({ column: 'one_of_the_top', search: true, operator: '' })
          break
        case FILTER_COMPANIES_WITH_CSM:
          if (this.me.lord_team_id) cusSearch.push({ column: 'id_lord_manager', search: null, operator: 'ne' })
          break
        case FILTER_CUSTOMER_COMPANIES:
          cusSearch.push({ column: 'type', search: this.$alto.defines.COMPANIES.CUSTOMER_COMPANY_TYPES, operator: 'in' })
          break
      }

      return cusSearch
    },
    async createItem () {
      /*
      * Set default attributes this new company
      */
      this.company = {
        blocked_payment: false,
        h_country: 'FRA',
        mail_accounting: [],
        type: this.TYPE_SUSPECT,
        parent: this.$store.getters.appConfig.defaults.parentCompanyCode || '',
        payment_terms: this.$alto.defines.COMPANIES.DEFAULT_PAYMENT_TERM
      }
      // If current session is a user that is a Scalair Sales person then, for this new company, we put this user as the company sales person.
      if (this.isLord && this.me && this.me.role === this.$alto.USER_ROLES.BUSINESS) {
        const salesPerson = this.$store.getters['$alto-users/getSalesPersonByEmail'](this.me.email)
        if (salesPerson) this.company.id_lord_salesperson = salesPerson.id
      }
      this.openCompany(this.company)
    },
    dataLoaded ({ success, error, items }) {
      if (error) {
        console.error(error)
      }
    },
    deleteItem ({ success, error, item }) {
      this.$stratus.services.notify.success(this.$t('Company {code} — {name} deleted.', item))
      if (this.$refs[`company-grid-${this.uuid}`]) this.$refs[`company-grid-${this.uuid}`].fetchData()
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openFollowup (type, id) {
      if (type === FILTER_LAST_CONTACT_CALL && this.$refs['company-followup-call-dialog']) this.$refs['company-followup-call-dialog'].openId(id)
      if (type === FILTER_LAST_CONTACT_EMAIL && this.$refs['company-followup-email-dialog']) this.$refs['company-followup-email-dialog'].openId(id)
      if (type === FILTER_LAST_CONTACT_EVENT && this.$refs['company-followup-event-dialog']) this.$refs['company-followup-event-dialog'].openId(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    queryContact (gridQuery, raw, grid) {
      // Use raw because last-contact is a field that does mot exists in companies
      let result = raw || ''

      if (this.showLastContactFilter) {
        result += `&last-contact=${this.filterLastContactBy.join(',')}`
      }
      return result
    },
    refreshGrid (savePrefs = false) {
      if (savePrefs) this.savePrefs()
      if (this.$refs[`company-grid-${this.uuid}`]) this.$refs[`company-grid-${this.uuid}`].fetchData()
    },
    resetFilters () {
      this.managerSearch = ''
      this.salesPersonSearch = ''
    },
    async savePrefs () {
      try {
        if (!this.prefKey) return
        if (!this.preferences[this.prefKey]) this.preferences[this.prefKey] = {}
        this.preferences[this.prefKey].filterBy = this.filterBy
        await this.$store.dispatch('$alto-preferences/save', { [this.prefKey]: this.preferences[this.prefKey] })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    selectItems (items) {
      this.selection = items
    },
    showItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.openCompany(data.item)
    },
    updateItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.openCompany(data?.item?.code || data.item)
    }
  },
  created () {
    this.isLogged = this.$stratus.services.auth.isLogged()
  },
  async mounted () {
    await this.$store.dispatch('$stratus-states/getMe')
    if (this.$alto.services.routes.connectionForbidden.call(this, this.me)) this.$router.push('/').catch((error) => { console.warn(error) })
    if (this.isLord) await this.$store.dispatch('$alto-users/loadSalesPersons')
    await this.$store.dispatch('$alto-companies/loadBusinessSectors')
    this.listBusinessSectors = this.$store.getters['$alto-companies/businessSectors']
    this.columns = this.createColumns()

    await this.$store.dispatch('$alto-preferences/load')
    this.filterBy = this.preferences?.[this.prefKey]?.filterBy || FILTER_CUSTOMER_COMPANIES
    this.$nextTick(() => {
      if (this.$refs[`company-grid-${this.uuid}`]) this.$refs[`company-grid-${this.uuid}`].fetchData({ forceReload: true })
      this.gridOnPause = false
    })
  }
}
</script>
