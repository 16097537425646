<template>
  <sca-modal-dialog :visible="visible" :loading="isLoading || loading || isSaving" @closeDialog="closeDialog" @saveDialog="saveDialog(true, $event)" :action="action" :can-save="canSave && !isLoading && !loadingCache" :is-saving="isSaving" :external-id="externalId" :max-width="$vuetify.breakpoint.xl ? '85%' : '100%'">
    <v-row slot="title" align="center" dense>
      <v-col v-if="ticket?.type === TICKET_TYPE_REQUEST && ticket.id_project_ticket" class="shrink text-no-wrap">
        <sca-ticket-identity :value="ticket.id_project_ticket" large-button dense show-type show-state show-severity link="emit" @link-click="openTicket" />
      </v-col>
      <v-col v-if="ticket?.type === TICKET_TYPE_REQUEST && ticket.id_project_ticket" class="shrink text-no-wrap">
        <v-icon small>
          icon-long-arrow-right
        </v-icon>
      </v-col>
      <v-col class="shrink text-no-wrap">
        <span class="text-font-fixed">{{ fullId }}</span> {{ isDirty() ? '*' : '' }}
      </v-col>
      <v-col class="shrink text-no-wrap">
        <span> — {{ this.$t('Ticket') }}</span>
      </v-col>
    </v-row>

    <div slot="subtitle" class="d-flex align-center">
      <cs-alert-panel v-if="isLord && blockedPayment && ticket?.type !== TICKET_TYPE_PRE_SALE" dense type="warning" :text="$t('Be careful: Payment is blocked by this company! Do not issue any order!')" />
    </div>

    <template #buttons-header>
      <cs-action-menu icon="$vuetify.icons.follow" help="Tracking" :text="ticket.ids_observers?.length">
        <v-card slot="menu" tile class="pa-2">
          <v-row dense>
            <v-btn v-if="itsNotTrackedByMe" block rounded @click="followThisTicket(true)">
              <v-icon small left>
                $vuetify.icons.follow
              </v-icon>
              {{ $t('Follow') }}
            </v-btn>
            <v-btn v-else block rounded class="text-body-1" @click="followThisTicket(false)">
              <v-icon small left>
                $vuetify.icons.unfollow
              </v-icon>
              {{ $t('Un-follow') }}
            </v-btn>
          </v-row>

          <v-row align="center" dense>
            <v-divider class="my-3" />
            <div v-if="isLord || ticket?.ids_observers?.length > 0" class="mt-2 mx-2">
              {{ $t('Trackers') }}
            </div>
            <v-divider class="my-3" />

            <v-col v-if="isLord" cols="12">
              <sca-users-select v-model="ticket.ids_observers" :filter-company="companies" multiple forbid-remove no-bot show-company show-card dense hide-details link="emit" @link-click="openUser" @change="onTicketObserversChange" />
            </v-col>

            <v-col v-else cols="12">
              <div v-for="userId in ticket.ids_observers" :key="`observer-${userId}`">
                <sca-user-identity :value="userId" show-card show-avatar show-company show-role dense class="mt-1" link="emit" @link-click="openUser(userId)" />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </cs-action-menu>
    </template>

    <div slot="content">
      <!-- Two columns -->
      <v-row>
        <!-- First column (largest) -->
        <v-col cols="12" md="7" lg="8" xl="9">
          <v-form ref="ticket-form-col-1" v-model="valid" lazy-validation :disabled="!canSave">
            <v-row align="center">
              <v-col class="shrink">
                <sca-ticket-type-select v-if="canChangeType" v-model="ticket.type" :label="$t('Type')" :disabled="!isLord || !canSave" @change="onTicketTypeChange" />
                <sca-ticket-type v-else :value="ticket.type" large show-label />
              </v-col>

              <v-col class="mx-2">
                <v-text-field v-model="ticket.name" :label="$t('Title')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(128)]" class="text-h5 required" counter="128" />
              </v-col>
            </v-row>

            <!-- DESCRIPTION -->
            <v-row dense>
              <v-col cols="12">
                <cs-expand-panel block expanded>
                  <template #header="{ expanded }">
                    {{ $t('Description') }}
                    <div v-if="expanded && canSave" class="ml-auto">
                      <v-btn icon @click.stop="canEditDescription = !canEditDescription">
                        <v-icon small>
                          $vuetify.icons.edit
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <v-card slot="content" :outlined="!canEditDescription" :flat="canEditDescription" tile>
                    <sca-wysiwyg-editor ref="ticket-description-editor" v-model="ticket.description" :placeholder="$t('Description')" :customers="ticket.code" :disabled="!canSave || !canEditDescription" :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" :medium="$vuetify.breakpoint.lgAndDown" :x-large="$vuetify.breakpoint.xl" highlight allow-image @add-image="onAddImage" @drop-paste-image="onDropPasteImage" />
                  </v-card>
                </cs-expand-panel>
              </v-col>
            </v-row>

            <!-- ATTACHMENTS -->
            <v-row dense>
              <v-col cols="12">
                <cs-expand-panel :header="$t('Attachments')" :count="ticket?.attachments?.length" block>
                  <template v-if="canSave" slot="header">
                    <div class="ml-auto">
                      <sca-ticket-attachments :ticket="ticket" :before-send="attachmentsBeforeSend" @send="attachmentsSend" :disabled="!canSave" />
                    </div>
                  </template>

                  <v-sheet v-if="ticket?.id" slot="content">
                    <cs-attachments-list :id="ticket.id" :value="ticket.attachments" dense can-insert-image can-download thumbnail :can-delete="canSave" @delete="deleteAttachments" @insert-image="insertImage" />
                  </v-sheet>
                </cs-expand-panel>
              </v-col>
            </v-row>

            <!-- SUB TASKS -->
            <v-row v-if="ticket?.type === TICKET_TYPE_PROJECT" dense>
              <v-col cols="12">
                <cs-expand-panel :header="$t('Sub-tasks')" :count="ticket.ids_project_sub_tickets?.length" block>
                  <template v-if="isLord && canSave" slot="header">
                    <div class="ml-auto">
                      <sca-button-assign-to-me v-if="ticket.ids_project_sub_tickets?.length > 0" x-small rounded :disabled="!canSave" :help="$t('Assign me all sub-tasks')" @click="assignAllSubtask" />

                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon rounded :disabled="loading" v-on="on" @click.stop="showSubTaskEditor = !showSubTaskEditor">
                            <v-icon :color="showSubTaskEditor ? 'primary' : 'menu-icon lighten-2'">
                              $vuetify.icons.add
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('New sub-task') }}</span>
                      </v-tooltip>
                    </div>
                  </template>

                  <v-row v-if="isLord && showSubTaskEditor" slot="header-append" align="center" class="ma-0 pa-0">
                    <v-col class="shrink">
                      <sca-ticket-severity-select v-model="subTaskSeverity" />
                    </v-col>

                    <v-col>
                      <v-text-field v-model="subTaskName" :ref="`sub-task-name-input-${uuid}`" :label="$t('Title of the new sub-task...')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(128)]" class="required" counter="128" :disabled="isSaving" @keyup.enter="saveSubTask" @focus="$event.target.select()" />
                    </v-col>

                    <v-col class="shrink ml-auto d-flex">
                      <v-btn rounded small class="main-button" :disabled="isSaving || subTaskName.length === 0" :loading="isSaving" @click="saveSubTask">
                        {{ $t('Add') }}
                      </v-btn>
                      <v-btn rounded small :disabled="isSaving" @click="showSubTaskEditor = false">
                        {{ $t('Cancel') }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <template slot="content">
                    <v-row v-show="isLord && canSave" dense align="center">
                      <v-col>
                        <sca-tickets-select v-model="newSubTicketLinkIds" :label="$t('Add sub-task from list')" :company="ticket.code" :ignore="ticketLinksIds.concat(ticket.ids_project_sub_tickets)" :types="TICKET_TYPE_REQUEST" class="mr-2" clearable hide-details dense multiple :disabled="!canSave" />
                      </v-col>
                      <v-col class="shrink">
                        <v-btn rounded small class="main-button" :disabled="!canSave || newSubTicketLinkIds?.length === 0" @click="addSubTaskLink">
                          {{ $t('Add') }}
                        </v-btn>
                      </v-col>
                    </v-row>

                    <div class="mt-2">
                      <sca-ticket-list-panel :value="ticket.project_sub_tickets" :can-delete="isLord && canSave" :can-open="isLord" dense :link="isLord ? 'emit' : false" @link-click="openTicket" show-state show-severity show-time @link-delete="delSubTaskLink" />
                    </div>
                  </template>
                </cs-expand-panel>
              </v-col>
            </v-row>
          </v-form>

          <!-- SUB TICKETS -->
          <v-row v-if="isLord && ticket?.code && ticket?.type !== TICKET_TYPE_PRE_SALE" dense class="mt-2">
            <v-col cols="12">
              <cs-expand-panel :header="$t('ticket-link-tickets')" :count="ticketLinksCount" block>
                <template slot="content">
                  <v-row v-show="canSave" dense align="center">
                    <v-col cols="12" md="6" lg="3">
                      <sca-ticket-link-type-select v-model="newTicketLinkType" :label="$t('Link type')" class="mr-2" :disabled="!canSave" hide-details dense />
                    </v-col>
                    <v-col>
                      <sca-tickets-select v-model="newTicketLinkIds" :label="$t('Link to a ticket')" :ignore="[ticket.id].concat(ticket.ids_project_sub_tickets).concat(ticketLinksIds)" :customers="linkedTicketCompanies" class="mr-2" clearable hide-details dense multiple :disabled="!canSave" />
                    </v-col>
                    <v-col class="shrink">
                      <v-btn rounded small class="main-button" :disabled="!canSave || !newTicketLinkType || newTicketLinkIds?.length === 0" @click="addTicketLink">
                        {{ $t('Add') }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <div v-for="link in ticketLinks" :key="link" class="mt-2">
                    <cs-expand-panel v-if="ticket.ids_linked_tickets?.[link]?.length" :header="$t(`ticket-link-type-${link}`)" :count="ticket.ids_linked_tickets[link].length" dense block expanded>
                      <sca-ticket-list-panel slot="content" :ref="`${link}-${ticketLinksUuid}`" :value="ticket.ids_linked_tickets[link]" :can-delete="canSave" can-open dense link="emit" @link-click="openTicket" show-state show-severity @link-delete="delTicketLink(link, $event)" />
                    </cs-expand-panel>
                  </div>
                </template>
              </cs-expand-panel>
            </v-col>
          </v-row>

          <!-- ACTIVITES -->
          <v-row v-if="ticket.id" dense class="mt-2">
            <v-col cols="12">
              <sca-ticket-activities ref="ticket-activities" :value="ticket" can-use-highlight :can-edit="canSave" link="emit" :goto-id="commentId" @link-click="openTicket" @change="onActivityChange" />
            </v-col>
          </v-row>
        </v-col>

        <!-- Second column -->
        <v-col cols="12" md="5" lg="4" xl="3">
          <v-form ref="ticket-form-col-2" v-model="valid" lazy-validation :disabled="!canSave">
            <!-- STATE -->
            <div class="d-flex align-center mb-3">
              <div class="shrink text-no-wrap">
                <sca-ticket-state :value="ticket.state" show-label :disabled="!isLord || !canSave" />
              </div>

              <div v-if="isLord" class="shrink">
                <sca-ticket-state-toggle ref="ticket-state-toggle" v-model="newTicketState" :graph="stateGraph" text dense :disabled="loadingReasonsCache || !canSave || !ticket.id" @input="onTicketStateChange">
                  <template v-slot:buttons-prepend="{ items }">
                    <v-icon v-show="items?.length" small>
                      icon-long-arrow-right
                    </v-icon>
                  </template>
                </sca-ticket-state-toggle>
              </div>
              <div cols="12" v-else-if="ticket.id && (waitingReasonRequired || interventionDateRequired)">
                <v-icon small left right>
                  icon-long-arrow-right
                </v-icon>
                {{ $t(`ticket-waiting-reason-${ticket.waiting_reason}`) }}
              </div>

              <div v-if="canSave && canBypassState && ticket.id" class="ml-auto">
                <sca-ticket-state-select v-model="newTicketState" :graph="graph" menu flat @input="onTicketStateChange" />
              </div>
            </div>

            <!-- RESOLUTION / WAITING REASON -->
            <v-row dense>
              <v-col v-if="isLord && ticket.type === TICKET_TYPE_FACT && !ticket.id" cols="12" class="d-flex align-center">
                <div class="mr-4 text-body-1">
                  {{ $t('Intervention date') }}
                </div>
                <div>
                  <cs-date-picker v-model="ticket.date_intervention" :disabled="!canSave" :rules="[$stratus.services.form.rules.required]" class="required" />
                </div>
              </v-col>

              <v-col cols="12" v-if="isLord && ticket.id && (waitingReasonRequired || interventionDateRequired)" class="px-4">
                <sca-ticket-waiting-reason-select v-if="waitingReasonRequired" v-model="ticket.waiting_reason" :label="$t('Waiting reason')" :ticket-type="ticket.type" :rules="waitingReasonRules" :class="waitingReasonRequired" dense :disabled="loadingReasonsCache ||!canSave" />
                <cs-date-time-picker v-if="interventionDateRequired" :label="$t('Intervention date')" v-model="ticket.date_intervention" :rules="[$stratus.services.form.rules.required]" class="required" dense :disabled="loadingReasonsCache || !canSave" />
              </v-col>

              <v-col cols="12" v-if="ticket.id && ticket.state !== TICKET_STATE_CANCELED && resolutionReasonRequired(ticket.state)" class="d-flex align-center px-4">
                {{ $t('Resolution') }}
                <v-icon small left right>
                  icon-long-arrow-right
                </v-icon>
                <sca-ticket-resolution-select v-if="canUpdateBillingFields" v-model="ticket.resolution_reason" :disabled="loadingReasonsCache" :graph="stateGraph" :ticket-state="ticket.state" :ticket-type="ticket.type" />
                <span v-else>
                  {{ $t(`ticket-resolution-reason-${ticket.resolution_reason}`) }}
                </span>
              </v-col>

              <v-col cols="12" v-if="ticket.date_closure" class="px-4">
                {{ $t('Closure date') }}
                <v-icon small>
                  icon-long-arrow-right
                </v-icon>
                {{ $stratus.dt(ticket.date_closure).format('LL LT') }}
              </v-col>
            </v-row>

            <!-- DETAILS -->
            <v-card outlined tile class="pa-2 mt-4">
              <cs-expand-panel :header="$t('Details')" block expanded>
                <template slot="content">
                  <v-row dense>
                    <v-col class="shrink">
                      {{ $t('Company') }}
                    </v-col>

                    <v-col>
                      <div class="d-flex align-center">
                        <div class="text-truncate">
                          <sca-company-identity v-if="update" :value="ticket.code" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(ticket.code)" />
                          <sca-customer-select v-else v-model="ticket.code" :rules="[$stratus.services.form.rules.required]" class="required" :customers-only="ticket?.type !== TICKET_TYPE_PRE_SALE" dense hide-details link="emit" @link-click="openCompany(ticket.code)" />
                        </div>

                        <v-btn v-if="canUpdateCompany" icon small @click="showChangeCompanyInput = !showChangeCompanyInput">
                          <v-icon color="menu-icon" :class="showChangeCompanyInput ? 'rotate-once-180' : ''" small>
                            $vuetify.icons.expand
                          </v-icon>
                        </v-btn>

                        <v-tooltip v-if="isLord && ticket.private" top>
                          <template v-slot:activator="{ on }">
                            <v-icon small class="ml-4" v-on="on">
                              $vuetify.icons.private
                            </v-icon>
                          </template>
                          {{ $t('Private') }}
                        </v-tooltip>
                      </div>

                      <v-expand-transition>
                        <div v-if="canUpdateCompany && showChangeCompanyInput" class="pb-4">
                          {{ $t('Change to company:') }}
                          <sca-customer-select v-model="newCompany" :rules="[$stratus.services.form.rules.required]" class="required" :customers-only="ticket?.type !== TICKET_TYPE_PRE_SALE" dense hide-details link="emit" @link-click="openCompany(newCompany)" @change="onChangeCompany" />
                        </div>
                      </v-expand-transition>
                    </v-col>
                  </v-row>

                  <v-row v-if="!isCorporateTicket && (ticket.origin || !ticket.id) && ticket?.type !== TICKET_TYPE_PRE_SALE" dense>
                    <v-col class="shrink">
                      {{ $t('Origin') }}
                    </v-col>

                    <v-col v-if="!ticket.id">
                      <v-select v-model="ticket.origin" :items="ticketOrigins" />
                    </v-col>

                    <v-col v-else-if="ticket.origin" class="ml-2">
                      {{ $t(`ticket-origin-${ticket.origin}`) }}
                    </v-col>
                  </v-row>

                  <v-row v-if="ticket.create_by" dense>
                    <v-col class="shrink">
                      {{ $t('Reporter') }}
                    </v-col>
                    <v-col>
                      <sca-user-identity :value="ticket.create_by" show-card show-avatar show-company show-role dense class="ml-2" link="emit" @link-click="openUser(ticket.create_by)" />
                    </v-col>
                  </v-row>

                  <v-row v-if="isLord" dense>
                    <v-col cols="12">
                      <div class="d-flex align-center justify-space-between">
                        <div class="text-subtitle-1">
                          {{ $t('Assigned to') }}
                        </div>

                        <sca-button-assign-to-me v-if="isLord && canSave && itsNotMine" x-small rounded :disabled="!canSave" @click="assignToMe" />
                      </div>
                    </v-col>

                    <v-col cols="11" offset="1">
                      <sca-users-select v-model="ticket.id_owner" :filter-company="companies" :disabled="!isLord || !canSave" clearable no-bot show-card show-company show-email show-team dense link="emit" @link-click="openUser(ticket.id_owner)" @change="onOwnerChange" />

                      <div v-if="isLord && ticket?.type !== TICKET_TYPE_PRE_SALE">
                        <sca-teams-select v-model="ticket.id_lord_team" dense :label="$t('Team')" clearable :disabled="!canSave" @change="onTeamChange" />
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-else dense>
                    <v-col class="shrink mr-4 text-no-wrap">
                      {{ $t('Assigned to') }}
                    </v-col>

                    <sca-user-identity :value="ticket.id_owner" :disabled="!canSave" show-card show-avatar show-company show-role dense class="mt-1" link="emit" @link-click="openUser(ticket.id_owner)" />
                  </v-row>

                  <div class="mt-2 d-flex">
                    <div class="shrink mr-4 text-no-wrap">
                      {{ ticket?.type === TICKET_TYPE_PRE_SALE ? $t('Sales consultant') : $t('Referrer') }}
                    </div>

                    <sca-users-select v-if="isLord" v-model="ticket.id_referring" :filter-company="companies" :disabled="!isLord || !canSave" clearable no-bot show-company show-email show-card dense link="emit" @link-click="openUser(ticket.id_referring)" />
                    <sca-user-identity v-else :value="ticket.id_referring" show-card show-avatar show-company show-role dense :disabled="!canSave" class="mt-1" link="emit" @link-click="openUser(ticket.id_referring)" />

                    <sca-button-assign-to-me v-if="isLord && canSave && notReferrer" x-small rounded :disabled="!canSave" @click="assignToMeAsReferrer" />
                  </div>

                  <v-row v-if="ticket?.type !== TICKET_TYPE_PRE_SALE" dense>
                    <v-col class="shrink mr-4">
                      {{ $t('Severity') }}
                    </v-col>
                    <v-col class="shrink">
                      <sca-ticket-severity-select v-if="isLord" v-model="ticket.severity" :disabled="!isLord || !canSave" :rules="[$stratus.services.form.rules.required]" class="required" dense />
                      <sca-ticket-severity v-else-if="ticket.severity" :value="ticket.severity" show-label :disabled="!canSave" />
                    </v-col>
                  </v-row>

                  <v-row v-if="isLord && !isCorporateTicket && [TICKET_STATE_CANCELED, TICKET_STATE_CLOSED, TICKET_STATE_DONE].includes(ticket.state)" no-gutters>
                    <v-col class="shrink mr-4">
                      {{ $t('Invoicing') }}
                    </v-col>
                    <v-col class="d-flex align-center">
                      <v-switch v-if="canUpdateBillingFields" v-model="ticket.billable" dense />
                      <div class="ml-auto">
                        <v-icon :class="ticket.billable ? 'success--text' : 'danger--text'">
                          {{ ticket.billable ? '$vuetify.icons.yes' : '$vuetify.icons.no' }}
                        </v-icon>
                        <span class="text-caption">
                          {{ ticket.billable ? $t('This ticket is billable') : $t('This ticket is not billable') }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="isLord" no-gutters>
                    <v-col class="shrink mr-4">
                      {{ $t('Sphere') }}
                    </v-col>
                    <v-col>
                      <sca-sphere-select v-model="ticket.id_sphere" :customer="ticket.code" :readonly="!canSave" dense />
                    </v-col>
                  </v-row>
                </template>
              </cs-expand-panel>
            </v-card>

            <!-- TICKET LIFE -->
            <v-card v-if="isLord" outlined tile class="pa-2 mt-4">
              <cs-expand-panel :header="$t('Ticket life')" block>
                <template slot="content">
                  <div class="text-h6">
                    {{ $t('Contributors') }}
                    <v-chip small>
                      {{ ticket.ids_contributors?.length || $t('None') }}
                    </v-chip>
                  </div>

                  <sca-user-identity v-for="contributor in ticket.ids_contributors" :key="contributor" :value="contributor" show-card show-avatar show-company show-role dense class="ml-2 mb-1" link="emit" @link-click="openUser(contributor)" />

                  <v-row dense class="mt-4" align="center">
                    <v-col class="d-flex align-center">
                      <v-icon left>
                        $vuetify.icons.time
                      </v-icon>
                      <span :class="estimatedDateRequired ? 'required' : ''">
                        {{ ticket?.type === TICKET_TYPE_PRE_SALE ? $t('Requested reply date'): $t('Estimated delivery date') }}
                      </span>
                    </v-col>

                    <v-col v-if="isLord">
                      <cs-date-picker v-model="ticket.estimated_date" :required="estimatedDateRequired" allow-empty format-short :disabled="!canSave" />
                    </v-col>
                  </v-row>

                  <v-row dense align="center">
                    <v-col class="d-flex align-center">
                      <v-icon left>
                        $vuetify.icons.time
                      </v-icon>
                      {{ $t('Estimated time') }}
                    </v-col>

                    <v-col v-if="isLord" cols="12">
                      <sca-ticket-time-input v-model="ticket.estimated_time" required no-padding :disabled="!canSave" @change="onChangeEstimatedTime" />
                    </v-col>
                  </v-row>

                  <ticket-passed-time :ticket="ticket" />
                </template>
              </cs-expand-panel>
            </v-card>
          </v-form>

          <!-- SLA -->
          <v-card v-if="canListSLA && ticket?.id && ticket?.type !== TICKET_TYPE_PRE_SALE" outlined tile class="py-2 mt-4">
            <cs-expand-panel :header="$t('SLA')" header-cls="mx-1" no-margin block>
              <template v-if="canActivateSLA" #header>
                <div class="ml-auto">
                  <v-menu offset-y :close-on-content-click="false" transition="slide-y-transition">
                    <template v-slot:activator="{ on: onMenu }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: onTooltip }">
                          <v-btn small icon rounded v-on="{ ...onMenu, ...onTooltip }">
                            <v-icon small color="menu-icon">
                              $vuetify.icons.options
                            </v-icon>
                          </v-btn>
                        </template>
                        {{ $t('Options') }}
                      </v-tooltip>
                    </template>

                    <v-card tile class="pa-2">
                      <v-switch v-model="ticket.is_sla_active" :label="$t('Activate SLA for this ticket')" dense hide-details class="my-0" />
                    </v-card>
                  </v-menu>
                </div>
              </template>

              <csm-sla-grid slot="content" ref="ticket-sla-grid" hide-actions hide-filters :query="querySLA" :columns="SLAColumns" :options="SLAOptions" dense />
            </cs-expand-panel>
          </v-card>

          <!-- LINKS -->
          <v-card v-if="isLord || ticketLinksOtherCount > 0" outlined tile class="pa-2 mt-4">
            <cs-expand-panel :header="$t('Links')" block :expanded="expandLinks">
              <template v-if="canSave && isLord && ticket.ids_intern" #header>
                <div class="ml-auto">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon rounded :disabled="loading" v-on="on" @click.stop="showAddLink = !showAddLink">
                        <v-icon :color="showAddLink ? 'primary' : 'menu-icon lighten-2'">
                          {{ showAddLink ? '$vuetify.icons.clear' : '$vuetify.icons.add' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('New link') }}</span>
                  </v-tooltip>
                </div>
              </template>

              <v-row v-show="isLord && showAddLink" slot="header-append" align="center" dense>
                <v-col cols="12" md="4">
                  <v-select v-model="linkTo" :items="ticketLinkToList" :placeholder="$t('Link...')" @change="linkToId = null" />
                </v-col>

                <v-col>
                  <sca-quotation-select v-if="linkTo === LINK_TO_CART" v-model="linkToId" :company="ticket.code" :placeholder="$t('Quotation')" />
                  <sca-opportunity-select v-if="linkTo === LINK_TO_OPPORTUNITY" v-model="linkToId" :company="ticket.code" :placeholder="$t('Opportunity')" :filter-company="ticket.code" />
                  <sca-order-select v-if="linkTo === LINK_TO_ORDER" v-model="linkToId" :company="ticket.code" :placeholder="$t('Order')" />
                  <sca-product-select v-if="linkTo === LINK_TO_PRODUCT" v-model="linkToId" :placeholder="$t('Product')" />
                  <sca-subscription-select v-if="linkTo === LINK_TO_SUBSCRIPTION" v-model="linkToId" :company="ticket.code" :placeholder="$t('Subscription')" only-subscriptions />
                </v-col>

                <v-col class="shrink">
                  <v-btn rounded small class="main-button" :disabled="!linkToId" @click="addOtherLink">
                    {{ $t('Link to') }}
                  </v-btn>
                </v-col>
              </v-row>

              <template slot="content">
                <ticket-links ref="ticket-links" :ticket="ticket" />
              </template>
            </cs-expand-panel>
          </v-card>
        </v-col>
      </v-row>

      <csm-company-dialog ref="company-dialog" />
      <csm-ticket-dialog @closeDialog="closeDialogSecondary" ref="ticket-dialog-secondary" />
      <csm-user-dialog ref="user-dialog" />
      <cs-generic-dialog ref="ticket-waiting-reason-dialog">
        <div slot="content">
          <sca-ticket-waiting-reason-select v-model="newTicketReason" :label="$t('Waiting reason')" :ticket-type="ticket.type" :rules="waitingReasonRules" :class="waitingReasonRequired" dense />
          <!-- Planned waiting reason must provide a date of intervention -->
          <cs-date-time-picker v-if="newTicketReason === TICKET_WAITING_REASON_PLANNED && ticketStateMandatoryFields(newTicketState).includes(TICKET_FIELD_WAITING_REASON)" :label="$t('Intervention date')" v-model="ticket.date_intervention" :rules="[$stratus.services.form.rules.required]" class="required" dense />
        </div>
      </cs-generic-dialog>
      <cs-generic-dialog ref="ticket-resolution-reason-dialog">
        <div slot="content">
          <sca-ticket-resolution-select v-model="ticket.resolution_reason" :graph="stateGraph" :ticket-state="newTicketState" :ticket-type="ticket.type" dense />
        </div>
      </cs-generic-dialog>
      <cs-confirm-dialog ref="confirm-ticket-dialog" />
    </div>

    <sca-footer-create-update-at-by v-if="update" v-model="ticket" slot="footer" :link-user="isLord" />
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import config from '@/config'

const LINK_TO_CART = 'carts'
const LINK_TO_OPPORTUNITY = 'opportunities'
const LINK_TO_ORDER = 'orders'
const LINK_TO_PRODUCT = 'products'
const LINK_TO_SUBSCRIPTION = 'onelines'

const CLOSE_NOTIFICATION_DELAY = 30000 // 30 seconds

export default {
  name: 'TicketForm',
  components: {
    'csm-sla-grid': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/SlaGrid.vue'),
    'ticket-links': () => import(/* webpackChunkName: "components" */ './TicketLinks.vue'),
    'ticket-passed-time': () => import(/* webpackChunkName: "components" */ './TicketPassedTime.vue')
  },
  props: {
    commentId: { type: [Number, String], default: null },
    loading: { type: Boolean, default: false },
    show: { type: Boolean, default: true },
    update: { type: Boolean, default: false },
    value: { type: Object, required: true, default: () => { return {} } }
  },
  data () {
    return {
      CORPORATION_CODE: config.defaults.corporationCode,
      DESCRIPTION_MAX_LENGTH: 524288, // 500KB
      DESCRIPTION_MIN_LENGTH: 0,
      LINK_TO_CART,
      LINK_TO_OPPORTUNITY,
      LINK_TO_ORDER,
      LINK_TO_PRODUCT,
      LINK_TO_SUBSCRIPTION,
      SECONDS_BY_WORK_DAY: this.$alto.defines.TICKETS.SECONDS_BY_WORK_DAY,
      TICKET_FIELD_WAITING_REASON: this.$alto.defines.TICKETS.TICKET_FIELD_WAITING_REASON,
      TICKET_STATE_CANCELED: this.$alto.defines.TICKETS.TICKET_STATE_CANCELED,
      TICKET_STATE_CLOSED: this.$alto.defines.TICKETS.TICKET_STATE_CLOSED,
      TICKET_STATE_DONE: this.$alto.defines.TICKETS.TICKET_STATE_DONE,
      TICKET_TYPE_FACT: this.$alto.defines.TICKETS.TICKET_TYPE_FACT,
      TICKET_TYPE_PRE_SALE: this.$alto.defines.TICKETS.TICKET_TYPE_PRE_SALE,
      TICKET_TYPE_PROJECT: this.$alto.defines.TICKETS.TICKET_TYPE_PROJECT,
      TICKET_TYPE_REQUEST: this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST,
      TICKET_WAITING_REASON_CLI_RETURN: this.$alto.defines.TICKETS.TICKET_WAITING_REASON_CLI_RETURN,
      TICKET_WAITING_REASON_PLANNED: this.$alto.defines.TICKETS.TICKET_WAITING_REASON_PLANNED,
      blockedPayment: false,
      canEditDescription: false,
      loadingCache: false,
      loadingReasonsCache: false,
      expandLinks: false,
      isLoading: false,
      isSaving: false,
      linkTo: null,
      linkToId: null,
      mouseX: 0,
      mouseY: 0,
      newCompany: null,
      newSubTicketLinkIds: [],
      newTicketLinkIds: [],
      newTicketLinkType: null,
      newTicketState: null,
      newTicketReason: '',
      observerMenu: false,
      SLAOptions: {
        allowAdvancedSearch: false,
        allowColumnsOrdering: false,
        allowColumnsVisible: false,
        create: false,
        csvExport: false,
        search: false,
        hideDefaultFooter: true,
        footerProps: { 'disable-items-per-page': true, 'disable-pagination': true, 'items-per-page-options': [-1] },
        itemsPerPage: -1
      },
      showAddLink: false,
      showChangeCompanyInput: false,
      showSubTaskEditor: false,
      stateGraph: 'default',
      subTaskName: '',
      subTaskSeverity: this.$alto.defines.TICKETS.TICKET_SEVERITY_NONE,
      ticket: {},
      ticketLinksUuid: this.$stratus.uuid(),
      ticketLinks: [],
      ticketLinkToList: [],
      uuid: this.$stratus.uuid(),
      valid: true,
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      ticketStateMandatoryFields: '$alto-ticketing/ticketStateMandatoryFields',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    action () {
      if (this.update) return this.$t('Update')
      return this.show ? this.$t('Details') : this.$t('Create')
    },
    canActivateSLA () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.TICKET_SLA_ACTIVE)
    },
    canBypassState () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.TICKET_BYPASS_STATE)
    },
    canChangeType () {
      return this.ticket.type !== this.TICKET_TYPE_PRE_SALE && _.isEmpty(this.ticket.id_project_ticket)
    },
    canListSLA () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_SLA_COUNTERS, this.$alto.API_PERMISSIONS.LIST)
    },
    canSave () {
      return (!this.blockedPayment || this.ticket?.type === this.TICKET_TYPE_PRE_SALE) &&
        (
          // (i) User as permission to create or update ticket
          this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.CREATE) ||
          this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.UPDATE)
        ) &&
        (
          // (i) Ticket is not at end of life or user can update EOL ticket
          !this.$alto.defines.TICKETS.TICKETS_STATES_END_OF_LIFE.includes(this.ticket?.state) ||
          this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.TICKET_UPDATE_END_OF_LIFE)
        )
    },
    canUpdateBillingFields () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.TICKET_BILLING_UPDATE)
    },
    canUpdateCompany () {
      return this.update &&
        this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.TICKET_UPDATE_COMPANY) &&
        this.ticket?.state !== this.$alto.defines.TICKETS.TICKET_STATE_CLOSED
    },
    companies () {
      const companies = [this.me.company]
      if (this.ticket?.code) companies.push(this.ticket?.code)
      if (this.me?.customers) companies.concat(this.me?.customers)
      return companies
    },
    estimatedDateRequired () {
      return this.ticket?.type === this.TICKET_TYPE_PRE_SALE
    },
    formIsValid () {
      return this.$refs['ticket-form-col-1'].validate() && this.$refs['ticket-form-col-2'].validate()
    },
    fullId () { return this.$stratus.services.strings.stripHtmlTags(this.ticket?.id || '') },
    graph () {
      return this.ticket?.type ? this.$alto.defines.TICKETS.STATE_GRAPHS[this.ticket.type] : 'default'
    },
    interventionDateRequired () {
      return this.waitingReasonRequired && this.ticket.waiting_reason === this.TICKET_WAITING_REASON_PLANNED
    },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    isCorporateTicket () {
      return this.ticket.code === this.CORPORATION_CODE
    },
    itsNotMine () {
      return this.ticket?.id_owner !== this.me?.id
    },
    itsNotTrackedByMe () {
      return this.ticket?.ids_observers ? !this.ticket.ids_observers.includes(this.me?.id) : false
    },
    linkedTicketCompanies () {
      return this.ticket.code !== config.defaults.corporationCode ? [this.ticket.code, config.defaults.corporationCode] : null
    },
    me () { return this.$store.getters['$stratus-states/me'] || {} },
    notReferrer () {
      return this.ticket?.id_referring !== this.me?.id
    },
    querySLA () {
      return `id_ticket=${this.ticket.id}`
    },
    SLAColumns () {
      // Columns for SLA grid. {String} value: {Boolean} hidden (if true)
      return {
        name: false,
        state: false,
        per_passed_time: false,
        due_date: false
      }
    },
    ticketClosed () {
      return this.$store.getters['$alto-ticketing/ticketStateIsClosed'](this.ticket?.state)
    },
    ticketLinksCount () {
      const linkTypes = this.ticket?.ids_linked_tickets ? Object.keys(this.ticket.ids_linked_tickets) || [] : []
      let count = 0
      _.forEach(linkTypes, linkType => (count += this.ticket?.ids_linked_tickets?.[linkType]?.length || 0))
      return count
    },
    ticketLinksIds () {
      const linkTypes = this.ticket?.ids_linked_tickets ? Object.keys(this.ticket.ids_linked_tickets) || [] : []
      let ids = []
      _.forEach(linkTypes, linkType => (ids = ids.concat(this.ticket?.ids_linked_tickets?.[linkType])))
      return ids
    },
    ticketLinksOtherCount () {
      const linkTypes = this.ticket?.ids_intern ? Object.keys(this.ticket.ids_intern) || [] : []
      let count = 0
      _.forEach(linkTypes, linkType => (count += this.ticket?.ids_intern?.[linkType]?.length || 0))
      return count
    },
    ticketOrigins () {
      return this.$store.getters['$alto-ticketing/ticketOrigins']()
    },
    waitingReasonRequired () {
      return this.$store.getters['$alto-ticketing/ticketStateMandatoryFields'](this.ticket?.state).includes(this.TICKET_FIELD_WAITING_REASON) ? 'required' : ''
    },
    waitingReasonRules () {
      return this.$store.getters['$alto-ticketing/ticketStateMandatoryFields'](this.ticket?.state).includes(this.TICKET_FIELD_WAITING_REASON) ? [this.$stratus.services.form.rules.required] : []
    }
  },
  watch: {
    'ticket.code': {
      immediate: true,
      async handler (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.blockedPayment = await this.companyBlockedPayment()
        }
      }
    },
    'ticket.type': {
      immediate: true,
      async handler (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.stateGraph = this.$alto.defines.TICKETS.STATE_GRAPHS?.[newValue] || 'default'
        }
      }
    },
    value: {
      immediate: true,
      // deep: true,
      handler (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue) && (!_.isEmpty(newValue) || oldValue !== undefined)) {
          this.ticket = this.sanitizeTicket({ ...newValue })
          this.newCompany = this.ticket?.code
          this.newTicketState = this.ticket?.state
          this.ticketLinks = this.ticket?.ids_linked_tickets ? Object.keys(this.ticket.ids_linked_tickets) || [] : []
        }
      }
    }
  },
  methods: {
    addOtherLink () {
      const ids = { ...this.ticket.ids_intern || {} }
      if (!ids[this.linkTo]) ids[this.linkTo] = []
      if (Array.isArray(this.linkToId)) ids[this.linkTo] = ids[this.linkTo].concat(this.linkToId)
      else ids[this.linkTo].push(this.linkToId)
      ids[this.linkTo] = _.uniq(ids[this.linkTo])
      this.linkToId = null
      this.$set(this.ticket, 'ids_intern', { ...ids }) // This will refresh display
      this.expandLinks = true

      try {
        this.saveTicketFields({
          id: this.ticket.id,
          ids_intern: this.ticket.ids_intern
        })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    async addSubTaskLink (forceSave) {
      if (!forceSave && this.newSubTicketLinkIds?.length === 0) return

      let tickets = this.ticket.ids_project_sub_tickets ? [...this.ticket.ids_project_sub_tickets] : []
      const ids = [...this.newSubTicketLinkIds]
      tickets = _.uniq(tickets.concat(ids))

      this.$set(this.ticket, 'ids_project_sub_tickets', tickets) // This will refresh display
      this.newSubTicketLinkIds = []

      try {
        const ticket = await this.saveTicketFields({
          id: this.ticket.id,
          ids_project_sub_tickets: this.ticket.ids_project_sub_tickets
        })

        if (ticket?.project_sub_tickets) this.$set(this.ticket, 'project_sub_tickets', ticket.project_sub_tickets)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    addTicketLink () {
      if (!this.newTicketLinkType || this.newTicketLinkIds?.length === 0) return

      const tickets = this.ticket.ids_linked_tickets ? { ...this.ticket.ids_linked_tickets } : {}
      const ids = [...this.newTicketLinkIds]
      if (!tickets[this.newTicketLinkType]) tickets[this.newTicketLinkType] = []
      tickets[this.newTicketLinkType] = _.uniq(tickets[this.newTicketLinkType].concat(ids))

      this.$set(this.ticket, 'ids_linked_tickets', tickets) // This will refresh display
      this.newTicketLinkIds = []
      this.newTicketLinkType = null

      try {
        const ticket = this.saveTicketFields({
          id: this.ticket.id,
          ids_linked_tickets: this.ticket.ids_linked_tickets
        })

        if (ticket?.linked_tickets) this.$set(this.ticket, 'linked_tickets', ticket.linked_tickets)

        this.resetOriginal() // Ticket saved: Remove dirty flag
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.ticketLinks = this.ticket?.ids_linked_tickets ? Object.keys(this.ticket.ids_linked_tickets) || [] : []
      }
    },
    async askAndSaveTicket (message) {
      const confirmed = await this.$refs['confirm-ticket-dialog']
        .open(this.$t('Save this ticket'), message)
      if (!confirmed) return false // User cancel

      // Save and do NOT close this dialog
      const newTicket = await this.saveDialog(false)
      this.refresh(newTicket)
      return newTicket
    },
    async assignAllSubtask () {
      if (this.me?.id) {
        try {
          this.isSaving = true
          await this.$store.dispatch('$alto-ticketing/changeOwner', {
            ticketIds: this.ticket.ids_project_sub_tickets,
            ownerId: this.me?.id
          })
          // Refresh sub-task list
          this.closeDialogSecondary()
          this.resetOriginal() // Ticket saved: Remove dirty flag
        } catch (error) {
          this.$stratus.services.notify.error(error)
        } finally {
          setTimeout(() => { this.isSaving = false }, 250)
        }
      }
    },
    assignToMe () {
      if (this.me?.id) {
        this.$set(this.ticket, 'id_owner', this.me.id)
        this.onOwnerChange(this.me.id)
      }
    },
    assignToMeAsReferrer () {
      if (this.me?.id) {
        this.$set(this.ticket, 'id_referring', this.me.id)
      }
    },
    async attachmentsBeforeSend ({ ticket, files }) {
      let saved
      if (this.isDirty()) {
        // Ticket MUST be saved before adding attachments.
        saved = await this.askAndSaveTicket(this.$t('This ticket must be saved before adding an image. Do you confirm?'))
        if (!saved) return false // Cancel
      } else saved = ticket

      return saved // Can now send attachments
    },
    attachmentsSend ({ error, ticket, validateInputs }) {
      if (error) {
        if (validateInputs) this.validateInputs()
        else this.$stratus.services.notify.error(error)
        return
      }
      if (ticket) {
        this.ticket = { ...ticket }
        this.newTicketState = ticket.state
        this.refreshStateToggle(this.newTicketState)
        this.refresh(ticket)
        this.resetOriginal() // Ticket saved: Remove dirty flag
      }
    },
    closeDialog ({ reloadTicket, forceClose } = {}) {
      if (forceClose || !this.canSave || !this.isDirty()) {
        this.$emit('closeDialog', { reloadTicket })
        return
      }

      this.$refs['confirm-ticket-dialog'].open(this.$t('Modifications detected'), this.$t('Do you confirm the loss of your modifications?'))
        .then(confirmed => {
          if (confirmed) {
            this.$emit('closeDialog', { reloadTicket })
          }
        })
    },
    closeDialogSecondary () {
      // Partially refreshing data for this ticket !
      // That is only the fields that will be updated by our secondary ticket
      this.reloadFields(['project_sub_tickets', 'ids_project_sub_tickets', 'linked_tickets', 'ids_linked_tickets'])
    },
    async companyBlockedPayment () {
      if (!this.ticket?.code) return false
      const company = await this.$store.dispatch('$alto-companies/getById', this.ticket.code)
      return company?.blocked_payment || false
    },
    async deleteAttachments ({ id, attachment }) {
      if (!this.canSave || !id || !attachment) return

      try {
        const ticket = await this.$store.dispatch('$alto-ticketing/delTicketAttachment', { ticketId: this.ticket.id, filename: attachment.name })
        if (ticket) {
          this.ticket = { ...ticket }
          this.newTicketState = this.ticket?.state
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    delSubTaskLink (ticketId) {
      let tickets = this.ticket.ids_project_sub_tickets ? [...this.ticket.ids_project_sub_tickets] : []
      _.remove(tickets, id => id === ticketId) // Remove given ticket id
      this.$set(this.ticket, 'ids_project_sub_tickets', tickets)

      tickets = this.ticket.project_sub_tickets ? [...this.ticket.project_sub_tickets] : []
      _.remove(tickets, ticket => ticket.id === ticketId) // Remove given ticket data
      this.$set(this.ticket, 'project_sub_tickets', tickets)
    },
    delTicketLink (link, ticketId) {
      const tickets = this.ticket.ids_linked_tickets ? { ...this.ticket.ids_linked_tickets } : {}
      _.remove(tickets[link], id => id === ticketId) // Remove given ticket
      this.$set(this.ticket, 'ids_linked_tickets', tickets)
      this.refresh()
      this.$refs[link + '-' + this.ticketLinksUuid][0].refresh(tickets[link])
    },
    display (show = false) {
      this.loadingCache = show
      this.loadingReasonsCache = show
      this.visible = show
      if (show) {
        this.$nextTick(async () => {
          try {
            await this.$store.dispatch('$alto-ticketing/fillResolutionReasons', false, this.isLord)
            await this.$store.dispatch('$alto-ticketing/fillWaitingReasons', false, this.isLord)
            this.loadingReasonsCache = false
            await this.$store.dispatch('$alto-ticketing/fillCache', false, this.isLord) // We MUST wait for these cache to be available
            this.$alto.fetchCaches(false, this.isLord) // Do no await cache of other data
            this.$stratus.fetchCaches(false, this.isLord) // Do no await cache of other data
          } finally {
            this.loadingCache = false
          }
        })
      }
    },
    externalId () {
      if (!this.ticket?.id) return
      return [this.ticket.id, this.ticket.id ? 'tickets/' + this.ticket.id : '', this.ticket.name || '']
    },
    followThisTicket (follow) {
      if (this.me?.id) {
        const followers = this.ticket.ids_observers ? [...this.ticket.ids_observers] : []
        if (follow) {
          // Follow this ticket
          followers.push(this.me.id)
        } else {
          // Do not follow this ticket anymore
          _.remove(followers, id => id === this.me.id)
        }
        this.$set(this.ticket, 'ids_observers', followers)
        this.onTicketObserversChange()
      }
    },
    insertImage ({ url }) {
      if (this.$refs['ticket-description-editor']) this.$refs['ticket-description-editor'].insertImage(url, 0)
    },
    isDirty () {
      if (!this.originalTicket) return false
      const currentTicket = this.sanitizeTicket(this.ticket)
      return !_.isEqual(currentTicket, this.originalTicket)
    },
    async onAddImage ({ file, Editor, cursorLocation, resetUploader }) {
      try {
        if (this.canSave) {
          this.isSaving = true
          if (!this.ticket.id) {
            // A new ticket MUST be saved before adding image to its description.
            const saved = await this.askAndSaveTicket(this.$t('This ticket must be saved before adding an image. Do you confirm?'))
            if (!saved) return
          }
          const ticket = await this.$store.dispatch('$alto-ticketing/addTicketAttachment', { ticket: this.ticket, files: [file] })
          // Get last activity (from our addTicketAttachment)
          const a = ticket.activities[ticket.activities.length - 1]
          // Get last attachment update
          const upd = a.ticket_updates.attachments.new[a.ticket_updates.attachments.new.length - 1]
          const url = `${this.$stratus.options.api}/documents/${upd.path}?format=raw`
          Editor.insertEmbed(cursorLocation, 'image', url)
          this.refresh(ticket)
        }
      } catch (error) {
        console.warn(error)
      } finally {
        resetUploader()
        setTimeout(() => { this.isSaving = false }, 250)
      }
    },
    onActivityChange (ticket) {
      // Activity can have some time passed on.
      // Ticket type can have change from activity.
      this.$set(this.ticket, 'type', ticket.type)
      this.$set(this.ticket, 'passed_time', ticket.passed_time)
      this.$set(this.ticket, 'passed_time_billable', ticket.passed_time_billable)
      this.$set(this.ticket, 'passed_time_cob_billable', ticket.passed_time_cob_billable)
      this.resetOriginal() // Ticket saved: Remove dirty flag
    },
    onChangeEstimatedTime (value) {
      this.$set(this.ticket, 'estimated_time', value)
    },
    async onChangeCompany (customer) {
      if (!this.canSave || !customer || customer === this.ticket.code) return

      try {
        const confirmed = await this.$refs['confirm-ticket-dialog']
          .open(this.$t('Change ticket company'), this.$t('Confirm changing the company from {from} to {to}?', { from: this.ticket.code, to: customer }))
        if (!confirmed) {
          this.newCompany = this.ticket?.code
          return false // User cancel}
        }

        this.isSaving = true
        const newTicket = await this.$store.dispatch('$alto-ticketing/changeCompany', { ticketId: this.ticket.id, companyId: customer })
        this.refresh(newTicket)
        this.closeDialog({ reloadTicket: newTicket.id, forceClose: true })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.isSaving = false
        this.showChangeCompanyInput = false
      }
    },
    async onDropPasteImage ({ imageDataUrl, type, imageData, Editor }) {
      try {
        if (this.canSave) {
          this.isSaving = true
          if (!this.ticket.id) {
            // A new ticket MUST be saved before adding image to its description.
            const saved = await this.askAndSaveTicket(this.$t('This ticket must be saved before adding an image. Do you confirm?'))
            if (!saved) return
          }

          const file = imageData.toFile()
          const ticket = await this.$store.dispatch('$alto-ticketing/addTicketAttachment', { ticket: this.ticket, files: [file] })
          // Get last activity (from our addTicketAttachment)
          const a = ticket.activities[ticket.activities.length - 1]
          // Get last attachment update
          const upd = a.ticket_updates.attachments.new[a.ticket_updates.attachments.new.length - 1]
          const url = `${this.$stratus.options.api}/documents/${upd.path}?format=raw`
          let index = (Editor.getSelection() || {}).index
          if (index === undefined || index < 0) index = Editor.getLength()
          Editor.insertEmbed(index, 'image', url, 'user')
          this.refresh(ticket)
        }
      } catch (error) {
        if (error.status === 500) this.$stratus.services.notify.error(error)
        else console.log(error)
      } finally {
        setTimeout(() => { this.isSaving = false }, 1000)
      }
    },
    async onOwnerChange (owner) {
      if (!owner) return
      try {
        const user = await this.$store.dispatch('$alto-users/getById', owner)
        if (user) {
          this.$set(this.ticket, 'id_lord_team', user.lord_team_id)
        }
      } catch (error) {
        console.error('[onOwnerChange]', error)
      }
    },
    onTeamChange () {
      this.$set(this.ticket, 'id_owner', null)
    },
    async onTicketObserversChange () {
      // Observers can be save whatever state the ticket has, and with permission to update
      if (!this.ticket.id || this.blockedPayment || !this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.UPDATE)) return

      try {
        this.isSaving = true
        await this.$store.dispatch('$alto-ticketing/saveTicket', { id: this.ticket.id, ids_observers: this.ticket.ids_observers })
        this.resetOriginal() // Ticket saved: Remove dirty flag
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.isSaving = false
      }
    },
    async onTicketStateChange () {
      if (this.newTicketState) {
        try {
          this.isSaving = true

          // Confirm new state is canceled
          if (this.newTicketState === this.$alto.defines.TICKETS.TICKET_STATE_CANCELED) {
            const confirmed = await this.$refs['confirm-ticket-dialog']
              .open(this.$t('Cancel this ticket'), this.$t('Do you confirm that this ticket is cancelled?'))
            if (!confirmed) {
              // User cancel dialog
              this.newTicketState = this.ticket.state
              return
            }
          }

          // State to save requires a reason
          if (this.$store.getters['$alto-ticketing/ticketStateMandatoryFields'](this.newTicketState).includes(this.TICKET_FIELD_WAITING_REASON)) {
            // New state waiting. Default is client return.
            this.newTicketReason = this.TICKET_WAITING_REASON_CLI_RETURN
            const { confirmed } = await this.$refs['ticket-waiting-reason-dialog']
              .open(this.$t('Waiting reason'), null, { width: 500, color: 'primary' })
            if (confirmed && this.newTicketReason) {
              const data = {
                id: this.ticket.id,
                state: this.newTicketState,
                waiting_reason: this.newTicketReason
              }
              // Planned waiting reason must provide a date of intervention
              if (this.newTicketReason === this.TICKET_WAITING_REASON_PLANNED && this.ticketStateMandatoryFields(this.newTicketState).includes(this.TICKET_FIELD_WAITING_REASON)) {
                data.date_intervention = this.ticket.date_intervention
              }
              this.$set(this.ticket, 'waiting_reason', this.newTicketReason)
              await this.saveTicketFields(data)
            } else {
              // User cancel dialog
              this.newTicketState = this.ticket.state
              return
            }
          } else if (this.resolutionReasonRequired(this.newTicketState) && (this.ticket.code === config.defaults.corporationCode || _.isEmpty(this.ticket.resolution_reason))
          ) { // (!) Do not input an already existing resolution reason for customer tickets
            const { confirmed } = await this.$refs['ticket-resolution-reason-dialog']
              .open(this.$t('Resolution reason'), null, { color: 'primary' })
            if (confirmed && this.ticket.resolution_reason) {
              const data = {
                id: this.ticket.id,
                state: this.newTicketState,
                resolution_reason: this.ticket.resolution_reason
              }
              await this.saveTicketFields(data)
            } else {
              // User cancel dialog
              this.newTicketState = this.ticket.state
              return
            }
          } else {
            const data = {
              id: this.ticket.id,
              state: this.newTicketState,
              resolution_reason: this.ticket.resolution_reason || '' // (!) Mandatory to keep existing reason
            }
            if (this.newTicketState === this.$alto.defines.TICKETS.TICKET_STATE_CANCELED) data.resolution_reason = 'canceled'
            await this.saveTicketFields(data)
          }
          this.refreshStateToggle(this.newTicketState)
          this.resetOriginal() // Ticket saved: Remove dirty flag
        } catch (error) {
          this.newTicketState = null
          this.$stratus.services.notify.error(error)
        } finally {
          this.isSaving = false
        }
      }
    },
    onTicketTypeChange () {
      // When creating a new ticket, we reset its current state according to the graph (of states) given by its type.
      if (!this.ticket.id) {
        const creationStates = this.$store.getters['$alto-ticketing/ticketCreationStates']({ graph: this.graph })
        if (creationStates?.length) {
          this.newTicketState = creationStates[0].value
          this.refreshStateToggle(this.newTicketState)
        }
      }
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog-secondary']) this.$refs['ticket-dialog-secondary'].open(ticket.id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    refresh (ticket) {
      this.ticket = { ...this.ticket, ...ticket }
      if (ticket?.id) this.$set(this.ticket, 'id', ticket.id)
      if (ticket?.code) this.$set(this.ticket, 'code', ticket.code)
      this.newTicketState = this.ticket?.state
      this.ticketLinks = []
      this.$set(this.ticket, 'attachments', [])
      this.$nextTick(() => {
        if (ticket?.attachments) this.$set(this.ticket, 'attachments', ticket.attachments)
        this.ticketLinks = this.ticket?.ids_linked_tickets ? Object.keys(this.ticket.ids_linked_tickets) || [] : []
      })
    },
    refreshStateToggle (newTicketState) {
      if (this.newTicketState) {
        this.$set(this.ticket, 'state', this.newTicketState)
      }
      // this.$refs['ticket-state-toggle'].refresh()
    },
    async reloadFields (fieldsToRefresh = []) {
      if (!this.canSave || !this.ticket.id || fieldsToRefresh.length === 0) return

      try {
        this.isSaving = true
        const reloadedTicket = { ...await this.$store.dispatch('$alto-ticketing/getTicket', { id: this.ticket.id, forceReload: true, history: true }) }
        const newTicket = { ...this.ticket }
        _.forEach(fieldsToRefresh, field => {
          newTicket[field] = reloadedTicket[field]
        })
        this.refresh(newTicket)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.isSaving = false }, 250)
      }
    },
    async reset (ticket) {
      // Reset field
      this.isLoading = true
      this.loadingCache = true
      if (this.$refs['ticket-form-col-1']) await this.$refs['ticket-form-col-1'].reset()
      if (this.$refs['ticket-form-col-2']) await this.$refs['ticket-form-col-2'].reset()
      this.blockedPayment = false
      // When modifying a ticket, description is read-only by default.
      this.canEditDescription = !ticket?.id // Allow description modification for new ticket
      this.expandLinks = false
      // this.isDirty = false
      this.isSaving = false
      this.linkTo = null
      this.linkToId = null
      this.newSubTicketLinkIds = []
      this.newTicketLinkIds = []
      this.newTicketLinkType = null
      this.showAddLink = false
      this.showAddLink = false
      this.showChangeCompanyInput = false
      this.showSubTaskEditor = false
      this.subTaskName = ''
      this.subTaskSeverity = this.$alto.defines.TICKETS.TICKET_SEVERITY_NONE
      this.ticket.attachments = []
      this.valid = true

      // Data from given ticket
      this.ticket = { ...ticket }
      this.newCompany = this.ticket?.code
      this.newTicketState = this.ticket?.state
      this.ticketLinks = this.ticket?.ids_linked_tickets ? Object.keys(this.ticket.ids_linked_tickets) || [] : []
      setTimeout(() => {
        this.resetOriginal()
        this.isLoading = false
      }, 3000)
    },
    resetOriginal () {
      this.originalTicket = { ...this.sanitizeTicket(this.ticket) }
    },
    resolutionReasons (state) {
      return this.$store.getters['$alto-ticketing/ticketResolutionReasons'](this.ticket.type, state, this.stateGraph)
    },
    resolutionReasonRequired (state) {
      return this.ticket.type && !this.ticket.id_project_ticket &&
        this.resolutionReasons(state || this.ticket?.state).length > 0 &&
        (this.ticket.type === this.$alto.defines.TICKETS.TICKET_TYPE_PRE_SALE || this.ticket.code !== config.defaults.corporationCode)
    },
    sanitizeTicket (ticket) {
      // Sanitize
      const result = {
        ...ticket,
        name: this.$stratus.services.strings.stripHtmlTags(ticket.name),
        id_owner: ticket.id_owner || '',
        id_referring: ticket.id_referring || '',
        ids_observers: ticket.ids_observers || [],
        estimated_date: ticket.estimated_date || null
      }
      if (this.$refs['ticket-description-editor']) {
        result.description = this.$refs['ticket-description-editor'].getContent()
      }
      // Remove computed fields
      delete result.ids_activities
      delete result.activities
      // if (!ticket.id || !this.resolutionReasonRequired(ticket.state)) delete result.resolution_reason
      return result
    },
    async saveDialog (closeDialog = true, { keys } = {}) {
      if (!this.canSave) return false

      this.isSaving = true

      if (!this.$refs['ticket-form-col-1']?.validate() || !this.$refs['ticket-form-col-2']?.validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return false
      }

      // (!) Special rule when creating a ticket: Mandatory observer for a customer's ticket. This will not be verified for pre-sale tickets. Sur un ticket ouvert par Scalair, qui n'est pas de type pre-sale, et pour une société différente de Scalair, il est obligatoire d'avoir une personne cliente (membre de la société du ticket) au suivi)
      if (!this.ticket?.id && this.isLord && this.ticket?.code !== this.CORPORATION_CODE && this.ticket?.type !== this.TICKET_TYPE_PRE_SALE) {
        // Creating a ticket as a member of Scalair staff -> check!
        // Let search for an user observer of that ticket that belong to the company of that ticket:
        let observerFound = false
        if (this.ticket?.ids_observers?.length) {
          let i = 0
          while (!observerFound && i < this.ticket.ids_observers.length) {
            const observer = await this.$store.dispatch('$alto-users/getOne', this.ticket.ids_observers[i])
            if (observer.company === this.ticket.code) observerFound = true
            i++
          }
        }

        if (!observerFound) {
          this.$stratus.services.notify.warning(this.$t('You must add a customer user to the people who will follow up this ticket.'))
          this.isSaving = false
          return false
        }
      }

      try {
        if (this.$refs['ticket-activities']) await this.$refs['ticket-activities'].savePendingInputs()

        // Sanitize
        const ticket = this.sanitizeTicket(this.ticket)

        // Detect the state if none is given
        if (!ticket.state) {
          const createState = this.$store.getters['$alto-ticketing/ticketStatesNext']()
          if (createState?.length > 0) ticket.state = createState[0].value
          else this.$stratus.services.notify.warning(this.$t('You must choose a state for this ticket!'))
        }

        const savedTicket = await this.$store.dispatch('$alto-ticketing/saveTicket', ticket)
        if (this.update) {
          this.$stratus.services.notify.success(this.$t('Ticket {name} updated.', ticket), { duration: CLOSE_NOTIFICATION_DELAY, tickets: [savedTicket] })
        } else {
          this.$stratus.services.notify.success(this.$t('Ticket {name} created.', ticket), { duration: CLOSE_NOTIFICATION_DELAY, tickets: [savedTicket] })
        }
        this.resetOriginal() // Ticket saved: Remove dirty flag
        if (closeDialog && !keys?.ctrl && !keys?.meta) this.closeDialog({ forceClose: true })
        this.isSaving = false
        return savedTicket
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.isSaving = false
        return false
      }
    },
    async saveSubTask () {
      if (!this.canSave || !this.ticket.code || this.subTaskName.trim().length === 0) return

      try {
        this.isSaving = true
        if (!this.ticket.id) {
          // A new ticket MUST be saved before adding sub-task to itself.
          const saved = await this.askAndSaveTicket(this.$t('This ticket must be saved before adding a sub-task. Do you confirm?'))
          if (!saved) return
        }

        // Save sub-task ticket
        const ticket = await this.$store.dispatch('$alto-ticketing/saveSubTask', {
          projectId: this.ticket.id,
          subTask: {
            type: this.TICKET_TYPE_REQUEST,
            code: this.ticket.code,
            name: this.subTaskName,
            id_project_ticket: this.ticket.id,
            severity: this.subTaskSeverity
          }
        })
        if (ticket) {
          this.ticket = { ...ticket }
          this.newTicketState = this.ticket?.state
          this.subTaskName = ''
          this.subTaskSeverity = this.$alto.defines.TICKETS.TICKET_SEVERITY_NONE
        }
        this.resetOriginal() // Ticket saved: Remove dirty flag
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.isSaving = false
        setTimeout(() => {
          if (this.$refs[`sub-task-name-input-${this.uuid}`]) this.$refs[`sub-task-name-input-${this.uuid}`].focus()
          if (this.$refs['ticket-form-col-1']) this.$refs['ticket-form-col-1'].resetValidation()
        }, 250)
      }
    },
    async saveTicketFields (partialTicket) {
      if (!this.canSave || !partialTicket.id) return
      return await this.$store.dispatch('$alto-ticketing/saveTicket', partialTicket)
    },
    validateInputs () {
      if (!this.$refs['ticket-form-col-1'].validate() || !this.$refs['ticket-form-col-2'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
      }
    }
  },
  created () {
    this.ticketLinkToList = [
      { value: LINK_TO_CART, text: this.$t('Link a quotation') },
      { value: LINK_TO_OPPORTUNITY, text: this.$t('Link an opportunity') },
      { value: LINK_TO_ORDER, text: this.$t('Link an order') },
      { value: LINK_TO_PRODUCT, text: this.$t('Link a product') },
      { value: LINK_TO_SUBSCRIPTION, text: this.$t('Link a subscription') }
    ]
  }
}
</script>
