<template>
  <sca-modal-dialog :visible="visible" :loading="loading || isSaving" @closeDialog="closeDialog" :is-saving="isSaving" max-width="70%">
    <template #title>
      {{ $t(`ticket-type-${type}`) }}
      <!-- <span class="text-body-1">
        — {{ $t(`ticket-type-${type}-help`) }}
      </span> -->
    </template>

    <template #subtitle>
      <div class="text-caption d-flex align-center highlighted-panel text-truncate">
        <div v-if="domain || product?.domain" class="d-flex align-center">
          {{ $t(`product-domain-${domain || product?.domain}`) }}
          <v-icon v-if="category || product?.product_category" small left right>
            icon-long-arrow-right
          </v-icon>

          <span v-if="category || product?.product_category">
            {{ $t(`sub-product-${domain || product?.domain}-${(category || product?.product_category).toLowerCase()}`) }}
          </span>
        </div>

        <v-icon v-if="subscription?.id" small left right>
          icon-long-arrow-right
        </v-icon>

        <sca-subscription-identity v-if="subscription?.id" :value="subscription" :label="subscription.customer_caption || subscription.caption || subscription.name" show-avatar show-card />
      </div>
    </template>

    <!-- <div v-if="subscription?.id" slot="subtitle" class="d-flex">
      <div class="highlighted-panel">
        <sca-subscription-identity :value="subscription" :label="subscription.customer_caption || subscription.caption || subscription.name" show-avatar show-card />
      </div>
    </div> -->

    <v-col v-if="!subscription && page > PRODUCT_PAGE" slot="buttons-header">
      <v-btn rounded text :loading="loading || isSaving" :disabled="loading || isSaving" @click="gotoPreviousPage">
        <v-icon small>
          $vuetify.icons.prev
        </v-icon>
        {{ $t('Return') }}
      </v-btn>
    </v-col>

    <v-col v-if="page === SAVE_PAGE && !blockedPayment" slot="buttons" class="shrink">
      <v-btn rounded :loading="loading || isSaving" :disabled="loading || isSaving" class="main-button" @click="saveDialog">
        {{ $t('Send') }}
      </v-btn>
    </v-col>

    <div slot="content">
      <v-row v-if="blockedPayment" justify="center">
        <v-col class="shrink text-no-wrap">
          <cs-alert-panel type="warning" dense :text="$t('Payment problem, please contact Scalair sales or administrative department.')" />
        </v-col>
      </v-row>

      <v-tabs-items v-model="page" class="transparent">
        <v-tab-item :value="PRODUCT_PAGE">
          <div class="text-h6 d-flex align-center mb-4">
            <v-divider />
            <span class="px-4">
              {{ $t('Products concerned') }}
            </span>
            <v-divider />
          </div>

          <v-row align="center" justify="center">
            <v-col v-for="(d, index) in domainsSorted" cols="12" md="6" lg="4" :key="index" class="text-center">
              <v-hover v-slot="{ hover }">
                <v-sheet v-ripple="{ center: true, class: 'primary--text' }" class="clickable pa-2" :class="(hover ? ' focused' : '') + (d === domain ? ' active-product-domain-full' : '')" :min-height="260" @click="selectDomain(d)">
                  <template v-if="d === DOMAIN_OTHER">
                    <v-img :src="'/img/products/all'+ (isDark ? '_dark' : '_light') + '.svg'" contain :height="120">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey" />
                        </v-row>
                      </template>
                    </v-img>
                    <div class="text-h6 font-weight-bold primary--text">
                      {{ $t(`Other`) }}
                    </div>
                    <v-divider vertical />
                    <div class="text-left" :class="isDark ? '' : 'secondary--text'">
                      {{ $t('Incidents involving another product.') }}
                    </div>
                  </template>

                  <template v-else>
                    <v-img :src="'/img/products/' + d.toLowerCase() + (isDark ? '_dark' : '_light') + '.svg'" contain :height="120">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey" />
                        </v-row>
                      </template>
                    </v-img>
                    <div class="text-h6 font-weight-bold primary--text">
                      {{ $t(`product-domain-${d.toLowerCase()}`) }}
                    </div>
                    <v-divider vertical />
                    <div class="text-left" :class="isDark ? '' : 'secondary--text'">
                      {{ $t(`product-domain-help-incident-${d.toLowerCase()}`) }}
                    </div>
                  </template>
                </v-sheet>
              </v-hover>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item :value="SUB_PRODUCT_PAGE">
          <div class="text-h6 d-flex align-center mb-4">
            <v-divider />
            <span class="px-4">
              {{ $t('Sub-products concerned') }}
            </span>
            <v-divider />
          </div>

          <v-row align="center">
            <v-col cols="12">
              <v-row align="center" justify="space-around">
                <v-col v-for="(c, index) in categories" cols="12" md="6" lg="3" :key="index" class="ma-4 pa-0" :class="c === category ? ' active-product-domain-full' : 'bordered'">
                  <v-hover v-slot="{ hover }">
                    <v-sheet v-ripple="{ center: true, class: 'primary--text' }" class="clickable pa-2" :class="(hover ? ' focused' : '')" :min-height="140" @click="selectCategory(c)">
                      <div class="text-h6 font-weight-bold primary--text">
                        {{ $t(`sub-product-${domain}-${c.toLowerCase()}`) }}
                      </div>
                      <v-divider class="mx-3 my-2" />
                      <div class="text-left" :class="isDark ? '' : 'secondary--text'">
                        {{ $t(`sub-product-help-${domain}-${c.toLowerCase()}`) }}
                      </div>
                    </v-sheet>
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item :value="SAVE_PAGE">
          <v-form ref="form-new-incident" v-model="valid" lazy-validation :disabled="loading || isSaving">
            <v-row dense>
              <v-col cols="12">
                <v-text-field v-model="title" :label="$t('Title')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(64)]" class="required" counter="64" />
              </v-col>

              <v-col cols="12" md="6">
                Quel est votre impact métier ?
                <sca-customer-incident-severity-select v-model="severity" dense />
              </v-col>

              <v-col v-if="needCustomer" cols="12" md="6">
                {{ $t('Which company is concerned?') }}
                <div v-if="subscription?.code">
                  <sca-company-identity :value="subscription.code" show-avatar show-email show-phone show-sales-person />
                </div>
                <sca-customer-select v-else v-model="company" clearable dense hide-details link="emit" @link-click="openCompany(company)" @input="companyBlockedPayment" />
              </v-col>

              <v-col cols="12">
                <sca-wysiwyg-editor v-model="description" ref="ticket-description-editor" :title="$t('Description')" medium :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(4096)]" :customers="company" required :max-length="4096" :min-length="1" :disabled="loading || isSaving" />
              </v-col>

              <v-col cols="12">
                <cs-file-upload-input v-model="attachments" :file-size-limit="ALL_FILE_SIZE_LIMIT" :total-size-limit="ALL_FILE_SIZE_LIMIT" multiple use-isu />
                <div class="text-caption text-right">
                  {{ $t('You have a space of {size}MB for all attachments.', { size: ALL_FILE_SIZE_LIMIT / 1000 / 1000 }) }}
                </div>
              </v-col>
            </v-row>

            <div v-if="!subscription" class="mt-2">
              {{ $t('Products') }}

              <div v-if="category">
                <sca-subscription-select v-model="products" :company="company" :category="category" :state="['completed', 're-pending', 'stopping']" clearable dense multiple show-caption :show-order="isLord" :show-product="isLord" />
              </div>
            </div>

            <div class="mt-2">
              {{ $t('Other products') }}

              <v-text-field v-if="subscription" v-model="productsOther" :rules="[$stratus.services.form.rules.max(128)]" counter="128" clearable dense />
              <cs-helper v-else class="full-width">
                <v-text-field slot="content" v-model="productsOther" :rules="[$stratus.services.form.rules.max(128)]" counter="128" clearable dense />
                <div slot="help">
                  {{ $t('Use this input to enter products you may not have found in the list.') }}
                </div>
              </cs-helper>
            </div>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

import { mapGetters } from 'vuex'

export default {
  name: 'TicketNewIncident',
  props: {
    descriptor: { type: [Array, Object], required: true },
    product: { type: Object, default: null },
    subscription: { type: Object, default: null },
    visible: { type: Boolean, default: false }
  },
  data () {
    return {
      ALL_FILE_SIZE_LIMIT: 20 * 1000 * 1000,
      PRODUCT_PAGE: 1,
      SUB_PRODUCT_PAGE: 2,
      SAVE_PAGE: 3,
      DOMAIN_OTHER: 'other',
      CATEGORY_VM: 'vm',
      attachments: [],
      blockedPayment: false,
      category: null,
      company: null,
      description: '',
      domain: null,
      isSaving: false,
      loading: false,
      page: 1,
      products: [],
      productsOther: '',
      severity: this.$alto.defines.TICKETS.TICKET_SEVERITY_NONE,
      title: '',
      type: this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT,
      uuid: this.$stratus.uuid(),
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord',
      me: '$stratus-states/me'
    }),
    categories () {
      return this.domain ? this.descriptor[this.domain] : []
    },
    categoryRules () {
      return this.categories?.length ? [this.$stratus.services.form.rules.required] : []
    },
    domains () {
      return Object.keys(this.descriptor).concat([this.DOMAIN_OTHER])
    },
    domainsSorted () {
      const domains = Object.keys(this.descriptor)
      return _.sortBy(domains, d => { return this.$t(`product-domain-${d.toLowerCase()}`) }).concat([this.DOMAIN_OTHER])
    },
    needCustomer () {
      return this.me?.customers?.length > 1 || this.me?.customers?.includes('ALL')
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },
    async companyBlockedPayment () {
      const company = await this.$store.dispatch('$alto-companies/getById', this.company)
      this.blockedPayment = company?.blocked_payment || false
    },
    filterDomain (domain) {
      this.domain = domain
      this.category = null
    },
    gotoPreviousPage () {
      if (this.domain === this.DOMAIN_OTHER) this.page = 1
      else this.page--
    },
    reset () {
      this.attachments = []
      this.blockedPayment = false
      this.page = this.PRODUCT_PAGE
      this.category = null
      this.company = this.me?.company || null
      this.description = ''
      this.domain = null
      this.isSaving = false
      this.loading = false
      this.products = []
      this.productsOther = ''
      this.severity = this.$alto.defines.TICKETS.TICKET_SEVERITY_NONE
      this.title = ''
      this.valid = false
      this.companyBlockedPayment()
    },
    async saveDialog (closeDialog = true) {
      this.isSaving = true

      if (!this.subscription?.id && !this.products.length && !this.productsOther.length) {
        this.$stratus.services.notify.warning(this.$t('At least one product must be entered.'))
        this.isSaving = false
        return false
      }

      if (!this.$refs['form-new-incident'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return false
      }

      try {
        // Sanitize
        this.title = this.$stratus.services.strings.stripHtmlTags(this.title)

        const ticket = {
          type: this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT,
          name: this.title,
          product_free: this.productsOther || null,
          severity: this.severity,
          is_urgent: this.isUrgent
        }

        if (this.$refs['ticket-description-editor']) {
          ticket.description = this.$refs['ticket-description-editor'].getContent()
        } else {
          ticket.description = ''
        }

        if (this.subscription?.code) {
          ticket.code = this.subscription.code
        } else {
          ticket.code = this.company || this.me.company
        }

        if (this.subscription?.id) {
          ticket.products = [this.subscription.id]
        } else {
          ticket.products = this.products
        }

        if (this.product?.product_category) {
          ticket.product_category = this.product?.product_category
        } else {
          ticket.product_category = this.category
        }

        if (this.product?.domain) {
          ticket.domain = this.product?.domain
        } else {
          ticket.domain = this.domain
        }
        const savedTicket = await this.$store.dispatch('$alto-ticketing/postTicketLite', {
          ticket,
          files: this.attachments
        })
        this.$stratus.services.notify.success(this.$t('Ticket {name} created.', { name: this.title }))

        if (closeDialog) this.closeDialog()
        this.isSaving = false
        return savedTicket
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.isSaving = false
        return false
      }
    },
    selectCategory (category) {
      this.category = category
      this.page = this.SAVE_PAGE
    },
    selectDomain (domain) {
      this.domain = domain
      this.category = null
      if (domain === this.DOMAIN_OTHER) this.page = this.SAVE_PAGE
      else this.page = this.SUB_PRODUCT_PAGE
    },
    resetPage () {
      if (this.subscription) {
        this.page = this.SAVE_PAGE
      } else {
        this.page = this.PRODUCT_PAGE
      }
    }
  }
}
</script>
