<template>
  <div>
    <v-menu v-model="showMenu" offset-y transition="scale-transition" :close-on-content-click="true">
      <template v-slot:activator="{ on }">
        <v-btn rounded small text v-on="on">
          <v-icon small left class="top-1">
            $vuetify.icons.cart
          </v-icon>
          <span v-if="current && current.name">
            {{ current.name }}
          </span>
          <span v-else>
            {{ $tc('No quotation|One quotation|{count} quotations', cartList.length, { count: cartList.length }) }}
          </span>
          <v-icon small right :class="showMenu ? 'rotate-once-180' : ''">
            $vuetify.icons.expand
          </v-icon>
        </v-btn>
      </template>
      <v-card flat tile class="pa-2">
        <v-row dense align="center" justify="space-between">
          <v-col>
            <v-btn v-if="canCatalog" to="/productchooser" rounded class="main-button">
              <v-icon left small>
                $vuetify.icons.catalog
              </v-icon>
              {{ $t('Products Catalog') }}
            </v-btn>
          </v-col>

          <v-col class="text-center">
            <v-btn @click="newCart" v-show="currentExists" rounded>
              <v-icon left small>
                $vuetify.icons.add
              </v-icon>
              {{ $t('New cart') }}
            </v-btn>
          </v-col>

          <v-col class="text-right">
            <v-btn to="/quotations" v-show="cartListSorted && cartListSorted.length" rounded>
              <v-icon left small>
                $vuetify.icons.cart
              </v-icon>
              {{ $t('Quotations list') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-show="cartListSorted && cartListSorted.length" dense>
          <v-col cols="12">
            <v-divider class="mx-3 my-4" />
          </v-col>
          <v-col cols="12">
            <span class="font-weight-bold">
              {{ $t('Last saved quotations') }}
            </span>
          </v-col>
        </v-row>

        <v-row v-for="cart in cartListSorted" :key="cart.id" dense>
          <v-col class="clickable" @click="gotoQuotation(cart.id)">
            <div>
              <span :class="cart.id === current.id ? 'font-weight-bold' : ''">
                {{ cart.customer }}/{{ cart.name }} :
              </span>
              <span class="primary--text pl-1">{{ $n((cart.total_discount) ? cart.total_discount : cart.total, 'currency') }}</span>
              <span class="text-caption primary--text pl-1"> + {{ $n((cart.total_setup_discount) ? cart.total_setup_discount : cart.total_setup, 'currency') }}</span>
            </div>
            <div>
              <span class="pl-1 text-caption">{{ $stratus.dt(cart.updatedAt).format('ll LT') }}</span>
              <span v-if="cart.expired" class="pl-2 text-caption danger--text">
                <v-icon small>
                  $vuetify.icons.expired
                </v-icon>
                {{ $t('Out-of-date') }}
              </span>
              <span v-else class="pl-2 text-caption" v-html="expirationDateHtml(cart.expiration_date)" />
            </div>
          </v-col>
          <v-col v-if="cart.id === current.id" class="shrink">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon text v-on="on" @click="gotoQuotation(cart.id)">
                  <v-icon small class="menu-icon--text">
                    $vuetify.icons.read
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('This is the current quotations.') }}</span>
            </v-tooltip>
          </v-col>
          <v-col v-if="cart.id !== current.id" class="shrink">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon text v-on="on" @click.capture.stop="deleteCart(cart)">
                  <v-icon small class="danger--text">
                    $vuetify.icons.delete
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Delete') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
    <cs-confirm-dialog ref="confirmCartDeletion" />
    <csm-quotation-dialog ref="cart-menu-quotation-dialog" />
  </div>
</template>

<script>
export default {
  components: {
    'csm-quotation-dialog': () => import('./QuotationDialog')
  },
  data () {
    return {
      cartList: [],
      cartListSorted: [],
      current: {},
      showMenu: false
    }
  },
  computed: {
    canCatalog () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST) },
    currentExists () { return this.current && this.current.items?.length > 0 }
  },
  methods: {
    clearCart () {
      this.$store.commit('carts/clear')
      this.current = this.$store.getters['carts/current'] || {}
    },
    deleteCart (cart) {
      this.$refs.confirmCartDeletion
        .open(this.$t('Delete quotation'), this.$t('Confirm deletion of quotation «{name}»?', { name: cart.name }), { color: 'red' })
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('carts/delete', cart.id)
              .then(() => {
                if (cart.id === this.current.id) {
                  // Clear current cart from store as we have just delete it!
                  this.clearCart()
                }
                this.$stratus.services.notify.success(this.$t('Quotation «{name}» deleted!', { name: cart.name }))
                this.refreshList()
              })
              .catch(error => this.$stratus.services.notify.error(error))
          }
        })
    },
    expirationDays (date) { return this.$store.getters['carts/expirationDays'](date) },
    expirationDateHtml (date) { return this.$store.getters['carts/expirationDateHtml'](date) },
    gotoCatalog () {
      if (this.$router.currentRoute.name !== 'catalog') {
        this.$router.push({ name: 'catalog' }).catch((error) => { console.warn(error) })
      }
    },
    gotoQuotation (cartId) {
      this.$refs['cart-menu-quotation-dialog'].load(cartId)
      this.current = this.$store.getters['carts/current'] || {}
    },
    async newCart () {
      // No cart exist, create a brand new one
      if (!this.currentExists) {
        await this.$store.dispatch('carts/create')
        this.current = this.$store.getters['carts/current'] || {}
        this.gotoCatalog()
        return
      }

      // A cart exist to tell the user that we will forget it
      this.$refs.confirmCartDeletion
        .open(this.$t('New cart'), this.$t('Creating a new cart will empty the current one. Do you saved you cart «{name}»?', { name: this.current.name }), { color: 'red' })
        .then(async (confirm) => {
          if (confirm) {
            await this.$store.dispatch('carts/create')
            this.current = this.$store.getters['carts/current'] || {}
            this.gotoCatalog()
          }
        })
    },
    async refreshCart () {
      this.current = this.$store.getters['carts/current'] || {}
    },
    async refreshList () {
      try {
        this.cartList = await this.$store.dispatch('carts/list') || []
        this.cartListSorted = (this.cartList).slice(0, 8)
        this.current = this.$store.getters['carts/current'] || {}
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    register () {
      if (this.$root['cart-menu-quotation-dialog']) return
      this.$root['cart-menu-quotation-dialog'] = this.$refs['cart-menu-quotation-dialog']
      if (this.$root['cart-menu-quotation-dialog']) console.log('[quotation] root dialog registered')
    }
  },
  mounted () {
    this.refreshList()
  }
}
</script>
