<template>
  <v-row dense>
    <v-col cols="12" class="text-right pa-4">
      <v-menu offset-y v-model="menuAttachments" transition="scale-transition" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <v-btn rounded v-on="on">
            {{ $t('Add attachments') }}
            <v-icon small right>
              icon-small-arrow-down
            </v-icon>
          </v-btn>
        </template>
        <v-card outlined class="px-0">
          <v-card-title>
            {{ $t('Add attachments') }}
          </v-card-title>
          <v-card-text>
            <cs-file-upload-input v-model="files" multiple :file-size-limit="fileSizeLimit" :total-size-limit="totalSizeLimit" use-isu />
            <div v-if="totalSizeLimit > 0" class="text-caption">
              {{ $t('You have a space of {size}MB for all attachments.', { size: fileSizeLimit / 1000 / 1000 }) }}
            </div>
            <div v-else-if="fileSizeLimit > 0" class="text-caption">
              {{ $t('File must not exceed {size}MB.', { size: fileSizeLimit / 1000 / 1000 }) }}
            </div>
            <cs-alert-panel v-if="files.length > MAX_FILE_COUNT" class="text-caption" type="error" dense :text="$t('You can only send {count} files at a time.', { count: MAX_FILE_COUNT })" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn rounded :disabled="!files.length || files.length > MAX_FILE_COUNT" @click="sendAttachment">
              {{ $t('Send') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>

    <v-col v-if="sendingFiles && files.length > 0" cols="12" class="pa-4">
      <cs-icon-loading small left />
      {{ $tc('Sending file...|Sending files...', files.length) }}
    </v-col>
  </v-row>
</template>

<script>
const MAX_FILE_COUNT = 10
const FILE_SIZE_LIMIT = 15 * 1000 * 1000 // 15MiB

export default {
  name: 'CartFiles',
  props: {
    cart: { type: Object, required: true },
    fileSizeLimit: { type: Number, default: FILE_SIZE_LIMIT },
    totalSizeLimit: { type: Number, default: 0 }
  },
  data () {
    return {
      MAX_FILE_COUNT,
      files: [],
      menuAttachments: false,
      sendingFiles: false
    }
  },
  methods: {
    async sendAttachment () {
      if (!this.files.length) return

      try {
        this.sendingFiles = true
        const cart = await this.$store.dispatch('carts/addAttachments', { id: this.cart.id, files: this.files })
        this.$emit('change', cart.files)
        this.files = []
        this.menuAttachments = false
      } catch (error) {
        console.error(error)
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.sendingFiles = false }, 500)
      }
    }
  }
}
</script>
