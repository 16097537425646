import config from '@/config'
import Menu from '@/views/menu'
import Vue from 'vue'
import Vuex from 'vuex'
import carts from './modules/carts.js'
import documents from './modules/documents.js'
import followups from './modules/followups.js'
import newsletters from './modules/newsletters.js'
import opportunities from './modules/opportunities.js'
import orders from './modules/orders.js'
import scheduler from './modules/scheduler.js'
import users from './modules/users.js'

import pkg from '../../package.json'

Vue.use(Vuex)

const MODULES = {
  carts,
  documents,
  followups,
  newsletters,
  opportunities,
  orders,
  scheduler,
  users
}

const store = new Vuex.Store({
  modules: MODULES,
  state: {
    $alto: null,
    $stratus: null,
    appConfig: config,
    appVersion: pkg.version,
    fullscreen: false,
    helpers: {
      showFullscreenHelp: true
    },
    secret: ''
  },
  getters: {
    appConfig: state => { return state.appConfig },
    appVersion: state => { return state.appVersion },
    fullscreen: state => {
      state.fullscreen = state.$stratus.services.localStorage.get('fullscreen', false)
      return state.fullscreen
    },
    helpers: state => helperKey => {
      state.helpers[helperKey] = state.$stratus.services.localStorage.get('helpers-' + helperKey, true)
      return state.helpers[helperKey]
    },
    secret: state => { return state.secret ? state.secret.data : '' }
  },
  actions: {},
  mutations: {
    loadAlto (state) {
      state.$alto = this._vm.$alto
      console.log('Linked to Alto framework', state.$alto ? 'Done' : ' Failed!')
    },
    loadStratus (state) {
      state.$stratus = this._vm.$stratus
      console.log('Linked to Stratus framework', state.$stratus ? 'Done' : ' Failed!')
    },
    setHelper (state, { helperKey, data }) {
      state.$stratus.services.localStorage.set('helper-' + helperKey, data)
      state.helpers[helperKey] = data
    },
    toggleFullscreen (state) {
      state.$stratus.services.localStorage.set('fullscreen', !state.$stratus.services.localStorage.get('fullscreen', false))
      state.fullscreen = state.$stratus.services.localStorage.get('fullscreen', false)
    }
  }
})

store.registerModule('menu', {
  namespaced: true,
  state: {
    menu: []
  },
  getters: {
    app: state => { return state.menu }
  },
  actions: {},
  mutations: {
    build (state) {
      state.menu = Menu.build(store)
    }
  }
})

export default store
