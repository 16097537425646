<template>
  <v-row align="center" dense>
    <v-col>
      <v-checkbox v-model="checked" v-bind="$attrs" v-on="$listeners" @change="onChange" style="margin: 0; padding: 0" hide-details dense />
    </v-col>
    <v-col class="shrink" v-if="unit">
      <span>{{ unit }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CsmDynaProductCheckbox',
  props: {
    unit: { type: String, default: '' },
    value: { type: Number, default: 0 }
  },
  watch: {
    value (newValue, oldValue) {
      if (newValue !== oldValue) this.setValue(this.value)
    }
  },
  data () {
    return {
      checked: false
    }
  },
  methods: {
    onChange (value) {
      this.setValue(value)
      this.$emit('change', this.checked)
    },
    setValue (value) {
      this.checked = value
    }
  },
  created () {
    this.onChange(this.value)
  }
}
</script>
