<template>
  <div>
    <v-data-iterator :items="sortedData">
      <template v-slot:header>
        <v-row dense align="center">
          <v-col>
            <cs-search-input @search="onSearch" prepend-inner-icon="icon-magnifier" :placeholder="$t('Search...')" color="primary" clearable />
          </v-col>

          <v-col class="shrink text-no-wrap">
            <span>{{ $t('Filter') }}</span>
          </v-col>

          <v-col>
            <v-chip-group v-model="filter" column multiple>
              <template v-for="(itemType, index) in ITEM_TYPES">
                <v-tooltip top :key="index">
                  <template v-slot:activator="{ on }">
                    <v-chip small v-on="on" :color="isFilterActive(index) ? ITEM_COLORS[itemType] : ''" :outlined="!isFilterActive(index)" class="ml-2">
                      <v-icon small :class="isFilterActive(index) ? 'black--text' : 'menu-icon--text'">
                        {{ ICON_TYPES[itemType] }}
                      </v-icon>
                      <span class="ml-2" :class="isFilterActive(index) ? 'black--text' : 'menu-icon--text'">
                        {{ followUp[itemType] ? followUp[itemType].length : '' }}
                      </span>
                    </v-chip>
                  </template>
                  {{ $t('Click to show or hide {name}.', { name: $t(`follow-up-type-${itemType}`) }) }}
                </v-tooltip>
              </template>
            </v-chip-group>
          </v-col>

          <v-col v-if="allowAdd" class="shrink">
            <v-menu bottom close-on-content-click offset-y transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="main-button" v-bind="attrs" v-on="on">
                  <v-icon>$vuetify.icons.add</v-icon>
                </v-btn>
              </template>

              <v-list flat dense>
                <v-list-item v-show="canAddCall">
                  <v-list-item-title class="clickable" @click="addCall">
                    <v-row align="end">
                      <v-col cols="1">
                        <v-icon small left>
                          {{ ICON_TYPES[ITEM_TYPE_CALL] }}
                        </v-icon>
                      </v-col>
                      <v-col>
                        {{ $t('Add a call') }}
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-show="canAddEvent">
                  <v-list-item-title class="clickable" @click="addEvent">
                    <v-row align="end">
                      <v-col cols="1">
                        <v-icon small left>
                          {{ ICON_TYPES[ITEM_TYPE_EVENT] }}
                        </v-icon>
                      </v-col>
                      <v-col>
                        {{ $t('Add an event') }}
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-show="canAddTask">
                  <v-list-item-title class="clickable" @click="addTask">
                    <v-row align="end">
                      <v-col cols="1">
                        <v-icon small left>
                          {{ ICON_TYPES[ITEM_TYPE_TASK] }}
                        </v-icon>
                      </v-col>
                      <v-col>
                        {{ $t('Add a task') }}
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </template>

      <template v-slot:default="props">
        <v-sheet v-for="(item, index) in props.items" :key="index" class="text-left">
          <csm-followup-call v-if="item && item.$type === ITEM_TYPE_CALL" :value="item" :company-code="item.code" show-company show-sales-person @updated="onFollowupDialogClose" />
          <csm-followup-email v-else-if="item && item.$type === ITEM_TYPE_EMAIL" :value="item" :company-code="item.code" show-company show-sales-person @updated="onFollowupDialogClose" />
          <csm-followup-event v-else-if="item && item.$type === ITEM_TYPE_EVENT" :value="item" :company-code="item.code" show-company show-sales-person @updated="onFollowupDialogClose" />
          <csm-followup-task v-else-if="item && item.$type === ITEM_TYPE_TASK" :value="item" :company-code="item.code" show-company show-sales-person @updated="onFollowupDialogClose" />
        </v-sheet>
      </template>
    </v-data-iterator>

    <cs-confirm-dialog ref="confirm-event-delete" />
    <template v-if="allowAdd">
      <csm-followup-call-dialog ref="followup-call-dialog" :company-code="companyCode" @close="onFollowupDialogClose" />
      <csm-followup-event-dialog ref="followup-event-dialog" :company-code="companyCode" @close="onFollowupDialogClose" />
      <csm-followup-task-dialog ref="followup-task-dialog" :company-code="companyCode" @close="onFollowupDialogClose" />
    </template>

    <sca-pdf-viewer-dialog ref="pdf-document-file-viewer" />
  </div>
</template>

<script>
import _ from 'lodash'
import getClassNameForExtension from 'font-awesome-filetypes'

export default {
  name: 'CompanyFollowUp',
  components: {
    'csm-followup-call-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpCallDialogLite'),
    'csm-followup-event-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpEventDialogLite'),
    'csm-followup-task-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpTaskDialogLite'),
    'csm-followup-call': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpCall'),
    'csm-followup-email': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpEmail'),
    'csm-followup-event': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpEvent'),
    'csm-followup-task': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpTask')
  },
  props: {
    allowAdd: { type: Boolean, default: false },
    beginAt: { type: [String, Date], default: '' },
    companyCode: { type: String, required: true },
    endAt: { type: [String, Date], default: '' },
    salesPersons: { type: Array, default: () => [] }
  },
  data () {
    return {
      filter: [],
      addButtonVisible: false,
      attachmentsMeta: [],
      call: {},
      followUp: {},
      loadingAttachments: false,
      loadingPdf: false,
      search: '',
      tab: 'tab-content'
    }
  },
  watch: {
    companyCode: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue && newValue !== oldValue) this.loadFollowUp()
      }
    }
  },
  computed: {
    canAddCall () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.CREATE)
    },
    canAddEvent () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.CREATE)
    },
    canAddTask () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.CREATE)
    },
    canDeleteCall () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.DELETE)
    },
    canDeleteEmail () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.DELETE)
    },
    canDeleteEvent () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.DELETE)
    },
    canDeleteTask () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.DELETE)
    },
    canListCalls () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.LIST)
    },
    canListEmails () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.LIST)
    },
    canListEvents () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.LIST)
    },
    canListTasks () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.LIST)
    },
    canUpdateCall () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.UPDATE)
    },
    canUpdateEmail () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.UPDATE)
    },
    ICON_TYPES () { return this.$store.getters['followups/ICON_TYPES'] },
    ITEM_TYPES () { return this.$store.getters['followups/ITEM_TYPES'] },
    ITEM_COLORS () { return this.$store.getters['followups/ITEM_COLORS'] },
    ITEM_TYPE_CALL () { return this.$store.getters['followups/ITEM_TYPE_CALL'] },
    ITEM_TYPE_EMAIL () { return this.$store.getters['followups/ITEM_TYPE_EMAIL'] },
    ITEM_TYPE_EVENT () { return this.$store.getters['followups/ITEM_TYPE_EVENT'] },
    ITEM_TYPE_TASK () { return this.$store.getters['followups/ITEM_TYPE_TASK'] },
    sortedData () {
      let list = []

      // Filter
      this.ITEM_TYPES.forEach((itemType, index) => {
        if (!this.filter.length || this.filter.includes(index)) list = list.concat(this.followUp[itemType])
      })

      // Search
      const s = this.search.toLowerCase()
      const result = this.search.length === 0
        ? list
        : _.filter(list, item => {
          // Yes, sadly it's a big, big filter
          return (String(item.opportunity_ids || []).includes(s) ||
          (item.code || '').toLowerCase().includes(s) ||
          (item.number_phone_user || '').toLowerCase().includes(s) ||
          (item.details_call || '').toLowerCase().includes(s) ||
          (item.email_user || '').toLowerCase().includes(s) ||
          (item.subject_email || '').toLowerCase().includes(s) ||
          (item.body_email || '').toLowerCase().includes(s) ||
          (item.from_email || '').toLowerCase().includes(s) ||
          (item.to_emails || []).join(' ').toLowerCase().includes(s) ||
          (item.cc_emails || []).join(' ').toLowerCase().includes(s))
        })

      // Sort
      return _.orderBy(result, ['sortDate'], ['desc'])
    },
    sortedMeta () {
      return _.sortBy(this.attachmentsMeta, ['name'])
    }
  },
  async created () {
    await this.$store.dispatch('$alto-users/loadSalesPersons')
    this.loadFollowUp = _.debounce(this.loadFollowUp, 1000)
    this.resetFollowup()
    this.resetFilter()
  },
  methods: {
    addCall () {
      if (this.canAddCall && this.$refs['followup-call-dialog']) {
        this.$refs['followup-call-dialog'].open()
      }
    },
    addEvent () {
      if (this.canAddEvent && this.$refs['followup-event-dialog']) {
        this.$refs['followup-event-dialog'].open()
      }
    },
    addTask () {
      if (this.canAddTask && this.$refs['followup-task-dialog']) {
        this.$refs['followup-task-dialog'].open()
      }
    },
    isFilterActive (index) {
      return this.filter.length === 0 || this.filter.includes(index)
    },
    async loadFollowUp () {
      if (!this.companyCode) return

      try {
        this.followUp = {}
        this.followUps = []
        let count = 0
        let company
        if (!this.companyCode.includes('ALL')) {
          company = this.companyCode
        }
        if (this.canListCalls) {
          await this.$store.dispatch('followups/calls', { company, beginAt: this.beginAt, endAt: this.endAt })
          this.followUp[this.ITEM_TYPE_CALL] = this.$store.getters['followups/calls']
          this.followUps.concat(this.followUp[this.ITEM_TYPE_CALL])
          count += this.followUp[this.ITEM_TYPE_CALL].length
        } else this.followUp[this.ITEM_TYPE_CALL] = []

        if (this.canListEmails) {
          await this.$store.dispatch('followups/emails', { company, beginAt: this.beginAt, endAt: this.endAt, status: 'sorted' })
          this.followUp[this.ITEM_TYPE_EMAIL] = this.$store.getters['followups/emails']
          this.followUps.concat(this.followUp[this.ITEM_TYPE_EMAIL])
          count += this.followUp[this.ITEM_TYPE_EMAIL].length
        } else this.followUp[this.ITEM_TYPE_EMAIL] = []

        if (this.canListEvents) {
          await this.$store.dispatch('followups/events', { company, beginAt: this.beginAt, endAt: this.endAt })
          this.followUp[this.ITEM_TYPE_EVENT] = this.$store.getters['followups/events']
          this.followUps.concat(this.followUp[this.ITEM_TYPE_EVENT])
          count += this.followUp[this.ITEM_TYPE_EVENT].length
        } else this.followUp[this.ITEM_TYPE_EVENT] = []

        if (this.canListTasks) {
          await this.$store.dispatch('followups/tasks', { company, beginAt: this.beginAt, endAt: this.endAt })
          this.followUp[this.ITEM_TYPE_TASK] = this.$store.getters['followups/tasks']
          this.followUps.concat(this.followUp[this.ITEM_TYPE_TASK])
          count += this.followUp[this.ITEM_TYPE_TASK].length
        } else this.followUp[this.ITEM_TYPE_TASK] = []

        this.resetFilter()
        this.$emit('loaded', count)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    loadFollowUpAttachmentsMeta (attachments) {
      if (!this.companyCode || !attachments || attachments.length === 0) return
      this.loadingAttachments = true
      try {
        const metaList = []
        _.forEach(attachments, async attachment => {
          const meta = await this.$store.dispatch('documents/getFileMeta', attachment.path)
          meta.name = attachment.name
          meta.path = attachment.path
          meta.icon = getClassNameForExtension(attachment.path.split('.').pop())
          meta.url = this.$stratus.services.api.path(`/documents/${attachment.path}?format=raw`)
          metaList.push(meta)
        })
        this.attachmentsMeta = metaList
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
      this.loadingAttachments = false
    },
    onFollowupDialogClose (saved) {
      if (saved) {
        // Reload data
        this.loadFollowUp()
      }
    },
    onSearch (value) {
      this.search = value || ''
    },
    refresh () {
      this.loadFollowUp()
    },
    resetFilter () {
      const filtered = [] // Use a local var to avoir multiple vue DOM update if we were using this.filter.
      this.ITEM_TYPES.forEach((itemType, index) => {
        filtered.push(index)
      })
      this.filter = filtered
    },
    resetFollowup () {
      this.followUp = {}
      this.ITEM_TYPES.forEach(itemType => {
        this.followUp[itemType] = []
      })
    },
    sanitizeBody (content) {
      if (!content) return ''
      // Sanitize and replace \n by html tag BR
      // Windows use \r\n, Linux/Unix use \n and MacOS use \r
      return this.$stratus.services.strings.stripHtmlTags(content).replace(/\r\n/g, '<br>').replace(/\r/g, '<br>').replace(/\n/g, '<br>')
    }
  }
}
</script>
