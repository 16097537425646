<template>
  <span v-if="isLord">
    <!-- Use this component to avoid "RangeError: Maximum call stack size exceeded" -->
    <sca-opportunity-identity :value="value" :label="value" :dense="dense" show-avatar :no-icon="noIcon" link="emit" @link-click="open" />
    <csm-opportunity-dialog ref="opportunity-dialog" />
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OpportunityButton',
  props: {
    noIcon: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    value: { type: [Number, String], required: true }
  },
  data () { return {} },
  computed: {
    ...mapGetters({
      isLord: '$stratus-states/isLord'
    })
  },
  methods: {
    async open () {
      if (!this.isLord || !this.value) return
      try {
        this.$refs['opportunity-dialog'].open(this.value)
      } catch (error) {
        console.error(error)
        this.$stratus.services.notify.error(error)
      }
    }
  }
}
</script>
