<script>
export default {
  data () { return {} },
  render: () => '',
  async mounted () {
    const me = await this.$store.getters['$stratus-states/me']
    if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

    const c = me.customers
    if (c && c.length === 1 && c[0] !== 'ALL') {
      this.$router.replace(`/opportunities/${c[0]}`)
    } else {
      this.$router.replace({ name: 'opportunities-list' })
    }
  }
}
</script>
