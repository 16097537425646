<template>
  <div>
    <v-row v-if="ticketTypes.length" align="center">
      <template v-for="ticketType in ticketTypes">
        <v-col v-if="canCreate(ticketType)" :key="ticketType">
          <v-card v-ripple="{ center: true, class: buttonCreateClass(ticketType) }" outlined @click="create(ticketType)" class="py-2">
            <v-row align="center">
              <v-col class="ml-auto shrink">
                <v-icon large :color="iconColor(ticketType)">
                  {{ icon(ticketType) }}
                </v-icon>
              </v-col>
              <v-col class="shrink text-no-wrap mr-auto">
                {{ $t(`ticket-type-${ticketType}`) }}
                <div class="text-caption">
                  {{ $t(`ticket-type-${ticketType}-help`) }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col cols="12">
        <csm-ticket-grid ref="user-ticket-grid" :options="gridOptions" :columns="gridColumns" pref-key="ticketList" filter-ticket-state filter-project inline-filter :auto-refresh-delay="DEFAULT_REFRESH_DELAY" />
      </v-col>
    </v-row>

    <csm-ticket-new-incident ref="form-ticket-new-incident" :visible="showIncidentDialog" :descriptor="descriptor" @closeDialog="closeDialog" />
    <csm-ticket-new-request ref="form-ticket-new-request" :visible="showRequestDialog" :descriptor="descriptor" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const DEFAULT_REFRESH_DELAY = 5 // Minutes

export default {
  name: 'TicketsListUsers',
  components: {
    'csm-ticket-grid': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketGrid'),
    'csm-ticket-new-incident': () => import(/* webpackChunkName: "views" */ '@/components/Tickets/TicketNewIncident'),
    'csm-ticket-new-request': () => import(/* webpackChunkName: "views" */ '@/components/Tickets/TicketNewRequest')
  },
  data () {
    return {
      DEFAULT_REFRESH_DELAY,
      categories: null,
      descriptor: [],
      descriptorType: '',
      showIncidentDialog: false,
      showRequestDialog: false,
      ticketTypes: []
    }
  },
  watch: {
    isLord (newValue, oldValue) {
      if (newValue === true) {
        this.$router.replace('/tickets-list')
      }
    }
  },
  async created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Tickets'))
    this.categories = await this.$store.dispatch('$alto-ticketing/getLiteCategories')
  },
  mounted () {
    this.isLogged = this.$stratus.services.auth.isLogged()
    this.ticketTypes = ['request', 'incident']
    if (this.$store.getters['$stratus-states/isLord']) {
      this.$router.replace('/tickets-list')
    }
  },
  computed: {
    ...mapGetters({
      isLord: '$stratus-states/isLord'
    }),
    gridColumns () {
      // Columns for ticket grid. {String} value: {Boolean} hidden (if true)
      return {
        id: false,
        name: false,
        type: false,
        severity: false,
        state: false,
        id_sphere: false,
        id_owner: true,
        update_at: false,
        create_by: false
      }
    },
    gridOptions () {
      return {
        create: false,
        sortBy: 'update_at',
        sortDescending: true
      }
    }
  },
  methods: {
    buttonCreateClass (ticketType) {
      if (ticketType === this.$alto?.defines?.TICKETS?.TICKET_TYPE_INCIDENT) return 'danger--text'
      if (ticketType === this.$alto?.defines?.TICKETS?.TICKET_TYPE_REQUEST) return 'primary--text'
      return ''
    },
    canCreate (ticketType) {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, `c-lite-${ticketType}`)
    },
    closeDialog () {
      this.showIncidentDialog = false
      this.showRequestDialog = false
      if (this.$refs['user-ticket-grid']) this.$refs['user-ticket-grid'].refresh()
    },
    create (ticketType) {
      if (![this.$alto?.defines?.TICKETS?.TICKET_TYPE_INCIDENT, this.$alto?.defines?.TICKETS?.TICKET_TYPE_REQUEST]?.includes(ticketType)) return

      this.descriptorType = ticketType
      this.descriptor = this.categories[ticketType]

      if (ticketType === this.$alto?.defines?.TICKETS?.TICKET_TYPE_INCIDENT) {
        if (this.$refs['form-ticket-new-incident']) {
          this.$refs['form-ticket-new-incident'].reset()
          this.showIncidentDialog = true
        }
      } else if (ticketType === this.$alto?.defines?.TICKETS?.TICKET_TYPE_REQUEST) {
        if (this.$refs['form-ticket-new-request']) {
          this.$refs['form-ticket-new-request'].reset()
          this.showRequestDialog = true
        }
      }
    },
    icon (ticketType) {
      return this.$alto?.defines?.TICKETS?.ICONS_TICKET_TYPE?.[ticketType] || ''
    },
    iconColor (ticketType) {
      return this.$alto?.defines?.TICKETS?.COLORS_TICKET_TYPE?.[ticketType]?.rgb || ''
    }
  }
}
</script>
