<template>
  <div>
    <sca-advanced-store-grid :options="gridOptions" resource="spheres" :columns="columns" :filters="filters" :custom-search="customSearch" @createItem="createItem" @deleteItem="deleteItem" @showItem="showItem" @updateItem="updateItem" @resetFilters="resetFilters" ref="sphere-grid">
      <template slot="legend">
        <v-row no-gutters justify="center">
          <v-col class="shrink text-no-wrap">
            <cs-alert-panel dense type="info" :text="$t('Spheres allow you to organize your quotations and orders.')" />
          </v-col>
        </v-row>
      </template>

      <template v-slot:item-name="{ row }">
        <sca-sphere-identity :value="row" link="emit" @link-click="updateItem({ success: true, item: { id: row.id } })" />
      </template>

      <template v-slot:item-code="{ itemRaw }">
        <sca-company-identity :value="itemRaw" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(itemRaw)" />
      </template>

      <template slot="search-append">
        <v-row dense>
          <v-col v-show="companies.length > 1">
            <sca-customer-select v-model="companiesSearch" :label="$t('Company')" @input="companiesSearchChange" clearable show-email show-phone link="emit" @link-click="openCompany(companiesSearch)" />
          </v-col>
        </v-row>
      </template>
    </sca-advanced-store-grid>

    <csm-sphere-dialog ref="sphere-dialog" @closeDialog="closeDialog" />
    <csm-company-dialog ref="company-dialog" />
  </div>
</template>

<script>
export default {
  name: 'SpheresList',
  data () {
    return {
      companies: [],
      companiesCache: {},
      companiesSearch: '',
      filters: ['name', 'description', 'company'],
      isLogged: false,
      sphere: {},
      sphereTypeSearch: ''
    }
  },
  computed: {
    columns () {
      return [
        {
          text: 'Name',
          value: 'name'
        }, {
          text: 'Description',
          value: 'description'
        }, {
          text: 'Company',
          value: 'code'
        }, {
          text: 'Is default',
          value: 'is_default',
          hidden: false,
          align: 'center',
          alignValue: 'center',
          format: this.$stratus.services.fieldRenderers.BOOLEAN_CHECK
        }, {
          text: 'Created at',
          value: 'create_at',
          hidden: true,
          align: 'right',
          alignValue: 'right',
          format: this.$stratus.services.fieldRenderers.DATETIME_SHORT
        }, {
          text: 'Created by',
          hidden: true,
          value: 'create_by'
        }, {
          text: 'Updated at',
          value: 'update_at',
          align: 'right',
          alignValue: 'right',
          format: this.$stratus.services.fieldRenderers.DATETIME_SHORT
        }, {
          text: 'Updated by',
          hidden: true,
          value: 'update_by'
        }
      ]
    },
    gridOptions () {
      return {
        allowColumnsVisible: true,
        allowContextMenuOnCell: true,
        create: this.isLogged,
        customShowItem: true,
        customUpdateItem: true,
        delete: false,
        messages: {
          deleteItem: 'Please, confirm the deletion of the {name} sphere?'
        },
        foreignFields: ['id', 'color'],
        key: 'id',
        multiSelect: false,
        notifyErrors: true,
        search: this.isLogged,
        show: false,
        sortBy: 'name',
        sortDescending: false,
        update: false
      }
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] }
  },
  methods: {
    companiesSearchChange (value) {
      if (this.$refs['sphere-grid']) this.$refs['sphere-grid'].fetchData()
    },
    closeDialog () {
      if (this.$refs['sphere-grid']) this.$refs['sphere-grid'].fetchData({ forceReload: true })
    },
    async createItem () {
      this.$refs['sphere-dialog'].open({})
    },
    customSearch () {
      const cusSearch = []
      if (this.companiesSearch) {
        cusSearch.push({
          column: 'code',
          search: this.companiesSearch
        })
      }
      return cusSearch
    },
    loadCompanies () {
      this.$store.dispatch('$alto-companies/list')
        .then(() => {
          this.companiesCache = this.$store.getters['$alto-companies/cache']()
          this.companies = this.$stratus.services.fields.ObjectToSelectItems(this.companiesCache, { keyInValue: true })
          this.customers = [this.myCompany]
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    resetFilters () {
      this.companiesSearch = ''
    },
    async showItem ({ success, error, item }) {
      if (!success) {
        if (error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(error)
        return
      }
      this.$refs['sphere-dialog'].open(item.id)
    },
    async updateItem ({ success, error, item }) {
      if (!success) {
        if (error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(error)
        return
      }
      this.$refs['sphere-dialog'].open(item.id)
    },
    deleteItem ({ success, error, item }) {
      if (success) {
        this.$stratus.services.notify.success(this.$t('Sphere "{name}" deleted.', item))
      } else {
        if (error && error.status === 403) {
          this.$stratus.services.notify.warning(this.$t('You\'re not allowed to delete sphere "{name}".', item))
        } else {
          this.$stratus.services.notify.error(error)
        }
      }
    }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Spheres'))
  },
  async mounted () {
    this.isLogged = this.$stratus.services.auth.isLogged()
    await this.$store.dispatch('$stratus-states/getMe')
    const me = this.$store.getters['$stratus-states/me']
    if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

    this.loadCompanies()
  }
}
</script>
