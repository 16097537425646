<template>
  <v-row dense align="center">
    <v-col cols="3">
      <v-text-field v-model="service" :label="$t('Service')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.permission]" @change="onChange" />
    </v-col>
    <v-col cols="3">
      <v-text-field v-model="resource" :label="$t('Resource')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.permission]" @change="onChange" />
    </v-col>
    <v-col>
      <v-combobox v-model="permissions" :label="$t('Permissions')" :placeholder="$t('Input permissions pressing enter key to create each of them')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.permission]" multiple chips deletable-chips small-chips @change="onChange" />
    </v-col>
    <v-col class="shrink">
      <v-icon small color="danger" @click="remove">
        $vuetify.icons.delete
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'PermissionInput',
  props: {
    value: { type: String, required: true }
  },
  data () {
    return {
      service: '',
      resource: '',
      permissions: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue !== oldValue) this.extractPermission(newValue)
      }
    }
  },
  methods: {
    extractPermission (value) {
      const service = value.split('/')
      if (service?.length === 2) {
        this.service = service[0]
        const resource = service[1].split(':')
        if (resource.length === 2) {
          this.resource = resource[0]
          this.permissions = resource[1].split(',')
        }
      }
      this.permissions = _.uniq(_.compact(this.permissions))
    },
    onChange (value) {
      this.permissions = _.uniq(_.compact(this.permissions))
      this.$emit('change', `${this.service}/${this.resource}:${this.permissions.join(',')}`)
    },
    remove () {
      this.$emit('delete')
    }
  }
}
</script>
