<template>
  <div v-if="products && products.length">
    <p>
      <v-subheader>
        {{ title }} <v-chip small>
          {{ products.length }}
        </v-chip>
      </v-subheader>
      <v-row>
        <v-col class="py-0" cols="12">
          <cs-color-divider class="ml-4" color="orange" />
        </v-col>
      </v-row>
    </p>

    <div class="ml-6">
      <template v-for="(child, index) in products">
        <v-form :key="index">
          <v-row>
            <v-col class="shrink">
              <span>{{ index + 1 }} ></span>
            </v-col>

            <v-col class="grow" cols="6" md="4" v-if="child.ref !== undefined">
              <sca-product-select v-model="child.ref" :readonly="readonly" :label="$t('Product')" class="required" />
            </v-col>

            <v-col class="grow" cols="6" md="4" v-if="child.type !== undefined">
              <product-type-select v-model="child.type" :readonly="readonly" />
            </v-col>

            <v-col class="shrink" cols="6" md="2" v-if="child.quantity && Array.isArray(child.quantity)">
              <v-text-field v-model="child.quantity" :rules="[$stratus.services.form.rules.required]" class="required" :label="$t('Quantity')" :readonly="readonly" />
            </v-col>

            <v-col class="shrink" cols="6" md="2" v-if="child.quantity && child.quantity.min !== undefined">
              <cs-integer-input v-model="child.quantity.min" :label="$t('Minimum quantity')" :readonly="readonly" />
            </v-col>

            <v-col class="shrink" cols="6" md="2" v-if="child.quantity && child.quantity.max !== undefined">
              <cs-integer-input v-model="child.quantity.max" :label="$t('Maximum quantity')" :readonly="readonly" />
            </v-col>

            <v-col class="shrink" cols="6" md="2" v-if="child.nb !== undefined">
              <v-text-field v-model="child.nb" :rules="[$stratus.services.form.rules.required]" class="required" :label="$t('Use quantity of')" :readonly="readonly" />
            </v-col>

            <v-col class="shrink" cols="6" md="1" v-if="child.unit !== undefined">
              <v-text-field v-model="child.unit" :label="$t('Unit')" :readonly="readonly" />
            </v-col>

            <v-col class="shrink" v-if="!readonly">
              <v-btn rounded small @click="deleteChild(index, child)">
                {{ $t('Remove') }}
              </v-btn>
            </v-col>
          </v-row>

          <product-node v-if="child.ref" :product="child" />
        </v-form>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductSubNode',
  components: {
    // Mandatory to avoid circular reference https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    'product-type-select': () => import('./ProductTypeSelect'),
    'product-node': () => import('./ProductNode')
  },
  props: {
    products: { type: Array, default: () => [] },
    readonly: { type: Boolean, default: false },
    section: { type: String, required: true },
    title: { type: String, default: '' }
  },
  data () { return {} },
  computed: {
    productList () {
      return this.$store.getters['products/list']
    },
    productTypes () {
      return this.$store.getters['products/types']
    }
  },
  methods: {
    deleteChild (index, child) {
      this.$emit('deleteChild', { section: this.section, index, child })
    }
  }
}
</script>
