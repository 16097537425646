<template>
  <v-row v-if="ticket" align="start" no-gutters>
    <v-col v-if="isLord" class="text-center">
      <div class="text-caption">
        {{ $t('Passed time') }}
      </div>
      <div>
        {{ ticket.passed_time ? $stratus.services.format.secondsToDays(60 * ticket.passed_time, SECONDS_BY_WORK_DAY) : '-' }}
      </div>
    </v-col>

    <v-col class="text-center">
      <div class="text-caption">
        {{ $t('Billable passed time') }}
      </div>
      <div>
        {{ ticket.passed_time_billable ? $stratus.services.format.secondsToDays(60 * ticket.passed_time_billable, SECONDS_BY_WORK_DAY) : '-' }}
      </div>
      <div v-if="ticket.passed_time_cob_billable">
        <span class="text-caption">
          ({{ $t('COB') }})
        </span>
        {{ $stratus.services.format.secondsToDays(60 * ticket.passed_time_cob_billable) }}
      </div>
    </v-col>

    <v-col v-if="isLord" class="text-center">
      <div class="text-caption">
        {{ $t('Remaining time') }}
      </div>
      <div>
        {{ $stratus.services.format.secondsToDays(60 * (ticket.estimated_time - ticket.passed_time_billable - ticket.passed_time_cob_billable), SECONDS_BY_WORK_DAY) || '-' }}
        <v-icon v-if="ticket.estimated_time < (ticket.passed_time_billable + ticket.passed_time_cob_billable)" color="danger">
          $vuetify.icons.time
        </v-icon>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TicketPassedTime',
  props: {
    ticket: { type: Object, required: true, default: null }
  },
  data () {
    return {
      SECONDS_BY_WORK_DAY: this.$alto.defines.TICKETS.SECONDS_BY_WORK_DAY
    }
  },
  computed: {
    isLord () { return this.$store.getters['$stratus-states/isLord'] }
  }
}
</script>
