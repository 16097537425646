<template>
  <span v-if="inline">
    <template v-for="(price, key) in prices">
      <span v-if="gratis || price > 0" :key="key">
        {{ $t('{name}: {price}', { name: $t('billing-' + key), price: $n(price * quantity, 'currency') }) }}
      </span>
    </template>
  </span>
  <span v-else>
    <template v-for="(price, key) in prices">
      <div v-if="gratis || price > 0" :key="key">
        {{ $t('{name}: {price}', { name: $t('billing-' + key), price: $n(price * quantity, 'currency') }) }}
      </div>
    </template>
  </span>
</template>

<script>
import { DEFAULT_PRICES } from '@/services/prices'

export default {
  name: 'ProductPricesPeriodicity',
  props: {
    gratis: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    prices: { type: Object, default () { return { ...DEFAULT_PRICES } } },
    quantity: { type: Number, default: 1 }
  },
  data () {
    return {}
  }
}
</script>
