<template>
  <div class="background-blurred">
    <div class="d-flex">
      <div class="mr-2">
        <cs-month-picker v-model="dateBegin" :disabled="loading" :label="$t('From date')" @change="onInputChange" />
      </div>

      <div class="mr-2">
        <cs-month-picker v-model="dateEnd" :disabled="loading" :label="$t('To date')" @change="onInputChange" />
      </div>

      <cs-percent-input v-model="successPercent" :disabled="loading" :label="$t('Minimal percentage of success')" @input="onInputChange" class="ma-0 pa-0" hide-details />

      <div class="align-self-center ml-auto">
        <cs-refresh-button :loading="loading" @click="onInputChange" />
      </div>
    </div>

    <v-row dense align="center">
      <v-col cols="12">
        <cs-chart-bar :chart-data="barChartData" :options="barChartOptions" ref="opportunity-forecast-bar-chart" />

        <v-divider class="my-3" />
        <div v-show="label.length" class="primary--text text-h6">
          {{ label }} — {{ month }}
        </div>

        <csm-opportunity-grid v-show="query" :query="query" :options="opportunityGridOptions" ref="opportunity-forecast-grid" @dataLoaded="dataLoaded" @dataLoading="dataLoading" />
      </v-col>
    </v-row>

    <v-row v-show="!query" dense justify="center">
      <v-col class="shrink text-no-wrap">
        <cs-alert-panel dense type="info" :text="$t('Click on a bar in the graph to see the opportunities.')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'

const PRICE_DIGITS = 2

// Attributes of an opportunity that will have a graph
const ATTRIBUTES_FOR_SERIES = ['booking', /* FUTURE 'netbooking_gross', */ 'setup_gross']
const ATTRIBUTES_FOR_SERIES_VISIBLE = ['booking', 'setup_gross'] // All other will be hidden by default

export default {
  name: 'OpportunitiesForecast',
  components: {
    'csm-opportunity-grid': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/OpportunitiesGrid')
  },
  props: {},
  data () {
    return {
      COLORS: this.$alto.defines.COLORS.CHART_COLOR_PALETTE,
      categoryColors: {},
      dateBegin: null,
      dateEnd: null,
      month: '',
      opportunities: [],
      query: '',
      barChartData: {
        labels: [],
        datasets: [{ data: [] }]
      },
      label: '',
      loading: false,
      opportunityGridOptions: {
        // disable advanced search to avoid collision with forged query from computeQuery
        allowAdvancedSearch: false
      },
      stackBar: false,
      successPercent: 0
    }
  },
  computed: {
    barChartOptions () {
      return {
        // Click on a bar to load it's opportunities
        onClick (e) {
          /* --- IMPORTANT! ---
           * This is a native click event, outside Vue scope!
           * Value of «this» is not vue but the chart instance this event is plug to.
           */
          const element = this.getElementAtEvent(e)
          if (element.length > 0) {
            // Create a focus effect by changing the background color of the bar
            // Reset all colors
            _.forEach(this.data.datasets, dataset => {
              dataset.backgroundColor = dataset._backgroundColor
            })
            // Set the focus
            const bc = new Array(this.data.datasets[element[0]._datasetIndex].data.length).fill(this.data.datasets[element[0]._datasetIndex]._backgroundColor) // Restore native color
            bc[element[0]._index] = this.data.datasets[element[0]._datasetIndex].borderColor
            this.data.datasets[element[0]._datasetIndex].backgroundColor = bc
            // Forge a query to load opportunities for this bar
            this.data.datasets[element[0]._datasetIndex].computeQuery(
              this.data.datasets[element[0]._datasetIndex].query[element[0]._index],
              this.data.datasets[element[0]._datasetIndex].label,
              this.data.labels[element[0]._index]
            )
            this.update()
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: this.stackBar
          }],
          yAxes: [{
            id: 'price-axis',
            type: 'linear',
            ticks: {
              callback: (tick) => {
                return this.$t('{count}{unit}', { count: tick, unit: '€' })
              }
            }
          }]
        },
        tooltips: {
          enabled: true,
          intersect: true,
          callbacks: {
            bodyFontSize: 16,
            label: (tooltipItems, data) => {
              return [
                this.$t('{label}: {value}{unit}.', {
                  label: data.datasets[tooltipItems.datasetIndex].label,
                  value: this.formatPrice(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]),
                  unit: data.datasets[tooltipItems.datasetIndex].unit
                })
              ]
            }
            // FUTURE
            // footer: (tooltipItems, data) => {
            //   return this.$tc('{count} opportunities', data.datasets[tooltipItems[0].datasetIndex].counts[tooltipItems[0].index], { count: data.datasets[tooltipItems[0].datasetIndex].countsWon[tooltipItems[0].index] })
            // }
          }
        }
      }
    },
    months () {
      const months = (Object.keys(this.opportunities) || []).sort()
      return this.reverseOrder ? months.reverse() : months
    }
  },
  methods: {
    computeQuery (query, label, month) {
      this.query = query || ''
      this.label = label || ''
      this.month = month || ''
    },
    createGraphData () {
      this.categoryColors = {}
      let colorIndex = 0

      const months = this.months
      const dataSets = []
      const dots = {} // Array of values by attribute
      const queries = {} // Opportunity requests by attribute, by month
      const countsWon = {} // Counts of the number of won opportunities, by attribute, by month
      const labels = []
      if (this.opportunities && months && months.length > 0) {
        // Graph is per month
        _.forEach(months, month => {
          // Avoid an attribute that is not a month, like «total»
          if (this.$stratus.dt(month).isValid()) {
            labels.push(this.$stratus.dt(month).format('MM/YYYY'))

            // FUTURE créer un dataset [netbooking_gross, booking] pour this.stackBar = true

            // Retrieve each attributes that is needed
            _.forEach(ATTRIBUTES_FOR_SERIES, attr => {
              if (!queries[attr]) queries[attr] = []
              queries[attr].push(this.opportunities[month][attr].query_previsional || '')

              if (!countsWon[attr]) countsWon[attr] = []
              countsWon[attr].push(this.opportunities[month][attr].nb_opportunities || 0)

              if (!dots[attr]) dots[attr] = [] // First dot
              if (this.opportunities[month][attr]) { // Object like «opportunities['2022-01'].booking»
                dots[attr].push(this.opportunities[month][attr].total_previsional || 0.0)
              } else dots[attr].push(0.0)
            })
          }
        })
        _.forEach(ATTRIBUTES_FOR_SERIES, (attr) => {
          if (!this.categoryColors[attr]) {
            this.categoryColors[attr] = this.COLORS[colorIndex]
            colorIndex--
            if (colorIndex < 0) colorIndex = this.COLORS.length - 1
          }
          dataSets.push({
            label: this.$t(`forecast-opportunities-${attr}`),
            backgroundColor: this.categoryColors[attr].opacity80,
            _backgroundColor: this.categoryColors[attr].opacity80,
            borderColor: this.$alto.defines.COLORS.PRIMARY,
            hoverBorderColor: this.$alto.defines.COLORS.PRIMARY,
            borderWidth: 0,
            data: dots[attr],
            query: queries[attr], // Internal use
            countsWon: countsWon[attr], // Internal use
            months, // Internal use
            unit: this.$t('CURRENCY.symbol'), // Internal use
            hidden: !ATTRIBUTES_FOR_SERIES_VISIBLE.includes(attr),
            computeQuery: this.computeQuery
          })
        })
      }
      this.barChartData.labels = labels
      this.barChartData.datasets = dataSets
      this.$refs['opportunity-forecast-bar-chart'].update()
    },
    dataLoaded () {
      this.loading = false
    },
    dataLoading () {
      this.loading = true
    },
    formatPrice (price) {
      return this.$stratus.services.format.asDigits(price, PRICE_DIGITS) // Keep 2 digits
    },
    onInputChange () {
      this.debouncedRefresh()
    },
    async refresh () {
      try {
        this.loading = true
        const data = await this.$store.dispatch('opportunities/listForecast', {
          dateBegin: this.$stratus.dt(this.dateBegin).startOf('month'),
          dateEnd: this.$stratus.dt(this.dateEnd).endOf('month'),
          successPercent: this.successPercent
        })
        this.opportunities = data
        this.createGraphData()
        this.computeQuery()
        this.refreshGrid()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    refreshGrid () {
      if (this.$refs['opportunity-forecast-grid']) this.$refs['opportunity-forecast-grid'].refreshGrid()
    }
  },
  created () {
    this.dateBegin = this.$stratus.dt().subtract(1, 'month').startOf('month').format('YYYY-MM')
    this.dateEnd = this.$stratus.dt().add(5, 'month').endOf('month').format('YYYY-MM')
    this.debouncedRefresh = _.debounce(this.refresh, 1000)
  },
  mounted () {
    this.refresh()
  }
}
</script>
