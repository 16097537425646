<template>
  <v-row align="center" dense>
    <v-col cols="12">
      <sca-product-domain-slider mandatory show-arrows allow-all @change="filterDomain" ref="subscription-domain-slider" />
    </v-col>

    <v-col cols="12">
      <sca-advanced-store-grid v-if="columns" :options="options" resource="orders/oneline" :columns="columns" :filters="filters" :custom-search="customSearch" :class-for-data-line-cb="classForDataLine" :csv-export="computeCsvData" @showItem="showItem" @updateItem="showItem" @selectItems="selectItems" @createItem="gotoCatalog" @resetFilters="resetFilters" ref="subscriptionsList">
        <v-col slot="options-append">
          <v-checkbox v-if="showProductsSwitch" v-model="hideProducts" :label="$t(productSwitchTitle)" dense hide-details @change="refreshGrid" />
        </v-col>

        <!-- // DEPRECATED
        <template slot="legend">
          <v-row no-gutters justify="center">
            <v-col class="shrink text-no-wrap">
              <cs-alert-panel :value="hideProducts" small dense type="info" :text="$t('status-Hide tickets. You can change this in the grid options.')" />
            </v-col>
          </v-row>
        </template> -->

        <v-row slot="search-append">
          <v-col v-show="companies.length > 1">
            <sca-customer-select v-show="companies.length > 1" v-model="companiesSearch" :label="$t('Company')" @input="refreshGrid" clearable show-email show-phone hide-details dense link="emit" @link-click="openCompany(companiesSearch)" />
          </v-col>
        </v-row>

        <v-row slot="search-row" align="center" dense class="px-2">
          <v-col v-if="canReadProjects" cols="6" md="4" lg="2">
            <sca-sphere-select v-model="sphereSearch" :customer="companiesSearch" :label="$t('Sphere')" clearable hide-details @input="refreshGrid" />
          </v-col>
          <!-- <v-col v-if="isLord" cols="6" md="4" lg="2">
            <sca-order-stop-reason-select v-model="stopReasonSearch" clearable :label="$t('Reason for final stop')" hide-details @input="refreshGrid" />
          </v-col> -->
          <v-col cols="6" md="4" lg="8">
            <cs-truncate-select :items="orderStates" v-model="orderStateSearch" :label="$t('State')" @input="debouncedRefreshGrid" clearable multiple hide-details />
          </v-col>
        </v-row>

        <template slot="search-buttons-append">
          <v-menu v-model="menuCmdbExtract" :close-on-click="false" :close-on-content-click="false" transition="slide-y-transition" offset-y bottom min-width="290px" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-on="on" v-bind="attrs" rounded>
                <v-icon small left>
                  $vuetify.icons.database
                </v-icon>
                {{ $t('CMDB') }}
                <v-icon small right :class="{ 'rotate-once-180': menuCmdbExtract }">
                  $vuetify.icons.expand
                </v-icon>
              </v-btn>
            </template>
            <v-card outlined class="pa-2">
              <v-card-title>
                <v-icon class="menu-icon--text">
                  $vuetify.icons.database
                </v-icon>
                <div class="text-h6 pl-2">
                  {{ $t('CMDB Extract') }}
                </div>
              </v-card-title>

              <v-row dense>
                <v-col cols="12" v-if="companies.length > 1">
                  <p>{{ $t('Choose the companies to extract their CMDB in CSV format. You will quickly receive an email with it.') }}</p>
                  <sca-customer-select v-model="customers" :label="$t('Customers')" :allow-all="isLord" multiple clearable />
                </v-col>
                <v-col cols="12" v-else>
                  {{ $t('Your CMDB will be converted to a CSV file. You will quickly receive an email with it. Please confirm.') }}
                </v-col>
                <v-col cols="12">
                  <v-switch v-model="withRef" :label="$t('Show product references instead of product names')" dense hide-details />
                </v-col>
              </v-row>

              <v-card-actions>
                <v-spacer />
                <v-btn text rounded @click="closeMenuExtractCmdb">
                  {{ $t('Cancel') }}
                </v-btn>
                <v-btn rounded class="main-button" @click="sendCmdb">
                  <v-icon small left>
                    $vuetify.icons.email
                  </v-icon>
                  {{ $t('Send') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </template>

        <template v-slot:item-code="{ itemRaw }">
          <sca-company-identity :value="itemRaw" show-avatar show-email show-phone show-sales-person :text-resume="20" link="emit" @link-click="openCompany(itemRaw)" />
        </template>

        <template v-slot:item-ref="{ itemRaw, row }">
          <sca-product-identity :value="itemRaw" :company="row.code" :label="itemRaw" show-avatar link="emit" @link-click="openProduct(itemRaw)" />
        </template>

        <template v-slot:item-cmd_id="{ itemRaw }">
          <sca-order-identity :value="itemRaw" :label="itemRaw" show-avatar link="emit" @link-click="openOrder(itemRaw)" />
        </template>

        <template v-slot:item-opportunity_id="{ itemRaw }">
          <cs-expand-list v-if="isLord && isArray(itemRaw) && itemRaw.length > 0" :items="itemRaw">
            <template v-slot:expand-list-item="{ item }">
              <sca-opportunity-identity :value="item" :label="item" show-avatar link="emit" @link-click="openOpportunity(item)" />
            </template>
          </cs-expand-list>
        </template>

        <template v-slot:item-ids_ext="{ itemRaw, row }">
          <div v-if="isLord" class="d-flex">
            <div v-if="itemRaw && isObject(itemRaw)">
              <csm-order-tickets :tickets="itemRaw" hide-label column />
            </div>
            <div v-if="row.ids_intern?.tickets" class="d-flex align-start">
              <div v-for="(tickets, index) in row.ids_intern?.tickets" :key="index" class="pr-1">
                <sca-ticket-identity v-for="ticket in tickets" :key="`${index}-${ticket}`" :value="ticket" show-card link="emit" @link-click="openTicket" />
              </div>
            </div>
          </div>
          <span v-else>-</span>
        </template>

        <template v-slot:item-unique_ids="{ itemRaw }">
          <cs-expand-list v-if="isLord && isArray(itemRaw) && itemRaw.length > 0" :items="itemRaw">
            <template v-slot:expand-list-item="{ item }">
              <sca-identity :value="[item]" small can-copy />
            </template>
          </cs-expand-list>
        </template>

        <template v-slot:item-state="{ itemRaw }">
          <sca-order-state v-if="itemRaw" :value="itemRaw" dense hide-text full-width />
        </template>

        <template v-slot:item-id_sphere="{ itemRaw }">
          <sca-sphere-identity :value="itemRaw" link="emit" @link-click="openSphere(itemRaw)" />
        </template>

        <template v-slot:actions-append="{ row }">
          <template v-if="row.state === 'completed' && row.periodicity !== 'O' && canModifyOrStopProduct">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" small icon @click="showDeliveredProduct(row)">
                  <v-icon small color="primary">
                    $vuetify.icons.setup
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Modify or stop product') }}</span>
            </v-tooltip>
          </template>

          <template v-if="canOrderSameProduct(row)">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" small icon :loading="orderingSameProduct === row.id" :disabled="orderingSameProduct > 0" @click="orderSameProduct(row.id)">
                  <v-icon small :color="isProductAvailable(row) ? 'primary' : 'danger'">
                    $vuetify.icons.cart
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('Order same product') }} ({{ currentCart.name ? currentCart.name: $t('New cart') }})
                <span v-if="!isProductAvailable(row)">
                  — {{ $t('Not available') }}
                </span>
              </span>
            </v-tooltip>
          </template>

          <template v-if="canDeliver(row)">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" small icon color="success" :loading="isDelivering" @click="deliverProduct(row)">
                  <v-icon small>
                    $vuetify.icons.complete
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Mark this product as delivered.') }}</span>
            </v-tooltip>
          </template>

          <sca-customer-new-request-incident v-if="!['canceled', 'stopped'].includes(row.state) && row.ref_domain !== 'service'" small :tooltip="requestIncidentTooltip" @create-ticket="createTicket(row, $event)" />
        </template>

        <v-row slot="multi-select" justify="center" dense class="mt-4">
          <v-col cols="12">
            <v-form :disabled="isActionRunning">
              <v-row dense align="baseline" justify="center">
                <v-col class="shrink">
                  <v-icon small>
                    $vuetify.icons.run
                  </v-icon>
                </v-col>

                <v-col cols="4">
                  <v-select dense v-model="selectionAction" :items="selectionActions" clearable hide-details :placeholder="$t('Select an action to perform...')" @change="validateSelection" />
                </v-col>

                <v-col v-show="selectionAction === LINE_ACTIONS.CHANGE_PROJECT">
                  <sca-sphere-select v-model="newSphere" :label="$t('Sphere')" :customer="sectionProjectForCompany" allow-empty clearable dense hide-details @change="validateSelection" />
                </v-col>

                <v-col>
                  <span class="mr-2">
                    {{ $tc('on selected line | on {count} lines', selection.length, { count: selection.length }) }}
                  </span>
                  <v-btn rounded small color="main-button" @click="actionRun" :disabled="!canRunAction" :loading="isActionRunning">
                    {{ $t('Run...') }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense justify="center">
                <v-col class="shrink text-no-wrap">
                  <cs-alert-panel v-show="selectionError" dense type="error" :text="selectionError" />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </sca-advanced-store-grid>
    </v-col>

    <csm-product-modify-dialog ref="delivered-product-modify-dialog" @addToCart="updateProduct" @productUpdated="productUpdated" />
    <csm-subscription-dialog ref="subscription-dialog" @modify-product="showDeliveredProduct" @closeDialog="closeDialog" />

    <cs-confirm-dialog ref="confirm-order-dialog" />
    <csm-opportunity-dialog ref="opportunity-dialog" />
    <csm-order-dialog ref="order-dialog" />
    <csm-sphere-dialog ref="sphere-dialog" />
    <csm-product-dialog ref="product-dialog" readonly />
    <csm-company-dialog ref="company-dialog" />
    <csm-ticket-dialog ref="ticket-dialog" />
    <csm-ticket-new-request ref="form-ticket-new-request" :visible="showRequestDialog" :descriptor="descriptor" :subscription="subscription" @closeDialog="closeTicketDialog" />
    <csm-ticket-new-incident ref="form-ticket-new-incident" :visible="showIncidentDialog" :descriptor="descriptor" :subscription="subscription" :product="product" @closeDialog="closeTicketDialog" />

    <csm-order-product-date-dialog ref="order-product-date-dialog">
      <v-form slot="content">
        <div v-if="deliveredProduct && refNeedCaption(deliveredProduct.ref)">
          <v-text-field v-model="deliveredProduct.caption" :label="$t('Caption')" :rules="captionRules(deliveredProduct)" counter="128" :disabled="!canDeliver(deliveredProduct)" />
        </div>
        <cs-date-picker v-model="productDate" :min="minDateEnd" :max="maxDateEnd" />
      </v-form>
    </csm-order-product-date-dialog>
  </v-row>
</template>

<script>
import _ from 'lodash'

import config from '@/config'

const DEFAULT_STATES = ['tech-awaiting', 'deposit-awaiting', 'pending', 'completed', 're-pending', 'stopping']

const COLUMN_SPHERE_ID = 'id_sphere'

export default {
  name: 'SubscriptionsList',
  components: {
    'csm-order-product-date-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Orders/OrderProductDate'),
    'csm-order-tickets': () => import(/* webpackChunkName: "components" */ '@/components/Orders/OrderTickets'),
    'csm-product-modify-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Products/ProductModifyDialog'),
    'csm-subscription-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Subscriptions/SubscriptionDialog'),
    'csm-ticket-new-incident': () => import(/* webpackChunkName: "views" */ '@/components/Tickets/TicketNewIncident'),
    'csm-ticket-new-request': () => import(/* webpackChunkName: "views" */ '@/components/Tickets/TicketNewRequest')
  },
  data () {
    return {
      isObject: _.isObject,
      isArray: _.isArray,
      isActionRunning: false,
      isLogged: false,
      canModifyProduct: false,
      columns: null,
      companies: [],
      companiesCache: {},
      companiesSearch: '',
      customers: [],
      descriptor: [],
      deliveredProduct: undefined,
      domainFilter: null,
      filters: ['date_begin', 'date_end', 'cmd_id', COLUMN_SPHERE_ID, 'id_jira', 'id_service_now'],
      hideProducts: true, // Default is to hide product from list in config.subscriptions.list.hideProducts
      isDelivering: false,
      isLoadingProjects: false,
      maxDateEnd: null,
      menuCmdbExtract: false,
      minDateEnd: null,
      options: {
        advancedSearchFieldsSchemaBaseUrl: '/docs/orders',
        advancedSearchFieldsSchemaName: 'OneLineOrder',
        allowContextMenuOnCell: true,
        allowColumnsVisible: true,
        allowSearchUnderHeader: true,
        customShowItem: true,
        customUpdateItem: true,
        create: false,
        delete: false,
        // TODO: optimizing to just mandatory foreign fields.
        // foreignFields: ['id', 'name', 'code', 'main', 'history', 'ref', 'total_discount', 'total_discount_incl_vat', 'total_setup_discount', 'total_setup_discount_incl_vat', 'state', 'description', 'caption', 'customer_caption', COLUMN_SPHERE_ID, 'periodicity', 'delete_by', 'end_subscription', 'available', 'history_cancel', 'licence_quantity', 'ref_domain', 'date_commitment_end', 'commitment_update_by', 'ids_intern'],
        foreignFields: ['id', 'main', 'name', 'ref', 'caption', 'history', COLUMN_SPHERE_ID, 'licence_quantity', 'ids_intern'],
        foreignFieldsFilter: true,
        footerProps: { 'items-per-page-options': [10, 20, 50, -1] },
        itemsPerPage: 10,
        searchVisibleColumns: false,
        key: 'id',
        multiSelect: true,
        notifyErrors: false,
        show: true,
        sortBy: 'date_order',
        sortDescending: true,
        update: false
      },
      orderingSameProduct: -1,
      orderStateSearch: DEFAULT_STATES,
      LINE_ACTIONS: {
        CHANGE_PROJECT: 10
      },
      productDate: null,
      newSphere: null,
      product: undefined,
      sphereSearch: '',
      requestIncidentTooltip: [],
      selection: [],
      selectionAction: 0,
      selectionActions: [],
      selectionError: null,
      sectionProjectForCompany: null,
      showIncidentDialog: false,
      showRequestDialog: false,
      stopReasonSearch: '',
      subscription: null,
      updateProduct: false,
      withRef: false
    }
  },
  computed: {
    canModifyOrStopProduct () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_MODIFY)
    },
    canReadCatalog () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST)
    },
    canReadPrices () {
      return !this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.LIST_NO_PRICE)
    },
    canReadProjects () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PROJECTS, this.$alto.API_PERMISSIONS.LIST)
    },
    canRunAction () {
      return !this.selectionError && this.selectionAction
    },
    currentCart () { return this.$store.getters['carts/current'] || {} },
    currentCartsExists () { return this.currentCart && this.currentCart.name },
    currentCartCustomer () { return this.currentCart.customer || this.$store.getters['$stratus-states/me'].company },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    myCompany () { return this.$store.getters['$stratus-states/me'].company },
    orderStates () { return this.$store.getters['orders/states'] },
    permissionDeliver () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_DELIVERY, this.$alto.API_PERMISSIONS.ORDER_DELIVERY_COMPLETE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_DELIVER_PAST)
    },
    showProductsSwitch () {
      return config && config.subscriptions && config.subscriptions.list && config.subscriptions.list.hideProducts && config.subscriptions.list.hideProducts.refs && config.subscriptions.list.hideProducts.refs.length
    },
    productSwitchTitle () {
      return this.showProductsSwitch ? config.subscriptions.list.hideProducts.title : ''
    }
  },
  methods: {
    actionRun () {
      switch (this.selectionAction) {
        case this.LINE_ACTIONS.CHANGE_PROJECT:
          this.changeSphere()
          break
      }
    },
    canDeliver (item) {
      return this.permissionDeliver && this.$store.getters['orders/STATE_CAN_DELIVER'].includes(item.state)
    },
    captionRules (item) {
      const rules = [this.$stratus.services.form.rules.max(128)]
      if (this.refNeedCaption(item.ref)) {
        rules.push(this.$stratus.services.form.rules.min(1))
      }
      return rules
    },
    changeSphere () {
      this.$refs['confirm-order-dialog']
        .open(this.$t('Change sphere'), this.$t('Do you want to set sphere «{pname}» for {count} subscribed products?', { pname: this.getProject(this.newSphere).name, count: this.selection.length }))
        .then(async (confirmed) => {
          if (confirmed) {
            this.isActionRunning = true
            try {
              const lines = _.map(this.selection, 'id')
              const failed = await this.$store.dispatch('orders/setLinesSphere', { idSphere: this.newSphere, lines })
              if (failed) {
                this.$stratus.services.notify.warning(this.$t('{count} subscribed product(s) did not match the reference company of the sphere, they have not been modified.', { count: failed }))
              }
            } catch (error) {
              console.error(error)
              this.$stratus.services.notify.error(error)
            } finally {
              this.refreshGrid()
              this.isActionRunning = false
            }
          }
        })
    },
    canOrderSameProduct (product) {
      if (!product || !product.ref) return
      if (!this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.CREATE)) return
      const p = this.$store.getters['$alto-catalog/get'](product.ref)
      if (!p) {
        console.warn(`[subscriptions] ${product.code}/${product.id}: product ${product.ref} not found in catalog!`)
        return false
      }
      if (p.end_subscription) return false
      return this.isLord || p.available
    },
    classForDataLine ({ row, isExpanded, isSelected, select }) {
      // ? FUTURE (delivered-line) See common.css to activate this class
      // ? return row?.state === 'completed' ? 'delivered-line' : ''
      return ''
    },
    closeMenuExtractCmdb () {
      this.menuCmdbExtract = false
      this.customers = [this.myCompany]
    },
    closeDialog () {
      this.$refs.subscriptionsList.fetchData()
    },
    closeTicketDialog () {
      this.showIncidentDialog = false
      this.showRequestDialog = false
      this.refreshGrid()
    },
    computeCsvData (rows) {
      if (!rows || rows.length === 0) return rows

      const result = []
      _.forEach(rows, item => {
        const orderedItem = {}
        Object.keys(item).forEach(key => {
          orderedItem[key] = item[key] // Add native field
          if (key === COLUMN_SPHERE_ID) {
            const sphere = _.find(this.$store.getters['$alto-spheres/list'], { id: item[COLUMN_SPHERE_ID] })
            orderedItem[COLUMN_SPHERE_ID] = sphere?.name || this.$t('None')
          }
        })
        result.push(orderedItem)
      })
      return result
    },
    createColumns () {
      let cols = [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Caption',
        value: 'caption',
        format: this.$stratus.services.fieldRenderers.FALSY_AS_EMPTY,
        hidden: false
      }, {
        text: 'Customer',
        value: 'code',
        width: '180px'

      }, {
        text: 'Customer caption',
        value: 'customer_caption',
        format: this.$stratus.services.fieldRenderers.FALSY_AS_EMPTY,
        hidden: true
      }, {
        text: 'Sphere',
        value: COLUMN_SPHERE_ID,
        hidden: true
      }, {
        text: 'Qty',
        value: 'quantity',
        alignValue: 'center',
        format: (value, column, row) => {
          let subQty
          if (row.licence_quantity) {
            subQty = this.$stratus.services.fieldRenderers.NUMBER.call(this, row.licence_quantity)
          }
          return this.$stratus.services.fieldRenderers.NUMBER.call(this, value) + (subQty !== undefined ? ` (${subQty})` : '')
        }
      }, {
        text: 'Daily use',
        value: 'detail_daily',
        classValue: 'text-caption',
        hidden: true,
        format: (value) => {
          if (!value) return this.$t('n/a')
          const text = (value.month !== undefined ? this.$t('This month: {count}{unit}', { count: value.month, unit: ' ' + this.$tc('day|day|days', value.month) }) : '') +
        '<br>' + (value.last_month !== undefined ? this.$t('Previous month: {count}{unit}', { count: value.last_month, unit: ' ' + this.$tc('day|day|days', value.last_month) }) : '')
          return text
        }
      }, {
        text: 'Begin',
        value: 'date_begin',
        format: (value, col, row) => {
          if (!value) return ''
          let res = `${this.$stratus.dt(value).format('L')}`
          if (row.history) res += (row.history[row.history.length - 1]) ? `<br> ${this.$t('Last update at {date}', { date: this.$stratus.dt(row.history[row.history.length - 1].date_end).format('L') })}` : ''
          return res
        }
      }, {
        text: 'End',
        value: 'date_end',
        format: value => value ? this.$stratus.dt(value).format('L') : this.$t('Not ended yet')
      }, {
        text: 'Order',
        alignValue: 'center',
        value: 'cmd_id'
      }, {
        text: 'Order date',
        value: 'date_order',
        format: value => value ? this.$stratus.dt(value).format('L') : ''
      }]

      if (this.isLord) {
        cols = cols.concat([{
          text: 'Opportunity',
          alignValue: 'center',
          value: 'opportunity_id',
          hidden: false
        }, {
          text: 'Tickets',
          align: 'center',
          alignValue: 'center',
          sortable: true,
          forceRefresh: true,
          value: 'ids_ext'
        }, {
          text: 'Identifier',
          align: 'center',
          alignValue: 'center',
          sortable: true,
          forceRefresh: true,
          value: 'unique_ids'
        }, {
          text: 'Reason for final stop',
          value: 'delete_reason',
          sortable: true,
          format: v => {
            return this.$t(v ? `delete-reason-${v}` : 'Undefined')
          }
        }])
      }

      cols = cols.concat([{
        text: 'Reference',
        value: 'ref',
        hidden: true
      }, {
        text: 'Domain',
        value: 'ref_domain',
        format (v) {
          return v ? this.$t(`product-domain-${v.toLowerCase()}`) : v // this.$t('None')
        }
      }, {
        text: 'periodicity-long-strict',
        value: 'periodicity',
        format: v => { return this.$t(`periodicity-short-${v}`) }
      }, {
        text: 'Commitment\'s days',
        value: 'nb_days_commitment',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.FALSY_AS_EMPTY
      }, {
        text: 'State',
        value: 'state'
      }])

      if (this.canReadPrices) {
        cols = cols.concat([{
          text: 'Total with discount',
          value: 'total_discount',
          hidden: true,
          format: this.$stratus.services.fieldRenderers.CURRENCY
        }, {
          text: 'Total setup with discount',
          value: 'total_setup_discount',
          hidden: true,
          format: this.$stratus.services.fieldRenderers.CURRENCY
        }])
      }

      return cols
    },
    async createTicket (subscription, { ticketType, descriptor }) {
      this.descriptor = descriptor
      this.subscription = subscription
      if (ticketType === this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT) {
        this.product = await this.$store.dispatch('$alto-catalog/getProductByRef', subscription.ref)
        this.$refs['form-ticket-new-incident'].reset()
        this.showIncidentDialog = true
        this.$refs['form-ticket-new-incident'].resetPage()
      } else if (ticketType === this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST) {
        this.$refs['form-ticket-new-request'].reset()
        this.showRequestDialog = true
      }
    },
    customSearch () {
      const cusSearch = []
      if (this.companiesSearch) {
        cusSearch.push({
          column: 'code',
          search: this.companiesSearch
        })
      }
      if (this.sphereSearch) {
        cusSearch.push({
          column: COLUMN_SPHERE_ID,
          search: this.sphereSearch
        })
      }
      if (this.orderStateSearch && this.orderStateSearch.length) {
        cusSearch.push({
          column: 'state',
          operator: 'in',
          search: this.orderStateSearch
        })
      }
      if (this.isLord && this.stopReasonSearch) {
        cusSearch.push({
          column: 'delete_reason',
          search: this.stopReasonSearch
        })
      }
      if (this.hideProducts) {
        cusSearch.raw = []
        _.forEach(config.subscriptions.list.hideProducts.refs, ref => {
          cusSearch.raw.push({
            column: 'ref',
            operator: 'ne', // Not equal
            search: ref,
            join: this.$stratus.services.query.QUERY_OPERATOR_AND
          })
        })
      }
      return cusSearch
    },
    deliverProduct (product) {
      this.deliveredProduct = { ...product } // Get a clone
      if (product.state === 'pending' && product.date_begin) {
        this.$refs['confirm-order-dialog']
          .open(this.$t('Confirm delivery'), this.$t('Validate is an irreversible step, do you confirm the validation?'), { color: 'blue' })
          .then(async (confirm) => {
            if (confirm) {
              this.isDelivering = true
              try {
                await this.$store.dispatch('orders/deliverProduct', { id: product.id })
                this.refreshGrid()
              } catch (error) {
                this.$stratus.services.notify.error(error)
              } finally {
                setTimeout(() => { this.isDelivering = false }, 250)
              }
            }
          })
      } else {
        this.productDate = this.$stratus.dt().toISOString().substr(0, 10)

        this.minDateEnd = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_DELIVERY, this.$alto.API_PERMISSIONS.ORDER_DELIVERY_COMPLETE_OVERRIDE) ? this.$stratus.dt().subtract(1, 'years').startOf('year') : this.$stratus.dt().startOf('month')
        this.maxDateEnd = this.$stratus.dt().add(1, 'days').startOf('day')

        this.$refs['order-product-date-dialog'].open(this.$t('Start this product'), `${product.name} × ${product.quantity}`)
          .then(async (confirm) => {
            if (confirm) {
              this.isDelivering = true
              try {
                await this.$store.dispatch('orders/updateSubscription', { id: product.id, caption: this.deliveredProduct.caption || '' })
                await this.$store.dispatch('orders/deliverProduct', { id: product.id, date_begin: this.$stratus.dt(this.productDate).format('YYYY-MM-DD') })
                this.refreshGrid()
              } catch (error) {
                this.$stratus.services.notify.error(error)
              } finally {
                setTimeout(() => { this.isDelivering = false }, 250)
              }
            }
          })
      }
    },
    fetchCatalog () {
      if (!this.canReadCatalog) return

      this.loading = true
      this.$store.dispatch('$alto-catalog/list')
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
        .finally(() => {
          setTimeout(() => { this.loading = false }, 500)
        })
    },
    filterDomain (domain) {
      this.domainFilter = domain
      this.refreshGrid()
    },
    getProject (id) {
      return this.$store.getters['$alto-spheres/cache'](id) || {}
    },
    gotoCatalog () {
      this.$router.push({ name: 'catalog' }).catch((error) => { console.warn(error) })
    },
    isProductAvailable (product) {
      const p = this.$store.getters['$alto-catalog/get'](product.ref)
      return p ? p.available : false
    },
    loadCompanies () {
      this.$store.dispatch('$alto-companies/list')
        .then(() => {
          this.companiesCache = this.$store.getters['$alto-companies/cache']()
          this.companies = this.$stratus.services.fields.ObjectToSelectItems(this.companiesCache, { keyInValue: true })
          this.customers = [this.myCompany]
          return this.$store.dispatch('$alto-spheres/list')
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    async loadOrderStates () {
      await this.$store.dispatch('$alto-opportunities/loadStates')
      await this.$store.dispatch('orders/listStates')
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openOrder (id) {
      if (this.$refs['order-dialog']) this.$refs['order-dialog'].open(id)
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openProduct (id) {
      if (this.$refs['product-dialog']) this.$refs['product-dialog'].openId(id)
    },
    openSphere (id) {
      if (this.$refs['sphere-dialog'] && id) this.$refs['sphere-dialog'].open(id)
    },
    openTicket (ticket) {
      if (ticket?.id && this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticket.id)
    },
    async orderSameProduct (id) {
      // load product
      try {
        const cartItem = await this.$refs.subscriptionsList.loadItem(id)

        this.$refs['confirm-order-dialog'].open(this.$t('Order same product'), this.$t('Do you want to add product «{pname}» to {cname}?', { pname: cartItem.name, cname: this.currentCart.name || this.$t('a new cart') }))
          .then(async (confirmed) => {
            if (confirmed) {
              this.orderingSameProduct = id

              // No cart exist, create a brand new one
              if (!this.currentCartsExists) {
                this.$store.dispatch('carts/create', { company: this.myCompany })
              }

              const quantity = 1
              // Add product to cart
              await this.$store.dispatch('carts/addToCurrent', { items: Object.assign(cartItem, { quantity }), company: this.myCompany })

              // Save the cart (it will cleanup all unwanted fields from item)
              const { cart, isNew } = await this.$store.dispatch('carts/save', { cart: this.currentCart })
              if (isNew) {
                this.$stratus.services.notify.success(this.$t('New cart {name} created.', { name: cart.name }))
              }
              this.$store.dispatch('carts/list') // Refresh cart menu
              this.$store.dispatch('carts/setCurrent', cart) // Force refresh current cart
              if (this.$root['global-cart-menu']) this.$root['global-cart-menu'].refreshList()
              this.$stratus.services.notify.success(this.$t('{quantity} × {product} added to cart.', { quantity, product: cartItem.name || cartItem.ref }))
              setTimeout(() => { this.orderingSameProduct = -1 }, 200)
            }
          })
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.orderingSameProduct = -1
      }
    },
    productUpdated () {
      this.$refs.subscriptionsList.fetchData()
    },
    queryDomain (gridQuery, raw, grid) {
      let result = raw || ''
      if (this.domainFilter) result += `&ref_domain=${this.domainFilter}`
      return result
    },
    refNeedCaption (ref) {
      const p = this.$store.getters['$alto-catalog/get'](ref)
      return p && p.need_caption
    },
    refreshGrid () {
      if (this.$refs.subscriptionsList) this.$refs.subscriptionsList.fetchData()
    },
    resetFilters () {
      this.companiesSearch = ''
      this.sphereSearch = ''
      this.orderStateSearch = ''
    },
    sendCmdb () {
      let customers = _.compact(this.customers)
      if (customers.includes('ALL')) customers = []

      this.$store.dispatch('orders/extractCmdb', { companies: customers, withRef: this.withRef })
        .then(() => {
          this.$stratus.services.notify.success(this.$t('The extract is being created...'))
        })
        .catch(error => this.$stratus.services.notify.error(error))
        .finally(() => {
          this.menuCmdbExtract = false
        })
    },
    showDeliveredProduct (product) {
      this.$refs['delivered-product-modify-dialog'].open(product.id, null, this.currentCartCustomer)
    },
    async showItem (data) {
      if (!data.success) {
        this.$stratus.services.notify.error(data.error)
        return
      }

      this.$refs['subscription-dialog'].open(data.item.id)
    },
    selectItems (items) {
      this.selection = items || []
      this.validateSelection()
    },
    validateSelection () {
      let error = null
      if (this.selection.length) {
        switch (this.selectionAction) {
          case this.LINE_ACTIONS.CHANGE_PROJECT: {
            this.sectionProjectForCompany = this.selection[0].code || this.myCompany
            const byCompany = _.groupBy(this.selection, 'code')
            if (Object.keys(byCompany).length > 1) {
              error = this.$t('You can change sphere for only one company at a time.')
            }
            break
          }
        }
      }
      this.selectionError = error
    }
  },
  async created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Subscriptions'))
    this.debouncedRefreshGrid = _.debounce(this.refreshGrid, 500)
    this.requestIncidentTooltip = {
      [this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT]: this.$t('Report an incident on this subscription.'),
      [this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST]: this.$t('Make a free-format request on this subscription.')
    }
  },
  async onBeforeMount () {
    // Fill all caches
    await this.$stratus.fetchCaches(false)
    await this.$alto.fetchCaches(false)
  },
  async mounted () {
    this.isLogged = this.$stratus.services.auth.isLogged()
    await this.$store.dispatch('$stratus-states/getMe')
    const me = this.$store.getters['$stratus-states/me']
    if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

    this.fetchCatalog()
    this.options.create = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST)
    this.options.update = false
    this.options.show = this.isLogged && !this.options.update
    this.options.delete = false
    this.options.search = this.isLogged
    this.options.queryAddRaw = this.queryDomain
    this.selectionActions = [
      { text: this.$t('Change sphere'), value: this.LINE_ACTIONS.CHANGE_PROJECT }
      // ? FUTURE:
      // ? { text: this.$t('Assign stop reason'), value: this.LINE_ACTIONS.CHANGE_STOP_REASON }
    ]
    this.productDate = this.$stratus.dt().toISOString().substr(0, 10)
    this.loadCompanies()
    this.loadOrderStates()
    this.columns = this.createColumns()
  }
}
</script>
