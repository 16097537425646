<template>
  <csm-kanban-generic graph="default" />
</template>

<script>
export default {
  name: 'KanbanView',
  components: {
    'csm-kanban-generic': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/KanbanGeneric')
  },
  data () { return {} },
  async mounted () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Kanban'))
  }
}
</script>
