<template>
  <div>
    <v-tabs hide-slider v-model="tab">
      <v-tab href="#tab-dashboard">
        <v-icon small left>
          $vuetify.icons.dashboardSales
        </v-icon>
        {{ $t('Sales') }}
      </v-tab>

      <cs-loader-tab href="#tab-forecast" :header="$t('Forecast')" icon="$vuetify.icons.forecast" @load="refreshForecast" />

      <v-tab href="#tab-targets">
        <v-icon small left>
          $vuetify.icons.targetSales
        </v-icon>
        {{ $t('Event Targets') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="pa-3">
      <v-tab-item value="tab-dashboard">
        <csm-opportunity-gauges />
      </v-tab-item>

      <v-tab-item value="tab-forecast">
        <csm-opportunity-forecast ref="opportunity-forecast" />
      </v-tab-item>

      <v-tab-item value="tab-targets">
        <csm-opportunity-sales-targets ref="opportunity-sales-targets" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'OpportunitiesSalesDashboard',
  components: {
    'csm-opportunity-gauges': () => import(/* webpackChunkName: "components" */ './OpportunitiesGauges.vue'),
    'csm-opportunity-forecast': () => import(/* webpackChunkName: "components" */ './OpportunitiesForecast.vue'),
    'csm-opportunity-sales-targets': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/SalesPersonsTargets.vue')
  },
  data () {
    return {
      tab: 'tab-dashboard'
    }
  },
  methods: {
    refreshForecast () {
      if (this.$refs['opportunity-forecast']) this.$refs['opportunity-forecast'].debouncedRefresh()
    }
  },
  async created () {
    await this.$store.dispatch('$stratus-states/getMe')
    const me = this.$store.getters['$stratus-states/me']
    if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Sales Dashboard'))
  }
}
</script>
