<template>
  <sca-modal-dialog :visible="visible" @closeDialog="closeDialog" @saveDialog="saveDialog" :action="action" :can-save="canSave" :loading="loading" :is-saving="isSaving" :max-width="$vuetify.breakpoint.mdAndUp ? '70%' : ''" :external-id="externalId">
    <div slot="title" class="d-flex align-center">
      <v-chip label class="text-h6 white--text pa-4 mr-4" :class="lookColor ? '' : 'primary'" :style="`background-color: ${lookColor};`">
        {{ userFullName || $t('User') }}
      </v-chip>

      <template v-if="canClone && canUpdate && currentUser.id" slot="buttons-header">
        <cs-action-button icon="$vuetify.icons.clone" help="Clone" @click="clone" />
      </template>

      <v-icon v-show="currentUser.is_lord" left>
        $vuetify.icons.lord
      </v-icon>

      <sca-user-tags :value="currentUser.tags" hide-text color="primary" />

      <v-tooltip v-if="isLord && currentUser.state === USER_STATE_INACTIVE" top>
        <template v-slot:activator="{ on }">
          <v-icon left v-on="on">
            $vuetify.icons.userInactive
          </v-icon>
        </template>
        <span>{{ $t('This user is not active. Customers never see deactivated users.') }}</span>
      </v-tooltip>

      <v-tooltip v-if="me.id === user.id" top>
        <template v-slot:activator="{ on }">
          <v-icon class="primary--text" left v-on="on">
            $vuetify.icons.user
          </v-icon>
        </template>
        <span>{{ $t('It\'s you!') }}</span>
      </v-tooltip>
    </div>

    <template #append-header>
      <div class="d-flex">
        <div v-if="currentUser?.id" class="text-center pr-6">
          <v-avatar v-if="isLord && currentUser.state === USER_STATE_INACTIVE" :size="avatarSize" class="my-4">
            <v-icon huge>
              $vuetify.icons.userInactive
            </v-icon>
          </v-avatar>

          <sca-user-avatar v-else :user-id="currentUser.id" :size="avatarSize" class="my-4" />
        </div>

        <div>
          <v-row no-gutters align="center">
            <v-col cols="12" md="6" lg="3" class="pr-2">
              <sca-customer-select v-model="currentUser.company" :label="$t('Customer')" :rules="[$stratus.services.form.rules.required]" class="required" @change="onCompanyChange" :readonly="!canSave" show-email show-phone link="emit" @link-click="openCompany(currentUser.company)" />
            </v-col>

            <v-col cols="12" md="6" lg="3" class="pr-2">
              <v-text-field v-model="currentUser.firstname" :label="$t('Firstname')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(63)]" class="required" :readonly="!canSave" @blur="fixCase(user)" />
            </v-col>

            <v-col cols="12" md="6" lg="3" class="pr-2">
              <div class="text-h6">
                <v-text-field v-model="currentUser.lastname" :label="$t('Lastname')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(63)]" class="required" :readonly="!canSave" @blur="fixCase(user)" />
              </div>
            </v-col>

            <v-col v-if="isAdmin || isLord" cols="12" md="6" lg="3">
              <cs-helper>
                <v-select slot="content" v-model="currentUser.role" :label="$t('Function')" :items="listRoles" :rules="[$stratus.services.form.rules.required]" class="required" :readonly="!canSave" @change="fillNewslettersForRole" />
                <div slot="help">
                  {{ $t('These roles limit the possibilities available to your accounts:') }}
                  <ul>
                    <li>{{ $t('The administrator can do everything: create other users, fully use supervision, access to the catalog price and ordering process, etc.') }}</li>
                    <li>{{ $t('The business can carry out business actions: see all prices, have access to invoices, validate quotations.') }}</li>
                    <li>{{ $t('The project manager can do technical actions and place orders: from the technical team, he has the same permissions as the technicians, and can place orders in addition.') }}</li>
                    <li>{{ $t('The accountant can do actions in relation to accounting : access only to invoices and contracts.') }}</li>
                    <li>{{ $t('The technician can perform technical actions: fully use the supervision, list subscriptions and create quotation. He does not have the right to place an order.') }}</li>
                    <li>{{ $t('The pure technician is a role with access to all technical tools without seeing prices: he can see subscriptions, open tickets and have access to supervision.') }}</li>
                    <li>{{ $t('The spectator cannot do anything but observe : has access to the Dashboard and the Manager in read-only mode, he can access the supervision, see the prices, see the orders, etc. However, he cannot take action on the applications.') }}</li>
                    <li>{{ $t('The supervisor is a role with access to supervision only.') }}</li>
                  </ul>
                </div>
              </cs-helper>
            </v-col>
          </v-row>

          <v-row no-gutters align="center">
            <v-col cols="12" md="6" lg="4" class="pr-2">
              <v-text-field v-model="currentUser.email" :label="$t('Email')" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.email]" class="required" :readonly="!canSave" />
            </v-col>

            <v-col cols="12" md="" class="pr-2 d-flex">
              <v-switch v-if="isLord && currentUser.is_lord" v-model="currentUser.is_phone_private" hide-details :label="$t('Phone is private:')" :readonly="!canSave" class="pr-2" />

              <v-text-field v-model="currentUser.phone" :label="$t('Phone')" :rules="[$stratus.services.form.rules.phoneOrEmpty]" :readonly="!canSave" />
            </v-col>

            <v-col cols="12" md="6" lg="2">
              <v-text-field v-model="currentUser.mobile_phone" :label="$t('Mobile phone')" :rules="[$stratus.services.form.rules.phoneOrEmpty]" :readonly="!canSave" />
            </v-col>
          </v-row>
        </div>
      </div>
    </template>

    <v-tabs hide-slider slot="tabs" color="transparent" v-model="tab" show-arrows>
      <cs-validation-tab href="#tab-info" icon="$vuetify.icons.user" :label="$t('Information')" :error="tabErrors['tab-info']" />
      <cs-validation-tab v-if="canListFollowUp" href="#tab-follow-up" :label="$t('Follow-up')" icon="$vuetify.icons.message" :loading="loadingFollowups" :count="followUpCount" eager />
      <cs-validation-tab href="#tab-notes-tags" :label="$t('Tags & Notes')" icon="$vuetify.icons.tags" :error="tabErrors['tab-notes-tags']" v-if="isLord" />
    </v-tabs>

    <div slot="content">
      <v-form v-if="user" ref="UserForm" v-model="valid" :disabled="loading || !canSave" lazy-validation>
        <v-row>
          <v-col>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-info" eager>
                <cs-expand-panel :header="$t('Additional information')" :expanded="expandAdditional" icon="icon-company">
                  <div slot="content">
                    <v-row align="center" no-gutters>
                      <v-col v-if="isLord" cols="12" md="3" lg="2" class="pr-2">
                        <v-select v-model="currentUser.type" :items="listTypes" :label="$t('Account type')" :rules="[$stratus.services.form.rules.required]" class="required" :readonly="!canSave" />
                      </v-col>

                      <v-col cols="12" md="3" class="pr-2">
                        <v-text-field v-model="currentUser.job_title" :label="$t('Job title')" :readonly="!canSave" />
                      </v-col>

                      <v-col cols="12" md="3" class="pr-2">
                        <sca-teams-select v-if="isLord && currentUser.is_lord" v-model="currentUser.lord_team_id" :label="$t('Team')" clearable @selectionChange="onTeamChange" />
                        <v-text-field v-else v-model="currentUser.team" :label="$t('Team')" :readonly="!canSave" />
                      </v-col>

                      <v-col class="align-center">
                        <cs-helper>
                          <v-switch slot="content" v-model="currentUser.is_technical_manager" :label="$t('Technical manager')" />
                          <div slot="help">
                            {{ $t('This role designates a contact person to be contacted in the event of a technical incident or specific technical questions about the company.') }}
                          </div>
                        </cs-helper>
                      </v-col>

                      <v-col cols="12">
                        <v-switch v-model="currentUser.follow_all_tickets" :label="$t('Be automatically added to my companies\' ticket tracking')" class="ma-0" />
                      </v-col>
                    </v-row>

                    <v-row v-if="isLord && currentUser.is_lord" no-gutters>
                      <v-col v-if="teamUserInfo.length" cols="12">
                        <v-row dense>
                          <v-col cols="12" md="6" lg="" v-for="(userInfo, index) in teamUserInfo" :key="index" :class="index > 0 ? 'pl-2' : ''">
                            <cs-decimal-input v-if="userInfo === 'setup_booking_goal'" :label="$t('Setup booking goal')" v-model="teamUserInfoValues[userInfo]" :rules="[$stratus.services.form.rules.required]" class="required" />
                            <cs-decimal-input v-if="userInfo === 'subscription_booking_goal'" :label="$t('Subscription booking goal')" v-model="teamUserInfoValues[userInfo]" :rules="[$stratus.services.form.rules.required]" class="required" />
                            <cs-integer-input v-if="userInfo === 'calls_by_week'" :label="$t('Number of calls')" v-model="teamUserInfoValues[userInfo]" :min="0" :suffix="$t('per week')" />
                            <cs-integer-input v-if="userInfo === 'event_prospect_by_week'" :label="$t('Number of prospect appointments')" v-model="teamUserInfoValues[userInfo]" :min="0" :suffix="$t('per week')" />
                            <cs-integer-input v-if="userInfo === 'business_lunch_by_month'" :label="$t('Number of business meals')" v-model="teamUserInfoValues[userInfo]" :min="0" :suffix="$t('per month')" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </cs-expand-panel>

                <cs-expand-panel :header="$t('Application connection')" :icon="currentUser?.state === USER_STATE_BLOCKED ? 'icon-padlock' : 'icon-padlock-unlocked'" :expanded="expandAccounts">
                  <v-row slot="content" no-gutters>
                    <v-col cols="12" v-if="isLord">
                      <sca-customer-select v-model="currentUser.customers" :label="$t('List of companies that this user manage')" :allow-all="currentUser.company === CORP_CODE" multiple :disabled="currentUser.company === CORP_CODE" :readonly="!canSave" />
                    </v-col>

                    <v-col cols="12" md="6" class="pr-2">
                      <cs-alert-panel v-if="isLord" :type="currentUser?.state === USER_STATE_ACTIVE && currentUser.auth_enabled ? 'success' : 'warning'" dense :text="currentUser?.state === USER_STATE_ACTIVE && currentUser.auth_enabled ? $t('This user can access Scalair applications such as Dashboard and Manager.') : $t('This user cannot access Scalair applications such as Dashboard and Manager.')" />

                      <div v-if="isLord" class="d-flex">
                        <v-checkbox inset flat :label="$t('Allow user to authenticate')" v-model="currentUser.auth_enabled" :disabled="!canSave" class="mr-4" />
                        <sca-user-state-select v-model="currentUser.state" :list-type="currentUser.id ? USER_STATE_LIST_UPDATE_USER : USER_STATE_LIST_CREATE_USER" :disabled="!canSave" />
                      </div>
                      <div v-else-if="isAdmin && canSave && currentUser.auth_enabled">
                        <v-btn v-if="currentUser?.state === USER_STATE_BLOCKED" rounded class="danger-button" @click="currentUser.state = USER_STATE_ACTIVE" :disabled="!canSave">
                          <v-icon small left>
                            icon-padlock-unlocked
                          </v-icon>
                          {{ $t('Allow user') }}
                        </v-btn>
                        <v-btn v-else rounded class="main-button" @click="currentUser.state = USER_STATE_BLOCKED" :disabled="!canSave">
                          <v-icon small left>
                            icon-padlock
                          </v-icon>
                          {{ $t('Block user') }}
                        </v-btn>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <p v-if="currentUser.lastAuthDate">
                        {{ $t('Last authentication') }}
                        <v-icon small left right>
                          $vuetify.icons.for
                        </v-icon>
                        <span class="color2--text">
                          {{ lastAuthDate }}
                        </span>
                      </p>
                      <p v-else class="warning--text">
                        {{ $t('Never logged in.') }}
                      </p>

                      <div v-if="isLord && currentUser.auth_enabled" class="d-flex align-center">
                        <div>
                          <cs-password-input v-model="currentUser.password" :label="$t('New password')" :rules="[$stratus.services.form.rules.passwordOrEmpty, isMandatory]" :readonly="!canSave" />
                        </div>

                        <div class="shrink pl-4">
                          <v-btn rounded @click="generatePassword" :disabled="!canSave">
                            {{ $t('Generate') }}
                          </v-btn>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="12" v-if="isLord && isAdmin" class="mt-4 dense align">
                      <v-card outlined class="pa-2">
                        <div class="text-h6">
                          {{ $t('Specific permissions') }}
                          <v-fab-transition>
                            <v-btn class="primary" fab dark small absolute right @click="specificPermissionAdd">
                              <v-icon>$vuetify.icons.add</v-icon>
                            </v-btn>
                          </v-fab-transition>
                        </div>

                        <v-row no-gutters>
                          <v-col v-show="!currentUser.specific_permissions?.length" cols="12" md="6" offset-md="3">
                            <cs-alert-panel class="mt-4" type="info" dense :text="$t('No specific permission.')" />
                          </v-col>

                          <v-col cols="12" v-for="(perm, index) in currentUser.specific_permissions" :key="index">
                            <csm-permission-input :value="perm" @change="specificPermissionUpdate(index, $event)" @delete="specificPermissionDelete(index, $event)" />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </cs-expand-panel>
              </v-tab-item>

              <v-tab-item v-if="canListFollowUp" value="tab-follow-up">
                <v-row align="center" no-gutters>
                  <v-col cols="6" md="3" lg="2" class="pr-2">
                    <cs-date-picker v-model="beginAt" format-short :disabled="loading" :label="$t('From date')" />
                  </v-col>

                  <v-col cols="6" md="3" lg="2" class="pr-2">
                    <cs-date-picker v-model="endAt" format-short :disabled="loading" :label="$t('To date')" />
                  </v-col>

                  <v-col>
                    <v-btn rounded small class="shortcut-button mr-2" @click="setDateRange(RANGE_LAST_WEEK)">
                      <v-icon small left color="menu-icon">
                        icon-clock
                      </v-icon>
                      {{ $t('Last week') }}
                    </v-btn>
                    <v-btn rounded small class="shortcut-button mr-2" @click="setDateRange(RANGE_CURRENT_WEEK)">
                      <v-icon small left color="menu-icon">
                        icon-clock
                      </v-icon>
                      {{ $t('Current week') }}
                    </v-btn>
                    <v-btn rounded small class="shortcut-button mr-2" @click="setDateRange(RANGE_CURRENT_MONTH)">
                      <v-icon small left color="menu-icon">
                        icon-clock
                      </v-icon>
                      {{ $t('Current month') }}
                    </v-btn>
                    <v-btn rounded small class="shortcut-button mr-2" @click="setDateRange(RANGE_CURRENT_YEAR)">
                      <v-icon small left color="menu-icon">
                        icon-clock
                      </v-icon>
                      {{ $t('Current year') }}
                    </v-btn>
                  </v-col>

                  <v-col class="shrink">
                    <cs-refresh-button :loading="loading" @click="refreshFollowUp" />
                  </v-col>
                </v-row>

                <csm-follow-up-list ref="user-follow-up" allow-add :sales-persons="currentUser.id" :begin-at="beginAt" :end-at="endAt" @loading="onFollowupLoading" @loaded="onFollowupLoaded" />
              </v-tab-item>

              <v-tab-item value="tab-notes-tags" v-if="isLord" eager>
                <v-row align="center">
                  <v-col v-if="isLord" class="shrink text-no-wrap">
                    <sca-user-look-select v-model="currentUser.look" :readonly="!canSave" />
                  </v-col>

                  <v-col>
                    <cs-tags-input v-model="currentUser.tags" :disabled="!canSave" />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <sca-notes v-model="currentUser.notes" :disabled="!canSave" />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-form>

      <cs-confirm-dialog ref="confirmUserForm" />
      <csm-company-dialog ref="company-dialog" />
    </div>

    <sca-footer-create-update-at-by v-model="user" slot="footer" :link-user="isLord" />
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

import config from '@/config'

const CORP_CODE = config.defaults.corporationCode

export default {
  name: 'CsmUserForm',
  components: {
    'csm-follow-up-list': () => import('@/components/FollowUps/FollowUpList'),
    // 'csm-user-follow-up': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/UserFollowUp'),
    'csm-permission-input': () => import(/* webpackChunkName: "components" */ '@/components/Users/PermissionInput')
  },
  data () {
    return {
      CORP_CODE,
      USER_STATE_LIST_CREATE_USER: this.$alto.defines.USERS.USER_STATE_LIST_CREATE_USER,
      USER_STATE_LIST_UPDATE_USER: this.$alto.defines.USERS.USER_STATE_LIST_UPDATE_USER,
      USER_STATE_ACTIVE: this.$alto.defines.USERS.USER_STATE_ACTIVE,
      USER_STATE_BLOCKED: this.$alto.defines.USERS.USER_STATE_BLOCKED,
      USER_STATE_INACTIVE: this.$alto.defines.USERS.USER_STATE_INACTIVE,
      RANGE_CURRENT_YEAR: this.$alto.defines.DATES.RANGE_CURRENT_YEAR,
      RANGE_CURRENT_MONTH: this.$alto.defines.DATES.RANGE_CURRENT_MONTH,
      RANGE_CURRENT_WEEK: this.$alto.defines.DATES.RANGE_CURRENT_WEEK,
      RANGE_LAST_WEEK: this.$alto.defines.DATES.RANGE_LAST_WEEK,
      currentUser: {},
      beginAt: null,
      endAt: null,
      allExpanded: false,
      expandAccounts: false,
      expandAdditional: false,
      expandAddress: false,
      expandNewsletters: false,
      extIdentifiers: {},
      followUpCount: 0,
      listNewsletters: [],
      listTypes: [],
      listRoles: [],
      loading: false,
      loadingFollowups: false,
      isSaving: false,
      isMandatory: (v) => {
        return this.currentUser.id !== undefined || (v && v.length > 0) || this.$t('Password is mandatory to create user')
      },
      newsletters: [],
      showDialog: false,
      subscribedNewsletters: {},
      tab: 'tab-info',
      tabErrors: {},
      teamUserInfo: [],
      teamUserInfoValues: {},
      user: {},
      valid: true,
      visible: false
    }
  },
  watch: {
    user (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.reset(newValue)
      }
    },
    visible: {
      immediate: true,
      handler (nv, ov) {
        if (nv !== ov && ov !== undefined) {
          this.showDialog = nv
        }
      }
    }
  },
  computed: {
    action () {
      if (this.canUpdate) return this.$t('Update')
      return this.canShow ? this.$t('Details') : this.$t('Create')
    },
    avatarSize () {
      return 100
    },
    isAdmin () {
      return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN
    },
    canClone () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.USERS, this.$alto.API_PERMISSIONS.CREATE)
    },
    canListFollowUp () {
      return this.currentUser?.id && (this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.LIST) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.LIST))
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.USERS, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.USERS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.USERS, this.$alto.API_PERMISSIONS.READ)
    },
    canUpdate () {
      return this.user?.id && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.USERS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    lastAuthDate () {
      return this.currentUser && this.currentUser.lastAuthDate ? this.$stratus.dt(this.currentUser.lastAuthDate).format('ll LT') : ''
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    lookColor () {
      if (!this.isLord) return ''
      const look = _.find(this.$store.getters['$alto-users/looks'], { value: this.currentUser.look })
      return look && look.color && look.color.html ? look.color.html : ''
    },
    me () { return this.$store.getters['$stratus-states/me'] || {} },
    userFullName () {
      return this.currentUser ? this.$stratus.services.strings.stripHtmlTags(`${this.currentUser.firstname || ''} ${this.currentUser.lastname || ''}`) : ''
    }
  },
  methods: {
    async clone () {
      try {
        this.currentUser = await this.$store.dispatch('users/clone', this.currentUser.id)
        this.tab = 'tab-info'
        this.$stratus.services.notify.success(this.$t('User cloned.'))
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    closeDialog (user) {
      this.$emit('closeDialog', user)
    },
    expandAll () {
      this.allExpanded = !this.allExpanded
      this.expandAccounts = this.allExpanded
      this.expandAdditional = this.allExpanded
      this.expandAddress = this.allExpanded
      this.expandNewsletters = this.allExpanded
    },
    externalId () {
      if (!this.currentUser?.id) return
      return [this.userFullName, this.currentUser.id ? 'users/' + this.currentUser.id : '']
    },
    async fillNewslettersForRole () {
      if (!this.canUpdate) {
        try {
          this.loading = true
          await this.$store.dispatch('users/getNewsLettersForRole', this.currentUser.role)
          const nlRole = this.$store.getters['users/getNewsletters'](this.currentUser.role)
          _.forEach(this.newsletters, nl => {
            const found = _.find(nlRole, { id: nl.id })
            this.subscribedNewsletters[nl.id] = found
          })
          this.expandNewsletters = true
          this.$forceUpdate()
        } catch (error) {
          console.error(error)
        } finally {
          setTimeout(() => { this.loading = false }, 250)
        }
      }
    },
    fixCase (user) {
      if (!user) return
      user.lastname = this.$stratus.services.strings.toUpperCase(user.lastname)
      if (user.firstname) user.firstname = this.$stratus.services.strings.inflector.titleize(user.firstname)
      if (user.city) user.city = this.$stratus.services.strings.inflector.titleize(user.city)
      if (user.street) user.street = this.$stratus.services.strings.inflector.titleize(user.street)
      if (user.street2) user.street2 = this.$stratus.services.strings.inflector.titleize(user.street2)
      return user
    },
    generatePassword () {
      this.$set(this.currentUser, 'password', this.$stratus.services.strings.getPassword())
    },
    async loadLists () {
      try {
        this.loading = true
        await this.$store.dispatch('$alto-users/loadTypes')
        this.listTypes = this.$store.getters['$alto-users/types']
        await this.$store.dispatch('$alto-users/loadRoles')
        this.listRoles = this.$store.getters['$alto-users/roles']
        await this.$store.dispatch('newsletters/list')
        this.newsletters = this.$store.getters['newsletters/list']
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => { this.loading = false }, 1000)
      }
    },
    onCompanyChange (value) {
      if (!this.currentUser?.customers) this.currentUser.customers = []
      if (value === CORP_CODE) {
        this.$set(this.currentUser, 'customers', ['ALL'])
      } else {
        this.$set(this.currentUser, 'customers', [value])
      }
    },
    async onTeamChange () {
      if (!this.currentUser.lord_team_id) {
        this.teamUserInfo = []
        this.teamUserInfoValues = {}
        return
      }
      try {
        this.loading = true
        const team = await this.$store.dispatch('$alto-companies/getTeam', { id: this.currentUser.lord_team_id })
        this.teamUserInfo = team?.required_user_info || []
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => { this.loading = false }, 2000)
      }
    },
    onFollowupLoaded (count) {
      this.followUpCount = count
      this.loadingFollowups = false
    },
    onFollowupLoading (loading) {
      this.loadingFollowups = loading
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    async reset (user = {}) {
      // Go to first tab
      this.tab = 'tab-info'
      this.tabErrors = {}
      // Reset field
      if (this.$refs.UserForm) await this.$refs.UserForm.reset()

      this.currentUser = this.fixCase(user)
      this.followUpCount = 0
      this.loadingFollowups = false
      this.currentUser.newsletters = this.currentUser.newsletters || []
      this.currentUser.address = this.currentUser.address || {}
      this.teamUserInfoValues = { ...this.currentUser.lord_team_info }
      this.extIdentifiers = { lord_jira_id: '', lord_sage_id: null, ...this.currentUser.ids_ext }
      this.subscribedNewsletters = {}
      _.forEach(this.currentUser.newsletters, nl => {
        this.subscribedNewsletters[nl] = true
      })
      this.onTeamChange()

      if (this.isLord) await this.$store.dispatch('$alto-users/loadLooks')

      this.setDateRange(this.RANGE_CURRENT_WEEK)
    },
    refreshFollowUp () {
      if (!this.canListFollowUp) return
      if (this.$refs['user-follow-up']) this.$refs['user-follow-up'].refresh()
    },
    async saveDialog () {
      this.isSaving = true
      // Sanitize
      this.currentUser = this.$stratus.services.strings.stripHtmlTags(this.currentUser)
      if (!this.$refs.UserForm.validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.tabErrors = this.$stratus.services.form.tabsWithError(this.$refs.UserForm)
        this.isSaving = false
        return
      }

      // Validation is OK
      const _user = _.cloneDeep(this.fixCase(this.currentUser))

      // Create needed fields
      if (!this.isLord && !_user.id) {
        _user.type = 'user'
        _user.customers = [_user.company]
        _user.password = this.$stratus.services.strings.getPassword()
      }

      if (this.isLord) {
        if (_user.is_lord) _user.lord_team_info = { ...this.teamUserInfoValues }
        if (this.isAdmin) _user.ids_ext = { ...this.extIdentifiers }
      }

      // Create newsletters
      const subscribedNewsletters = []
      _.forEach(this.newsletters, nl => {
        if (this.subscribedNewsletters[nl.id]) {
          subscribedNewsletters.push(nl.id)
        }
      })
      _user.newsletters = subscribedNewsletters

      // Keep only companies codes
      // _user.company = this.currentUser.company.code
      try {
        const returnedUser = await this.$store.dispatch('$alto-users/save', _user)

        if (this.canUpdate) {
          this.$stratus.services.notify.success(this.$t('User {lastname} {firstname} updated.', _user))
        } else {
          this.$stratus.services.notify.success(this.$t('User {lastname} {firstname} created.', _user))
        }

        this.$store.dispatch('$alto-users/fillCache', true)
        this.closeDialog(returnedUser)
      } catch (error) {
        if (error.body?.code === 'USE-403') {
          this.$stratus.services.notify.error(this.$t('This email address is already used by another user!'))
        } else {
          this.$stratus.services.notify.error(error)
        }
      } finally {
        this.isSaving = false
      }
    },
    setDateRange (rangeType) {
      switch (rangeType) {
        case this.RANGE_CURRENT_YEAR:
          this.beginAt = this.$stratus.dt().startOf('year').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('year').format('YYYY-MM-DD')
          break
        case this.RANGE_CURRENT_MONTH:
          this.beginAt = this.$stratus.dt().startOf('month').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('month').format('YYYY-MM-DD')
          break
        case this.RANGE_CURRENT_WEEK:
          this.beginAt = this.$stratus.dt().startOf('week').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('week').format('YYYY-MM-DD')
          break
        case this.RANGE_LAST_WEEK:
          this.beginAt = this.$stratus.dt().startOf('week').subtract(1, 'week').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('week').subtract(1, 'week').format('YYYY-MM-DD')
          break
      }
      this.refreshFollowUp()
    },
    specificPermissionAdd () {
      if (!this.currentUser.specific_permissions) this.currentUser.specific_permissions = []
      this.currentUser.specific_permissions.push('')
      this.$forceUpdate()
    },
    specificPermissionDelete (index) {
      this.currentUser.specific_permissions.splice(index, 1)
      this.$forceUpdate()
    },
    specificPermissionUpdate (index, newPermission) {
      this.currentUser.specific_permissions[index] = newPermission
      this.$forceUpdate()
    },
    async display (show = false) {
      this.visible = show
      if (show) {
        // Load some data in cache
        await this.loadLists()
      }
    }
  }
}
</script>
