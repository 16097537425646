<template>
  <sca-modal-dialog :visible="visible" :loading="loading || isSaving" :can-save="canSave" :is-saving="isSaving" :max-width="$vuetify.breakpoint.lgAndUp ? '50%' : ''" @closeDialog="closeDialog()" @saveDialog="saveDialog" :external-id="externalId()">
    <div slot="title" class="d-flex align-center">
      {{ lead.id }}
      <sca-user-identity v-if="lead.id_user" :value="lead.id_user" class="mx-4" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(lead.id_user)" />
      <span v-else class="mx-4">
        {{ lead.email }}
      </span>
      {{ isDirty() ? '*' : '' }}
    </div>

    <template #subtitle>
      <div v-if="lead.id_user" slot="subtitle" class="highlighted-panel">
        <div class="text-caption">
          {{ $t('This lead has already been associated with a company.') }}
        </div>
        <sca-company-identity :value="lead.code" dense show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(lead.code)" />
      </div>
    </template>

    <template v-if="!lead.id_user" #buttons-header>
      <cs-action-button v-if="lead.email && lead.firstname && lead.lastname && lead.company_name" text icon="$vuetify.icons.company" :loading="loading || isSaving" help="Turning into a suspect..." @click="createCompany" />
    </template>

    <template v-if="errors" #content-fixed>
      <sca-field-error-panel :value="errors" />
    </template>

    <div slot="content">
      <v-form ref="lead-form" v-model="valid" lazy-validation :disabled="!canSave">
        <v-row align="end" dense>
          <v-col cols="12" md="6" lg="2">
            <v-select v-model="lead.state" :items="leadStates" :label="$t('State')">
              <template #selection="{item}">
                {{ $t(item) }}
              </template>
              <template #item="{item}">
                {{ $t(item) }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <v-text-field v-model="lead.company_name" :label="$t('Company')" :rules="[$stratus.services.form.rules.max(128)]" :readonly="!canSave" />
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <v-text-field v-model="lead.firstname" :label="$t('Firstname')" :rules="[$stratus.services.form.rules.max(128)]" :readonly="!canSave" />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <div class="text-h6">
              <v-text-field v-model="lead.lastname" :label="$t('Lastname')" :rules="[$stratus.services.form.rules.max(128)]" :readonly="!canSave" />
            </div>
          </v-col>
        </v-row>

        <v-row align="start" dense>
          <v-col cols="12" md="6" lg="2" class="text-center">
            {{ $t('Score') }}
            <v-avatar size="96" class="mx-4">
              <span class="text-h3">
                {{ lead.score || 0 }}
              </span>
            </v-avatar>
          </v-col>

          <v-col>
            <v-row align="center" dense>
              <v-col cols="12" md="6" lg="4">
                <div class="text-h6">
                  <v-text-field v-model="lead.function" :label="$t('Function')" :rules="[$stratus.services.form.rules.max(128)]" :readonly="!canSave" />
                </div>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-text-field v-model="lead.email" :label="$t('Email')" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.email]" class="required" :readonly="!canSave" />
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-text-field v-model="lead.phone" :label="$t('Phone')" :readonly="!canSave" />
              </v-col>
            </v-row>

            <v-row align="center" dense>
              <v-col cols="12" md="6" lg="4">
                <sca-sales-person-select v-model="lead.id_lord_salesperson" clearable :label="$t('Sales Manager')" :readonly="!canSave" show-email show-phone show-company show-card link="emit" @link-click="openUser(lead.id_lord_salesperson)" />
              </v-col>

              <v-col cols="12" lg="8">
                <v-text-field v-model="lead.comment" :label="$t('Comment')" :rules="[$stratus.services.form.rules.max(4095)]" :readonly="!canSave" counter="4095" auto-grow />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" class="transparent" hide-slider>
            <v-tab href="#tab-campaigns">
              <v-icon left small>
                $vuetify.icons.marketingEvent
              </v-icon>
              {{ $t('Campaigns') }}
            </v-tab>

            <v-tab href="#tab-history">
              <v-icon left small>
                $vuetify.icons.history
              </v-icon>
              {{ $t('History') }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="mt-4">
            <v-tab-item value="tab-campaigns">
              <div class="text-caption">
                {{ $t('Related campaigns') }}
              </div>
              <sca-marketing-event-select v-model="lead.marketing_events_ids" clearable multiple dense />

              <csm-marketing-emailing-panel v-if="lead.brevo_last_date_data_recovery" outlined>
                <template #content>
                  <div class="d-flex align-center">
                    <div>
                      {{ $t('Last refresh on: {date}', { date: $stratus.dt(lead.brevo_last_date_data_recovery).format('LLL') }) }}
                    </div>

                    <v-divider vertical class="mx-4" />

                    <v-chip small label class="ml-2">
                      {{ $t('Sent: ') }} {{ lead.brevo_nb_sent }}
                    </v-chip>
                    <v-chip small label class="ml-2">
                      {{ $t('Delivered: ') }} {{ lead.brevo_nb_delivered }}
                    </v-chip>
                    <v-chip small label class="ml-2">
                      {{ $t('Clickers: ') }} {{ lead.brevo_nb_clicking }}
                    </v-chip>
                    <v-chip small label class="ml-2">
                      {{ $t('Opening: ') }} {{ lead.brevo_nb_opening }}
                    </v-chip>
                    <v-chip small label class="ml-2">
                      {{ $t('Unsubscribing: ') }} {{ lead.brevo_nb_unsubscribing }}
                    </v-chip>
                  </div>
                </template>
              </csm-marketing-emailing-panel>

              <div v-if="!lead.score_detail?.length" class="text-center mt-4">
                {{ $t(loading ? 'Loading...' : 'No data...') }}
              </div>
              <v-row v-else v-for="(detail, index) in leadScoreDetailSorted" :key="index" align="center" class="mt-2">
                <v-col cols="1" class="text-center">
                  <v-avatar size="36">
                    {{ detail.score || 0 }}
                  </v-avatar>
                </v-col>

                <v-col>
                  {{ detail.description }}
                  <div class="color2--text">
                    {{ $stratus.dt(detail.date).format('LLL') }}
                  </div>
                </v-col>

                <v-col>
                  <div v-if="detail.campaign_id">
                    <a :href="`https://app.brevo.com/marketing-campaign/edit/${detail.campaign_id}`" target="_ext" class="hover-underline-from-center pt-1">
                      <v-icon small class="top-2">
                        $vuetify.icons.for
                      </v-icon>
                      {{ $t('Open campaign in Brevo') }}
                    </a>
                  </div>

                  <div v-if="detail.url">
                    <a :href="detail.url" target="_ext" class="hover-underline-from-center pt-1">
                      <v-icon small class="top-2">
                        $vuetify.icons.link
                      </v-icon>
                      {{ $t('Open link') }}
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item value="tab-history">
              Stay tuned for History
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <cs-confirm-dialog ref="marketing-confirm-dialog" />
      <csm-company-dialog ref="marketing-company-dialog" @closeDialog="closeDialog({ lead })" />
      <csm-user-dialog ref="marketing-user-dialog" />
    </div>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'MarketingLeadForm',
  components: {
    'csm-marketing-emailing-panel': () => import(/* webpackChunkName: "components" */ './MarketingEmailingPanel')
  },
  props: {
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      errors: null,
      isSaving: false,
      lead: {},
      leadStates: ['handled', 'unhandled'],
      tab: 'tab-campaigns',
      valid: false,
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_LEADS, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_LEADS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    leadScoreDetailSorted () {
      return _.sortBy(this.lead.score_detail, score => -this.$stratus.dt(score.date))
    }
  },
  methods: {
    closeDialog ({ lead, forceClose } = {}) {
      if (forceClose || !this.canSave || !this.isDirty()) {
        this.$emit('closeDialog', lead)
        return
      }

      this.$refs['marketing-confirm-dialog'].open(this.$t('Modifications detected'), this.$t('Do you confirm the loss of your modifications?'))
        .then(confirmed => {
          if (confirmed) {
            this.$emit('closeDialog', lead)
          }
        })
    },
    async createCompany () {
      this.errors = null
      if (!this.lead.id_user && this.lead.email && this.lead.firstname && this.lead.lastname && this.lead.company_name) {
        const confirmed = await this.$refs['marketing-confirm-dialog'].open(this.$t('Confirm'), this.$t('Do you confirm the creation of the company from {name}?', { name: `${this.lead.id || ''} ${this.lead.company_name}` }))
        if (confirmed) {
          try {
            const savedLead = await this.saveDialog(false)
            this.reset(savedLead)
            this.isSaving = true
            // First a dry run to detect errors
            const response = await this.$store.dispatch('$alto-marketing/createCompanyFromLead', { lead: this.sanitize(savedLead) })
            if (response?.errors) {
              const errList = Object.keys(response.errors)
              if (errList.length) {
                this.errors = response.errors // Houston, we've got a problem!
              } else {
                // We can really create the company
                const newCompany = await this.$store.dispatch('$alto-marketing/createCompanyFromLead', { lead: savedLead, dryRun: false })
                this.$stratus.services.notify.success(this.$t('Company {code} — {name} created.', newCompany))
                this.openCompany(newCompany.code)
              }
            }
          } catch (error) {
            this.$stratus.services.notify.error(error)
          } finally {
            this.isSaving = false
          }
        }
      }
    },
    display (show = false) {
      this.visible = show
    },
    externalId () {
      return this.lead.id ? 'marketing-lead/' + this.lead.id : ''
    },
    isDirty () {
      if (!this.originalLead) return false
      return !_.isEqual(this.sanitize(this.lead), this.originalLead)
    },
    openCompany (id) {
      if (this.$refs['marketing-company-dialog']) this.$refs['marketing-company-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['marketing-user-dialog']) this.$refs['marketing-user-dialog'].open(id)
    },
    resetOriginal () {
      this.originalLead = { ...this.sanitize(this.lead) }
    },
    reset (lead = {}) {
      this.lead = { ...lead }
      this.resetOriginal()
    },
    sanitize (lead) {
      const result = {
        ...lead,
        date_begin: this.$stratus.dt(lead.date_begin).isValid() ? this.$stratus.dt(lead.date_begin).format('YYYY-MM-DD') : '',
        date_end: this.$stratus.dt(lead.date_end).isValid() ? this.$stratus.dt(lead.date_end).format('YYYY-MM-DD') : '',
        marketing_events_ids: lead.marketing_events_ids,
        name: this.$stratus.services.strings.stripHtmlTags(lead.name)
      }
      if (lead.id) result.id = lead.id
      return result
    },
    async saveDialog (close = true) {
      if (!this.canSave) return false

      if (!this.$refs['lead-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        return false
      }

      this.isSaving = true
      try {
        const newLead = await this.$store.dispatch('$alto-marketing/saveLead', this.sanitize(this.lead))
        this.resetOriginal()
        if (close) this.closeDialog({ lead: newLead })
        return newLead
      } catch (error) {
        this.$stratus.services.notify.error(error)
        return false
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>
