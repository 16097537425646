<template>
  <sca-modal-dialog :visible="visible" @closeDialog="closeDialog" @saveDialog="saveDialog" :action="action" :can-save="canSave" :is-saving="isSaving" :max-width="$vuetify.breakpoint.lgAndUp ? '1200' : ''" :external-id="externalId">
    <span slot="title">
      {{ sphereFullName }}
      — {{ this.$t('Sphere') }}
    </span>

    <template v-if="canClone && update" slot="buttons-header">
      <cs-action-button icon="$vuetify.icons.clone" help="Clone" @click="clone" />
    </template>

    <div slot="append-header" class="mb-4">
      <cs-alert-panel dismiss :text="$t('Spheres allow you to better organize your products. Each sphere will generate a clean invoice. You have the possibility for each order to choose a sphere directly. If you later want to change the subscriptions contained in a sphere order, this will also be possible from the instances & other products tab.')" />
    </div>

    <template #tabs>
      <v-tabs v-model="tab" class="transparent" hide-slider>
        <v-tab href="#tab-sphere">
          <v-icon small left>
            $vuetify.icons.details
          </v-icon>
          {{ $t('Details') }}
        </v-tab>

        <cs-loader-tab v-if="canReadSubscriptions && sphere?.id" href="#tab-subscriptions" :header="$t('Subscriptions')" icon="$vuetify.icons.subscription" :count="subscriptions?.length || 0" :loading="loadingSubscriptions" @load="loadSubscriptions" />

        <v-tab v-if="sphere?.id" href="#tab-tickets">
          <v-icon small left>
            $vuetify.icons.ticket
          </v-icon>
          {{ $t('Tickets') }}
        </v-tab>
      </v-tabs>
    </template>

    <div slot="content">
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-sphere" class="px-2 pt-4">
          <v-form ref="SphereDialog" v-model="valid" lazy-validation>
            <v-row align="center">
              <v-col cols="12" md="4">
                <sca-customer-select v-model="sphere.code" :label="$t('Customer')" :rules="[$stratus.services.form.rules.required]" class="required" show-email show-phone link="emit" @link-click="openCompany(sphere.code)" />
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field v-model="sphere.name" :label="$t('Name')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(63)]" class="required" counter="63" />
              </v-col>

              <v-col cols="12" md="4">
                <v-switch v-model="sphere.is_default" :label="$t('Is default')" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                {{ $t('Color') }}
                <cs-color-select v-model="sphere.color" :colors="COLORS" />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="sphere.description" :label="$t('Description')" auto-grow :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(63)]" class="required" counter="63" />
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>

        <v-tab-item v-if="canReadSubscriptions && sphere?.id" value="tab-subscriptions">
          <v-row v-if="subscriptions && subscriptions.length">
            <v-col cols="12">
              <v-row dense align="center" class="mt-2">
                <v-col class="shrink text-no-wrap">
                  {{ $t('Filter') }}
                </v-col>
                <v-col>
                  <v-chip v-for="(family, id) in productFamilies" :key="id" small class="mx-2" :color="familyFilter === id ? 'primary' : 'gray lighten-2'" @click="onFilterFamily(id)">
                    {{ $t(family.text) }}
                    <v-icon>
                      $vuetify.icons.next
                    </v-icon>
                    {{ $t('{count}{unit}', { count: family.count, unit: $tc('product|product|products', family.count) }) }}
                  </v-chip>
                </v-col>
              </v-row>

              <v-row dense align="center">
                <v-col md="6">
                  <cs-search-input :label="$t('Search...')" @search="onSearchProduct" :disabled="false" />
                </v-col>
              </v-row>

              <v-data-table :headers="headers" :items="subscriptionsFiltered" :options.sync="options" :footer-props="footerProps" :page.sync="pageSubscription" :loading="loadingSubscriptions">
                <!-- eslint-disable-next-line -->
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                  <div>{{ item.caption || '' }}</div>
                  <div v-if="item.customer_caption !== item.caption">
                    {{ item.customer_caption || '' }}
                  </div>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.state="{ item }">
                  {{ $t('order-state-' + item.state) }}
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.date_begin="{ item }">
                  <span v-if="item.date_begin">
                    {{ $stratus.dt(item.date_begin).format('LL') }}
                  </span>
                  <span v-if="item.date_end">
                    <v-icon small class="px-1">
                      icon-long-arrow-right
                    </v-icon>
                    {{ $t('End: {date}', { date: $stratus.dt(item.date_end).format('LL') }) }}
                  </span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.date_end="{ item }">
                  {{ item.date_end ? $stratus.dt(item.date_end).format('LL') : '-' }}
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item._price_="{ item }">
                  <sca-product-prices-grid :prices="item" :show-margin="isLord" dense />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-else-if="loadingSubscriptions" justify="center">
            <v-col class="shrink text-no-wrap">
              <cs-alert-panel type="loading" />
            </v-col>
          </v-row>
          <v-row v-else justify="center">
            <v-col class="shrink text-no-wrap">
              <cs-alert-panel type="info" :text="$t('No product.')" />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item v-if="sphere?.id" value="tab-tickets">
          <csm-ticket-grid ref="ticket-grid-sphere" :query="querySphereTickets" graph="default" pref-key="ticket-grid-sphere" :options="ticketGridOptions" :columns="ticketColumns" :save-preferences="false" hide-toggles hide-global-incident inline-filter />
        </v-tab-item>
      </v-tabs-items>

      <cs-confirm-dialog ref="confirmSphereDialog" />
      <csm-company-dialog ref="company-dialog" />
    </div>

    <sca-footer-create-update-at-by v-if="update" v-model="sphere" slot="footer" :link-user="isLord" />
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ProjectForm',
  components: {
    'csm-ticket-grid': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketGrid')
  },
  props: {
    sphere: { type: Object, required: true, default: () => { return {} } },
    show: { type: Boolean, default: true },
    update: { type: Boolean, default: false },
    visible: { type: Boolean, default: false }
  },
  data () {
    return {
      COLORS: this.$alto.defines.SPHERES.COLORS,
      familyFilter: null,
      footerProps: { 'items-per-page-options': [10, 25, 50, -1] },
      headers: [{
        text: this.$t('Ref'),
        value: 'ref'
      }, {
        text: this.$t('Name'),
        value: 'name'
      }, {
        text: this.$t('Quantity'),
        value: 'quantity',
        align: 'right'
      }, {
        text: this.$t('State'),
        value: 'state'
      }, {
        text: this.$t('Begin date'),
        value: 'date_begin'
      }, {
        text: this.$t('Prices'),
        value: '_price_',
        align: 'center',
        width: '400px'
      }],
      loadingSubscriptions: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['date_begin'],
        sortDesc: [true],
        multiSort: true,
        mustSort: false
      },
      pageSubscription: 1,
      productFamilies: [],
      searchSubscription: '',
      subscriptions: null,
      isSaving: false,
      tab: 'tab-sphere',
      ticketGridOptions: {
        allowAdvancedSearch: false,
        create: false,
        sortBy: 'create_at'
      },
      valid: true,
      isMandatory: (v) => {
        return this.sphere.id !== undefined || (v && v.length > 0) || this.$t('Password is mandatory to create a sphere')
      }
    }
  },
  computed: {
    action () {
      if (this.update) return this.$t('Update')
      return this.show ? this.$t('Details') : this.$t('Create')
    },
    allowed: {
      get () {
        return !this.sphere.blocked
      },
      set (value) {
        if (value !== undefined) {
          this.$set(this.sphere, 'blocked', !value) // Mandatory to allow Vue to detect change on nested attr.
        }
      }
    },
    canClone () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PROJECTS, this.$alto.API_PERMISSIONS.CREATE)
    },
    canReadSubscriptions () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.LIST)
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PROJECTS, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PROJECTS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    dark () {
      return this.$store.getters['$stratus-states/isDark']
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    me () {
      return this.$store.getters['$stratus-states/me'] || {}
    },
    querySphereTickets () {
      return `id_sphere[eq]=${this.sphere.id}`
    },
    sphereFullName () {
      return this.$stratus.services.strings.stripHtmlTags(this.sphere.name || '')
    },
    subscriptionsFiltered () {
      const tmpSubscription = this.familyFilter ? _.filter(this.subscriptions, { familyId: this.familyFilter }) : this.subscriptions
      return !this.searchSubscription
        ? tmpSubscription
        : _.filter(tmpSubscription, item => {
          return (item.ref || '').toLowerCase().includes(this.searchSubscription.toLowerCase()) ||
          (item.caption || '').toLowerCase().includes(this.searchSubscription.toLowerCase()) ||
          (item.customer_caption || '').toLowerCase().includes(this.searchSubscription.toLowerCase()) ||
          (item.name || '').toLowerCase().includes(this.searchSubscription.toLowerCase())
        })
    },
    ticketColumns () {
      return [{
        text: 'Type',
        value: 'type'
      }, {
        text: 'Created at',
        value: 'create_at',
        hidden: false,
        format: v => v ? this.$stratus.services.fieldRenderers.DATE_SHORT(v) : ''
      }, {
        text: 'State',
        value: 'state'
      }, {
        text: 'Closure date',
        value: 'date_closure',
        format: v => v ? this.$stratus.services.fieldRenderers.DATE_SHORT(v) : ''
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Referrer',
        value: 'id_referring'
      }]
    }
  },
  methods: {
    clone () {
      this.tab = 'tab-info'
      this.$nextTick(() => {
        this.sphere.id = ''
        this.sphere.name = ''
        this.sphere.description = ''
        this.$stratus.services.notify.success(this.$t('Sphere cloned.'))
      })
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    externalId () {
      if (!this.sphere.id) return
      return [this.sphereFullName, this.sphere.id ? 'spheres/' + this.sphere.id : '']
    },
    generatePassword () {
      this.$set(this.sphere, 'password', this.$stratus.services.strings.getPassword())
    },
    getFamily (id) { return this.$store.getters['$alto-catalog/getFamily'](id) },
    getProductByRef (ref) { return this.$store.getters['$alto-catalog/get'](ref) },
    async loadSubscriptions () {
      if (!this.sphere?.id) return

      this.loadingSubscriptions = true
      this.subscriptions = []

      try {
        this.subscriptions = await this.$store.dispatch('$alto-subscriptions/getSubscriptionsForSphere', this.sphere.id)
        const prodFamilies = {}
        _.forEach(this.subscriptions, prod => {
          const p = this.getProductByRef(prod.ref)
          if (p?.family) {
            const family = this.getFamily(p.family)
            if (family?.id) {
              prod.family = family || ''
              prod.familyId = family?.id || null
              if (!prodFamilies[family.id]) prodFamilies[family.id] = { text: family.text, count: 0 }
              prodFamilies[family.id].count += prod.quantity
            }
          }
        })
        this.productFamilies = prodFamilies
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }

      setTimeout(() => { this.loadingSubscriptions = false }, 500)
    },
    onFilterFamily (id) {
      if (this.familyFilter === id) this.familyFilter = null
      else this.familyFilter = id
    },
    onSearchProduct (value) {
      this.searchSubscription = value
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    async reset () {
      // Reset field
      this.tab = 'tab-sphere'
      if (this.$refs.SphereDialog) await this.$refs.SphereDialog.reset()
      this.subscriptions = []
      this.pageSubscription = 1
      this.productFamilies = []
      this.searchSubscription = ''
      this.loadingSubscriptions = false
      this.familyFilter = null
    },
    saveDialog () {
      this.isSaving = true

      // Sanitize
      this.sphere = this.$stratus.services.strings.stripHtmlTags(this.sphere)
      if (!this.$refs.SphereDialog.validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      this.$store.dispatch('$alto-spheres/save', this.sphere)
        .then(returnedProject => {
          if (this.update) {
            this.$stratus.services.notify.success(this.$t('Sphere {name} updated.', this.sphere))
          } else {
            this.$stratus.services.notify.success(this.$t('Sphere {name} created.', this.sphere))
          }
          this.closeDialog()
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
</script>
