<template>
  <div>
    <sca-modal-dialog :visible="show" max-width="700" @closeDialog="close" @saveDialog="save" :can-save="canSave" :is-saving="isSaving" :loading="loading || isSaving" :external-id="externalId()">
      <template #title>
        {{ id ? $t('Call') : $t('Add a call') }}
      </template>

      <div slot="content">
        <v-form ref="followup-form" v-model="valid" lazy-validation>
          <v-row align="center" dense>
            <v-col cols="11" class="d-flex align-center">
              <sca-contact-select v-model="callUser" ref="followup-call-contact-select" show-company show-phone single-line :label="$t('Contact')" :rules="[$stratus.services.form.rules.required]" class="required" link="emit" @link-click="openUser(callUser)" />
            </v-col>

            <v-col cols="1" class="text-right">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-if="canCreateUser" v-on="on" icon @click="createUser" class="ml-2">
                    <v-icon>
                      $vuetify.icons.add
                    </v-icon>
                  </v-btn>
                </template>
                {{ $t('Create a user') }}
              </v-tooltip>
            </v-col>

            <v-col cols="12" md="4">
              <sca-call-type-select v-model="callType" :label="$t('Type')" />
            </v-col>

            <v-col cols="12" md="8">
              <cs-date-time-picker v-model="callDateTime" required />
            </v-col>

            <v-col cols="12">
              <v-row dense align="center">
                <v-col cols="12" :lg="subjectId === SUBJECT_OTHER ? 2 : 12">
                  <v-select v-model="subjectId" :items="subjectList" :label="$t('Subject')" class="required" />
                </v-col>

                <v-col v-if="subjectId === SUBJECT_OTHER" cols="12" lg="10">
                  <v-text-field v-model="subject" :placeholder="$t('Subject')" :rules="[$stratus.services.form.rules.required]" class="required" />
                </v-col>
              </v-row>

              <div class="mt-4">
                <cs-wysiwyg-textarea v-model="callDetails" :placeholder="$t('Call details...')" :title="$t('Call details...')" :actions="['bold', 'italic', 'underline', 'olist', 'ulist']" :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </div>

      <div v-if="!id" slot="buttons-prepend">
        <v-switch v-model="anotherOne" :label="$t('Create another call')" />
      </div>

      <sca-footer-create-update-at-by slot="footer" v-model="followup" :link-user="isLord" />

      <csm-company-dialog ref="company-dialog" />
      <csm-user-dialog ref="user-dialog" />
    </sca-modal-dialog>

    <sca-modal-dialog :visible="showCreateUserDialog" max-width="600" :is-saving="isSaving" :loading="isSaving" can-save @closeDialog="closeUser" @saveDialog="saveUser">
      <span slot="title">
        {{ $t('Create a user') }}
      </span>

      <div slot="content">
        <v-form ref="user-creation-form" v-model="validUser" :disabled="isSaving || !canCreateUser" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="6" xl="4">
              <v-text-field v-model="userFirstname" :label="$t('Firstname')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(63)]" class="required" :readonly="!canCreateUser" />
            </v-col>

            <v-col cols="12" md="6" xl="4">
              <div class="text-h6">
                <v-text-field v-model="userLastname" :label="$t('Lastname')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(63)]" class="required" :readonly="!canCreateUser" />
              </div>
            </v-col>

            <v-col cols="12" md="6" xl="4">
              <v-text-field v-model="userEmail" :label="$t('Email')" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.email]" class="required" :readonly="!canCreateUser" />
            </v-col>

            <v-col cols="12" md="6" xl="4">
              <sca-customer-select v-model="userCompany" :label="$t('Customer')" :rules="[$stratus.services.form.rules.required]" class="required" single-line :readonly="!canCreateUser" show-email show-phone />
            </v-col>

            <v-col cols="12" md="6" xl="4">
              <v-text-field v-model="userPhone" :label="$t('Phone')" :rules="[$stratus.services.form.rules.phoneOrEmpty]" :readonly="!canCreateUser" />
            </v-col>

            <v-col cols="12" md="6" xl="4">
              <v-text-field v-model="userMobilePhone" :label="$t('Mobile phone')" :rules="[$stratus.services.form.rules.phoneOrEmpty]" :readonly="!canCreateUser" />
            </v-col>
          </v-row>
        </v-form>
      </div>
    </sca-modal-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import config from '@/config'

const CORP_CODE = config.defaults.corporationCode

export default {
  name: 'FollowUpCallDialogLite',
  data () {
    return {
      CORP_CODE,
      DESCRIPTION_MAX_LENGTH: 4095,
      DESCRIPTION_MIN_LENGTH: 1,
      ITEM_TYPE_CALL_IN: this.$alto.defines.FOLLOWUPS.ITEM_TYPE_CALL_IN,
      ITEM_TYPE_CALL_OUT: this.$alto.defines.FOLLOWUPS.ITEM_TYPE_CALL_OUT,
      SUBJECT_OTHER: 0,
      anotherOne: false,
      callDateTime: '',
      callDetails: '',
      caller: null,
      callOpportunityIds: [],
      callPhone: '',
      callType: this.$alto.defines.FOLLOWUPS.ITEM_TYPE_CALL_OUT,
      callUser: null,
      customer: null,
      followup: {},
      id: null,
      idSalesPerson: null,
      isSaving: false,
      loading: false,
      show: false,
      showCreateUserDialog: false,
      subject: '',
      subjectId: 1,
      subjectList: [],
      userCompany: '',
      userEmail: '',
      userFirstname: '',
      userLastname: '',
      userMobilePhone: '',
      userPhone: '',
      valid: false,
      validUser: false
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isLord: '$stratus-states/isLord'
    }),
    canCreateUser () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.USERS, this.$alto.API_PERMISSIONS.CREATE)
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.UPDATE)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.UPDATE)
    },
    color () { return this.$store.getters['followups/ITEM_COLOR'](this.$store.getters['followups/ITEM_TYPE_CALL']) }
  },
  async mounted () {
    this.subjectList = [
      { value: 1, text: this.$t('Making contact') },
      { value: 2, text: this.$t('Business follow-up') },
      { value: 3, text: this.$t('Lead qualification') },
      { value: 4, text: this.$t('Closing') },
      { value: 5, text: this.$t('News taking') },
      { value: this.SUBJECT_OTHER, text: this.$t('Other') }
    ]
  },
  methods: {
    close (saved = false) {
      this.show = false
      this.resolve(saved)
      if (this.anotherOne) {
        this.$emit('close-reopen', saved)
      } else {
        this.$emit('close', saved)
      }
    },
    closeUser () {
      this.showCreateUserDialog = false
      this.userFirstname = ''
      this.userLastname = ''
      this.userCompany = ''
      this.userEmail = ''
      this.userPhone = ''
      this.userMobilePhone = ''
    },
    createUser () {
      this.showCreateUserDialog = true
    },
    externalId () { return this.id ? 'followup-call/' + this.id : '' },
    getCurrentUser () {
      return this.$refs['followup-call-contact-select'] ? this.$refs['followup-call-contact-select'].getUser() : null
    },
    isDark () { return this.$store.getters['$stratus-states/isDark'] },
    open (data) {
      this.loading = true
      this.reset(data)
      this.show = true
      this.$nextTick(() => { this.loading = false })
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async reset (data = {}) {
      if (this.$refs['followup-form']) await this.$refs['followup-form'].reset()
      if (data.id) this.id = data.id
      this.callDateTime = data.date_call || new Date()
      this.caller = data.id_caller || this.me.id
      this.callDetails = data.details_call || ''
      this.callPhone = data.number_phone_user || this.getCurrentUser()?.user.phone
      this.idSalesPerson = data.id_lord_salesperson || this.getCurrentUser()?.companyRaw.id_lord_salesperson
      this.callType = data.type_call || this.ITEM_TYPE_CALL_OUT
      this.callUser = data.id_user || this.getCurrentUser()?.id
      this.subject = data.subject || ''
      this.customer = data.code || this.getCurrentUser()?.company
      this.isSaving = false
      this.valid = false
      this.anotherOne = data.anotherOne || false
    },
    async save () {
      if (!this.$refs['followup-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        return
      }

      try {
        this.isSaving = true
        const subjectText = _.find(this.subjectList, { value: this.subjectId })?.text || ''

        const data = {
          code: this.customer,
          id_caller: this.caller,
          id_user: this.callUser,
          number_phone_user: this.callPhone,
          id_lord_salesperson: this.idSalesPerson,
          details_call: this.callDetails,
          subject: this.subjectId === this.SUBJECT_OTHER ? this.subject : subjectText,
          type_call: this.callType,
          date_call: this.$stratus.dt(this.callDateTime).toISOString()
        }

        if (this.id) data.id = this.id // Update

        const response = this.$store.dispatch('followups/saveCall', data)
        this.$stratus.services.notify.success(this.$t('Call followup saved.', this.getCurrentUser()?.user))
        this.resolve(response)
        this.close(true)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.isSaving = false
      }
    },
    async saveUser () {
      if (!this.$refs['user-creation-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      try {
        this.isSaving = true
        const _user = this.$stratus.services.strings.stripHtmlTags({
          firstname: this.$stratus.services.strings.inflector.titleize(this.userFirstname),
          lastname: this.$stratus.services.strings.toUpperCase(this.userLastname),
          company: this.userCompany,
          email: this.userEmail,
          phone: this.userPhone,
          mobile_phone: this.userMobilePhone,
          type: this.$alto.defines.USERS.USER_TYPE_USER,
          role: this.$alto.defines.USERS.USER_ROLE_SPECTATOR,
          password: this.$stratus.services.strings.getPassword(),
          customers: [this.userCompany]
        })

        const newUser = await this.$store.dispatch('$alto-users/save', _user)
        await this.$store.dispatch('$alto-users/fillCache', true)
        this.$stratus.services.notify.success(this.$t('User {lastname} {firstname} created.', newUser))
        this.closeUser()
        // Add new user to cache and select it
        if (this.$refs['followup-call-contact-select']) {
          this.$refs['followup-call-contact-select'].cacheUser(newUser)
          this.$nextTick(() => { this.callUser = newUser.id })
        }
      } catch (error) {
        if (error.body?.code === 'USE-403') {
          this.$stratus.services.notify.error(this.$t('This email address is already used by another user!'))
        } else {
          this.$stratus.services.notify.error(error)
        }
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>
