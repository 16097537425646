import Vue from 'vue'

// Register services
Vue.mixin({
  beforeCreate () {
    const options = this.$options
    if (options.csServices) {
      this.$cs = options.csServices
    } else if (options.parent && options.parent.$cs) {
      this.$cs = options.parent.$cs
      // Shortcuts
      this.$prices = options.parent.$cs.prices
    }
  }
})

export default {}
