<template>
  <v-row>
    <v-col cols="12">
      <sca-advanced-store-grid v-if="columns" :options="gridOptions" resource="users" :columns="columns" :filters="filters" :csv-export="computeCsvData" search-text-col-size="sm" @createItem="createItem" @deleteItem="deleteItem" @showItem="showItem" @updateItem="updateItem" @resetFilters="resetFilters" :custom-search="customSearch" ref="usersGrid">
        <template slot="search-append">
          <v-row dense align="center" class="mx-1">
            <v-col cols="2">
              <v-select :items="userTypes" v-model="userTypeSearch" :label="$t('Type')" @input="onCustomSearchInput" clearable>
                <template v-slot:item="{ item }">
                  <v-icon small left>
                    {{ getUserTypeIcon(item.value) }}
                  </v-icon> {{ item.text }}
                </template>
                <template v-slot:selection="{ item }">
                  <v-icon small left>
                    {{ getUserTypeIcon(item.value) }}
                  </v-icon> {{ item.text }}
                </template>
              </v-select>
            </v-col>

            <v-col v-if="isLord" cols="6" md="4">
              <sca-customer-select v-model="companiesSearch" :label="$t('Company')" @input="onCustomSearchInput" single-line clearable show-email show-phone link="emit" @link-click="openCompany(companiesSearch)" />
            </v-col>

            <v-col v-if="isLord" cols="6" md="3">
              <sca-user-state-select v-model="userStateSearch" @input="onCustomSearchInput" clearable />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item-company="{ itemRaw }">
          <sca-company-identity :value="itemRaw" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(itemRaw)" />
        </template>

        <template v-slot:item-lord_team_id="{ itemRaw }">
          {{ getTeamName(itemRaw) }}
        </template>

        <template v-slot:item-lastname="{ row }">
          <sca-user-identity :value="row.id" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(row.id)" />
        </template>

        <template v-slot:item-email="{ itemRaw }">
          <sca-email :value="itemRaw" dense show-card :link="isLord" />
        </template>

        <template v-slot:item-phone="{ itemRaw }">
          <cs-phone :value="itemRaw" dense />
        </template>

        <template v-slot:item-state="{ itemRaw }">
          <sca-user-state-identity :value="itemRaw" />
        </template>

        <template v-slot:item-type="{ item, row }">
          <v-icon small :color="me?.id === row.id ? 'primary' : getUserTypeColor(item)">
            {{ getUserTypeIcon(item) }}
          </v-icon>
          <span class="text-caption" :class="me?.id === row.id ? 'primary--text' : ''">
            {{ $t(item) }}
          </span>
        </template>

        <template slot="legend">
          <div class="d-flex align-center">
            <div class="flex-shrink-1 text-no-wrap primary--text">
              <v-icon class="primary--text" left>
                $vuetify.icons.user
              </v-icon>
              {{ $t('It\'s you!') }}
            </div>
            <div v-if="isLord" class="ml-auto text-caption">
              {{ $t('(¹) You can see all users, even those who have been deleted. Deleted users are in fact simply deactivated.') }}
            </div>
          </div>
        </template>
      </sca-advanced-store-grid>
    </v-col>
    <csm-company-dialog ref="company-dialog" />
    <csm-user-dialog ref="user-dialog" @closeDialog="closeDialog" />
  </v-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'UsersList',
  data () {
    return {
      columns: null,
      companies: [],
      companiesCache: {},
      companiesSearch: '',
      isLogged: false,
      me: {},
      user: {},
      teams: [],
      userTypeSearch: '',
      userStateSearch: null,
      filters: ['firstname', 'lastname', 'company', 'email', 'phone']
    }
  },
  computed: {
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    gridOptions () {
      return {
        allowColumnsVisible: true,
        advancedSearchFieldsSchemaBaseUrl: '/docs/users',
        advancedSearchFieldsSchemaName: 'User',
        create: this.isLogged && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.USERS, this.$alto.API_PERMISSIONS.CREATE),
        allowContextMenuOnCell: true,
        delete: false,
        foreignFields: ['phone', 'firstname'],
        foreignFieldsFilter: true,
        messages: {
          deleteItem: 'Please, confirm the deletion of user {lastname} {firstname} <{email}>?'
        },
        multiSelect: false,
        notifyErrors: true,
        search: this.isLogged,
        searchVisibleColumns: false, // Search all column, even those that are not visible
        show: false,
        sortBy: 'lastname',
        sortDescending: false,
        update: false
      }
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    userTypes () {
      return [
        { text: this.$t('Bot'), value: 'bot' },
        { text: this.$t('User'), value: 'user' }
      ]
    }
  },
  methods: {
    computeCsvData (userList) {
      if (!userList || userList.length === 0) return userList

      const newsCols = {}
      // Then set to true if a newsletter is subscribed
      const result = []
      _.forEach(userList, user => {
        // Reset subscriptions
        _.map(newsCols, col => { return { name: col.name, subscribed: false } })
        // Replace object attribute newsLetters by our simple list
        const record = { ...user }
        // delete record.newsletters // Do not keep original field
        result.push(record)
      })
      return result
    },
    async closeDialog () {
      await this.$store.dispatch('$alto-users/getCache', true) // Reload cache
      if (this.$refs.usersGrid) this.$refs.usersGrid.fetchData({ forceReload: true })
    },
    createColumns () {
      let cols = [{
        text: 'Name',
        value: 'lastname'
      }, {
        text: 'Email',
        value: 'email'
      }, {
        text: 'Company',
        value: 'company'
      }, {
        text: 'Role',
        value: 'role',
        format: (value) => {
          return this.$t('user-role-' + value)
        }
      }, {
        text: 'Last authentication',
        value: 'lastAuthDate',
        hidden: true,
        format: v => {
          return v ? this.$stratus.services.fieldRenderers.DATETIME(v) : `<span class="warning--text">${this.$t('Never logged in.')}</span>`
        }
      }, {
        text: 'Type',
        value: 'type',
        align: 'center',
        alignValue: 'center'
      }, {
        text: 'Technical manager',
        value: 'is_technical_manager',
        align: 'center',
        alignValue: 'center',
        format: this.$stratus.services.fieldRenderers.BOOLEAN_CHECK_IF_TRUE
      }]

      if (this.isLord) {
        cols.push({
          text: 'Team',
          value: 'lord_team_id'
        }, {
          text: 'Can authenticate?',
          value: 'auth_enabled',
          align: 'center',
          alignValue: 'center',
          hidden: true,
          format: function (value) {
            return '<span class="' + (value ? 'green--text icon-padlock-unlocked' : 'danger--text icon-padlock') + '"></span> <span class="caption">' + this.$t(value ? 'Yes' : 'No') + '</span>'
          }
        })
      }

      cols = cols.concat([{
        text: 'State',
        value: 'state',
        width: 100,
        align: 'center',
        alignValue: 'center',
        hidden: true
      }, {
        text: 'Phone',
        value: 'phone',
        hidden: true
      }, {
        text: 'Updated at',
        value: 'update_at',
        align: 'right',
        alignValue: 'right',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }])

      return cols
    },
    createItem () {
      if (!this.companiesSearch && !this.me?.company) return
      this.user = {
        type: 'user',
        role: 'bus',
        company: this.companiesSearch || this.me?.company
      }
      this.openUser(this.user)
    },
    customSearch (items) {
      const cusSearch = []
      if (this.companiesSearch) {
        cusSearch.push({
          column: 'company',
          search: this.companiesSearch
        })
      }
      if (this.userTypeSearch) {
        cusSearch.push({
          column: 'type',
          search: this.userTypeSearch
        })
      }
      if (this.isLord && this.userStateSearch) {
        cusSearch.push({
          column: 'state',
          search: this.userStateSearch,
          operator: 'eq' // Strict equality
        })
      }
      return cusSearch
    },
    deleteItem ({ success, error, item }) {
      if (success) {
        this.$stratus.services.notify.success(this.$t('User {lastname} {firstname} <{email}> deleted.', item))
      } else {
        if (error && error.status === 403) {
          this.$stratus.services.notify.warning(this.$t('You\'re not allowed to delete {lastname} {firstname} <{email}>!', item))
        } else {
          this.$stratus.services.notify.error(error)
        }
      }
    },
    getTeamName (id) {
      return _.find(this.teams, { value: id })?.text
    },
    getUserTypeColor (type) {
      switch (type ? type.toLowerCase() : type) {
        case 'user':
          return 'menu-icon'
        case 'bot':
          return 'orange'
        default:
          return type
      }
    },
    getUserTypeIcon (type) {
      switch (type ? type.toLowerCase() : type) {
        case 'user':
          return 'icon-user'
        case 'bot':
          return 'icon-bot'
        default:
          return type
      }
    },
    async loadLists () {
      try {
        await this.$store.dispatch('$alto-companies/list')
        this.companiesCache = this.$store.getters['$alto-companies/cache']()
        this.companies = this.$stratus.services.fields.ObjectToSelectItems(this.companiesCache, { keyInValue: true })
        this.customers = [this.myCompany]

        if (this.isLord) {
          await this.$store.dispatch('$alto-companies/loadTeams')
          this.teams = this.$store.getters['$alto-companies/teams']
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    onCustomSearchInput () {
      this.$refs.usersGrid.fetchData()
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    resetFilters () {
      this.companiesSearch = ''
      this.userTypeSearch = ''
    },
    showItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.openUser(data.item.id)
    },
    updateItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.openUser(data.item.id)
    }
  },
  async created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Users'))
    this.me = await this.$store.dispatch('$stratus-states/getMe')
  },
  mounted () {
    this.isLogged = this.$stratus.services.auth.isLogged()
    this.loadLists()
    this.columns = this.createColumns()
  }
}
</script>
