<template>
  <sca-modal-dialog :visible="show" max-width="600" @closeDialog="close" @saveDialog="save" :can-save="canSave" :is-saving="isSaving" :action="action" :external-id="externalId()">
    <template #title>
      {{ subject }} — {{ update ? $t('Task') : $t('Add a task') }}
    </template>

    <div slot="content">
      <v-form ref="followup-form" v-model="valid" lazy-validation>
        <v-row dense align="end">
          <v-col v-if="companyCode === null" cols="12" md="4">
            <sca-customer-select v-model="customer" :rules="[$stratus.services.form.rules.required]" class="required" :label="$t('Customer')" clearable @input="onChangeCustomer" show-email show-phone link="emit" @link-click="openCompany(customer)" />
          </v-col>

          <v-col>
            <sca-users-select v-model="owner" filter-lord show-email show-phone show-company show-card :label="$t('Owner')" :rules="[$stratus.services.form.rules.required]" class="required" clearable link="emit" @link-click="openUser(owner)" />
          </v-col>
        </v-row>

        <v-row dense align="end">
          <v-col cols="4">
            <v-select v-model="priority" :items="priorityList" :label="$t('Priority')" :rules="[$stratus.services.form.rules.required]" class="required" />
          </v-col>

          <v-col cols="4">
            <v-select v-model="status" :items="statusList" :label="$t('Status')" :rules="[$stratus.services.form.rules.required]" class="required" />
          </v-col>

          <v-col cols="4">
            <cs-date-picker v-model="dateDue" :label="$t('Due date')" :rules="[$stratus.services.form.rules.required]" class="required" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field v-model="subject" :label="$t('Subject')" :rules="[$stratus.services.form.rules.required]" class="required" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <cs-wysiwyg-textarea v-model="description" :placeholder="$t('Description')" :title="$t('Description')" :actions="['bold', 'italic', 'underline', 'olist', 'ulist']" :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" />
          </v-col>
        </v-row>
      </v-form>
    </div>

    <sca-footer-create-update-at-by slot="footer" v-model="followup" :link-user="isLord" />

    <csm-company-dialog ref="company-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

let previousId = null

export default {
  name: 'FollowUpTaskDialog',
  props: {
    companyCode: { type: String, default: null },
    update: { type: Boolean, default: false }
  },
  data () {
    return {
      followup: {},
      customer: null,
      dateDue: null,
      description: '',
      guestList: [],
      owner: null,
      idSalesPerson: null,
      idUser: null,
      subject: '',
      priority: '',
      status: '',
      DESCRIPTION_MAX_LENGTH: 4095,
      DESCRIPTION_MIN_LENGTH: 1,
      id: null,
      isSaving: false,
      show: false,
      valid: false
    }
  },
  watch: {
    companyCode: {
      immediate: true,
      handler (newValue) {
        this.customer = newValue
      }
    }
  },
  computed: {
    action () {
      if (this.canUpdate) return this.$t('Update')
      return this.$t('Details')
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.UPDATE)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.UPDATE)
    },
    color () { return this.$store.getters['followups/ITEM_COLOR'](this.$store.getters['followups/ITEM_TYPE_TASK']) },
    icon () { return this.$store.getters['followups/ICON_TYPE'](this.$store.getters['followups/ITEM_TYPE_TASK']) },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    maxWidth () {
      if (this.$vuetify.breakpoint.xl) return '50%'
      if (this.$vuetify.breakpoint.lgAndUp) return '70%'
      return '100%'
    },
    priorityList () { return this.$store.getters['$alto-followups/tasksPriorities'] },
    statusList () { return this.$store.getters['$alto-followups/tasksStatus'] }
  },
  async mounted () {
    await this.$store.dispatch('$alto-followups/tasksStatus')
    await this.$store.dispatch('$alto-followups/tasksPriorities')

    if (this.$route?.name === 'followup-task' && this.$route.params.id) {
      if (this.$route.params.id !== previousId) {
        previousId = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        this.openId(this.$route.params.id)
      }
    }
  },
  methods: {
    close (saved = false) {
      this.show = false
      this.resolve(saved)
      this.$emit('close', saved)
    },
    customerName (id) { return this.$store.getters['$alto-companies/getName'](id) },
    externalId () {
      return this.id ? 'followup-task/' + this.id : ''
    },
    isDark () { return this.$store.getters['$stratus-states/isDark'] },
    async onChangeCustomer () {
      // Do not modify if a sales person is already input
      if (!this.customer || this.idSalesPerson) return null
      try {
        const salesPersonId = await this.$store.dispatch('$alto-companies/getSalesPerson', this.customer)
        if (_.isNumber(salesPersonId) && salesPersonId >= 0) {
          this.idSalesPerson = salesPersonId
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    open (data) {
      this.reset(data)
      this.followup = { ...data }
      this.show = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    async openId (id) {
      try {
        const followup = await this.$store.dispatch('$alto-followups/task', id)
        this.open(followup)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async reset (data = {}) {
      if (this.$refs['followup-form']) await this.$refs['followup-form'].reset()
      this.id = data.id
      this.subject = data.subject || ''
      this.priority = data.priority || ''
      this.status = data.status || ''
      this.dateDue = data.date_due || this.$stratus.dt().format('YYYY-MM-DD')
      this.description = data.description || ''
      this.idSalesPerson = data.id_lord_salesperson
      this.customer = data.code || this.companyCode
      this.owner = data.id_owner || null
      this.isSaving = false
      this.valid = false
    },
    save () {
      this.isSaving = true
      if (!this.$refs['followup-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      const data = {
        id: this.id,
        subject: this.subject,
        priority: this.priority,
        status: this.status,
        date_due: this.date_due || this.$stratus.dt(this.dateDue).format('YYYY-MM-DD'),
        description: this.description,
        id_lord_salesperson: this.idSalesPerson,
        code: this.customer || this.companyCode,
        id_owner: this.owner
      }

      this.$store.dispatch('followups/saveTask', data)
        .then(response => {
          this.resolve(response)
          this.close(true)
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
          this.isSaving = false
        })
    }
  }
}
</script>
