<template>
  <sca-modal-dialog :visible="visible" :loading="loading || isSaving" save-button-text="Send" @closeDialog="closeDialog" @saveDialog="saveDialog" :can-save="!blockedPayment" :is-saving="isSaving" max-width="70%">
    <template #title>
      {{ $t(`ticket-type-${type}`) }}
      <span class="text-caption">
        — {{ $t(`ticket-type-${type}-help`) }}
      </span>
    </template>

    <div slot="content">
      <v-row v-if="blockedPayment" justify="center">
        <v-col class="shrink text-no-wrap">
          <cs-alert-panel type="warning" dense :text="$t('Payment problem, please contact Scalair sales or administrative department.')" />
        </v-col>
      </v-row>

      <v-form ref="form-new-request" v-model="valid" lazy-validation :disabled="loading || isSaving">
        <v-row dense>
          <v-col>
            <v-text-field v-model="title" :label="$t('Title')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(64)]" class="required" dense counter="64" />
          </v-col>

          <v-col cols="12">
            <sca-wysiwyg-editor v-model="description" ref="ticket-description-editor" medium :title="$t('Description')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(4096)]" required :customers="company" :max-length="4096" :min-length="1" :disabled="loading || isSaving" />
          </v-col>

          <v-col v-if="needCustomer" cols="12" md="6" lg="4">
            {{ $t('Which company is concerned?') }}
            <div v-if="subscription?.code">
              <sca-company-identity :value="subscription.code" show-avatar show-email show-phone show-sales-person />
            </div>
            <sca-customer-select v-else v-model="company" clearable dense hide-details link="emit" @link-click="openCompany(company)" @input="companyBlockedPayment" />
          </v-col>

          <v-col cols="12" md="6" lg="8">
            {{ $t('Products concerned') }}
            <div v-if="subscription?.id">
              <sca-subscription-identity :value="subscription" :label="subscription.customer_caption || subscription.caption || subscription.name" show-card />
            </div>
            <sca-subscription-select v-else v-model="products" :company="company" only-subscriptions clearable dense hide-details multiple show-caption single-line :show-company="needCustomer" :readonly="subscription !== null" :show-order="isLord" :show-product="isLord" />
          </v-col>

          <v-col cols="12">
            {{ $t('Other products') }}

            <cs-helper class="full-width">
              <v-text-field slot="content" v-model="productsOther" :rules="[$stratus.services.form.rules.max(128)]" hide-details counter="128" clearable dense />
              <div slot="help">
                {{ $t('Use this input to enter products you may not have found in the list.') }}
              </div>
            </cs-helper>
          </v-col>

          <v-col cols="12">
            <v-switch v-model="isUrgent" :label="$t('This is an exceptionally urgent request')" />
          </v-col>
        </v-row>
      </v-form>

      <div>
        <div>
          <cs-file-upload-input v-model="attachments" :file-size-limit="ALL_FILE_SIZE_LIMIT" :total-size-limit="ALL_FILE_SIZE_LIMIT" multiple use-isu />
        </div>
        <div class="text-caption text-right">
          {{ $t('You have a space of {size}MB for all attachments.', { size: ALL_FILE_SIZE_LIMIT / 1000 / 1000 }) }}
        </div>
      </div>
    </div>
  </sca-modal-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import config from '@/config'

export default {
  name: 'TicketNewRequest',
  props: {
    descriptor: { type: [Array, Object], required: true },
    subscription: { type: Object, default: null },
    visible: { type: Boolean, default: false }
  },
  data () {
    return {
      excludeRefs: config?.subscriptions?.list?.hideProducts?.refs,
      ALL_FILE_SIZE_LIMIT: 20 * 1000 * 1000,
      attachments: [],
      blockedPayment: false,
      company: [],
      description: '',
      isSaving: false,
      isUrgent: false,
      loading: false,
      products: [],
      productsOther: '',
      title: '',
      type: this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST,
      uuid: this.$stratus.uuid(),
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      isLord: '$stratus-states/isLord',
      me: '$stratus-states/me'
    }),
    needCustomer () {
      return this.me?.customers?.length > 1 || this.me?.customers?.includes('ALL')
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },
    async companyBlockedPayment () {
      const company = await this.$store.dispatch('$alto-companies/getById', this.company)
      this.blockedPayment = company?.blocked_payment || false
    },
    reset () {
      this.attachments = []
      this.blockedPayment = false
      this.company = this.me?.company || null
      this.description = ''
      this.products = []
      this.productsOther = ''
      this.isUrgent = false
      this.isSaving = false
      this.loading = false
      this.title = ''
      this.valid = false
      this.companyBlockedPayment()
    },
    async saveDialog (closeDialog = true) {
      this.isSaving = true

      let products = []
      if (this.subscription?.id) {
        products = [this.subscription.id]
      } else {
        products = this.products
      }

      if (!products.length && !this.productsOther.length) {
        this.$stratus.services.notify.warning(this.$t('At least one product must be entered.'))
        this.isSaving = false
        return false
      }

      if (!this.$refs['form-new-request'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return false
      }

      try {
        // Sanitize
        this.title = this.$stratus.services.strings.stripHtmlTags(this.title)

        const ticket = {
          type: this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST,
          domain: null,
          product_category: null,
          product_free: this.productsOther || null,
          name: this.title,
          is_urgent: this.isUrgent
        }

        if (this.$refs['ticket-description-editor']) {
          ticket.description = this.$refs['ticket-description-editor'].getContent()
        } else {
          ticket.description = ''
        }

        if (this.subscription?.code) {
          ticket.code = this.subscription.code
        } else {
          ticket.code = this.company?.length ? this.company : this.me.company
        }

        ticket.products = products

        const savedTicket = await this.$store.dispatch('$alto-ticketing/postTicketLite', {
          ticket,
          files: this.attachments
        })
        this.$stratus.services.notify.success(this.$t('Ticket {name} created.', { name: this.title }))

        if (closeDialog) this.closeDialog()
        this.isSaving = false
        return savedTicket
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.isSaving = false
        return false
      }
    }
  }
}
</script>
