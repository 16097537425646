<template>
  <v-select :value="value" v-bind="$attrs" :items="productTypes" :rules="[$stratus.services.form.rules.required]" class="required" :label="$t('Type of product')" :loading="isLoading" multiple @input="updateValue">
    <template v-slot:selection="{ item }">
      <v-chip>{{ item }}</v-chip>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'ProductTypeSelect',
  /* eslint-disable-next-line vue/require-prop-types */
  props: ['value'],
  data () {
    return {
      isLoading: false,
      search: null
    }
  },
  computed: {
    productTypes () {
      return this.$store.getters['products/types']
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    }
  },
  mounted () {
    if (this.productTypes.length === 0) {
      this.isLoading = true
      this.$store.dispatch('products/list')
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.esLoading = false
          })
        })
    }
  }
}
</script>
