<template>
  <csm-sphere-form ref="sphere-form" :visible="dialogVisible" :sphere="sphere" :show="canShow" :update="canUpdate" @closeDialog="closeDialog" @refresh="onRefresh" />
</template>

<script>
import _ from 'lodash'

let previousProject = null

export default {
  name: 'SphereDialog',
  components: { 'csm-sphere-form': () => import(/* webpackChunkName: "components" */ './SphereForm') },
  data () {
    return {
      dialogVisible: false,
      sphere: {}
    }
  },
  computed: {
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PROJECTS, this.$alto.API_PERMISSIONS.READ)
    },
    canUpdate () {
      return this.sphere?.id && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PROJECTS, this.$alto.API_PERMISSIONS.UPDATE)
    }
  },
  methods: {
    closeDialog () {
      this.dialogVisible = false
      previousProject = null
      if (this.$route?.name === 'sphere' && this.$route?.params?.id) {
        this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
      } else {
        this.$emit('closeDialog', this.sphere)
      }
    },
    async get (id) {
      if (_.isObject(id)) {
        this.sphere = id || {}
        return
      }

      try {
        this.sphere = { ...await this.$store.dispatch('$alto-spheres/get', { id, forceReload: true }) }
      } catch (error) {
        if (error.status === 404) this.$stratus.services.notify.error(this.$t('Sphere «{name}» not found!', { name: id }))
        else this.$stratus.services.notify.error(error)
        this.closeDialog()
      }
    },
    async onRefresh (data) {
      if (!data.id) {
        this.sphere = { ...data }
        this.$forceUpdate()
        return
      }
      // Reload
      await this.get(data.id)
      this.$forceUpdate()
    },
    open (id) {
      if (!id) return
      this.reset()
      this.get(id)
      this.dialogVisible = true
    },
    async reset () {
      if (this.$refs['sphere-form']) await this.$refs['sphere-form'].reset()
    }
  },
  async mounted () {
    if (this.$route?.name === 'sphere' && this.$route.params.id) {
      if (this.$route.params.id !== previousProject) {
        previousProject = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        this.open(this.$route.params.id)
      }
    }
  }
}
</script>
