<template>
  <csm-sla-grid ref="sla-dashboard-grid" :columns="columns" :options="options">
    <span slot="search-append" class="text-caption ml-1" v-show="lastRefreshDate">
      {{ $t('Last refresh: {date}', { date: $stratus.dt(this.lastRefreshDate).format('LT') }) }}
    </span>
  </csm-sla-grid>
</template>

<script>
const DEFAULT_REFRESH_DELAY = 3 // Minutes

export default {
  name: 'SlaDashboard',
  components: {
    'csm-sla-grid': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/SlaGrid')
  },
  data () {
    return {
      lastRefreshDate: new Date(),
      options: {
        advancedSearchFieldsSchemaBaseUrl: '/docs/tickets',
        advancedSearchFieldsSchemaName: 'SLACounter',
        allowContextMenuOnCell: true,
        allowActions: true,
        allowColumnsVisible: true,
        create: false,
        delete: false,
        foreignFields: ['id'],
        key: 'id',
        multiSelect: false,
        notifyErrors: true,
        search: this.isLogged,
        show: false,
        sortBy: 'due_date',
        sortDescending: false,
        update: false,
        queryAdd: { onList: { state: 'ongoing' } } // Only a part of SLA
      },
      refreshHandler: null
    }
  },
  computed: {
    columns () {
      return [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Ticket',
        value: 'id_ticket'
      }, {
        text: 'Start at',
        value: 'start_at',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.DATETIME
      }, {
        text: 'Passed time',
        value: 'passed_time',
        hidden: true
      }, {
        text: 'Passed time %',
        value: 'per_passed_time',
        sortable: false
      }, {
        text: 'Due time',
        value: 'due_time',
        hidden: true
      }, {
        text: 'Due date',
        value: 'due_date',
        format: v => v ? this.$stratus.services.fieldRenderers.DATETIME(v) : ''
      }, {
        text: 'Ticket name',
        value: 'ticket_name'
      }, {
        text: 'Company',
        value: 'ticket_code'
      }, {
        text: 'Assigned to',
        value: 'ticket_id_owner'
      }, {
        text: 'Referrer',
        value: 'ticket_id_referring'
      }, {
        text: 'Team',
        value: 'ticket_id_lord_team'
      }, {
        text: 'Created by',
        value: 'ticket_create_by',
        hidden: true
      }]
    }
  },
  methods: {
    async refresh () {
      try {
        if (this.$refs['sla-dashboard-grid']) {
          this.$refs['sla-dashboard-grid'].refresh()
          this.lastRefreshDate = new Date()
        }
      } catch (error) {
        console.error('[SLA] dashboard error:', error)
        this.$stratus.services.notify.error(error)
      } finally {
        if (this.$refs['sla-dashboard-grid']) this.refreshHandler = setTimeout(this.refresh, DEFAULT_REFRESH_DELAY * 60 * 1000)
      }
    }
  },
  beforeDestroy () {
    clearTimeout(this.refreshHandler)
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('SLA Dashboard'))
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(this.refresh, DEFAULT_REFRESH_DELAY * 60 * 1000)
    })
  }
}
</script>
