<template>
  <v-row align="center" no-gutters>
    <v-col>
      <span>{{ label }} : {{ value }}</span> <span v-if="unit">{{ $t(unit) }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CsmDynaProductOther',
  props: {
    label: { type: String, default: '' },
    unit: { type: String, default: '' },
    value: { type: String, required: true }
  },
  data () { return {} }
}
</script>
