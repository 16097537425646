<template>
  <cs-expand-panel block>
    <v-row slot="header" align="center" dense>
      <v-col class="font-weight-bold">
        {{ $t('Number of calls') }}
        <cs-icon-loading v-if="loading" small right />
      </v-col>

      <v-col v-if="user?.lord_team_info?.calls_by_week" class="shrink text-no-wrap">
        <span class="text-caption">
          {{ $t('Target: ') }}
        </span>
        <span class="font-weight-bold">
          {{ $t('{count}{unit}', { count: user.lord_team_info.calls_by_week, unit: $t('per week') } ) }}
        </span>
      </v-col>

      <v-col v-else class="shrink text-no-wrap">
        <span class="error--text">
          <v-icon class="error--text">
            $vuetify.icons.error
          </v-icon>
          {{ $t('No target is defined.') }}
        </span>
      </v-col>

      <v-col v-show="dataExists && average?.user?.lord_team_info?.calls_by_week" cols="12">
        <v-row dense align="center">
          <v-col class="shrink text-no-wrap ml-4">
            {{ $t('On target') }}
          </v-col>

          <v-col class="shrink text-no-wrap">
            <v-chip label :dark="dark" :color="average?.weeksTargetIn === average?.weeks ? 'success' : colorTargetIn" class="color0--text">
              {{ $tc('{count} weeks', average?.weeksTargetIn, { count: average?.weeksTargetIn }) }}
            </v-chip>
          </v-col>

          <v-col class="shrink text-no-wrap ml-4">
            {{ $t('Under target') }}
          </v-col>

          <v-col class="shrink text-no-wrap">
            <v-chip label :dark="dark" :color="colorTargetOut" class="color0--text">
              {{ $tc('{count} weeks', average?.weeksTargetOut, { count: average?.weeksTargetOut }) }}
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div slot="content">
      <v-row v-show="!dataExists" justify="center" class="mt-4">
        <cs-alert-panel type="info" dense :text="$t('No data for this period...')" />
      </v-row>

      <v-row v-show="dataExists" justify="center">
        <v-col cols="12">
          <cs-chart-bar :ref="`call-by-week-chart-${userId}`" class="call-by-week-chart" :chart-data="graphData" :options="getGraphOptions()" />
        </v-col>

        <v-col v-show="callsDetails?.length" v-if="canListCalls" cols="12">
          <div class="text-h5">
            {{ $t('Details') }} — {{ callsDetailsPeriod }}
            <v-chip>
              {{ $t('{count} events', { count: callsDetails.length }) }}
            </v-chip>
          </div>
          <csm-follow-up-list ref="target-follow-up" :sales-persons="[userId]" :no-data-request="true" :raw-data-calls="callsDetails" hide-search @loading="onFollowupLoading" @loaded="onFollowupLoaded" />
        </v-col>

        <v-col v-if="!callsDetails?.length" class="shrink text-no-wrap">
          <cs-alert-panel dense type="info" :text="$t('Click on a bar in the graph to see the event list.')" />
        </v-col>
      </v-row>
    </div>
  </cs-expand-panel>
</template>

<script>
import _ from 'lodash'

const COLOR_TARGET_IN = '#6BDE81'
const COLOR_TARGET_OUT = '#FFC133'
const COLOR_NO_TARGET = '#777'
const DEFAULT_CHART_DATA = { labels: [], datasets: [{ data: [] }] }

export default {
  name: 'TargetProspectByWeek',
  components: {
    'csm-follow-up-list': () => import('@/components/FollowUps/FollowUpList')
  },
  props: {
    beginAt: { type: [Date, String], required: true },
    colorNoTarget: { type: String, default: COLOR_NO_TARGET },
    colorTargetIn: { type: String, default: COLOR_TARGET_IN },
    colorTargetOut: { type: String, default: COLOR_TARGET_OUT },
    endAt: { type: [Date, String], required: true },
    includeWeekend: { type: Boolean, default: false },
    userId: { type: String, required: true }
  },
  data () {
    return {
      average: {},
      dataExists: false,
      graphData: { ...DEFAULT_CHART_DATA },
      loading: false,
      callsDetails: [],
      callsDetailsPeriod: '',
      rawData: {},
      user: null
    }
  },
  computed: {
    canListCalls () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.LIST)
    },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    isLord () { return this.$store.getters['$stratus-states/isLord'] }
  },
  methods: {
    computeAverage () {
      const hours = this.$stratus.dt(this.endAt).endOf('week').diff(this.$stratus.dt(this.beginAt).startOf('week'), 'hours')
      const weeks = Math.ceil(hours / 24 / 7)
      const average = {
        weeks,
        user: this.user,
        weeksTargetIn: 0,
        weeksTargetOut: this.rawData.bySalesPerson[this.userId] ? 1 + weeks - Object.keys(this.rawData.bySalesPerson[this.userId]).length : weeks,
        callsTargetIn: 0,
        callsTargetFull: this.user?.lord_team_info?.calls_by_week ? this.user?.lord_team_info?.calls_by_week * weeks : 0,
        value: NaN,
        empty: this.rawData.bySalesPerson[this.userId] === undefined
      }

      if (this.rawData?.bySalesPerson[this.userId]) {
        average.value = average.weeks > 0 ? this.$stratus.services.format.asDigits(this.rawData.bySalesPerson[this.userId].total / average.weeks, 2) : NaN

        if (this.user?.lord_team_info?.calls_by_week) {
          _.forEach(this.rawData.bySalesPerson[this.userId], (count, date) => {
            // This is to avoid the key named "total"
            if (this.$stratus.dt(date).isValid()) {
              average.callsTargetIn += count
              if (count >= this.user?.lord_team_info?.calls_by_week) average.weeksTargetIn++
              else average.weeksTargetOut++
            }
          })
        }
      }

      this.average = { ...average }
    },
    createGraph () {
      const labels = []
      const data = []
      const target = []
      let dataExists = false
      const backgroundColor = []

      // Create array with a tick/dot by day

      let date = this.$stratus.dt(this.beginAt).startOf('week')
      const stopDate = this.$stratus.dt(this.endAt).endOf('week')
      while (stopDate.isAfter(date)) {
        const key = date.format('YYYY') + '-' + date.week()
        labels.push(key)
        target.push(this.user?.lord_team_info?.calls_by_week)
        dataExists = true

        // Create graph series of dots
        if (this.rawData.bySalesPerson[this.userId]?.[key] !== undefined) {
          data.push(parseInt(this.rawData.bySalesPerson[this.userId][key]))
          if (this.user?.lord_team_info?.calls_by_week === undefined) {
            // No target is defined for the user, use a neutral color
            backgroundColor.push(this.colorNoTarget)
          } else {
            // Color depends if target is hit or miss
            backgroundColor.push(parseInt(this.rawData.bySalesPerson[this.userId][key]) >= this.user?.lord_team_info?.calls_by_week ? this.colorTargetIn : this.colorTargetOut)
          }
        } else {
          // Put a tick in the graph
          data.push(0)
          backgroundColor.push('')
        }

        date = date.add(1, 'weeks') // Next
      }

      try {
        this.graphData = {
          labels,
          datasets: [{
            label: this.$t('Number of calls'),
            yAxisID: 'counter-axis', // Attach to the correct Y axis
            data,
            backgroundColor,
            ids: this.rawData?.ids?.byWeek || {},
            loadDetails: this.loadDetails,
            order: 1
          }, {
            // This dataset display a line to show the target
            label: this.$t('Target'),
            data: target,
            borderColor: this.$alto.defines.COLORS.PRIMARY,
            borderDash: [10, 10],
            borderWidth: 2,
            fill: false,
            type: 'line',
            pointRadius: 0, // Hide all points
            pointHitRadius: 0, // Disable tooltip
            order: 0
          }]
        }
        this.dataExists = dataExists
      } catch (error) {
        console.error(error)
      }

      this.updateGraph()
    },
    getGraphOptions () {
      return {
        // Click on a bar to load it's events
        onClick (e) {
          // * IMPORTANT *
          // This is a native click event, outside Vue scope!
          // Value of «this» is not vue but the chart instance this event is plug to.
          const element = this.getElementAtEvent(e)
          if (element.length > 0) {
            // load all calls according to the array of ids
            this.data.datasets[element[0]._datasetIndex].loadDetails({
              index: element[0]._index, // Index in data, ids, labels
              ids: this.data.datasets[element[0]._datasetIndex].ids?.[this.data.labels[element[0]._index]],
              chartLabel: this.data.datasets[element[0]._datasetIndex].label,
              xLabel: this.data.labels[element[0]._index]
            })
            this.update()
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              callback: (tick) => {
                const date = tick.split('-')
                return this.$t('{year}/week {week}', { year: date[0], week: date[1] })
              }
            },
            gridLines: {
              display: true
            }
          }],
          yAxes: [{
            id: 'counter-axis',
            type: 'linear',
            ticks: {
              beginAtZero: true,
              min: 0,
              callback: function (value) {
                // Display only ticks for integer value
                if (value % 1 === 0) { return value }
              }
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: (tooltipItems, data) => {
              this.$t('{count}{unit}', { count: tooltipItems.yLabel, unit: this.$t('calls') })
            }
          }
        }
      }
    },
    async loadDetails ({ index, ids, chartLabel, xLabel }) {
      try {
        this.loading = true
        const yearWeek = xLabel.split('-')
        this.callsDetailsPeriod = this.$t('{year}/week {week}', { year: yearWeek[0], week: yearWeek[1] })
        const details = await this.$store.dispatch('followups/getCallsByIds', ids)
        this.callsDetails = details?.results || []
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.loading = false }, 1000)
      }
    },
    onFollowupLoaded (count) {
      this.loading = false
    },
    onFollowupLoading (loading) {
      this.loading = loading
    },
    progress (byCallsCount) {
      if (!this.average) return null

      if (byCallsCount) {
        return this.average.callsTargetFull > 0 ? 100 * this.average.callsTargetIn / this.average.callsTargetFull : null
      }

      return this.average.weeks > 0 ? 100 * this.average.weeksTargetIn / this.average.weeks : null
    },
    async refreshInternal () {
      try {
        this.loading = true
        this.reset()

        this.user = this.$store.getters['$alto-users/getSalesPerson'](this.userId)
        this.rawData = await this.$store.dispatch('followups/callsByWeek', { beginAt: this.beginAt, endAt: this.endAt, salesPersons: this.userId, includeWeekend: this.includeWeekend })

        this.createGraph()
        this.computeAverage()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.loading = false }, 1000)
      }
    },
    reset () {
      this.average = {}
      this.rawData = {}
      this.graphData = { ...DEFAULT_CHART_DATA }
      this.callsDetails = []
      this.callsDetailsPeriod = ''
    },
    success () {
      return this.average && this.average.weeks > 0 ? this.average.weeksTargetIn === this.average.weeks : null
    },
    updateGraph () {
      try {
        if (this.$refs[`call-by-week-chart-${this.userId}`] && this.$refs[`call-by-week-chart-${this.userId}`][0]) {
          this.$refs[`call-by-week-chart-${this.userId}`][0].resize(400, '100%')
          this.$refs[`call-by-week-chart-${this.userId}`][0].update()
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  created () {
    this.refresh = _.debounce(this.refreshInternal, 1000)
  },
  mounted () {
    this.user = this.$store.getters['$alto-users/getSalesPerson'](this.userId)
  }
}
</script>

<style scoped>
.call-by-week-chart {
  height: 400px;
  width: 100%;
}
</style>
