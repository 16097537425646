<template>
  <v-row align="center">
    <v-col cols="12">
      <sca-advanced-store-grid :options="options" resource="orders" :columns="columns" :custom-search="customSearch" @showItem="showItem" @resetFilters="resetFilters" ref="ordersGrid">
        <template slot="search-append">
          <v-row dense align="baseline">
            <v-col v-show="companies.length > 1">
              <sca-customer-select v-model="companiesSearch" :label="$t('Company')" @input="companyChange" clearable show-email show-phone link="emit" @link-click="openCompany(companiesSearch)" />
            </v-col>
            <v-col>
              <v-autocomplete :items="orderStates" v-model="orderStateSearch" :label="$t('State')" @input="orderStateChange" clearable />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item-code="{ itemRaw }">
          <div class="text-truncate">
            <sca-company-identity :value="itemRaw" :text-resume="20" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(itemRaw)" />
          </div>
        </template>

        <template v-slot:item-accomplishment="{ item, row }">
          <sca-order-progress v-if="row.current_state !== 'validating'" :value="item" small />
          <span v-else>—</span>
        </template>

        <template v-slot:item-current_state="{ itemRaw }">
          <sca-order-state :value="itemRaw" dense hide-text full-width />
        </template>
      </sca-advanced-store-grid>
    </v-col>
    <csm-order-dialog ref="order-dialog" @closeDialog="closeDialog" />
    <csm-company-dialog ref="company-dialog" />
  </v-row>
</template>

<script>
export default {
  name: 'OrdersList',
  data () {
    return {
      companies: [],
      companiesCache: {},
      companiesSearch: '',
      isLogged: false,
      // filters: ['id', 'cart_name', 'code', 'create_at', 'current_state', 'accomplishment', 'code'],
      options: {
        advancedSearchFieldsSchemaBaseUrl: '/docs/orders',
        advancedSearchFieldsSchemaName: 'Order',
        allowContextMenuOnCell: true,
        allowColumnsVisible: true,
        create: false,
        customShowItem: true,
        delete: false,
        foreignFields: ['code'],
        key: 'id',
        multiSelect: false,
        notifyErrors: false,
        show: true,
        sortBy: 'update_at',
        sortDescending: true,
        update: false
      },
      orderStateSearch: ''
    }
  },
  computed: {
    columns () {
      return [{
        text: '#Order',
        value: 'id'
      }, {
        text: 'Quotation',
        value: 'cart_name',
        format: this.$stratus.services.fieldRenderers.FALSY_AS_EMPTY
      }, {
        text: 'Customer',
        value: 'code'
      }, {
        text: 'Created at',
        value: 'create_at',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }, {
        text: 'Updated at',
        value: 'update_at',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }, {
        text: 'State',
        value: 'current_state'
      }, {
        text: 'Progression',
        value: 'accomplishment'
      },
      {
        text: 'Total',
        value: 'total',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.CURRENCY
      },
      {
        text: 'Total with discount',
        value: 'total_discount',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.CURRENCY
      },
      {
        text: 'Total setup',
        value: 'total_setup',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.CURRENCY
      },
      {
        text: 'Total setup with discount',
        value: 'total_setup_discount',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.CURRENCY
      },
      {
        text: 'Order number',
        value: 'order_form_number',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.FALSY_AS_EMPTY
      }]
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    orderStates () { return this.$store.getters['orders/states'] }
  },
  methods: {
    closeDialog () {
      this.$refs.ordersGrid.fetchData()
    },
    companyChange () {
      this.$refs.ordersGrid.fetchData()
    },
    customSearch () {
      const cusSearch = []
      if (this.companiesSearch) {
        cusSearch.push({
          column: 'code',
          search: this.companiesSearch
        })
      }
      if (this.orderStateSearch) {
        cusSearch.push({
          column: 'current_state',
          search: this.orderStateSearch
        })
      }
      return cusSearch
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    orderStateChange () {
      this.$refs.ordersGrid.fetchData()
    },
    resetFilters () {
      this.companiesSearch = ''
      this.orderStateSearch = ''
    },
    showItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.$refs['order-dialog'].open(data.item.id)
    }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Orders'))
  },
  async mounted () {
    this.isLogged = this.$stratus.services.auth.isLogged()
    if (!this.isLogged) this.$router.push('/').catch((error) => { console.warn(error) })

    try {
      await this.$store.dispatch('$stratus-states/getMe')
      const me = this.$store.getters['$stratus-states/me']
      if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

      await this.$store.dispatch('orders/listStates')
      await this.$store.dispatch('alto-companies/list')
      this.companiesCache = this.$store.getters['$alto-companies/cache']()
      this.companies = this.$stratus.services.fields.ObjectToSelectItems(this.companiesCache, { keyInValue: true })
    } catch (error) {
      this.$stratus.services.notify.error(error)
    }
    this.options.create = false
    this.options.update = false
    this.options.show = this.isLogged && !this.options.update
    this.options.delete = false
    this.options.search = this.isLogged
  }
}
</script>
