const actions = {
  createFolder ({ commit, rootState, state }, path) {
    return rootState.$stratus.services.api.post('/documents?recursive', { name: path.path, type: 'd' })
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.status !== 409) {
          commit('API_FAILURE', error)
        }
      })
  },
  deleteFile ({ commit, rootState, state }, { path, recursive }) {
    return rootState.$stratus.services.api.delete('/documents' + path + (recursive ? '?recursive' : ''))
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  getFileMeta ({ commit, rootState, state }, path) {
    return rootState.$stratus.services.api.get('/documents' + path)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  getLastRegisters ({ commit, rootState, state }) {
    return rootState.$stratus.services.api.get('/registers?limit=10&query=code[ne]=null&sort=-update_at')
      .then(response => {
        return response?.results || []
      })
      .catch(error => commit('API_FAILURE', error))
  },
  openFolder ({ commit, rootState, state }, path) {
    return rootState.$stratus.services.api.get('/documents' + path)
      .then(response => {
        return response
      })
      .catch(error => commit('API_FAILURE', error))
  },
  sendDocument ({ commit, state, rootState }, { id, file }) {
    return rootState.$stratus.services.api.sendFilesPatch(`/documents/${id}`, file, 'file')
      .then(response => {
        return response.body
      })
      .catch(error => commit('API_FAILURE', error))
  },
  uploadFile ({ commit, rootState, state }, { path, data }) {
    return rootState.$stratus.services.api.put('/documents' + path, data, { headers: { 'Content-Type': 'application/octet-stream' } })
      .then(response => {
        return response.body
      })
      .catch(error => commit('API_FAILURE', error))
  }
}

const mutations = {
  API_FAILURE (state, error) {
    if (error.status === 401) {
      console.warn('[API] status 401', error)
    } else throw error
  }
}

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions,
  mutations
}
