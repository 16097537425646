<script>
export default {
  data () { return {} },
  render: () => '',
  async mounted () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Tickets'))
    const me = await this.$store.getters['$stratus-states/me']
    if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

    if (this.$store.getters['$stratus-states/isLord']) {
      this.$router.replace({ name: 'tickets-list' })
    } else {
      this.$router.replace('/tickets/list')
    }
  }
}
</script>
