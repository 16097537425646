<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters align="center">
        <v-col class="text-subtitle-1 my-2 font-weight-bold">
          {{ $t('Data') }}
        </v-col>
        <v-col class="shrink text-no-wrap text-right text-caption">
          * {{ $t('Required field') }}
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-row v-for="(field, index) in fields" :key="index" dense align="baseline">
        <v-col class="shrink text-no-wrap">
          {{ field.name }}
        </v-col>

        <v-col v-if="isArrayFieldType(field.type)">
          <div v-for="i in fieldCountByName[field.name]" :key="i">
            <csm-task-data-input :index="i" :field-data="getData(field.name, i - 1)" :type="field.type" :ref="getUuid(field, index, i - 1)" :uuid="getUuid(field, index, i - 1)" :required="isRequired(field, i)" />
          </div>
          <div class="text-right pt-2">
            <v-btn v-show="fieldCountByName[field.name] < field.max - field.min + 1" @click="addFieldInput(field.name)">
              {{ $t('Add a value') }}
              <span class="text-caption">
                ({{ fieldCountByName[field.name] + 1 }} / {{ field.max - field.min + 1 }})
              </span>
            </v-btn>
          </div>
        </v-col>

        <v-col v-else>
          <csm-task-data-input :field-data="getData(field.name)" :type="field.type" :max="field.max" :min="field.min" :ref="getUuid(field, index)" :uuid="getUuid(field, index)" :required="field.required || false" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'

import { ARRAY_FIELDS } from '@/services/scheduler'

export default {
  name: 'TaskDataInputs',
  components: {
    'csm-task-data-input': () => import(/* webpackChunkName: "components" */ './TaskDataInput')
  },
  props: {
    fields: { type: Array, required: true },
    data: { type: Object, required: true }
  },
  data () {
    return {
      fieldCountByName: {},
      uuids: {},
      uuidByField: {}
    }
  },
  watch: {
    fields: {
      immediate: true,
      handler (newVal) {
        this.reset()
        _.forEach(newVal, (field, index) => {
          if (this.isArrayFieldType(field.type)) {
            for (let i = 0; i < (field.max - field.min + 1); i++) {
              this.createUuid(field, index, i)
            }
          } else this.createUuid(field, index)
        })
      }
    }
  },
  methods: {
    addFieldInput (fieldName) {
      this.fieldCountByName[fieldName] = this.fieldCountByName[fieldName] + 1
      this.$forceUpdate()
    },
    createUuid (field, index, subIndex) {
      const id = field.name + '-' + index + (subIndex >= 0 ? '-' + subIndex : '')
      const uuid = `task-field-${index}-` + this.$stratus.uuid()
      this.uuids[uuid] = id
      this.uuidByField[id] = {
        uuid,
        field
      }
      if (!this.fieldCountByName[field.name]) this.fieldCountByName[field.name] = _.isArray(this.data[field.name]) ? this.data[field.name].length : 1
      this.$forceUpdate()
      return uuid
    },
    getData (fieldName, subIndex = -1) {
      if (!this.data[fieldName]) return
      return subIndex >= 0 ? this.data[fieldName]?.[subIndex] : this.data[fieldName]
    },
    getUuid (field, index, subIndex = -1) {
      const id = field.name + '-' + index + (subIndex >= 0 ? '-' + subIndex : '')
      return this.uuidByField[id] ? this.uuidByField[id]?.uuid : ''
    },
    isArrayFieldType (type) { return ARRAY_FIELDS.includes(type) },
    isRequired (field, index) {
      if (!this.isArrayFieldType(field.type)) return field.required
      if (field.required && field.min > 0) return index <= field.min
      return false
    },
    reset () {
      this.fieldCountByName = {}
      this.uuids = {}
      this.uuidByField = {}
    },
    serialize () {
      const data = {}
      _.forEach(this.uuidByField, (item, id) => {
        if (this.$refs[item.uuid] && this.$refs[item.uuid][0]) {
          if (this.$refs[item.uuid][0].serialize()) {
            // We have input a value
            const fieldData = this.$refs[item.uuid][0].serialize()
            if (this.isArrayFieldType(item.field.type)) {
              if (data[item.field.name] === undefined) data[item.field.name] = [fieldData]
              else data[item.field.name].push(fieldData)
            } else data[item.field.name] = fieldData
          }
        }
      })
      return data
    }
  }
}
</script>
