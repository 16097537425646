<script>
export default {
  data () { return {} },
  render: () => '',
  async mounted () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Tickets'))
    const me = await this.$store.getters['$stratus-states/me']
    if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

    const id = this.$route.params.id ? `/${this.$route.params.id}` : ''
    const cid = this.$route.params.comment_id ? `/${this.$route.params.comment_id}` : ''

    if (this.$store.getters['$stratus-states/isLord']) {
      this.$router.replace(`/ticket${id}${cid}`)
    } else {
      this.$router.replace(`/ticket-view${id}${cid}`)
    }
  }
}
</script>
