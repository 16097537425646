<template>
  <v-row v-if="Object().hasOwnProperty.call(quantityRules, 'list')" align="center" no-gutters>
    <v-col v-if="label" :class="labelTop ? 'text-caption' : ''" :cols="labelTop ? '12' : ''">
      {{ label }}
    </v-col>

    <v-col>
      <v-text-field v-if="quantityRules.list.length === 1" disabled reverse :value="$t('{count}{unit}', { count: quantityChosen, unit })" />
      <csm-dyna-product-checkbox v-else-if="isBoolean(quantityRules.list)" v-model="quantityChosen" :false-value="0" :true-value="1" :unit="unit" @change="onChangeQuantity" />
      <csm-dyna-product-select v-else :items="getQuantityList()" v-model="quantityChosen" :customer="customer" @change="onChangeQuantity" :readonly="readonly" :unit="unit" />
    </v-col>
  </v-row>
  <v-row v-else align="start" no-gutters>
    <v-col v-if="label" :class="labelTop ? 'text-caption' : ''" :cols="labelTop ? '12' : ''">
      {{ label }}
    </v-col>

    <v-col>
      <cs-decimal-input v-if="quantityRules && quantityRules.rules && quantityRules.rules.indexOf('float') >= 0" v-model="quantityChosen" @input="onChangeQuantity" :min="getQuantityLimit('min', 0)" :max="getQuantityLimit('max', 100000)" :readonly="readonly" :suffix="unit" />
      <cs-integer-input v-else v-model="quantityChosen" @input="onChangeQuantity" :min="getQuantityLimit('min', 100000)" :max="getQuantityLimit('max', 100000)" :readonly="readonly" :suffix="unit" />
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ProductQuantityChoice',
  props: {
    customer: { type: String, default: '' },
    greaterZero: { type: Boolean, default: false },
    label: { type: String, default: '' },
    labelTop: { type: Boolean, default: false },
    modifySubProduct: { type: Boolean, default: false },
    quantity: { type: Number, default: undefined },
    quantityRules: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
    stopProduct: { type: Boolean, default: false },
    takeByDefaultMax: { type: Boolean, default: false },
    unit: { type: String, default: '' }
  },
  components: {
    'csm-dyna-product-checkbox': () => import(/* webpackChunkName: "components" */ './DynaProductCheckbox'),
    'csm-dyna-product-select': () => import(/* webpackChunkName: "components" */ './DynaProductSelect')
  },
  data () {
    return {
      quantityChosen: 0
    }
  },
  watch: {
    quantity (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.quantityChosen = this.verifyQuantity(newValue)
      }
    }
  },
  methods: {
    canAlwaysModify () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_ALWAYS_MODIFY) },
    getQuantityLimit (type, def) {
      if (!this.quantityRules) {
        return def
      }
      if (Object.hasOwnProperty.call(this.quantityRules, 'list')) {
        // List
        if (type === 'min') {
          if (this.greaterZero) {
            return Math.min(...this.quantityRules.list.filter(x => x > 0))
          }
          return Math.min(...this.quantityRules.list)
        } else if (type === 'max') {
          if (this.stopProduct) {
            // stopProduct true means that we can only change quantity with less than actual
            return Math.max(...this.quantityRules.list.filter(x => x < this.quantity))
          }
          return Math.max(...this.quantityRules.list)
        } else {
          return def
        }
      } else {
        // Min-max
        let delta = 1
        if (this.quantityRules.rules && this.quantityRules.rules.indexOf('float') >= 0) {
          delta = 0.125
        }

        if (type === 'min') {
          // Rules for modifying sub-product
          if (this.modifySubProduct && this.quantityRules.rules && this.quantityRules.rules.indexOf('more') >= 0 && !this.canAlwaysModify()) {
            if (this.quantityRules.rules.indexOf('less') >= 0) {
              return this.quantityRules.min
            }
            return this.quantity
          }
          if (this.quantityRules.min > 0 || !this.greaterZero) {
            delta = 0
          }
          return this.quantityRules.min + delta
        } else {
          // Rules for modifying sub-product
          if (this.modifySubProduct && this.quantityRules.rules && this.quantityRules.rules.indexOf('less') >= 0 && !this.canAlwaysModify()) {
            if (this.quantityRules.rules.indexOf('more') >= 0) {
              return this.quantityRules.max
            }
            return this.quantity
          }
          if (this.stopProduct) {
            // stopProduct true means that we can only change quantity with less than actual
            return this.quantity - delta
          } else {
            return this.quantityRules.max
          }
        }
      }
    },
    getQuantityList () {
      if (!this.quantityRules) {
        return []
      }
      let listReturned = this.quantityRules.list
      if (this.greaterZero) {
        listReturned = listReturned.filter(x => x > 0)
      }
      if (this.stopProduct) {
        // stopProduct true means that we can only change quantity with less than actual
        listReturned = listReturned.filter(x => x < this.quantity)
      }
      // Rules for modifying sub-product
      if (this.modifySubProduct && this.quantityRules.rules && this.quantityRules.rules.length > 0) {
        if (this.quantityRules.rules.indexOf('less') >= 0) {
          listReturned = listReturned.filter(x => x <= this.quantity)
        }
        if (this.quantityRules.rules.indexOf('more') >= 0) {
          listReturned = listReturned.filter(x => x >= this.quantity)
        }
      }
      return listReturned
    },
    isBoolean (array) {
      return _.isEqual(array, [0, 1])
    },
    onChangeQuantity (payload) {
      this.quantityChosen = payload
      this.$emit('input', this.quantityChosen)
    },
    verifyQuantity (quantity) {
      if (!this.quantityRules) {
        return quantity
      }
      if (Object.hasOwnProperty.call(this.quantityRules, 'list')) {
        // List
        const list = this.getQuantityList()
        if (list.indexOf(quantity) < 0) {
          if (this.takeByDefaultMax) {
            return Math.max(...list)
          }
          return Math.min(...list)
        }
      } else {
        const min = this.getQuantityLimit('min', 0)
        const max = this.getQuantityLimit('max', 0)
        if (quantity < min || quantity > max) {
          if (this.takeByDefaultMax) {
            return max
          }
          return min
        }
      }
      return quantity
    }
  },
  created () {
    this.quantityChosen = this.verifyQuantity(this.quantity)
    if (this.quantityChosen !== this.quantity) {
      this.onChangeQuantity(this.quantityChosen)
    }
  }
}
</script>
