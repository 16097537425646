<template>
  <csm-user-form :ref="`user-form-${uuid}`" @closeDialog="closeDialog" />
</template>

<script>
import _ from 'lodash'

let previousUser = null

export default {
  name: 'UserDialog',
  components: { 'csm-user-form': () => import(/* webpackChunkName: "components" */ './UserForm') },
  data () {
    return {
      uuid: this.$stratus.uuid()
    }
  },
  methods: {
    closeDialog (user) {
      if (this.$refs[`user-form-${this.uuid}`]) this.$refs[`user-form-${this.uuid}`].display(false)
      previousUser = null
      if (this.$route?.name === 'user' && this.$route?.params?.id) {
        this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
      } else {
        this.$emit('closeDialog', user)
      }
    },
    async get (id) {
      try {
        let user
        if (_.isObject(id)) {
          user = id || {}
        } else {
          user = { ...await this.$store.dispatch('$alto-users/getOne', id) }
        }
        if (this.$refs[`user-form-${this.uuid}`]) this.$refs[`user-form-${this.uuid}`].reset(user)
      } catch (error) {
        if (error.status === 404) this.$stratus.services.notify.error(this.$t('User «{name}» not found!', { name: id }))
        else this.$stratus.services.notify.error(error)
        this.closeDialog()
      }
    },
    open (id) {
      if (!id) return
      this.get(id)
      if (this.$refs[`user-form-${this.uuid}`]) this.$refs[`user-form-${this.uuid}`].display(true)
    }
  },
  async mounted () {
    if (this.$route?.name === 'user' && this.$route.params.id) {
      if (this.$route.params.id !== previousUser) {
        previousUser = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        this.open(this.$route.params.id)
      }
    }
  }
}
</script>
