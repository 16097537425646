import config from '@/config'

function build (store) {
  const menu = {}

  menu.FOOTER = [{ href: '/about', title: 'About', icon: 'icon-version' }]

  menu.FOOTER_MINI = []

  menu.DISCONNECTED = [
    { href: '/', title: 'Home', icon: 'icon-home', big: true },
    {
      title: 'Reveal a secret',
      modal: 'reveal-secret',
      icon: 'icon-opened-email',
      big: true
    },
    {
      title: 'Preferences',
      modal: 'preferences-dialog',
      icon: 'icon-settings',
      big: true
    }
  ].concat(menu.FOOTER)

  menu.DISCONNECTED_MINI = [
    { href: '/', title: 'Home', icon: 'icon-home' },
    {
      title: 'Preferences',
      modal: 'preferences-dialog',
      icon: 'icon-settings'
    }
  ].concat(menu.FOOTER_MINI)

  menu.CONNECTED = [
    {
      href: '/activities',
      title: 'Activities',
      icon: 'icon-scalair',
      big: true
    },
    {
      title: 'Subscriptions',
      icon: 'icon-cloud',
      big: true,
      hidden: !config.features.subscriptions && !config.features.catalog,
      items: [
        {
          href: '/productchooser',
          title: 'Products Catalog',
          id: 'products_catalog',
          icon: 'icon-catalog',
          favorite: 1,
          hidden:
            !store.getters['$alto-roles/canI'](
              store.getters['$alto-roles/API_CONTEXTS'].PRODUCTS,
              store.getters['$alto-roles/API_PERMISSIONS'].LIST
            ) &&
            !store.getters['$alto-roles/canI'](
              store.getters['$alto-roles/API_CONTEXTS'].PRODUCTS,
              store.getters['$alto-roles/API_PERMISSIONS'].LIST_NO_PRICE
            )
        },
        {
          href: '/quotations',
          title: 'Quotations',
          id: 'quotations',
          icon: 'icon-cart',
          favorite: false,
          role: {
            context: store.getters['$alto-roles/API_CONTEXTS'].CARTS,
            permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
          },
          disabled: false,
          hidden: !config.features.carts
        },
        {
          href: '/orders',
          title: 'Orders',
          id: 'Orders',
          icon: 'icon-bag',
          favorite: 2,
          disabled: false,
          hidden:
            !store.getters['$alto-roles/canI'](
              store.getters['$alto-roles/API_CONTEXTS'].ORDERS,
              store.getters['$alto-roles/API_PERMISSIONS'].LIST
            ) &&
            !store.getters['$alto-roles/canI'](
              store.getters['$alto-roles/API_CONTEXTS'].ORDERS,
              store.getters['$alto-roles/API_PERMISSIONS'].LIST_NO_PRICE
            )
        },
        {
          href: '/subscriptions',
          title: 'Subscribed products',
          id: 'instances_products',
          icon: 'icon-products',
          favorite: 3,
          disabled: false,
          hidden:
            !store.getters['$alto-roles/canI'](
              store.getters['$alto-roles/API_CONTEXTS'].ORDERS_LINE,
              store.getters['$alto-roles/API_PERMISSIONS'].LIST
            ) &&
            !store.getters['$alto-roles/canI'](
              store.getters['$alto-roles/API_CONTEXTS'].ORDERS,
              store.getters['$alto-roles/API_PERMISSIONS'].LIST_NO_PRICE
            )
        }
      ]
    },
    {
      title: 'Administration',
      icon: 'icon-list',
      big: true,
      hidden:
        !config.features.companies &&
        !config.features.contracts &&
        !config.features.users &&
        !config.features.projects,
      items: [
        {
          href: '/companies',
          title: 'Companies',
          id: 'companies',
          icon: 'icon-company',
          favorite: false,
          disabled: !store.getters['$alto-roles/canI'](
            store.getters['$alto-roles/API_CONTEXTS'].COMPANIES,
            store.getters['$alto-roles/API_PERMISSIONS'].LIST
          ),
          hidden: !config.features.companies
        },
        {
          href: '/users',
          title: 'Users',
          id: 'users',
          icon: 'icon-user',
          favorite: false,
          role: {
            context: store.getters['$alto-roles/API_CONTEXTS'].USERS,
            permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
          },
          hidden: !config.features.users
        },
        {
          href: '/contracts',
          title: 'Contracts',
          id: 'contracts',
          icon: 'icon-file',
          favorite: false,
          hidden: !store.getters['$alto-roles/canI'](
            store.getters['$alto-roles/API_CONTEXTS'].CONTRACTS,
            store.getters['$alto-roles/API_PERMISSIONS'].LIST
          )
        },
        {
          href: '/spheres',
          title: 'Spheres',
          id: 'spheres',
          icon: 'icon-sphere',
          favorite: false,
          hidden:
            !store.getters['$alto-roles/canI'](
              store.getters['$alto-roles/API_CONTEXTS'].PROJECTS,
              store.getters['$alto-roles/API_PERMISSIONS'].LIST
            )
        },
        {
          href: '/documents',
          title: 'Documents',
          id: 'documents',
          icon: 'icon-folder',
          favorite: false,
          hidden: !store.getters['$alto-roles/canI'](
            store.getters['$alto-roles/API_CONTEXTS'].DOCUMENTS,
            store.getters['$alto-roles/API_PERMISSIONS'].LIST
          )
        },
        {
          href: '/scheduler',
          title: 'Scheduler',
          icon: 'icon-task-scheduler',
          hidden:
            !store.getters['$alto-roles/canI'](
              store.getters['$alto-roles/API_CONTEXTS'].SCHEDULER,
              store.getters['$alto-roles/API_PERMISSIONS'].LIST
            ) ||
            !store.getters['$alto-roles/canI'](
              store.getters['$alto-roles/API_CONTEXTS'].SCHEDULER_BY_TYPE,
              store.getters['$alto-roles/API_PERMISSIONS'].LIST
            )
        }
      ]
    }
  ]

  if (store.getters['$stratus-states/isLord']) {
    menu.CONNECTED = menu.CONNECTED.concat([
      {
        title: 'Tickets',
        icon: 'icon-ticket',
        big: true,
        items: [
          {
            href: '/kanban',
            title: 'Kanban',
            icon: '$vuetify.icons.kanban',
            favorite: 5,
            hidden: !store.getters['$stratus-states/isLord'],
            role: {
              context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
              permission: store.getters['$alto-roles/API_PERMISSIONS'].TICKET_KANBAN
            }
          },
          {
            href: '/tickets',
            title: 'Tickets',
            icon: 'icon-ticket',
            favorite: 4,
            role: {
              context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
              permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
            }
          },
          {
            href: '/tickets-launcher',
            title: 'Tickets automation',
            icon: '$vuetify.icons.ticketLauncher',
            favorite: false,
            role: {
              context: store.getters['$alto-roles/API_CONTEXTS'].TICKETS_LAUNCHERS,
              permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
            }
          },
          {
            href: '/questionnaire-list',
            title: 'Satisfaction questionnaire',
            icon: 'icon-satisfaction-survey',
            favorite: false,
            role: {
              context: store.getters['$alto-roles/API_CONTEXTS'].TICKETS_QUESTIONNAIRES,
              permission: store.getters['$alto-roles/API_PERMISSIONS'].TICKET_QUESTIONNAIRES_ALL
            }
          },
          {
            href: '/sla-dashboard',
            title: 'SLA Dashboard',
            icon: '$vuetify.icons.sla',
            favorite: false,
            hidden: !store.getters['$stratus-states/isLord'],
            role: {
              context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
              permission: store.getters['$alto-roles/API_PERMISSIONS'].TICKET_SLA_DASHBOARD
            }
          },
          {
            href: '/cds-dashboard',
            title: 'CDS Dashboard',
            icon: 'icon-dashboard-cds',
            favorite: false,
            hidden: !store.getters['$stratus-states/isLord'],
            role: {
              context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
              permission: store.getters['$alto-roles/API_PERMISSIONS'].TICKET_CDS_DASHBOARD
            }
          },
          {
            href: '/infra-dashboard',
            title: 'Infra Dashboard',
            icon: 'icon-dashboard-infra',
            favorite: false,
            hidden: !store.getters['$stratus-states/isLord'],
            role: {
              context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
              permission: store.getters['$alto-roles/API_PERMISSIONS'].TICKET_INFRA_DASHBOARD
            }
          }
        ]
      }
    ])
  } else {
    menu.CONNECTED = menu.CONNECTED.concat([
      {
        title: 'Tickets',
        icon: 'icon-ticket',
        big: true,
        href: '/tickets',
        favorite: 4,
        role: {
          context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
          permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
        }

      }
    ])
  }

  menu.CONNECTED = menu.CONNECTED.concat([
    {
      title: 'Activity follow-up',
      id: 'followup-activity',
      big: true,
      icon: 'icon-ring-gauge',
      disabled: false,
      hidden: !store.getters['$stratus-states/isLord'] ||
        (
          !store.getters['$alto-roles/canI'](
            store.getters['$alto-roles/API_CONTEXTS'].OPPORTUNITIES,
            store.getters['$alto-roles/API_PERMISSIONS'].LIST
          ) &&
          !store.getters['$alto-roles/canI'](
            store.getters['$alto-roles/API_CONTEXTS'].OPPORTUNITIES,
            store.getters['$alto-roles/API_PERMISSIONS'].OPPORTUNITIES_DASHBOARD
          ) &&
          !store.getters['$alto-roles/canI'](
            store.getters['$alto-roles/API_CONTEXTS'].OPPORTUNITIES,
            store.getters['$alto-roles/API_PERMISSIONS'].OPPORTUNITIES_DASHBOARD_CSM
          ) &&
          !store.getters['$alto-roles/canI'](
            store.getters['$alto-roles/API_CONTEXTS'].FOLLOWUP_EMAIL,
            store.getters['$alto-roles/API_PERMISSIONS'].LIST
          ) &&
          !store.getters['$alto-roles/canI'](
            store.getters['$alto-roles/API_CONTEXTS'].FOLLOWUP_EVENT,
            store.getters['$alto-roles/API_PERMISSIONS'].SALES_PERSONS_TARGET
          )
        ),
      items: [{
        href: '/opportunities',
        title: 'Opportunities',
        id: 'opportunities',
        icon: 'icon-business-opportunity',
        favorite: false,
        disabled: false,
        hidden:
            !config.features.opportunities ||
            !store.getters['$stratus-states/isLord'],
        role: {
          context: store.getters['$alto-roles/API_CONTEXTS'].OPPORTUNITIES,
          permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
        }
      },
      {
        href: '/sales-dashboard',
        title: 'Sales Dashboard',
        icon: 'icon-dashboard-sales',
        favorite: false,
        disabled: false,
        hidden: !store.getters['$stratus-states/isLord'],
        role: {
          context: store.getters['$alto-roles/API_CONTEXTS'].OPPORTUNITIES,
          permission: store.getters['$alto-roles/API_PERMISSIONS'].OPPORTUNITIES_DASHBOARD
        }
      },
      {
        href: '/csm-dashboard',
        title: 'CSM Dashboard',
        icon: 'icon-dashboard-csm',
        favorite: false,
        disabled: false,
        hidden: !store.getters['$stratus-states/isLord'],
        role: {
          context: store.getters['$alto-roles/API_CONTEXTS'].OPPORTUNITIES,
          permission: store.getters['$alto-roles/API_PERMISSIONS'].OPPORTUNITIES_DASHBOARD_CSM
        }
      },
      {
        href: '/marketing-dashboard',
        title: 'Marketing',
        icon: '$vuetify.icons.dashboardMarketing',
        hidden: !store.getters['$stratus-states/isLord'],
        role: {
          context: store.getters['$alto-roles/API_CONTEXTS'].OPPORTUNITIES_MARKETING,
          permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
        }
      },
      {
        href: '/pre-sale',
        title: 'Pre-sale',
        icon: '$vuetify.icons.dashboardPreSale',
        hidden: !store.getters['$stratus-states/isLord'],
        role: {
          context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
          permission: store.getters['$alto-roles/API_PERMISSIONS'].TICKET_KANBAN
        }
      },
      {
        href: '/followup-activity',
        title: 'Follow-up',
        icon: 'icon-message',
        favorite: false,
        disabled: false,
        hidden: !store.getters['$stratus-states/isLord'],
        role: {
          context: store.getters['$alto-roles/API_CONTEXTS'].FOLLOWUP_EMAIL,
          permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
        }
      // },
      // {
      //   href: '/salespersons-targets',
      //   title: 'Targets',
      //   icon: 'icon-target-sales',
      //   favorite: false,
      //   disabled: false,
      //   hidden: !store.getters['$stratus-states/isLord'],
      //   role: {
      //     context: store.getters['$alto-roles/API_CONTEXTS'].FOLLOWUP_EVENT,
      //     permission: store.getters['$alto-roles/API_PERMISSIONS'].SALES_PERSONS_TARGET
      //   }
      }
      ]
    },
    {
      title: 'Secrets',
      icon: 'icon-spy',
      big: true,
      items: [
        {
          modal: 'share-secret',
          title: 'Share a secret',
          icon: 'icon-secure-email'
        },
        {
          modal: 'reveal-secret',
          title: 'Reveal a secret',
          icon: 'icon-opened-email'
        }
      ]
    },
    { divider: true },
    { modal: 'bug-report', title: 'Report', icon: 'icon-bug' }
  ]).concat(menu.FOOTER)

  menu.CONNECTED_MINI = [
    { href: '/activities', title: 'Activities', icon: 'icon-scalair' },

    {
      href: '/productchooser',
      title: 'Products Catalog',
      id: 'products_catalog',
      icon: 'icon-catalog',
      favorite: 1,
      hidden:
        !store.getters['$alto-roles/canI'](
          store.getters['$alto-roles/API_CONTEXTS'].PRODUCTS,
          store.getters['$alto-roles/API_PERMISSIONS'].LIST
        ) &&
        !store.getters['$alto-roles/canI'](
          store.getters['$alto-roles/API_CONTEXTS'].PRODUCTS,
          store.getters['$alto-roles/API_PERMISSIONS'].LIST_NO_PRICE
        )
    },
    {
      href: '/quotations',
      title: 'Quotations',
      id: 'quotations',
      icon: 'icon-cart',
      favorite: false,
      role: {
        context: store.getters['$alto-roles/API_CONTEXTS'].CARTS,
        permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
      },
      disabled: false,
      hidden: !config.features.carts
    },
    {
      href: '/orders',
      title: 'Orders',
      id: 'Orders',
      icon: 'icon-bag',
      favorite: 2,
      disabled: false,
      hidden:
        !store.getters['$alto-roles/canI'](
          store.getters['$alto-roles/API_CONTEXTS'].ORDERS,
          store.getters['$alto-roles/API_PERMISSIONS'].LIST
        ) &&
        !store.getters['$alto-roles/canI'](
          store.getters['$alto-roles/API_CONTEXTS'].ORDERS,
          store.getters['$alto-roles/API_PERMISSIONS'].LIST_NO_PRICE
        )
    },
    {
      href: '/subscriptions',
      title: 'Subscribed products',
      id: 'instances_products',
      icon: 'icon-products',
      favorite: 3,
      disabled: false,
      hidden:
        !store.getters['$alto-roles/canI'](
          store.getters['$alto-roles/API_CONTEXTS'].ORDERS_LINE,
          store.getters['$alto-roles/API_PERMISSIONS'].LIST
        ) &&
        !store.getters['$alto-roles/canI'](
          store.getters['$alto-roles/API_CONTEXTS'].ORDERS,
          store.getters['$alto-roles/API_PERMISSIONS'].LIST_NO_PRICE
        )
    },

    { divider: true },

    {
      href: '/companies',
      title: 'Companies',
      id: 'companies',
      icon: 'icon-company',
      favorite: false,
      disabled: !store.getters['$alto-roles/canI'](
        store.getters['$alto-roles/API_CONTEXTS'].COMPANIES,
        store.getters['$alto-roles/API_PERMISSIONS'].LIST
      ),
      hidden: !config.features.companies
    },
    {
      href: '/users',
      title: 'Users',
      id: 'users',
      icon: 'icon-user',
      favorite: false,
      role: {
        context: store.getters['$alto-roles/API_CONTEXTS'].USERS,
        permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
      },
      hidden: !config.features.users
    },
    {
      href: '/contracts',
      title: 'Contracts',
      id: 'contracts',
      icon: 'icon-file',
      favorite: false,
      hidden: !store.getters['$alto-roles/canI'](
        store.getters['$alto-roles/API_CONTEXTS'].CONTRACTS,
        store.getters['$alto-roles/API_PERMISSIONS'].LIST
      )
    },
    {
      href: '/spheres',
      title: 'Spheres',
      id: 'spheres',
      icon: 'icon-sphere',
      favorite: false,
      hidden: !config.features.projects
    },
    {
      href: '/documents',
      title: 'Documents',
      id: 'documents',
      icon: 'icon-folder',
      favorite: false,
      hidden: !store.getters['$alto-roles/canI'](
        store.getters['$alto-roles/API_CONTEXTS'].DOCUMENTS,
        store.getters['$alto-roles/API_PERMISSIONS'].LIST
      )
    },
    {
      href: '/scheduler',
      title: 'Scheduler',
      icon: 'icon-task-scheduler',
      hidden:
        !store.getters['$alto-roles/canI'](
          store.getters['$alto-roles/API_CONTEXTS'].SCHEDULER,
          store.getters['$alto-roles/API_PERMISSIONS'].LIST
        ) ||
        !store.getters['$alto-roles/canI'](
          store.getters['$alto-roles/API_CONTEXTS'].SCHEDULER_BY_TYPE,
          store.getters['$alto-roles/API_PERMISSIONS'].LIST
        )
    },

    { divider: true },

    {
      href: '/kanban',
      title: 'Kanban',
      icon: '$vuetify.icons.kanban',
      favorite: 5,
      hidden: !store.getters['$stratus-states/isLord'],
      role: {
        context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
        permission: store.getters['$alto-roles/API_PERMISSIONS'].TICKET_KANBAN
      }
    },
    {
      href: '/tickets',
      title: 'Tickets',
      icon: 'icon-ticket',
      favorite: 4,
      role: {
        context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
        permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
      }
    },
    {
      href: '/sla-dashboard',
      title: 'SLA Dashboard',
      icon: '$vuetify.icons.sla',
      favorite: false,
      hidden: !store.getters['$stratus-states/isLord'],
      role: {
        context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
        permission: store.getters['$alto-roles/API_PERMISSIONS'].TICKET_SLA_DASHBOARD
      }
    },
    {
      href: '/cds-dashboard',
      title: 'CDS Dashboard',
      icon: 'icon-dashboard-cds',
      favorite: false,
      hidden: !store.getters['$stratus-states/isLord'],
      role: {
        context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
        permission: store.getters['$alto-roles/API_PERMISSIONS'].TICKET_CDS_DASHBOARD
      }
    },
    {
      href: '/infra-dashboard',
      title: 'Infra Dashboard',
      icon: 'icon-dashboard-infra',
      favorite: false,
      hidden: !store.getters['$stratus-states/isLord'],
      role: {
        context: store.getters['$alto-roles/API_CONTEXTS'].TICKETING,
        permission: store.getters['$alto-roles/API_PERMISSIONS'].TICKET_INFRA_DASHBOARD
      }
    },
    {
      href: '/opportunities',
      title: 'Opportunities',
      id: 'opportunities',
      icon: 'icon-business-opportunity',
      favorite: false,
      hidden:
        !config.features.opportunities ||
        !store.getters['$stratus-states/isLord'],
      role: {
        context: store.getters['$alto-roles/API_CONTEXTS'].OPPORTUNITIES,
        permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
      }
    },
    {
      href: '/sales-dashboard',
      title: 'Sales Dashboard',
      icon: 'icon-dashboard-sales',
      favorite: false,
      hidden: !store.getters['$stratus-states/isLord'],
      role: {
        context: store.getters['$alto-roles/API_CONTEXTS'].OPPORTUNITIES,
        permission: store.getters['$alto-roles/API_PERMISSIONS'].OPPORTUNITIES_DASHBOARD
      }
    },
    {
      href: '/followup-activity',
      title: 'Follow-up',
      icon: 'icon-message',
      disabled: false,
      hidden: !store.getters['$stratus-states/isLord'],
      role: {
        context: store.getters['$alto-roles/API_CONTEXTS'].FOLLOWUP_EMAIL,
        permission: store.getters['$alto-roles/API_PERMISSIONS'].LIST
      }
    // },
    // {
    //   href: '/salespersons-targets',
    //   title: 'Targets',
    //   icon: 'icon-target-sales',
    //   favorite: false,
    //   disabled: false,
    //   hidden: !store.getters['$stratus-states/isLord'],
    //   role: {
    //     context: store.getters['$alto-roles/API_CONTEXTS'].FOLLOWUP_EVENT,
    //     permission: store.getters['$alto-roles/API_PERMISSIONS'].SALES_PERSONS_TARGET
    //   }
    }
  ].concat(menu.FOOTER_MINI)

  if (process.env.NODE_ENV !== 'production') console.log('App menu built.')

  return menu
}

export default { build }
