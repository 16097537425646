/* eslint-disable quote-props */
export const DEFAULT_PRICES = {
  'O': 0.0,
  'H': 0.0,
  'D': 0.0,
  'W': 0.0,
  'M': 0.0,
  'Q': 0.0,
  'Y': 0.0,
  'B': 0.0,
  'T': 0.0
}
