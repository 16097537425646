<template>
  <sca-reveal-secret-form :id="id" />
</template>

<script>
export default {
  name: 'RevealSecretDialog',
  data () {
    return {
      id: ''
    }
  },
  mounted () {
    // Is there an identifier for a secret to reveal?
    // Example: https://manager-int-cloudsystem.aws.scalair.fr/#/secrets/53e90b61ce1d6e98f2d7b516dbc4ece3ad824e283eee9a7f
    this.id = this.$route.params ? (this.$route.params.secretid || '') : null
  }
}
</script>
