const actions = {
  clone ({ commit, rootState }, userId) {
    return rootState.$stratus.services.api.get(`/users/${userId}/clone`)
      .then((response) => { return response })
      .catch((error) => commit('API_FAILURE', error))
  },
  list ({ commit, rootState, state }, data) {
    return rootState.$stratus.services.api.get('/users?' + rootState.$stratus.services.api.encodeQueryString(data))
      .then((response) => {
        state.list = rootState.$stratus.services.sort.natural(response.results, { firstname: 'asc', lastname: 'desc' })
        return response
      })
      .catch((error) => commit('API_FAILURE', error))
  },
  get ({ commit, rootState, state }, userId) {
    return rootState.$stratus.services.api.get(`/users/${userId}`)
      .then((response) => {
        return response
      })
      .catch((error) => commit('API_FAILURE', error))
  },
  getForCompany ({ commit, rootState, state }, company) {
    return rootState.$stratus.services.api.get(`/users?limit=0&fields=code,firstname,lastname,email,phone,role,avatar&query=company[eq]=${company}`)
      .then((response) => {
        return rootState.$stratus.services.sort.natural(response.results, { lastname: 'asc', firstname: 'asc' })
      })
      .catch((error) => commit('API_FAILURE', error))
  },
  getNewsLettersForRole ({ commit, state, rootState }, role) {
    if (!role || state.newsLettersByRole[role]) return
    return rootState.$stratus.services.api.get('/newsletters/default_roles_subscriber/' + role)
      .then(response => {
        commit('setNewsLettersByRole', { rootState, role, data: response.results })
        return response
      })
      .catch((error) => commit('API_FAILURE', error))
  }
}

const mutations = {
  API_FAILURE: (state, error) => {
    if (error.status === 401) {
      console.warn('[API] status 401', error)
    } else throw error
  },
  setNewsLettersByRole (state, { role, data }) {
    if (role && data) {
      state.newsLettersByRole[role] = data
    }
  }
}

export default {
  namespaced: true,
  state: {
    list: [],
    newsLettersByRole: {}
  },
  getters: {
    list: state => { return state.list || [] },
    get: state => id => {
      const _users = state.list || []
      let found
      let i = 0
      while (!found && i < _users.length) {
        if (_users[i].id === id) {
          found = _users[i]
        }
        i++
      }
      return found
    },
    getNewsletters: state => role => {
      if (!role) return state.newsLettersByRole
      return state.newsLettersByRole[role]
    }
  },
  actions,
  mutations
}
