<template>
  <div>
    <sca-modal-dialog v-show="!showUpdating" :visible="visible" @closeDialog="saveDialog" :is-saving="isSaving" :action="action" :max-width="maxWidth" :external-id="externalId" :save-button-color="''">
      <div slot="title" class="d-flex align-center">
        {{ cart.name }}
      </div>

      <div slot="subtitle" class="d-flex">
        <div v-if="!cart.expired" class="highlighted-panel">
          {{ $t('Expires on {date}', { date: $stratus.dt(cart.expiration_date).format('LL') }) }}
        </div>
        <cs-alert-panel v-else type="error" :text="$t('This quotation expires on {date}.', { date: $stratus.dt(expirationDateOrigin).format('LLL') })" />
      </div>

      <template #buttons-header>
        <cs-action-button icon="$vuetify.icons.delete" help="Delete" color="danger" @click="deleteCart" />

        <div class="action-button-separator" />

        <csm-cart-invoice-download v-show="cart.id" action-button :cart="cart" :name="cart.name" format="pdf" />
        <csm-cart-invoice-download v-show="cart.id" action-button :cart="cart" :name="cart.name" format="csv" />
        <div class="action-button-separator" />

        <cs-action-button v-if="canSave" icon="$vuetify.icons.clone" help="Clone" @click="clone" />
      </template>

      <div slot="content-fixed">
        <v-row v-if="companyForCart.blocked_payment" dense justify="center">
          <v-col class="shrink text-no-wrap">
            <cs-alert-panel type="info" :text="$t('Please contact your Sales manager at Scalair.')" />
          </v-col>
        </v-row>

        <v-row v-if="!canCompanyOrder()" slot="content-fixed" justify="center" class="mt-4" dense>
          <v-col class="shrink text-no-wrap text-center">
            <div class="d-flex align-center">
              <cs-alert-panel type="warning" :text="isLord ? $t('Impossible to place an order for a non-customer company.') : $t('Unable to order for this company, please contact Scalair.')" />
              <v-btn v-show="isLord && companyForCart.code" small class="main-button" rounded @click="companyIsVisible = true">
                {{ $t('Modify company {name} — {code}', companyForCart) }}
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="isLord && isForeignCompany" justify="center" dense>
          <v-col class="shrink text-no-wrap">
            <cs-alert-panel type="warning" :text="$t('This is a foreign customer. Contact the accounting department for invoicing.')" />
          </v-col>
        </v-row>

        <v-row v-if="hasDeposit" dense>
          <v-col cols="12" class="my-4">
            <v-row no-gutters justify="center">
              <v-col class="shrink text-no-wrap">
                <cs-alert-panel type="warning" :text="$t('A deposit will be required before delivering your order.')" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div slot="content">
        <csm-cart-header ref="current-cart-header" :show-updating="showUpdating" :pause-save-cart="pauseSaveCart" @refresh="refresh" @saveCart="saveCart" />

        <v-row v-if="needEstimatedDate()" no-gutters class="mb-4">
          <v-col class="shrink text-no-wrap">
            <cs-alert-panel type="warning">
              <div slot="content" class="d-flex align-center justify-center">
                <v-icon left color="color0">
                  $vuetify.icons.warning
                </v-icon>

                <div class="pl-1 pr-8">
                  <div>
                    {{ $t('The quotation cannot be validated because certain information is missing concerning:') }}
                  </div>
                  <div v-if="needDetailInput()" class="font-weight-bold">
                    - {{ $t('Order details') }}
                  </div>
                  <div v-if="needDelayDays()" class="font-weight-bold">
                    - {{ $t('Information on the delivery date of the order') }}
                  </div>
                </div>

                <sca-sales-manager-message page="quotation" outlined small button-close class="color0" />
              </div>
            </cs-alert-panel>
          </v-col>
        </v-row>

        <v-row v-if="countProductReachEndOfLife > 0" justify="center" no-gutters>
          <v-col class="shrink text-no-wrap">
            <cs-alert-panel type="warning" :text="$tc('This cart contains no product that reach end of life.|This cart contains one product that reach end of life.|This cart contains {count} products that reach end of life.', countProductReachEndOfLife, { count: countProductReachEndOfLife }) + ' — ' + $t('Please contact your sales manager at Scalair.')" />
          </v-col>
        </v-row>

        <v-tabs hide-slider v-model="quotationTabs" class="transparent">
          <cs-validation-tab href="#tab-product-list" :label="$t('Products')" icon="$vuetify.icons.product" />
          <cs-validation-tab href="#tab-details" :label="cart.description?.length > 0 ? $t('Detail') + ` (${$t('...')})` : $t('Detail')" icon="$vuetify.icons.details" />
          <cs-validation-tab href="#tab-contacts" :label="$t('Contacts')" icon="$vuetify.icons.contact" />
        </v-tabs>

        <v-row no-gutters justify="center">
          <v-col cols="12">
            <v-tabs-items v-model="quotationTabs">
              <v-tab-item value="tab-product-list" eager>
                <v-form ref="cart-details-form" v-model="validCart" lazy-validation>
                  <csm-cart ref="current-cart" :show-updating="showUpdating" :pause-save-cart="pauseSaveCart" @refresh="refresh" @saveCart="saveCart" />
                </v-form>
              </v-tab-item>

              <v-tab-item value="tab-details" eager>
                <cs-wysiwyg-textarea v-model="cart.description" :placeholder="$t('Details')" :title="$t('Add some important informations about your quotation...')" :actions="['bold', 'italic', 'underline', 'olist', 'ulist']" dense :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" @input="refresh" />

                <v-divider />

                <v-sheet outlined>
                  <div v-if="canAddFile">
                    <csm-cart-files :cart="cart" @change="onChangeCartFile" />
                  </div>

                  <cs-attachments-list v-if="cart && cart.id" :id="cart.id" :value="cart.files" can-download :can-delete="canAddFile" @delete="deleteAttachments" />
                </v-sheet>
              </v-tab-item>

              <v-tab-item value="tab-contacts" eager>
                <v-row dense class="py-8">
                  <v-col cols="12" class="my-4">
                    <v-autocomplete v-if="!companyForCart.blocked_payment" :items="emailsCache" v-model="cart.users_alerts" chips deletable-chips multiple :label="$t('List of people to be notified by email about the progress of the order')" prepend-icon="icon-email" hide-details dense />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>

      <template #buttons>
        <v-btn v-if="canOrder && !companyForCart.blocked_payment" rounded @click.native="saveOrder" class="main-button" :loading="isSendingOrder" :disabled="isSaving || needEstimatedDate()">
          {{ $t('Send order') }}
        </v-btn>
      </template>

      <sca-footer-create-update-at-by v-if="isLord" v-model="cart" slot="footer" :link-user="isLord" />
    </sca-modal-dialog>

    <cs-confirm-dialog ref="confirm-cart-to-order" />
    <cs-confirm-dialog ref="confirm-cart-deletion" />

    <cs-alert-dialog ref="order-validate-alert" />

    <csm-order-dialog ref="order-dialog" />
    <csm-product-dialog ref="product-dialog" readonly />

    <cs-generic-dialog ref="alerts-ordering-items-dialog">
      <div slot="content">
        <div v-for="(product, index) in alertsOrderingItems" :key="product.id">
          <v-divider v-if="index > 0" class="my-4" />

          <div class="d-flex align-center">
            <div class="font-weight-bold">
              {{ product.name }}
            </div>

            <div class="ml-4">
              <sca-product-identity :value="product.ref" :label="product.ref" :company="companyCode" show-avatar link="emit" @link-click="openProduct(product.ref)" />
            </div>
          </div>

          <div class="ml-6" v-html="product.warning_message_ordering" />
        </div>
      </div>
    </cs-generic-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import CartHeader from './CartHeader'
import Cart from './Cart'

export default {
  name: 'QuotationForm',
  components: {
    'csm-cart': Cart,
    'csm-cart-header': CartHeader,
    'csm-cart-files': () => import(/* webpackChunkName: "components" */ './CartFiles'),
    'csm-cart-invoice-download': () => import(/* webpackChunkName: "components" */ './CartInvoiceDownload')
  },
  props: {
    update: { type: Boolean, default: false },
    show: { type: Boolean, default: true },
    showUpdating: { type: Boolean, default: true }
  },
  data () {
    return {
      alertsOrderingItems: [],
      companyIsVisible: false,
      companyShow: false,
      companyUpdate: true,
      DESCRIPTION_MAX_LENGTH: 4096 * 4,
      DESCRIPTION_MIN_LENGTH: 0,
      emailsCache: [],
      isCloning: false,
      isForeignCompany: false,
      isLoadingOrder: false,
      isSendingOrder: false,
      isLogged: this.$stratus.services.auth.isLogged(),
      isSaving: false,
      me: {},
      diff: null,
      originalCart: { items: [] },
      prevCompany: '',
      companyForCart: {
        mail_accounting: [],
        nic: '',
        siren: '',
        type: ''
      },
      pauseSaveCart: false,
      quotationTabs: null,
      showDownloadMenu: false,
      stepComplete: {
        cart: false,
        order: false
      },
      valid: false,
      validCart: false,
      visible: false,
      VATRule: v => {
        if (v && v !== '') {
          return (this.$alto.services.vat.validate({ vatNumber: v }) || this.$t('VAT number must be valid!'))
        }
        return true
      },
      BicRule: v => {
        // Test BIC if IBAN is not filled-in
        if (this.companyForCart.payment_method === this.$alto.defines.COMPANIES.DIRECT_DEBIT_PAYMENT_METHOD && (!this.companyForCart.iban || !this.companyForCart.iban.length === 0)) {
          return (v && v !== '') || this.$t('BIC or IBAN is mandatory!')
        }
        return true
      },
      IbanRule: v => {
        // Test IBAN if BIC is not filled-in
        if (this.companyForCart.payment_method === this.$alto.defines.COMPANIES.DIRECT_DEBIT_PAYMENT_METHOD && (!this.companyForCart.bic || this.companyForCart.bic.length === 0)) {
          return (v && v !== '') || this.$t('BIC or IBAN is mandatory!')
        }
        return true
      }
    }
  },
  computed: {
    ...mapGetters({
      cart: 'carts/current',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    action () {
      if (this.update) return this.$t('Update')
      return this.show ? this.$t('Details') : this.$t('Create')
    },
    canAddFile () {
      return this.isLord && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.CART_UPDATE_EXPIRED)
    },
    canDelete () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.DELETE)
    },
    canDelFile () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.DELETE_FILE)
    },
    canOrder () {
      return this.canSave &&
        this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CREATE) &&
        this.canCompanyOrder() &&
        this.countProductReachEndOfLife === 0
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.UPDATE) &&
       (
         this.isLord ||
         !this.cart.expired ||
          (
            this.cart.expired &&
            this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.CART_UPDATE_EXPIRED)
          )
       )
    },
    companyCode () {
      return this.cart.customer || this.me.company
    },
    countProductReachEndOfLife () {
      let count = 0
      _.forEach(this.cart?.items, item => {
        const p = this.getProductByRef(item.ref)
        // Ensure simple product do not have attribut « main » in quotation
        count += Number(p?.end_subscription || false)
      })
      return count
    },
    hasDeposit () {
      return (this.cart.per_partial_billing > 0 && this.cart.total_deposit && this.cart.total_deposit_incl_vat)
    },
    maxWidth () {
      if (this.$vuetify.breakpoint.xl) return '75%'
      if (this.$vuetify.breakpoint.lgAndUp) return '90%'
      return '90%'
    }
  },
  watch: {
    'cart.customer': {
      immediate: true,
      async handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          try {
            this.isForeignCompany = await this.$store.dispatch('$alto-companies/isForeign', this.companyCode)
            this.getEmails()
          } catch (error) {
            console.error(error)
            this.$stratus.services.notify.error(error)
          }
        }
      }
    },
    companyCode: {
      immediate: true,
      async handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          try {
            const company = await this.$store.dispatch('$alto-companies/getById', this.companyCode)
            this.companyForCart = { ...company }
            this.getEmails()
          } catch (error) {
            console.error(error)
            this.$stratus.services.notify.error(error)
          }
        }
      }
    },
    visible (newVal, oldVal) {
      if (newVal !== oldVal) this.quotationTabs = 'tab-product-list'
    }
  },
  methods: {
    beautifyTotalCart (cart) {
      const cartTotal = cart.total
      const cartToSend = cart
      delete cartToSend.total
      const result = Object.assign({}, cartToSend, cartTotal, {
        customer: (cart.customer) ? cart.customer : this.$store.getters['$stratus-states/me'].company
      })
      this.sanitize(result)
      return result
    },
    async canCompanyOrder () {
      return this.companyForCart && this.companyForCart.type && this.$alto.defines.COMPANIES.TYPE_THAT_CAN_ORDER.includes(this.companyForCart.type)
    },
    clone () {
      this.isCloning = true
      this.$nextTick(() => {
        delete this.cart.id
        delete this.cart.expired
        delete this.cart.opportunity_id
        this.$set(this.cart, 'files', []) // Documents cannot be cloned
        this.$set(this.cart, 'name', this.cart.name + ' ' + this.$t('(copy)'))
        this.$store.dispatch('carts/setCurrent', this.cart)
          .then(() => {
            this.$stratus.services.notify.success(this.$t('Cart cloned.'))
          })
          .finally(() => {
            this.isCloning = false
          })
      })
    },
    async closeCompanyDialog () {
      try {
        this.companyForCart = await this.$store.dispatch('$alto-companies/getByCode', this.companyCode)
        this.isForeignCompany = await this.$store.dispatch('$alto-companies/isForeign', this.companyCode)
      } catch (error) {
        console.error(error)
      }
      this.companyIsVisible = false
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    computeDiscount (total, discount) {
      return parseFloat(total) - (parseFloat(total) * (parseFloat(discount) * 0.01))
    },
    async deleteAttachments ({ id, attachment }) {
      if (!id || !attachment) return

      try {
        const newCart = await this.$store.dispatch('carts/delAttachment', { id, file: attachment })
        await this.$store.dispatch('carts/setCurrent', newCart)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    deleteCart () {
      this.$refs['confirm-cart-deletion']
        .open(this.$t('Delete quotation'), this.$t('Confirm deletion of quotation «{name}»?', { name: this.cart.name }), { color: 'red' })
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('carts/delete', this.cart.id)
              .then(() => {
                this.$stratus.services.notify.success(this.$t('Quotation «{name}» deleted!', { name: this.cart.name }))
                // Cart is deleted. There's no more current one.
                if (this.$root['global-cart-menu']) {
                  this.$root['global-cart-menu'].clearCart()
                  this.$root['global-cart-menu'].refreshList()
                }
              })
              .then(() => {
                this.closeDialog()
              })
              .catch(error => this.$stratus.services.notify.error(error))
          }
        })
    },
    externalId () {
      if (!this.cart?.id) return
      return [this.cart.name, this.cart.id ? 'quotations/' + this.cart.id : '']
    },
    async getEmails () {
      let list = []
      try {
        const companies = [this.companyCode, this.me.company]
        if (this.me?.customers) companies.concat(this.me?.customers)

        list = await this.$store.dispatch('$alto-quotations/getUserEmails', _.uniq(companies))
      } catch (error) {
        console.error(error)
      }
      const emails = []
      _.forEach(list, user => {
        emails.push({
          text: user.lastname + ' ' + user.firstname + ' (' + user.email + ')',
          value: user.email
        })
      })

      this.emailsCache = this.$stratus.services.sort.natural(emails, { text: 'asc' })
    },
    async saveOrder () {
      if (!this.canOrder) return

      if (this.cart.description && this.cart.description.length > this.DESCRIPTION_MAX_LENGTH) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      // First we remove double refs and we add `showAlertMessage` property
      const itemsUniq = [...new Map(this.cart?.items.map((item) => [item.ref, { ...item, showAlertMessage: false }])).values()]
      // Then we filter only those who have a `warning_message_ordering`
      this.alertsOrderingItems = itemsUniq.filter(x => x.warning_message_ordering)
      if (this.alertsOrderingItems.length > 0) {
        const { confirmed } = await this.$refs['alerts-ordering-items-dialog']
          .open(this.$tc('Before ordering, one product needs your attention.|Before ordering, {count} products need your attention.', this.alertsOrderingItems.length, { count: this.alertsOrderingItems.length }), null, { color: 'accent', width: '60%' })
        if (!confirmed) {
          // User cancel dialog
          return
        }
      }

      this.stepComplete.order = true
      this.isSaving = true
      this.isSendingOrder = true
      let id = 0
      const myCart = this.beautifyTotalCart(this.cart)

      try {
        const { cart } = await this.$store.dispatch('carts/save', { cart: myCart })
        const res = await this.$store.dispatch('carts/sendOrder', (cart.id || cart.body.id))
        id = res.id
        this.closeDialog()
        await this.$store.dispatch('carts/setCurrent', {}) // No more current cart
        await this.$store.dispatch('orders/list') // Refresh orders
        // Cart is completed. There's no more current one.
        if (this.$root['global-cart-menu']) {
          this.$root['global-cart-menu'].clearCart()
          this.$root['global-cart-menu'].refreshList()
        }

        if (this.$refs['order-dialog']) this.$refs['order-dialog'].open(id) // Open order dialog
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.isSendingOrder = false; this.isSaving = true }, 1000)
      }
    },
    getProductByRef (ref) { return this.$store.getters['$alto-catalog/get'](ref) },
    hide () { this.visible = false },
    async onChangeCartFile (files) {
      const currentCart = this.$store.getters['carts/current']
      try {
        await this.$store.dispatch('carts/setCurrent', { ...currentCart, files }) // Modify files only
      } catch (error) {
        console.error(error)
      }
    },
    needDelayDays () {
      return !this.cart.nb_days_begin_delay || !this.cart.nb_days_building_time
    },
    needDetailInput () {
      return this.$stratus.services.strings.stripHtmlTags(this.cart.description, { ignoreTags: [] })?.length < 40
    },
    needEstimatedDate () {
      return this.cart.has_service_in_items && (this.needDelayDays() || this.needDetailInput())
    },
    onChangeMails (value) {
      this.companyForCart.mail_accounting = value
    },
    openProduct (id) {
      if (this.$refs['product-dialog']) this.$refs['product-dialog'].openId(id)
    },
    refresh () {
      this.$forceUpdate()
    },
    resetControls () {
      this.currentStep = 1
      this.isCloning = false
      this.isLoadingOrder = false
      this.isSendingOrder = false
      this.isSaving = false
      this.showDownloadMenu = false
      if (this.$refs['current-cart']) this.$refs['current-cart'].resetControls()
    },
    sanitize (cart) {
      _.forEach(cart?.items, item => {
        const p = this.getProductByRef(item.ref)
        // Ensure simple product do not have attribut « main » in quotation
        if (!p) {
          console.error('[sanitize] cart product not found', item.ref, item.name)
        } else if (!p.main || p.main.length === 0) {
          item.main = []
        }
      })
    },
    async save () {
      if (!this.canSave) {
        return this.cart?.id
      }

      this.pauseSaveCart = true

      let myCart
      try {
        myCart = this.beautifyTotalCart(this.cart)
      } catch (error) {
        console.error(error)
      }

      try {
        // Do not catch error here, let it bubble!
        const cart = await this.$store.dispatch('carts/save', { cart: myCart })
        myCart = _.cloneDeep(cart.cart)
        await this.$store.dispatch('carts/setCurrent', myCart)
        await this.$store.dispatch('carts/list')
        if (this.$refs['current-cart']) {
          this.$refs['current-cart'].computeTotal()
        }
        return myCart.id
      } finally {
        this.pauseSaveCart = false
      }
    },
    async saveDialog () {
      if (!this.canSave) {
        this.closeDialog()
        return
      }

      this.isSaving = true

      if (this.cart.description && this.cart.description.length > this.DESCRIPTION_MAX_LENGTH) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      if (!this.$refs['cart-details-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      try {
        if (!_.isEqual(this.originalCart, this.cart) && this.canSave) {
          await this.save()
          this.$stratus.services.notify.success(this.$t('Quotation «{name}» saved.', { name: this.cart.name }))
        }
        this.closeDialog()
      } catch (error) {
        if (error.status === 422 && error.body && error.body.code === 'QUO-030' && error.body.error && error.body.error.stack) {
          const ErrorMsg = error.body.error.stack.split('\n')
          if (ErrorMsg.length > 0) {
            this.$stratus.services.notify.error(ErrorMsg[0])
          } else {
            this.$stratus.services.notify.error(error)
          }
        } else {
          this.$stratus.services.notify.error(error)
        }
      }
      this.$nextTick(() => { this.isSaving = false })
    },
    resetOriginalCart () {
      this.originalCart = { ...this.cart }
      if (this.$refs['current-cart']) {
        this.$refs['current-cart'].reload()
      }
    },
    async saveCart () {
      try {
        if (!this.pauseSaveCart) await this.save()
      } catch (error) {
        if (error.status === 422 && error.body && error.body.code === 'QUO-030' && error.body.error && error.body.error.stack) {
          const ErrorMsg = error.body.error.stack.split('\n')
          if (ErrorMsg.length > 0) {
            this.$stratus.services.notify.error(ErrorMsg[0])
          } else {
            this.$stratus.services.notify.error(error)
          }
        } // Do not handle other errors
      }
    },
    async display (show = false) {
      this.visible = show
      if (show) {
        // Load some data in cache
        await this.$store.dispatch('$alto-catalog/list')
        await this.$store.dispatch('orders/list')
      }
    }
  },
  async created () {
    this.saveCart = _.debounce(this.saveCart, 1000)
  },
  async mounted () {
    this.me = this.$store.getters['$stratus-states/me']
  }
}
</script>
