<template>
  <csm-ticket-questionnaire-form :visible="visible" :value="questionnaires[currentQuestionnaire]" @closeDialog="closeDialog" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TicketQuestionnaire',
  components: { 'csm-ticket-questionnaire-form': () => import(/* webpackChunkName: "components" */ './TicketQuestionnaireForm') },
  data () {
    return {
      visible: false,
      currentQuestionnaire: 0, // Display first one
      questionnaires: []
    }
  },
  mounted () {
    if (!this.$stratus.services.auth.isLogged()) return
    setTimeout(() => this.load(), 5000)
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me'
    })
  },
  methods: {
    closeDialog () {
      this.visible = false
    },
    async load () {
      try {
        if (!this.me?.id) {
          setTimeout(() => this.load(), 10000)
          return
        }
        const questionnaires = await this.$store.dispatch('$alto-ticketing/getQuestionnaires', { notAnswered: true, ownerId: this.me?.id })
        this.questionnaires = questionnaires || []
        this.visible = questionnaires?.length > 0
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
