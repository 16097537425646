<template>
  <div>
    <v-row align="center" dense class="mb-4">
      <v-col cols="4" md="2" lg="1">
        <cs-date-picker v-model="beginAt" format-short hide-details :disabled="loading" :label="$t('From date')" @change="refresh()" />
      </v-col>

      <v-col cols="4" md="2" lg="1">
        <cs-date-picker v-model="endAt" format-short hide-details :disabled="loading" :label="$t('To date')" @change="refresh()" />
      </v-col>

      <v-col class="shrink text-no-wrap">
        <v-switch v-model="includeWeekend" :label="$t('Include weekends')" :disabled="loading" hide-details dense class="pa-0 ma-0" @change="refresh()" />

        <v-switch v-model="includeUserWithNoTarget" :label="$t('Include users with no target')" :disabled="loading" hide-details dense class="pa-0 ma-0" @change="refresh()" />
      </v-col>

      <v-col v-if="RANGES.length">
        <v-btn v-for="(range, index) in RANGES" :key="index" rounded small class="shortcut-button mr-2" :disabled="disabled || loading" @click="setDateRange(range)">
          <v-icon small left color="menu-icon">
            icon-clock
          </v-icon>
          {{ $t(range.name) }}
        </v-btn>
      </v-col>

      <v-col class="shrink">
        <cs-refresh-button :loading="loading" @click="refresh()" />
      </v-col>
    </v-row>

    <v-row v-for="sp in salesPersonsSorted" :key="sp.value">
      <v-col v-if="sp?.value" cols="12">
        <v-card outlined class="pa-2 background-blurred">
          <cs-expand-panel block expanded>
            <v-row slot="header" align="center" dense class="px-4 py-1">
              <v-col v-if="loading" class="shrink">
                <cs-icon-loading />
              </v-col>
              <v-col>
                <sca-user-identity :value="sp.value" show-card show-avatar show-company show-email show-phone show-role large link="emit" @link-click="openUser(sp.value)" />
              </v-col>

              <v-col v-if="callByWeekProgressPercent(sp.value) !== null" class="shrink text-caption text-center mx-2">
                <div>{{ $t('Calls') }}</div>
                <v-tooltip v-if="callByWeekProgressPercent(sp.value) < 100" top>
                  <template v-slot:activator="{ on }">
                    <v-progress-circular v-on="on" :value="callByWeekProgressPercent(sp.value)" color="primary" />
                  </template>
                  {{ $stratus.services.format.asPercent(callByWeekProgressPercent(sp.value)) }}
                </v-tooltip>
                <v-icon v-else x-large color="success">
                  $vuetify.icons.success
                </v-icon>
              </v-col>

              <v-col v-if="eventWithProspectProgressPercent(sp.value) !== null" class="shrink text-no-wrap text-caption text-center mx-2">
                <div>{{ $t('Events with prospects') }}</div>
                <v-tooltip v-if="eventWithProspectProgressPercent(sp.value) < 100" top>
                  <template v-slot:activator="{ on }">
                    <v-progress-circular v-on="on" :value="eventWithProspectProgressPercent(sp.value)" color="primary" />
                  </template>
                  {{ $stratus.services.format.asPercent(eventWithProspectProgressPercent(sp.value)) }}
                </v-tooltip>
                <v-icon v-else x-large color="success">
                  $vuetify.icons.success
                </v-icon>
              </v-col>

              <v-col v-if="businessMealByMonthProgressPercent(sp.value) !== null" class="shrink text-no-wrap text-caption text-center mx-2">
                <div>{{ $t('Business meals') }}</div>
                <v-tooltip v-if="businessMealByMonthProgressPercent(sp.value) < 100" top>
                  <template v-slot:activator="{ on }">
                    <v-progress-circular v-on="on" :value="businessMealByMonthProgressPercent(sp.value)" color="primary" />
                  </template>
                  {{ $stratus.services.format.asPercent(businessMealByMonthProgressPercent(sp.value)) }}
                </v-tooltip>
                <v-icon v-else x-large color="success">
                  $vuetify.icons.success
                </v-icon>
              </v-col>
            </v-row>

            <div slot="content">
              <v-row no-gutters>
                <v-col v-if="canFollowUpTargetCall" class="pa-4" cols="12">
                  <v-card outlined class="pa-2 background-blurred">
                    <csm-target-call-by-week :ref="`call-target-panel-${sp.value}`" :user-id="sp.value" :begin-at="beginAt" :end-at="endAt" :color-no-target="COLOR_NO_TARGET" :color-target-in="COLOR_TARGET_IN" :color-target-out="COLOR_TARGET_OUT" :include-weekend="includeWeekend" />
                  </v-card>
                </v-col>

                <v-col v-if="canFollowUpTargetEvent" class="pa-4" cols="12">
                  <v-card outlined class="pa-2 background-blurred">
                    <csm-target-prospect-by-week :ref="`prospect-target-panel-${sp.value}`" :user-id="sp.value" :begin-at="beginAt" :end-at="endAt" :color-no-target="COLOR_NO_TARGET" :color-target-in="COLOR_TARGET_IN" :color-target-out="COLOR_TARGET_OUT" :include-weekend="includeWeekend" />
                  </v-card>
                </v-col>

                <v-col v-if="canFollowUpTargetEvent" class="pa-4" cols="12">
                  <v-card outlined class="pa-2 background-blurred">
                    <csm-target-business-meal-by-month :ref="`business-meal-target-panel-${sp.value}`" :user-id="sp.value" :begin-at="beginAt" :end-at="endAt" :color-no-target="COLOR_NO_TARGET" :color-target-in="COLOR_TARGET_IN" :color-target-out="COLOR_TARGET_OUT" :include-weekend="includeWeekend" />
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </cs-expand-panel>
        </v-card>
      </v-col>
    </v-row>

    <csm-user-dialog ref="user-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'

const COLOR_TARGET_IN = '#6BDE81'
const COLOR_TARGET_OUT = '#FFC133'
const COLOR_NO_TARGET = '#777'

export default {
  name: 'SalesPersonsTargets',
  components: {
    'csm-target-business-meal-by-month': () => import('./TargetBusinessMealByMonth'),
    'csm-target-call-by-week': () => import('./TargetCallByWeek'),
    'csm-target-prospect-by-week': () => import('./TargetProspectByWeek')
  },
  data () {
    return {
      COLOR_NO_TARGET,
      COLOR_TARGET_IN,
      COLOR_TARGET_OUT,
      RANGES: [],
      averageCalls: {},
      beginAt: '',
      endAt: '',
      disabled: false,
      includeUserWithNoTarget: false,
      includeWeekend: false,
      includeWeekendCallCount: 0,
      loading: false,
      me: {},
      salesPersonId: null,
      salesPersons: []
    }
  },
  computed: {
    canFollowUpTargetCall () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.SALES_PERSONS_TARGET)
    },
    canFollowUpTargetEvent () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.SALES_PERSONS_TARGET)
    },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    myIdSalesPerson () { return this.myself ? this.myself.value : null },
    myself () { return _.find(this.salesPersons, { mail: this.me.email }) || {} },
    salesPersonsSorted () {
      // Return users with targets first then users without target
      const targets = _.filter(this.salesPersons, sp => {
        return sp.lord_team_info?.calls_by_week || sp.lord_team_info?.business_lunch_by_month || sp.lord_team_info?.event_prospect_by_week
      })
      const noTarget = this.includeUserWithNoTarget ? _.filter(this.salesPersons, sp => {
        return !_.find(targets, { value: sp.value }) // All others users
      }) : []
      return targets.concat(noTarget)
    }
  },
  methods: {
    businessMealByMonthProgressPercent (userId) {
      return this.$refs[`business-meal-target-panel-${userId}`] && this.$refs[`business-meal-target-panel-${userId}`][0] ? this.$refs[`business-meal-target-panel-${userId}`][0].progress(true) : null
    },
    callByWeekProgressPercent (userId) {
      return this.$refs[`call-target-panel-${userId}`] && this.$refs[`call-target-panel-${userId}`][0] ? this.$refs[`call-target-panel-${userId}`][0].progress(true) : null
    },
    eventWithProspectProgressPercent (userId) {
      return this.$refs[`prospect-target-panel-${userId}`] && this.$refs[`prospect-target-panel-${userId}`][0] ? this.$refs[`prospect-target-panel-${userId}`][0].progress(true) : null
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async refresh () {
      try {
        this.loading = true
        _.forEach(this.salesPersonsSorted, sp => {
          if (this.canFollowUpTargetCall && this.$refs[`call-target-panel-${sp.value}`] && this.$refs[`call-target-panel-${sp.value}`][0]) this.$refs[`call-target-panel-${sp.value}`][0].refresh()

          if (this.canFollowUpTargetEvent && this.$refs[`business-meal-target-panel-${sp.value}`] && this.$refs[`business-meal-target-panel-${sp.value}`][0]) this.$refs[`business-meal-target-panel-${sp.value}`][0].refresh()

          if (this.canFollowUpTargetEvent && this.$refs[`prospect-target-panel-${sp.value}`] && this.$refs[`prospect-target-panel-${sp.value}`][0]) this.$refs[`prospect-target-panel-${sp.value}`][0].refresh()
        })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.loading = false }, 500)
      }
    },
    setDateRange (range) {
      this.beginAt = range.beginAt
      this.endAt = range.endAt
      setTimeout(() => this.refresh(), 1000)
    }
  },
  created () {
    this.RANGES = [{
      name: 'Current quarter',
      beginAt: this.$stratus.dt().startOf('quarter').format('YYYY-MM-DD'),
      endAt: this.$stratus.dt().format('YYYY-MM-DD') // Now
    }, {
      name: 'Current month',
      beginAt: this.$stratus.dt().startOf('month').format('YYYY-MM-DD'),
      endAt: this.$stratus.dt().format('YYYY-MM-DD') // Now
    }, {
      name: 'Current week',
      beginAt: this.$stratus.dt().startOf('week').format('YYYY-MM-DD'),
      endAt: this.$stratus.dt().format('YYYY-MM-DD') // Now
    }, {
      name: 'Last month',
      beginAt: this.$stratus.dt().startOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
      endAt: this.$stratus.dt().endOf('month').subtract(1, 'month').format('YYYY-MM-DD') // Now
    }, {
      name: 'Last week',
      beginAt: this.$stratus.dt().startOf('week').subtract(1, 'week').format('YYYY-MM-DD'),
      endAt: this.$stratus.dt().endOf('week').subtract(1, 'week').format('YYYY-MM-DD')
    }]
  },
  async mounted () {
    try {
      await this.$store.dispatch('$alto-companies/list', 'code,name,type,id_lord_salesperson')
      this.me = await this.$store.dispatch('$stratus-states/getMe')
      if (!this.isLord) {
        this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
        return
      }

      await this.$store.dispatch('$alto-users/loadSalesPersons')
      this.salesPersons = this.$store.getters['$alto-users/salesPersons']
      _.forEach(this.salesPersons, sp => {
        const user = this.$store.getters['$alto-users/getSalesPerson'](sp.value)
        sp.lord_team_info = user?.lord_team_info ? { ...user.lord_team_info } : undefined
      })

      this.setDateRange(this.RANGES[0]) // Default to current quarter
    } catch (error) {
      this.$stratus.services.notify.error(error)
    }
  }
}
</script>
