<template>
  <div :class="$vuetify.breakpoint.lgAndUp ? 'grid-list-md' : 'pa-0'">
    <v-dialog v-model="isSaving" persistent max-width="400">
      <div class="pa-12">
        {{ $t('Updating cart data...') }}
      </div>
    </v-dialog>

    <v-data-iterator :items="cart.items" :headers="headers" item-key="uuid" :loading="loading" :custom-sort="sortNatural" :page="page" :items-per-page.sync="rowsPerPage" :sort-by.sync="sortBy" :sort-desc.sync="descending" must-sort hide-default-footer>
      <template v-slot:item="{ item, index }">
        <v-divider v-if="index > 0" class="ma-4" />

        <v-hover v-slot="{ hover }">
          <div :class="hover ? 'focused' : ''">
            <v-row align="start" class="px-2 py-0" dense>
              <v-col class="text-h6 shrink">
                {{ index + 1 }}
              </v-col>

              <v-col class="px-4">
                <v-row align="center" no-gutters>
                  <span class="text-subtitle-1 font-weight-bold">
                    <sca-product-identity :value="item.ref" :label="item.name" :company="cartCustomer" show-avatar link="emit" @link-click="openProduct(item.ref)" />
                  </span>

                  <v-spacer />

                  <v-tooltip top v-if="isLord && item.main && item.main.length && cart.customer">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="expandedToggle(item)">
                        <v-icon :class="expanded[item.uuid] ? 'primary--text' : 'menu-icon--text'" small>
                          $vuetify.icons.discount
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Sub-product discount') }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="canSave && ((item.main && item.main.length) || item.no_price)" top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="modifyItem(item)">
                        <v-icon class="menu-icon--text" small>
                          $vuetify.icons.edit
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Update') }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="canSave" top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="duplicateItem(item)">
                        <v-icon class="menu-icon--text" small>
                          $vuetify.icons.clone
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Add same product to this quotation') }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="canSave" top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="deleteItem(item)" :loading="isDeleting">
                        <v-icon class="danger--text" small>
                          $vuetify.icons.delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Delete') }}</span>
                  </v-tooltip>
                </v-row>

                <v-row no-gutters class="mt-2">
                  <v-col>
                    <v-text-field v-model="item.caption" :label="$t('Caption')" :rules="[$stratus.services.form.rules.max(128)]" counter="128" :disabled="!isLord" :readonly="!canSave" dense />
                    <v-text-field v-model="item.customer_caption" :label="$t('Customer caption')" :rules="[$stratus.services.form.rules.max(128)]" counter="128" :readonly="!canSave" dense />

                    <cs-alert-panel v-if="isLord && item.no_price" type="info">
                      <template #content>
                        <div class="font-weight-bold">
                          {{ $t('No price fixed') }}
                        </div>
                        {{ $t('To update price and buying price fields you need to update this product.') }}
                      </template>
                    </cs-alert-panel>
                    <cs-alert-panel v-if="isLord && item.package" type="info">
                      <template #content>
                        <div class="font-weight-bold">
                          {{ $t('Package') }}
                        </div>
                        {{ $t('Complex products including this reference will not be taken into account when calculating the price with and without discount, and the price including VAT.') }}
                      </template>
                    </cs-alert-panel>

                    <sca-product-details :product="item" :company="cartCustomer" :show="true" :show-description="false" class="pb-8" />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="5" class="pl-4">
                <v-row align="center" justify="center" dense>
                  <v-col v-if="isLord" cols="4">
                    <cs-integer-input v-model="item.nb_days_commitment" :label="$t('Commitment\'s days')" dense :min="0" />
                  </v-col>
                  <v-col v-if="isLord">
                    <span v-if="item.commitment_update_by" class="text-caption">
                      {{ $t('Updated by') }} {{ item.commitment_update_by }}
                    </span>
                  </v-col>
                  <v-col v-else>
                    <span :class="item.nb_days_commitment ? 'font-weight-bold' : ''">
                      {{ $tc('Committed for {days} days', item.nb_days_commitment, { days: item.nb_days_commitment }) }}
                    </span>
                    <div v-if="item.nb_days_commitment">
                      ({{ $t('Until {date}', { date: $stratus.dt().add(item.nb_days_commitment, 'days').format('LL') }) }})
                    </div>
                  </v-col>

                  <v-col cols="4" class="font-weight-bold">
                    <csm-product-quantity-choice v-if="getProductByRef(item.ref).quantity_rules && typeof item.quantity === 'number'" :label="$t('Quantity')" :customer="cartCustomer" :quantity-rules="getProductByRef(item.ref).quantity_rules" :quantity="item.quantity" v-model="item.quantity" @input="onChangeQuantity(item)" :readonly="!canSave || !canChangeQuantity(item)" label-top />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <cs-alert-panel v-if="getProductByRef(item.ref).end_subscription" type="warning" :text="$t('This product reach end of life.')" />
                    <sca-product-prices-grid :prices="item" :periodicity="item.periodicity" :show-margin="isLord" :working="working" dense flat />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="isLord || item.per_discount > 0 || item.per_discount_setup > 0" align="center" class="pl-1 ml-4">
              <v-col cols="12" md="4">
                <v-checkbox v-show="isLord" :label="$t('Apply customer discounts')" v-model="item.custom_discounts" :false-value="true" :true-value="false" @change="setCheckbox(item)" />
              </v-col>
              <v-col v-if="item.periodicity === 'O'" cols="12" sm="6" md="3" lg="2">
                <cs-percent-input v-show="isLord || item.per_discount > 0" v-model="item.per_discount" :label="$t('Once')" :min="0" :max="100" :step="1" :disabled="!isLord || !item.custom_discounts" @input="onChangeInput" :readonly="!canSave" />
              </v-col>

              <v-col v-if="item.periodicity !== 'O'" cols="12" sm="6" md="3" lg="2">
                <cs-percent-input v-show="isLord || item.per_discount > 0" v-model="item.per_discount" :label="$t('Subscription')" :min="0" :max="100" :step="1" :disabled="!isLord || !item.custom_discounts" @input="onChangeInput" :readonly="!canSave" />
              </v-col>
              <v-col v-if="item.periodicity !== 'O'" cols="12" sm="6" md="3" lg="2">
                <cs-percent-input v-show="isLord || item.per_discount_setup > 0" v-model="item.per_discount_setup" :label="$t('Setup')" :min="0" :max="100" :step="1" :disabled="!isLord || !item.custom_discounts" @input="onChangeInput" :readonly="!canSave" />
              </v-col>
            </v-row>

            <v-row v-if="expanded[item.uuid] && isLord && item.main?.length && !isNaN(item.main[0]?.per_discount)" no-gutters class="pl-2 ml-4">
              <v-col cols="12">
                <csm-product-sub-discount :ref="`product-discount-${item.uuid}`" :product="item" :customer="cart.customer" @change="onChangeDiscount" />
              </v-col>
            </v-row>
          </div>
        </v-hover>
      </template>

      <template v-slot:no-data>
        <div class="text-center mt-4">
          {{ $t('No product.') }}
        </div>
      </template>
    </v-data-iterator>

    <csm-product-duplicate-dialog ref="cart-product-duplicate-dialog" :code="cartCustomer" :in-cart="true" />
    <csm-product-dialog ref="product-dialog" readonly />
    <csm-company-dialog ref="company-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'ShoppingCart',
  components: {
    'csm-product-duplicate-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Products/ProductDuplicateDialog'),
    'csm-product-sub-discount': () => import(/* webpackChunkName: "components" */ '@/components/Products/ProductSubDiscount'),
    'csm-product-quantity-choice': () => import(/* webpackChunkName: "components" */ '@/components/Products/ProductQuantityChoice')
  },
  props: {
    pauseSaveCart: { type: Boolean, default: false }
  },
  data () {
    return {
      cartId: 0,
      expirationDateOrigin: null,
      expanded: {},
      isSaving: false,
      isDeleting: false,
      loading: false,
      page: 1,
      rowsPerPage: -1,
      sortBy: 'index',
      descending: false,
      pricesByPeriodicity: null,
      showDescription: [],
      total_discount: 0,
      total_setup_discount: 0,
      updateProduct: false,
      isModifyingProduct: false,
      working: false,
      listSalesPersons: []
    }
  },
  computed: {
    ...mapGetters({
      cart: 'carts/current',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    cartCustomer () { return this.cart.customer || this.myCompany },
    canEditClosing () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.CART_UPDATE_CLOSING)
    },
    cartQuantity () {
      let result = 0.0
      this.cart.items.forEach(item => {
        if (item.float_quantity) {
          result += 1
        } else {
          result += item.quantity || 0
        }
      })
      return result
    },
    canSave () {
      return !this.cart.expired || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CARTS, this.$alto.API_PERMISSIONS.CART_UPDATE_EXPIRED)
    },
    headers () {
      return [
        { text: this.$t('#'), value: 'id', width: 32, sortable: false },
        { text: this.$t('Product'), value: 'name', sortable: false },
        { text: this.$t('Quantity'), value: 'quantity', width: 180, sortable: false },
        { text: this.$t('Price'), value: 'price', sortable: false }
      ]
    },
    myCompany () { return this.$store.getters['$stratus-states/me'].company },
    prices () {
      if (!this.pricesByPeriodicity) this.computeTotal()
      return this.pricesByPeriodicity
    }
  },
  watch: {
    'cart.expiration_date': {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue && !this.expirationDateOrigin) {
          this.expirationDateOrigin = this.$stratus.dt(newValue)
        }
      }
    },
    cart: {
      handler () {
        if (this.cart.id !== this.cartId) {
          this.cartId = this.cart.id
          this.computeTotal()
        }
      }
    }
  },
  methods: {
    async addProductToCart ({ product, main, quantity }) {
      this.isSaving = true
      // Override default product from catalog with user inputs, if any
      product.main = main || null
      try {
        await this.$store.dispatch('carts/addToCurrent', { items: Object.assign(product, { quantity }), company: this.cartCustomer })
        this.$stratus.services.notify.success(this.$t('{quantity} × {product} added to cart.', { quantity, product: product.name || product.ref }))
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.isSaving = false }, 1000)
      }
    },
    canChangeQuantity (item) {
      if (!this.isLord) {
        if (item.no_price) {
          return false
        }
      }
      return true
    },
    computePrice () {
      this.working = true
      this.saveCart()
      setTimeout(() => { this.working = false }, 2000)
    },
    computeTotal () {
      this.$forceUpdate()
    },
    async createName () {
      if (this.canSave) {
        this.$set(this.cart, 'name', await this.$store.dispatch('carts/createName'))
        this.$forceUpdate()
        this.$emit('refresh')
      }
    },
    async deleteItem (item) {
      try {
        this.isDeleting = true
        await this.$store.dispatch('carts/delFromCurrent', item.uuid)
        this.saveCart()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
      setTimeout(() => { this.isDeleting = false }, 2000)
    },
    duplicateItem (item) {
      this.$refs['cart-product-duplicate-dialog'].open(_.cloneDeep(item), { update: false, customer: this.cartCustomer })
        .then(({ product, main, quantity }) => {
          if (product) this.addProductToCart({ product, main, quantity })
        })
    },
    expandedToggle (product) {
      this.$set(this.expanded, product.uuid, !this.expanded[product.uuid])
    },
    expirationDays () { return this.$store.getters['carts/expirationDays'](this.cart.date) },
    expirationDateHtml () { return this.$store.getters['carts/expirationDateHtml'](this.cart.date) },
    getIndexFromUUID (tab, uuid) {
      let index = null
      _.forEach(tab, (item, key) => {
        if (item.uuid === uuid) {
          index = key
        }
      })
      return index
    },
    getProductByRef (ref) { return this.$store.getters['$alto-catalog/get'](ref) || {} },
    isExpired () {
      return this.expirationDateOrigin && this.$stratus.dt(this.expirationDateOrigin).isBefore(this.$stratus.dt())
    },
    modifyItem (item) {
      this.$refs['cart-product-duplicate-dialog'].open(_.cloneDeep(item), { update: true, customer: this.cartCustomer })
        .then(({ product, main, quantity }) => {
          if (product) {
            item.caption = product.caption
            item.customer_caption = product.customer_caption
            item.main = _.cloneDeep(main)
            item.quantity = quantity
            if (product.no_price) {
              item.price = product.price
              item.buying_price = product.buying_price
            }
            this.computePrice()
          }
        })
    },
    onChangeCompany (customer) {
      if (!customer) return
      this.isSaving = true
      setTimeout(() => {
        this.isSaving = false
      }, 4000)
      this.resetDiscounts(this.cart.items)
      this.resetControls()
      this.computePrice()
    },
    onChangeDiscount (payload) {
      if (!this.$refs[`product-discount-${payload.uuid}`]) return
      const index = this.getIndexFromUUID(this.cart.items, payload.uuid)
      this.cart.items[index].main = this.$refs[`product-discount-${payload.uuid}`].build()
      this.computePrice()
    },
    onChangeInput: _.debounce(function (newValue) {
      if (!isNaN(newValue)) {
        this.computePrice()
      }
    }, 500),
    onChangeQuantity: _.debounce(function (item) {
      if (item.quantity) {
        this.computePrice()
      }
    }, 500),
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openProduct (id) {
      if (this.$refs['product-dialog']) this.$refs['product-dialog'].openId(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    reload () {
      this.$nextTick(() => {
        try { this.$forceUpdate() } catch (error) {}
      })
    },
    resetDiscounts (productTree) {
      _.each(productTree, (value, key, product) => {
        if (key === 'per_discount') { product.per_discount = 0 }
        if (key === 'per_discount_setup') { product.per_discount_setup = 0 }
        if (key === 'custom_discounts') { product.custom_discounts = false }
        if (_.isObject(value)) this.resetDiscounts(value)
      })
    },
    resetControls () {
      // This will force nested components to update themselves
      this.expanded = {}
    },
    saveCart () {
      if (!this.canSave) return
      if (!this.pauseSaveCart) this.$emit('saveCart')
    },
    setCheckbox (item) {
      if (this.$refs[`product-discount-${item.uuid}`]) {
        this.$refs[`product-discount-${item.uuid}`].setCheckbox(!item.custom_discounts)
      }
    },
    showItem (item) {
      this.$refs['cart-product-duplicate-dialog'].open(_.cloneDeep(item), { update: false, readonly: true, customer: this.cartCustomer })
        .then(({ product, main, quantity }) => {
          if (product) {
            item.caption = product.caption
            item.customer_caption = product.customer_caption
            item.main = _.cloneDeep(main)
            item.quantity = quantity
            this.computePrice()
          }
        })
    },
    sortNatural (items, index, isDescending) {
      const sort = {}
      sort[index] = isDescending ? 'desc' : 'asc'
      return this.$stratus.services.sort.natural(items, sort)
    }
  },
  async created () {
    if (!this.$store.getters['$alto-catalog/list'].length) await this.$store.dispatch('$alto-catalog/list')
  }
}
</script>
