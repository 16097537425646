/* eslint-disable quote-props */
/* eslint-disable no-irregular-whitespace */
export default {
  'Visit <a href="{brand_website}" target="_sws">{brand} website</a> to stay aware about {brand} latest news and offers.': 'Visit <a href="{brand_website}" target="_sws">{brand} website</a> to stay aware about {brand} latest news and offers.', // «Visitez le <a href="{brand_website}" target="_sws">Site Web {brand}</a> pour les dernières actualités et offres.»
  'Manage your products, quotations and orders with <a href="https://manager.scalair.fr" target="_csi">Scalair Manager</a>.': 'Manage your products, quotations and orders with <a href="https://manager.scalair.fr" target="_csi">Scalair Manager</a>.', // «Gérer vos produits, devis et commandes avec <a href="https://manager.scalair.fr" target="_csi">Scalair Manager</a>.»
  'Scalair': 'Scalair', // «Scalair»
  'Scalair Manager': 'Scalair Manager', // «Scalair Manager»
  'Administration': 'Administration', // «Administration»
  'To automate the transition to full screen, you can install a kiosk extension in your browser.': 'To automate the transition to full screen, you can install a kiosk extension in your browser.', // «Pour automatiser le passage en plein écran, vous pouvez installer une extension de type kiosk dans votre navigateur.»
  'Disconnecting...': 'Disconnecting...', // «Déconnexion…»
  'Report': 'Report', // «Signaler»
  'None': 'None', // «Aucun(e)»
  'Empty': 'Empty', // «Vide»
  'About': 'About', // «À propos»
  'Light theme': 'Light theme', // «Thème clair»
  'Dark theme': 'Dark theme', // «Thème sombre»
  'Languages': 'Languages', // «Langages»
  'Home': 'Home', // «Accueil»
  'Activities': 'Activities', // «Activités»
  'Reloading application...': 'Reloading application...', // «Rechargement de l'application…»
  'You must login to access your Scalair Manager.': 'You must login to access your Scalair Manager.', // «Connectez-vous pour accéder à Scalair Manager.»
  'Can not connect to Scalair Manager!': 'Can not connect to Scalair Manager!', // «Impossible de se connecter à Scalair Manager !»
  'Recipient email': 'Recipient email', // «Email du destinataire»
  'Scalair applications access': 'Scalair applications access', // «Accès aux applications Scalair»
  'Block user': 'Block user', // «Interdire la connexion de cet utilisateur»
  'Allow user': 'Allow user', // «Autoriser la connexion de cet utilisateur»
  'Generate API key': 'Generate API key', // «Générer une clé d'API»
  'Previous API key will be revoked and a new one will be generated. Confirm?': 'Previous API key will be revoked and a new one will be generated. Confirm?', // «La clé d'API en cours d'utilisation sera révoquée et une nouvelle clé sera générée. Les applications tierces pourraient ne plus avoir accès à l'API Scalair. Confirmez-vous la génération ?»
  'Reset password': 'Reset password', // «Réinitialiser le mot de passe»
  'User will receive a password reset link. Confirm?': 'User will receive a password reset link. Confirm?', // «L'utilisateur recevra un lien pour réinitialiser son mot de passe. Confirmez-vous la réinitialisation ?»
  '* Indicates required field': '* Indicates required field', // «* Champ obligatoire»
  'Password is mandatory to create user': 'Password is mandatory to create user', // «Le mot de passe est obligatoire pour créer l'utilisateur»
  'This kind of field is mandatory.': 'This kind of field is mandatory.', // «Ce type de champ est obligatoire»
  'Portal': 'Portal', // «Portail»
  'It\'s you!': 'It\'s you!', // «C'est vous !»
  'Username': 'Username', // «Identifiant»
  'Users': 'Users', // «Utilisateurs»
  'User': 'User', // «Utilisateur»
  'Spheres': 'Spheres', // «Projets»
  'Sphere': 'Sphere', // «Projet»
  'Bot': 'Bot', // «Automate»
  'user': 'user', // «Utilisateur»
  'bot': 'bot', // «Automate»
  'Partner': 'Partner', // «Revendeur»
  'Crew': 'Crew', // «Équipe Scalair»
  'Crew?': 'Crew?', // «Équipe Scalair ?»
  'Settings': 'Settings', // «Préférences»
  'Lastname': 'Lastname', // «Nom de famille»
  'Please, confirm the deletion of user {lastname} {firstname} <{email}>?': 'Please, confirm the deletion of user {lastname} {firstname} <{email}>?', // «Please, confirm the deletion of user {lastname} {firstname} <{email}>?»
  'Please, confirm the deletion of the {name} sphere?': 'Please, confirm the deletion of the {name} sphere?', // «Confirmez-vous la suppression du projet "{name}" ?»
  'Show/hide menu': 'Show/hide menu', // «Afficher / cacher le menu»
  'CSV': 'CSV', // «CSV»
  'Note': 'Note', // «Note»
  'Last update at {date}': 'Last update at {date}', // «Dernière modification le {date}»
  'Created at {date}': 'Created at {date}', // «Créé le {date}»
  'Created by': 'Created by', // «Créé par»
  'Modified by': 'Modified by', // Modifié par»
  'by {name}': 'by {name}', // «par {name}»
  'Comments': 'Comments', // «Commentaires»
  'Connection: ': 'Connection: ', // «Connexion : »
  '{count} Items': '{count} Items', // «{count} éléments»
  'priority-critical': 'Critical', // «Critique»
  'priority-high': 'High', // «Élevée»
  'priority-moderate': 'Moderate', // «Modérée»
  'priority-medium': 'Medium', // «Modérée»
  'priority-low': 'Low', // «Basse»
  'priority-planning': 'Planning', // «Planifiée»
  'Severity': 'Severity', // «Gravité»
  'severity-disaster': 'Disaster', // «Catastrophique»
  'severity-critical': 'Critical', // «Critique»
  'severity-high': 'High', // «Élevée»
  'severity-moderate': 'Moderate', // «Modérée»
  'severity-average': 'Average', // «Moyenne»
  'severity-low': 'Low', // «Basse»
  'severity-planning': 'Planning', // «Planifiée»
  'scalair-status-compute': 'Compute', // «Calcul»
  'scalair-status-storage': 'Storage', // «Stockage»
  'scalair-status-network': 'Network', // «Réseau»
  'scalair-status-voip': 'Voip', // «VoIP»
  'scalair-status-other': 'Other', // «Autre»
  'State': 'State', // «État»
  'state-new': 'state-new', // «Nouveau»
  'state-in progress': 'state-in progress', // «En cours…»
  'Compute': 'Compute', // «Calcul»
  'Storage': 'Storage', // «Stockage»
  'Network': 'Network', // «Réseau»
  'VoIP': 'VoIP', // «VoIP»
  'Other': 'Other', // «Autre»
  'and': 'and', // «et»
  'Back': 'Back', // «Retour»
  'Edit': 'Edit', // «Modifier»
  'Clone': 'Clone', // «Dupliquer»
  'Save': 'Save', // «Enregistrer»
  'Send order': 'Send order', // «Envoyer la commande»
  'Update order': 'Update order', // «Modifier la commande»
  'Return to Cart': 'Return to Cart', // «Retourner au panier»
  'Are you sure ?': 'Are you sure ?', // «Êtes-vous sûr ?»
  'No.': 'No.', // «n°»
  'Confirm the stop of this product.': 'Confirm the stop of this product.', // «Confirmez-vous l'arrêt du produit ?»
  'Confirm to completed this product.': 'Confirm to completed this product.', // «Confirmez-vous la validation du produit ?»
  'Cannot delete this item!': 'Cannot delete this item!', // «Impossible de supprimer cet élément !»
  'Create': 'Create', // «Créer»
  'Submit': 'Submit', // «Enregistrer»
  'Created at': 'Created at', // «Créé le»
  'Updated at': 'Updated at', // «Modifié le»
  'Product detail :': 'Product detail :', // «Détail du produit :»
  'Name': 'Name', // «Nom»
  'Title': 'Title', // «Titre»
  'Create {}': 'Create {}', // «Création {}»
  'Page Not Found.': 'Page Not Found.', // «Page non trouvée»
  'Demo user': 'Demo user', // «Utilisateur de démonstration»
  'Type': 'Type', // «Type»
  'Id': 'Id', // «Id»
  'Scalair account': 'Scalair account', // «Compte Scalair»
  'Application connection': 'Application connection', // «Comptes Scalair»
  'Label': 'Label', // «Intitulé»
  'Notes': 'Notes', // «Notes»
  'Saved!': 'Saved!', // «Enregistré !»
  'Last update:': 'Last update:', // «Mise à jour :»
  'Services Status': 'Services Status', // «Météo des services»
  'Toggle fullscreen': 'Toggle fullscreen', // «Activer/Désactiver le plein écran»
  'Toggle widgets bar': 'Toggle widgets bar', // «Afficher/cacher la barre des widgets»
  'Supervision': 'Supervision', // «Supervision»
  'Alarms': 'Alarms', // «Alarmes»
  'Tickets': 'Tickets', // «Tickets»
  'Hardware': 'Hardware', // «Matériel»
  'Monitor': 'Monitor', // «Suivi»
  'Choose one or more widgets above...': 'Choose one or more widgets above...', // «Choisissez un widget ci-dessus…»
  'Hide supervision widget': 'Hide supervision widget', // «Cacher le widget de supervision»
  'Show supervision widget': 'Show supervision widget', // «Afficher le widget de supervision»
  'Hide': 'Hide', // «Cacher»
  'Your search for «{search}» found no results.': 'Your search for «{search}» found no results.', // «Votre recherche «{search}» ne donne aucun résultat.»
  'Hide grid columns headers': 'Hide grid columns headers', // «Cacher les entêtes des colonnes»
  'Display dates in long format': 'Display dates in long format', // «Afficher les dates en format long»
  'Display tickets with recent customer comments first': 'Display tickets with recent customer comments first', // «Afficher en premier les tickets récemment commentés par les clients»
  'Shortcuts': 'Shortcuts', // «Raccourcis»
  'Ticket': 'Ticket', // «Ticket»
  'Assigned to': 'Assigned to', // «Assigné à»
  'Profiles': 'Profiles', // «Profils»
  'Choose a profile': 'Choose a profile', // «Choisir un profil»
  'Profile': 'Profile', // «Profil»
  'Profile: ': 'Profile: ', // «Profil : »
  'Profile name': 'Profile name', // «Nom du profil»
  'New profile': 'New profile', // «Nouveau profil»
  'Default profile': 'Default profile', // «Profil par défaut»
  'Choose as default profile': 'Choose as default profile', // «Choisir comme profil par défaut»
  'Confirm deletion of «{item}»?': 'Confirm deletion of «{item}»?', // «Confirmez-vous l'effacement de « {item} » ?»
  'Address': 'Address', // «Adresse»
  'Host': 'Host', // «Hôte»
  'Service': 'Service', // «Service»
  'Message': 'Message', // «Message»
  'Products & licenses': 'Products & licenses', // «Produits & licences»
  'Virtual machines': 'Virtual machines', // «Machines Virtuelles»
  'Licenses & other products': 'Licenses & other products', // «Licences & autres produits»
  'Customer': 'Customer', // «Client»
  'Customers': 'Customers', // «Clients»
  'Company': 'Company', // «Société»
  'Companies': 'Companies', // «Sociétés»
  'Subscribed products': 'Subscribed products', // «Produits souscrits»
  'Subscribed product': 'Subscribed product', // «Produit souscrit»
  'Your products': 'Your products', // «Vos produits»
  'Instances': 'Instances', // «Instances»
  'Other products & licences': 'Other products & licences', // «Autres produits & licences»
  'Other products': 'Other products', // «Autres produits
  'Orders history': 'Orders history', // «Historique des commandes»
  'Quotations': 'Quotations', // «Devis»
  'Finalize': 'Finalize', // «Finaliser»
  'Welcome to Scalair Manager': 'Welcome to Scalair Manager', // «Bienvenue dans Scalair Manager»
  'This application allows you to manage all your Scalair products.': 'This application allows you to manage all your Scalair products.', // «Cette application vous permet de gérer tous vos produits Scalair.»
  'List all your products.': 'List all your products.', // «Lister les produits»
  'Manage your licences and instances': 'Manage your licences and instances', // «Gérer vos licences et instances»
  'Purchasing new ones': 'Purchasing new ones', // «En acheter de nouveaux»
  'Keep in touch with our teams...': 'Keep in touch with our teams...', // «Rester en contact avec nos équipes…»
  'Your report as been submitted.': 'Your report as been submitted.', // «Votre rapport a été envoyé.»
  'Please, confirm the deletion of company {code} — {name}?': 'Please, confirm the deletion of company {code} — {name}?', // «Confirmez-vous la suppression de la société {codeName} ?»
  'Parent company': 'Parent company', // «Société gérante»
  'Start typing to Search': 'Start typing to Search', // «Saisissez votre recherche»
  'Find...': 'Find...', // «Recherche…»
  'Company {code} — {name} created.': 'Company {code} — {name} created.', // «Société {code} — {name} créée.»
  'Company {code} — {name} updated.': 'Company {code} — {name} updated.', // «Société {code} — {name} modifiée.»
  'Company {code} — {name} deleted.': 'Company {code} — {name} deleted.', // «Société {code} — {name} supprimée.»
  'User {lastname} {firstname} created.': 'User {lastname} {firstname} created.', // «Utilisateur {lastname} {firstname} créé.»
  'User {lastname} {firstname} updated.': 'User {lastname} {firstname} updated.', // «Utilisateur {lastname} {firstname} modifié.»
  'User {lastname} {firstname} <{email}> deleted.': 'User {lastname} {firstname} <{email}> deleted.', // «Utilisateur « {fullName} » supprimé.»
  'This email address is already used by another user!': 'This email address is already used by another user!',
  'You\'re not allowed to delete {lastname} {firstname} <{email}>!': 'You\'re not allowed to delete {lastname} {firstname} <{email}>!', // «Vous n'êtes pas autorisé à supprimer « {fullName} » !»
  'Sphere "{name}" deleted.': 'Sphere "{name}" deleted.', // «Projet "{name}" supprimé.»
  'You\'re not allowed to delete sphere "{name}".': 'You\'re not allowed to delete sphere "{name}".', // «Vous n'êtes pas autorisé à supprimer le projet "{name}".»
  'Your product has been updated.': 'Your product has been updated.', // «Votre produit a bien été modifié»
  'Detail': 'Detail', // «Détail»
  'Details': 'Details', // «Détails»
  'n/a': 'n/a', // «n/a»
  'Usage': 'Usage', // «Utilisation»
  'Daily use': 'Daily use', // «Utilisation journalière»
  'Used this month: {count}{unit}': 'Used this month: {count}{unit}', // «utilisé ce mois : {count}{unit}»
  'This month: {count}{unit}': 'This month: {count}{unit}', // «utilisé ce mois : {count}{unit}»
  'Previous month: {count}{unit}': 'Previous month: {count}{unit}', // «Mois précédent : {count}{unit}»
  'Previous: {count}{unit}': 'Previous: {count}{unit}', // «Mois précédent : {count}{unit}»
  'day|day|days': 'day|day|days', // «jour|jours»
  'Subscriptions': 'Subscriptions', // «Souscriptions»
  'Subscription': 'Subscription', // «Abonnement»
  'Subscription: ': 'Subscription: ', // «Abonnement : »
  'Setup': 'Setup', // «Installation»
  'Setup: ': 'Setup: ', // «Installation : »
  'No user for this company.|One user for this company.|{count} users for this company.': 'No user for this company.|One user for this company.|{count} users for this company.', // «Aucun Utilisateur pour cette société.|Un seul utilisateur pour ce client.|{count} utilisateurs pour cette société.»
  'Scalair contacts': 'Scalair contacts', // «Contacts Scalair»
  'Code': 'Code', // «Code»
  'Complement': 'Complement', // «Complément»
  'Fax': 'Fax', // «Fax»
  'Phone': 'Phone', // «Téléphone»
  'Mobile phone': 'Mobile phone', // «Téléphone portable»
  'You should choose a sales manager, a project manager and one or more Service Manager for this customer.': 'You should choose a sales manager, a project manager and one or more CSM for this customer.', // «Vous devriez choisir un responsable commercial, un chef de projet et un ou plusieurs Service Manager.»
  'Contracts': 'Contracts', // «Contrats»
  'Contract': 'Contract', // «Contrat»
  'This contract': 'Ce contract', // «Contrat»
  'to_sign': 'To sign', // «À signer»
  'signed': 'Signed', // «Signé»
  'terminated': 'Terminated', // «Terminé»
  'To sign': 'To sign', // «À signer»
  'Signed': 'Signed', // «Signé»
  'Sign date': 'Sign date', // «Date de signature»
  'Terminated': 'Terminated', // «Terminé»
  'Number': 'Number', // «Numéro»
  'Principal\'s contract': 'Principal\'s contract', // «Contrat principal»
  'Contract\'s number': 'Contract\'s number', // «Numéro de contract»
  'Additional clause': 'Additional clause', // «Clause additionnelle»
  'Additional clauses': 'Additional clauses', // «Clauses additionnelles»
  'Document\'s path': 'Document\'s path', // «Chemin de document»
  'Signature\'s date:': 'Signature\'s date:', // «Date de signature :»
  'Begin\'s date:': 'Begin\'s date:', // «Date de début du contrat :»
  'Please, confirm the deletion of contract {number}?': 'Please, confirm the deletion of contract {number}?', // «Confirmez-vous la suppression du contrat {number} ?»
  'Contract {number} updated.': 'Contract {number} updated.', // «Contrat {number} modifié.»
  'Contract {number} created.': 'Contract {number} created.', // «Contrat {number} créé.»
  'Contract {number} deleted.': 'Contract {number} deleted.', // «Contrat {number} supprimé.»
  'Document\'s path:': 'Document\'s path', // «Chemin du document»
  'Upload a contract:': 'Upload a contract:', // «Envoyer un document :»
  'Confirm selection of file «{name}»?': 'Confirm selection of file «{name}»?', // «Confirmer la selection de ce document {name} ?»
  'Select': 'Select', // «Sélectionner»
  'Select file': 'Select file', // «Sélectionner un document»
  'My contract': 'My contract', // «Mon contrat»
  'My contracts': 'My contracts', // «Mes contrats»
  'Replace document:': 'Replace document:',
  'Add a document:': 'Add a document:',
  'Please select a company\'s code': 'Please select a company\'s code', // «Veuillez sélectionner un code compagnie»
  'Tags & Notes': 'Tags & Notes', // «Étiquettes & Notes»
  'Add a note': 'Add a note', // «Ajouter une note»
  'Blocked?': 'Blocked?', // «Bloqué ?»
  'Allow user to authenticate': 'Allow user to authenticate',
  'Payment blocked': 'Payment blocked', // «Paiement bloqué»
  'Account is blocked': 'Account is blocked', // «Le compte est bloqué»
  'Account allowed': 'Account allowed', // «Compte autorisé»
  'This user can access Scalair applications such as Dashboard and Manager.': 'This user can access Scalair applications such as Dashboard and Manager.', // «Cet utilisateur peut accéder aux applications Scalair, telles que Dashboard et Manager.»
  'This user cannot access Scalair applications such as Dashboard and Manager.': 'This user cannot access Scalair applications such as Dashboard and Manager.', // «Cet utilisateur ne peut pas accéder aux applications Scalair, telles que Dashboard et Manager.»
  'Scalair Bot': 'Scalair Bot', // «Automate Scalair»
  'Firstname': 'First name', // «Prénom»
  'Function': 'Function', // «Fonction»
  'Job title': 'Job title',
  'Team': 'Team',
  'Teams': 'Teams',
  'Reset password...': 'Reset password...', // «Réinitialiser le mot de passe…»
  'Client': 'Client', // «Client»
  'Sales Manager': 'Sales Manager', // «Responsable commercial»
  'Project Manager': 'Project Manager', // «Chef de projet»
  'Service Manager': 'CSM', // «Service Manager»
  'Backup Service Manager': 'Backup CSM', // «Service Manager remplaçant»
  'Account type': 'Account type', // «Type de compte»
  'Delete note': 'Delete note', // «Supprimer la note»
  'Scalair applications account': 'Scalair applications account', // «Compte Scalair»
  'Scalair API key': 'Scalair API key', // «Clé d'API Scalair»
  'Generate API Key...': 'Generate API Key...', // «Générer une clé d'API…»
  'List of companies that this user manage': 'List of companies that this user manage', // «Liste des sociétés gérés par cet utilisateur»
  'Do you want to delete «{note}»?': 'Do you want to delete «{note}»?', // «Voulez-vous supprimer la note « {note} » ?»
  'Products Catalog': 'Products Catalog', // «Catalogue Produits»
  'Filter': 'Filter', // «Filtre»
  'Add to your shopping cart': 'Add to your shopping cart', // «Ajouter au panier»
  'No quotation|One quotation|{count} quotations': 'No quotation|One quotation|{count} quotations', // «Aucun devis|Un devis|{count} devis»
  'No item added to cart.|One item added to cart.|{count} items added to cart.': 'No item added to cart.|One item added to cart.|{count} items added to cart.', // «Aucun produit ajouté au panier!|Un article ajouté au panier : {item}|{count} articles ajoutés au panier.»
  'Quotation «{name}» not found!': 'Quotation «{name}» not found!', // «Devis « {name} » non trouvé !»
  'Order «{name}» not found!': 'Order «{name}» not found!', // «Commande « {name} » non trouvée !»
  'Deposit validate by {mail} on {date}.': 'Deposit validate by {mail} on {date}.',
  'Cart': 'Cart', // «Panier»
  'Carts': 'Carts', // «Paniers»
  'Quantity': 'Quantity', // «Quantité»
  'Qty': 'Qty', // «Qte»
  'Qty:': 'Qty:', // «Qte :»
  'No description...': 'No description...', // «Aucune description…»
  'No detail...': 'No detail...', // «Aucun detail…»
  'MY QUOTATION': 'MY QUOTATION', // «MON DEVIS»
  'Delete quotation': 'Delete quotation', // «Supprimer le devis»
  'Delete quotations': 'Delete quotations', // «Supprimer les devis»
  'Confirm deletion of quotation «{name}»?': 'Confirm deletion of quotation «{name}»?', // «Confirmez-vous la suppression du devis « {name} » ?»
  'Confirm deletion of quotations?': 'Confirm deletion of quotations?', // «Confirmez-vous la suppression du/des devis ?»
  'Quotation «{name}» saved.': 'Quotation «{name}» saved.', // «Devis « {name} » enregistré.»
  'Quotation «{name}» deleted!': 'Quotation «{name}» deleted!', // «Devis « {name} » supprimé !»
  'Download as PDF': 'Download as PDF', // «Télécharger le PDF»
  'Download as CSV': 'Download as CSV', // «Télécharger le CSV»
  'Secret': 'Secret', // «Secret»
  'Share a secret': 'Share a secret', // «Partager un secret»
  'This feature allows you to securely transmit information to an email. The recipient will receive two emails: one with the link for open the secret, a second with the password for unlock it. Once read, the secret will no longer be accessible.': 'This feature allows you to securely transmit information to an email. The recipient will receive two emails: one with the link for open the secret, a second with the password for unlock it. Once read, the secret will no longer be accessible.', // «Créer et partager un secret avec un utilisateur.»
  'Secret send to {email}.': 'Secret send to {email}.', // «Secret envoyé à {email}.»
  'Reveal a secret': 'Reveal a secret', // «Révéler un secret»
  'Secret identifier': 'Secret identifier', // «Identifiant du secret»
  'Reveal': 'Reveal', // «Révéler»
  'Please, enter the secret identifier, and password. Then click on «Reveal» button to display the content of the secret.': 'Please, enter the secret identifier, and password. Then click on «Reveal» button to display the content of the secret.', // «Saisissez l'identifiant et le mot de passe du secret. Puis cliquez sur le bouton « Révéler » pour en afficher le contenu.»
  'Please, enter the password for the secret and click on «Reveal» button to display the content of the secret.': 'Please, enter the password for the secret and click on «Reveal» button to display the content of the secret.', // «Saisissez le mot de passe du secret, puis cliquez sur le bouton « Révéler » pour en afficher le contenu.»
  'Secret as been read and is now erased.': 'Secret as been read and is now erased.', // «Le secret a été lu et est maintenant effacé.»
  'No secret for this id and password.': 'No secret for this id and password.', // «Aucun secret ne correspond à cet identifiant et ce mot de passe.»
  'Product': 'Product', // «Produit»
  'Instance': 'Instance', // «Instance»
  'Select an action to perform...': 'Select an action to perform...', // «Choisissez une action à réaliser…»
  'on selected instance | on {count} instances': 'on selected instance | on {count} instances', // «sur l'instance sélectionnée | sur {count} instances»
  'on selected line | on {count} lines': 'on selected line | on {count} lines', // «sur la ligne sélectionnée | sur {count} lignes»
  'Change sphere': 'Change sphere', // «Changer le projet»
  'Do you want to set sphere «{pname}» for {count} subscribed products?': 'Do you want to set sphere «{pname}» for {count} lines?', // «Voulez-vous affecter le projet « {pname} » à {count} produits souscrits ?»
  '{count} subscribed product(s) did not match the reference company of the sphere, they have not been modified.': '{count} subscribed product(s) did not match the reference company of the sphere, they have not been modified.', // «La mise à jour de {count} produits souscrits à échouée.»,
  'You can change sphere for only one company at a time.': 'You can change sphere for only one company at a time.', // «Vous ne pouvez changer le projet que pour une société à la fois.»
  'No user found on {count}. | One user found on {count}. | {found} users found on {count}.': 'No user found on {count}. | One user found on {count}. | {found} users found on {count}.', // «Aucun utilisateur trouvé sur {count}. | Un utilisateur trouvé sur {count}. | {found} utilisateurs trouvés sur {count}.»
  'No product found on {count}. | One product found on {count}. | {found} products found on {count}.': 'No product found on {count}. | One product found on {count}. | {found} products found on {count}.', // «Aucun produit trouvé sur {count}. | Un produit trouvé sur {count}. | {found} produits trouvés sur {count}.»
  'Provider': 'Provider', // «Fournisseur»
  'Service Pack': 'Service Pack', // «Pack de Services»
  'Belongs to': 'Belongs to', // «Propriétaire»
  'Instance is {state}': 'Instance is {state}', // «L'instance est {state}»
  'state_on': 'ON', // «allumée»
  'state_off': 'Off', // «éteinte»
  'state_stopped': 'state_stopped', // «suspendue»
  'state_unknown': 'state_unknown', // «état inconnu»
  'Instance is on': 'Instance is on', // «Instance allumée»
  'Instance is off': 'Instance is off', // «Instance éteinte»
  'Instance is not supervised': 'Instance is not supervised', // «Instance non supervisée»
  'Instance is supervised': 'Instance is supervised', // «Instance supervisée»
  'Storage {name}': 'Storage {name}', // «Stockage {name}»
  'Storage used': 'Storage used', // «Stockage utilisé»
  'from {min}{unit} to {max}{unit}': 'from {min}{unit} to {max}{unit}', // «De {min}{unit} à {max}{unit}»
  '{qty} * {name}': '{qty} * {name}', // «{qty} × {name}»
  'Run...': 'Run...', // «Exécuter…»
  'End subscription': 'End subscription', // «Arrêter la souscription»
  'Restart': 'Restart', // «Redémarrer»
  'Last refresh: {date}': 'Last refresh: {date}', // «Actualisé le {date}»
  'Last refresh on: {date}': 'Last refresh on: {date}',
  'Start {name}': 'Start {name}', // «Démarrer {name}»
  'To start this instance, please enter your password:': 'To start this instance, please enter your password:', // «Pour démarrer cette instance, saisissez votre mot de passe :»
  'Restart {name}': 'Restart {name}', // «Redémarrer {name}»
  'To restart this instance, please enter your password:': 'To restart this instance, please enter your password:', // «Pour redémarrer cette instance, saisissez votre mot de passe :»
  'Stop': 'Stop', // «Arrêter»
  'To stop this instance, please enter your password:': 'To stop this instance, please enter your password:', // «Pour arrêter cette instance, saisissez votre mot de passe :»
  'Stop {name}': 'Stop {name}', // «Arrêter {name}»
  'Unknown': 'Unknown', // «Inconnu»
  'Allow/Disallow user\'s connection': 'Allow/Disallow user\'s connection', // «Autoriser / Interdire la connexion de l'utilisateur»
  'Roles': 'Roles', // «Rôles»
  'Invoicing': 'Invoicing', // «Facturation»
  'Business sector': 'Business sector', // «Secteur d'activité»
  'You can paste a SIRET number into this SIREN field to fill it in as well as the NIC field.': 'You can paste a SIRET number into this SIREN field to fill it in as well as the NIC field.', // «Vous pouvez coller un numéro de SIRET dans ce champ SIREN pour le renseigner lui ainsi que le champ NIC.»
  'This SIREN is already used by:': 'This SIREN is already used by:', // «Ce numéro de SIREN est utilisé par :»
  'Orders': 'Orders', // «Commandes»
  'Order': 'Order', // «Commande»
  'To order': 'To order', // «Commander»
  'Order now': 'Order now', // «Passer la commande»
  'Confirm the order of quotation {name}?': 'Confirm the order of quotation {name}?', // «Confirmez-vous le passage de la commande pour le devis {name} ?»
  'Order waiting for validation.': 'Order waiting for validation', // «Commande en attente de validation.»
  'Order in building.': 'Order in building', // «Commande en construction.»
  'Order canceled.': 'Order canceled', // «Commande annulée.»
  'Order completed.': 'Order completed', // «Commande livrée.»
  'Order ID': 'Order ID', // «Numéro de commande»
  'Order progress:': 'Order progress:', // «État de la commande :»
  'Begin: {date}': 'Begin: {date}', // «Démarrage : {date}»
  'End: {date}': 'End: {date}', // «Fin : {date}»
  'validating': 'validating', // «En attente de validation»
  'Waiting': 'Waiting', // «En attente»
  'Pending': 'Building', // «En construction»
  'Repending': 'Rebuilding', // «Évolution en cours»
  'Canceled': 'Canceled', // «Annulé»
  'Stopped': 'Canceled', // «Annulé'»
  'Completed': 'Completed', // «Délivré»
  'Technical validation': 'Technical validation', // «Validation technique»
  'Financial validation': 'Financial validation', // «Validation financière»
  'Latest orders & quotations': 'Latest orders & quotations', // «Commandes & devis récents»
  'Last saved quotations': 'Last saved quotations', // «Devis récents»
  'action-Order': 'action-Order', // «Commander»
  'state-validating': 'Validating', // «En attente de validation»
  'state-waiting': 'Waiting', // «En attente»
  'state-tech-awaiting': 'Waiting for technical informations', // «En attente d\'informations techniques»
  'state-deposit-awaiting': 'Waiting for paid deposit', // «En attente d'acompte versé»
  'state-pending': 'Building', // «En construction»
  'state-re-pending': 'Rebuilding', // «En reconstruction»
  'state-stopping': 'Stopping', // «Arrêt programmé»
  'state-stopped': 'Stopped', // «En pause»
  'state-canceled': 'Canceled', // «Annulé»
  'state-completed': 'Completed', // «Délivré»
  'Be careful: Payment is blocked by this company! Do not issue any order!': 'Be careful: Payment is blocked by this company! Do not issue any order!', // «Attention : Paiement bloqué par cette société ! Ne plus passer de commande !»
  'Be careful: Payment is blocked by this company! Ticket cannot be saved!': 'Be careful: Payment is blocked by this company! Ticket cannot be saved!', // «Attention : Paiement bloqué par cette société ! Aucun ticket ne pourra être enregistré !»
  'Payment problem, please contact Scalair sales or administrative department.': 'Payment problem, please contact Scalair sales or administrative department.',
  'Use head-quarter address for invoicing.': 'Use head-quarter address for invoicing.', // «Utiliser l'adresse de la société comme adresse de facturation»
  'IBAN': 'IBAN', // «IBAN»
  'BIC': 'BIC', // «BIC»
  'NIC': 'NIC', // «NIC»
  'SIREN': 'SIREN', // «SIREN»
  'SIRET': 'SIRET', // «SIRET»
  'VAT number must be valid!': 'VAT number must be valid!', // «Le numéro TVA doit être valide!»
  'VAT is mandatory!': 'VAT is mandatory!', // «Le numéro de TVA est obligatoire !»
  'E-Mail accounting': 'E-Mail accounting', // «Email de facturation»
  'No product in catalog!|One product in catalog.|Loaded {count} products from catalog.': 'No product in catalog!|One product in catalog.|Loaded {count} products from catalog.', // «Aucun produit au catalogue!|1 produit au catalogue.|{count} produits dans le catalogue.»
  'Once': 'Inclusive', // «Forfait»
  'Hourly': 'Hourly', // «Par heure»
  'Daily': 'Daily', // «Par jour»
  'Weekly': 'Weekly', // «Par semaine»
  'Monthly': 'Monthly', // «Par mois»
  'Quarterly': 'Quarterly', // «Par trimestre»
  'Yearly': 'Yearly', // «Pour 1 an»
  'Bi-annually': 'For 2 years', // «Pour 2 ans»
  'Byannually': 'For 2 years', // «Pour 2 ans»
  'Three-yearly': 'For three years', // «Pour 3 ans»
  '{name}: {price}': '{name}: {price}', // «{name} : {price}»
  'Prices': 'Prices', // «Prix»
  '#': '#', // «N°»
  'Caption': 'Caption', // «Libellé»
  'Price': 'Price', // «Prix»
  'Price: ': 'Price: ', // «Prix : »
  'No product.': 'No product.', // «Aucun produit.»
  'No order.': 'No order.',
  'No quotation.': 'No quotation.',
  'Products': 'Products', // «Produits»
  'Hide academic products': 'Hide academic products', // «Cacher les produits pour les Académies»
  'Included:': 'Included:', // «Inclus :»
  'Total': 'Total', // «Total»
  'Total: ': 'Total: ', // «Total : »
  'Total with discount': 'Total with discount', // «Total avec remise»
  'Total setup': 'Total setup', // «Total d'installation»
  'Total setup with discount': 'Total setup with discount', // «Total d'installation avec remise»
  'Total setup incl. VAT': 'Total setup incl. VAT', // «Total d'installation TTC»
  'Total subscription': 'Total subscription', // «Total d'abonnement»
  'Total subscription with discount': 'Total subscription with discount', // «Total d'abonnement avec remise»
  'Total subscription incl. VAT': 'Total subscription incl. VAT', // «Total d'abonnement TTC»
  'Associated products:': 'Associated products:', // «Produits associés :»
  'Not available': 'Not available', // «Non disponible»
  'Hide products that are not available': 'Hide products that are not available', // «Cacher les produits non disponibles»
  'section-main': 'section-main', // «Propriété du produit»
  'on selected company | on {count} companies': 'on selected company | on {count} companies', // «sur la société sélectionnée | sur {count} sociétés»
  'on selected quotation | on {count} quotations': 'on selected quotation | on {count} quotations', // «sur le devis sélectionné | sur {count} devis»
  'Category: {category}': 'Category: {category}', // «Catégorie : {category}»
  'Issuer: {lastname} {firstname}\nEmail: {email} Company: { company } ': 'Issuer: { lastname } { firstname } \nEmail: { email } Company: { company } ', // «Émetteur : {lastname} {firstname} Email: { email } Société: { company } »
  'Secrets': 'Secrets', // «Secrets»
  'Close': 'Close', // «Fermer»
  '{ description } — {ref}': '{ description } — {ref}', // «{ description } — {ref}»
  'Sub-product discount': 'Sub-product discount', // «Remise sous-produit»
  'You don\'t have any quotation yet.': 'You don\'t have any quotation yet.', // «Vous n'avez pas encore de panier ou de commande !»
  'When you do, their status will be visible here.': 'When you do, their status will be visible here.', // «Leurs états s'afficheront ici quand vous en aurez enregistré.»
  'Payment method': 'Payment method', // «Moyen de paiement»
  'Payment term': 'Payment term', // «Échéance de paiement»
  'Newsletters': 'Newsletters', // «Lettres d'information»
  'Role': 'Role', // «Role»
  'Last authentication: {date}': 'Last authentication: {date}', // «Dernière authentification le {date}»
  'Last authentication': 'Last authentication', // «Dernière authentification»
  'Never logged in.': 'Never logged in.',
  'Generate': 'Generate', // «Générer»
  'Add to cart': 'Add to cart', // «Ajouter au panier»
  'Discount': 'Discount', // «Réduction»
  'Discount %': 'Discount %', // «% Réduction»
  'Installation discount %': 'Installation discount %', // «% Réduction d'installation»
  'Subscription discount %': 'Subscription discount %', // «% Réduction d'abonnement»
  'Subscription price: ': 'Subscription price: ', // «Prix d'abonnement : »
  'Subscription price': 'Subscription price', // «Prix d'abonnement : »
  'Subscr. margin: ': 'Subscr. margin: ', // «Marge abo. : »
  'Setup price': 'Setup price', // «Prix d'installation»
  'Setup price: ': 'Setup price: ', // «Prix d'installation : »
  'Setup margin: ': 'Setup margin: ', // «Marge d'installation : »
  'Buying price': 'Buying price', // «Prix d'achat»
  'Margin subscription: ': 'Margin subscription: ', // «Marge abonnement : »
  'Margin subscription %': 'Margin subscription %', // «% Marge»
  'Margin': 'Margin', // «Marge»
  'Product catalog': 'Product catalog', // «Catalogue des produits»
  'All products': 'All products', // «Tous les produits»
  '{quantity} × {product} added to cart.': '{quantity} × {product} added to cart.', // «{quantity} × {product} ajouté au panier.»
  'There is no cart!': 'There is no cart!', // «Aucun panier !»
  'Billing start date': 'Billing start date', // «Début de facturation»
  'Billing end date': 'Billing end date', // «Fin de facturation»
  'Not started yet': 'Not started yet', // «Non commencé»
  'Not ended yet': 'Not ended yet', // «Non terminé»
  'Progression': 'Progression', // «Progression»
  '(copy)': '(copy)', // «(copie)»
  'Cart cloned.': 'Cart cloned.', // «Panier dupliqué.»
  'Sphere cloned.': 'Sphere cloned.', // «Projet dupliqué.»
  'User cloned.': 'User cloned.', // «Utilisateur dupliqué.»
  'Choose...': 'Choose...', // «Choisissez…»
  'Order number': 'Your order number', // «Numéro de commande»
  'Add some important informations about your quotation...': 'Add some important informations about your quotation...', // «Ajouter toutes les informations que vous jugez nécessaires pour ce devis…»
  '{percent}%': '{percent}%', // «{percent} %»
  '#Order': '#Order', // «N° Commande»
  'Date': 'Date', // «Date»
  'Authorizations': 'Authorizations', // «Autorisations»
  'Update Order': 'Update Order', // «Modifier la commande»
  'Reference': 'Reference', // «Référence»
  'No filter': 'No filter', // «Aucun filtre»
  'Hide products at end of subscription': 'Hide products at end of subscription', // «Cacher les produits en fin de souscription»
  '(Price excl. VAT)': '(Price excl. VAT)', // «(Prix H.T.)»
  '(Price incl. VAT)': '(Price incl. VAT)', // «(Prix T.T.C.)»
  'Total incl. VAT': 'Total incl. VAT', // «Total T.T.C.»
  'Price excluding VAT': 'Price excluding VAT', // «Prix H.T.»
  'Price including VAT': 'Price including VAT', // «Prix T.T.C.»
  'VAT': 'VAT', // «T.V.A.»
  'excl.VAT': 'excl.VAT', // «H.T.»
  'incl.VAT': 'incl.VAT', // «T.T.C.»
  'New cart': 'New cart', // «Nouveau panier»
  'Quotations list': 'Quotations list', // «Liste des devis»
  'Creating a new cart will empty the current one. Do you saved you cart «{name}»?': 'Creating a new cart will empty the current one. Do you saved you cart «{name}»?', // «Créer un nouveau panier va vider celui qui est chargé. Avez-vous enregistré votre panier « {name} » ?»
  'This is the current quotations.': 'This is the current quotations.', // «Ce devis est le devis courant.»
  '[CoDD] Error reading products catalog.': '[CoDD] Error reading products catalog.', // «[CoDD] Erreur de lecteur du catalogue produits.»
  'Signature by mail': 'Signature by mail', // «Signature par mail»
  'Electronic signature': 'Electronic signature', // «Signature électronique»
  'Terms of your contract': 'Terms of your contract', // «Votre contrat»
  'This is your first order. Please take some time to verify that the following informations are correct. If not, you can modify them right now.': 'This is your first order. Please take some time to verify that the following informations are correct. If not, you can modify them right now.', // «C'est votre première commande. Merci de bien vouloir prendre le temps de vérifier que les informations suivantes sont correctement renseignées. Vous pouvez les rectifier ci-dessous.»
  'When you will order, this quotation will be recorded as an order and sent to our teams.': 'When you will order, this quotation will be recorded as an order and sent to our teams.', // «Lorsque vous passerez commande, ce devis sera enregistré en tant que commande puis envoyé à nos équipes.»
  'E-Mail accounts': 'E-Mail accounts', // «Comptes mails»
  'Sphere {name} created.': 'Sphere {name} created.', // «Projet {name} créé.»
  'Sphere {name} updated.': 'Sphere {name} updated.', // «Projet {name} modifié.»
  'Please contact your Sales manager at Scalair.': 'Please contact your Sales manager at Scalair.', // «Merci de contacter votre responsable commercial Scalair.»
  'Before ordering, one product needs your attention.|Before ordering, {count} products need your attention.': 'Before ordering, one product needs your attention.|Before ordering, {count} products need your attention.', // «Avant de commander, un product requiert votre attention.|Avant de commander, {count} produits requièrent votre attention.»
  'This free field allows you to enter an internal order number, and thus better identify this future order and allow you to better follow it.': 'This free field allows you to enter an internal order number, and thus better identify this future order and allow you to better follow it.', // «Ce champ libre vous permet de rentrer un numéro de commande interne, et donc mieux identifier cette future commande pour vous permettre de mieux la suivre.»
  'Spheres allow you to generate multiple invoices. To create and manage spheres, go to his space, in the administration tab!': 'Spheres allow you to generate multiple invoices. To create and manage spheres, go to his space, in the administration tab!', // «Les projets vous permettent de générer plusieurs factures. Pour créer et gérer les projets, rendez-vous dans son espace, dans l'onglet administration !»
  'Spheres allow you to better organize your products. Each sphere will generate a clean invoice. You have the possibility for each order to choose a sphere directly. If you later want to change the subscriptions contained in a sphere order, this will also be possible from the instances & other products tab.': 'Spheres allow you to better organize your products. Each sphere will generate a clean invoice. You have the possibility for each order to choose a sphere directly. If you later want to change the subscriptions contained in a sphere order, this will also be possible from the instances & other products tab.', // «Les projets vous permettent d'organiser au mieux vos produits.Chaque projet génèrera une facture propre. Vous avez la possibilité à chaque commande de choisir un projet directement. Si par la suite vous voudriez changer les abonnements contenus dans une commande de projet, ça sera possible également depuis l'onglet instances & autres produits.»
  'Welcome to our catalog. You will find below our entire product catalog. It is organized into themes. For more information, do not hesitate to get closer to our teams.': 'Welcome to our catalog. You will find below our entire product catalog. It is organized into themes. For more information, do not hesitate to get closer to our teams.', // «Bienvenue dans notre catalogue. Vous trouverez ci dessous l'ensemble de notre catalogue produits. Il est organisé en thèmes. Pour plus d'informations, n'hésitez pas à vous rapprocher près de nos équipes.»
  'These roles limit the possibilities available to your accounts:': 'These roles limit the possibilities available to your accounts:', // «Ces rôles permettent de limiter les possibilités offertes à vos comptes :»
  'The administrator can do everything: create other users, fully use supervision, access to the catalog price and ordering process, etc.': 'The administrator can do everything: create other users, fully use supervision, access to the catalog price and ordering process, etc.', // «L'administrateur peut tout faire : créer d'autres utilisateurs, utiliser pleinement la supervision, accès au catalogue tarifaire et à tout le processus de commande, etc.»
  'The business can carry out business actions: see all prices, have access to invoices, validate quotations.': 'The business can carry out business actions: see all prices, have access to invoices, validate quotations.', // «Le business peut réaliser des actions commerciales : voir tous les prix, avoir accès aux factures, valider les devis.»
  'The project manager can do technical actions and place orders: from the technical team, he has the same permissions as the technicians, and can place orders in addition.': 'The project manager can do technical actions and place orders: from the technical team, he has the same permissions as the technicians, and can place orders in addition.', // «Le chef de projet peut réaliser des actions techniques et passer des commandes : issue de l'équipe technique, il a les mêmes permissions que les techniciens, et peut passer des commandes en plus.»
  'The accountant can do actions in relation to accounting : access only to invoices and contracts.': 'The accountant can do actions in relation to accounting : access only to invoices and contracts.', // «Le comptable peut réaliser des actions rapport à la facturation : il peut accéder uniquement aux factures et aux contrats.»
  'The technician can perform technical actions: fully use the supervision, list subscriptions and create quotation. He does not have the right to place an order.': 'The technician can perform technical actions: fully use the supervision, list subscriptions and create quotation. He does not have the right to place an order.', // «Le technicien peut réaliser des actions techniques : utiliser pleinement la supervision, lister les souscriptions et réaliser des devis. Il ne peut pas passer commande.»
  'The spectator cannot do anything but observe : has access to the Dashboard and the Manager in read-only mode, he can access the supervision, see the prices, see the orders, etc. However, he cannot take action on the applications.': 'The spectator cannot do anything but observe : has access to the Dashboard and the Manager in read-only mode, he can access the supervision, see the prices, see the orders, etc. However, he cannot take action on the applications.', // «Le spectateur ne peut réaliser d'autre d'action que de voir : il a accès au Dashboard et au Manager en mode lecture seul, il peut accéder à la supervision, voir les prix, voir les commandes, etc. Cependant il ne peut pas réaliser d'action sur les applications.»
  'The pure technician is a role with access to all technical tools without seeing prices: he can see subscriptions, open tickets and have access to supervision.': 'The pure technician is a role with access to all technical tools without seeing prices: he can see subscriptions, open tickets and have access to supervision.',
  'The supervisor is a role with access to supervision only.': 'The supervisor is a role with access to supervision only.',
  'Validations': 'Validations', // «Validations»
  'Validate': 'Validate', // «Valider»
  'Deliver': 'Deliver', // «Livrer»
  'Confirm validation': 'Confirm validation', // «Confirmez la validation»
  'Validate is an irreversible step, do you confirm the validation?': 'Validate is an irreversible step, do you confirm the validation?', // «Valider est une étape irréversible, confirmez-vous la validation ?»
  'Confirm delivery': 'Confirm delivery', // «Confirmez la livraison»
  'Display this product.': 'Display this product.', // «Afficher le produit»
  'Mark this product as delivered.': 'Mark this product as delivered.', // «Marquer ce produit comme étant livré.»
  'Deliver the remaining product...|Deliver remaining {count} products...': 'Deliver the remaining product...|Deliver remaining {count} products...', // «Livrer le produit restant|Livrer les {count} produits restant…»
  'Cancel the product order.': 'Cancel the product order.', // «Annuler la livraison de ce produit.»
  'Do you confirm the cancellation of the order of the product "{name}"?': 'Do you confirm the cancellation of the order of the product "{name}"?', // «Confirmez-vous l\'annulation de la commande du produit {name} ?'»
  'Complete this product': 'Complete this product', // «Livraison du produit»
  'Cost: ': 'Cost: ', // «Frais : »
  'Stop this product': 'Stop this product', // «Arrêt du produit»
  'margin-O: ': 'Margin inclusive: ', // «Marge forfait : »
  'margin-O-%: ': 'Margin inclusive %: ', // «Marge forfait : »
  'off|Used for one day|Used for {count} days': 'off|Used for one day|Used for {count} days', // Non utilisé|Utilisé une journée|Utilisé {count} jours»
  'Invoices': 'Invoices', // «Factures»
  'Invoice': 'Invoice', // «Facture»
  'Saving your cart...': 'Saving your cart...', // «Enregistrement de votre panier…»
  'Updating cart data...': 'Updating cart data...', // «Mise à jour des informations du panier…»
  'Order updated.': 'Order updated.', // «La commande a été mise à jour.»
  'Update this product': 'Update this product', // «Mettre à jour le produit»
  'Modify or stop product': 'Modify or stop product', // «Modifier ou arrêter le produit»
  'Modify quantity or other properties': 'Modify quantity or other properties', // «Modifier la quantité ou d'autres propriétés»
  'Stop subscription': 'Stop subscription', // «Arrêter la souscription»
  'Stop product': 'Stop product', // «Arrêter le produit»
  'Please choose the number of product that you want to stop:': 'Please choose the number of product that you want to stop:', // «Indiquez le nombre de produit à arrêter :»
  'Installation Discount %': 'Installation Discount %', // «% remise installation»
  'All product stopped|One product remains|{count} products remain': 'All product stopped|One product remains|{count} products remain', // «Tous les produits seront arrêtés|Un seul produit restant|{count} produits restant»
  'Select the date to stop the product:': 'Select the date to stop the product:', // «Sélectionnez la date d'arrêt du produit :»
  'Select the date to apply the modifications:': 'Select the date to apply the modifications:', // «Sélectionnez la date d'application des modifications :»
  'The order must be validated in order to be processed by our teams.': 'The order must be validated in order to be processed by our teams.', // «La commande doit être validée afin de pouvoir être traitée par nos équipes.»
  'Apply': 'Apply', // «Appliquer»
  'What do you want to do?': 'What do you want to do?', // «Que voulez-vous faire ?»
  'Start this product': 'Start this product', // «Démarrer le produit»
  'Product update': 'Product update', // «Modification de produit»
  'Confirm the modifications?': 'Confirm the modifications?', // «Confirmez-vous les modifications ?»
  'Product stop': 'Product stop', // «Arrêt de produit»
  'Confirm the stop?': 'Confirm the stop?', // «Confirmez-vous l'arrêt ?»
  'Favorites': 'Favorites', // «Favoris»
  'Add to your favorites': 'Add to your favorites', // «Ajouter à vos favoris»
  'Remove from your favorites': 'Remove from your favorites', // «Retirer de vos favoris»
  'Add same product to this quotation': 'Add same product to this quotation', // «Dupliquer ce produit»
  'Building': 'Building', // «En construction»
  'Rebuilding': 'Rebuilding', // «Évolution en cours»
  'If this switch is on, we will send one billing for each sphere. Otherwise, every product of every sphere will be send in one billing.': 'If this switch is on, we will send one billing for each sphere. Otherwise, every product of every sphere will be send in one billing.', // «Activer la facturation par projet signifie que le client recevra autant de facture que de projets. Le cas échéant, une seule facture, regroupant tous les produits de tous les projets sera émise.»
  'Margin subscription %: ': 'Margin subscription %: ', // «% Marge abonnement : »
  'Margin: ': 'Margin: ', // «Marge : »
  'Permanently stop product': 'Permanently stop product', // «Arrêter définitivement le produit»
  'Permanently stop all products': 'Permanently stop all products', // «Arrêter définitivement tous les produits»
  'Permanent product stop': 'Permanent product stop', // «Arrêt définitif de produit»
  'Confirm the permanent stop?': 'Confirm the permanent stop?', // «Confirmez-vous l'arrêt définitif ?»
  '(Date is undefined)': '(Date is undefined)', // «(Date non renseignée)»
  'Download this invoice.': 'Download this invoice.', // «Télécharger la facture.»
  'Modify this quotation.': 'Modify this quotation.', // «Modifier le devis.»
  'Display this order.': 'Display this order.', // «Afficher la commande.»
  'Documents': 'Documents', // «Documents»
  'This folder contains your invoices and other precious documents.': 'This folder contains your invoices and other precious documents.', // «Ce dossier contient vos factures et autres documents précieux.»
  'Select a File': 'Select a File', // «Sélectionnez in fichier»
  'Select a file or a folder': 'Select a file or a folder', // «Sélectionnez un fichier ou un dossier»
  'Download': 'Download', // «Télécharger»
  'Name on invoices': 'Name on invoices', // «Nom pour les factures»
  'IBAN is mandatory!': 'IBAN is mandatory!', // 'IBAN est obligatoire !'
  'BIC is mandatory!': 'BIC is mandatory!', // 'BIC est obligatoire !'
  'BIC or IBAN is mandatory!': 'BIC or IBAN is mandatory!', // 'IBAN ou BIC est obligatoire !'
  'Your order has been saved. Before being taken into account by our teams, we kindly ask you to validate it financially, and technically by members of your company having the rights necessary for this purpose.': 'Your order has been saved. Before being taken into account by our teams, we kindly ask you to validate it financially, and technically by members of your company having the rights necessary for this purpose.', // «Votre commande a été enregistrée. Avant d\'être prise en compte par nos équipes, nous vous demandons de bien vouloir la valider financièrement, et techniquement par des membres de votre entreprise ayant les droits nécessaires à cet effet.»
  'Important!': 'Important!', // «Important !»
  'History': 'History', // «Historique»
  'Currently': 'Currently', // Actuellement»
  'Estimated billing': 'Estimated billing', // «Estimation de facturation»
  'Evolution of subscriptions': 'Evolution of subscriptions',
  'This is an estimate of your monthly product billing by sector.': 'This is an estimate of your monthly product billing by sector.', // «Il s'agit d'une estimation de la facturation de vos produits mensuels par secteur.»
  '{label} — {value}': '{label} — {value}', // «{label} — {value}»
  'Preferences': 'Preferences', // «Préférences»
  'Use dark theme': 'Use dark theme', // «Utiliser le thème sombre»
  'Use light theme': 'Use light theme', // «Utiliser le thème clair»
  'Use small application drawer': 'Use small application drawer', // «Utiliser un menu d\'application réduit»
  'Your preferences has been saved.': 'Your preferences has been saved.', // «Vos préférences ont été enregistrées.»
  'State modified by': 'State modified by', // «État modifié par»
  'Customer caption': 'Customer caption', // «Libellé client»
  'Tickets:': 'Tickets:', // «Tickets :»
  'Quotation for:': 'Quotation for:', // «Devis pour :»
  'Quotation': 'Quotation', // «Devis»
  'Quotation: {name}': 'Quotation: {name}', // «Devis : {name}»
  'You are engaged on this product until { date }.': 'You are engaged on this product until { date }.', // «Vous êtes engagé sur ce produit jusqu\'au {date}.'»
  'This complete stop of this product takes {days} notice days. You can program the stop of this product from {date}.': 'This complete stop of this product takes {days} notice days. You can program the stop of this product from {date}.', // «L'arrêt complet de ce produit nécessite {days} jours de préavis. Vous pouvez programmer l'arrêt de ce produit à partir du {date}.»
  'You will be able to stop it definitively after this deadline.': 'You will be able to stop it definitively after this deadline.', // «Vous pourrez l\'arrêter définitivement après cette échéance.»
  'This deadline has passed, so you can definitively stop this product if you wish.': 'This deadline has passed, so you can definitively stop this product if you wish.', // «Cette échéance est passée et vous pouvez donc arrêter définitivement ce produit si vous le désirez.»
  'List of news letters subscriptions.  You will receive the activated ones.': 'List of news letters subscriptions. You will receive the activated ones.', // «Liste des souscriptions aux lettres d\'information. Vous recevrez celles qui sont activées.»
  'Connect as...': 'Connect as...', // «Se connecter en tant que…»
  'Choose a user...': 'Choose a user...', // «Choisissez un utilisateur…»
  'Please choose the user you want to connect as. Only user accounts are shown, bot account are not allowed.': 'Please choose the user you want to connect as. Only user accounts are shown, bot account are not allowed.', // «Choisissez l\'utilisateur dont vous voulez emprunter le compte. Les comptes de type automate ne sont pas autorisés.»
  'Returning to your account.': 'Returning to your account.', // «Retour à votre compte.»
  'Error connecting as {name}!': 'Error connecting as {name}!', // «Erreur de connexion en tant que {name} !»
  'Expires on': 'Expires on', // «Expire le»
  'Out-of-date': 'Expired', // «Expiré»
  'Expires on {date}': 'Expires on {date}', // «Date d'\expiration : {date}»
  'Periodicity': 'Periodicity', // «Type d'abonnement»
  'periodicity-long-strict': 'Periodicity',
  'For now your periodicity is {periodicity}': 'For now your periodicity is {periodicity}', // «Pour le moment, vous avez un {periodicity}»
  'Please choose the new periodicity of your product:': 'Please choose the new periodicity of your product:', // «Choisissez un nouveau type d'abonnement pour votre produit :»
  'Modify products\' periodicity': 'Modify products\' periodicity', // «Modifier le type d'abonnement»
  'Modify product\'s properties': 'Modify product\'s properties', // «Modifier les propriétés du produits»
  'Please choose your new properties of your product:': 'Please choose your new properties of your product:', // «Choisissez les nouvelles propriétés de votre produit :»
  'Permanently stop a part of all products': 'Permanently stop a part of all products', // «Arrêter définitivement une partie des produits»
  'Please choose the new quantity wanted:': 'Please choose the new quantity wanted:', // «Indiquez la nouvelle quantité voulue :»
  'No product stopped|One product stopped|{count} products stopped': 'No product stopped|One product stopped|{count} products stopped', // «Aucun produit ne sera arrêté définitivement|Un seul produit s'arrête|{count} produits s'arrêtent»
  'All product stopped': 'All product stopped', // «Tous les produits seront arrêtés définitivement»
  'You are engaged on this product until {date}.': 'You are engaged on this product until {date}.', // «Vous êtes engagé sur ce produit jusqu'au {date}.»
  'List of news letters subscriptions. You will receive the activated ones.': 'List of news letters subscriptions. You will receive the activated ones.', // «Liste des souscriptions aux lettres d'information. Vous recevrez celles qui sont activées.»
  'This quotation expires on {date}.': 'This quotation expires on {date}.', // «Ce devis a expiré le {date}»
  'Expired|Expire tomorrow|Expires in {d} days': 'Expired|Expire tomorrow|Expires in {d} days', // «Expiré|Expire demain|Expire dans {d} jours»
  'Folder': 'Folder', // «Dossier»
  'Send a document': 'Send a document', // «Envoyer un document»
  'Send documents': 'Send documents', // «Envoyer des documents»
  'Confirm uploading {count} file(s)?': 'Confirm uploading {count} file(s)?', // «Confirmez-vous l\'envoi de {count} fichiers(s) ?»
  'File {name} deleted.': 'File {name} deleted.', // «Fichier {name} supprimé.»
  'Delete file': 'Delete file', // «Supprimer le fichier»
  'Confirm deletion of file «{name}»?': 'Confirm deletion of file «{name}»?', // «Confirmez-vous la suppression du fichier « {name} » ?»
  'Delete folder': 'Delete folder', // «Supprimer le dossier»
  'Confirm deletion of folder «{name}»?': 'Confirm deletion of folder «{name}»?', // «Confirmez-vous la suppression du fichier « {name} » ?»
  'Retry': 'Retry', // «Réessayer»
  'Apply customer discounts': 'Apply customer discounts', // «Appliquer les réductions clients»
  'Payment information': 'Payment information',
  'Billing Address': 'Billing address',
  'Billing address': 'Billing address', // «Adresse (facturation)»
  'Billing complement': 'Billing complement', // «Complément (facturation)»
  'Billing town': 'Billing town', // «Localité (facturation)»
  'Billing zip code': 'Billing zip code', // «Code postal (facturation)»
  'Billing country': 'Billing country', // «Pays (facturation)»
  'Spheres allow you to organize your quotations and orders.': 'Spheres allow you to organize your quotations and orders.', // «Les projets vous permettent d\'organiser vos devis et commandes.»,
  'Create folder:': 'Create folder:', // «Créer le dossier :»
  'Create folder here': 'Create folder here', // «Créer un dossier ici»
  'Folder {name} created.': 'Folder {name} created.', // «Le dossier {name} a été créé.»
  'An error occurred!': 'An error occurred!', // «Une erreur est survenue !»
  'Rotate': 'Rotate', // «Pivoter»
  'Document': 'Document',
  'Status': 'Status',
  'End\'s date:': 'End\'s date:', // Date de fin :»
  'This contract is ongoing.': 'This contract is ongoing.', // «Ce contrat est en cours»
  'A contract with number {n} already exists!': 'A contract with number {n} already exists!', // «Un contrat numéro {n} existe déjà !»
  'List': 'List', // «Liste»
  'Tree': 'Tree', // «Arborescence»
  'Search for customers': 'Search for customers', // «Rechercher un client»
  'Not started': 'Not started', // «Non commencé»
  'Finished': 'Finished', // «Terminé»
  'Document «{doc}» for this contract is missing!': 'Document «{doc}» for this contract is missing!', // «Le document «{doc}» associé à ce contrat est manquant !»
  'To search for a contract number or other information, use the list tab.': 'To search for a contract number or other information, use the list tab.', // «Pour chercher un numéro de contrat ou d'autres informations, utiliser l'onglet liste.»
  'To be signed': 'To be signed', // «À signer»
  'Sign this contract...': 'Sign this contract...', // «Signer ce contrat…»
  'This contract must be signed to take effect.': 'This contract must be signed to take effect.', // «Ce contrat doit être signé pour entrer en vigueur.»
  'Sign': 'Sign', // «Signer»
  'Finish': 'Finish', // «Terminer»
  'Finish this contract...': 'Finish this contract...', // «Terminer ce contrat…»
  'One product needs a caption.|{count} products need a caption.': 'One product needs a caption.|{count} products need a caption.', // «Un produit requiert son libellé.|{count} produits requièrent leurs libellés.»
  'The caption is mandatory!': 'The caption is mandatory!', // «Le libellé est obligatoire !»
  'Display document': 'Display document', // «Afficher le document»
  'CMDB': 'CMDB', // «CMDB…»
  'CMDB Extract': 'CMDB Extract', // «Extrait de CMDB»
  'Show product references instead of product names': 'Show product references instead of product names', // «Montrer les références des produit au lieu de leurs noms»
  'Your CMDB will be converted to a CSV file. You will quickly receive an email with it. Please confirm.': 'Your CMDB will be converted to a CSV file. You will quickly receive an email with it. Please confirm.', // «Votre CMDB sera convertie en un fichier CSV qui va vous être envoyé par email rapidement. Confirmez-vous l\'envoi ?»
  'Choose the companies to extract their CMDB in CSV format. You will quickly receive an email with it.': 'Your CMDB will be converted to a CSV file. You will quickly receive an email with it.', // «Choisissez les sociétés pour extraire leurs CMDB au format CSV. Ce fichier vous sera envoyé par email rapidement. Confirmez-vous l\'envoi ?»
  'The extract is being created...': 'The extract is being created...', // «L\'extrait est en cours de création…»
  'List of people to be notified by email about the progress of the order': 'List of people to be notified by email about the progress of the order', // «Liste des personnes à avertir par mail de l'avancement de la commande»
  '{name} prices': '{name} prices', // «Tarifs {name}»
  'Purchase chances': 'Purchase chances', // «Probabilité d'achat»
  'Potential closing date': 'Potential closing date', // «Date potentielle de closing»
  'Hide products description': 'Hide products description', // «Cacher les descriptions»
  'Hide linked products': 'Hide linked products', // «Cacher les produits associés»
  'Declaration date': 'Declaration Date', // «Déclaré le»
  'Validated': 'Validated', // «Validé»
  'Validation date': 'Validation date', // «Validé le»
  'Validated by': 'Validated by', // «Validé par»
  'Validated at {date}': 'Validated at {date}', // «Validé le {date}»
  'Current quantity': 'Current Quantity', // «Quantité actuelle»
  'Costs': 'Costs', // «Coûts»
  'No caption': 'No caption', // «Aucun libellé»
  'Sales Persons': 'Sales Persons', // «Commerciaux»
  'Sales Person': 'Sales Person', // «Commercial»
  'VAT is mandatory for customers of type "{mandatory}". Excluding "{excluding}" business sectors.': 'VAT is mandatory for customers of type "{mandatory}". Excluding "{excluding}" business sectors.', // «La TVA est obligatoire pour les clients de type «{mandatory}». Mais pas pour le(s) secteur(s) d\'activité(s) «{excluding}».»
  'Hide tickets': 'Hide tickets', // «Cacher les tickets»
  'status-Hide tickets. You can change this in the grid options.': 'Tickets are hidden. You can change this in the grid options.', // «Les tickets ne sont pas affichés»
  'Order same product': 'Order same product', // «Commander un produit identique»
  'Do you want to add product «{pname}» to {cname}?': 'Do you want to add product «{pname}» to {cname}?', // «Voulez-vous ajouter le produit « {pname} » à {cname} ?»
  'a new cart': 'a new cart', // «un nouveau devis»
  'New cart {name} created.': 'New cart {name} created.', // «Nouveau devis {name} créé.»
  'Historic updates': 'Historic updates',
  'Commercials': 'Commercials',
  'Opportunity': 'Opportunity',
  'Opportunities': 'Opportunities',
  'Salesperson': 'Salesperson',
  'Price Subs. - Setup': 'Price Subs. - Setup',
  'Percentage of success': 'Percentage of success',
  'Opportunity\'s order': 'Opportunity\'s order',
  'Opportunity\'s order\'s lines': 'Opportunity\'s order\'s lines',
  'Prices:': 'Prices:',
  'Discount:': 'Discount:',
  'Total subscription:': 'Total subscription:',
  'Total subscription with discount:': 'Total subscription with discount:',
  'Margin subscription:': 'Margin subscription:',
  'Discount setup:': 'Discount setup:',
  'Total setup:': 'Total setup:',
  'Total setup with discount:': 'Total setup with discount:',
  'Margin setup:': 'Margin setup:',
  'Margin setup': 'Margin setup',
  'Opportunity {name} updated.': 'Opportunity {name} updated.',
  'Opportunity {name} created.': 'Opportunity {name} created.',
  'Your request will be processed immediately.': 'Your request will be processed immediately.', // «Votre demande sera traitée immédiatement.»
  'To stop this product, please contact Scalair customer support.': 'To stop this product, please contact Scalair customer support.', // «Pour arrêter ce produit, merci de contacter le support client Scalair.»
  'You cannot stop some quantity of this product. To stop all quantities of this product, please go to "Permanently stop all products".': 'You cannot stop some quantity of this product. To stop all quantities of this product, please go to "Permanently stop all products".', // «Impossible d'arrêter d'autre produit. Pour arrêter définitivement tous les produits, cliquez sur "Arrêter définitivement tous les produits"»
  'Closing date': 'Closing date', // «Date de closing»
  'opp-state-qualification': 'Qualification',
  'opp-state-proposal': 'Proposal',
  'opp-state-won': 'Won',
  'opp-state-lost': 'Lost',
  'opp-state-abandoned': 'Abandoned',
  'Percentage of subscription discount:': 'Percentage of subscription discount:', // «% de remise sur l\'abonnement :»
  'Percentage of setup discount:': 'Percentage of setup discount:', // «% de remise sur l\'installation :»
  'Opportunity...': 'Opportunity...', // «Opportunité…»
  'Create opportunity': 'Create opportunity', // «Créer une opportunité»
  'Update opportunity': 'Update opportunity', // «Modifier l\'opportunité»
  'Take the lead': 'Take the lead',
  'Opportunity lost': 'Opportunity lost',
  'Opportunity canceled': 'Opportunity canceled',
  'Abandon opportunity': 'Abandon opportunity',
  'Abandoning the opportunity is irreversible. Please confirm?': 'Abandoning the opportunity is irreversible. Please confirm?',
  'Opportunity is won': 'Opportunity is won',
  'You must provide an accounting family!': 'You must provide an accounting family!',
  'Winning the opportunity is irreversible. Please confirm?': 'Winning the opportunity is irreversible. Please confirm?',
  'Opportunity {name} has been updated.': 'Opportunity {name} has been updated.', // «L\'opportunité {name} a été mise à jour.»
  'Rates': 'Rates', // «Tarifs»
  'Remove opportunity': 'Remove opportunity', // «Retirer l\'opportunité»
  'This opportunity refers to a command line not found!': 'This opportunity refers to a command line not found!',
  'No history': 'No history',
  'The history below concerns the parent opportunity.': 'The history below concerns the parent opportunity.',
  'Related Opportunity...': 'Related Opportunity...',
  'Please, confirm the deletion of opportunity {id} — {name}?': 'Please, confirm the deletion of opportunity {id} — {name}?',
  'This is a foreign customer. Contact the accounting department for invoicing.': 'This is a foreign customer. Contact the accounting department for invoicing.',
  'Delivered at {date} by': 'Delivered at {date} by', // «Livré le {date} par»
  'Delivered at {date} by {name}.': 'Delivered at {date} by {name}.', // «Livré le {date} par {name}.»
  'Order date': 'Order date',
  'Price trends': 'Price trends',
  'Sales margin': 'Margin', // «Marge commerciale
  'Sales margin%': 'Margin%', // «% Marge commerciale
  'Gross margin': 'Gross margin:', // «Marge brute»
  'Gross margin setup': 'Gross margin setup:', // «Marge brute installation»
  'No user for this company.': 'No user for this company.', // «Aucun utilisateurs pour cette société.»
  'Internal production cost': 'Internal production cost', // «Coût de production»
  'The quantity of this product will not be multiplied by the quantities of its ancestors.': 'The quantity of this product will not be multiplied by the quantities of its ancestors.', // «La quantité de ce produit ne sera pas modifiée par les quantités de ses ascendants.»
  'New total:': 'New total:', // «Nouveau total :»
  'Total before changes:': 'Total before changes:', // «Total avant modifications»
  'Scheduler': 'Scheduler', // «Planificateur de tâches»
  'Template': 'Template',
  'Data': 'Data',
  'Scheduling': 'Scheduling',
  'Not run': 'Not run',
  'Last run': 'Last run',
  'Next run': 'Next run',
  'Choose how often this task should be performed.': 'Choose how often this task should be performed.', // «Choisissez la fréquence d\'exécution de cette tâche.»
  'Every hour at minute': 'Every hour at minute', // «Toutes les heures, à la minute»
  'Every day at': 'Every day at', // «Tous les jours à»
  'Every day': 'Every day', // «Tous les jours»
  'Every week on': 'Every week on', // «Toutes les semaines, le»
  'Every month, the': 'Every month, the', // «Tous les mois, le»
  'Expert': 'Expert', // «Expert»
  'Relaunch if failure': 'Relaunch if failure', // «Relancer is échec»
  'Please, confirm the deletion of task «{caption}»?': 'Please, confirm the deletion of task «{caption}»?',
  'Task template {name} deleted.': 'Task template {name} deleted.',
  'Body': 'Content',
  'If task execution failed, it will relaunch in the same order as first run.': 'If task execution failed, it will relaunch in the same order as first run.', // «Si l\'exécution de la tâche a échoué, elle sera relancée dans le même ordre que lors de la première exécution.»
  'at': 'at',
  'This is a cron format. Click for more details.': 'This is a cron format. Click for more details.', // «C\'est un format cron. Cliquez pour plus d\'informations.»
  'Required field': 'Required field',
  'The product will be stopped after {days} notice.': 'The product will be stopped after {days} notice.',
  'Prices diff:': 'Prices diff:',
  'Is default': 'Is default',
  'Email on error': 'Email on error', // «Email si erreur»
  'Send a mail on error': 'Send a mail on error', // «Envoyer un mail en cas d\'erreur»
  'If an error occurs, a mail will be sent to {email} and copy emails if any.': 'If an error occurs, a mail will be sent to {email} and copy emails if any.', // «En cas d\'erreur, un mail est envoyé à {email} et une copie à la liste des emails en copie.»
  'E-Mail copy': 'E-Mail copy', // «Emails en copie»
  'Domicile of the company': 'Domicile of the company', // «Domiciliation»
  'Additional information': 'Additional information', // «Informations complémentaires»
  'Business': 'Business', // «Activité»
  'Business creation': 'Business creation', // «Activité créée le»
  'Employees': 'Employees', // «Employés»
  'Source': 'Source', // «Provenance»
  'Source details': 'Source details',
  'First contact': 'First contact', // «Premier contact»
  'First contact details': 'First contact details',
  'Two-factor authentication for new users by default': 'Two-factor authentication for new users by default', // «Nouveaux utilisateurs ont la double authentification»
  'Turnover': 'Turnover', // «Chiffre d\'affaire»
  'company-first-contact-phone_prospecting': 'Phone prospecting',
  'company-first-contact-event_meeting': 'Event meeting',
  'company-first-contact-get_in_touch': 'Get in touch',
  'company-first-contact-lead_partner': 'Lead partner',
  'company-first-contact-emailing': 'Emailing',
  'company-first-contact-google_ads': 'Google ads',
  'company-first-contact-kaspr': 'Kaspr',
  'company-first-contact-linkedin': 'LinkedIn',
  'company-first-contact-linkedin_ads': 'LinkedIn ads',
  'company-first-contact-media_coverage': 'Media coverage',
  'company-first-contact-nomination': 'Nomination',
  'company-first-contact-partner': 'Partner',
  'company-first-contact-web_site': 'Website',
  'company-first-contact-networking': 'Networking',
  'company-first-contact-existing_customer': 'Existing customer',
  'Current subscriptions': 'Current subscriptions', // Abonnements en cours»
  'Tickets and cancelled or stopped subscriptions are not in this list.': 'Tickets and cancelled or stopped subscriptions are not in this list.',
  'product|product|products': 'product|product|products',
  'CSM': 'CSM', // «CloudSystem® Manager»
  'Accounting software': 'Accounting software', // «Logiciel de comptabilité»
  'Corporate': 'Corporate', // «Entreprise»
  'Others': 'Others', // «Autres»
  'License': 'Licence', // «Licence»
  'Computer hardware': 'Computer hardware', // «Matériel informatique»
  'Telecom equipment': 'Telecom equipment', // «Équipement télécom»
  'Follow-up': 'Follow-up', // «Suivi des échanges»
  'Link': 'Link', // «Lien réseau»
  'Cancel last update of this line': 'Cancel last update of this line', // «Annuler cette demande»
  'This will remove latest history item, update quantity with history\'s quantity, update state to completed, remove last update\'s tickets and delete it in apps, remove last opportunity ids and update order\'s price. Do you confirm?': 'This will remove latest history item, update quantity with history\'s quantity, update state to completed, remove last update\'s tickets and delete it in apps, remove last opportunity ids and update order\'s price. Do you confirm?', // "La dernière entrée d\'historique de cette ligne sera supprimée, la quantité modifiée, l\'état passé à délivré, les tickets et opportunités concernées seront supprimées et les prix de la commande mis à jour. Confirmez-vous l\'annulation de cette ligne ?»
  'Overwrite file': 'Overwrite file', // «Remplacer le fichier»
  'File «{name}» already exists!': 'File «{name}» already exists!', // «Le fichier «{name}» existe déjà !»
  'File «{name}» already exists. Do you want to overwrite?': 'File «{name}» already exists. Do you want to overwrite?', // «Le fichier «{name}» existe déjà. Voulez-vous le remplacer ?»
  'follow-up-type-call': 'calls',
  'follow-up-type-event': 'events',
  'follow-up-type-email': 'emails',
  'follow-up-type-task': 'tasks',
  'follow-up-type-call-1': 'call',
  'follow-up-type-event-1': 'event',
  'follow-up-type-email-1': 'email',
  'follow-up-type-task-1': 'task',
  'Click to show or hide {name}.': 'Click to show or hide {name}.',
  'Calls': 'Calls',
  'Call': 'Call',
  'in': 'in',
  'out': 'out',
  'Add a call': 'Add a call',
  'Call details...': 'Call details...',
  'Delete call': 'Delete call',
  'Do you want to delete call of {date}?': 'Do you want to delete call of {date}?',
  'Time': 'Time',
  'Emails': 'Emails',
  'Content': 'Content',
  'Raw text': 'Raw text',
  'From: ': 'From: ',
  'To: ': 'To: ',
  'Cc: ': 'Cc: ',
  'Percentage of order\'s deliver accomplishment.': 'Percentage of order\'s deliver accomplishment.',
  'Name of the cart before ordered.': 'Name of the cart before ordered.',
  'Company\'s code.': 'Company\'s code.',
  'Creation\'s date time.': 'Creation\'s date time.',
  'Current state of the order.': 'Current state of the order.',
  'Order\'s ID.': 'Order\'s ID.',
  'Client\'s order\'s number.': 'Client\'s order\'s number.',
  'Subscription\'s total price of the order.': 'Subscription\'s total price of the order.',
  'Subscription\'s total price of the order with discount.': 'Subscription\'s total price of the order with discount.',
  'Setup\'s total price of the order.': 'Setup\'s total price of the order.',
  'Setup\'s total price of the order with discount.': 'Setup\'s total price of the order with discount.',
  'Last update\'s date time.': 'Last update\'s date time.',
  'Attachments': 'Attachments',
  'Unknown user': 'Unknown user',
  'Can authenticate?': 'Can authenticate?',
  'Authentication': 'Authentication',
  '2 factors': '2 factors',
  'Simple': 'Simple',
  'simple': 'simple',
  'Add a value': 'Add a value',
  'Product\'s caption of the oneline.': 'Caption of the product',
  'Order\'s ID': 'Order ID',
  'Company\'s code of the oneline.': 'Company code',
  'Customer product\'s caption of the oneline.': 'Customer caption for the product',
  'Beginning datetime of the oneline.': 'Starting date',
  'Ending datetime of the oneline.': 'Ending date',
  'Ordering datetime.': 'Date of the order',
  'Product\'s name of the oneline.': 'Name of the product',
  'Product\'s percentage of discount of the oneline.': 'Percentage of discount of the product',
  'Product\'s periodicity of the oneline.': 'Periodicity of the product',
  'Product\'s price of the oneline.': 'Price of the product',
  'Product\'s setup\'s price of the oneline.': 'Setup price of the product',
  'Product\'s quantity of the oneline.': 'Quantity of product',
  'Product\'s reference of the oneline.': 'Product reference',
  'Current state of the oneline.': 'Current state of the order line',
  'Product\'s total price of the oneline. Correspond to price multiplied by quantity.': 'Total price excluding discount',
  'Product\'s total price with discount of the oneline.': 'Total price including discount',
  'Product\'s total setup\'s price of the oneline. Correspond to price_setup multiplied by quantity.': 'Total setup price excluding discount',
  'Product\'s total setup\'s price with discount of the oneline.': 'Total price including discount',
  'Product\'s setup\'s price with discount of the oneline.': 'Setup price of the product including discount',
  'Product\'s percentage of discount of setup\'s price of the oneline.': 'Percentage of discount of the product setup price',
  'Company\'s business city adress.': 'Company\'s business city address.',
  'Company\'s business 3 first letter of country adress.': 'Company\'s business 3 first letter of country address.',
  'Company\'s business street adress.': 'Company\'s business street address.',
  'Company\'s business street adress 2.': 'Company\'s business street adress 2.',
  'Company\'s business zipcode adress.': 'Company\'s business zip code adress.',
  'Is company has billings by sphere.': 'Is company has billings by sphere.',
  'Company\'s home city adress.': 'Company\'s home city adress.',
  'Company\'s home 3 first letter of country adress.': 'Company\'s home 3 first letter of country address.',
  'Company\'s home street adress.': 'Company\'s home street adress.',
  'Company\'s home street adress 2.': 'Company\'s home street adress 2.',
  'Company\'s home zipcode adress.': 'Company\'s home zipcode adress.',
  'Company\'s manager ID.': 'Company\'s manager identifier.',
  'Company\'s manager ID 2 (if first manager is already fill).': 'Company\'s second manager identifier.',
  'Salesperson\'s ID of the company. Can be only modified by admin lord or salesperson\'s manager.': 'Salesperson\'s identifier of the company',
  'Company\'s email adress.': 'Company\'s email adress.',
  'Company\'s name.': 'Company\'s name.',
  'Company\'s nic.': 'Company\'s NIC.',
  'Company\'s parent. Default to C1111.': 'Company\'s parent.',
  'Company\'s payment method.': 'Company\'s payment method.',
  'Company\'s payment terms.': 'Company\'s payment terms.',
  'Company\'s phone number.': 'Company\'s phone number.',
  'Company\'s siren.': 'Company\'s siren.',
  'Company\'s type.': 'Company\'s type.',
  'Company\'s vat number.': 'Company\'s VAT number.',
  'Zip code': 'Zip code',
  'No content...': 'No content...',
  'Attendees': 'Attendees',
  'No attendee|One attendee|{count} attendees': 'No attendee|One attendee|{count} attendees',
  'Undefined sales person': 'Undefined sales person',
  'Undefined opportunity': 'Undefined opportunity',
  'Subject': 'Subject',
  'Add an event': 'Add an event',
  'Event location: {location}': 'Event location: {location}',
  'Task': 'Task',
  'Tasks': 'Tasks',
  'Add a task': 'Add a task',
  'task-status-to_do': 'To do',
  'task-status-doing': 'In progress',
  'task-status-done': 'Done',
  'task-priority-low': 'Low',
  'task-priority-medium': 'Medium',
  'task-priority-high': 'High',
  'Due date: {date}': 'Due date: {date}',
  'Due date': 'Due date',
  'Due time': 'Due time',
  'event-type-meeting': 'Meeting',
  'event-type-interview': 'Interview',
  'event-type-show': 'Show',
  'event-type-cold_calling': 'Cold Calling',
  'event-type-lunch': 'Lunch',
  'event-type-other': 'Other',
  'Deleted? (¹)': 'Deleted? (¹)',
  'Show deactivated users': 'Show deactivated users',
  '(¹) You can see all users, even those who have been deleted. Deleted users are in fact simply deactivated.': '(¹) You can see all users, even those who have been deleted. Deleted users are in fact simply deactivated.',
  'Return to quotation': 'Return to quotation',
  'Unknown field type «{type}»!': 'Unknown field type «{type}»!',
  'Schedules List (admin)': 'Schedules List (admin)',
  'Schedules Tasks': 'Schedules Tasks',
  'Reason for cancellation': 'Reason for cancellation',
  'Package': 'Package',
  'Complex products including this reference will not be taken into account when calculating the price with and without discount, and the price including VAT.': 'Complex products including this reference will not be taken into account when calculating the price with and without discount, and the price including VAT.', // «Les produits complexes incluant cette référence ne seront pas pris en compte lors du calcul du prix avec et sans remise, et du prix incluant la TVA.»
  'No price fixed': 'No price fixed',
  'Please fill the price and buying price fields for this product.': 'Please fill the price and buying price fields for this product.',
  'To update price and buying price fields you need to update this product.': 'To update price and buying price fields you need to update this product.',
  'Custom price': 'Custom price',
  'Custom buying price': 'Custom buying price',
  'Quantity (sub-products quantity)': 'Quantity (sub-products qty)',
  'Unique IDs': 'Unique IDs',
  'Comment': 'Comment',
  'Scalair comment': 'Scalair comment',
  'Comment here...': 'Comment here...',
  'Product {ref} not found!': 'Product {ref} not found!',
  'Deposit': 'Deposit',
  'Details of the monthly cost of a powered day:': 'Details of the monthly cost of a powered day:',
  'Deposit percentage': 'Deposit percentage',
  'A deposit will be required before delivering your order.': 'A deposit will be required before delivering your order.',
  'This order is waiting for paid deposit.': 'This order is waiting for paid deposit.',
  'Validate deposit': 'Validate deposit',
  'The deposit has been validated.': 'The deposit has been validated.',
  'You need to input the country to validate the VAT!': 'You need to input the country to validate the VAT!',
  'Include proposal (¹)': 'Include proposal (¹)',
  '(¹) Taking into account opportunities that are not yet on order (in qualification status or proposal). Each calculated price is multiplied by the probability field.': '(¹) Taking into account opportunities that are not yet on order (in qualification status or proposal). Each calculated price is multiplied by the probability field.',
  'graph-opportunity-margin_setup_proposal-percent': 'Setup margin% (¹)',
  'graph-opportunity-margin_proposal-percent': 'Margin% (¹)',
  'graph-opportunity-buying_setup_proposal': 'Setup buying price (¹)',
  'graph-opportunity-buying_proposal': 'Buying price (¹)',
  'graph-opportunity-price_setup_proposal': 'Setup price (¹)',
  'graph-opportunity-price_proposal': 'Price (¹)',
  'graph-opportunity-internal_production_cost_proposal': 'Production cost (¹)',
  'graph-opportunity-internal_production_cost_setup_proposal': 'Setup production cost (¹)',
  'graph-opportunity-gross_margin_setup-percent_proposal': 'Raw setup margin (¹)',
  'graph-opportunity-gross_margin-percent_proposal': 'Raw margin (¹)',
  'Accounting': 'Accounting',
  'Impossible to place an order for a non-customer company.': 'Impossible to place an order for a non-customer company.',
  'Unable to order for this company, please contact Scalair.': 'Unable to order for this company, please contact Scalair.',
  'Modify company {name} — {code}': 'Modify company {name} — {code}',
  'Your request will be processed at the end of the commitment.': 'Your request will be processed at the end of the commitment.',
  'Activity follow-up': 'Activity follow-up',
  'Sales Dashboard': 'Sales Dashboard',
  'From {dateBegin} to {dateEnd}': 'From {dateBegin} to {dateEnd}',
  'Return to dashboard': 'Return to dashboard',
  'Date range': 'Date range',
  'Ref': 'Ref',
  'customer': 'customer',
  'customers': 'customers',
  'Do you want to delete {subject}?': 'Do you want to delete {subject}?',
  'Opportunity cloned.': 'Opportunity cloned.',
  'User email': 'User email',
  'Owner': 'Owner',
  'Change owner': 'Change owner',
  'Change closing date': 'Change closing date',
  'Abandon opportunities': 'Abandon opportunities',
  'Do you confirm the deletion of {count} opportunities?': 'Do you confirm the deletion of {count} opportunities?',
  'Do you want to set owner «{name}» for {count} opportunities?': 'Do you want to set owner «{name}» for {count} opportunities?',
  'Caller': 'Caller',
  'Organizer': 'Organizer',
  'Company\'s business sector.': 'Company\'s business sector.',
  'Last week': 'Last week',
  'Created by Scalair': 'Created by Scalair',
  'Created independently': 'Created independently',
  'Domain': 'Domain',
  'Orders in progress or recently delivered': 'Orders in progress or recently delivered',
  'Last quotations': 'Last quotations',
  'Last products delivered': 'Last products delivered',
  'product-domain-help-infrastructure': 'Scalair offers you IaaS through its Housing, Cloud and Dedicated Cloud offers.',
  'product-domain-help-license': 'Scalair allows you to declare your use of publisher tools but also of certificates and domain names.',
  'product-domain-help-service': 'Scalair accompanies you with tailor-made expert services during BUILD and RUN.',
  'product-domain-help-network': 'Scalair offers you Internet transit with Public IPs in the datacenter but also to create private networks such as L2L/MPLS.',
  'product-domain-help-security': 'Scalair has a range of products/services to meet your security needs.',
  'product-domain-help-all': 'The complete Scalair catalog with our expertise.',
  'Send technical information': 'Send technical information',
  'Send technical information...': 'Send technical information...',
  'Tech form': 'Tech form',
  '{count} questions': 'No question|One question|{count} questions',
  'This order needs some technical informations. Click on «Send technical information...» to provide them.': 'This order needs some technical informations. Click on «Send technical information...» to provide them.',
  'Ask for help...': 'Ask for help...',
  'This will send a ticket to SCALAIR support. Do you confirm?': 'This will send a ticket to SCALAIR support. Do you confirm?',
  'Ignore questionnaires...': 'Ignore questionnaires...',
  'Error for the response to « {question} »': 'Error for the response to « {question} »',
  'Confirm that you override the questions?': 'Confirm that you override the questions?',
  'Forecast': 'Forecast',
  '{count} opportunities': 'No opportunity|One opportunity|{count} opportunities',
  'Minimal percentage of success': 'Minimal percentage of success',
  'forecast-opportunities-booking': 'Forecast subscription',
  'forecast-opportunities-netbooking_gross': 'Forecast netbooking gross',
  'forecast-opportunities-setup_gross': 'Forecast setup gross',
  'Click on a bar in the graph to see the opportunities.': 'Click on a bar in the graph to see the opportunities.',
  'Click on the graph to see the opportunities.': 'Click on the graph to see the opportunities.',
  'Do you want to set closing date «{date}» for {count} opportunities?': 'Do you want to set closing date «{date}» for {count} opportunities?',
  'This cart contains no product that reach end of life.|This cart contains one product that reach end of life.|This cart contains {count} products that reach end of life.': 'This cart contains no product that reach end of life.|This cart contains one product that reach end of life.|This cart contains {count} products that reach end of life.',
  'This product reach end of life.': 'This product reach end of life.',
  'Please contact your sales manager at Scalair.': 'Please contact your sales manager at Scalair.',
  'An adjustment must be made for the change to be taken into account.': 'An adjustment must be made for the change to be taken into account.',
  'Show prices on details': 'Show prices (excl. VAT) on details',
  'Latest documents': 'Latest documents',
  'Health contact point': 'Health contact point',
  'Commitment\'s days': 'Commitment\'s days',
  'Committed for {days} days': 'No commitment|Committed for 1 day|Committed for {days} days',
  'Initial product commitment: {days} days': 'No initial product commitment|Initial product commitment: 1 day|Initial product commitment: {days} days',
  'Committed until {date}': 'Committed until {date}',
  'Until {date}': 'Until {date}',
  'Modify the commitment': 'Modify the commitment',
  'Specific permissions': 'Specific permissions',
  'No specific permission.': 'No specific permission.',
  'Resource': 'Resource',
  'Permissions': 'Permissions',
  'Input permissions pressing enter key to create each of them': 'Input permissions pressing enter key to create each of them',
  'Question « {name} » requires a response!': 'Question « {name} » requires a response!',
  'Setup booking goal': 'Setup booking goal',
  'Subscription booking goal': 'Subscription booking goal',
  'Sage Id': 'Sage Id',
  'Jira Id': 'Jira Id',
  'Number of calls': 'Number of calls',
  'per day': 'per day',
  'Number of business meals': 'Number of business meals',
  'Business meals': 'Business meals',
  'per month': 'per month',
  'Number of prospect appointments': 'Number of prospect appointments',
  'per week': 'per week',
  'Targets': 'Targets',
  'Event Targets': 'Event Targets',
  'Sales': 'Sales',
  'Target': 'Target',
  'Target: ': 'Target: ',
  'On target': 'On target',
  'Under target': 'Under target',
  'calls': 'calls',
  'call(s)': 'call(s)',
  'meal(s)': 'meal(s)',
  'event(s)': 'event(s)',
  'Include weekends': 'Include weekends',
  '{count} days': 'No day|One day|{count} days',
  '{count} months': 'No month|One month|{count} months',
  '{count} weeks': 'No week|One week|{count} weeks',
  '{count} calls': '{count} calls',
  '{count} events': '{count} events',
  '{count} meals': '{count} meals',
  '{count} calls during weekend.': '{count} calls during weekend.',
  'on {count} days': 'on {count} days',
  'on {count} months': 'on {count} months',
  'on {count} weeks': 'on {count} weeks',
  'Average: ': 'Average: ',
  'Average {count}{unit} {period}': 'Average {count}{unit} {period}',
  'No target is defined.': 'No target is defined.',
  'business meals': 'business meals',
  '{count} business meals': '{count} business meals',
  '{count} events with prospects': '{count} events with prospects',
  '{count} business meals during weekend.': '{count} business meals during weekend.',
  'Events with prospects': 'Events with prospects',
  'Include users with no target': 'Include users with no target',
  'Number of event with prospects': 'Number of event with prospects',
  'Phone is private:': 'Phone is private:',
  'Downsell reason': 'Downsell reason',
  'Assign stop reason': 'Assign stop reason',
  'Assign downsell reason': 'Assign downsell reason',
  'A reason must be selected !': 'A reason must be selected !',
  'This can apply only to stopped products !': 'This can apply only to stopped products !',
  'Do you want to set reason «{name}» for {count} subscribed products?': 'Do you want to set reason «{name}» for {count} subscribed products?',
  '{count} subscribed product(s) have not been modified.': '{count} subscribed product(s) have not been modified.',
  'Do you want to set reason «{name}» for {count} opportunities?': 'Do you want to set reason «{name}» for {count} opportunities?',
  '{count} opportunities have not been modified.': '{count} opportunities have not been modified.',
  'If you have a moment, tell us why you\'re stopping this product?': 'If you have a moment, tell us why you\'re stopping this product?',
  'This can apply only to opportunities won!': 'This can apply only to opportunities won!',
  'CSM Dashboard': 'Dashboard CSM',
  '{label}= {value}': '{label}= {value}',
  'Top {count} downsell by customers': 'Top {count} downsell by customers',
  'Distribution of downsell': 'Distribution of downsell',
  'Top {count} stopped products by downsell category': 'Top {count} stopped products by downsell category',
  'No product has been stopped.': 'No product has been stopped.',
  'List of opportunities': 'List of opportunities',
  'Show opportunities...': 'Show opportunities...',
  'Look': 'Look',
  'Compare with history': 'Compare with history',
  'Quantity:': 'Quantity:',
  'Show only products to be delivered.': 'Show only products to be delivered.',
  'Billable': 'Billable',
  'This ticket is billable': 'This ticket is billable',
  'This ticket is not billable': 'This ticket is not billable',
  'Waiting reason': 'Waiting reason',
  'Passed time': 'Passed time',
  'Estimated time (minutes)': 'Estimated time (minutes)',
  'Estimated time': 'Estimated time',
  'Billable passed time': 'Billable passed time',
  'Follow ticket': 'Follow ticket',
  'Follow tickets': 'Follow tickets',
  'Unfollow tickets': 'Unfollow tickets',
  'Assign a sprint': 'Assign a sprint',
  'Change tickets referrer': 'Change tickets referrer',
  'Delete tickets': 'Delete tickets',
  'Change tickets owner': 'Change tickets owner',
  'Change tickets state': 'Change tickets state',
  'Tracked tickets': 'Tracked tickets',
  'Tickets with me as referrer': 'Tickets with me as referrer',
  'Tickets created be me': 'Tickets created be me',
  'Trackers': 'Trackers',
  'Contributors': 'Contributors',
  'Referrer': 'Referrer',
  'Reporter': 'Reporter',
  'Ticket {name} created.': 'Ticket {name} created.',
  'Ticket {name} updated.': 'Ticket {name} updated.',
  'Ticket «{name}» not found!': 'Ticket «{name}» not found!',
  'Please, confirm the deletion of ticket {id} {name}?': 'Please, confirm the deletion of ticket {id} {name}?',
  'Closure date': 'Closure date',
  'Ticket life': 'Ticket life',
  'Sprints': 'Sprints',
  'Links': 'Links',
  'Remaining time': 'Remaining time',
  'Request information': 'Request information',
  'Request information on a product': 'Request information on a product',
  'I am interested in this product « {name} » for...': 'I am interested in this product « {name} » for...',
  'Estimated delivery date': 'Estimated delivery date',
  'You must choose a state for this ticket!': 'You must choose a state for this ticket!',
  'Link to a ticket': 'Link to a ticket',
  'ticket-link-tickets': 'Linked tickets',
  'ticket-link-orders': 'Orders',
  'ticket-link-onelines': 'Subscriptions',
  'ticket-link-products': 'Products',
  'ticket-link-opportunities': 'Opportunities',
  'ticket-link-carts': 'Quotations',
  'Link to': 'Link',
  'Link an order': 'Link an order',
  'Link a product': 'Link a product',
  'Link a subscription': 'Link a subscription',
  'Add sub-task from list': 'Add sub-task from list',
  'Link...': 'Link...',
  'Ticket "{id} {name}" deleted.': 'Ticket "{id} {name}" deleted.',
  'Follow': 'Follow',
  'Tracking': 'Follow-up',
  'Un-follow': 'Un-follow',
  'New link': 'New link',
  'Compact view': 'Compact view',
  'Save this ticket': 'Save this ticket',
  'This ticket must be saved before adding an image. Do you confirm?': 'This ticket must be saved before adding an image. Do you confirm?',
  'This ticket must be saved before adding a sub-task. Do you confirm?': 'This ticket must be saved before adding a sub-task. Do you confirm?',
  'This ticket must be saved before adding an attachment. Do you confirm?': 'This ticket must be saved before adding an attachment. Do you confirm?',
  'Start at': 'Start at',
  'SLA': 'SLA',
  'SLA Dashboard': 'SLA Dashboard',
  'Passed time %': 'Passed time %',
  'Ticket name': 'Ticket name',
  'CDS Dashboard': 'CDS Dashboard',
  '{count} tickets': 'No ticket|One ticket|{count} tickets',
  'Total failures': 'Total failures',
  'Latest SLA': 'Latest SLA',
  'Distribution of tickets': 'Distribution of tickets',
  'Distribution of pending tickets': 'Distribution of pending tickets',
  'Customer feedback': 'Customer feedback',
  'Unallocated': 'Unallocated',
  'Ticket(s) not assigned': 'Ticket(s) not assigned',
  'Change ticket company': 'Change ticket company',
  'Confirm changing the company from {from} to {to}?': 'Confirm changing the company from {from} to {to}?',
  'Change to company:': 'Change to company:',
  'Group by': 'Group by',
  'By team': 'By team',
  'By user': 'By user',
  'sla-state-ongoing': 'ongoing',
  'sla-state-paused': 'paused',
  'sla-state-stopped': 'stopped',
  'Estimated delivery time': 'Estimated delivery time',
  'Pre-sale': 'Pre-sale',
  'My SLA': 'My SLA',
  'SLA of my team': 'SLA of my team',
  'Display empty project tickets': 'Display empty project tickets',
  'Products concerned': 'Products concerned',
  'Sub-products concerned': 'Sub-products concerned',
  'List of virtual machines': 'List of virtual machines',
  'Sub-product': 'Sub-product',
  'Which company is concerned?': 'Which company is concerned?',
  'Which companies are concerned?': 'Which companies are concerned?',
  'Which companies have this problem?': 'Which companies have this problem?',
  'This is an exceptionally urgent request': 'This is an exceptionally urgent request',
  'sub-product-help-infrastructure-backup-ms365': 'Backup service for your MS365 data to Scalair.',
  'sub-product-help-infrastructure-dedicated-cloud': 'Scalair Dedicated Platform or VM/Volume from your Scalair Dedicated Cloud.',
  'sub-product-help-infrastructure-housing-network': 'Physical hosting of equipment in Scalair Datacenter.',
  'sub-product-help-infrastructure-pra': 'Scalair\'s Disaster Recovery Plan service for your infrastructure.',
  'sub-product-help-infrastructure-storage-cloud-scalair': 'Storage volume on Scalair Cloud.',
  'sub-product-help-infrastructure-vm': 'Virtual machine on Scalair Cloud.',
  'sub-product-help-license-backup': 'Veeam Backup & Replication or Veeam Backup MS365.',
  'sub-product-help-license-captive-portal': 'Ucopia.',
  'sub-product-help-license-domain': 'Internet DN (Domain Name).',
  'sub-product-help-license-mails-protection': 'Vade Cloud or Vade MS365.',
  'sub-product-help-license-microsoft-365': 'AD connect, entra ID, exchange online, sharepoint etc.',
  'sub-product-help-license-microsoft-spla': 'Windows Server, Remote Desktop, SQL etc',
  'sub-product-help-license-virtual-desktop': 'Ivanti/RES Workspace manager.',
  'sub-product-help-network-data-center': 'Delivered in datacenter.',
  'sub-product-help-network-lan-to-lan': 'Level 2 point-to-point link or data center ring road.',
  'sub-product-help-network-mpls': 'Interconnection service for your sites.',
  'sub-product-help-security-captive-portal': 'Scalair service based on the UCOPIA solution.',
  'sub-product-help-security-edr': 'Harfanglab EDR.',
  'sub-product-help-security-epp-antivirus': 'Antivirus & Patch Manager With-Secure.',
  'sub-product-help-security-firewall': 'Firewall (filtering/VPN) Stormshield virtual or physical.',
  'sub-product-help-security-pam-bastion': 'Wallix bastion for administrator access.',
  'sub-product-help-security-sase': 'CATO Networks solution for network and cybersecurity convergence.',
  'sub-product-help-security-ssl-certificate': 'For domain and sub-domain.',
  'sub-product-infrastructure-backup-ms365': 'MS365 Backup',
  'sub-product-infrastructure-dedicated-cloud': 'Dedicated cloud',
  'sub-product-infrastructure-housing-network': 'Housing & network',
  'sub-product-infrastructure-pra': 'PRA',
  'sub-product-infrastructure-storage-cloud-scalair': 'Storage',
  'sub-product-infrastructure-vm': 'VM',
  'sub-product-license-backup': 'Backup',
  'sub-product-license-captive-portal': 'Captive portal',
  'sub-product-license-domain': 'Domain',
  'sub-product-license-mails-protection': 'Protection mails',
  'sub-product-license-microsoft-365': 'O365',
  'sub-product-license-microsoft-spla': 'SPLA',
  'sub-product-license-virtual-desktop': 'Virtual desktop',
  'sub-product-network-data-center': 'Internet Transit',
  'sub-product-network-lan-to-lan': 'Lan to lan',
  'sub-product-network-mpls': 'MPLS',
  'sub-product-security-captive-portal': 'Captive Portal',
  'sub-product-security-edr': 'EDR',
  'sub-product-security-epp-antivirus': 'EPP / Anti-virus',
  'sub-product-security-firewall': 'Firewall',
  'sub-product-security-pam-bastion': 'PAM / Bastion',
  'sub-product-security-sase': 'SASE',
  'sub-product-security-ssl-certificate': 'SSL/TLS Certificate',
  'Incidents involving another product.': 'Incident concerning any other product/system/service not listed.',
  'No tickets were processed.': 'No tickets were processed.',
  'All tickets were processed.': 'All tickets were processed.',
  'Only {count} tickets on {total} were processed.': 'Only {count} tickets on {total} were processed.',
  'Confirm deletion of {count} ticket(s)?': 'Confirm deletion of {count} ticket(s)?',
  'Confirm new owner {name} for {count} ticket(s)?': 'Confirm new owner {name} for {count} ticket(s)?',
  'Confirm new state {state} for {count} ticket(s)?': 'Confirm new state {state} for {count} ticket(s)?',
  'Confirm the follow-up for {count} ticket(s)?': 'Confirm the follow-up for {count} ticket(s)?',
  'Can you confirm that you will no longer be following these {count} ticket(s)?': 'Can you confirm that you will no longer be following these {count} ticket(s)?',
  'product-domain-help-incident-infrastructure': 'Incident concerning a hosted resource (VM/Volume/Housing) or an infrastructure service such as Dedicated Cloud, DRP, MS365 Backup.',
  'product-domain-help-incident-license': 'Incident concerning a third-party system such as domain names, Microsoft, Vade, Veeam, Ucopia, Ivanti.',
  'product-domain-help-incident-network': 'Incident involving a network service such as data center Internet transit, MPLS and Lan-To-Lan.',
  'product-domain-help-incident-security': 'Incident concerning a security product/service such as firewall, EPP, EDR, SASE, PAM, captive portal and SSL/TLS certificates.',
  'Use this input to enter products you may not have found in the list.': 'Use this input to enter products you may not have found in the list.',
  'Display sphere': 'Display sphere',
  'Display': 'Display',
  'Hide subs-tasks': 'Hide subs-tasks',
  'New sub-task': 'New sub-task',
  'Title of the new sub-task...': 'Title of the new sub-task...',
  'Filter name': 'Filter name',
  'Save current filter': 'Save current filter',
  'Save in this filter': 'Save in this filter',
  'Apply this filter': 'Apply this filter',
  'Saved filters': 'Saved filters',
  'Delete filter': 'Delete filter',
  'Delete filter «{name}»?': 'Delete filter «{name}»?',
  'Spheres replace projects. They work in the same way.': 'Spheres replace projects. They work in the same way.',
  'Copy ticket URL to clipboard': 'Copy ticket URL to clipboard',
  'New tickets': 'New tickets',
  'Report an incident on this product.': 'Report an incident on this product.',
  'Make a free-format request on this product.': 'Make a free-format request on this product.',
  'Report an incident on this subscription.': 'Report an incident on this subscription.',
  'Make a free-format request on this subscription.': 'Make a free-format request on this subscription.',
  'Create a global incident': 'Create a global incident',
  'Create a global ticket': 'Create a global ticket',
  'Impacted companies': 'Impacted companies',
  'A global incident will create an incident on the Scalair company, and then an incident on each selected company. The incidents will be linked together so that any publication or modification on the global incident will reflect on the incidents on each dedicated company.': 'A global incident will create an incident on the Scalair company, and then an incident on each selected company. The incidents will be linked together so that any publication or modification on the global incident will reflect on the incidents on each dedicated company.',
  'Remove all': 'Remove all',
  'Please, choose one or more customers from the selection above to add them to this list.': 'Please, choose one or more customers from the selection above to add them to this list.',
  'Origin': 'Origin',
  'Modifications detected': 'Modifications detected',
  'Do you confirm the loss of your modifications?': 'Do you confirm the loss of your modifications?',
  'Top 70': 'Top 70',
  'Customer companies': 'Customer companies',
  'Companies with CSM': 'Companies with CSM',
  'This role designates a contact person to be contacted in the event of a technical incident or specific technical questions about the company.': 'This role designates a contact person to be contacted in the event of a technical incident or specific technical questions about the company.',
  'Hide canceled or closed tickets': 'Hide canceled or closed tickets',
  'Be automatically added to my companies\' ticket tracking': 'Be automatically added to my companies\' ticket tracking',
  'Customer tickets': 'Customer tickets',
  'Internal tickets': 'Internal tickets',
  'Unassigned tickets': 'Unassigned tickets',
  'Questionnaire «{name}» not found!': 'Questionnaire «{name}» not found!',
  'We\'d like to know how the handling of your recently closed ticket went.': 'We\'d like to know how the handling of your recently closed ticket went.',
  'Was the incident handled correctly by our teams?': 'Was the incident handled correctly by our teams?',
  'Was the request handled correctly by our teams?': 'Was the request handled correctly by our teams?',
  'If no, please give details': 'If no, please give details',
  'Are you satisfied with the way the project went?': 'Are you satisfied with the way the project went?',
  'Did our team meet your requirements?': 'Did our team meet your requirements?',
  'Does Scalair\'s service meet expectations?': 'Does Scalair\'s service meet expectations?',
  'Is the quality of Scalair\'s support satisfactory (visibility, exchanges, responsiveness, etc.)': 'Is the quality of Scalair\'s support satisfactory (visibility, exchanges, responsiveness, etc.)',
  'Were Scalair\'s commitments respected (response times, appointments, planning, etc.)': 'Were Scalair\'s commitments respected (response times, appointments, planning, etc.)',
  'Comments on project progress': 'Comments on project progress',
  'A reply was received on {date}.': 'A reply was received on {date}.',
  'Do not reply': 'Do not reply',
  'Send reply': 'Send reply',
  'Satisfaction questionnaire': 'Satisfaction questionnaire',
  'Email sent': 'Email sent',
  'Answered at': 'Answered at',
  'You must add a customer user to the people who will follow up this ticket.': 'You must add a customer user to the people who will follow up this ticket.',
  'ticket-questionnaire-state-answered': 'Answered',
  'ticket-questionnaire-state-created': 'Created',
  'ticket-questionnaire-state-read': 'Read',
  'ticket-questionnaire-state-refused': 'Refused',
  'Making contact': 'Making contact',
  'Business follow-up': 'Business follow-up',
  'Lead qualification': 'Lead qualification',
  'Closing': 'Closing',
  'News taking': 'News taking',
  'Create another call': 'Create another call',
  'Create another event': 'Create another event',
  'Create a user': 'Create a user',
  'Call followup created for {lastname} {firstname}.': 'Call followup created for {lastname} {firstname}.',
  'Call followup saved.': 'Call followup saved.',
  'At least one product must be entered.': 'At least one product must be entered.',
  'Do you have a specific request to pass on to our teams concerning the shutdown (precise time of intervention, need or other)?': 'Do you have a specific request to pass on to our teams concerning the shutdown (precise time of intervention, need or other)?',
  'Infra Dashboard': 'Infra Dashboard',
  'Today': 'Today',
  'Statistics': 'Statistics',
  'Ticket breakdown': 'Ticket breakdown',
  'Category breakdown': 'Category breakdown',
  'Customer incidents': 'Customer incidents',
  'Customer requests': 'Customer requets',
  'Customer projects': 'Customer projects',
  'Internal projects': 'Internal projects',
  'The Scalair teams': 'The Scalair teams',
  'For your feedback': 'For your feedback',
  'For your deposit': 'For your deposit',
  'For your technical information': 'For your technical information',
  'Favorite products': 'Favorite products',
  'Add favorite products to find them here.': 'Add favorite products to find them here.',
  'Quick order': 'Quick order',
  'No action pending on your part.': 'No action pending on your part.',
  'Trend': 'Trend',
  'To do': 'To do',
  'Incidents': 'Incidents',
  'Requests': 'Requests',
  'Projects': 'Projects',
  'Projects & Requests': 'Projects & Requests',
  'Open ticket': 'Open ticket',
  'Open ticket "{id}"': 'Open ticket "{id}"',
  'Managing company': 'Managing company',
  'Sphere «{name}» not found!': 'Sphere «{name}» not found!',
  'Scalair Manager (top)': 'Scalair Manager',
  'Downsale analysis': 'Downsale analysis',
  'Company indicators': 'Company indicators',
  'Overview': 'Overview',
  'Current topics': 'Current topics',
  'Satisfaction': 'Satisfaction',
  'Scope': 'Scope',
  'Questionnaire {id}': 'Questionnaire {id}',
  'Link a quotation': 'Link a quotation',
  'Link an opportunity': 'Link an opportunity',
  'Create a ticket': 'Create a ticket',
  'Pre-sale Dashboard': 'Dashboard',
  'Requested reply date': 'Requested reply date',
  'Add the technical referents of the companies affected to the ticket follow-up': 'Add the technical referents of the companies affected to the ticket follow-up',
  'Assign me all sub-tasks': 'Assign me all sub-tasks',
  'Sales consultant': 'Sales consultant',
  'Activate SLA for this ticket': 'Activate SLA for this ticket',
  'No topics.': 'No topics.',
  'Hide prices': 'Hide prices',
  'Marketing Dashboard': 'Marketing Dashboard',
  'Campaign type': 'Campaign type',
  'Leads target': 'Leads target',
  'Turnover target': 'Turnover target',
  'Turnover generated': 'Turnover generated',
  'Parent': 'Parent',
  'Create a new cost...': 'Create a new cost...',
  'Add a cost:': 'Add a cost:',
  'Leads': 'Leads',
  'Lead': 'Lead',
  'ROI': 'R.O.I.',
  'Add opportunity:': 'Add opportunity:',
  'Add a lead:': 'Add a lead:',
  'Create a lead...': 'Create a lead...',
  'Amount spent by type of event': 'Amount spent by type of event',
  'R.O.I. of shares': 'R.O.I. of shares',
  'Sales generated by leads': 'Sales generated by leads',
  'Total number of leads': 'Total number of leads',
  'Leads converted to opportunities': 'Leads converted to opportunities',
  'Turnover generated by type of event': 'Turnover generated by type of event',
  'Campaigns with the most impact': 'Campaigns with the most impact',
  'Start-up time': 'Start-up time',
  'Time to production': 'Time to production',
  'The quotation cannot be validated because certain information is missing concerning:': 'The quotation cannot be validated because certain information is missing concerning:',
  'Order details': 'Order details',
  'Information on the delivery date of the order': 'Information on the delivery date of the order',
  'The estimated delivery date is calculated from the day the quotation is signed. The sooner the quotation is signed, the sooner the project can be delivered.': 'The estimated delivery date is calculated from the day the quotation is signed. The sooner the quotation is signed, the sooner the project can be delivered.',
  'Campaign cloned.': 'Campaign cloned.',
  'Update targets': 'Update targets',
  'The build load reflects the current load of the team that will be in charge of your project, the start of your project should take place after this deadline.': 'The build load reflects the current load of the team that will be in charge of your project, the start of your project should take place after this deadline.',
  'Estimate the duration of your project, the time between the start date and the delivery date.': 'Estimate the duration of your project, the time between the start date and the delivery date.',
  'Planned budget': 'Planned budget',
  'Campaign': 'Campaign',
  'Campaigns': 'Campaigns',
  'A global ticket will create a ticket on the Scalair company, and then a ticket on each selected company.': 'A global ticket will create a ticket on the Scalair company, and then a ticket on each selected company.',
  'Writing a comment will turn this ticket into a request.': 'Writing a comment will turn this ticket into a request.',
  'Score': 'Score',
  'Download leads template': 'Download leads template',
  'Upload leads': 'Upload leads',
  'An error occurred when importing the file {name}. Is the file in the expected format?': 'An error occurred when importing the file {name}. Is the file in the expected format?',
  'The leads have been imported.': 'The leads have been imported.',
  'Marketing': 'Marketing',
  'Average response time': 'Average response time',
  'Average time over the period of completed requests between the date of creation and the date of transition to “completed” status.': 'Average time over the period of completed requests between the date of creation and the date of transition to “completed” status.',
  'Time spent on requests processed': 'Time spent on requests processed',
  'Number of requests by month': 'Number of requests by month',
  'Forecast of requests by week': 'Forecast of requests by week',
  'Number of tickets': 'Number of tickets',
  '* Current week': '* Current week',
  'Related events': 'Related events',
  'Create an email campaign': 'Create an email campaign',
  'Send date: ': 'Send date: ',
  'Campaign name: ': 'Campaign name: ',
  'Clickers: ': 'Clickers: ',
  'Opening: ': 'Opening: ',
  'Recipients: ': 'Recipients: ',
  'Unsubscribing: ': 'Unsubscribing: ',
  'Open campaign in Brevo': 'Open campaign in Brevo',
  'Email campaign': 'Email campaign',
  'Choose a team to see its tickets...': 'Choose a team to see its tickets...',
  'Please select one or more options above and press button refresh to display the follow-ups.': 'Please select one or more options above and press button refresh to display the follow-ups.',
  'Please select a customer and press button refresh to display the follow-ups.': 'Please select a customer and press button refresh to display the follow-ups.',
  '{count} leads': 'No lead|One lead|{count} leads',
  'Sent: ': 'Sent: ',
  'Delivered: ': 'Delivered: ',
  'Please, choose a temblate for the email campaign :': 'Please, choose a temblate for the email campaign :',
  'No template available!': 'No template available!',
  'No campaign': 'No campaign',
  'Related campaigns': 'Related campaigns',
  'Cancel this ticket': 'Cancel this ticket',
  'Do you confirm that this ticket is cancelled?': 'Do you confirm that this ticket is cancelled?',
  'Delete cost': 'Delete cost',
  'Do you confirm the deletion of cost "{name}"?': 'Do you confirm the deletion of cost "{name}"?',
  'Turning into a suspect...': 'Turning into a suspect...',
  'Do you confirm the creation of the company from {name}?': 'Do you confirm the creation of the company from {name}?',
  'Company {name} created.': 'Company {name} created.',
  'company-field-name': 'Name',
  'company-field-parent': 'Managing company',
  'company-field-name_billing': 'Name on invoices',
  'company-field-business_sector': 'Business sector',
  'company-field-business_description': 'Business',
  'company-field-b_street': 'Billing address',
  'company-field-b_street2': 'Complement',
  'company-field-b_city': 'Town',
  'company-field-b_zipcode': 'Zip code',
  'company-field-b_country': 'Country',
  'company-field-h_street': 'Address',
  'company-field-h_city': 'Town',
  'company-field-h_zipcode': 'Zip code',
  'company-field-h_country': 'Country',
  'company-field-siren': 'SIREN',
  'company-field-nic': 'NIC',
  'company-field-vat': 'VAT',
  'company-field-health_contact_point': 'Health contact point',
  'company-field-payment_method': 'Payment method',
  'company-field-iban': 'IBAN',
  'company-field-bic': 'BIC',
  'company-field-id_lord_salesperson': 'Sales Manager',
  'company-field-mail_accounting': 'E-Mail accounting',
  'company-field-type': 'Type',
  'company-field-business_creation_date': 'Business creation',
  'company-field-employee_number': 'Employees',
  'Subtype': 'Subtype',
  'This lead has already been associated with a company.': 'This lead has already been associated with a company.',
  'Required for non suspect company.': 'Required for non suspect company.',
  'No company found for SIREN "{siren}"!': 'No company found for SIREN "{siren}"!',
  'A customer company needs at least one user.': 'A customer company needs at least one user.',
  'Required for client company.': 'Required for client company.',
  'Expiration date': 'Expiration date',
  'Company «{name}» not found!': 'Company «{name}» not found!',
  'Tickets automation': 'Tickets launcher',
  'Cancel last ticket': 'Cancel last ticket',
  'Modify scheduling...': 'Modify scheduling...',
  'Cancel the previous ticket when creating the new one.': 'Cancel the previous ticket when creating the new one.',
  'Last contact': 'Last contact',
  'Last call': 'Last call',
  'Last event': 'Last event',
  'Last email': 'Last email'
}
