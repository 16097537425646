<template>
  <div>
    <sca-advanced-store-grid :options="options" :filters="filters" resource="schedules" :columns="columns" @dataLoaded="dataLoaded" ref="scheduler-task-templates-grid" @deleteItem="onTaskTemplateDelete">
      <template v-slot:actions-prepend="{ row }">
        <template v-if="canRead">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon @click="showTaskHistory(row)">
                <v-icon small color="primary">
                  $vuetify.icons.history
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('History') }}</span>
          </v-tooltip>
        </template>
      </template>
    </sca-advanced-store-grid>
    <csm-task-history-dialog ref="user-task-history-dialog" />
  </div>
</template>

<script>
import cronParser from 'cron-parser'

const OPTIONS_DEFAULT = {
  allowColumnsVisible: true,
  allowContextMenuOnCell: true,
  create: true,
  delete: true,
  key: 'id',
  messages: {
    deleteItem: 'Please, confirm the deletion of task «{caption}»?'
  },
  foreignFields: ['emails_error_copy'],
  multiSelect: false,
  notifyErrors: true,
  permissionField: false,
  search: true,
  show: false,
  sortBy: 'update_at',
  sortDescending: true,
  update: false
}

export default {
  name: 'TaskTemplatesList',
  components: {
    'csm-task-history-dialog': () => import('./UserTaskHistoryDialog')
  },
  data () {
    return {
      filters: ['name', 'description']
    }
  },
  computed: {
    canCreate () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.SCHEDULER, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.SCHEDULER, this.$alto.API_PERMISSIONS.SCHEDULER_BY_TYPE) },
    canDelete () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.SCHEDULER, this.$alto.API_PERMISSIONS.DELETE) },
    canRead () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.SCHEDULER, this.$alto.API_PERMISSIONS.READ) },
    canUpdate () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.SCHEDULER, this.$alto.API_PERMISSIONS.UPDATE) },
    columns () {
      return [
        {
          text: 'Caption',
          value: 'caption',
          hidden: false,
          format: this.$stratus.services.fieldRenderers.FALSY_AS_EMPTY
        }, {
          text: 'Service',
          value: 'service_id',
          format: v => {
            return this.servicesList[v] ? this.servicesList[v].name : this.$t('Unknown')
          }
        }, {
          text: 'Last run',
          value: 'history',
          format: v => {
            const lastRun = this.Latest(v)
            return lastRun && lastRun.date ? this.$stratus.dt(lastRun.date).format('LL LTS') + ' (' + lastRun.response + ')' : this.$t('Not run')
          }
        }, {
          text: 'Next run',
          value: 'cron',
          format: v => {
            const nextDate = cronParser.parseExpression(v, { tz: this.$stratus.dt.tz.guess() }).next()
            return this.$stratus.dt(nextDate).format('LL LTS')
          }
        }, {
          text: 'Email on error',
          value: 'send_error_email',
          hidden: false,
          format: (v, col, row) => {
            if (v) {
              return this.$store.getters['$stratus-states/me'].email + ' ' +
                (row.emails_error_copy && row.emails_error_copy.length > 0 ? ', ' + row.emails_error_copy.join(', ') : '')
            } else return this.$t('No')
          }
        }, {
          text: 'Updated at',
          align: 'right',
          alignValue: 'right',
          hidden: false,
          value: 'update_at',
          format: this.$stratus.services.fieldRenderers.DATE_SHORT
        }, {
          text: 'Updated by',
          hidden: true,
          value: 'update_by'
        }, {
          text: 'Created at',
          align: 'right',
          alignValue: 'right',
          hidden: true,
          value: 'create_at',
          format: this.$stratus.services.fieldRenderers.DATE_SHORT
        }, {
          text: 'Created by',
          hidden: true,
          value: 'create_by'
        }
      ]
    },
    options () {
      return {
        ...OPTIONS_DEFAULT,
        ...{
          create: false,
          delete: this.canDelete,
          show: false,
          // Update is not available. Backend cannot send us what we have previously input.
          // It use a pattern to create the task to schedule and so does no keep creation information as it was input by user.
          update: false // this.isLord && this.canUpdate
        }
      }
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    me () { return this.$store.getters['$stratus-states/me'] },
    servicesList () { return this.$store.getters['scheduler/servicesCache'] }
  },
  async mounted () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Scheduler'))
    await this.$store.dispatch('$stratus-states/getMe')
    await this.$store.dispatch('scheduler/servicesList')
  },
  methods: {
    dataLoaded ({ error }) {
      if (error) console.error(error)
      this.$store.dispatch('scheduler/listTaskTemplates')
    },
    Latest (history) {
      return history && history.length ? history[history.length - 1] : null
    },
    async onTaskTemplateShow ({ success, item }) {
      if (this.$refs['user-task-history-dialog']) {
        this.$refs['user-task-history-dialog'].open({
          readOnly: true,
          data: item
        })
          .then(() => this.refreshGrid())
      }
    },
    async onTaskTemplateDelete ({ success, error, item }) {
      if (success) {
        this.$stratus.services.notify.success(this.$t('Task template {name} deleted.', { name: item.caption }))
      } else {
        if (error && error.status === 403) {
          this.$stratus.services.notify.warning(this.$t('You\'re not allowed to delete {name}!', item))
        } else {
          this.$stratus.services.notify.error(error)
        }
      }
    },
    refreshGrid () {
      this.$refs['scheduler-task-templates-grid'].fetchData()
    },
    async showTaskHistory (item) {
      if (this.$refs['user-task-history-dialog']) {
        this.$refs['user-task-history-dialog'].open({
          data: item
        })
      }
    }
  }
}
</script>
