<template>
  <v-row>
    <v-col cols="12">
      <sca-advanced-store-grid :options="gridOptions" resource="tickets/launchers" :store-suffix="uuid" :columns="gridColumns" :filters="filters" search-text-col-size="sm" @createItem="onCreateItem" @dataLoaded="onDataLoaded" @deleteItem="onDeleteItem" @showItem="onShowItem" @updateItem="onUpdateItem" :ref="`ticket-launchers-grid-${uuid}`">
        <template v-slot:item-type="{ itemRaw }">
          <sca-ticket-type :value="itemRaw" show-label />
        </template>

        <template v-slot:item-severity="{ itemRaw }">
          <sca-ticket-severity :value="itemRaw" show-label />
        </template>

        <template v-slot:item-code="{ itemRaw }">
          <sca-company-identity :value="itemRaw" show-avatar show-email show-phone show-sales-person :text-resume="20" link="emit" @link-click="openCompany(itemRaw)" />
        </template>

        <template v-slot:item-id_owner="{ itemRaw }">
          <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
          <span v-else />
        </template>

        <template v-slot:item-id_referring="{ itemRaw }">
          <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
          <span v-else />
        </template>

        <template v-slot:item-id_lord_team="{ itemRaw }">
          <sca-team-identity v-if="itemRaw" :value="itemRaw" />
          <span v-else />
        </template>

        <template v-slot:item-estimated_time="{ itemRaw }">
          {{ itemRaw ? $stratus.services.format.secondsToDays(60 * itemRaw, SECONDS_BY_WORK_DAY) : '-' }}
        </template>

        <template v-slot:item-id_sphere="{ itemRaw }">
          <sca-sphere-identity :value="itemRaw" link="emit" @link-click="openSphere(itemRaw)" />
        </template>

        <template v-slot:item-ids_intern="{ row }">
          <ticket-links ref="ticket-links" :ticket="row" hide-label inline />
        </template>
      </sca-advanced-store-grid>
    </v-col>
    <csm-ticket-automation-dialog ref="ticket-automation-dialog" @closeDialog="closeDialog" />
    <csm-company-dialog ref="company-dialog" />
    <csm-sphere-dialog ref="sphere-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </v-row>
</template>

<script>
// import _ from 'lodash'
import { mapGetters } from 'vuex'

const LOADING_MIN_DURATION = 500

export default {
  name: 'TicketsAutomationList',
  components: {
    'csm-ticket-automation-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketAutomationDialog.vue'),
    'ticket-links': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketLinks.vue')
  },
  data () {
    return {
      SECONDS_BY_WORK_DAY: this.$alto.defines.TICKETS.SECONDS_BY_WORK_DAY,
      TICKET_DEFAULT_VALUES: {
        type: this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST,
        severity: this.$alto.defines.TICKETS.TICKET_SEVERITY_NONE,
        name: '',
        code: null,
        id_owner: null,
        id_referring: null,
        id_lord_team: null,
        estimated_time: 5,
        id_sphere: null,
        ids_intern: {}
      },
      filters: ['id', 'name'],
      uuid: this.$stratus.uuid()
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    canCreate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_LAUNCHERS, this.$alto.API_PERMISSIONS.CREATE)
    },
    // canDelete () {
    //   return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_LAUNCHERS, this.$alto.API_PERMISSIONS.DELETE)
    // },
    canList () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_LAUNCHERS, this.$alto.API_PERMISSIONS.LIST)
    },
    // canRead () {
    //   return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_LAUNCHERS, this.$alto.API_PERMISSIONS.READ)
    // },
    // canUpdate () {
    //   return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_LAUNCHERS, this.$alto.API_PERMISSIONS.UPDATE)
    // }
    gridColumns () {
      const cols = [{
        text: 'Type',
        value: 'type'
      }, {
        text: 'Severity',
        value: 'severity'
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Company',
        value: 'code'
      }, {
        text: 'Owner',
        value: 'id_owner'
      }, {
        text: 'Referrer',
        value: 'id_referring'
      }, {
        text: 'Cancel last ticket',
        value: 'cancel_last_ticket',
        align: 'center',
        alignValue: 'center',
        format: this.$stratus.services.fieldRenderers.BOOLEAN_CHECK_IF_TRUE
      }, {
        text: 'Last run',
        value: 'last_date_launch',
        format: v => v ? this.$stratus.services.fieldRenderers.DATETIME_SHORT(v) : ''
      }, {
        text: 'Team',
        value: 'id_lord_team',
        hidden: true
      }, {
        text: 'Estimated time',
        value: 'estimated_time',
        hidden: true
      }, {
        text: 'Sphere',
        value: 'id_sphere',
        hidden: true
      }, {
        text: 'Links',
        value: 'ids_intern',
        hidden: true
      }]

      return cols
    },
    gridOptions () {
      return {
        advancedSearchFieldsSchemaBaseUrl: '/docs/tickets',
        advancedSearchFieldsSchemaName: 'LauncherTicket',
        allowContextMenuOnCell: true,
        allowColumnsVisible: true,
        create: this.isLogged && this.$store.getters['$alto-roles/canI'](this.$store.getters['$alto-roles/API_CONTEXTS'].TICKETS_LAUNCHERS, this.$store.getters['$alto-roles/API_PERMISSIONS'].CREATE),
        clipboardURL: {
          path: 'tickets',
          value: 'id'
        },
        customUpdateItem: true,
        delete: false,
        foreignFields: ['id', 'ids_observers'],
        foreignFieldsFilter: true,
        key: 'id',
        messages: {
          deleteItem: 'Please, confirm the deletion of ticket {id} {name}?'
        },
        multiSelect: false,
        notifyErrors: true,
        searchVisibleColumns: false,
        show: true,
        sortBy: 'update_at',
        sortDescending: true,
        update: true
      }
    }
  },
  methods: {
    closeDialog (saved) {
      if (saved) this.refresh()
    },
    async onCreateItem () {
      this.$refs['ticket-automation-dialog'].openNew({ ...(this.defaultTicket || this.TICKET_DEFAULT_VALUES), code: this.me.company })
    },
    onDataLoaded () {
      setTimeout(() => { this.loading = false }, LOADING_MIN_DURATION)
      this.lastRefreshDate = new Date()
    },
    onDeleteItem ({ success, error, item }) {
      if (success) {
        this.$stratus.services.notify.success(this.$t('Ticket "{id} {name}" deleted.', item))
      } else {
        if (error && error.status === 403) {
          this.$stratus.services.notify.warning(this.$t('You\'re not allowed to delete ticket "{name}".', item))
        } else {
          this.$stratus.services.notify.error(error)
        }
      }
    },
    async onShowItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
      } else this.$refs['ticket-automation-dialog'].open(data.item.id)
    },
    async onUpdateItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
      } else this.$refs['ticket-automation-dialog'].open(data.item.id)
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openSphere (id) {
      if (this.$refs['sphere-dialog'] && id) this.$refs['sphere-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    refresh () {
      if (this.$refs[`ticket-launchers-grid-${this.uuid}`]) this.$refs[`ticket-launchers-grid-${this.uuid}`].fetchData()
    }
  },
  created () {
    this.isLogged = this.$stratus.services.auth.isLogged()
  },
  async mounted () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Tickets automation'))
  }
}
</script>
