<template>
  <v-card flat tile>
    <v-card-title>
      <v-row dense align="center" v-if="hasUpload">
        <v-col class="shrink">
          <v-avatar size="36" color="primary white--text">
            <v-icon>
              {{ uploadFile.icon }}
            </v-icon>
          </v-avatar>
        </v-col>
        <v-col>
          <div class="text-h6">
            {{ uploadFile.name }}
          </div>
          <span class="text-caption">
            {{ document }}
            ({{ $t('{count}{unit}', $stratus.services.format.bytesToBestUnit(uploadFile.size)) }})
          </span>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click.native.stop="downloadItem(uploadFile)" :disabled="isDownloading" icon>
                <cs-icon-loading v-if="isDownloading" />
                <v-icon v-else>
                  $vuetify.icons.download
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Download') }}</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="danger" @click.native.stop="deleteItem(uploadFile)" :disabled="isDeleting" icon>
                <cs-icon-loading v-if="isDeleting" />
                <v-icon v-else>
                  $vuetify.icons.delete
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <span v-if="!hasUpload" class="text-h6">{{ $t('My contract') }}</span>
    </v-card-title>

    <cs-color-divider color="primary" />

    <v-row class="pt-4" justify="center" no-gutters>
      <v-col class="text-center" cols="12" v-if="!hasUpload">
        <div class="text-subtitle-1 font-weight-bold">
          <p v-if="code !== ''">
            {{ $t('Select a file or a folder') }}
          </p>
          <p v-else>
            {{ $t('Please select a company\'s code') }}
          </p>
          <cs-file-upload-button :disabled="code === ''" ref="document-upload-button" class="no-left-margin-in-snack" :default-text="$t('Send documents')" @fileChanged="onFileChanged" @reset="onResetUploads" multiple />
        </div>
      </v-col>

      <v-row v-if="hasUpload" justify="center" no-gutters>
        <v-col class="shrink">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon @click="rotate90">
                <v-icon small>
                  icon-upload
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Rotate') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="2" v-show="isLoaded">
          <cs-integer-input small :min="1" :max="numPages" v-model="page" />
        </v-col>
        <v-col cols="1" class="text-h6" v-show="isLoaded">
          / {{ numPages }}
        </v-col>

        <v-col cols="12">
          <sca-pdf-viewer class="mt-4" ref="contract-pdf-file-viewer" :page="page" :rotation="rotation" @loaded="onLoaded" />
        </v-col>
      </v-row>
    </v-row>

    <cs-confirm-dialog ref="confirm-file-action" />
  </v-card>
</template>

<script>
import getClassNameForExtension from 'font-awesome-filetypes'

import config from '@/config'

const TYPE_DIR = 'd'
const TYPE_FILE = '-'
const ROTATION_LIST = [0, 90, 180, 270]

export default {
  data: () => ({
    TYPE_DIR,
    TYPE_FILE,
    document: '',
    hasUpload: false,
    isDeleting: false,
    isDownloading: false,
    isLoading: false,
    isUploading: false,
    isSelecting: false,
    loadingTask: {},
    pathContracts: '/contracts',
    pathDocument: '',
    showFile: true,
    uploadFile: {},
    isLoaded: false,
    page: 1,
    numPages: 1,
    rotation: 0,
    rotationIndex: 0
  }),
  props: {
    documentProp: { type: String, default: '' },
    code: { type: String, default: '' }
  },
  computed: {
    canSend () {
      return !this.hasUpload && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.DOCUMENTS, this.$alto.API_PERMISSIONS.CREATE)
    }
  },
  watch: {
    documentProp (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.document = this.documentProp
        this.loadItem(this.document)
      }
    }
  },
  methods: {
    onLoaded ({ numPages }) {
      this.isLoaded = true
      this.numPages = numPages
      this.page = 1
      this.rotation = 0
    },
    rotate90 () {
      this.rotationIndex++
      if (this.rotationIndex >= ROTATION_LIST.length) this.rotationIndex = 0
      this.rotation = ROTATION_LIST[this.rotationIndex]
    },
    isDark () {
      return this.$store.getters['$stratus-states/isDark']
    },
    deleteItem (item) {
      this.$refs['confirm-file-action']
        .open(this.$t('Delete file'), this.$t('Confirm deletion of file «{name}»?', { name: item.name }), { color: 'red' })
        .then(async (confirm) => {
          if (confirm) {
            this.isDeleting = true
            try {
              await this.$store.dispatch('documents/deleteFile', { path: this.document, recursive: item.type === TYPE_DIR })
              this.$stratus.services.notify.success(this.$t('File {name} deleted.', { name: item.name }))
              this.$stratus.services.array.removeObject(this.items, 'id', item.id, 'children')
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }
            this.isDeleting = false
            this.hasUpload = false
            this.document = ''
            this.$emit('change', this.document)
          }
        })
    },
    async downloadItem (item) {
      if (!item || !this.document) return

      if (item.type === TYPE_FILE) {
        this.isLoading = true
        await this.$stratus.services.api.downloadProgress(`/documents${this.document}?format=raw`, item.name)
        setTimeout(() => { this.isLoading = false }, 3000)
      }
    },
    async loadItem (path) {
      await this.$store.dispatch('documents/openFolder', path)
        .then(response => {
          this.uploadFile = response
          this.uploadFile.icon = this.uploadFile.type === TYPE_DIR ? 'icon-folder' : getClassNameForExtension(this.uploadFile.name.split('.').pop())
          this.hasUpload = true
          this.$nextTick(() => {
            this.$refs['contract-pdf-file-viewer'].load(this.document)
          })
        })
    },
    getSourceFile () {
      return `${config.api}/documents${this.document}`
    },
    readFile (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        let fileContent
        reader.onError = (e) => {
          reader.abort()
          reject(e)
        }
        reader.onload = (e) => {
          fileContent = e.target.result
          resolve(fileContent)
        }
        reader.readAsArrayBuffer(file)
      })
    },
    async sendDoc (file) {
      await this.$store.dispatch('documents/createFolder', { path: `${this.pathContracts}/${this.code}` })
      const fileContent = await this.readFile(file)
      this.document = `${this.pathContracts}/${this.code}/${file.name}`
      await this.$store.dispatch('documents/uploadFile', { path: this.document, data: fileContent })
      this.hasUpload = true
      this.$nextTick(() => {
        this.$refs['contract-pdf-file-viewer'].load(this.document)
      })
      this.$emit('change', this.document)
    },
    async sendFile (file) {
      try {
        await this.sendDoc(file)
        return false
      } catch (error) {
        if (error.status !== 409 || !error.bodyText.includes('File already exists')) {
          this.$stratus.services.notify.error(error)
        } else {
          this.$refs['confirm-file-action']
            .open(this.$t('Overwrite file'), this.$t('File «{name}» already exists. Do you want to overwrite?', { name: file.name }), { color: 'red' })
            .then(async (confirmed) => {
              if (confirmed) {
                try {
                  await this.$store.dispatch('documents/deleteFile', { path: `${this.pathContracts}/${this.code}/${file.name}`, recursive: false })
                  await this.sendDoc(file)
                } catch (error) {
                  this.$stratus.services.notify.error(error)
                }
              }
            })
        }
        return true
      }
    },
    onFileChanged (files, names) {
      if (files && files.length) {
        this.$refs['confirm-file-action']
          .open(this.$t('Send documents'), this.$t('Confirm uploading {count} file(s)?', { count: files.length }))
          .then(async confirm => {
            if (confirm) {
              this.uploadFile = files[0]
              this.uploadFile.icon = this.uploadFile.type === TYPE_DIR ? 'icon-folder' : getClassNameForExtension(this.uploadFile.name.split('.').pop())
              const error = await this.sendFile(this.uploadFile)
              if (!error) this.$stratus.services.notify.success(this.$tc('{count} files uploaded.', 1, { count: 1 }))
              this.$forceUpdate()
            }
          })
      }
    },
    onResetUploads () {
      this.uploadFile = {}
    }
  },
  mounted () {
    if (this.documentProp && this.documentProp !== '') {
      this.document = this.documentProp
      this.loadItem(this.document)
    }
  }
}
</script>

<style>
  .no-left-margin-in-snack > button  {
    margin-left: 0 !important;
  }
</style>
