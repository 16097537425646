<template>
  <v-row dense align="center" justify="start">
    <v-col cols="12">
      <v-sheet :class="indentationClass">
        <div :class="{ focused }">
          <csm-product-name v-if="type.length === 0" :company="customer" :name="product.name" class="primary--text" :reference="product.ref" :large="large" />
          <csm-product-name v-else-if="detail.type" :company="customer" :name="beautifyTypeName(detail.type[0])" class="primary--text" :reference="product.ref" :large="large" />
          <span v-show="!hideSubProductsExpander && product.main && product.main.length > 0 && product.quantityChosen !== 0">
            <v-icon :class="showSubProducts ? 'rotate-once-180' : ''" @click="showSubProducts = !showSubProducts">
              $vuetify.icons.expand
            </v-icon>
          </span>

          <v-row no-gutters :class="{ focused }">
            <v-col class="shrink text-no-wrap mr-2">
              {{ detail.quantity && detail.quantity.list && isBoolean(detail.quantity.list) ? (product.quantityChosen ? $t('Yes') : $t('None')) : $t('Qty:') }}
            </v-col>

            <v-col v-if="product.option_not_multiplication" class="shrink">
              <cs-help-icon>
                <div slot="content">
                  {{ $t('The quantity of this product will not be multiplied by the quantities of its ancestors.') }}
                </div>
              </cs-help-icon>
            </v-col>

            <v-col cols="12" md="3">
              <csm-product-quantity-choice v-if="detail.quantity && product.quantityChosen !== undefined && typeof product.quantityChosen === 'number'" :customer="customer" :quantity-rules="detail.quantity" :quantity="productReferant.quantity" v-model="product.quantityChosen" :unit="detail.unit" :modify-sub-product="!inCart" @input="onChangeQuantity" />
            </v-col>

            <v-col v-if="type.length > 0">
              <!--S'il s'agit d'un type de produit-->
              <csm-dyna-product-select v-show="product.quantityChosen" :items="typeSorted" v-model="product" :customer="customer" item-text="name" return-object :class="(indent) ? `margin-indent-${level}` : ''" hide-details @change="onSelectProduct(product)" @blur="focused = false" @focus="focused = true" />
            </v-col>
          </v-row>
        </div>

        <div v-show="product.quantityChosen !== 0 && !processChanging">
          <div v-for="subProduct in product.main" v-show="showSubProducts" :key="subProduct.index" class="sub-product-border-left">
            <!--S'il s'agit d'un produit-->
            <csm-dyna-product-recursive v-if="subProduct.ref && productReferant && productReferant.sub" v-model="subProduct.ref" :detail="subProduct" :product-ref="productReferant.sub.main[hasThisRef(productReferant.sub.main, subProduct.index)]" :indent="true" :level="level+1" :large="large" @goToTop="goToTop" :super-indent="superIndent" :customer="customer" :in-cart="inCart" />
            <!--S'il s'agit d'un type de produit-->
            <csm-dyna-product-recursive v-else-if="productReferant && productReferant.sub" v-model="subProduct.type[0]" :detail="subProduct" :product-ref="productReferant.sub.main[hasThisRef(productReferant.sub.main, subProduct.index)]" :indent="true" :level="level+1" :large="large" @goToTop="goToTop" :super-indent="superIndent" :customer="customer" :in-cart="inCart" />
          </div>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CsmDynaProductModifyRecursive',
  props: {
    customer: { type: String, default: '' },
    detail: { type: Object, required: true },
    level: { type: Number, default: 0 },
    large: { type: Boolean, default: false },
    indent: { type: Boolean, default: false },
    superIndent: { type: Boolean, default: false },
    value: { type: String, required: true },
    productRef: { type: Object, default: () => {} },
    inCart: { type: Boolean, default: false }
  },
  components: {
    'csm-dyna-product-select': () => import(/* webpackChunkName: "components" */ './DynaProductSelect'),
    'csm-dyna-product-recursive': () => import(/* webpackChunkName: "components" */ './DynaProductModifyRecursive'),
    'csm-product-name': () => import(/* webpackChunkName: "components" */ './ProductName'),
    'csm-product-quantity-choice': () => import(/* webpackChunkName: "components" */ './ProductQuantityChoice')
  },
  data () {
    return {
      focused: false,
      hideSubProductsExpander: false,
      processChanging: false,
      product: {},
      productReferant: {},
      type: [],
      productToReturn: {},
      showSubProducts: false,
      typeTmp: ''
    }
  },
  watch: {
    value: {
      handler () {
        if (this.value !== this.productToReturn.ref && this.detail.index === this.productToReturn.index) {
          this.initialiseProduct()
        }
      }
    }
  },
  computed: {
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    indentationClass () {
      const cls = (this.indent) ? `margin-indent-${(this.superIndent) ? this.level * 2 : this.level}` : ''
      return cls
    },
    typeSorted () {
      return _.sortBy(this.type, 'sort')
    }
  },
  methods: {
    beautifyTypeName (type) {
      const tmp = type.split('-')
      return tmp.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')
    },
    async getProductDiscount (ref) {
      return new Promise(resolve => {
        this.$store.dispatch('carts/getDiscount', { ref, company: this.customer })
          .then(res => {
            resolve(res.per_discount || 0)
          })
          .catch(error => {
            this.$stratus.services.notify.error(error)
          })
      })
    },
    getMinimalQuantity (quantity, quantityChosen) {
      if (Object().hasOwnProperty.call(quantity, 'list')) {
        if (quantity.list.indexOf(quantityChosen) >= 0) {
          return quantityChosen
        }
        return quantity.list[0]
      } else {
        if (quantityChosen >= quantity.min && quantityChosen <= quantity.max) {
          return quantityChosen
        }
        return quantity.min
      }
    },
    getProductByRef (ref) {
      return new Promise(resolve => {
        this.$store.dispatch('$alto-catalog/getProductByRef', ref)
          .then(result => {
            resolve(result)
          })
      })
    },
    getProductsByType (type) {
      return new Promise(resolve => {
        this.$store.dispatch('$alto-catalog/getType', { type })
          .then(result => {
            resolve(result)
          })
      })
    },
    goToTop (payload) {
      // On crée un produit à retourner avec les infos nécessaire
      this.productToReturn.quantity = this.product.quantityChosen
      this.productToReturn.ref = this.product.ref
      this.productToReturn.name = this.product.name
      this.productToReturn.per_discount = this.product.per_discount
      if (!Object.hasOwnProperty.call(this.productToReturn, 'index')) {
        this.productToReturn.index = this.detail.index
      }

      // On s'occupe du main à retourner
      if (this.product.main && this.product.main.length > 0) {
        // Si le produit a un main non vide
        if (!Object().hasOwnProperty.call(this.productToReturn, 'sub')) {
          // Si le produit à retourner n'a pas encore de sub alors on lui en crée un vide
          this.productToReturn.sub = { main: [] }
        }
        // On test si la référence existe déjà dans le main du produit à retourner
        const test = this.hasThisRef(this.productToReturn.sub.main, payload.index)
        if (this.productToReturn.ref !== payload.ref) {
          // On filtre la ref lui même
          if (test !== null) {
            // Si la réf est déjà présente on la modifie
            this.productToReturn.sub.main[test] = payload
          } else {
            this.productToReturn.sub.main.push(payload)
          }
        }
      } else {
        // Si le produit n'a pas de main, on en ajoute un vide
        this.productToReturn.sub = { main: [] }
      }

      if (this.productToReturn.quantity === 0 || this.product.main.length === this.productToReturn.sub.main.length) {
        if (this.level === 0) {
          this.$emit('change', this.productToReturn)
        } else {
          this.$emit('goToTop', this.productToReturn)
        }
      }
    },
    hasThisRef (main, index) {
      let i = 0
      while (i < main.length) {
        if (main[i].index === index) return i
        i++
      }
      return null
    },
    hasToShowBoth () {
      return !(this.type.length === 0 || (this.detail && Array.isArray(this.detail.quantity) && this.detail.quantity.length === 1 && this.type.length === 0))
    },
    async initialiseProduct () {
      if (Object().hasOwnProperty.call(this.detail, 'type')) {
        // S'il s'agit d'un type de produit on remplit le type et on prend le premier produit des type
        this.type = await this.getProductsByType(this.detail.type)
        this.type.forEach(item => {
          if (this.productReferant) {
            item.quantityChosen = this.getMinimalQuantity(this.detail.quantity, this.productReferant.quantity)
            item.per_discount = this.productReferant.per_discount
          }
        })

        let refInit = this.type[0].ref
        if (this.productReferant && Object().hasOwnProperty.call(this.productReferant, 'ref')) {
          refInit = this.productReferant.ref
        }

        this.product = this.initProductRefType({ ref: refInit }, this.type)
      } else {
        this.product = await this.getProductByRef(this.value)
      }

      if (!this.productReferant) {
        this.productReferant = this.product
        this.product.quantityChosen = this.getMinimalQuantity(this.detail.quantity, 0)
      } else {
        this.product.quantityChosen = this.getMinimalQuantity(this.detail.quantity, this.productReferant.quantity)
      }

      this.product.per_discount = Object().hasOwnProperty.call(this.productReferant, 'per_discount') ? this.productReferant.per_discount : await this.getProductDiscount(this.product.ref)

      this.$forceUpdate()

      // On update le produit père avec l'ajout de la quantité
      if (this.product.main.length === 0 || this.product.quantityChosen === 0) {
        this.goToTop(this.product)
      }

      this.showSubProducts = this.isLord || !this.product.hide_subproduct
      this.hideSubProductsExpander = !this.showSubProducts
    },
    initProductRefType (productRef, type) {
      let refType = type[0]
      if (Object.hasOwnProperty.call(productRef, 'ref')) {
        type.forEach(item => {
          if (item.ref === productRef.ref) {
            refType = item
          }
        })
      }
      return refType
    },
    isBoolean (array) {
      return _.isEqual(array, [0, 1])
    },
    async onChangeQuantity (payload) {
      this.product.quantityChosen = payload
      if (Object().hasOwnProperty.call(this.detail, 'type')) {
        this.type.forEach(item => {
          item.quantityChosen = this.getMinimalQuantity(this.detail.quantity, payload)
        })
      }
      this.$forceUpdate()
      this.goToTop(this.product)
    },
    async onSelectProduct (payload) {
      if (payload.ref !== this.productReferant.ref) {
        this.processChanging = true
        this.productReferant = await this.getProductByRef(this.product.ref)
        if (!Object.hasOwnProperty.call('sub', this.productReferant)) {
          this.productReferant.sub = { main: this.productReferant.main }
        }
        if (!Object.hasOwnProperty.call('quantity', this.productReferant) || this.productReferant.quantity === undefined) {
          this.productReferant.quantity = this.product.quantityChosen
        }
        this.product.per_discount = await this.getProductDiscount(payload.ref)

        this.processChanging = false
        this.$forceUpdate()
        if (payload.main.length === 0) {
          this.goToTop(payload)
        }
      }
    }
  },
  created () {
    this.initialiseProduct = _.debounce(this.initialiseProduct, 500)
  },
  async mounted () {
    await this.initialiseProduct()
    this.productReferant = this.productRef
  }
}
</script>
