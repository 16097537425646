<template>
  <sca-modal-dialog :visible="show" @closeDialog="close" :max-width="$vuetify.breakpoint.lgAndUp ? '80%' : ''" :hide-footer="readonly || computedPrices.total_discount_incl_vat === undefined">
    <div slot="title" class="d-flex align-center">
      <span class="text-h6">
        {{ product.ref }}
      </span>

      <span class="pl-2">
        — {{ product.name }}
      </span>
    </div>

    <div slot="content">
      <v-row justify="space-between">
        <v-col cols="12" md="6">
          <p>{{ product.customer_caption }}</p>
          <p>{{ product.caption }}</p>
          <p>{{ product.name }}</p>
          <p v-if="isLord && customer">
            <sca-company-identity :value="customer" />
          </p>
          <v-divider />
          <p v-html="product.description" />
        </v-col>

        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-card outlined>
                <sca-product-prices-grid :prices="computedPrices" :title="$t('Total')" :periodicity="product.periodicity" :working="isWorking" show-margin dense />
                <sca-product-price-simulator v-if="product.periodicity === 'D' && customer && computedPrices.total_discount_incl_vat !== undefined" ref="product-price-simulator" :customer-id="customer" :product="product" />
              </v-card>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-text-field v-if="isLord" v-model="product.caption" :label="$t('Caption')" class="mr-2" :rules="[$stratus.services.form.rules.max(128)]" :readonly="readonly" />
              <div v-else-if="!isLord && product.caption">
                {{ product.caption }}
              </div>
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="product.customer_caption" :label="$t('Customer caption')" class="mr-2" :rules="[$stratus.services.form.rules.max(128)]" :readonly="readonly" />
            </v-col>

            <v-form ref="product-form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" class="mt-4 text-h6">
                  <csm-product-quantity-choice v-if="product.quantity_rules && typeof quantity === 'number'" :label="$t('Quantity')" :customer="customer" :quantity-rules="product.quantity_rules" :quantity="quantity" @input="getPriceProduct()" v-model="quantity" :readonly="readonly || !canChangeQuantity(product)" />
                </v-col>

                <v-col cols="12" v-if="isLord && product.no_price">
                  <v-row align="center">
                    <v-col cols="12" md="6">
                      <cs-decimal-input v-model="product.price" :label="$t('Custom price')" :min="0" @input="getPriceProduct" :readonly="readonly" />
                    </v-col>
                    <v-col cols="12" md="6">
                      <cs-decimal-input v-model="product.buying_price" :label="$t('Custom buying price')" :min="0" @input="getPriceProduct" :readonly="readonly" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>

            <v-col cols="12">
              <sca-product-details v-if="readonly" :product="product" :company="customer" :has-caption="!isLord" :show="true" />
              <csm-dyna-products-modify v-else-if="product.main && product.main.length" :customer="customer" ref="dyna-products" :product="product" :product-ref="productByRef" :in-cart="inCart" large @change="onChange" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <cs-alert-panel v-if="isLord && product.no_price" type="info">
            <template #content>
              <div class="font-weight-bold">
                {{ $t('No price fixed') }}
              </div>
              {{ $t('Please fill the price and buying price fields for this product.') }}
            </template>
          </cs-alert-panel>
          <cs-alert-panel v-if="isLord && product.package" type="info">
            <template #content>
              <div class="font-weight-bold">
                {{ $t('Package') }}
              </div>
              {{ $t('Complex products including this reference will not be taken into account when calculating the price with and without discount, and the price including VAT.') }}
            </template>
          </cs-alert-panel>
        </v-col>
      </v-row>
    </div>

    <div v-if="!readonly && computedPrices.total_discount_incl_vat !== undefined" slot="buttons">
      <v-btn rounded class="main-button" :disabled="isWorking || this.quantity <= 0" :loading="isWorking" @click="updateProduct">
        {{ update ? $t('Update') : $t('Add') }}
      </v-btn>
    </div>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ProductDuplicateDialog',
  components: {
    'csm-dyna-products-modify': () => import(/* webpackChunkName: "components" */ './DynaProductsModify'),
    'csm-product-quantity-choice': () => import(/* webpackChunkName: "components" */ './ProductQuantityChoice')
  },
  props: {
    code: { type: String, default: '' },
    inCart: { type: Boolean, default: false }
  },
  data () {
    return {
      computedPrices: {},
      customer: null,
      isWorking: false,
      price: NaN,
      product: {},
      productByRef: {},
      productComputed: {},
      quantity: 1,
      readonly: false,
      show: false,
      update: false,
      valid: false
    }
  },
  computed: {
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    maxDateEnd () {
      return this.$stratus.dt().add(1, 'days').startOf('day').toISOString()
    }
  },
  methods: {
    canChangeQuantity (item) {
      if (!this.isLord) {
        if (item.no_price) {
          return false
        }
      }
      return true
    },
    close () {
      this.show = false
      this.resolve(false)
    },
    getPriceProduct () {
      // Wait until productComputed is fully fed
      if (this.productByRef.main && this.productComputed.length !== this.productByRef.main.length) return

      this.isWorking = true
      const data = {
        main: this.productComputed,
        quantity: this.quantity,
        per_discount: 0,
        per_discount_setup: 0
      }
      if (this.product.no_price) {
        data.price = this.product.price || 0
        data.buying_price = this.product.buying_price || 0
      }
      this.$store.dispatch('carts/getPrice', {
        code: this.code,
        ref: this.product.ref,
        data
      })
        .then(result => {
          this.computedPrices = result.price
          if (this.$refs['product-price-simulator']) {
            this.$refs['product-price-simulator'].refresh({
              main: this.productComputed
            })
          }
        })
        .catch(error => {
          console.error(error)
          this.$stratus.services.notify.error(error)
        })
        .finally(() => {
          setTimeout(() => { this.isWorking = false }, 500)
        })
    },
    async getProductByRef (ref) {
      return new Promise(resolve => {
        this.$store.dispatch('$alto-catalog/getProductByRef', ref)
          .then(result => {
            resolve(result)
          })
      })
    },
    onChange (payload) {
      this.productComputed = payload
      if (payload.length === this.productByRef.main.length) {
        this.getPriceProduct()
      }
    },
    async open (product = {}, { update, readonly, customer }) {
      await this.reset()

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        this.customer = customer
        this.product = product
        this.productComputed = product.main
        this.productByRef = await this.getProductByRef(this.product.ref)
        this.update = update
        this.readonly = readonly
        this.quantity = product.quantity || 1
        this.resolve = resolve
        this.reject = reject
        // Simple product needs a first explicit call to price computation because they do not have sub-products that will launch onChange event.
        if (!product.main || !product.main.length) this.getPriceProduct()
        this.show = true
      })
    },
    async reset () {
      if (this.$refs['product-form']) await this.$refs['product-form'].reset()
      if (this.$refs['dyna-products']) await this.$refs['dyna-products'].reset()
      this.quantity = NaN
      this.date_end = this.$stratus.dt().toISOString().substr(0, 10)
      this.computedPrices = {}
      this.price = NaN
      this.product = {}
      this.valid = false
    },
    updateProduct (product) {
      if (!this.$refs['product-form'].validate()) {
        this.errorMessage = this.$t('One or more fields must be corrected!')
        return
      }
      this.resolve({ product: this.product, main: this.productComputed, quantity: this.quantity })
      this.show = false
    }
  },
  mounted () {
    this.getPriceProduct = _.debounce(this.getPriceProduct, 500)
  }
}
</script>
